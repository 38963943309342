import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
  ADD_DEPARTMENT_KPI,
  addDepartmentKPISuccessGenerator, DELETE_DEPARTMENT_KPI,
  deleteDepartmentKPISuccessGenerator, EDIT_DEPARTMENT_KPI,
  editDepartmentKPISuccessGenerator,
  FETCH_DEPARTMENT_KPIs, fetchDepartmentKPIsGenerator,
  fetchDepartmentKPIsSuccessGenerator
} from "../../actions/appraisal/DepartmentKPIActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchDepartmentKPIsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/appraisal/departmentkpi',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addDepartmentKPIRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/appraisal/departmentkpi',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteDepartmentKPIRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/appraisal/departmentkpi/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editDepartmentKPIRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/appraisal/departmentkpi/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchDepartmentKPIs({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDepartmentKPIsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let department_kpis = response.data.data;
        yield put(fetchDepartmentKPIsSuccessGenerator(department_kpis));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDepartmentKPI({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addDepartmentKPIRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let department_kpis = response.data.data;
        yield put(addDepartmentKPISuccessGenerator(department_kpis));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteDepartmentKPI({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDepartmentKPIRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let department_kpis = response.data.data;
        yield put(deleteDepartmentKPISuccessGenerator(department_kpis));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editDepartmentKPI({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editDepartmentKPIRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let department_kpis = response.data.data;
        yield put(fetchDepartmentKPIsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchDepartmentKPIsDefault() {
  yield takeLatest(FETCH_DEPARTMENT_KPIs, fetchDepartmentKPIs)
}
export function* addDepartmentKPIDefault() {
  yield takeLatest(ADD_DEPARTMENT_KPI, addDepartmentKPI);
}
export function* deleteDepartmentKPIDefault() {
  yield takeLatest(DELETE_DEPARTMENT_KPI, deleteDepartmentKPI);
}
export function* editDepartmentKPIDefault() {
  yield takeLatest(EDIT_DEPARTMENT_KPI, editDepartmentKPI);
}
export default function* rootSaga() {
  yield all([fork(fetchDepartmentKPIsDefault),
    fork(addDepartmentKPIDefault),
    fork(deleteDepartmentKPIDefault),
    fork(editDepartmentKPIDefault)
  ]);
}
