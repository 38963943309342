export const FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES = "FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES";
export const FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_SUCCESS = "FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_SUCCESS";
export const ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME = 'ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME';
export const ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS = 'ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS';
export const EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME = 'EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME';
export const EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS = 'EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS';
export const DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME = 'DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME';
export const DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS = 'DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS';
export const FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMME_DETAILS = "FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMME_DETAILS";
export const FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_DETAILS_SUCCESS = "FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_DETAILS_SUCCESS";

export const fetchPerformanceImprovementProgrammesGenerator = (data=[])=>{
  return {
    type:FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES,
    payload:data
  };
};

export const fetchPerformanceImprovementProgrammesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_SUCCESS,
    payload:dataById
  }
};

export const addPerformanceImprovementProgrammeGenerator = (data)=>{
  return {
    type: ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME,
    payload: data
  }
};

export const addPerformanceImprovementProgrammeSuccessGenerator = (data)=>{
  return {
    type: ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS,
    payload: data
  }
};

export const editPerformanceImprovementProgrammeGenerator = (data)=>{
  return {
    type: EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME,
    payload: data
  }
};
export const editPerformanceImprovementProgrammeSuccessGenerator = (data)=>{
  return {
    type: EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS,
    payload: data
  }
};

export const deletePerformanceImprovementProgrammeGenerator = (data)=>{
  return {
    type: DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME,
    payload: data
  }
};
export const deletePerformanceImprovementProgrammeSuccessGenerator = (data)=>{
  return {
    type: DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS,
    payload: data
  }
};

export const fetchPerformanceImprovementProgrammeDetailsGenerator = (data=[])=>{
  return {
    type:FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMME_DETAILS,
    payload:data
  };
};

export const fetchPerformanceImprovementProgrammeDetailsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_DETAILS_SUCCESS,
    payload:dataById
  }
};
