export const FETCH_COMPANY_ACCOUNT_SETTINGS = 'FETCH_COMPANY_ACCOUNT_SETTINGS';
export const FETCH_COMPANY_ACCOUNT_SETTINGS_SUCCESS = 'FETCH_COMPANY_ACCOUNT_SETTINGS_SUCCESS';

export const UPDATE_COMPANY_ACCOUNT_SETTINGS = 'UPDATE_COMPANY_ACCOUNT_SETTINGS';
export const UPDATE_COMPANY_ACCOUNT_SETTINGS_SUCCESS = 'UPDATE_COMPANY_ACCOUNT_SETTINGS_SUCCESS';

export const fetchCompanyAccountSettingsGenerator =(data=[])=>{
  return{
    type:FETCH_COMPANY_ACCOUNT_SETTINGS,
    payload:data
  }
}
export const fetchCompanyAccountSettingsSuccessGenerator =(data=[])=>{
  return{
    type:FETCH_COMPANY_ACCOUNT_SETTINGS_SUCCESS,
    payload:data
  }
}
export const updateCompanyAccountSettingsGenerator =(data=[])=>{
  return{
    type:UPDATE_COMPANY_ACCOUNT_SETTINGS,
    payload:data
  }
}
export const updateCompanyAccountSettingsSuccessGenerator =(data=[])=>{
  return{
    type:UPDATE_COMPANY_ACCOUNT_SETTINGS_SUCCESS,
    payload:data
  }
}
