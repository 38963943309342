export const FETCH_EMPLOYEE_PROFILE_DETAILS = 'FETCH_EMPLOYEE_PROFILE_DETAILS';
export const FETCH_EMPLOYEE_PROFILE_DETAILS_SUCCESS = 'FETCH_EMPLOYEE_PROFILE_DETAILS_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PROFILE_PHOTO = 'CHANGE_PROFILE_PHOTO';
export const CHANGE_PROFILE_PHOTO_SUCCESS = 'CHANGE_PROFILE_PHOTO_SUCCESS';
export const MY_DAILY_ATTENDANCE = 'MY_DAILY_ATTENDANCE';
export const MY_DAILY_ATTENDANCE_SUCCESS = 'MY_DAILY_ATTENDANCE_SUCCESS';
export const FETCH_MY_TRAINING = 'FETCH_MY_TRAINING';
export const FETCH_MY_TRAINING_SUCCESS = 'FETCH_MY_TRAINING_SUCCESS';

export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS';

export const updateProfileInfoGenerator =(payload)=>{
  return {
    type:UPDATE_PROFILE_INFO,
    payload
  }
}
export const updateProfileInfoSuccessGenerator =(payload)=>{
  return {
    type:UPDATE_PROFILE_INFO_SUCCESS,
    payload
  }
}

export const fetchMyTrainingGenerator = payload =>{
  return {
    type:FETCH_MY_TRAINING,
    payload
  }
};
export const fetchMyTrainingSuccessGenerator = payload =>{
  return {
    type:FETCH_MY_TRAINING_SUCCESS,
    payload
  }
};
export const myDailyAttendanceGenerator = payload =>{
  return{
    type:MY_DAILY_ATTENDANCE,
    payload
  }
};
export const myDailyAttendanceSuccessGenerator = payload =>{
  return{
    type:MY_DAILY_ATTENDANCE_SUCCESS,
    payload
  }
};
export const changeProfilePhoto = (data)=>{
  return {
    type:CHANGE_PROFILE_PHOTO,
    payload:data
  }
};
export const changeProfileSuccessPhoto = (data)=>{
  return {
    type:CHANGE_PROFILE_PHOTO_SUCCESS,
    payload:data
  }
};
export const fetchEmployeeProfileDetailsGenerator = (data)=>{
  return {
    type:FETCH_EMPLOYEE_PROFILE_DETAILS,
    payload:data
  }
};
export const fetchEmployeeProfileDetailSuccessGenerator = (data)=>{
  return {
    type:FETCH_EMPLOYEE_PROFILE_DETAILS_SUCCESS,
    payload:data
  }
};
export const changePasswordGenerator = (data)=>{
  return {
    type:CHANGE_PASSWORD,
    payload:data
  }
};
export const changePasswordSuccessGenerator = (data)=>{
  return {
    type:CHANGE_PASSWORD_SUCCESS,
    payload:data
  }
};

