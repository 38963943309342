import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {
  ACTIVATE_BULK_EMPLOYEES,
  ADD_BULK_EMPLOYEES,
  ADD_DYNAMIC_OFF_DAYS,
  ADD_EMPLOYEE, ADD_EMPLOYEE_SHARES,
  addEmployeeSuccessGenerator,
  BULK_DELETE_EMPLOYEES,
  BULK_EDIT_EMPLOYEES,
  BULK_INVITE_EMPLOYEES, CONFIRM_EMPLOYEE_FROM_PROBATION,
  DELETE_DYNAMIC_OFF_DAYS,
  DELETE_EMPLOYEE,
  deleteEmployeeSuccessGenerator, EDIT_EMPLOYEE_SHARES, editEmployeeSharesSuccessGenerator,

  FETCH_DYNAMIC_OFF_DAYS, FETCH_EMPLOYEE_SHARES, FETCH_PENDING_EMPLOYEES,
  FETCH_EMPLOYEES,
  fetchDynamicOffDaysGenerator,
  fetchDynamicOffDaysSuccessGenerator,
  fetchEmployeesGenerator, fetchEmployeeSharesGenerator, fetchEmployeeSharesSuccessGenerator,
  fetchEmployeesSuccessGenerator,fetchPendingEmployeesSuccessGenerator,

  hardDeleteEmployeeSuccessGenerator,
  INVITE_EMPLOYEES,
  inviteEmployeesSuccessGenerator, RATE_EMPLOYEE,
  resetEmployeePasswordSuccessGenerator,
  REST_EMPLOYEE_PASSWORD,
  SEND_CREDENTIALS,
  sendEmployeeCredentialSuccessGenerator, SET_EMPLOYEE_ON_PROBATION,
  SET_EMPLOYEE_STATUS,
  setEmployeeStatusSuccessGenerator,
  TRANSFER_EMPLOYEE,
  transferEmployeeSuccessGenerator,
  UPDATE_EMPLOYEE, UPDATE_EXCEL_BULK_EMPLOYEES,
  updateEmployeeSuccessGenerator,
  UPLOAD_EMPLOYEE_EDIT,
  UPLOAD_EMPLOYEE_NO_EDIT
} from "../../actions/payroll/EmployeeActions";
import {userSignOutGenerator, userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { push } from "connected-react-router";

const setEmployeeOnProbationRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/set/employee/probation',requestData)
    .then(response=>response)
    .catch(error=>error.request);

const confirmEmployeeFromProbationRequest =async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/confirm/employee/probation',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const bulkDeleteEmployeeRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/bulk-delete-employees',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const fetchEmployeesRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/employees",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

const fetchDynamicOffDaysRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/off-days",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

const addDynamicOffDaysRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/off-days',requestParameters)
    .then(response => response)
    .catch(error=>error.response);

const deleteDynamicOffDaysRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/off-days/delete',requestParameters)
    .then(response => response)
    .catch(error=>error.response);

const activateBulkEmployeesRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/bulk/activate/employees',requestData)
    .then(response => response)
    .catch(error=>error.response);
const bulkEditEmployeesRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/bulk/edit/employees',requestData)
    .then(response => response)
    .catch(error=>error.response);
const rateEmployeeRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/rate/employees',requestData)
    .then(response => response)
    .catch(error=>error.response);
const fetchEmployeeSharesRequest = async (requestData) =>
  await  axios.get(API_ENDPOINT+'/hrm/employee-shares', { params:requestData })
    .then(response => response)
    .catch(error => error.response);
const addEmployeeSharesRequest = async (requestData) =>
  await  axios.post(API_ENDPOINT+'/hrm/employee-shares', requestData,{headers: {
      'content-type': `multipart/form-data`
    }} //also used for file upload
  )
    .then(response => response)
    .catch(error => error.response);
const editEmployeeSharesRequest = async (requestData) =>
  await  axios.put(API_ENDPOINT+'/hrm/employee-shares/'+requestData.id,requestData)
    .then(response => response)
    .catch(error => error.response);

const fetchPendingEmployeesRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/get/pending/employees",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

function* fetchPendingEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(fetchPendingEmployeesRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchPendingEmployeesSuccessGenerator(response.data.data));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* confirmEmployeeFromProbation({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(confirmEmployeeFromProbationRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addEmployeeShares({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    requestParams = Helpers.getFormData(requestParams);//the same route is used for bulk upload

    let response =yield(call(addEmployeeSharesRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeeSharesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editEmployeeShares({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(editEmployeeSharesRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeeSharesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEmployeeShares({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(fetchEmployeeSharesRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeeSharesSuccessGenerator(response.data.data));
        //yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* setEmployeeOnProbation({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(setEmployeeOnProbationRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* bulkDeleteEmployee({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(bulkDeleteEmployeeRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* bulkEditEmployees({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(bulkEditEmployeesRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* rateEmployee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(rateEmployeeRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* activateBulkEmployees({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(activateBulkEmployeesRequest,requestParams));
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
        if (response.data.hasOwnProperty('subscribe')){
          yield put(push('/common/subscription',response.data.data));
        }
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* activateBulkEmployeesDefault() {
  yield takeLatest(ACTIVATE_BULK_EMPLOYEES,activateBulkEmployees);
}

function* fetchEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(fetchEmployeesRequest,requestParams));

    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesSuccessGenerator(response.data.data));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

function* fetchDynamicOffDays({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(fetchDynamicOffDaysRequest,requestParams));

    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchDynamicOffDaysSuccessGenerator(response.data.data));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

function* addDynamicOffDays({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(addDynamicOffDaysRequest,requestParams));

    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchDynamicOffDaysGenerator({month:payload.month, year:payload.year}));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

function* deleteDynamicOffDays({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield(call(deleteDynamicOffDaysRequest,requestParams));

    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchDynamicOffDaysGenerator({month:payload.month, year:payload.year}));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

export function* fetchEmployeesDefault(){
  yield(takeLatest(FETCH_EMPLOYEES,fetchEmployees));
}
/*End:Fetch Employees*/
/*Start:Add Employees*/
const addEmployeeRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/employees",requestParameters)
    .then(response=>response).catch(error =>error.response);

function* addEmployee({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let response =yield call(addEmployeeRequest,requestParameters);
    if(response.status ===201 || response.status ===200){
      if(response.data.success){
        yield put(addEmployeeSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else {
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* addEmployeeDefault() {
  yield (takeLatest(ADD_EMPLOYEE,addEmployee));
}
/*End:Add Employees*/
const updateExcelBulkEmployeesRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/bulk-employee-update",requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response).catch(error =>error.response);
function* updateExcelBulkEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    let response =yield call(updateExcelBulkEmployeesRequest,formData);
    if(response.status ===201 || response.status ===200){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else {
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
/*Start:Add Bulk Employees*/
const addBulkEmployeesRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/file-upload/employee/edit",requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response).catch(error =>error.response);

function* addBulkEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    let response =yield call(addBulkEmployeesRequest,formData);
    if(response.status ===201 || response.status ===200){
      if(response.data.success){
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else {
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* addBulkEmployeesDefault() {
  yield (takeLatest(ADD_BULK_EMPLOYEES,addBulkEmployees));
}
/*End:Add Bulk Employees*/
/*Start:Edit Employees*/
const updateEmployeeRequest=async (request)=>await axios.put(API_ENDPOINT+"/hrm/employees/"+request.id,request)
  .then(response =>response).catch(error=>error.response);

function* updateEmployee({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let request ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    let response =yield call(updateEmployeeRequest,request);
    if(response.status ===201 || response.status ===200){

      if(response.data.success){
        /*update the record in the store*/
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }else{
      yield put(showMessageGenerator(request.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* updateEmployeeDefault() {
  yield (takeLatest(UPDATE_EMPLOYEE,updateEmployee));
}
/*End:Edit Employees*/
/*start: delete employee*/
const deleteEmployeeRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/employees/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
function* deleteEmployee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteEmployeeRequest,requestParameters);
    if(response.status === 201 || response.status===200){
      if(response.data.success){
        if(requestParameters.action ==="delete"){
          yield put(updateEmployeeSuccessGenerator(response.data.data));
        }else {
          yield put(deleteEmployeeSuccessGenerator(response.data.data));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      }else {
        yield put(showMessageGenerator(response.data.message));
      }
    }else if(response.status === 401){
      yield put(showMessageGenerator(response.statusText,'error'));
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* deleteEmployeeDefault() {
  yield (takeLatest(DELETE_EMPLOYEE,deleteEmployee));
}
/*end: delete employee*/
//// start: transfer employee
const transferEmployeeRequest=async (request)=>
  await axios.post(API_ENDPOINT+"/hrm/employees/transfer/employee",request)
    .then(response =>response).catch(error=>error.response);

function* transferEmployee({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let request ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    let response =yield call(transferEmployeeRequest,request);
    if(response.status ===201 || response.status ===200){

      if(response.data.success){
        /*if (typeof request.employee_id ==='undefined'){
          yield put(fetchEmployeesGenerator());
        }else{
          yield put(transferEmployeeSuccessGenerator(response.data.data));
        }*/
        yield put(fetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }else{
      yield put(showMessageGenerator(request.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* transferEmployeeDefault() {
  yield (takeLatest(TRANSFER_EMPLOYEE,transferEmployee));
}
/////invite employees
const inviteEmployeesRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/invite/employees",requestParameters)
    .then(response=>response).catch(error =>error.response);

function* inviteEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let request ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    let response =yield call(inviteEmployeesRequest,request);
    if(response.status ===201 || response.status ===200){

      if(response.data.success){
        yield put(inviteEmployeesSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }else{
      yield put(showMessageGenerator(request.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* inviteEmployeesDefault() {
  yield (takeLatest(INVITE_EMPLOYEES, inviteEmployees));
}
/////send credentials
const sendEmployeeCredentialsRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/send/credintials",requestParameters)
    .then(response=>response).catch(error =>error.response);

function* sendCredentials({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let request ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    let response =yield call(sendEmployeeCredentialsRequest,request);
    if(response.status ===201 || response.status ===200){

      if(response.data.success){
        yield put(sendEmployeeCredentialSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }else{
      yield put(showMessageGenerator(request.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* sendCredentialsDefault() {
  yield (takeLatest(SEND_CREDENTIALS, sendCredentials));
}

///reset password
const resetEmployeePasswordRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/reset/employee/password",requestParameters)
    .then(response=>response).catch(error =>error.response);

function* resetEmployeePassword({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let request ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    let response =yield call(resetEmployeePasswordRequest,request);
    if(response.status ===201 || response.status ===200){

      if(response.data.success){
        yield put(resetEmployeePasswordSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }else{
      yield put(showMessageGenerator(request.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* resetEmployeePasswordDefault() {
  yield (takeLatest(REST_EMPLOYEE_PASSWORD, resetEmployeePassword));
}

/*set status*/
const setEmployeeStatusRequest =async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/employees/set_status/"+requestParameters.id+"/"+requestParameters.setStatus,requestParameters)
    .then(response=>response).catch(error =>error.response);
function* setEmployeeStatus({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let request ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    let response =yield call(setEmployeeStatusRequest,request);
    if(response.status ===201 || response.status ===200){

      if(response.data.success){
        switch (request.setStatus) {
          case 'HIDDEN':
            yield put(hardDeleteEmployeeSuccessGenerator(response.data.data));
            break;
          default:
            yield put(setEmployeeStatusSuccessGenerator(response.data.data));
            break;
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
        if (response.data.hasOwnProperty('subscribe')){
          yield put(push('/common/subscription',response.data.data));
        }
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }else{
      yield put(showMessageGenerator(request.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* setEmployeeStatusDefault() {
  yield (takeLatest(SET_EMPLOYEE_STATUS, setEmployeeStatus));
}
/*end set status*/

const uploadEmployeeNoEditRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+"/hrm/file-upload/employee/no-edit",requestData,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);
function* uploadEmployeeNoEdit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const sendData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadEmployeeNoEditRequest, sendData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* uploadEmployeeNoEditDefault() {
  yield takeLatest(UPLOAD_EMPLOYEE_NO_EDIT,uploadEmployeeNoEdit);
}
const uploadEmployeeEditRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+"/hrm/file-upload/employee/edit",requestData,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);
function* uploadEmployeeEdit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const sendData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadEmployeeEditRequest, sendData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* setEmployeeOnProbationDefault(){
  yield takeLatest(SET_EMPLOYEE_ON_PROBATION,setEmployeeOnProbation);
}
export function* confirmEmployeeFromProbationDefault(){
  yield takeLatest(CONFIRM_EMPLOYEE_FROM_PROBATION,confirmEmployeeFromProbation);
}
export function* uploadEmployeeEditDefault() {
  yield takeLatest(UPLOAD_EMPLOYEE_EDIT,uploadEmployeeEdit);
}
export function* bulkEditEmployeesDefault() {
  yield takeLatest(BULK_EDIT_EMPLOYEES,bulkEditEmployees);
}
export function* fetchDynamicOffDaysDefault() {
  yield takeLatest(FETCH_DYNAMIC_OFF_DAYS,fetchDynamicOffDays);
}
export function* addDynamicOffDaysDefault() {
  yield takeLatest(ADD_DYNAMIC_OFF_DAYS,addDynamicOffDays);
}
export function* deleteDynamicOffDaysDefault() {
  yield takeLatest(DELETE_DYNAMIC_OFF_DAYS,deleteDynamicOffDays);
}
export function* bulkDeleteEmployeeDefault() {
  yield takeLatest(BULK_DELETE_EMPLOYEES,bulkDeleteEmployee);
}
export function* updateExcelBulkEmployeesDefault() {
  yield takeLatest(UPDATE_EXCEL_BULK_EMPLOYEES, updateExcelBulkEmployees);
}
export function* rateEmployeeDefault() {
  yield takeLatest(RATE_EMPLOYEE,rateEmployee);
}
export function* editEmployeeSharesDefault() {
  yield takeLatest(EDIT_EMPLOYEE_SHARES,editEmployeeShares);
}

export function* fetchPendingEmployeesDefault() {
  yield(takeLatest(FETCH_PENDING_EMPLOYEES, fetchPendingEmployees));
}

export function* fetchEmployeeSharesDefault() {
  yield takeLatest(FETCH_EMPLOYEE_SHARES,fetchEmployeeShares);
}
export function* addEmployeeSharesDefault() {
  yield takeLatest(ADD_EMPLOYEE_SHARES,addEmployeeShares);
}
export default function* rootSaga(){
  yield all([
    fork(fetchEmployeesDefault),
    fork(addEmployeeDefault),
    fork(updateEmployeeDefault),
    fork(deleteEmployeeDefault),
    fork(transferEmployeeDefault),
    fork(addBulkEmployeesDefault),
    fork(resetEmployeePasswordDefault),
    fork(sendCredentialsDefault),
    fork(inviteEmployeesDefault),
    fork(setEmployeeStatusDefault),
    fork(uploadEmployeeNoEditDefault),
    fork(uploadEmployeeEditDefault),
    fork(activateBulkEmployeesDefault),
    fork(bulkEditEmployeesDefault),
    fork(fetchDynamicOffDaysDefault),
    fork(addDynamicOffDaysDefault),
    fork(deleteDynamicOffDaysDefault),
    fork(bulkDeleteEmployeeDefault),
    fork(updateExcelBulkEmployeesDefault),
    fork(rateEmployeeDefault),
    fork(confirmEmployeeFromProbationDefault),
    fork(setEmployeeOnProbationDefault),
    fork(editEmployeeSharesDefault),
    fork(fetchPendingEmployeesDefault),
    fork(fetchEmployeeSharesDefault),
    fork(addEmployeeSharesDefault),
  ]);
}
