export const FETCH_SMS = "FETCH_SMS";
export const FETCH_SMS_SUCCESS = "FETCH_SMS_SUCCESS";
export const SHOW_SMS_CREATE_FORM = 'SHOW_SMS_CREATE_FORM';
export const HIDE_SMS_CREATE_FORM = 'HIDE_SMS_CREATE_FORM';
export const ADD_SMS = 'ADD_SMS';
export const ADD_SMS_SUCCESS = 'ADD_SMS_SUCCESS';
export const SHOW_SMS_RECIPIENTS = 'SHOW_SMS_RECIPIENTS';
export const HIDE_SMS_RECIPIENTS = 'HIDE_SMS_RECIPIENTS';
export const FETCH_SMS_RECIPIENTS = 'FETCH_SMS_RECIPIENTS';
export const FETCH_SMS_RECIPIENTS_SUCCESS = 'FETCH_SMS_RECIPIENTS_SUCCESS';


export const fetchSmsGenerator = (data=[])=>{
  return {
    type:FETCH_SMS,
    payload:data
  };
};

export const fetchSmsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_SMS_SUCCESS,
    payload:dataById
  }
};

export const showSmsCreateFormGenerator = ()=>{
  return {
    type:SHOW_SMS_CREATE_FORM
  };
};
export const hideSmsCreateFormGenerator = ()=>{
  return {
    type:HIDE_SMS_CREATE_FORM
  };
};
export const addSmsGenerator = (data)=>{
  return {
    type: ADD_SMS,
    payload: data
  }
};
export const showSmsRecipients = (data)=>{
  return {
    type:SHOW_SMS_RECIPIENTS,
    payload:data
  }
};
export const hideSmsRecipients = (data)=>{
  return {
    type:HIDE_SMS_RECIPIENTS,
    payload:data
  }
};
export const addSmsSuccessGenerator = (data=[])=>{
  return {
    type: ADD_SMS_SUCCESS,
    payload: data
  }
};
export const fetchSmsRecipientsGenerator = (data=[])=>{
  return {
    type:FETCH_SMS_RECIPIENTS,
    payload:data
  };
};

export const fetchSmsRecipientsSuccessGenerator = (data)=> {
  return {
    type:FETCH_SMS_RECIPIENTS_SUCCESS,
    payload:data
  }
};

