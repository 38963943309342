import {BULK_ALLOWANCE, BULK_ALLOWANCE_SUCCESS} from "./AllowanceActions";

export const FETCH_DEDUCTIONS = "FETCH_DEDUCTIONS";
export const FETCH_DEDUCTIONS_SUCCESS = "FETCH_DEDUCTIONS_SUCCESS";
export const FETCH_SAVINGS = "FETCH_SAVINGS";
export const FETCH_SAVINGS_SUCCESS = "FETCH_SAVINGS_SUCCESS";

export const SHOW_DEDUCTION_CREATE_FORM = 'SHOW_DEDUCTION_CREATE_FORM';
export const HIDE_DEDUCTION_CREATE_FORM = 'HIDE_DEDUCTION_CREATE_FORM';
export const ADD_DEDUCTION = 'ADD_DEDUCTION';
export const ADD_DEDUCTION_SUCCESS = 'ADD_DEDUCTION_SUCCESS';

export const SHOW_DEDUCTION_EDIT_FORM = 'SHOW_DEDUCTION_EDIT_FORM';
export const HIDE_DEDUCTION_EDIT_FORM = 'HIDE_DEDUCTION_EDIT_FORM';
export const EDIT_DEDUCTION = 'EDIT_DEDUCTION';
export const EDIT_DEDUCTION_SUCCESS = 'EDIT_DEDUCTION_SUCCESS';

export const SHOW_DEDUCTION_DELETE_FORM = 'SHOW_DEDUCTION_DELETE_FORM';
export const HIDE_DEDUCTION_DELETE_FORM = 'HIDE_DEDUCTION_DELETE_FORM';
export const DELETE_DEDUCTION = 'DELETE_DEDUCTION';
export const DELETE_DEDUCTION_SUCCESS = 'DELETE_DEDUCTION_SUCCESS';
export const BULK_DEDUCTION_SUCCESS = 'BULK_DEDUCTION_SUCCESS';
export const BULK_DEDUCTION = 'BULK_DEDUCTION';
export const CANCEL_DEDUCTION = "CANCEL_DEDUCTION";
export const CANCEL_DEDUCTION_SUCCESS = "CANCEL_DEDUCTION_SUCCESS";
export const BULK_DEACTIVATE_DEDUCTION = 'BULK_DEACTIVATE_DEDUCTION';

export const bulkDeactivateDeductionGenerator = (payload=[])=>{
    return {
        type:BULK_DEACTIVATE_DEDUCTION,
        payload
    }
}

export const fetchDeductionsGenerator = (data=[])=>{
    return {
        type:FETCH_DEDUCTIONS,
        payload:data
    };
};

export const fetchDeductionsSuccessGenerator = (dataById)=> {
    return {
        type:FETCH_DEDUCTIONS_SUCCESS,
        payload:dataById
    }
};
export const fetchSavingsGenerator = (data=[])=>{
    return {
        type:FETCH_SAVINGS,
        payload:data
    };
};

export const fetchSavingsSuccessGenerator = (dataById)=> {
    return {
        type:FETCH_SAVINGS_SUCCESS,
        payload:dataById
    }
};
export const showDeductionCreateFormGenerator = ()=>{
    return {
        type:SHOW_DEDUCTION_CREATE_FORM
    };
};
export const hideDeductionCreateFormGenerator = ()=>{
    return {
        type:HIDE_DEDUCTION_CREATE_FORM
    };
};
export const addDeductionGenerator = (data)=>{
    return {
        type: ADD_DEDUCTION,
        payload: data
    }
};

export const addDeductionSuccessGenerator = (data)=>{
    return {
        type: ADD_DEDUCTION_SUCCESS,
        payload: data
    }
};
export const showDeductionEditFormGenerator = (data)=>{
    return {
        type:SHOW_DEDUCTION_EDIT_FORM,
        payload:data
    };
};
export const hideDeductionEditFormGenerator = ()=>{
    return {
        type:HIDE_DEDUCTION_EDIT_FORM
    };
};
export const editDeductionGenerator = (data)=>{
    return {
        type: EDIT_DEDUCTION,
        payload: data
    }
};
export const editDeductionSuccessGenerator = (data)=>{
    return {
        type: EDIT_DEDUCTION_SUCCESS,
        payload: data
    }
};
export const showDeductionDeleteFormGenerator = (data)=>{
    return {
        type:SHOW_DEDUCTION_DELETE_FORM,
        payload:data
    };
};
export const hideDeductionDeleteFormGenerator = ()=>{
    return {
        type:HIDE_DEDUCTION_DELETE_FORM
    };
};
export const deleteDeductionGenerator = (data)=>{
    return {
        type: DELETE_DEDUCTION,
        payload: data
    }
};
export const cancelDeductionGenerator = (data)=>{
    return {
        type: CANCEL_DEDUCTION,
        payload: data
    }
};
export const cancelDeductionSuccessGenerator = (data)=>{
    return {
        type: CANCEL_DEDUCTION_SUCCESS,
        payload: data
    }
};
export const deleteDeductionSuccessGenerator = (data)=>{
    return {
        type: DELETE_DEDUCTION_SUCCESS,
        payload: data
    }
};
export const bulkDeductionGenerator = (data)=>{
    return {
        type: BULK_DEDUCTION,
        payload: data
    }
};

export const bulkDeductionSuccessGenerator = (data)=>{
    return {
        type: BULK_DEDUCTION_SUCCESS,
        payload: data
    }
};
