import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  addSubscriptionGenerator,
  fetchSubscriptionGenerator, renewSubscriptionGenerator, upgradeSubscriptionGenerator
} from "../../appRedux/actions/common/SubscriptionActions";
import { userSignOutGenerator, userSignOutSuccessGenerator } from "../../appRedux/actions/AuthActions";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Card, CardActions, CardContent } from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import UpdateIcon from '@material-ui/icons/Update';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import { CardHeader } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { Helpers } from "../../util/Helpers";
import BackGroundLoader from "../../components/common/BackGroundLoader";
import { REACT_APP_ON_BOARDING_API_ADDRESS } from "../../constants/ActionTypes";
import IntlMessages from "../../util/IntlMessages";

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  largerIcons: {
    fontSize:'70px'
  }
});

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfEmployees: this.props.noOfEmployees,
      planType: this.props.planType,
      planId: this.props.plan.id || '',
      amount: this.props.amount || 0 ,
      modalOpen: false,
      onClose:false,
      plan:this.props.plan || {},
      selected: Helpers.isNull(Helpers.activeSubscription(true)) ? "renew" : "upgrade",
      showAlert:false
    };
  }

  componentDidMount() {
    this.props.fetchSubscriptionGenerator();
  }

  updateState = (e) => {
    const {id, name, value} = e.target;
    let amount = this.props.plan.amount_per_user * value;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState['amount']=amount;
    newState[key] = value;
    this.setState({ ...newState });
  };

  handleChange = (event) => {
    this.setState({selected: event.target.value})
  };

  handleClickOpen = () => {
    this.setState({
      modalOpen: true
    });
  };

  handleClickRenew = () =>{
    if (Helpers.loading(this.props)){
      return;
    }
    let amount = parseFloat(this.props.plan.amount_per_user) * parseInt(this.props.company.number_of_employees);
    let balance = this.props.company.balance;
    if (balance < amount) {
      this.props.history.push({
        pathname: '/top-up',
        state: {
          amount_mpesa: amount - balance,
          mobile_mpesa: this.props.company.address.mobile_no ,
          page: 'first-topup' ,
        }
      });
    } else {
      this.props.renewSubscriptionGenerator();
    }
  }

  handleSubmit = ()=>{
    const {selected} = this.state;
    switch (selected) {
      case "upgrade":
        this.handleClickOpen();
        break;
      case "pick-plan":
        location.href = Helpers.choosePlanRedirect();
        break;
      case "renew":
        this.handleClickRenew();
        break;
    }
  };

  closeAlert = ()=>{
    const newState = Object.assign({}, this.state);
    newState["showAlert"]=false;
    this.setState({ ...newState });
  };

  handleUpgrade =()=>{
    if(this.state.noOfEmployees <= 0){
      this.setState({showAlert: true});
      return;
    }
    this.setState({showAlert: false});

    let balance = this.props.company.balance;
    if (balance < this.state.amount) {
      this.props.history.push({
        pathname: '/top-up',
        state: {
          amount_mpesa: this.state.amount - balance,
          mobile_mpesa: this.props.company.address.mobile_no ,
          page: 'first-topup',
          upgrading_employees:true,
          noOfEmployees: this.state.noOfEmployees
        }
      });
    } else {
      const request = {
        plan_id: this.props.plan.id,
        plan_type: 'monthly',
        employees: this.state.noOfEmployees
      };
      this.props.upgradeSubscriptionGenerator(request);
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  handleSignOut = () => {
    Helpers.clearAuthData();
    this.props.userSignOutGenerator();
  };

  render() {
    const { classes,history,company } = this.props;
    const { selected,noOfEmployees ,amount, showAlert} = this.state;
    const subscription = Helpers.activeSubscription(true);
    if (subscription && subscription.hasOwnProperty('status') && subscription.status==="UNPAID"){
      this.props.history.push({
        pathname: '/top-up',
        state: {
          amount_mpesa: subscription.amount_paid,
          mobile_mpesa: subscription.hasOwnProperty("company") ? subscription.company.address.mobile_no: '',
          page: 'first-topup' ,
        }
      });
    }

    return (<div className="app-subscription-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3" style={{ background:"transparent", boxShadow: "none"}}>
        <div className="app-login-main-content" style={{ background:"transparent", boxShadow: "none"}}>
          {
            Helpers.loading(this.props)
            && (
              <BackGroundLoader />
            )
          }
          <div className="row col-12">
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={this.state.modalOpen}
            >
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                Enter the number of employees you would like to pay for activation
              </DialogTitle>
              <DialogContent>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="noOfEmployees"
                    name="noOfEmployees"
                    value={noOfEmployees}
                    type="number"
                    label="No of employees"
                    fullWidth
                    margin="normal"
                    onChange={this.updateState}
                  />
                </FormControl>
                <span className="error text-danger">{showAlert && noOfEmployees <=0 ? "No of employees should not be less than 1" : ' '}</span>

                <p>The total amount to pay is <strong>{amount}</strong></p>
                <br/>
                <i>Your Wallet Account balance is <strong>{ company && company.hasOwnProperty("balance") ? company.balance : 0 }</strong> </i>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUpgrade}
                >
                  Pay
                </Button>
                <Button onClick={this.handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
            <Card style={{ width: 700}}>
              <CardHeader>
                <h2>Subscription</h2>
              </CardHeader>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <RadioGroup
                    aria-label="upgrade"
                    name="upgrade"
                    className={classes.group}
                    value={selected}
                    onChange={this.handleChange}
                  >
                    <div className="row col-xl-12 col-lg-12 col-md-12 col-12">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <Card className="text-center" style={{ padding: 10, minHeight: 160}}>

                          <div className="mb-2 text-secondary">
                            <LaunchIcon className={classes.largerIcons} />
                          </div>
                          <FormControlLabel
                            value={"pick-plan"}
                            control={<Radio color="primary" checked={selected==="pick-plan"} />}
                            label={"Pick "+((this.props.plan && this.props.plan.hasOwnProperty('plan_name')) ? 'another' : 'a')+" plan"}
                            labelPlacement="top"
                          />
                        </Card>
                      </div>
                      { (this.props.plan && this.props.plan.hasOwnProperty('plan_name')) && <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <Card className="text-center" style={{ padding: 10, minHeight: 160 }}>
                          <div className="mb-2 text-secondary">
                            <UpdateIcon className={classes.largerIcons}/>
                          </div>
                          <FormControlLabel
                            value={"upgrade"}
                            control={<Radio color="primary" checked={selected==="upgrade"} />}
                            label={"Add employees to " +` ${this.props.plan && this.props.plan.hasOwnProperty('plan_name') ? this.props.plan.plan_name : ''}`+" plan"}
                            labelPlacement="top"
                          />
                        </Card>
                      </div>}

                      { subscription && subscription.days_remaining <= 1 && <div className="col-xl-6 col-lg-6 col-md-6 col-12"> {/*one day remaining will show the renew button*/}
                       <br/>
                        <Card className="text-center" style={{ padding: 10, minHeight: 160 }}>
                          <div className="mb-2 text-secondary">
                            <AutorenewIcon className={classes.largerIcons} />
                          </div>
                          <FormControlLabel
                            value={"renew"}
                            control={<Radio color="primary" checked={selected==="renew"} />}
                            label={"Renew " +` ${this.props.plan && this.props.plan.hasOwnProperty('plan_name') ? this.props.plan.plan_name : 'the'}` +" plan"}
                            labelPlacement="top"
                          />
                        </Card>
                      </div> }
                    </div>
                  </RadioGroup>
                </FormControl>
              </CardContent>
              <CardActions className={"text-right"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ () => history.push('/home') }
                >
                  Go back home
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={"pull-right"}
                  onClick={this.handleSubmit}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={"pull-right"}
                  onClick={this.handleSignOut}
                >
                  Sign Out
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>);
  }
}

Subscription.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = ({subscription, general}) => {
  const {
    subscriptionByID, noOfEmployees, planType, packageCosts, planId, amount,plan,company
  } = subscription;
  const { appLoading } = general;
  return {
    subscriptionByID, noOfEmployees, planType, packageCosts, planId, amount, appLoading,plan,company
  };
};
export default withStyles(styles)(connect(mapStateToProps, {addSubscriptionGenerator, fetchSubscriptionGenerator, upgradeSubscriptionGenerator,userSignOutSuccessGenerator,renewSubscriptionGenerator,userSignOutGenerator})(Subscription));

