import {
  APPROVE_REJECT_BANK_TRANSFER_SUCCESS,
  FETCH_BANK_TRANSFER_SUCCESS
} from "../../actions/admin/BankTransferActions";

const INIT_STATE ={
  bankTransferByID:{}
}

export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_BANK_TRANSFER_SUCCESS:
      return{
        ...state,
        bankTransferByID: action.payload
      }
    case APPROVE_REJECT_BANK_TRANSFER_SUCCESS:
      let data = {...state.bankTransferByID};
      data[action.payload.id]=action.payload
      return {
        ...state,
        bankTransferByID: data
      }
    default:
      return state;
  }
}