import  { FETCH_SCHEDULE_REPORTS_SUCCESS } from "../../actions/attendance/SchedulesReportsActions";

const INIT_STATE = {
    schedulesReportsByID: []
};

export default(state = INIT_STATE,action)=> {
    switch (action.type) {
        case 'FETCH_SCHEDULE_REPORTS_SUCCESS':
            return {
                ...state,
                schedulesReportsByID: action.payload
            };
        default:
            return state;
    }
};
