import {all, call, fork, put, takeLatest} from "redux-saga/effects";

import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {ADD_ADMIN_HOLIDAY, addAdminHolidaySuccess, DELETE_ADMIN_HOLIDAY, fetchAdminHolidaysGenerator, fetchAdminHolidaySuccess,editAdminHolidaySuccess,EDIT_ADMIN_HOLIDAY,deleteAdminHolidaySuccess,FETCH_ADMIN_HOLIDAYS} from "../../actions/admin/AdminHolidayAction";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {BETA_API_ENDPOINT } from "../../../constants/ActionTypes";

/// add new
const addAdminHolidayRequest = async (requestParameters) =>
  await  axios.post(BETA_API_ENDPOINT+'/tozza/holidays',requestParameters)
    .then(response => response)
    .catch(error => error.response);
// delete
const deleteAdminHolidayRequest = async (requestParameters) =>
  await  axios.delete(BETA_API_ENDPOINT+'/tozza/holidays/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
///fetch
const fetchAdminHolidaysRequest = async (requestParameters) =>
  await  axios.get(BETA_API_ENDPOINT+'/tozza/holidays',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);
///update
const updateAdminHolidayRequest = async (requestParameters) =>
  await axios.put(BETA_API_ENDPOINT+'/tozza/holidays/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.message);

function* addAdminHoliday({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:0,from_v1:1};
    const response = yield call(addAdminHolidayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holiday = response.data.data;
        yield put(addAdminHolidaySuccess(holiday));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchAdminHolidays({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:0,from_v1:1};
    const response = yield call(fetchAdminHolidaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holidays = response.data.data;
        yield put(fetchAdminHolidaySuccess(holidays));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* updateAdminHoliday({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:0,from_v1:1};
    const response = yield call(updateAdminHolidayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAdminHolidaysGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteAdminHoliday({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:0,from_v1:1};
    const response = yield call(deleteAdminHolidayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holiday = response.data.data;
        yield put(deleteAdminHolidaySuccess(holiday));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchAdminHolidaysDefault() {
  yield takeLatest(FETCH_ADMIN_HOLIDAYS,fetchAdminHolidays)
}
export function* addAdminHolidayDefault() {
  yield takeLatest(ADD_ADMIN_HOLIDAY, addAdminHoliday);
}
export function* deleteAdminHolidayDefault() {
  yield takeLatest(DELETE_ADMIN_HOLIDAY,deleteAdminHoliday)
}
export function* updateAdminHolidayDefault() {
  yield takeLatest(EDIT_ADMIN_HOLIDAY,updateAdminHoliday)
}

export default function* rootSaga() {
  yield all([fork(addAdminHolidayDefault),fork(deleteAdminHolidayDefault),fork(fetchAdminHolidaysDefault),fork(updateAdminHolidayDefault)]);
}
