import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { forgotPasswordGenerator, userSignInGenerator } from "../appRedux/actions/AuthActions";
import { Helpers } from "../util/Helpers";
import BackGroundLoader from "../components/common/BackGroundLoader";
import {withStyles} from '@material-ui/core/styles';

const useStyles = theme => ({
  buttonProgress: {
    color: '#fff',
    marginRight: 5,
  },
  eye: {
    cursor: 'pointer',
  },

});
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      buttonLoading:false,
    };
  }

  render() {
    const {
      email,
      buttonLoading,
    } = this.state;
   const RestClicked = () => {
      this.props.forgotPasswordGenerator({
        email
      });
     this.setState({   buttonLoading:true});
    };
   const {classes} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          {
            Helpers.loading(this.props)
            && (
                <BackGroundLoader />
            )
          }

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="">
              <img src={require('assets/images/logo.png')} alt="" title="" style={{ height:"40px"}} />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-1 text-center text-muted">
              <p><IntlMessages id="appModule.forgotPasswordDesc" /></p>
              <p><IntlMessages id="appModule.resetPasswordDesc" /></p>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label="Email Address"
                    placeholder="Email Address"
                    fullWidth
                    onChange={event => this.setState({ email: event.target.value })}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      onClick={RestClicked}
                      variant="contained"
                      color="primary" size="small"
                      disabled={buttonLoading&&Helpers.loading(this.props)}>
                      {(Helpers.loading(this.props)&&buttonLoading)&&<CircularProgress size={24} className={classes.buttonProgress} />}
                      <IntlMessages id="appModule.forgotPasswordButton" />
                    </Button>

                    <Link to="/signin">
                      Sign In
                    </Link>
                  </div>
                  <div className="mb-3 d-flex align-items-center justify-content-end">
                    <a href="https://dashboard.myworkpay.com/signup">
                        <IntlMessages id="signIn.signUp" />
                      </a>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth, general}) => {
  const {alertMessage, showMessage,appLoading } = general;
  const {authUser} = auth;
  return {
    alertMessage,
    showMessage,
    authUser,
    appLoading
  };
};
export default withStyles(useStyles)( connect(mapStateToProps, {
  userSignInGenerator,
  forgotPasswordGenerator
})(ResetPassword));

