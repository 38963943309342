import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withRouter from 'react-router/es/withRouter';
import {Link} from 'react-router-dom';


const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 8,
  },
  title: {
    display: 'flex'
  },
});

class Headline extends React.Component {
  constructor(props) {
    super(props);
  }

  handleTransactionsLink = () => {
    this.props.history.push('/payouts/transaction-history');
  };

  handleBulkPayoutsLink = () => {
    this.props.history.push('/payouts/bulk-payouts');
  };

  render() {
    const { classes, headline, accountNo } = this.props;
    return (
      <div className={classes.title}>
        <Typography variant="h6" gutterBottom style={{ color: '#205926'}}>{headline}</Typography>
        <div className="list-inline ml-auto">
          <Button variant="contained" color="primary" style={{ borderRadius: 10, marginRight: 5, marginBottom: 5 }} size="small">
            <Link to="/payouts/banks" className="text-white" style={{ textDecoration: 'none'}}>Banks</Link></Button>
          <Button onClick={this.handleTransactionsLink} variant="contained" color="primary" style={{ borderRadius: 10, marginRight: 5, marginBottom: 5 }} size="small">Transactions</Button>
          <Button variant="contained" color="primary" style={{ borderRadius: 10, marginRight: 5, marginBottom: 5 }} size="small">
            Account No:
            {accountNo}
          </Button>
          <Button onClick={this.handleBulkPayoutsLink} variant="contained" color="primary" style={{ borderRadius: 10, marginBottom: 5 }} size="small">Bulk Payouts</Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Headline));
