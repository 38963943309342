export const FETCH_HELBS = "FETCH_HELBS";
export const CREATE_HELB = 'CREATE_HELB';
export const CREATE_HELB_SUCCESS = 'CREATE_HELB_SUCCESS';
export const FETCH_HELBS_SUCCESS ='FETCH_HELBS_SUCCESS';
export const EDIT_HELB_SUCCESS ='EDIT_HELB_SUCCESS';
export const EDIT_HELB ='EDIT_HELB';
export const DELETE_HELB ='DELETE_HELB';
export const DELETE_HELB_SUCCESS ='DELETE_HELB_SUCCESS';
export const BULK_HELBS ='Bulk_HELBS';
export const BULK_HELBS_SUCCESS ='BULK_HELBS_SUCCESS';
export const ACTIVE_DEACTIVATE_HELBS ='ACTIVE_DEACTIVATE_HELBS';

export const activateDeactivateHelbGenerator =(payload=[])=>{
  return {
    type:ACTIVE_DEACTIVATE_HELBS,
    payload
  }
}
export const fetchHelbGenerator = (data=[])=>{
  return {
    type:FETCH_HELBS,
    payload:data
  };
};

export const fetchHelbSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_HELBS_SUCCESS,
    payload:dataById
  }
};
export const createHelbGenerator = (payload)=> {
  return {
    type:CREATE_HELB,
    payload
  }
};
export const createHelbSuccessGenerator = (payload)=> {
  return {
    type:CREATE_HELB_SUCCESS,
    payload
  }
};

export const editHelbGenerator = (payload)=>{
  return{
    type:EDIT_HELB,
    payload
  }
}
export const editHelbSuccessGenerator = (payload)=>{
  return{
    type:EDIT_HELB_SUCCESS,
    payload
  }
}

export const deleteHelbGenerator =(payload)=>{
  return {
    type:DELETE_HELB,
    payload
  }
}
export const deleteHelbSuccessGenerator =(payload)=>{
  return {
    type:DELETE_HELB_SUCCESS,
    payload
  }
}
export const bulkHelbGenerator = (data)=>{
  return {
    type: BULK_HELBS,
    payload: data
  }
};

export const bulkHelbSuccessGenerator = (data)=>{
  return {
    type: BULK_HELBS_SUCCESS,
    payload: data
  }
};
