export const FETCH_FIREBASE_USERS = "FETCH_FIREBASE_USERS";
export const FETCH_FIREBASE_USERS_SUCCESS = "FETCH_FIREBASE_USERS_SUCCESS";
export const SEND_FIREBASE_BY_AUTH_VALUE = "SEND_FIREBASE_BY_AUTH_VALUE";
export const SEND_FIREBASE_BY_AUTH_VALUE_SUCCESS = "SEND_FIREBASE_BY_AUTH_VALUE_SUCCESS";

export const fetchFirebaseUsersRequestGenerator = (data=[])=>{
  return {
    type:FETCH_FIREBASE_USERS,
    payload:data
  }
};

export const fetchFirebaseUsersSuccessGenerator = data => {
  return {
    type: FETCH_FIREBASE_USERS_SUCCESS,
    payload: data
  };
};

export const sendFirebaseByAuthValueRequestGenerator = (data=[])=>{
  return {
    type:SEND_FIREBASE_BY_AUTH_VALUE,
    payload:data
  }
};

export const sendFirebaseByAuthValueSuccessGenerator = data => {
  return {
    type: SEND_FIREBASE_BY_AUTH_VALUE_SUCCESS,
    payload: data
  };
};