import {
  ADD_ASSET_CATEGORY_SUCCESS,
  DELETE_ASSET_CATEGORY_SUCCESS,
  EDIT_ASSET_CATEGORY_SUCCESS,
  FETCH_ASSETS_CATEGORIES_SUCCESS
} from "../../actions/payroll/AssetCategoryActions";

const INIT_STATE = {
  assetCategoriesByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_ASSETS_CATEGORIES_SUCCESS:
      return {
        ...state,
        assetCategoriesByID: action.payload
      };
    case ADD_ASSET_CATEGORY_SUCCESS:
    case EDIT_ASSET_CATEGORY_SUCCESS:
      let assets ={...state.assetCategoriesByID};
      assets[action.payload.id] = action.payload;
      return{
        ...state,
        assetCategoriesByID:assets
      };
    case DELETE_ASSET_CATEGORY_SUCCESS:
      let assetCategoriesByID = {...state.assetCategoriesByID};
      delete assetCategoriesByID[action.payload.id];
      return {
        ...state,
        assetCategoriesByID:assetCategoriesByID
      };
    default:
      return state;
  }
}
