import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import {
  API_ADDRESS,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION} from '../../constants/ActionTypes';
import {switchLanguage, toggleCollapsedNav} from '../../appRedux/actions/SettingActions';
import Menu from '../TopNav/Menu';
import UserInfo from '../../components/header/userProfile';
import AppSwitch from '../../components/header/appSwitch';
import Notifications from '../../components/header/notifications';
import CompanySwitch from '../../components/header/companySwitch';
import DaysLeft from '../../components/header/daysLeft';
import { Helpers } from '../../util/Helpers';
import {
  AUTH_HEAD_OFFICE_ID_KEY,
  AUTH_SWITCHED_APP,
  AUTH_SWITCHED_COMPANY_ID_KEY,
  AUTH_USER_KEY,
  SUBSCRIPTION_KEY
} from "../../constants/SessionKeys";
import CountrySwitch from '../../components/header/countrySwitch';

class Header extends React.Component {


  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    });
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };


  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
    };
  }

  render() {
    const {
      drawerType, navigationStyle, horizontalNavPosition
    } = this.props;
    const currentHeadOffice = Helpers.recordFromIndexedData(this.props.companiesByID,Helpers.authSwitchedCompanyID());
    const hasAllowedCompanies = this.props.companiesByID && Object.keys(this.props.companiesByID).count()>0;
    const atAdminPages = window.location.pathname.startsWith('/admin') || Helpers.getItem(AUTH_SWITCHED_APP) ==='admin';
    let subscription=[];
   try{
     subscription =Object.values(JSON.parse(Helpers.getItem(SUBSCRIPTION_KEY)))[0];
   }catch (e) {}
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    const avatar = Helpers.authAvatarSrc();
    const  is_eor_enabled = Helpers.checkSetting('eor_enabled');
    const is_staging = window.location.href.includes('staging');
    const beta_system_link =is_staging?'https://staging.myworkpay.com':(is_eor_enabled?'https://dashboard-v2.myworkpay.com':'https://dashboard.myworkpay.com');
    return (
      <AppBar
        className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION
            ? (
              <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                <span className="jr-menu-icon">
                  <span className="menu-icon" />
                </span>
              </div>
            )
            : (
              <IconButton
                className={`jr-menu-icon mr-3 ${drawerStyle}`}
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}>
                <span className="menu-icon bg-success" />
              </IconButton>
            )
          }

          <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            {
              Helpers.isNull(Helpers.authSwitchedCompanyID()) ? <img src={require('assets/images/logo.png')} className="company_logo" alt="Workpay" title="Workpay" style={{ height:"40px"}} />
              : Helpers.isNull(currentHeadOffice.logo) ?  <img src={require('assets/images/logo.png')} className="company_logo" alt="Workpay" title="Workpay" style={{ height:"40px"}} />
              :  <img src={API_ADDRESS+"/"+currentHeadOffice.logo} alt={currentHeadOffice.name} className="company_logo" title="Workpay" />
            }
          </Link>


          <Menu />

          <ul className="header-notifications list-inline ml-auto">
            {
              <Button onClick={ ()=> { window.open(beta_system_link+'/login?utm_source=legacy&utm_content='+Helpers.token()+'&company_id='+Helpers.authSwitchedCompanyID(), '_self'); }} variant="contained" size="small" color="primary" style={{ textTransform: 'none', marginTop: -10, borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:0, paddingBottom:0 }}>Go to Beta System</Button>
            }
            {
              (!atAdminPages && !Helpers.isNull(Helpers.authSwitchedCompanyID()) && Helpers.authUserHasPermissions())&&<li className="list-inline-item" style={{ marginRight: 20}}>
                <Hidden only={['sm', 'xs']}><DaysLeft daysLeft={subscription ? subscription.days_remaining :0}/></Hidden>
              </li>
            }
            {
              (!atAdminPages && Helpers.isSystemAdmin() )&&<li className="list-inline-item">
                <Button variant="raised" size="small" style={{ width: 20 }} onClick={()=>{
                  /*revert switched company to the users company*/
                  let company_id = Helpers.getItem(AUTH_USER_KEY).company_id;
                  if(company_id){
                    Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY,company_id);
                    Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY,company_id);
                  }
                  Helpers.saveItem(AUTH_SWITCHED_APP,'admin');
                }
                }>
                  <a style={{ textAlign: 'center',
                    textDecoration: 'none', fontSize: 10 }} href="/admin/companies" className="a-header font-weight-bold">
                    Admin Panel
                  </a>
                </Button>
              </li>
            }

            <li className="list-inline-item">
              <CountrySwitch />
            </li>

            {
              (hasAllowedCompanies)
              && (
              <li className="list-inline-item">
                <Hidden only={['sm', 'xs']}><CompanySwitch /></Hidden>
              </li>
              )
            }

            {
              (hasAllowedCompanies && Helpers.authUserHasPermissions())
              && (
              <li className="list-inline-item">
                <AppSwitch />
              </li>
              )
            }

            {
              (!atAdminPages && !Helpers.isNull(Helpers.authSwitchedCompanyID()) && Helpers.authUserHasPermissions())
              && (
              <li className="list-inline-item">
                <Notifications />
              </li>
              )
            }


            {navigationStyle === HORIZONTAL_NAVIGATION
            && (
            <li className="list-inline-item user-nav">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.userInfo}
                toggle={this.onUserInfoSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn size-30">
                    <Avatar
                      alt="..."
                      src={ Helpers.imageExist(avatar) ? avatar : API_ADDRESS+'/images/icons/flic/png/avatar.png'}
                      className="size-30"
                    />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <UserInfo />
                </DropdownMenu>
              </Dropdown>
            </li>
            )}
          </ul>

          <div className="ellipse-shape" />
        </Toolbar>
      </AppBar>
    );
  }

}


const mapStateToProps = ({settings,company}) => {
  const {
    drawerType, locale, navigationStyle, horizontalNavPosition
  } = settings;
  const {companiesByID} = company;
  return {
    drawerType, locale, navigationStyle, horizontalNavPosition,companiesByID
  };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header));
