import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {
  ADD_DESIGNATION,
  addDesignationSuccessGenerator, DELETE_DESIGNATION,
  deleteDesignationSuccessGenerator,
  EDIT_DESIGNATION,
  editDesignationSuccessGenerator,
  FETCH_DESIGNATIONS,
  fetchDesignationSuccessGenerator
} from "../../actions/common/DesignationActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";


///fetch designations
const fetchDesignationRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/designations',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);

function* fetchDesignation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDesignationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let designation = response.data.data;
        yield put(fetchDesignationSuccessGenerator(designation));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchDesignationDefault() {
  yield takeLatest(FETCH_DESIGNATIONS,fetchDesignation)
}
////end fetch designations
////create designations
const addDepartmentRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/setup/designations',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* addDesignation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addDepartmentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let designation = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(addDesignationSuccessGenerator(designation));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDesignationDefault() {
  yield takeLatest(ADD_DESIGNATION,addDesignation);
}
///end add designations
///edit designation
const editDesignationRequest = async (requestParameters)=>
  await axios.put(API_ENDPOINT+'/setup/designations/'+requestParameters.id,requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* updateDesignation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editDesignationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let designations = response.data.data;
        yield put(editDesignationSuccessGenerator(designations));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateDesignationDefault() {
  yield takeLatest(EDIT_DESIGNATION,updateDesignation)
}
///end update department
/// start delete department
const deleteDesignationRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/setup/designations/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* deleteDesignation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDesignationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let designation = response.data.data;
        yield put(deleteDesignationSuccessGenerator(designation));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* deleteDesignationDefault() {
  yield takeLatest(DELETE_DESIGNATION,deleteDesignation)
}
export default function* rootSagas(){
  yield all([fork(fetchDesignationDefault),
    fork(addDesignationDefault),
    fork(updateDesignationDefault),
    fork(deleteDesignationDefault),
  ])
}
