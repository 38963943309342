import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import {
  DELETE_SYSTEM_USERS, deleteSystemUsersSuccessGenerator, EXPORT_SYSTEM_USERS,
  FETCH_SYSTEM_USERS, fetchSystemUsersGenerator,
  fetchSystemUsersSuccessGenerator
} from "../../actions/admin/SystemUsersActions";

const fetchSystemUsersRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/system-users',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const deleteSystemUsersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/delete-system-users',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const exportSystemUsersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/export-system-users',requestParameters)
    .then(response => response)
    .catch(error => error.response);


function* fetchSystemUsers({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchSystemUsersRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let users = response.data.data;
        yield put(fetchSystemUsersSuccessGenerator(users));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteSystemUsers({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteSystemUsersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchSystemUsersGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* exportSystemUsers({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportSystemUsersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchSystemUsersDefault() {
  yield takeLatest(FETCH_SYSTEM_USERS, fetchSystemUsers)
}

export function* deleteSystemUsersDefault() {
  yield takeLatest(DELETE_SYSTEM_USERS, deleteSystemUsers);
}

export function* exportSystemUsersDefault() {
  yield takeLatest(EXPORT_SYSTEM_USERS, exportSystemUsers);
}


export default function* rootSaga() {
  yield all([fork(fetchSystemUsersDefault),
    fork(deleteSystemUsersDefault),
    fork(exportSystemUsersDefault)
  ]);
}
