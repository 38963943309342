import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
  ADD_BULK_RECIPIENTS,
  ADD_FAVOURITE_RECIPIENT,
  ADD_RECIPIENT,
  CHANGE_FEE_POLICY,
  DELETE_RECIPIENT,
  deleteRecipientSuccessGenerator,
  EDIT_RECIPIENT,
  editRecipientSuccessGenerator,
  FETCH_FAVOURITE_RECIPIENTS,
  FETCH_ONGOING_BULK_TRANSACTIONS,
  FETCH_RECIPIENTS,
  fetchFavouriteRecipientsSuccessGenerator,
  fetchOngoingBulkTransactionsGenerator,
  fetchOngoingBulkTransactionsSuccessGenerator,
  fetchRecipientsGenerator,
  fetchRecipientsSuccessGenerator,
  RESEND_PIN,
  SEND_BULK_PAYOUT,
  sendBulkPayoutSuccessGenerator,
  VERIFY_TRANSACTION,
  verifyTransactionSuccessGenerator
} from "../../actions/payouts/BulkPayoutActions";
import { push } from 'connected-react-router';
import { SELECTED_TRANSACTION } from "../../../constants/SessionKeys";

const fetchRecipientsRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/make/payment',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchOngoingBulkTransactionsRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/ongoing/bulk-transactions',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchFavouriteRecipientsRequest= async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/home/make/payment/get-favourites',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const resendPinRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/payout/pin/'+requestParameters.payment_cache_id+'/'+requestParameters.amount+'/pin/resend',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addFavouriteRecipientRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/make/payment/add/recipient',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const addRecipientRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/add/user/payouts',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const sendBulkPayoutRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/send/bulk/payout/all',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteRecipientRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/user/cache/delete',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const addBulkRecipientRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/update/import/cache/users',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const editRecipientRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/update/users/cache',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const verifyTransactionRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/payout/pin/verify',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const changeFeePolicyRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/make/payment/edit/transaction_fee',requestParameters)
    .then(response => response)
    .catch(error => error.response);
function* fetchRecipients({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchRecipientsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchOngoingBulkTransactions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchOngoingBulkTransactionsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOngoingBulkTransactionsSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchFavouriteRecipients({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchFavouriteRecipientsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchFavouriteRecipientsSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message, 'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* resendPin({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(resendPinRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addRecipient({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addRecipientRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsGenerator({cache_type:payload.cache_type||'BULK'}));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addFavouriteRecipient({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addFavouriteRecipientRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsGenerator({cache_type:payload.cache_type||'BULK'}));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator());
  yield put(hideAppLoaderGenerator(type));
}
function* addBulkRecipient({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    const response = yield call(addBulkRecipientRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsGenerator({cache_type:payload.cache_type||'BULK'}));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* changeFeePolicy({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(changeFeePolicyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsGenerator({cache_type:payload.cache_type||'BULK'}));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteRecipient({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteRecipientRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {

        if (requestParameters.multiple){
          yield put(fetchRecipientsGenerator({cache_type:payload.cache_type||'BULK'}));
        } else{
          yield put(deleteRecipientSuccessGenerator(response.data.data));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editRecipient({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editRecipientRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsGenerator({cache_type:payload.cache_type||'BULK'}));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* verifyTransaction({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(verifyTransactionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(verifyTransactionSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchOngoingBulkTransactionsGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* sendBulkPayout({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendBulkPayoutRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(sendBulkPayoutSuccessGenerator(response.data.data.tozza_transaction_id));
        yield put(showMessageGenerator(response.data.message,'success'));
        Helpers.saveItem(SELECTED_TRANSACTION, JSON.stringify(response.data.data));
        const data ={fromHistory:true};
        yield put(push('/payouts/transaction-details',data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchRecipientsDefault() {
  yield takeLatest(FETCH_RECIPIENTS, fetchRecipients);
}
export function* fetchOngoingBulkTransactionsDefault() {
  yield takeLatest(FETCH_ONGOING_BULK_TRANSACTIONS, fetchOngoingBulkTransactions);
}
export function* addRecipientDefault() {
  yield takeLatest(ADD_RECIPIENT, addRecipient);
}
export function* addBulkRecipientDefault() {
  yield takeLatest(ADD_BULK_RECIPIENTS, addBulkRecipient);
}
export function* deleteRecipientDefault() {
  yield takeLatest(DELETE_RECIPIENT, deleteRecipient);
}
export function* verifyTransactionDefault() {
  yield  takeLatest(VERIFY_TRANSACTION,verifyTransaction);
}
export function* sendBulkPayoutDefault() {
  yield  takeLatest(SEND_BULK_PAYOUT,sendBulkPayout);
}
export function* changeFeePolicyDefault() {
  yield takeLatest(CHANGE_FEE_POLICY, changeFeePolicy);
}
export function* editRecipientDefault() {
  yield takeLatest(EDIT_RECIPIENT, editRecipient);
}export function* resendPinDefault() {
  yield takeLatest(RESEND_PIN, resendPin);
}
export function* fetchFavouriteDefault() {
   yield takeLatest(FETCH_FAVOURITE_RECIPIENTS,fetchFavouriteRecipients) ;
}
export function* addFavouriteDefault() {
   yield takeLatest(ADD_FAVOURITE_RECIPIENT,addFavouriteRecipient) ;
}
export default function* rootSaga() {
  yield all([
    fork(fetchOngoingBulkTransactionsDefault),
    fork(fetchRecipientsDefault),
    fork(addRecipientDefault),
    fork(addBulkRecipientDefault),
    fork(deleteRecipientDefault),
    fork(editRecipientDefault),
    fork(changeFeePolicyDefault),
    fork(verifyTransactionDefault),
    fork(sendBulkPayoutDefault),
    fork(resendPinDefault),
    fork(fetchFavouriteDefault),
    fork(addFavouriteDefault),
  ]);
}
