import {
    FETCH_COMPANIES_WITH_APPRAISAL_SUCCESS
} from "../../actions/admin/AppraisalActions";


const INIT_STATE = {
  companyWithAppraisalByID:[]
};

export default (state=INIT_STATE,action) => {
    switch (action.type) {
        case FETCH_COMPANIES_WITH_APPRAISAL_SUCCESS:
            return {
                ...state,
                companyWithAppraisalByID: action.payload
            };
        default: return {...state};
    }
}