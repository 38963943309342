import {
    FETCH_EXCHANGE_RATES_SUCCESS,
    ADD_EXCHANGE_RATES_SUCCESS,
    UPDATE_EXCHANGE_RATES_SUCCESS,
    DELETE_EXCHANGE_RATES_SUCCESS
  } from "../../actions/admin/ExchangeRatesActions";
  
  const INIT_STATE = {
    exchangeRates:{},
  };
  
  export default (state=INIT_STATE,action)=>{
    switch (action.type) {
      case FETCH_EXCHANGE_RATES_SUCCESS:
        return {
          ...state,
          exchangeRates: action.payload
        };
      case ADD_EXCHANGE_RATES_SUCCESS:
      case UPDATE_EXCHANGE_RATES_SUCCESS:
        let id = action.payload.id;
        return {
          ...state,
          exchangeRates: {...state.exchangeRates, [id]:action.payload}
        };
      case DELETE_EXCHANGE_RATES_SUCCESS:
        let exchangeRates = {...state.exchangeRates};
        delete exchangeRates[action.payload.id];
        return {
          ...state,
          exchangeRates: exchangeRates
        };

      default:
        return state;
    }
  }