import {
  ADD_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_SUCCESS,
  EDIT_DESIGNATION_SUCCESS,
  FETCH_DESIGNATION_SUCCESS,
  HIDE_CREATE_DESIGNATION_FORM,
  HIDE_DELETE_DESIGNATION_MODAL,
  HIDE_EDIT_DESIGNATION_FORM,
  SHOW_CREATE_DESIGNATION_FORM,
  SHOW_DELETE_DESIGNATION_MODAL,
  SHOW_EDIT_DESIGNATION_FORM
} from '../../actions/common/DesignationActions';


const INIT_STATE = {
  designationsByID:[],
  designationToEdit:[],
  showCreateDesignationForm:false,
  showEditDesignationForm:false,
  showDeleteDesignationModal:false
};

export default (state = INIT_STATE, action)=>{
  let designations;
  switch (action.type){
    case ADD_DESIGNATION_SUCCESS:
    case EDIT_DESIGNATION_SUCCESS:
      designations = {...state}.designationsByID;
      designations[action.payload.id]=action.payload;
      return {
        ...state,
        designationsByID:designations
      };
    case FETCH_DESIGNATION_SUCCESS:
      return {
        ...state,
        designationsByID:action.payload
      };
    case SHOW_CREATE_DESIGNATION_FORM:
      return {
        ...state,
        showCreateDesignationForm:true
      };
    case HIDE_CREATE_DESIGNATION_FORM:
      return {
        ...state,
        showCreateDesignationForm:false
      };
    case SHOW_EDIT_DESIGNATION_FORM:
      return {
        ...state,
        designationToEdit:action.payload,
        showEditDesignationForm:true
      };
    case HIDE_EDIT_DESIGNATION_FORM:
      return {
        ...state,
        showEditDesignationForm:false
      };
    case SHOW_DELETE_DESIGNATION_MODAL:
      return {
        ...state,
        designationToEdit:action.payload,
        showDeleteDesignationModal:true
      };
    case HIDE_DELETE_DESIGNATION_MODAL:
      return {
        ...state,
        showDeleteDesignationModal:false
      };
    case DELETE_DESIGNATION_SUCCESS:
      designations = {...state}.designationsByID;
      delete designations[action.payload.id]
      return {
        ...state,
        designationsByID:designations,
      };
    default:
      return {
        ...state
      };
  }
}
