import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import {
  addQueuedReportSuccessGenerator, APPROVE_ATTENDANCE_REPORT,
  APPROVE_DAILY_OVERTIME,
  approveDailyOvertimeSuccessGenerator,
  DELETE_QUEUED_REPORT,
  deleteQueuedReportSuccessGenerator,
  EXPORT_ATTENDANCE_REPORT,
  FETCH_ATTENDANCE_NOT_REPORTED_REPORT,
  FETCH_ATTENDANCE_ON_DAY_OFF_REPORT,
  FETCH_ATTENDANCE_ON_LEAVE_REPORT,
  FETCH_ATTENDANCE_PRESENT_REPORT,
  FETCH_GUARD_REGISTER_REPORT,
  FETCH_GUARD_SCHEDULE_REPORT,
  FETCH_HOURS_AND_WAGES_REPORT,
  FETCH_HOURS_REPORT,
  FETCH_QUEUED_REPORTS,
  FETCH_WAGES_REPORT,
  fetchAttendanceReportSuccessGenerator,
  fetchGuardRegisterReportGenerator,
  fetchGuardRegisterSuccessReportGenerator,
  fetchGuardScheduleReportSuccessGenerator,
  fetchHoursAndWagesReportGenerator,
  fetchHoursOrWagesReportSuccessGenerator,
  fetchHoursReportGenerator, fetchQueuedReportsGenerator,
  fetchQueuedReportsSuccessGenerator,
  fetchWagesReportGenerator,
  GENERATE_ATTENDANCE_REPORT,
  GENERATE_GUARD_REGISTER_REPORT, queuedReportRefreshedSuccessGenerator
} from "../../actions/attendance/AttendanceReportActions";
import { push } from 'connected-react-router';

const fetchQueuedReportsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/queued-reports',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const deleteQueuedReportRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/queued-report/delete/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const guardScheduleReportRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/report/guard/scheduling',{params:requestParameters})
    .then(response =>response).catch(error => error.response);
const generateReportRequest= async (requestParameters) => {
   let path = '';
   let action = requestParameters.action;
   switch (action) {
     case 'generate_hours_report':
       path = '/hours';
       break;
     case 'generate_wages_report':
       path = '/wages';
       break;
     case 'generate_hours_and_wages_report':
       path = '/hours-and-wages';
       break;
     case 'generate_attendance_report':
       path = '/attendance';
       break;
     case 'generate_guard_attendance_report':
       path = '/attendance';
       break;
   }
   return await  axios.post(API_ENDPOINT+'/attendance/report'+path,requestParameters)
     .then(response => response)
     .catch(error => error.response)
};
const exportAttendanceReportRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/report/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const generateGuardRegisterReportRequest =async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/guards-register-report',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);
const approveDailyOvertimeRequest =async (requestParameters)=>
  await  axios.post(API_ENDPOINT+'/attendance/overtime-approvals',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const approveAttendanceReportRequest =async (requestParameters)=>
  await  axios.post(API_ENDPOINT+'/attendance/report/approve',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchGuardRegisterReportRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/queued-report/view/'+requestParameters.id,{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);
const fetchAttendanceReportRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/queued-report/view/'+requestParameters.id,{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);
const fetchHoursOrWagesReportRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/queued-report/view/'+requestParameters.id,{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

function* exportAttendanceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportAttendanceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        if(response.data.data.queuedReport){
           yield put(queuedReportRefreshedSuccessGenerator(response.data.data.queuedReport));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchGuardRegister({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchGuardRegisterReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let guard_register = response.data.data;
        yield put(fetchGuardRegisterSuccessReportGenerator(guard_register));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* generateGuardRegisterReport({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(generateGuardRegisterReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let queued_report_data = response.data.data;
        if (queued_report_data.report_type !== 'GUARD_REGISTER'){
          yield put(addQueuedReportSuccessGenerator(queued_report_data));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
        if(!payload.hasOwnProperty("page_from") || payload.page_from !== "overtime"){
          yield put(push('/attendance/queued-reports'));
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* approveDailyOvertime({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(approveDailyOvertimeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));

        if(payload.report_id){
          switch (payload.report_type) {
            case "GUARD_REGISTER":
              yield put(fetchGuardRegisterReportGenerator({id:payload.report_id}));
              break;
            case "HOURS":
              yield put(fetchHoursReportGenerator({id:payload.report_id}));
              break;
            case "WAGES":
              yield put(fetchWagesReportGenerator({id:payload.report_id}));
              break;
            case "HOURS_AND_WAGES":
              yield put(fetchHoursAndWagesReportGenerator({id:payload.report_id}));
              break;
            default:
              break;
          }
        }

      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* approveAttendanceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(approveAttendanceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchQueuedReportsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
        //yield put(push('/'+requestParameters.module+'/queued-reports'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* generateReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(generateReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let queued_report_data = response.data.data;
        yield put(fetchQueuedReportsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(push('/'+requestParameters.module+'/queued-reports'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchQueuedReports({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchQueuedReportsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let queued_report_data = response.data.data;
        yield put(fetchQueuedReportsSuccessGenerator(queued_report_data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchAttendanceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAttendanceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchAttendanceReportSuccessGenerator(data));
      } else {
        //yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchHoursOrWagesReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchHoursOrWagesReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchHoursOrWagesReportSuccessGenerator(data));
      } else {
        //yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteQueuedReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteQueuedReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let queued_report_data = response.data.data;
        yield put(fetchQueuedReportsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* guardSchedulingReport({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(guardScheduleReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchGuardScheduleReportSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchGuardRegisterDefault() {
 yield takeLatest(FETCH_GUARD_REGISTER_REPORT,fetchGuardRegister);
}
export function* generateReportDefault() {
  yield takeLatest(GENERATE_ATTENDANCE_REPORT,generateReport);
}
export function* fetchQueuedReportsDefault() {
  yield takeLatest(FETCH_QUEUED_REPORTS,fetchQueuedReports);
}
export function* deleteQueuedReportDefault() {
  yield takeLatest(DELETE_QUEUED_REPORT,deleteQueuedReport);
}
export function* guardScheduleReportDefault() {
  yield  takeLatest(FETCH_GUARD_SCHEDULE_REPORT,guardSchedulingReport)
}
export function* generateGuardRegisterReportDefault(){
  yield takeLatest(GENERATE_GUARD_REGISTER_REPORT,generateGuardRegisterReport);
}
export function* exportAttendanceReportDefault() {
  yield takeLatest(EXPORT_ATTENDANCE_REPORT, exportAttendanceReport);
}
export function* fetchAttendancePresentReportDefault() {
  yield takeLatest(FETCH_ATTENDANCE_PRESENT_REPORT,fetchAttendanceReport);
}
export function* fetchAttendanceNotReportedReportDefault() {
  yield takeLatest(FETCH_ATTENDANCE_NOT_REPORTED_REPORT,fetchAttendanceReport);
}
export function* fetchAttendanceOnDayOffReportDefault() {
  yield takeLatest(FETCH_ATTENDANCE_ON_DAY_OFF_REPORT,fetchAttendanceReport);
}
export function* fetchAttendanceOnLeaveReportDefault() {
  yield takeLatest(FETCH_ATTENDANCE_ON_LEAVE_REPORT,fetchAttendanceReport);
}
export function* fetchHoursReportDefault() {
  yield takeLatest(FETCH_HOURS_REPORT,fetchHoursOrWagesReport);
}
export function* fetchWagesReportDefault() {
  yield takeLatest(FETCH_WAGES_REPORT,fetchHoursOrWagesReport);
}
export function* fetchHoursAndWagesReportDefault() {
  yield takeLatest(FETCH_HOURS_AND_WAGES_REPORT,fetchHoursOrWagesReport);
}
export function* approveDailyOvertimeDefault(){
  yield takeLatest(APPROVE_DAILY_OVERTIME, approveDailyOvertime);
}
export function* approveAttendanceReportDefault(){
  yield takeLatest(APPROVE_ATTENDANCE_REPORT, approveAttendanceReport);
}
export default function* rootSaga() {
  yield all([
    fork(generateReportDefault),
    fork(fetchQueuedReportsDefault),
    fork(deleteQueuedReportDefault),
    fork(guardScheduleReportDefault),
    fork(generateGuardRegisterReportDefault),
    fork(fetchGuardRegisterDefault),
    fork(exportAttendanceReportDefault),
    fork(fetchAttendancePresentReportDefault),
    fork(fetchAttendanceNotReportedReportDefault),
    fork(fetchAttendanceOnDayOffReportDefault),
    fork(fetchAttendanceOnLeaveReportDefault),
    fork(fetchHoursReportDefault),
    fork(fetchWagesReportDefault),
    fork(fetchHoursAndWagesReportDefault),
    fork(approveDailyOvertimeDefault),
    fork(approveAttendanceReportDefault)
  ])
}
