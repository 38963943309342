import {
  ADD_SCHEDULE_GROUP_SHIFT_SUCCESS,
  ADD_SCHEDULE_SUCCESS_GROUP, ASSIGN_EMPLOYEES_SUCCESS, DEASSIGN_EMPLOYEES_SUCCESS, DELETE_SCHEDULE_GROUP_SHIFT_SUCCESS,
  DELETE_SCHEDULES_SUCCESS_GROUP,
  EDIT_SCHEDULE_GROUP_SHIFT_SUCCESS, FETCH_SCHEDULE_GROUP_EMPLOYEES_SUCCESS,
  FETCH_SCHEDULE_GROUP_SHIFTS_SUCCESS,
  FETCH_SCHEDULES_GROUP_SUCCESS,
  FETCH_SCHEDULES_SUCCESS, FETCH_SHIFT_WORKING_HOURS_SUCCESS,
  FETCH_SHIFTS_SUCCESS
} from "../../actions/attendance/SchedulesActions";

const INIT_STATE ={
  schedulesGroupsByID : [],
  shiftsByID:[],
  schedulesByID:[],
  scheduleGroupShiftByID:[],
  globalScheduleGroupShiftsByID:{},
  scheduleGroupEmployeesByID:[],
  shiftWorkingHours:[]
};

export default(state= INIT_STATE,action)=>{
   switch (action.type){
     case 'FETCH_SCHEDULES_GROUP_SUCCESS':
       return {
         ...state,
         schedulesGroupsByID:action.payload
       };
     case 'FETCH_SHIFTS_SUCCESS':
       return {
         ...state,
         shiftsByID:action.payload
       };
     case 'EDIT_SCHEDULE_SUCCESS_GROUP':
     case 'ADD_SCHEDULE_SUCCESS_GROUP':
       let scheduleGroup_id = action.payload.id;
       return {
         ...state,
         schedulesGroupsByID: {
           ...state.schedulesGroupsByID,
           [scheduleGroup_id]:action.payload
         }
       };
     case 'FETCH_SCHEDULES_SUCCESS':
       return {
         ...state,
         schedulesByID:action.payload
       };
     case 'DELETE_SCHEDULES_SUCCESS_GROUP':
       let scheduleGroups = {...state.schedulesGroupsByID};
       delete scheduleGroups[action.payload.id];
       return {
         ...state,
         schedulesGroupsByID:scheduleGroups
       };
     case 'FETCH_SCHEDULE_GROUP_SHIFTS_SUCCESS':
       return {
         ...state,
         scheduleGroupShiftByID:action.payload
       };
     case 'FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS_SUCCESS':
       return {
         ...state,
         globalScheduleGroupShiftsByID:action.payload
       };
     case 'ADD_SCHEDULE_GROUP_SHIFT_SUCCESS':
     case 'EDIT_SCHEDULE_GROUP_SHIFT_SUCCESS':
       let scheduleGroupShift_id = action.payload.id;
       return {
         ...state,
         globalScheduleGroupShiftsByID: {
           ...state.globalScheduleGroupShiftsByID,
           [scheduleGroupShift_id]:action.payload
         }
       };
     case 'DELETE_SCHEDULE_GROUP_SHIFT_SUCCESS':
       let globalScheduleGroupShiftsByID = {...state.globalScheduleGroupShiftsByID};
       delete globalScheduleGroupShiftsByID[action.payload.id];
       return {
         ...state,
         globalScheduleGroupShiftsByID:globalScheduleGroupShiftsByID
       };
     case 'FETCH_SCHEDULE_GROUP_EMPLOYEES_SUCCESS':
       return {
         ...state,
         scheduleGroupEmployeesByID:action.payload
       };
     case 'ASSIGN_EMPLOYEES_SUCCESS':
       let scheduleGroupEmployee_id = action.payload.id;
       return {
         ...state,
         scheduleGroupEmployeesByID: {
           ...state.scheduleGroupEmployeesByID,
           [scheduleGroupEmployee_id]:action.payload
         }
       };
     case 'DEASSIGN_EMPLOYEES_SUCCESS':
       let scheduleGroupEmployee = {...state.scheduleGroupEmployeesByID};
       delete scheduleGroupEmployee[action.payload.id];
       return {
         ...state,
         scheduleGroupEmployeesByID:scheduleGroupEmployee
       };
     case FETCH_SHIFT_WORKING_HOURS_SUCCESS:
       return {
         ...state,
         shiftWorkingHours:action.payload
       };
     default: return state;
   }
};
