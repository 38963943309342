export const FETCH_MARKET_LENDING_RATES = 'FETCH_MARKET_LENDING_RATES';
export const FETCH_MARKET_LENDING_RATES_SUCCESS = 'FETCH_MARKET_LENDING_RATES_SUCCESS';
export const CREATE_MARKET_LENDING_RATES = 'CREATE_MARKET_LENDING_RATES';
export const CREATE_MARKET_LENDING_RATES_SUCCESS = 'CREATE_MARKET_LENDING_RATES_SUCCESS';
export const EDIT_MARKET_LENDING_RATES = 'EDIT_MARKET_LENDING_RATES';
export const EDIT_MARKET_LENDING_RATES_SUCCESS = 'EDIT_MARKET_LENDING_RATES_SUCCESS';
export const DELETE_MARKET_LENDING_RATES = 'DELETE_MARKET_LENDING_RATES';
export const DELETE_MARKET_LENDING_RATES_SUCCESS = 'DELETE_MARKET_LENDING_RATES_SUCCESS';

export const fetchMarketLendingRatesGenerator = (payload=[])=>{
  return {
    type:FETCH_MARKET_LENDING_RATES,
    payload
  }
};
export const fetchMarketLendingRatesSuccessGenerator = (payload=[])=>{
  return {
    type:FETCH_MARKET_LENDING_RATES_SUCCESS,
    payload
  }
};
export const createMarketLendingRatesGenerator = (payload=[])=>{
  return {
    type:CREATE_MARKET_LENDING_RATES,
    payload
  }
};
export const createMarketLendingRatesSuccessGenerator = (payload=[])=>{
  return {
    type:CREATE_MARKET_LENDING_RATES_SUCCESS,
    payload
  }
};
export const editMarketLendingRatesGenerator = (payload=[])=>{
  return {
    type:EDIT_MARKET_LENDING_RATES,
    payload
  }
};
export const editMarketLendingRatesSuccessGenerator = (payload=[])=>{
  return {
    type:EDIT_MARKET_LENDING_RATES_SUCCESS,
    payload
  }
}
export const deleteMarketLendingRatesGenerator = (payload=[])=>{
  return {
    type:DELETE_MARKET_LENDING_RATES,
    payload
  }
};
export const deleteMarketLendingRatesSuccessGenerator = (payload=[])=>{
  return {
    type:DELETE_MARKET_LENDING_RATES_SUCCESS,
    payload
  }
}
