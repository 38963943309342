import {
  ADD_ASSET_SUCCESS,
  DELETE_ASSET_SUCCESS,
  EDIT_ASSET_SUCCESS,
  FETCH_ASSETS_SUCCESS,
  HIDE_ASSET_CREATE_FORM,
  HIDE_ASSET_DELETE_FORM,
  HIDE_ASSET_EDIT_FORM,
  SHOW_ASSET_CREATE_FORM,
  SHOW_ASSET_DELETE_FORM,
  SHOW_ASSET_EDIT_FORM
} from '../../actions/payroll/AssetActions';

const INIT_STATE = {
  assetByID:{},
  showAssetCreateForm:false,
  showAssetEditForm:false,
  assetToEdit:false,
  showAssetDeleteForm:false,
  assetToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        assetByID: action.payload
      };
    case SHOW_ASSET_CREATE_FORM:
      return {
        ...state,
        showAssetCreateForm: true
      };
    case HIDE_ASSET_CREATE_FORM:
      return {
        ...state,
        showAssetCreateForm: false
      };
    case SHOW_ASSET_EDIT_FORM:
      return {
        ...state,
        showAssetEditForm: true,
        assetToEdit: action.payload
      };
    case HIDE_ASSET_EDIT_FORM:
      return {
        ...state,
        showAssetEditForm: false
      };
    case ADD_ASSET_SUCCESS:
    case EDIT_ASSET_SUCCESS:
      let asset_id = action.payload.id;
      return {
        ...state,
        assetByID: {
          ...state.assetByID,
          [asset_id]:action.payload
        }
      };
    case SHOW_ASSET_DELETE_FORM:
      return {
        ...state,
        showAssetDeleteForm: true,
        assetToDelete: action.payload
      };
    case HIDE_ASSET_DELETE_FORM:
      return {
        ...state,
        showAssetDeleteForm: false
      };
    case DELETE_ASSET_SUCCESS:
      let assetByID = {...state.assetByID};
      delete assetByID[action.payload.id];
      return {
        ...state,
        assetByID:assetByID
      };
    default:
      return state;
  }
}
