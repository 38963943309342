import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import axios from "axios";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import {
  EXPORT_LOAN_EARNED_INTEREST_REPORT, EXPORT_LOAN_EARNED_PENALTY_AND_FEES_REPORT, EXPORT_LOAN_OVERDUE_REPORT,
  EXPORT_LOAN_REPAYMENTS_REPORT,
  FETCH_LOAN_EARNED_INTEREST_REPORT, FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT,
  FETCH_LOAN_REPAYMENTS_REPORT,
  FETCH_REPORTS_MONTHS,
  fetchLoanEarnedInterestReportSuccessGenerator,
  fetchLoanEarnedPenaltyAndFeesReportSuccessGenerator,
  fetchLoanRepaymentsReportSuccessGenerator,
  fetchReportMonthsSuccessGenerator
} from "../../actions/admin/LoanReportsActions";

const fetchLoanReportsMonthsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/months',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchRepaymentsReportRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/repayments',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const exportRepaymentsReportRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/repayments/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchEarnedInterestReportRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/earned_interest',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const exportEarnedInterestReportRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/earned_interest/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchEarnedPenaltyAndFeesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/earned_penalty_and_fees',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const exportEarnedPenaltyAndFeesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/earned_penalty_and_fees/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const exportOverdueReportRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/reports/overdue/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* fetchLoanReportsMonths({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoanReportsMonthsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchReportMonthsSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchRepaymentsReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchRepaymentsReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchLoanRepaymentsReportSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportRepaymentsReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(exportRepaymentsReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEarnedInterestReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchEarnedInterestReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchLoanEarnedInterestReportSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportEarnedInterestReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(exportEarnedInterestReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEarnedPenaltyAndFeesReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchEarnedPenaltyAndFeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchLoanEarnedPenaltyAndFeesReportSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportEarnedPenaltyAndFeesReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(exportEarnedPenaltyAndFeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportOverdueReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(exportOverdueReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchLoanReportsMonthsDefault() {
  yield takeLatest(FETCH_REPORTS_MONTHS, fetchLoanReportsMonths);
}
export function* fetchRepaymentsReportDefault() {
  yield takeLatest(FETCH_LOAN_REPAYMENTS_REPORT, fetchRepaymentsReport);
}
export function* fetchEarnedInterestReportDefault() {
  yield takeLatest(FETCH_LOAN_EARNED_INTEREST_REPORT, fetchEarnedInterestReport);
}
export function* fetchEarnedPenaltyAndFeesReportDefault() {
  yield takeLatest(FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT, fetchEarnedPenaltyAndFeesReport);
}
export function* exportRepaymentsReportDefault() {
  yield takeLatest(EXPORT_LOAN_REPAYMENTS_REPORT, exportRepaymentsReport);
}
export function* exportEarnedInterestReportDefault() {
  yield takeLatest(EXPORT_LOAN_EARNED_INTEREST_REPORT, exportEarnedInterestReport);
}
export function* exportEarnedPenaltyAndFeesReportDefault() {
  yield takeLatest(EXPORT_LOAN_EARNED_PENALTY_AND_FEES_REPORT, exportEarnedPenaltyAndFeesReport);
}
export function* exportOverdueReportDefault() {
  yield takeLatest(EXPORT_LOAN_OVERDUE_REPORT, exportOverdueReport);
}
export default function* rootSaga() {
  yield all([fork(fetchLoanReportsMonthsDefault),
    fork(fetchRepaymentsReportDefault),
    fork(fetchEarnedInterestReportDefault),
    fork(fetchEarnedPenaltyAndFeesReportDefault),
    fork(exportRepaymentsReportDefault),
    fork(exportEarnedInterestReportDefault),
    fork(exportEarnedPenaltyAndFeesReportDefault),
    fork(exportOverdueReportDefault)
  ]);
}
