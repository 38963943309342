import {
    COMPANY_PROFILE_SUCCESS,
    CREATE_PAYMENT_CHANNEL_SUCCESS,
    DEACTIVATE_COMPANY_SUBSCRIPTION,
    FETCH_ADMIN_COMPANIES_SUCCESS,
    FETCH_ADMIN_PAGINATED_COMPANIES_SUCCESS,
    FETCH_ADMIN_PAGINATED_COMPANY_WALLETS_SUCCESS,
    FETCH_ALL_TRANSACTIONS_SUCCESS,
    FETCH_COMPANY_TRANSACTIONS_SUCCESS,
    FILTER_COMPANY_BY_DATE,
    GET_THREE_PAYROLLS_FOR_A_COMPANY_SUCCESS,
    MARK_EMAIL_VERIFIED_SUCCESS
} from "../../actions/admin/CompanyActions";
import { Helpers } from "../../../util/Helpers";
import moment from "moment";

const INIT_STATE = {
    adminCompanyWalletsByID:{},
    adminCompaniesByID:{},
    adminPaginatedCompanies:{},
    mode_of_payment:'',
    companyTransactionsByID:{},
    allTransactionsByID:{data:[]},
    companyProfile:{},
    threePayrolls:{},
    adminCompaniesFilterStoreByID:{},
    created_at:'',

};
export default (state=INIT_STATE,action)=>{
    switch (action.type) {
        case FETCH_ADMIN_PAGINATED_COMPANY_WALLETS_SUCCESS:
            return {
                ...state,
                adminCompanyWalletsByID:action.payload
            };
        case FETCH_ADMIN_PAGINATED_COMPANIES_SUCCESS:
            return {
                ...state,
                adminPaginatedCompanies:action.payload
            };
        case FILTER_COMPANY_BY_DATE:
            let adminCompanies = {...state.adminCompaniesFilterStoreByID};
            let filtered = adminCompanies;
            let created_at='';
            let dateFormat = "YYYY-MM-DD";
            let {startDate,endDate,date}= action.payload;
            try{
                startDate = moment(startDate).format(dateFormat);
                endDate = moment(endDate).format(dateFormat);
                date = moment(date).format(dateFormat);
            }catch (e) {
                //console.log('Error');
            }

            if (action.payload.type === 'RANGE' || action.payload.type ==='WEEK' ){
                filtered = Object.values(adminCompanies).filter(company => {
                    created_at = moment(company.created_at).format(dateFormat);
                    return  Date.parse(created_at) >= Date.parse(startDate) && Date.parse(created_at) <= Date.parse(endDate);
                });
                created_at = action.payload.startDate+' and ' +action.payload.endDate;
            }else if (action.payload.type === 'SINGLE'){
                filtered = Object.values(adminCompanies).filter(company => {
                    created_at = moment(company.created_at).format(dateFormat);
                    return  Date.parse(created_at) === Date.parse(date);
                });
                created_at=action.payload.date;
            }else if (action.payload.type === 'MONTH'){
                filtered=Object.values(adminCompanies).filter(company=>{
                    let month = new Date(company.created_at);
                    return month.getMonth()+1 === Number(action.payload.month) && month.getFullYear() === Number(action.payload.year);
                });
                created_at = Helpers.getMonthName(action.payload.month)+" "+ Number(action.payload.year);
            }else{
                filtered=adminCompanies;
                created_at = '';
            }
            return {
                ...state,
                adminCompaniesByID: filtered,
                created_at: (created_at && created_at !== ''?'Registered on '+ created_at:''),
            };
        case GET_THREE_PAYROLLS_FOR_A_COMPANY_SUCCESS:
            return {
                ...state,
                threePayrolls:action.payload
            };
        case MARK_EMAIL_VERIFIED_SUCCESS:
            let companies = {...state.adminCompaniesByID};
            companies[action.payload.id] = action.payload;
            return {
                ...state,
                adminCompaniesByID: companies
            };
        case DEACTIVATE_COMPANY_SUBSCRIPTION:
            return {
                ...state,
                companyProfile:action.payload
            };
        case FETCH_ADMIN_COMPANIES_SUCCESS:
            return {
              ...state,
                adminCompaniesByID:action.payload,
                adminCompaniesFilterStoreByID:action.payload
            };
        case CREATE_PAYMENT_CHANNEL_SUCCESS:
            return {
                ...state,
                mode_of_payment:action.payload
            };
        case FETCH_COMPANY_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                companyTransactionsByID:action.payload
            };
        case FETCH_ALL_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                allTransactionsByID:action.payload
            };
        case COMPANY_PROFILE_SUCCESS:
            return {
                ...state,
                companyProfile:action.payload
            };
        default: return state;
    }
}
