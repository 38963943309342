import {
  ADD_EXPENSE_CATEGORY_SUCCESS,
  DELETE_EXPENSE_CATEGORY_SUCCESS,
  EDIT_EXPENSE_CATEGORY_SUCCESS,
  FETCH_EXPENSE_CATEGORIES_SUCCESS,
  FETCH_EXPENSE_SUB_CATEGORIES_SUCCESS,
  HIDE_EXPENSE_CATEGORY_CREATE_FORM,
  HIDE_EXPENSE_CATEGORY_DELETE_FORM,
  HIDE_EXPENSE_CATEGORY_EDIT_FORM,
  SHOW_EXPENSE_CATEGORY_CREATE_FORM,
  SHOW_EXPENSE_CATEGORY_DELETE_FORM,
  SHOW_EXPENSE_CATEGORY_EDIT_FORM
} from '../../actions/payroll/ExpenseCategoryActions';

const INIT_STATE = {
  expenseCategoriesByID:{},
  expenseSubCategoriesByID:{},
  showExpenseCategoryCreateForm:false,
  showExpenseCategoryEditForm:false,
  expenseCategoryToEdit:false,
  showExpenseCategoryDeleteForm:false,
  expenseCategoryToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_EXPENSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        expenseCategoriesByID: action.payload
      };
    case FETCH_EXPENSE_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        expenseSubCategoriesByID:action.payload
      };
    case SHOW_EXPENSE_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showExpenseCategoryCreateForm: true
      };
    case HIDE_EXPENSE_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showExpenseCategoryCreateForm: false
      };
    case SHOW_EXPENSE_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showExpenseCategoryEditForm: true,
        expenseCategoryToEdit: action.payload
      };
    case HIDE_EXPENSE_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showExpenseCategoryEditForm: false
      };
    case ADD_EXPENSE_CATEGORY_SUCCESS:
    case EDIT_EXPENSE_CATEGORY_SUCCESS:
      let expenseCategory_id = action.payload.id;
      return {
        ...state,
        expenseCategoriesByID: {
          ...state.expenseCategoriesByID,
          [expenseCategory_id]:action.payload
        }
      };
    case SHOW_EXPENSE_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showExpenseCategoryDeleteForm: true,
        expenseCategoryToDelete: action.payload
      };
    case HIDE_EXPENSE_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showExpenseCategoryDeleteForm: false
      };
    case DELETE_EXPENSE_CATEGORY_SUCCESS:
      let expenseCategoriesByID = {...state.expenseCategoriesByID};
      delete expenseCategoriesByID[action.payload.id];
      return {
        ...state,
        expenseCategoriesByID:expenseCategoriesByID
      };
    default:
      return state;
  }
}
