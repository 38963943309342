import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import MainApp from '../routes/index';
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import '../assets/vendors/style';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';
import Subscription from './subscription';
import Onboarding from './onboarding';
import { setInitUrlGenerator, userSignInGenerator, userSignOutSuccessGenerator } from "../appRedux/actions/AuthActions";
import { LATEST_PATH, NOTIFICATION_TIME_OUT, SELECTED_EMPLOYEE_ID_FOR_PORTAL } from "../constants/SessionKeys";
import OTPonLogin from './OTPonLogin';
import SweetAlert from 'react-bootstrap-sweetalert';
import TopUp from './onboarding/topUp'
import VerifyEmail from './verifyEmail';
import IdleTimer from 'react-idle-timer'
import { Helpers } from "../util/Helpers";
import Loadable from "react-loadable";
import LoadingComponent from "./Loading";
const ReversePayeCalculator = Loadable({
  loader: () => {
    return import("../containers/ReversePayeCalculator.js");
  },
  loading: LoadingComponent
});

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route key={'Route1'}
    {...rest}
    render={(props) => {
      const location = props.location;
      if(!authUser && (location.pathname !== '/signin' || location.pathname !== '/signup')){
        return (
          <Redirect key={'R1'}
            to={{
              pathname: '/signin',
              state: {from: props.location}
            }}
          />
        );
      }

      if(location.pathname === '/signin' || location.pathname === '/signup'){
        let latest_path = Helpers.getItem(LATEST_PATH) || '';

        return (
          <Redirect key={'R2'}
            to={{
              pathname: latest_path,
              state: {from: props.location}
            }}
          />
        );
      }
      if (authUser.is_email_verified !==1 && !Helpers.isSystemAdmin() && location.pathname !== '/verify-email') {
        return (
          <Redirect key={'R10'}
                    to={{
                      pathname: '/verify-email',
                      state: {data: authUser}
                    }}
          />
        );
      }

      if (authUser && !Helpers.isSystemAdmin() && location.pathname !== '/onboarding'  && !Helpers.authSwitchedCompanyID()) {
        return (
          <Redirect key={'R3'}
            to={{
              pathname: '/onboarding',
              state: {from: props.location}
            }}
          />
        );
      }
      if (!Helpers.isSystemAdmin()
        && location.pathname !== '/onboarding' &&
        location.pathname !== '/common/subscription-payment' &&
        Helpers.unpaidSubscription() &&
        location.pathname !== '/home' &&
        location.pathname !=='/payouts/recharge' &&
        location.pathname !=='/top-up') { // no subscription
        const subscription = Helpers.unpaidSubscription(true);//return the subscription object
        //console.log(subscription);
        return (
          <Redirect key={'R4'}
            to={{
              pathname: '/home',
              state: {amount_mpesa:subscription.amount_paid}
            }}
          />
        );
      }
      if (!Helpers.isSystemAdmin()
        && location.pathname !== '/onboarding' &&
        location.pathname !== '/home' &&
        !Helpers.activeSubscription() &&
        location.pathname !=='/payouts/recharge' &&
        location.pathname !=='/common/subscription-payment' &&
        location.pathname !=='/top-up' && !location.pathname.startsWith('/payouts')) { // no subscription
        return (
          <Redirect key={'R4'}
            to={{
              pathname: '/home',
              state: {from: props.location}
            }}
          />
        );
      }

      if (!Helpers.isSystemAdmin() && location.pathname !== '/onboarding' && location.pathname !== '/common/subscription' && !location.pathname.startsWith('/portal') && !Helpers.authUserHasPermissions() && Helpers.authUserCanAccessPortal()) {
        let employee_id = Helpers.authUserEmployeeID();
        Helpers.saveItem(SELECTED_EMPLOYEE_ID_FOR_PORTAL, {id: employee_id, for:'auth_employee'});
        return (
          <Redirect key={'R5'}
            to={{
              pathname: '/portal/profile',
              state: {from: props.location}
            }}
          />
        );
      }
        if (!Helpers.isNull(Helpers.authSwitchedCompanyID())&& authUser.can_update_settings ===true && !Helpers.isSystemAdmin() && !Helpers.isNull(Helpers.accountSettings()) && Helpers.settingValue('status')==='NEW' && (location.pathname !== '/hrm/system-settings' && location.pathname !== '/payouts/system-settings'  && location.pathname !== '/attendance/system-settings' &&!location.pathname.startsWith('/portal'))) {
            let pathname = window.location.pathname;
            if (pathname.startsWith('/attendance')){
                return (
                  <Redirect key={'R6gbgbg'}
                            to={{
                                pathname: '/attendance/system-settings',
                                state: {from: props.location}
                            }}
                  />
                );
            }else if (pathname.startsWith('/hrm')){
                return (
                  <Redirect key={'R6gbgbg'}
                            to={{
                                pathname: '/hrm/system-settings',
                                state: {from: props.location}
                            }}
                  />
                );
            } else if (pathname.startsWith('/payouts')){
                return (
                  <Redirect key={'R6gbgbg'}
                            to={{
                                pathname: '/payouts/system-settings',
                                state: {from: props.location}
                            }}
                  />
                );
            }
        }
      if (!Helpers.isSystemAdmin() && location.pathname === '' || location.pathname === '/') {
        return (
          <Redirect key={'R6'}
            to={{
              pathname: '/home',
              state: {from: props.location}
            }}
          />
        );
      }
      if(Helpers.isSystemAdmin() && location.pathname === '' || location.pathname === '/'){
        return (
          <Redirect key={'R7'}
            to={{
              pathname: '/admin/companies',
              state: {from: props.location}
            }}
          />
        );
      }


      return <Component {...props} key={'Component'}/>;

    }}
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state= {
      show: true,
      showAlerts: [],
      isIdle: false
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  UNSAFE_componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrlGenerator(this.props.history.location.pathname);
    }
  }

  closeAlert = (notification)=>{
    const newState = Object.assign({}, this.state);
    newState["showAlerts"][notification.time]=false;
    this.setState({ ...newState });
  };

  componentDidMount() {
    const {location} = this.props;
    if(location.pathname === '/signout'){
       this.props.userSignOutSuccessGenerator({from:'user_click'});
    }
  }

  onIdle =(e)=> {
    let {location} = this.props;
    if(location.pathname !== '/signin' && location.pathname !== '/signout'){
      this.setState({isIdle:true});
    }else{
      this.setState({isIdle:false});
    }
  };

  logOutOnCancelPasswordAlert =()=> {
    let {location} = this.props;
    if(location.pathname !== '/signin' && location.pathname !== '/signout'){
      this.setState({isIdle:false});
      this.props.userSignOutSuccessGenerator({from:'user_click'});
    }
  };

  submitPasswordOnIdle =(password)=> {
    let {location} = this.props;
    if(!password || password.length === 0){//alert timed out
      if(this.state.isIdle){
        this.setState({isIdle:false});
        this.props.userSignOutSuccessGenerator({from:'user_click'});
      }
       return;
    }
    if(location.pathname === '/signin' || location.pathname === '/signout'){
      this.setState({isIdle:false});
       return;
    }
    this.setState({isIdle:false});
    let email = Helpers.authUserEmail();
    this.props.userSignInGenerator({
      email,
      password
    });
  };

  render() {
    const { match, location, locale, authUser, isDirectionRTL } = this.props;
    const {isIdle} = this.state;
    if (location.pathname !== '/signin' && location.pathname !== '/signout' && location.pathname !== '/signup'
      && location.pathname !== '/hrm/payslips/' && location.pathname !== '/portal/payslips'
       && location.pathname !== '/common/system-settings'
       && location.pathname !== '/hrm/system-settings'
       && location.pathname !== '/attendance/system-settings'
       && location.pathname !== '/payouts/system-settings'
      && location.pathname !== '/onboarding' && location.pathname !== '/common/subscription' && location.pathname !== '/top-up'
      && location.pathname !== '/forgot-password' && !location.pathname.startsWith('/password/reset') &&
      !location.pathname.startsWith('/portal') && location.pathname !== 'hrm/expenses-subcategories' &&
    location.pathname !== 'hrm/expenses-receipts' && location.pathname !=='/recover/code' && !location.pathname.startsWith('/admin') && location.pathname !== 'payouts/transaction-details') {
      Helpers.saveItem(LATEST_PATH, location.pathname);
    }

    if(location.pathname === '/signout'){
      return (
        <Redirect key={'R6'}
                  to={{
                    pathname: '/signin',
                    state: {from: location}
                  }}
        />
      );
    }


    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    let notifications = Helpers.notifications();
    const currentAppLocale = AppLocale[locale.locale];

    if(location.pathname === '/verify-email' && authUser && authUser.is_email_verified ===1){
      return (
        <Redirect key={'R10'}
                  to={{
                    pathname: '/home',
                    state: {data: authUser}
                  }}
        />
      );
    }
    let timeoutPasswordRequired = location.pathname !== '/signin' && location.pathname !== '/signup' && isIdle;
    let showIdleTimer = false;
    return (
      <MuiThemeProvider theme={applyTheme} key={'MuiThemeProvider'}>
        <MuiPickersUtilsProvider utils={MomentUtils} key={'MuiPickersUtilsProvider'}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages} key={'IntlProvider'}>

            <div className="app-main" key={'app-main'}>
              {showIdleTimer &&
              <IdleTimer key={'idle-timer'}
                         ref={ref => { this.idleTimer = ref }}
                         element={document}
                         onIdle={this.onIdle}
                         debounce={250}
                         timeout={1000 * 60 * 10} />
              }

              {
                Object.keys(notifications).map(time=>{
                  let notification = notifications[time];
                  let  type = notification.type;
                  if(!notification.displayed && notification.message && notification.message.toLowerCase() !== 'not found' && notification.message.toLowerCase() !== 'unknown error'){
                    Helpers.updateNotification(notification, {displayed:true});
                    switch (type) {
                      case 'success':
                        return (
                          <div key={notification.time}>
                            <SweetAlert
                              success
                              show={!this.state.showAlerts.hasOwnProperty(notification.name) }
                              title={''}
                              onConfirm={()=>this.closeAlert(notification)}
                              confirmBtnBsStyle={'success'}
                            >
                              {(typeof notification.message == 'string' && notification.message.includes("<br/>"))?<div style={{align:'left', textAlign:'left'}}>{notification.message.split("<br/>").map((str, index)=><span key={index}>{str}<br/></span>)}</div>:notification.message}
                            </SweetAlert>
                          </div>);
                      case 'warning':
                        return (
                          <div key={notification.time}>
                            <SweetAlert
                              warning
                              show={!this.state.showAlerts.hasOwnProperty(notification.name) }
                              title={''}
                              onConfirm={()=>this.closeAlert(notification)}
                              confirmBtnBsStyle={'warning'}
                            >
                              {(typeof notification.message == 'string' && notification.message.includes("<br/>"))?<div style={{align:'left', textAlign:'left'}}>{notification.message.split("<br/>").map((str, index)=><span key={index}>{str}<br/></span>)}</div>:notification.message}
                            </SweetAlert>
                          </div>);
                      case 'error':
                        return (
                          <div key={notification.time}>
                            <SweetAlert
                              danger
                              show={!this.state.showAlerts.hasOwnProperty(notification.name) }
                              title={''}
                              onConfirm={()=>this.closeAlert(notification)}
                              confirmBtnBsStyle={'danger'}
                            >
                              {(typeof notification.message == 'string' && notification.message.includes("<br/>"))?<div style={{align:'left', textAlign:'left'}}>{notification.message.split("<br/>").map((str, index)=><span key={index}>{str}<br/></span>)}</div>:notification.message}
                            </SweetAlert>
                          </div>);
                      default:
                        return (
                          <div key={notification.time}>
                            <SweetAlert
                              info
                              show={!this.state.showAlerts.hasOwnProperty(notification.name) }
                              title={''}
                              onConfirm={()=>this.closeAlert(notification)}
                              confirmBtnBsStyle={'info'}
                            >
                              {(typeof notification.message == 'string' && notification.message.includes("<br/>"))?<div style={{align:'left', textAlign:'left'}}>{notification.message.split("<br/>").map((str, index)=><span key={index}>{str}<br/></span>)}</div>:notification.message}
                            </SweetAlert>
                          </div>);
                    }

                  }
              })
              }
              <Switch key={'Switch'}>
                <Route path="/verify-account" component={OTPonLogin} key={''}/>
                <Route path="/forgot-password" component={ResetPassword} key={'forgot-password'}/>
                <Route path="/recover/code" component={ForgotPassword} key={'recover-code'}/>
                <Route path="/verify-email" component={VerifyEmail} key={'verify-email'}/>
                <Route path="/reverse/paye-calculator" component={ReversePayeCalculator} key={'reverse-paye-calculator'}/>
                {
                  (authUser === null || window.location.search.includes('utm_content')) && //utm_content serves as a token from other apps attempting to start a new session thus the need to use  the auto-signing flow at signin page
                  [
                    <Route path="/signin" component={SignIn} key={'signin'}/> ,
                    <Route path="/signup" component={SignUp} key={'signup'}/> ,
                    <Route path="/signout" component={SignIn} key={'signout'}/>
                  ]
                }
                {
                  authUser &&
                    [
                      <Route path="/common/subscription" component={Subscription} key={'subscription'}/>,
                      <Route path="/onboarding" component={Onboarding} key={'onboarding'}/>,
                      <Route path="/top-up" component={TopUp} key={'top-up'}/>
                      ]
                }
                <RestrictedRoute
                  path={`${match.url}`}
                  authUser={authUser}
                  component={MainApp} key={'RestrictedRoute'}/>
              </Switch>
              {
                timeoutPasswordRequired &&
                <SweetAlert
                  key={'on-idle-sweet-alert'}
                  warning
                  show={isIdle}
                  title={'Session Time Out'}
                  timeout={1000 * 30}
                  type={'input'}
                  inputType={'password'}
                  required={true}
                  validationMsg={'Please enter password!'}
                  showCancel={true}
                  cancelBtnText={'Sign Out'}
                  confirmBtnText={'Submit'}
                  onConfirm={this.submitPasswordOnIdle}
                  onCancel={this.logOutOnCancelPasswordAlert}
                  closeOnClickOutside={false}
                  confirmBtnBsStyle={'danger'}
                >
                  <span>Your session has timed out. Submit password to continue.<br/><br/></span>
                </SweetAlert>
              }
            </div>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth, general}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {authUser, initURL, role} = auth;
  const {showMessage, alertMessage} = general;//important to trigger need to show a notification saved in localStorage
  return {
    sideNavColor,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    role,
    showMessage,
    alertMessage
  };
};

export default connect(mapStateToProps, {setInitUrlGenerator, userSignOutSuccessGenerator, userSignInGenerator})(App);
