export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';

export const FETCH_SUBSCRIPTION_PACKAGE = 'FETCH_SUBSCRIPTION_PACKAGE';
export const FETCH_SUBSCRIPTION_PACKAGE_SUCCESS = 'FETCH_SUBSCRIPTION_PACKAGE_SUCCESS';

export const PLAN_TYPE_SWITCHER = "PLAN_TYPE_SWITCHER";

export const GET_TRIAL_PERIOD = "GET_TRIAL_PERIOD";
export const GET_TRIAL_PERIOD_SUCCESS = "GET_TRIAL_PERIOD_SUCCESS";
export const UPGRADE_SUBSCRIPTION = "UPGRADE_SUBSCRIPTION";

export const RENEW_SUBSCRIPTION = 'RENEW_SUBSCRIPTION';
export const RENEW_SUBSCRIPTION_SUCCESS = 'RENEW_SUBSCRIPTION_SUCCESS';

export const TOP_UP_AND_SUBSCRIBE = "TOP_UP_AND_SUBSCRIBE";
export const ACCEPT_TOP_UP_SUBSCRIBE ="ACCEPT_TOP_UP_SUBSCRIBE";

export const SEND_NOTIFICATION_ON_UPGRADE_SUBSCRIPTION ="SEND_NOTIFICATION_ON_UPGRADE_SUBSCRIPTION";

export const sendNotificationOnRequestUpgradeOfSubscriptionGenerator =(payload=[])=>{
  return{
    type:SEND_NOTIFICATION_ON_UPGRADE_SUBSCRIPTION,
    payload
  }
}
export const topUpAndSubscribeGenerator =(payload)=>{
  return{
    type:TOP_UP_AND_SUBSCRIBE,
    payload
  }
}
export const acceptTopUpAndSubscribeGenerator =(payload)=>{
  return{
    type:ACCEPT_TOP_UP_SUBSCRIBE,
    payload
  }
}

export const renewSubscriptionGenerator =(payload=[])=>{
  return{
    type:RENEW_SUBSCRIPTION,
    payload
  }
}

export const renewSubscriptionSuccessGenerator =(data=[])=>{
  return {
    type:RENEW_SUBSCRIPTION_SUCCESS,
    payload:data
  }
};

export const upgradeSubscriptionGenerator =(payload=[])=>{
  return{
    type:UPGRADE_SUBSCRIPTION,
    payload
  }
}

export const getTrialPeriodGenerator =(data=[])=>{
  return {
    type:GET_TRIAL_PERIOD,
    payload:data
  }
};
export const getTrialPeriodSuccessGenerator =(data=[])=>{
  return {
    type:GET_TRIAL_PERIOD_SUCCESS,
    payload:data
  }
};
export const fetchSubscriptionGenerator = (data=[])=>{
  return {
    type:FETCH_SUBSCRIPTION,
    payload:data
  };
};
export const planTypeSwitcherGenerator =(planType)=>{
  return{
    type:PLAN_TYPE_SWITCHER,
    payload:planType
  }
};

export const fetchSubscriptionSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_SUBSCRIPTION_SUCCESS,
    payload:dataById
  }
};

export const addSubscriptionGenerator = (data)=>{
  return {
    type: ADD_SUBSCRIPTION,
    payload: data
  }
};

export const addSubscriptionSuccessGenerator = (data)=>{
  return {
    type: ADD_SUBSCRIPTION_SUCCESS,
    payload: data
  }
};
export const fetchSubscriptionPackageSuccessGenerator = (data)=>{
  return {
    type: FETCH_SUBSCRIPTION_PACKAGE_SUCCESS,
    payload: data
  }
};export const fetchSubscriptionPackageGenerator = (data=[])=>{
  return {
    type: FETCH_SUBSCRIPTION_PACKAGE,
    payload: data
  }
};
