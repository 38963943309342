export const FETCH_IMPREST = "FETCH_IMPREST";
export const FETCH_IMPREST_SUCCESS = "FETCH_IMPREST_SUCCESS";
export const SHOW_IMPREST_CREATE_FORM = 'SHOW_IMPREST_CREATE_FORM';
export const HIDE_IMPREST_CREATE_FORM = 'HIDE_IMPREST_CREATE_FORM';
export const ADD_IMPREST = 'ADD_IMPREST';
export const ADD_IMPREST_SUCCESS = 'ADD_IMPREST_SUCCESS';
export const SHOW_IMPREST_EDIT_FORM = 'SHOW_IMPREST_EDIT_FORM';
export const HIDE_IMPREST_EDIT_FORM = 'HIDE_IMPREST_EDIT_FORM';
export const EDIT_IMPREST = 'EDIT_IMPREST';
export const EDIT_IMPREST_SUCCESS = 'EDIT_IMPREST_SUCCESS';
export const SHOW_IMPREST_DELETE_FORM = 'SHOW_IMPREST_DELETE_FORM';
export const HIDE_IMPREST_DELETE_FORM = 'HIDE_IMPREST_DELETE_FORM';
export const DELETE_IMPREST = 'DELETE_IMPREST';
export const DELETE_IMPREST_SUCCESS = 'DELETE_IMPREST_SUCCESS';

export const fetchImprestGenerator = (data=[])=>{
  return {
    type:FETCH_IMPREST,
    payload:data
  };
};

export const fetchImprestSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_IMPREST_SUCCESS,
    payload:dataById
  }
};

export const showImprestCreateFormGenerator = ()=>{
  return {
    type:SHOW_IMPREST_CREATE_FORM
  };
};
export const hideImprestCreateFormGenerator = ()=>{
  return {
    type:HIDE_IMPREST_CREATE_FORM
  };
};
export const addImprestGenerator = (data)=>{
  return {
    type: ADD_IMPREST,
    payload: data
  }
};

export const addImprestSuccessGenerator = (data)=>{
  return {
    type: ADD_IMPREST_SUCCESS,
    payload: data
  }
};
export const showImprestEditFormGenerator = (data)=>{
  return {
    type:SHOW_IMPREST_EDIT_FORM,
    payload:data
  };
};
export const hideImprestEditFormGenerator = ()=>{
  return {
    type:HIDE_IMPREST_EDIT_FORM
  };
};
export const editImprestGenerator = (data)=>{
  return {
    type: EDIT_IMPREST,
    payload: data
  }
};
export const editImprestSuccessGenerator = (data)=>{
  return {
    type: EDIT_IMPREST_SUCCESS,
    payload: data
  }
};
export const showImprestDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_IMPREST_DELETE_FORM,
    payload:data
  };
};
export const hideImprestDeleteFormGenerator = ()=>{
  return {
    type:HIDE_IMPREST_DELETE_FORM
  };
};
export const deleteImprestGenerator = (data)=>{
  return {
    type: DELETE_IMPREST,
    payload: data
  }
};
export const deleteImprestSuccessGenerator = (data)=>{
  return {
    type: DELETE_IMPREST_SUCCESS,
    payload: data
  }
};
