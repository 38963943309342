export const FETCH_MPESA_SETTINGS = "FETCH_MPESA_SETTINGS";
export const FETCH_MPESA_SETTINGS_SUCCESS = "FETCH_MPESA_SETTINGS_SUCCESS";
export const REQUEST_MPESA_BALANCE = "REQUEST_MPESA_BALANCE";

export const UPDATE_MPESA_SETTING = "UPDATE_MPESA_SETTING";
export const UPDATE_MPESA_SETTING_SUCCESS = "UPDATE_MPESA_SETTING_SUCCESS";

export const DOWNLOAD_JENGA_STATEMENT = "DOWNLOAD_JENGA_STATEMENT";

export const requestMpesaSettingsGenerator = data => {
  return {
    type: REQUEST_MPESA_BALANCE,
    payload: data
  };
};
export const fetchMpesaSettingsGenerator = data => {
  return {
    type: FETCH_MPESA_SETTINGS,
    payload: data
  };
};

export const fetchMpesaSettingsSuccessGenerator = data => {
  return {
    type: FETCH_MPESA_SETTINGS_SUCCESS,
    payload: data
  };
};
export const updateMpesaSettingGenerator = data => {
  return {
    type: UPDATE_MPESA_SETTING,
    payload: data
  };
};
export const updateMpesaSettingSuccessGenerator = data => {
  return {
    type: UPDATE_MPESA_SETTING_SUCCESS,
    payload: data
  };
};
export const downloadJengaStatementGenerator = data => {
  return {
    type: DOWNLOAD_JENGA_STATEMENT,
    payload: data
  };
};
