export const FETCH_ASSETS_CATEGORIES = "FETCH_ASSETS_CATEGORIES";
export const FETCH_ASSETS_CATEGORIES_SUCCESS = "FETCH_ASSETS_CATEGORIES_SUCCESS";
export const ADD_ASSET_CATEGORY = 'ADD_ASSET_CATEGORY';
export const ADD_ASSET_CATEGORY_SUCCESS = 'ADD_ASSET_CATEGORY_SUCCESS';
export const EDIT_ASSET_CATEGORY = 'EDIT_ASSET_CATEGORY';
export const EDIT_ASSET_CATEGORY_SUCCESS = 'EDIT_ASSET_CATEGORY_SUCCESS';
export const DELETE_ASSET_CATEGORY = 'DELETE_ASSET_CATEGORY';
export const DELETE_ASSET_CATEGORY_SUCCESS = 'DELETE_ASSET_CATEGORY_SUCCESS';

export const fetchAssetCategoryGenerator = (data=[])=>{
  return {
    type:FETCH_ASSETS_CATEGORIES,
    payload:data
  };
};

export const fetchAssetsCategorySuccessGenerator = (dataById)=> {
  return {
    type:FETCH_ASSETS_CATEGORIES_SUCCESS,
    payload:dataById
  }
};

export const addAssetCategoryGenerator = (data)=>{
  return {
    type: ADD_ASSET_CATEGORY,
    payload: data
  }
};

export const addAssetCategorySuccessGenerator = (data)=>{
  return {
    type: ADD_ASSET_CATEGORY_SUCCESS,
    payload: data
  }
};

export const editAssetCategoryGenerator = (data)=>{
  return {
    type: EDIT_ASSET_CATEGORY,
    payload: data
  }
};
export const editAssetCategorySuccessGenerator = (data)=>{
  return {
    type: EDIT_ASSET_CATEGORY_SUCCESS,
    payload: data
  }
};
export const deleteAssetCategoryGenerator = (data)=>{
  return {
    type: DELETE_ASSET_CATEGORY,
    payload: data
  }
};
export const deleteAssetCategorySuccessGenerator = (data)=>{
  return {
    type: DELETE_ASSET_CATEGORY_SUCCESS,
    payload: data
  }
};
