
import {
  AUTH_DEFAULT_APP, AUTH_SWITCHED_APP,
  LATEST_PATH, PRODUCT_ADMIN_SIGNED_IN_AS_CLIENT_KEY, SELECTED_EMPLOYEE_ID_FOR_PORTAL
} from "../../constants/SessionKeys";
import {Helpers} from "../../util/Helpers";
import {
  INIT_URL,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS
} from "../../constants/ActionTypes";
import {
  CLOSE_PASSWORD_CHANGE_MODAL,
  FETCH_AUTH_USER_ROLE_SUCCESS,
  FORGOT_PASSWORD_SUCCESS, OPEN_PASSWORD_CHANGE_MODAL,
  RECOVER_PASSWORD_SUCCESS,
  SET_DEFAULT_APP_SUCCESS, USE_REST_CODE_SUCCESS
} from "../actions/AuthActions";
import { CHANGE_PROFILE_PHOTO_SUCCESS } from "../actions/profiles/EmployeeProfileActions";
import { ADD_COMPANY_SUCCESS, SWITCH_COMPANY_SUCCESS } from "../actions/common/CompanyActions";
import {USE_OTP_SUCCESS} from "../actions/common/UserActions";
const INIT_STATE = {
  initURL: Helpers.getItem(LATEST_PATH) || '',
  authUser: Helpers.authUser(),
  role: Helpers.authRole(),
  defaultApp: Helpers.authDefaultApp(),
  email:'',
  user_:'',
  openChangePasswordModal:false,
};

export default (state = INIT_STATE, action) => {

  try{
    //intercepting all no-array object data before saga submits it to backend for permissions purpose
    if(!action.type.toUpperCase().includes('SUCCESS')){//SUCCESS implies data arriving from backend; so do not act on it
      if(Helpers.isNull(action.payload)){
         action.payload = {};
      }
      if(Array.isArray(action.payload) || typeof action.payload === 'object'){
        let check = Helpers.authorizeFor();
        action.payload.authorize_for = check['authorizeFor'];
        action.payload.auth_employee_id = check['employeeID'];
      }
    }
  }catch (e) {}
  switch (action.type) {
    case OPEN_PASSWORD_CHANGE_MODAL:
      return {
        ...state,
        openChangePasswordModal:true
      };
    case CLOSE_PASSWORD_CHANGE_MODAL:
      return {
        ...state,
        openChangePasswordModal:false
      };
    case USE_REST_CODE_SUCCESS:
      return {
        ...state,
      };
    case FORGOT_PASSWORD_SUCCESS:
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        email:action.payload.email,
        user_id:action.payload.id
      };
    case USE_OTP_SUCCESS:
    case SIGNUP_USER_SUCCESS:
    case SIGNIN_USER_SUCCESS: {
      Helpers.saveItem(PRODUCT_ADMIN_SIGNED_IN_AS_CLIENT_KEY,action.payload.productAdminSignedInAsClient?{status:1, product_admin_email:action.payload.productAdminEmail}:{status:0, product_admin_email: ''});
      let latest_path = Helpers.getItem(LATEST_PATH) || '';
      if (latest_path === '' || latest_path === '/') {
        let defaultApp = {app_id: 0};
        let app_id = 0;
        defaultApp = JSON.parse(Helpers.getItem(AUTH_DEFAULT_APP, JSON.stringify(defaultApp)));
        app_id = defaultApp?defaultApp.app_id:0;
        const app = Helpers.app(app_id);
        Helpers.saveItem(AUTH_SWITCHED_APP, app);
        latest_path = '/home';
        Helpers.saveItem(LATEST_PATH, latest_path);
      }
      return {
        ...state,
        authUser: action.payload,
        role: action.payload.role
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      Helpers.clearAuthData();
      return {
        ...state,
        authUser: null,
        initURL: '/'
      }
    }
    case FETCH_AUTH_USER_ROLE_SUCCESS: {
      if(action.payload.hasOwnProperty('is_email_verified') && Number(state.authUser.is_email_verified) !== Number(action.payload.is_email_verified)){
        let authUser = {...state.authUser};
        authUser.is_email_verified = action.payload.is_email_verified;
        Helpers.updateAuthUser('is_email_verified', 1);
        return {
          ...state,
          authUser:authUser,
          role: action.payload
        }
      }
      return {
        ...state,
        role: action.payload
      }
    }
    case SWITCH_COMPANY_SUCCESS:
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        role: action.payload.role
      };
    case SET_DEFAULT_APP_SUCCESS:{
      return {
        ...state,
        defaultApp:action.payload
      }
    }
    case CHANGE_PROFILE_PHOTO_SUCCESS:
      let user_id = action.payload.user_id;
      let employee_id = action.payload.employee_id;
      let isAuthUser = !Helpers.isNull(user_id) && (Number(user_id) === Number(Helpers.authUserID()));
      if(!isAuthUser){
        isAuthUser = !Helpers.isNull(employee_id) && (Number(employee_id) === Number(Helpers.authUserEmployeeID()));
      }

      if(isAuthUser){
        Helpers.updateAuthAvatarSrc(action.payload.path);
        return {
          ...state,
          authUser:{...state.authUser, avatar:action.payload.path}
        };
      }else{
        return state
      }
    default:
      return state;
  }
}
