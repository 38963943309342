import {
  FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS
} from "../../actions/admin/SalaryAdvancesDefaultSettingsAction";

const INIT_STATE = {
  adminDefaultSalaryAdvanceSettings:{},
};

export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS:
    case UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS:
      return {
        ...state,
        adminDefaultSalaryAdvanceSettings: action.payload
      }
    default:
      return state;
  }
}
