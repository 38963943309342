export const FETCH_EXITS = "FETCH_EXITS";
export const FETCH_EXITS_SUCCESS = "FETCH_EXITS_SUCCESS";
export const SHOW_EXIT_CREATE_FORM = 'SHOW_EXIT_CREATE_FORM';
export const HIDE_EXIT_CREATE_FORM = 'HIDE_EXIT_CREATE_FORM';
export const ADD_EXIT = 'ADD_EXIT';
export const ADD_EXIT_SUCCESS = 'ADD_EXIT_SUCCESS';
export const SHOW_EXIT_EDIT_FORM = 'SHOW_EXIT_EDIT_FORM';
export const HIDE_EXIT_EDIT_FORM = 'HIDE_EXIT_EDIT_FORM';
export const EDIT_EXIT = 'EDIT_EXIT';
export const EDIT_EXIT_SUCCESS = 'EDIT_EXIT_SUCCESS';
export const SHOW_EXIT_DELETE_FORM = 'SHOW_EXIT_DELETE_FORM';
export const HIDE_EXIT_DELETE_FORM = 'HIDE_EXIT_DELETE_FORM';
export const DELETE_EXIT = 'DELETE_EXIT';
export const DELETE_EXIT_SUCCESS = 'DELETE_EXIT_SUCCESS';
export const CERTIFY_EXIT = 'CERTIFY_EXIT';
export const CERTIFY_EXIT_SUCCESS = 'CERTIFY_EXIT_SUCCESS';
export const REJECT_EXIT = 'REJECT_EXIT';
export const REJECT_EXIT_SUCCESS= 'REJECT_EXIT_SUCCESS';
export const APPROVE_EXIT_SUCCESS='APPROVE_EXIT_SUCCESS';
export const APPROVE_EXIT='APPROVE_EXIT';
export const BULK_EXIT='BULK_EXIT';
export const BULK_EXIT_SUCCESS='BULK_EXIT_SUCCESS';

export const fetchExitsGenerator = (data=[])=>{
  return {
    type:FETCH_EXITS,
    payload:data
  };
};

export const fetchExitsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_EXITS_SUCCESS,
    payload:dataById
  }
};

export const showExitCreateFormGenerator = ()=>{
  return {
    type:SHOW_EXIT_CREATE_FORM
  };
};
export const hideExitCreateFormGenerator = ()=>{
  return {
    type:HIDE_EXIT_CREATE_FORM
  };
};
export const addExitGenerator = (data)=>{
  return {
    type: ADD_EXIT,
    payload: data
  }
};

export const addExitSuccessGenerator = (data)=>{
  return {
    type: ADD_EXIT_SUCCESS,
    payload: data
  }
};

export const bulkExitGenerator = (data)=>{
  return {
    type: BULK_EXIT,
    payload: data
  }
};

export const bulkExitSuccessGenerator = (data)=>{
  return {
    type: BULK_EXIT_SUCCESS,
    payload: data
  }
};

export const showExitEditFormGenerator = (data)=>{
  return {
    type:SHOW_EXIT_EDIT_FORM,
    payload:data
  };
};
export const hideExitEditFormGenerator = ()=>{
  return {
    type:HIDE_EXIT_EDIT_FORM
  };
};
export const editExitGenerator = (data)=>{
  return {
    type: EDIT_EXIT,
    payload: data
  }
};
export const editExitSuccessGenerator = (data)=>{
  return {
    type: EDIT_EXIT_SUCCESS,
    payload: data
  }
};
export const showExitDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_EXIT_DELETE_FORM,
    payload:data
  };
};
export const hideExitDeleteFormGenerator = ()=>{
  return {
    type:HIDE_EXIT_DELETE_FORM
  };
};
export const deleteExitGenerator = (data)=>{
  return {
    type: DELETE_EXIT,
    payload: data
  }
};
export const deleteExitSuccessGenerator = (data)=>{
  return {
    type: DELETE_EXIT_SUCCESS,
    payload: data
  }
};

export const certifyExitGenerator = (data)=>{
  return {
    type: CERTIFY_EXIT,
    payload: data
  }
};

export const certifyExitSuccessGenerator = (data)=>{
  return {
    type: CERTIFY_EXIT_SUCCESS,
    payload: data
  }
};

export const rejectExitGenerator = (data)=>{
  return {
    type: REJECT_EXIT,
    payload: data
  }
};

export const rejectExitSuccessGenerator = (data)=>{
  return {
    type: REJECT_EXIT_SUCCESS,
    payload: data
  }
};

export const approveExitGenerator = (data)=>{
  return {
    type: APPROVE_EXIT,
    payload: data
  }
};

export const approveExitSuccessGenerator = (data)=>{
  return {
    type: APPROVE_EXIT_SUCCESS,
    payload: data
  }
};
