import {
  CREATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
  DELETE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
  FETCH_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
  UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
  FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS,
  CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS,
  FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS,
  CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS
} from "../../actions/admin/SalaryAdvancesSettingsAction";

const INIT_STATE = {
  SalaryAdvanceSettings:{},
};

export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case CREATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS:
    case UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS:
    case FETCH_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS:
    case DELETE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS:
      return {
        ...state,
        SalaryAdvanceSettings: action.payload
      }

    case FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS:
    case CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS:
    case FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS:
    case CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS:
      return {
        ...state,
        SalaryAdvanceSettings: action.payload
      }

    default:
      return state;
  }
}
