// Customizer const
export const AUTH_USER_KEY = 'calls';
export const AUTH_SWITCHED_COMPANY_ID_KEY = 'comp';
export const AUTH_SWITCHED_COMPANY_EMAIL_KEY = 'comp_em';
export const AUTH_HEAD_OFFICE_ID_KEY = 'comp_head';
export const CURRENCIES_KEY = 'currencies';
export const COUNTRIES_KEY = 'countries';
export const BANKS_KEY = 'banks';
export const SECTORS_KEY = 'sectors';
export const CACHE_STAMP = 'cache_stamp';
export const NHIF_RATES_KEY = 'nhif_rates';
export const NSSF_RATES_KEY = 'nssf_rates';
export const PAYE_RATES_KEY = 'paye_rates';
export const SELECTED_COMPANY_KEY = 'selected_comp';
export const SELECTED_EXPENSE_CATEGORY_KEY = 'selected_exp_cat';
export const SELECTED_ROLE_KEY = 'selected_role';
export const SELECTED_PROJECT_KEY = 'selected_prj';
export const SELECTED_TRANSACTION = 'selected_trn';
export const AUTH_SWITCHED_APP = 's_app';
export const AUTH_DEFAULT_APP = 'app';
export const LATEST_PATH = 'route';
export const AUTH_ROLE_KEY = 'auth_rl';
export const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY;
export const SUBSCRIPTION_KEY = 'subscription';
export const SELECTED_EMPLOYEE_ID_FOR_PORTAL = 'emp_portal';
export const SELECTED_MUSTER_ROLL = "muster";
export const NOTIFICATION_TIME_OUT = 10000;//milliseconds
export const NOTIFICATION_STORE = "notif_str";
export const SYSTEM_ADMIN = "admin_panel";
export const ACCOUNT_SETTINGS_KEY = "sett_account";
export const LOAN_LENDER_KEY = "l_l_k";
export const LOAN_REPAYMENTS_REPORT_KEY = "l_r_r_k";
export const LOAN_INTEREST_REPORT_KEY = "l_i_r_k";
export const LOAN_PENALTY_AND_FEE_REPORT_KEY = "l_p_a_f_r_k";
export const LOAN_DASHBOARD_YEAR = "l_d_y";
export const SELECTED_LOAN_PAYMENT_KEY = 's_l_p_k';
export const SELECTED_LOAN_STATEMENT_KEY = 's_l_s_k';
export const SELECTED_ATTENDANCE_MAPPING_DETAILS = 's_a_m_d';
export const LOAN_OVERDUE_REPORT_KEY = 'l_o_r_k';
export const PAYOUT_CACHE_TYPE = 'p_c_t';
export const ADMIN_SETTINGS_KEY='admin_set_keys';
export const PRODUCTS_DELIVERED_MONTH_KEY = 'p_d_m_k';
export const PRODUCT_ADMIN_SIGNED_IN_AS_CLIENT_KEY = 'p_s_i__a_c_k';
export const SELECTED_PAYROLL_VARIANCE_BATCH_IDS_KEY = 's_p_v_b_i_k';
export const SELECTED_LOAN_FOR_CUSTOMER_STATEMENT = 'se_l_f_c_st';
export const SWITCHED_COUNTRY_KEY = 'sw_cy_ky';
export const USER_EMAIL = 'us_em';
