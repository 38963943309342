export const FETCH_PAYOUT_CHARGES = "FETCH_PAYOUT_CHARGES";
export const FETCH_PAYOUT_CHARGES_SUCCESS = "FETCH_PAYOUT_CHARGES_SUCCESS";
export const ADD_PAYOUT_CHARGE = "ADD_PAYOUT_CHARGE";
export const ADD_PAYOUT_CHARGE_SUCCESS = "ADD_PAYOUT_CHARGE_SUCCESS";
export const EDIT_PAYOUT_CHARGE = "EDIT_PAYOUT_CHARGE";
export const EDIT_PAYOUT_CHARGE_SUCCESS = "EDIT_PAYOUT_CHARGE_SUCCESS";
export const DELETE_PAYOUT_CHARGE = "DELETE_PAYOUT_CHARGE";
export const DELETE_PAYOUT_CHARGE_SUCCESS = "DELETE_PAYOUT_CHARGE_SUCCESS";

export const FETCH_DEFAULT_PAYOUT_CHARGES = "FETCH_DEFAULT_PAYOUT_CHARGES";
export const FETCH_DEFAULT_PAYOUT_CHARGES_SUCCESS = "FETCH_DEFAULT_PAYOUT_CHARGES_SUCCESS";
export const ADD_DEFAULT_PAYOUT_CHARGE = "ADD_DEFAULT_PAYOUT_CHARGE";
export const ADD_DEFAULT_PAYOUT_CHARGE_SUCCESS = "ADD_DEFAULT_PAYOUT_CHARGE_SUCCESS";
export const EDIT_DEFAULT_PAYOUT_CHARGE = "EDIT_DEFAULT_PAYOUT_CHARGE";
export const EDIT_DEFAULT_PAYOUT_CHARGE_SUCCESS = "EDIT_DEFAULT_PAYOUT_CHARGE_SUCCESS";
export const DELETE_DEFAULT_PAYOUT_CHARGE = "DELETE_DEFAULT_PAYOUT_CHARGE";
export const DELETE_DEFAULT_PAYOUT_CHARGE_SUCCESS = "DELETE_DEFAULT_PAYOUT_CHARGE_SUCCESS";

export const fetchPayoutChargesGenerator =(data=[])=>{
  return{
    type:FETCH_PAYOUT_CHARGES,
    payload:data
  }
};
export const fetchPayoutChargesSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_PAYOUT_CHARGES_SUCCESS,
    payload:dataByID
  }
};
export const addPayoutChargeGenerator =(data=[])=>{
  return{
    type:ADD_PAYOUT_CHARGE,
    payload:data
  }
};
export const addPayoutChargeSuccessGenerator =(data)=>{
  return{
    type:ADD_PAYOUT_CHARGE_SUCCESS,
    payload:data
  }
};
export const editPayoutChargeGenerator =(data=[])=>{
  return{
    type:EDIT_PAYOUT_CHARGE,
    payload:data
  }
};
export const editPayoutChargeSuccessGenerator =(data)=>{
  return{
    type:EDIT_PAYOUT_CHARGE_SUCCESS,
    payload:data
  }
};
export const deletePayoutChargeGenerator =(data=[])=>{
  return{
    type:DELETE_PAYOUT_CHARGE,
    payload:data
  }
};
export const deletePayoutChargeSuccessGenerator =(data)=>{
  return{
    type:DELETE_PAYOUT_CHARGE_SUCCESS,
    payload:data
  }
};






export const fetchDefaultPayoutChargesGenerator =(data=[])=>{
  return{
    type:FETCH_DEFAULT_PAYOUT_CHARGES,
    payload:data
  }
};
export const fetchDefaultPayoutChargesSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_DEFAULT_PAYOUT_CHARGES_SUCCESS,
    payload:dataByID
  }
};
export const addDefaultPayoutChargeGenerator =(data=[])=>{
  return{
    type:ADD_DEFAULT_PAYOUT_CHARGE,
    payload:data
  }
};
export const addDefaultPayoutChargeSuccessGenerator =(data)=>{
  return{
    type:ADD_DEFAULT_PAYOUT_CHARGE_SUCCESS,
    payload:data
  }
};
export const editDefaultPayoutChargeGenerator =(data=[])=>{
  return{
    type:EDIT_DEFAULT_PAYOUT_CHARGE,
    payload:data
  }
};
export const editDefaultPayoutChargeSuccessGenerator =(data)=>{
  return{
    type:EDIT_DEFAULT_PAYOUT_CHARGE_SUCCESS,
    payload:data
  }
};
export const deleteDefaultPayoutChargeGenerator =(data=[])=>{
  return{
    type:DELETE_DEFAULT_PAYOUT_CHARGE,
    payload:data
  }
};
export const deleteDefaultPayoutChargeSuccessGenerator =(data)=>{
  return{
    type:DELETE_DEFAULT_PAYOUT_CHARGE_SUCCESS,
    payload:data
  }
};

