import axios from 'axios';
import {API_ENDPOINT,} from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import {all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import { push } from 'connected-react-router';
import {
  ADD_COMPANY_LOANS,
  addCompanyLoanSuccessGenerator, AUTO_CALCULATE_REPAYMENT, autoCalculateRepaymentSuccessGenerator,
  FETCH_COMPANY_LOANS,
  FETCH_LENDERS, FETCH_LOAN_BY_DEFAULT, FETCH_LOAN_BY_NEW, FETCH_LOAN_BY_PENDING,
  FETCH_LOAN_BY_STATUS,
  fetchCompanyLoansGenerator,
  fetchCompanyLoansSuccessGenerator,
  fetchLenderSuccessGenerator, fetchLoanByDefaultSuccessGenerator, fetchLoanByNewSuccessGenerator,
  fetchLoanByPendingSuccessGenerator,
  fetchLoanByStatusSuccessGenerator, PAUSE_UNPAUSE_COMPANY_LOAN
} from "../../actions/admin/CompanyLoansActions";
import {
  DELETE_MY_COMPANY_LOAN,
  deleteMyCompanyLoanSuccessGenerator, EDIT_MY_COMPANY_LOAN,
  editMyCompanyLoanSuccessGenerator, getMyCompanyLoanGenerator
} from "../../actions/payroll/LoansActions";

const fetchLoansByNewRequest =async (requestData)=>
    axios.get(API_ENDPOINT+'/admin/company/loan/NEW/'+requestData.company_id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);
const fetchLoansBYPendingRequest =async (requestData)=>
    axios.get(API_ENDPOINT+'/admin/company/loan/ACTIVE/'+requestData.company_id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);
const fetchLoansBYDefaultedRequest =async (requestData)=>
    axios.get(API_ENDPOINT+'/admin/company/loan/DEFAULTED/'+requestData.company_id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const fetchLendersRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/admin/company/loan/lender/'+requestData.company_id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const fetchCompanyLoansRequest = async (requestData)=>
  await axios.get(API_ENDPOINT+"/admin/company/loan",{params:requestData})
    .then(response=>response)
    .catch(error=>error.response);

const addCompanyLoanRequest =async (requestData)=>
  await axios.post(API_ENDPOINT+"/admin/company/loan/lend",requestData)
    .then(response=>response)
    .catch(error=>error.response);
const editCompanyLoanRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/edit',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const deleteCompanyLoanRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/delete',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const pauseUnpauseCompanyLoanRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/pause-unpause-loan',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const autoCalculateRepaymentRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/admin/company/loan/auto-calculate-repayment',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

function* pauseUnpauseCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(pauseUnpauseCompanyLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchCompanyLoansGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* autoCalculateRepayment({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(autoCalculateRepaymentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //yield put(showMessageGenerator(response.data.message,'success'));
        let data = response.data.data;
        yield put(autoCalculateRepaymentSuccessGenerator(data))
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editCompanyLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
          const loan = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        //yield put(editMyCompanyLoanSuccessGenerator(loan));
        yield put(getMyCompanyLoanGenerator())
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(deleteCompanyLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
          const loan = response.data.data;
          yield put(showMessageGenerator(response.data.message,'success'));
        // yield put(deleteMyCompanyLoanSuccessGenerator(loan));
        yield put(getMyCompanyLoanGenerator())
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLoansByNew({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoansByNewRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
          const loansNew = response.data.data;
          yield put(fetchLoanByNewSuccessGenerator(loansNew));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLoansBYPending({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoansBYPendingRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
          let loansPending = response.data.data;
          yield put(fetchLoanByPendingSuccessGenerator(loansPending))
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLoansBYDefaulted({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoansBYDefaultedRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
          let loansDefault = response.data.data;
          yield put(fetchLoanByDefaultSuccessGenerator(loansDefault));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchLenders({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLendersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let lenders = response.data.data;
        yield put(fetchLenderSuccessGenerator(lenders));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(addCompanyLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompanyLoansGenerator());
        yield put(push('/admin/companies-loans'));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchCompanyLoans({type,payload}) {
  try {
    yield put(showAppLoaderGenerator(type));
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchCompanyLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let companyLoans = response.data.data;
        yield put(fetchCompanyLoansSuccessGenerator(companyLoans));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchLoansByNewDefault() {
yield takeLatest(FETCH_LOAN_BY_NEW,fetchLoansByNew);
}
export function* fetchLoansByPendingDefault() {
yield takeLatest(FETCH_LOAN_BY_PENDING,fetchLoansBYPending);
}
export function* fetchLoansByDefaultedDefault() {
yield takeLatest(FETCH_LOAN_BY_DEFAULT,fetchLoansBYDefaulted);
}
export function* fetchLendersDefault() {
yield takeLatest(FETCH_LENDERS,fetchLenders);
}
export function* addCompanyLoansDefault() {
yield takeLatest(ADD_COMPANY_LOANS,addCompanyLoan);
}
export function* fetchCompanyLoansDefault() {
  yield takeLatest(FETCH_COMPANY_LOANS,fetchCompanyLoans);
}
export function* editCompanyLoanDefault() {
  yield takeLatest(EDIT_MY_COMPANY_LOAN,editCompanyLoan);
}
export function* deleteCompanyLoanDefault() {
  yield takeLatest(DELETE_MY_COMPANY_LOAN,deleteCompanyLoan);
}
export function* autoCalculateRepaymentDefault() {
 yield takeLatest(AUTO_CALCULATE_REPAYMENT,autoCalculateRepayment);
}
export function* pauseUnpauseCompanyLoanDefault() {
 yield takeLatest(PAUSE_UNPAUSE_COMPANY_LOAN,pauseUnpauseCompanyLoan);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCompanyLoansDefault),
    fork(addCompanyLoansDefault),
      fork(fetchLendersDefault),
      fork(fetchLoansByNewDefault),
      fork(fetchLoansByPendingDefault),
      fork(fetchLoansByDefaultedDefault),
      fork(editCompanyLoanDefault),
      fork(deleteCompanyLoanDefault),
      fork(autoCalculateRepaymentDefault),
      fork(pauseUnpauseCompanyLoanDefault),
  ]);
}
