import {
  CREATE_MARKET_LENDING_RATES_SUCCESS, DELETE_MARKET_LENDING_RATES_SUCCESS, EDIT_MARKET_LENDING_RATES_SUCCESS,
  FETCH_MARKET_LENDING_RATES_SUCCESS
} from "../../actions/payroll/FringeBenefitActions";
const INIT_STATE ={
  MarketLendingRatesByID:{}
}

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_MARKET_LENDING_RATES_SUCCESS:
      return {
        ...state,
        MarketLendingRatesByID: action.payload
      };
    case CREATE_MARKET_LENDING_RATES_SUCCESS:
    case EDIT_MARKET_LENDING_RATES_SUCCESS:
      let rate_id = action.payload.id;
      return {
        ...state,
        MarketLendingRatesByID: {
          ...state.MarketLendingRatesByID,
          [rate_id]:action.payload
        }
      };
    case DELETE_MARKET_LENDING_RATES_SUCCESS:
      let MarketLendingRatesByID = {...state.MarketLendingRatesByID};
      delete MarketLendingRatesByID[action.payload.id];
      return {
        ...state,
        MarketLendingRatesByID:MarketLendingRatesByID
      };
    default: return {...state}
  }
}
