import {
  ADD_PERFORMANCE_RATING_SUCCESS,
  DELETE_PERFORMANCE_RATING_SUCCESS,
  EDIT_PERFORMANCE_RATING_SUCCESS,
  FETCH_PERFORMANCE_RATINGS_SUCCESS
} from '../../actions/appraisal/PerformanceRatingActions';

const INIT_STATE = {
  performanceRatingsByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PERFORMANCE_RATINGS_SUCCESS:
      return {
        ...state,
        performanceRatingsByID: action.payload
      };
    case ADD_PERFORMANCE_RATING_SUCCESS:
    case EDIT_PERFORMANCE_RATING_SUCCESS:
      let performance_rating_id = action.payload.id;
      return {
        ...state,
        performanceRatingsByID: {
          ...state.performanceRatingsByID,
          [performance_rating_id]:action.payload
        }
      };
    case DELETE_PERFORMANCE_RATING_SUCCESS:
      let performanceRatingsByID = {...state.performanceRatingsByID};
      delete performanceRatingsByID[action.payload.id];
      return {
        ...state,
        performanceRatingsByID:performanceRatingsByID
      };
    default:
      return state;
  }
}
