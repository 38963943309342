import {
  FETCH_ONBOARDING_WORKFLOW_SUCCESS, ADD_ONBOARDING_WORKFLOW_SUCCESS
} from "../../actions/admin/CompanyOnboardingActions";

const INIT_STATE = {
  onboardingCompanyWorkflowByID: {}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_ONBOARDING_WORKFLOW_SUCCESS:
      return {
        ...state,
        onboardingCompanyWorkflowByID: action.payload
      };
    case ADD_ONBOARDING_WORKFLOW_SUCCESS:
      let id = action.payload.id;
      return {
        ...state,
        onboardingCompanyWorkflowByID: {...state.onboardingCompanyWorkflowByID, [id]:action.payload}
      };

    default:
      return state;
  }
}
