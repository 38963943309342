export const  MANUAL_CLOCK = 'MANUAL_CLOCK';
export const  MANUAL_CLOCK_SUCCESS = 'MANUAL_CLOCK_SUCCESS';
export const DELETE_ATTENDANCE_RECORD="DELETE_ATTENDANCE_RECORD";
export const DELETE_ATTENDANCE_RECORD_SUCCESS="DELETE_ATTENDANCE_RECORD_SUCCESS";
export const UPDATE_ATTENDANCE_RECORD ='UPDATE_ATTENDANCE_RECORD';
export const UPDATE_ATTENDANCE_RECORD_SUCCESS ='UPDATE_ATTENDANCE_RECORD_SUCCESS';
export const FETCH_ATTENDANCE ='FETCH_ATTENDANCE';
export const FETCH_ATTENDANCE_SUCCESS ='FETCH_ATTENDANCE_SUCCESS';
export const FETCH_ATTENDANCE_DASHBOARD = "FETCH_ATTENDANCE_DASHBOARD";
export const FETCH_ATTENDANCE_DASHBOARD_SUCCESS = "FETCH_ATTENDANCE_DASHBOARD_SUCCESS";
export const FETCH_ATTENDANCE_KEYS = "FETCH_ATTENDANCE_KEYS";
export const FETCH_ATTENDANCE_KEYS_SUCCESS = "FETCH_ATTENDANCE_KEYS_SUCCESS";
export const UPDATE_ATTENDANCE_KEY = "UPDATE_ATTENDANCE_KEY";
export const UPDATE_ATTENDANCE_KEY_SUCCESS = "UPDATE_ATTENDANCE_KEY_SUCCESS";


export const fetchAttendanceGenerator =(data=[])=>{
  return {
    type:FETCH_ATTENDANCE,
    payload:data
  }
};
export const fetchAttendanceSuccessGenerator =(data)=>{
  return {
    type:FETCH_ATTENDANCE_SUCCESS,
    payload:data
  }
};
export const fetchAttendanceDashboardGenerator =(data=[])=>{
  return {
    type:FETCH_ATTENDANCE_DASHBOARD,
    payload:data
  }
};
export const fetchAttendanceDashboardSuccessGenerator =(data)=>{
  return {
    type:FETCH_ATTENDANCE_DASHBOARD_SUCCESS,
    payload:data
  }
};
export const manualClockGenerator = (data=[])=>{
  return {
    type:MANUAL_CLOCK,
    payload:data
  };
};
export const manualClockSuccessGenerator = (dataById)=> {
  return {
    type:MANUAL_CLOCK_SUCCESS,
    payload:dataById
  }
};

export const deleteAttendanceRecordGenerator = (data=[])=>{
  return {
    type:DELETE_ATTENDANCE_RECORD,
    payload:data
  };
};
export const deleteAttendanceRecordSuccessGenerator = (dataById)=> {
  return {
    type:DELETE_ATTENDANCE_RECORD_SUCCESS,
    payload:dataById
  }
};
export const updateAttendanceRecordGenerator =(data=[])=>{
  return {
    type:UPDATE_ATTENDANCE_RECORD,
    payload:data
  }
};
export const updateAttendanceRecordSuccessGenerator =(data)=>{
  return {
    type:UPDATE_ATTENDANCE_RECORD_SUCCESS,
    payload:data
  }
};
export const fetchAttendanceKeysGenerator =(data=[])=>{
  return {
    type:FETCH_ATTENDANCE_KEYS,
    payload:data
  }
};
export const fetchAttendanceKeysSuccessGenerator =(data)=>{
  return {
    type:FETCH_ATTENDANCE_KEYS_SUCCESS,
    payload:data
  }
};
export const updateAttendanceKeyGenerator =(data=[])=>{
  return {
    type:UPDATE_ATTENDANCE_KEY,
    payload:data
  }
};
export const updateAttendanceKeySuccessGenerator =(data)=>{
  return {
    type:UPDATE_ATTENDANCE_KEY_SUCCESS,
    payload:data
  }
};
