import {
  ADD_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  EDIT_COMPANY_SUCCESS,
  FETCH_COMPANIES_SUCCESS,
  HIDE_COMPANY_BRANCHES,
  HIDE_COMPANY_CREATE_FORM,
  HIDE_COMPANY_DELETE_FORM,
  HIDE_COMPANY_EDIT_FORM,
  SHOW_COMPANY_BRANCHES,
  SHOW_COMPANY_CREATE_FORM,
  SHOW_COMPANY_DELETE_FORM,
  SHOW_COMPANY_EDIT_FORM,
  SWITCH_COMPANY_SUCCESS, SWITCH_COUNTRY_SUCCESS,
  UPLOAD_COMPANY_LOGO_SUCCESS
} from "../../actions/common/CompanyActions";
import {SIGNIN_USER_SUCCESS, SIGNOUT_USER_SUCCESS, SIGNUP_USER_SUCCESS} from '../../../constants/ActionTypes';
import {Helpers} from '../../../util/Helpers';
import { AUTH_SWITCHED_APP, SWITCHED_COUNTRY_KEY } from "../../../constants/SessionKeys";


const INIT_STATE = {
  companiesByID:{},
  switchedCompanyID: Helpers.authSwitchedCompanyID(),
  currentHeadOfficeID: Helpers.authCurrentHeadOfficeID(),
  showCompanyCreateForm:false,
  companyToCreateIsBranch:false,
  showCompanyEditForm:false,
  companyToEdit:{},
  showCompanyDeleteForm:false,
  companyToDelete:{},
  showCompanyBranches:false,
  companyIDToViewBranches:0,
  switchedCountry: Helpers.authSwitchedCountry(),
};

export default (state = INIT_STATE, action)=>{
    switch (action.type) {
      case SIGNOUT_USER_SUCCESS: {
        return {
          ...state,
          switchedCompanyID: null
        }
      }
      case SIGNUP_USER_SUCCESS:
        return {
          ...state,
          switchedCompanyID: action.payload.company_id,
          currentHeadOfficeID: action.payload.head_office_id
        };
      case SIGNIN_USER_SUCCESS:
        return {
          ...state,
          switchedCompanyID: action.payload.company_id,
          currentHeadOfficeID: action.payload.head_office_id
        };
      case FETCH_COMPANIES_SUCCESS:
        return {
          ...state,
          companiesByID:action.payload
        };
      case SHOW_COMPANY_CREATE_FORM:
        return {
          ...state,
          showCompanyCreateForm: true,
          companyToCreateIsBranch: action.payload
        };
      case HIDE_COMPANY_CREATE_FORM:
        return {
          ...state,
          showCompanyCreateForm: false
        };
      case SWITCH_COMPANY_SUCCESS:
        let data = {...state.companiesByID};
        if(Helpers.isSystemAdmin()){
          data[action.payload.id]=action.payload;
        }
        if(Helpers.getItem(AUTH_SWITCHED_APP) === 'admin'){
          Helpers.saveItem(AUTH_SWITCHED_APP,'hrm');
        }
        return {
          ...state,
          switchedCompanyID: action.payload.id,
          currentHeadOfficeID: action.payload.head_office_id,
          companiesByID:data,
        };
      case SHOW_COMPANY_EDIT_FORM:
        return {
          ...state,
          showCompanyEditForm: true,
          companyToEdit: action.payload
        };
      case HIDE_COMPANY_EDIT_FORM:
        return {
          ...state,
          showCompanyEditForm: false
        };
      case ADD_COMPANY_SUCCESS:
      case EDIT_COMPANY_SUCCESS:
       case UPLOAD_COMPANY_LOGO_SUCCESS:
        let company_id = action.payload.id;
        if(action.payload.is_head_office == 1){
          return {
            ...state,
            companiesByID: {
              ...state.companiesByID,
              [company_id]:action.payload
            }
          };
        }
        company_id = action.payload.head_office_id;
        let branch_id = action.payload.id;
        return {
          ...state,
          companiesByID: {
            ...state.companiesByID,
            [company_id]:{
              ...state.companiesByID[company_id],
              branches:{
                ...state.companiesByID[company_id]['branches'],
                [branch_id]:action.payload
              }
            }
          }
        };
      case SHOW_COMPANY_DELETE_FORM:
        return {
          ...state,
          showCompanyDeleteForm: true,
          companyToDelete: action.payload
        };
      case HIDE_COMPANY_DELETE_FORM:
        return {
          ...state,
          showCompanyDeleteForm: false
        };
      case DELETE_COMPANY_SUCCESS:
        let companiesByID = {...state.companiesByID};
        if(action.payload.is_head_office == 1){
          delete companiesByID[action.payload.id];
        }else{
          let company = companiesByID[action.payload.head_office_id];
          let branches = company.branches;
          delete branches[action.payload.id];
          company.branches = branches;
          companiesByID[action.payload.head_office_id] = company;
        }
        return {
           ...state,
          companiesByID:companiesByID,
          force_refresh:'force'
        };
      case SHOW_COMPANY_BRANCHES:
        return {
          ...state,
          showCompanyBranches: true,
          companyIDToViewBranches: action.payload
        };
      case HIDE_COMPANY_BRANCHES:
        return {
          ...state,
          showCompanyBranches: false,
          companyIDToViewBranches: action.payload
        };
      case SWITCH_COUNTRY_SUCCESS:
        Helpers.saveItem(SWITCHED_COUNTRY_KEY, action.payload);
        return {
          ...state,
          switchedCountry: action.payload
        };
      default:
        return state;
    }
}
