import { call, put,all,fork,takeLatest } from "redux-saga/effects";
import  axios from "axios";
import {API_ADDRESS, API_ENDPOINT, BETA_API_ENDPOINT, NODE_API_ENDPOINT} from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import { push } from  "connected-react-router";
import {
    ADD_COMPANY_BALANCE,
    ADD_COMPANY_EMPLOYEES,
    BULK_UPDATE_TRANSACTIONS,
    COMPANY_PROFILE,
    companyProfileGenerator,
    companyProfileSuccessGenerator,
    CREATE_PAYMENT_CHANNEL,
    createCompanyPaymentChannelSuccess,
    DEACTIVATE_COMPANY_SUBSCRIPTION,
    ENABLE_APPRAISAL_COMPANY,
    EXPORT_COMPANY_BALANCES,
    FETCH_ADMIN_COMPANIES, FETCH_ADMIN_PAGINATED_COMPANY_WALLETS,
    FETCH_ALL_TRANSACTIONS,
    FETCH_COMPANY_TRANSACTIONS,
    FETCH_TRANSACTION_ACCOUNTS,
    fetchAdminCompaniesGenerator,
    fetchAdminCompaniesSuccessGenerator,
    fetchAdminPaginatedCompaniesSuccessGenerator,
    fetchAdminPaginatedCompanyWalletsSuccessGenerator,
    fetchAllTransactionsGenerator,
    fetchAllTransactionsSuccessGenerator,
    fetchCompanyTransactionsSuccessGenerator,
    fetchTransactionAccountsSuccessGenerator,
    GENERATE_QRCODE,
    GET_THREE_PAYROLLS_FOR_A_COMPANY,
    getThreePayrollsForACompanySuccessGenerator,
    MARK_EMAIL_VERIFIED,
    markEmailVerifiedSuccessGenerator,
    UPDATE_COMPANY_BALANCE_LIMIT, EXPORT_COMPANIES_BALANCES
} from "../../actions/admin/CompanyActions";
import {fetchCompaniesWithAppraisalGenerator} from "../../actions/admin/AppraisalActions";
const deactivateCompanySubscriptionRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/tozza/subscription/'+requestData.id+'/'+requestData.status,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const addCompanyBalanceRequest = async  (requestData)=>
    await axios.post(API_ENDPOINT+'/tozza/companybalance/update',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const addCompanyEmployeesRequest = async  (requestData)=>
  await axios.post(API_ENDPOINT+'/tozza/company/employees/update',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const viewCompanyProfileRequest = async (requestData) =>
    await axios.get(API_ENDPOINT+'/tozza/users/profile/'+requestData.id+'/companies',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const generateQrCodeRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/tozza/create/qrcode',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const fetchAllCompaniesRequest= async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/tozza/companies',{params:requestParameters})
        .then(response=>response)
        .catch(error=>error.response);

const fetchCompanyWalletsRequest= async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/tozza/companies-wallets/'+requestParameters.auth_company_id,{params:requestParameters})
        .then(response=>response)
        .catch(error=>error.response);

const fetchCompanyTransactionsRequest =async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/tozza/company/transactions',{params:requestParameters})
        .then(response=>response)
        .catch(error=>error.response);

const fetchTransactionAccountsRequest =async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/tozza/daily/transaction-accounts',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const createCompanyModeOfPaymentRequest =async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/tozza/company/payment/mode',requestParameters)
        .then(response=>response)
        .catch(error=>error.response);

const fetchAllTransactionRequest =async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/tozza/all/transaction',{params:requestParameters})
        .then(response=>response)
        .catch(error=>error.response);

const enableAppraisalCompanyRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/tozza/appraisal/companies',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const exportCompanyBalancesRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/tozza/companies/export/balances',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const bulkUpdateTransactionsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/all/transactions/bulk/update',requestParameters, {headers: {
          'content-type': `multipart/form-data`
      }})
    .then(response => response)
    .catch(error => error.response);

const markEmailVerifiedRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/setup/user/mark-email',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const updateCompanyBalanceLimitRequest = async  (requestData)=>
  await axios.post(API_ENDPOINT+'/tozza/companybalance/limit/update',requestData)
    .then(response=>response)
    .catch(error=>error.response);

function* markEmailVerified({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload,token:Helpers.token()};
        const response = yield call(markEmailVerifiedRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(markEmailVerifiedSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* exportCompanyBalances({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload,token:Helpers.token()};
        const response = yield call(exportCompanyBalancesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                // yield put(companyProfileGenerator({id:response.data.data}));
                // yield put(exportCompaniesBalancesSuccessGenerator({id:response.data.data}));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
     yield put(hideAppLoaderGenerator(type));
}
function* deactivateCompanySubscription({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(deactivateCompanySubscriptionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(showMessageGenerator(response.data.message,'success'));
               yield put(companyProfileGenerator({id:response.data.data}));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
     yield put(hideAppLoaderGenerator(type));
}

function* viewCompanyProfile({type, payload}){
     yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(viewCompanyProfileRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(push('/admin/company-profile',response.data.data));
                yield put(companyProfileSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
     yield put(hideAppLoaderGenerator(type));
}

function* addCompanyBalance({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(addCompanyBalanceRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* addCompanyEmployees({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(addCompanyEmployeesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* generateQrCode({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(generateQrCodeRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
               window.open(API_ADDRESS+response.data.data,'_blank');
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* bulkUpdateTransactions({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const formData = Helpers.getFormData(requestParameters);
        const response = yield call(bulkUpdateTransactionsRequest, formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchAllTransactionsGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* enableAppraisalCompany({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(enableAppraisalCompanyRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            yield put(fetchCompaniesWithAppraisalGenerator());
            yield put(showMessageGenerator(response.data.message,'success'));
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchAllTransactions({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchAllTransactionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchAllTransactionsSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchCompanyTransactions({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchCompanyTransactionsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchCompanyTransactionsSuccessGenerator(response.data.data));
                yield put(push('/admin/company/transaction',response.data.data));
                /*yield put(showMessageGenerator(response.data.message));*/
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* createCompanyModeOfPayment({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(createCompanyModeOfPaymentRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(createCompanyPaymentChannelSuccess(response.data.data));
                yield put(fetchAdminCompaniesGenerator({status: 'all', recordsPerPage: 15}));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchAllCompanies({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchAllCompaniesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                if (response.data.data.hasOwnProperty("per_page")){
                    yield put(fetchAdminPaginatedCompaniesSuccessGenerator(response.data.data));
                }else {
                    yield put(fetchAdminCompaniesSuccessGenerator(response.data.data));
                }
                /*yield put(showMessageGenerator(response.data.message));*/
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchCompanyWallets({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchCompanyWalletsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchAdminPaginatedCompanyWalletsSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchTransactionAccounts({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchTransactionAccountsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchTransactionAccountsSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* updateCompanyBalanceLimit({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(updateCompanyBalanceLimitRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchAdminCompaniesGenerator());
                yield put(showMessageGenerator(response.data.message));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* deactivateCompanySubscriptionDefault() {
    yield takeLatest(DEACTIVATE_COMPANY_SUBSCRIPTION,deactivateCompanySubscription)
}
export function* viewCompanyProfileDefault(){
    yield takeLatest(COMPANY_PROFILE,viewCompanyProfile);
}

export function* generateQrCodeDefault() {
    yield takeLatest(GENERATE_QRCODE,generateQrCode);
}
export function* createCompanyModeOfPaymentDefault(){
    yield takeLatest(CREATE_PAYMENT_CHANNEL,createCompanyModeOfPayment)
}
export function* fetchAllCompaniesDefault(){
    yield takeLatest(FETCH_ADMIN_COMPANIES,fetchAllCompanies);
}
export function* fetchCompanyTransactionsDefault() {
    yield takeLatest(FETCH_COMPANY_TRANSACTIONS,fetchCompanyTransactions);
}
export function* fetchAllTransactionDefault() {
    yield takeLatest(FETCH_ALL_TRANSACTIONS,fetchAllTransactions)
}
export function* enableAppraisalCompanyDefault() {
    yield takeLatest(ENABLE_APPRAISAL_COMPANY,enableAppraisalCompany)
}
export function* bulkUpdateTransactionsDefault() {
    yield takeLatest(BULK_UPDATE_TRANSACTIONS,bulkUpdateTransactions)
}
export function* addCompanyBalanceDefault() {
    yield takeLatest(ADD_COMPANY_BALANCE,addCompanyBalance);
}

export function* addCompanyEmployeesDefault() {
    yield takeLatest(ADD_COMPANY_EMPLOYEES,addCompanyEmployees);
}

export function* exportCompanyBalancesDefault() {
    yield takeLatest(EXPORT_COMPANY_BALANCES,exportCompanyBalances);
}
export function* markEmailVerifiedDefault(){
    yield takeLatest(MARK_EMAIL_VERIFIED,markEmailVerified)
}
export function* fetchTransactionAccountsDefault(){
    yield takeLatest(FETCH_TRANSACTION_ACCOUNTS,fetchTransactionAccounts)
}

export function* fetchCompanyWalletsDefault(){
    yield takeLatest(FETCH_ADMIN_PAGINATED_COMPANY_WALLETS,fetchCompanyWallets)
}

////get three payrolls of a company

const getThreePayrollsForACompanyRequest = async (requestData)=>
  await axios.get(API_ENDPOINT+'/admin/company/loan/three/payrolls/'+requestData.company_id,{params:requestData})
    .then(response=>response)
    .catch(error=>error.response)

function* getThreePayrollsForACompany({payload}){
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(getThreePayrollsForACompanyRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(getThreePayrollsForACompanySuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
}
export function* getThreePayrollsForACompanyDefault() {
  yield takeLatest(GET_THREE_PAYROLLS_FOR_A_COMPANY,getThreePayrollsForACompany)
}
export function* updateCompanyBalanceLimitDefault() {
    yield takeLatest(UPDATE_COMPANY_BALANCE_LIMIT,updateCompanyBalanceLimit)
}


export default function* rootSaga() {
    yield all([
        fork(fetchAllCompaniesDefault),
        fork(createCompanyModeOfPaymentDefault),
        fork(fetchCompanyTransactionsDefault),
        fork(fetchAllTransactionDefault),
        fork(enableAppraisalCompanyDefault),
        fork(bulkUpdateTransactionsDefault),
        fork(generateQrCodeDefault),
        fork(addCompanyBalanceDefault),
        fork(viewCompanyProfileDefault),
        fork(deactivateCompanySubscriptionDefault),
        fork(exportCompanyBalancesDefault),
       fork(markEmailVerifiedDefault),
      fork(fetchTransactionAccountsDefault),
      fork(getThreePayrollsForACompanyDefault),
      fork(updateCompanyBalanceLimitDefault),
      fork(addCompanyEmployeesDefault),
      fork(fetchCompanyWalletsDefault)
    ]);
}
