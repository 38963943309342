export const FETCH_INSURANCE_PREMIUM = 'FETCH_INSURANCE_PREMIUM';
export const FETCH_INSURANCE_PREMIUM_SUCCESS = 'FETCH_INSURANCE_PREMIUM_SUCCESS';
export const CREATE_INSURANCE_PREMIUM = 'CREATE_INSURANCE_PREMIUM';
export const CREATE_INSURANCE_PREMIUM_SUCCESS = 'CREATE_INSURANCE_PREMIUM_SUCCESS';
export const EDIT_INSURANCE_PREMIUM = 'EDIT_INSURANCE_PREMIUM';
export const EDIT_INSURANCE_PREMIUM_SUCCESS = 'EDIT_INSURANCE_PREMIUM_SUCCESS';
export const DELETE_INSURANCE_PREMIUM = 'DELETE_INSURANCE_PREMIUM';
export const DELETE_INSURANCE_PREMIUM_SUCCESS = 'DELETE_INSURANCE_PREMIUM_SUCCESS';
export const BULK_UPLOAD_INSURANCE_PREMIUM = 'BULK_UPLOAD_INSURANCE_PREMIUM';

export const bulkUploadInsurancePremiumGenerator =(payload=[])=>{
  return {
    type: BULK_UPLOAD_INSURANCE_PREMIUM,
    payload
  }
};
export const fetchInsurancePremiumGenerator = (payload=[])=>{
  return {
    type:FETCH_INSURANCE_PREMIUM,
    payload
  }
};
export const fetchInsurancePremiumSuccessGenerator = (payload=[])=>{
  return {
    type:FETCH_INSURANCE_PREMIUM_SUCCESS,
    payload
  }
};
export const createInsurancePremiumGenerator = (payload=[])=>{
  return {
    type:CREATE_INSURANCE_PREMIUM,
    payload
  }
};
export const createInsurancePremiumSuccessGenerator = (payload=[])=>{
  return {
    type:CREATE_INSURANCE_PREMIUM_SUCCESS,
    payload
  }
};
export const editInsurancePremiumGenerator = (payload=[])=>{
  return {
    type:EDIT_INSURANCE_PREMIUM,
    payload
  }
};
export const editInsurancePremiumSuccessGenerator = (payload=[])=>{
  return {
    type:EDIT_INSURANCE_PREMIUM_SUCCESS,
    payload
  }
}
export const deleteInsurancePremiumGenerator = (payload=[])=>{
  return {
    type:DELETE_INSURANCE_PREMIUM,
    payload
  }
};
export const deleteInsurancePremiumSuccessGenerator = (payload=[])=>{
  return {
    type:DELETE_INSURANCE_PREMIUM_SUCCESS,
    payload
  }
}
