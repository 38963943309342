export const ADD_LEAVE_TYPE_CATEGORY = 'ADD_LEAVE_TYPE_CATEGORY';
export const ADD_LEAVE_TYPE_CATEGORY_SUCCESS = 'ADD_LEAVE_TYPE_CATEGORY_SUCCESS';
export const SHOW_CREATE_LEAVE_TYPE_CATEGORY_FORM = 'SHOW_CREATE_LEAVE_TYPE_CATEGORY_FORM';
export const HIDE_CREATE_LEAVE_TYPE_CATEGORY_FORM = 'HIDE_CREATE_LEAVE_TYPE_CATEGORY_FORM';
/// fetch
export const FETCH_LEAVE_TYPE_CATEGORY = 'FETCH_LEAVE_TYPE_CATEGORY';
export const FETCH_LEAVE_TYPE_CATEGORY_SUCCESS = 'FETCH_LEAVE_TYPE_CATEGORY_SUCCESS';
// ////edit
export const EDIT_LEAVE_TYPE_CATEGORY = 'EDIT_LEAVE_TYPE_CATEGORY';
export const EDIT_LEAVE_TYPE_CATEGORY_SUCCESS = 'EDIT_LEAVE_TYPE_CATEGORY_SUCCESS';
export const SHOW_EDIT_LEAVE_TYPE_CATEGORY_FORM = 'SHOW_EDIT_LEAVE_TYPE_CATEGORY_FORM';
export const HIDE_EDIT_LEAVE_TYPE_CATEGORY_FORM = 'HIDE_EDIT_LEAVE_TYPE_CATEGORY_FORM';
// ////delete
export const DELETE_LEAVE_TYPE_CATEGORY = 'DELETE_LEAVE_TYPE_CATEGORY';
export const DELETE_LEAVE_TYPE_CATEGORY_SUCCESS ='DELETE_LEAVE_TYPE_CATEGORY_SUCCESS';
export const SHOW_DELETE_LEAVE_TYPE_CATEGORY_MODAL = 'SHOW_DELETE_LEAVE_TYPE_CATEGORY_MODAL';
export const HIDE_DELETE_LEAVE_TYPE_CATEGORY_MODAL = 'HIDE_DELETE_LEAVE_TYPE_CATEGORY_MODAL';
/////CREATE
export const addLeaveTypeCategoryGenerator = (data)=>{
  return {
    type:ADD_LEAVE_TYPE_CATEGORY ,
    payload: data
  }
};
export const showLeaveTypeCategoryCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_LEAVE_TYPE_CATEGORY_FORM,
    payload:data
  }
};
export const hideLeaveTypeCategoryCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_LEAVE_TYPE_CATEGORY_FORM,
    payload:data
  }
};
export const addLeaveTypeCategorySuccessGenerator =(data)=>{
  return {
    type:ADD_LEAVE_TYPE_CATEGORY_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchLeaveTypeCategoryGenerator=(data=[])=>{
  return {
    type:FETCH_LEAVE_TYPE_CATEGORY,
    payload:data
  }
};

export const fetchLeaveTypeCategorySuccessGenerator =(data)=>{
  return {
    type:FETCH_LEAVE_TYPE_CATEGORY_SUCCESS,
    payload:data
  }
};


///EDIT
export const editLeaveTypeCategoryGenerator = (data)=>{
  return {
    type: EDIT_LEAVE_TYPE_CATEGORY,
    payload: data
  }
};

export const editLeaveTypeCategorySuccessGenerator=(data)=>{
  return {
    type:EDIT_LEAVE_TYPE_CATEGORY_SUCCESS,
    payload:data
  }
};
export const showLeaveTypeCategoryEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_LEAVE_TYPE_CATEGORY_FORM,
    payload:data
  }
};
export const hideLeaveTypeCategoryEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_LEAVE_TYPE_CATEGORY_FORM,
    payload:data
  }
};
// /////DELETE
export const deleteLeaveTypeCategoryGenerator=(data)=>{
  return {
    type:DELETE_LEAVE_TYPE_CATEGORY,
    payload:data
  }
};

export const deleteLeaveTypeCategorySuccessGenerator = (data)=>{
  return {
    type:DELETE_LEAVE_TYPE_CATEGORY_SUCCESS,
    payload:data
  }
};
export const showLeaveTypeCategoryDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_LEAVE_TYPE_CATEGORY_MODAL,
    payload:data
  }
};
export const hideLeaveTypeCategoryDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_LEAVE_TYPE_CATEGORY_MODAL,
    payload:data
  }
};


