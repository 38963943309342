export const FETCH_LEAVE_BALANCE_REPORT = 'FETCH_LEAVE_BALANCE_REPORT';
export const FETCH_LEAVE_BALANCE_REPORT_SUCCESS = 'FETCH_LEAVE_BALANCE_REPORT_SUCCESS';

export const FETCH_AUDIT_LOG_REPORT = 'FETCH_AUDIT_LOG_REPORT';
export const FETCH_AUDIT_LOG_REPORT_SUCCESS = 'FETCH_AUDIT_LOG_REPORT_SUCCESS';
export const SHOW_AUDIT_LOG_REPORT_FORM = 'SHOW_AUDIT_LOG_REPORT_FORM';
export const HIDE_AUDIT_LOG_REPORT_FORM = 'HIDE_AUDIT_LOG_REPORT_FORM';

export const FETCH_LOAN_PAYMENT_REPORT = 'FETCH_LOAN_PAYMENT_REPORT';
export const FETCH_LOAN_PAYMENT_REPORT_SUCCESS = 'FETCH_LOAN_PAYMENT_REPORT_SUCCESS';

export const FETCH_STATUTORY_REPORT = 'FETCH_STATUTORY_REPORT';
export const FETCH_STATUTORY_REPORT_SUCCESS = 'FETCH_STATUTORY_REPORT_SUCCESS';
export const SHOW_STATUTORY_FORM = 'SHOW_STATUTORY_FORM';
export const HIDE_STATUTORY_FORM = 'HIDE_STATUTORY_FORM';

export const FETCH_ALLOWANCE_LEDGER = 'FETCH_ALLOWANCE_LEDGER';
export const FETCH_ALLOWANCE_LEDGER_SUCCESS = 'FETCH_ALLOWANCE_LEDGER_SUCCESS';
export const SHOW_ALLOWANCE_LEDGER_FORM = 'SHOW_ALLOWANCE_LEDGER_FORM';
export const HIDE_ALLOWANCE_LEDGER_FORM = 'HIDE_ALLOWANCE_LEDGER_FORM';

export const FETCH_MUSTER_ROLL_SUCCESS = 'FETCH_MUSTER_ROLL_SUCCESS';
export const FETCH_MUSTER_ROLL = 'FETCH_MUSTER_ROLL';
export const SHOW_MUSTER_ROLL_FORM = 'SHOW_MUSTER_ROLL_FORM';
export const HIDE_MUSTER_ROLL_FORM = 'HIDE_MUSTER_ROLL_FORM';

export const FETCH_VIEW_PAYSLIPS = 'FETCH_VIEW_PAYSLIPS';
export const FETCH_VIEW_PAYSLIPS_SUCCESS = 'FETCH_VIEW_PAYSLIPS_SUCCESS';
export const SHOW_VIEW_PAYSLIPS_FORM = 'SHOW_VIEW_PAYSLIPS_FORM';
export const HIDE_VIEW_PAYSLIPS_FORM = 'HIDE_VIEW_PAYSLIPS_FORM';

export const FETCH_NET_PAY = 'FETCH_NET_PAY';
export const FETCH_NET_PAY_SUCCESS = 'FETCH_NET_PAY_SUCCESS';
export const SHOW_NET_PAY_FORM = 'SHOW_NET_PAY_FORM';
export const HIDE_NET_PAY_FORM = 'HIDE_NET_PAY_FORM';

export const FETCH_DEDUCTION_LEDGER_REPORT = 'FETCH_DEDUCTION_LEDGER_REPORT';
export const FETCH_DEDUCTION_LEDGER_REPORT_SUCCESS = 'FETCH_DEDUCTION_LEDGER_REPORT_SUCCESS';
export const SHOW_DEDUCTION_LEDGER_REPORT_FORM = 'SHOW_DEDUCTION_LEDGER_REPORT_FORM';
export const HIDE_DEDUCTION_LEDGER_REPORT_FORM = 'HIDE_DEDUCTION_LEDGER_REPORT_FORM';

export const FETCH_SALARY_ADVANCE_REPORT = 'FETCH_SALARY_ADVANCE_REPORT';
export const FETCH_SALARY_ADVANCE_REPORT_SUCCESS = 'FETCH_SALARY_ADVANCE_REPORT_SUCCESS';
export const SHOW_SALARY_ADVANCE_REPORT_FORM = 'SHOW_SALARY_ADVANCE_REPORT_FORM';
export const HIDE_SALARY_ADVANCE_REPORT_FORM = 'HIDE_SALARY_ADVANCE_REPORT_FORM';

export const DELETE_SALARY_ADVANCE_REPORT = 'DELETE_SALARY_ADVANCE_REPORT';
export const DELETE_SALARY_ADVANCE_REPORT_SUCCESS = 'DELETE_SALARY_ADVANCE_REPORT_SUCCESS';

export const ADD_FROZEN_TO_MUSTER_ROLL ="ADD_FROZEN_TO_MUSTER_ROLL";
export const ADD_FROZEN_TO_MUSTER_ROLL_SUCCESS ="ADD_FROZEN_TO_MUSTER_ROLL_SUCCESS";

export const EXPORT_STATUTORY_DEDUCTIONS = "EXPORT_STATUTORY_DEDUCTIONS";

export const FETCH_QUEUED_MUSTER_ROLL_EXPORTS = 'FETCH_QUEUED_MUSTER_ROLL_EXPORTS';
export const FETCH_QUEUED_MUSTER_ROLL_EXPORTS_SUCCESS = 'FETCH_QUEUED_MUSTER_ROLL_EXPORTS_SUCCESS';

export const ADD_QUEUED_MUSTER_ROLL_EXPORT = 'ADD_QUEUED_MUSTER_ROLL_EXPORT';
export const GENERATE_PAYROLL_JOURNAL = 'GENERATE_PAYROLL_JOURNAL';
export const GENERATE_PAYROLL_JOURNAL_SUCCESS = 'GENERATE_PAYROLL_JOURNAL_SUCCESS';
export const GENERATE_INSURANCE_PROVIDENT = 'GENERATE_INSURANCE_PROVIDENT';
export const GENERATE_INSURANCE_PROVIDENT_SUCCESS = 'GENERATE_INSURANCE_PROVIDENT_SUCCESS';
export const GENERATE_PENSION = 'GENERATE_PENSION';
export const GENERATE_PENSION_SUCCESS = 'GENERATE_PENSION_SUCCESS';

export const FETCH_LEAVES_ATTENDED_REPORT = "FETCH_LEAVES_ATTENDED_REPORT";
export const FETCH_LEAVES_ATTENDED_REPORT_SUCCESS = "FETCH_LEAVES_ATTENDED_REPORT_SUCCESS";
export const EXPORT_LEAVES_ATTENDED_REPORT = "EXPORT_LEAVES_ATTENDED_REPORT";

export const FETCH_PAYROLL_VARIANCE_REPORT = "FETCH_PAYROLL_VARIANCE_REPORT";
export const FETCH_PAYROLL_VARIANCE_REPORT_SUCCESS = "FETCH_PAYROLL_VARIANCE_REPORT_SUCCESS";
export const FETCH_EMPLOYEE_PAY_VARIANCE_REPORT = "FETCH_EMPLOYEE_PAY_VARIANCE_REPORT";
export const FETCH_EMPLOYEE_PAY_VARIANCE_REPORT_SUCCESS = "FETCH_EMPLOYEE_PAY_VARIANCE_REPORT_SUCCESS";
export const EXPORT_PAYROLL_VARIANCE_REPORT = "EXPORT_PAYROLL_VARIANCE_REPORT";

export const EDIT_PAROLL_VARIANCE_REMARK = "EDIT_PAROLL_VARIANCE_REMARK";
export const EDIT_PAROLL_VARIANCE_REMARK_SUCCESS = "EDIT_PAROLL_VARIANCE_REMARK_SUCCESS";

export const generatePensionGenerator = (payload)=>{
  return {
    type:GENERATE_PENSION,
    payload
  }
};
export const generatePensionSuccessGenerator = (payload)=>{
  return {
    type:GENERATE_PENSION_SUCCESS,
    payload
  }
};

export const generateInsuranceProvidentGenerator =(data)=>{
  return {
    type:GENERATE_INSURANCE_PROVIDENT,
    payload:data
  }
};
export const generateInsuranceProvidentSuccessGenerator =(data)=>{
  return {
    type:GENERATE_INSURANCE_PROVIDENT_SUCCESS,
    payload:data
  }
};
export const generatePayrollJournalGenerator =(data)=>{
  return {
    type:GENERATE_PAYROLL_JOURNAL,
    payload:data
  }
} ;
export const generatePayrollJournalSuccessGenerator =(data)=>{
  return {
    type:GENERATE_PAYROLL_JOURNAL_SUCCESS,
    payload:data
  }
} ;

export const exportStatutoryDeductionsGenerator=(data=[])=>{
  return {
    type:EXPORT_STATUTORY_DEDUCTIONS,
    payload:data,
  }
};
export const addFrozenToMusterRollGenerator =(data=[])=>{
  return {
    type:ADD_FROZEN_TO_MUSTER_ROLL,
    payload:data
  }
};
export const addFrozenToMusterRollSuccessGenerator =(data=[])=>{
  return {
    type:ADD_FROZEN_TO_MUSTER_ROLL_SUCCESS,
    payload:data
  }
};

export const deleteSalaryAdvanceGenerator = (data)=>{
  return {
    type: DELETE_SALARY_ADVANCE_REPORT,
    payload: data
  }
};
export const deleteSalaryAdvanceSuccessGenerator = (data)=>{
  return {
    type: DELETE_SALARY_ADVANCE_REPORT_SUCCESS,
    payload: data
  }
};

export const fetchViewPayslipsGenerator = (data = []) => ({
  type: FETCH_VIEW_PAYSLIPS,
  payload: data
});
export const fetchViewPayslipsSuccessGenerator = data => ({
  type: FETCH_VIEW_PAYSLIPS_SUCCESS,
  payload: data
});
export const showViewPayslipsFormGenerator = (data = []) => ({
  type: SHOW_VIEW_PAYSLIPS_FORM,
  payload: data
});
export const hideViewPayslipsFormGenerator = (data = []) => ({
  type: HIDE_VIEW_PAYSLIPS_FORM,
  payload: data
});
export const fetchMusterRollGenerator = (data = []) => ({
  type: FETCH_MUSTER_ROLL,
  payload: data
});
export const fetchMusterRollSuccessGenerator = dataByID => ({
  type: FETCH_MUSTER_ROLL_SUCCESS,
  payload: dataByID
});
export const showMusterRollFormGenerator = (data = []) => ({
  type: SHOW_MUSTER_ROLL_FORM,
  payload: data
});
export const hideMusterRollFormGenerator = (data = []) => ({
  type: HIDE_MUSTER_ROLL_FORM,
  payload: data
});
export const fetchLeaveBalanceReportGenerator = (data = []) => ({
  type: FETCH_LEAVE_BALANCE_REPORT,
  payload: data
});

export const fetchLeaveBalanceReportSuccessGenerator = dataById => ({
  type: FETCH_LEAVE_BALANCE_REPORT_SUCCESS,
  payload: dataById
});
export const fetchAllowanceLedgerGenerator = (data = []) => ({
  type: FETCH_ALLOWANCE_LEDGER,
  payload: data
});
export const fetchAllowanceLedgerSuccessGenerator = dataByID => ({
  type: FETCH_ALLOWANCE_LEDGER_SUCCESS,
  payload: dataByID
});
export const showAllowanceLedgerFormGenerator = (data = []) => ({
  type: SHOW_ALLOWANCE_LEDGER_FORM,
  payload: data
});
export const hideAllowanceLedgerFormGenerator = (data = []) => ({
  type: HIDE_ALLOWANCE_LEDGER_FORM,
  payload: data
});

export const showStatutoryFormGenerator = (data = []) => ({
  type: SHOW_STATUTORY_FORM,
  payload: data
});
export const fetchStatutoryReportGenerator = (data) => ({
  type: FETCH_STATUTORY_REPORT,
  payload: data
});
export const fetchStatutoryReportSuccessGenerator = (dataById) => ({
  type: FETCH_STATUTORY_REPORT_SUCCESS,
  payload: dataById
});

export const hideStatutoryFormGenerator = (data = []) => ({
  type: HIDE_STATUTORY_FORM,
  payload: data
});
  // net pay
export const fetchNetPayGenerator = (data = []) => ({
  type: FETCH_NET_PAY,
  payload: data
});
export const fetchNetPaySuccessGenerator = dataById => ({
  type: FETCH_NET_PAY_SUCCESS,
  payload: dataById
});

export const showNetPayFormGenerator = (data = []) => ({
  type: SHOW_NET_PAY_FORM,
  payload: data
});
export const hideNetPayFormGenerator = (data = []) => ({
  type: HIDE_NET_PAY_FORM,
  payload: data
});

export const fetchAuditLogReportGenerator = (data = []) => ({
  type: FETCH_AUDIT_LOG_REPORT,
  payload: data
});

export const fetchAuditLogReportSuccessGenerator = data => ({
  type: FETCH_AUDIT_LOG_REPORT_SUCCESS,
  payload: data
});

export const showAuditLogReportForm = data => ({
  type: SHOW_AUDIT_LOG_REPORT_FORM,
  payload: data
});
export const hideAuditLogReportForm = data => ({
  type: HIDE_AUDIT_LOG_REPORT_FORM,
  payload: data
});
export const fetchLoanPaymentReportGenerator = (data = []) => ({
  type: FETCH_LOAN_PAYMENT_REPORT,
  payload: data
});

export const fetchLoanPaymentReportSuccessGenerator = dataById => ({
  type: FETCH_LOAN_PAYMENT_REPORT_SUCCESS,
  payload: dataById
});
export const fetchDeductionLedgerReportGenerator = (data = []) => ({
  type: FETCH_DEDUCTION_LEDGER_REPORT,
  payload: data
});
export const fetchDeductionLedgerReportSuccessGenerator = dataById => ({
  type: FETCH_DEDUCTION_LEDGER_REPORT_SUCCESS,
  payload: dataById
});
export const showDeductionLedgerReportFormGenerator = (data = []) => ({
  type: SHOW_DEDUCTION_LEDGER_REPORT_FORM,
  payload: data
});
export const hideDeductionLedgerReportFormGenerator = (data = []) => ({
  type: HIDE_DEDUCTION_LEDGER_REPORT_FORM,
  payload: data
});

export const fetchSalaryAdvanceReportGenerator = (data = []) => ({
  type: FETCH_SALARY_ADVANCE_REPORT,
  payload: data
});
export const fetchSalaryAdvanceReportSuccessGenerator = dataById => ({
  type: FETCH_SALARY_ADVANCE_REPORT_SUCCESS,
  payload: dataById
});
export const showSalaryAdvanceReportFormGenerator = (data = []) => ({
  type: SHOW_SALARY_ADVANCE_REPORT_FORM,
  payload: data
});
export const hideSalaryAdvanceReportFormGenerator = (data = []) => ({
  type: HIDE_SALARY_ADVANCE_REPORT_FORM,
  payload: data
});

export const fetchQueuedMusterRollExportsGenerator = (data = []) => ({
  type: FETCH_QUEUED_MUSTER_ROLL_EXPORTS,
  payload: data
});
export const fetchQueuedMusterRollExportsSuccessGenerator = data => ({
  type: FETCH_QUEUED_MUSTER_ROLL_EXPORTS_SUCCESS,
  payload: data
});

export const addQueuedMusterRollExportGenerator = (data = []) => ({
  type: ADD_QUEUED_MUSTER_ROLL_EXPORT,
  payload: data
});

export const fetchLeavesAttendedReportGenerator = (data = {}) => ({
  type: FETCH_LEAVES_ATTENDED_REPORT,
  payload: data
});

export const fetchLeavesAttendedReportSuccessGenerator = (data = {}) => ({
  type: FETCH_LEAVES_ATTENDED_REPORT_SUCCESS,
  payload: data
});

export const exportLeavesAttendedReportGenerator = (data = {}) => ({
  type: EXPORT_LEAVES_ATTENDED_REPORT,
  payload: data
});

export const fetchPayrollVarianceReportGenerator = (data = {}) => ({
  type: FETCH_PAYROLL_VARIANCE_REPORT,
  payload: data
});

export const fetchPayrollVarianceReportSuccessGenerator = (data = {}) => ({
  type: FETCH_PAYROLL_VARIANCE_REPORT_SUCCESS,
  payload: data
});

export const exportPayrollVarianceReportGenerator = (data = {}) => ({
  type: EXPORT_PAYROLL_VARIANCE_REPORT,
  payload: data
});


export const fetchEmployeePayVarianceReportGenerator = (data = {}) => ({
  type: FETCH_EMPLOYEE_PAY_VARIANCE_REPORT,
  payload: data
});

export const fetchEmployeePayVarianceReportSuccessGenerator = (data = {}) => ({
  type: FETCH_EMPLOYEE_PAY_VARIANCE_REPORT_SUCCESS,
  payload: data
});

export const editPayrollVarianceRemarkGenerator = (data = {}) => ({
  type: EDIT_PAROLL_VARIANCE_REMARK,
  payload: data
});

export const editPayrollVarianceRemarkSuccessGenerator = (data = {}) => ({
  type: EDIT_PAROLL_VARIANCE_REMARK_SUCCESS,
  payload: data
});
