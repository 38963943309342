///create
export const ADD_HOLIDAY = 'ADD_HOLIDAY';
export const ADD_HOLIDAY_SUCCESS = 'ADD_HOLIDAY_SUCCESS';
export const SHOW_CREATE_HOLIDAY_FORM = 'SHOW_CREATE_HOLIDAY_FORM';
export const HIDE_CREATE_HOLIDAY_FORM = 'HIDE_CREATE_HOLIDAY_FORM';
/// fetch
export const FETCH_HOLIDAYS = 'FETCH_HOLIDAYS';
export const FETCH_HOLIDAY_SUCCESS = 'FETCH_HOLIDAY_SUCCESS';
////delete
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';
export const DELETE_HOLIDAY_SUCCESS ='DELETE_HOLIDAY_SUCCESS';
export const SHOW_DELETE_HOLIDAY_MODAL = 'SHOW_DELETE_HOLIDAY_MODAL';
export const HIDE_DELETE_HOLIDAY_MODAL = 'HIDE_DELETE_HOLIDAY_MODAL';
////edit
export const EDIT_HOLIDAY = 'EDIT_HOLIDAY';
export const EDIT_HOLIDAY_SUCCESS = 'EDIT_HOLIDAY_SUCCESS';
export const SHOW_EDIT_HOLIDAY_FORM = 'SHOW_EDIT_HOLIDAY_FORM';
export const HIDE_EDIT_HOLIDAY_FORM = 'HIDE_EDIT_HOLIDAY_FORM';

/////CREATE
export const addHolidayGenerator = (data)=>{
  return {
    type: ADD_HOLIDAY,
    payload: data
  }
};
export const showHolidayCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_HOLIDAY_FORM,
    payload:data
  }
};
export const hideHolidayCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_HOLIDAY_FORM,
    payload:data
  }
};
export const addHolidaySuccess =(data)=>{
  return {
    type:ADD_HOLIDAY_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchHolidays=(data=[])=>{
  return {
    type:FETCH_HOLIDAYS,
    payload:data
  }
};

export const fetchHolidaySuccess =(data)=>{
  return {
    type:FETCH_HOLIDAY_SUCCESS,
    payload:data
  }
};


///EDIT
export const editHolidayGenerator = (data)=>{
  return {
    type: EDIT_HOLIDAY,
    payload: data
  }
};

export const editHolidaySuccess=(data)=>{
  return {
    type:EDIT_HOLIDAY_SUCCESS,
    payload:data
  }
};
export const showHolidayEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_HOLIDAY_FORM,
    payload:data
  }
};
export const hideHolidayEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_HOLIDAY_FORM,
    payload:data
  }
};
/////DELETE
export const deleteHoliday=(data)=>{
 return {
   type:DELETE_HOLIDAY,
   payload:data
 }
};

export const deleteHolidaySuccess = (data)=>{
  return {
    type:DELETE_HOLIDAY_SUCCESS,
    payload:data
  }
};
export const showHolidayDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_HOLIDAY_MODAL,
    payload:data
  }
};
export const hideHolidayDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_HOLIDAY_MODAL,
    payload:data
  }
};



