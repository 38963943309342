export const ADD_PRODUCT_VARIETY = 'ADD_PRODUCT_VARIETY';
export const EDIT_PRODUCT_VARIETY = 'EDIT_PRODUCT_VARIETY';
export const DELETE_PRODUCT_VARIETY = 'DELETE_PRODUCT_VARIETY';

export const addProductVarietyGenerator = (data)=>{
  return {
    type: ADD_PRODUCT_VARIETY,
    payload: data
  }
};


export const editProductVarietyGenerator = (data)=>{
  return {
    type: EDIT_PRODUCT_VARIETY,
    payload: data
  }
};

export const deleteProductVarietyGenerator = (data)=>{
  return {
    type: DELETE_PRODUCT_VARIETY,
    payload: data
  }
};

