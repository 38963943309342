export const  FETCH_SYNCED_DEVICES = 'FETCH_SYNCED_DEVICES';
export const  FETCH_SYNCED_DEVICES_SUCCESS = 'FETCH_SYNCED_DEVICES_SUCCESS';
export const  DELETE_SYNCED_DEVICES="DELETE_SYNCED_DEVICES";
export const  DELETE_SYNCED_DEVICES_SUCCESS="DELETE_SYNCED_DEVICES_SUCCESS";
export const  UPDATE_SYNCED_DEVICES="UPDATE_SYNCED_DEVICES";
export const  UPDATE_SYNCED_DEVICES_SUCCESS="UPDATE_SYNCED_DEVICES_SUCCESS";

export const fetchSyncedDevicesGenerator = (data=[])=>{
    return {
        type:FETCH_SYNCED_DEVICES,
        payload:data
    };
};

export const fetchSyncedDevicesSuccessGenerator = (data)=> {
    return {
        type:FETCH_SYNCED_DEVICES_SUCCESS,
        payload:data
    }
};

export const deleteSyncedDevicesGenerator = (data)=> {
    return {
        type:DELETE_SYNCED_DEVICES,
        payload:data
    }
};

export const deleteSyncedDevicesSuccessGenerator = (data)=> {
    return {
        type:DELETE_SYNCED_DEVICES_SUCCESS,
        payload:data
    }
};

export const updateSyncedDevicesGenerator =(data)=>{
    return {
        type:UPDATE_SYNCED_DEVICES,
        payload:data
    }
};

export const updateSyncedDevicesSuccessGenerator =(data)=>{
    return {
        type:UPDATE_SYNCED_DEVICES_SUCCESS,
        payload:data
    }
};