export const FETCH_EXIT_REASONS = "FETCH_EXIT_REASONS";
export const FETCH_EXIT_REASONS_SUCCESS = "FETCH_EXIT_REASONS_SUCCESS";
export const SHOW_EXIT_REASON_CREATE_FORM = "SHOW_EXIT_REASON_CREATE_FORM";
export const HIDE_EXIT_REASON_CREATE_FORM = "HIDE_EXIT_REASON_CREATE_FORM";
export const ADD_EXIT_REASON = "ADD_EXIT_REASON";
export const ADD_EXIT_REASON_SUCCESS = "ADD_EXIT_REASON_SUCCESS";
export const SHOW_EXIT_REASON_EDIT_FORM = "SHOW_EXIT_REASON_EDIT_FORM";
export const HIDE_EXIT_REASON_EDIT_FORM = "HIDE_EXIT_REASON_EDIT_FORM";
export const EDIT_EXIT_REASON = "EDIT_EXIT_REASON";
export const EDIT_EXIT_REASON_SUCCESS = "EDIT_EXIT_REASON_SUCCESS";
export const SHOW_EXIT_REASON_DELETE_FORM = "SHOW_EXIT_REASON_DELETE_FORM";
export const HIDE_EXIT_REASON_DELETE_FORM = "HIDE_EXIT_REASON_DELETE_FORM";
export const DELETE_EXIT_REASON = "DELETE_EXIT_REASON";
export const DELETE_EXIT_REASON_SUCCESS = "DELETE_EXIT_REASON_SUCCESS";

export const fetchExitReasonsGenerator = (data = []) => {
  return {
    type: FETCH_EXIT_REASONS,
    payload: data
  };
};

export const fetchExitReasonsSuccessGenerator = dataById => {
  return {
    type: FETCH_EXIT_REASONS_SUCCESS,
    payload: dataById
  };
};

export const showExitReasonCreateFormGenerator = () => {
  return {
    type: SHOW_EXIT_REASON_CREATE_FORM
  };
};
export const hideExitReasonCreateFormGenerator = () => {
  return {
    type: HIDE_EXIT_REASON_CREATE_FORM
  };
};
export const addExitReasonGenerator = data => {
  return {
    type: ADD_EXIT_REASON,
    payload: data
  };
};

export const addExitReasonSuccessGenerator = data => {
  return {
    type: ADD_EXIT_REASON_SUCCESS,
    payload: data
  };
};
export const showExitReasonEditFormGenerator = data => {
  return {
    type: SHOW_EXIT_REASON_EDIT_FORM,
    payload: data
  };
};
export const hideExitReasonEditFormGenerator = () => {
  return {
    type: HIDE_EXIT_REASON_EDIT_FORM
  };
};
export const editExitReasonGenerator = data => {
  return {
    type: EDIT_EXIT_REASON,
    payload: data
  };
};
export const editExitReasonSuccessGenerator = data => {
  return {
    type: EDIT_EXIT_REASON_SUCCESS,
    payload: data
  };
};
export const showExitReasonDeleteFormGenerator = data => {
  return {
    type: SHOW_EXIT_REASON_DELETE_FORM,
    payload: data
  };
};
export const hideExitReasonDeleteFormGenerator = () => {
  return {
    type: HIDE_EXIT_REASON_DELETE_FORM
  };
};
export const deleteExitReasonGenerator = data => {
  return {
    type: DELETE_EXIT_REASON,
    payload: data
  };
};
export const deleteExitReasonSuccessGenerator = data => {
  return {
    type: DELETE_EXIT_REASON_SUCCESS,
    payload: data
  };
};
