import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import { push } from  "connected-react-router";
import axios from "axios";
import {
  AUTH_HEAD_OFFICE_ID_KEY,
  AUTH_ROLE_KEY, AUTH_SWITCHED_COMPANY_EMAIL_KEY,
  AUTH_SWITCHED_COMPANY_ID_KEY, AUTH_USER_KEY, SELECTED_COMPANY_KEY, SELECTED_EMPLOYEE_ID_FOR_PORTAL,
  SUBSCRIPTION_KEY
} from "../../../constants/SessionKeys";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers as userData, Helpers} from '../../../util/Helpers';
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
  ADD_COMPANY,
  addCompanySuccessGenerator,
  DELETE_COMPANY,
  EDIT_COMPANY, ENABLE_FOREIGN_CURRENCY,
  FETCH_COMPANIES, fetchCompaniesGenerator,
  fetchCompaniesSuccessGenerator,
  SWITCH_COMPANY,
  switchCompanySuccessGenerator, switchCountrySuccessGenerator,
  UPLOAD_COMPANY_LOGO,
  uploadCompanyLogoSuccessGenerator
} from "../../actions/common/CompanyActions";
import {editCompanySuccessGenerator} from "../../actions/common/CompanyActions";
import {deleteCompanySuccessGenerator} from "../../actions/common/CompanyActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { fetchAdminCompaniesGenerator } from "../../actions/admin/CompanyActions";
/*import { push } from "connected-react-router";*/

const addCompanyRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/companies',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const uploadCompanyLogoRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/upload/company/logo',requestParameters, {headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const editCompanyRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/home/companies/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);
const deleteCompanyRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/companies/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* addCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
      try {
        let payloadCopy = {...payload};
        let country_pending_switch = null;
        if(payloadCopy.hasOwnProperty('country_pending_switch')){
          country_pending_switch = payloadCopy.country_pending_switch;
          delete payloadCopy[country_pending_switch];
        }
        let requestParameters = {...payloadCopy, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        let formData = Helpers.getFormData(requestParameters);
        const response = yield call(addCompanyRequest, formData);
        if (response.status === 200 || response.status === 201) {
          if (response.data.success) {
            let company = response.data.data;
            Helpers.saveItem(AUTH_ROLE_KEY, company.role);
            Helpers.saveItem(SUBSCRIPTION_KEY,company.subscription.subscription);
            yield put(fetchCompaniesGenerator());
            if(!Helpers.isNull(payload.switch_to)){
              // save company ID in local storage
              Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, company.id);
              if(company.is_head_office === 1 || company.is_head_office === '1'){
                company.head_office_id = company.id;
              }
              Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, company.head_office_id);
              Helpers.saveItem(SELECTED_COMPANY_KEY, company.id);
              let userProperties = {
                company_name: company.name,
                sector: company.sector,
                city: company.address.city,
                country: company.country
              };
              amplitude.getInstance().setUserProperties(userProperties);
              //amplitude.getInstance().logEvent('new company');
              amplitude.getInstance().logEvent('create new company');

              yield put(switchCompanySuccessGenerator(company));
            }
            let pathname = window.location.pathname;
            if(!pathname.endsWith('/branches') && !pathname.endsWith('/companies')){
              yield put(push('/home')) ;
            }
            if(country_pending_switch){
              yield put(switchCountrySuccessGenerator(country_pending_switch));
              yield put(showMessageGenerator('Switched to '+country_pending_switch.name+' successfully.','success'));
            }else{
              yield put(showMessageGenerator(response.data.message,'success'));
            }
          } else {
            yield put(showMessageGenerator(response.data.message,'error'));
          }
    }else if(response.status === 401){
       Helpers.clearAuthData();
       yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* uploadCompanyLogo({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadCompanyLogoRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let company = response.data.data;
        yield put(fetchCompaniesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
       Helpers.clearAuthData();
       yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editCompanyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let company = response.data.data;
        yield put(fetchCompaniesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteCompanyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let company = response.data.data;
        if(window.location.pathname === '/admin/companies'){
          yield put(fetchAdminCompaniesGenerator());
        }else{
          yield put(fetchCompaniesGenerator());
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
const fetchCompaniesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/companies',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* fetchCompanies({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchCompaniesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let companiesById = response.data.data;
        yield put(fetchCompaniesSuccessGenerator(companiesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
const switchCompanyRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/companies/switch',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const enableForeignCurrencyRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/companies/enable-foreign-currency',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* enableForeignCurrency({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(enableForeignCurrencyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompaniesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      }else {
          yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* switchCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(switchCompanyRequest, requestParameters);
    const openNewUi = (payload.hasOwnProperty('open_new_ui') && payload.open_new_ui);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let company = response.data.data;
        if(company.is_head_office === 1){
          company.head_office_id = company.id;
        }
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, company.id);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_EMAIL_KEY, company.email);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, company.head_office_id);
        // save company ID in local storage
        if(company.hasOwnProperty('role')){
          Helpers.saveItem(AUTH_ROLE_KEY, company.role);

          /*update switched employee ID*/
          let employee = Helpers.selectedPortalEmployee();
          if(employee && employee.for === 'auth_employee'){
            let auth_emp_id = Number(company.role.hasOwnProperty('employee_id')?company.role.employee_id:0);
            Helpers.saveItem(SELECTED_EMPLOYEE_ID_FOR_PORTAL, {id: auth_emp_id, for:'auth_employee'});
          }else{
            if(window.location.pathname.startsWith('/portal')){
              yield put(push('/portal/profile'));
            }
          }
          /*end of update switched employee ID*/
        }
        if(company.hasOwnProperty('subscription') && company.subscription){
          Helpers.saveItem(SUBSCRIPTION_KEY,company.subscription.subscription);
        }
        if(company.hasOwnProperty('can_access_appraisal') && company.can_access_appraisal){
          let userData = JSON.parse(Helpers.getItem(AUTH_USER_KEY));
          userData.user.can_access_appraisal = company.can_access_appraisal;
          Helpers.saveItem(AUTH_USER_KEY,userData);
        }
        yield put(switchCompanySuccessGenerator(company));
        if(window.location.pathname.startsWith('/admin') && !window.location.pathname.includes('transaction')){
          yield put(push('/payouts/transaction-history'));
        }else{
          if(openNewUi && (response.data.data.hasOwnProperty('has_beta_access') && response.data.data.has_beta_access)){//uncomment for auto redirect to beta system
            //let encryptedToken = Helpers.encrypt(Helpers.token());//javascript encrypted token not working yet
            //console.log({ENCRYPTED: encryptedToken, DECRYPTED: Helpers.decrypt(encryptedToken)});
            //window.open('https://dashboard.myworkpay.com/login?utm_source=legacy&utm_content='+Helpers.token()+'&company_id='+company.id, '_self');
          }
          Helpers.delay(1000).finally(function() {
            window.location.reload();
          });
        }
        if(payload.hasOwnProperty('switching_country') && payload.switching_country){
          yield put(showMessageGenerator(payload.message,'success'));//the message is formatted in the payload
        }else{
          yield put(showMessageGenerator(response.data.message,'success'));
        }
      } else {
        if(payload.hasOwnProperty('switching_country') && payload.switching_country){
          yield put(showMessageGenerator(payload.message,'success'));//the message is formatted in the payload in success type since country switch happens from client end
        }else{
          yield put(showMessageGenerator(response.data.message,'error'));
        }
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* addCompanyDefault() {
  yield takeLatest(ADD_COMPANY, addCompany);
}
export function* fetchCompaniesDefault() {
  yield takeLatest(FETCH_COMPANIES, fetchCompanies);
}
export function* switchCompanyDefault() {
  yield takeLatest(SWITCH_COMPANY, switchCompany);
}
export function* editCompanyDefault() {
  yield takeLatest(EDIT_COMPANY, editCompany);
}
export function* deleteCompanyDefault() {
  yield takeLatest(DELETE_COMPANY, deleteCompany);
}
export function* uploadCompanyLogoDefault() {
  yield takeLatest(UPLOAD_COMPANY_LOGO, uploadCompanyLogo);
}
export function* enableForeignCurrencyDefault() {
  yield takeLatest(ENABLE_FOREIGN_CURRENCY, enableForeignCurrency);
}
export default function* rootSaga() {
  yield all([fork(fetchCompaniesDefault),
    fork(addCompanyDefault),
    fork(switchCompanyDefault),
    fork(editCompanyDefault),
    fork(deleteCompanyDefault),
    fork(uploadCompanyLogoDefault),
    fork(enableForeignCurrencyDefault),
    ]);
}
