import {
    FETCH_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS,
    UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS
  } from "../../actions/admin/StatutoryFillingRequestsActions";
  
  const INIT_STATE = {
    StatutoryFillingRequests:{},
  };
  
  export default (state=INIT_STATE,action)=>{
    switch (action.type) {
      case UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS:
      case FETCH_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS:
        return {
          ...state,
          StatutoryFillingRequests: action.payload
        }
      default:
        return state;
    }
  }