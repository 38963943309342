export const SWITCH_COMPANY = 'SWITCH_COMPANY';
export const SHOW_COMPANY_CREATE_FORM = 'SHOW_COMPANY_CREATE_FORM';
export const HIDE_COMPANY_CREATE_FORM = 'HIDE_COMPANY_CREATE_FORM';
export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const SHOW_COMPANY_EDIT_FORM = 'SHOW_COMPANY_EDIT_FORM';
export const HIDE_COMPANY_EDIT_FORM = 'HIDE_COMPANY_EDIT_FORM';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const SHOW_COMPANY_DELETE_FORM = 'SHOW_COMPANY_DELETE_FORM';
export const HIDE_COMPANY_DELETE_FORM = 'HIDE_COMPANY_DELETE_FORM';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const SWITCH_COMPANY_SUCCESS = 'SWITCH_COMPANY_SUCCESS';
export const SHOW_COMPANY_BRANCHES = "SHOW_COMPANY_BRANCHES";
export const HIDE_COMPANY_BRANCHES = "HIDE_COMPANY_BRANCHES";

export const UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO';
export const UPLOAD_COMPANY_LOGO_SUCCESS ='UPLOAD_COMPANY_LOGO_SUCCESS';

export const SWITCH_COUNTRY_SUCCESS = "SWITCH_COUNTRY_SUCCESS";

export const ENABLE_FOREIGN_CURRENCY = 'ENABLE_FOREIGN_CURRENCY';

export  const enableForeignCurrencyGenerator =(data=[])=>{
  return {
    type:ENABLE_FOREIGN_CURRENCY,
    payload:data
  }
};

export const uploadCompanyLogoGenerator =(data=[])=>{
   return {
       type:UPLOAD_COMPANY_LOGO,
       payload:data
   }
};
export const uploadCompanyLogoSuccessGenerator =(data=[])=>{
    return {
        type:UPLOAD_COMPANY_LOGO_SUCCESS,
        payload:data
    }
};

export const fetchCompaniesGenerator = (data=[])=>{
  return {
    type:FETCH_COMPANIES,
    payload:data
  };
};
export const fetchCompaniesSuccessGenerator =(dataById)=>{
  return {
    type:FETCH_COMPANIES_SUCCESS,
    payload:dataById
  };
};
export const showCompanyCreateFormGenerator = (is_branch=false)=>{
    return {
       type:SHOW_COMPANY_CREATE_FORM,
       payload:is_branch
    };
};
export const hideCompanyCreateFormGenerator = ()=>{
  return {
    type:HIDE_COMPANY_CREATE_FORM
  };
};
export const switchCompanyGenerator = (data)=>{
    return {
       type: SWITCH_COMPANY,
       payload: data
    };
};
export const switchCompanySuccessGenerator =(data)=>{
  return {
    type:SWITCH_COMPANY_SUCCESS,
    payload:data
  };
};
export const addCompanyGenerator = (data)=>{
    return {
       type: ADD_COMPANY,
       payload: data
    }
};

export const addCompanySuccessGenerator = (data)=>{
  return {
    type: ADD_COMPANY_SUCCESS,
    payload: data
  }
};
export const showCompanyEditFormGenerator = (data)=>{
  return {
    type:SHOW_COMPANY_EDIT_FORM,
    payload:data
  };
};
export const hideCompanyEditFormGenerator = ()=>{
  return {
    type:HIDE_COMPANY_EDIT_FORM
  };
};
export const editCompanyGenerator = (data)=>{
  return {
    type: EDIT_COMPANY,
    payload: data
  }
};
export const editCompanySuccessGenerator = (data)=>{
  return {
    type: EDIT_COMPANY_SUCCESS,
    payload: data
  }
};
export const showCompanyDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_COMPANY_DELETE_FORM,
    payload:data
  };
};
export const hideCompanyDeleteFormGenerator = ()=>{
  return {
    type:HIDE_COMPANY_DELETE_FORM
  };
};
export const deleteCompanyGenerator = (data)=>{
  return {
    type: DELETE_COMPANY,
    payload: data
  }
};
export const deleteCompanySuccessGenerator = (data)=>{
  return {
    type: DELETE_COMPANY_SUCCESS,
    payload: data
  }
};
export const showCompanyBranchesGenerator = (company_id)=>{
   return {
     type: SHOW_COMPANY_BRANCHES,
     payload: company_id
   }
};
export const hideCompanyBranchesGenerator = (data=null)=>{
   return {
      type:HIDE_COMPANY_BRANCHES,
      payload:data
   }
};
export const switchCountrySuccessGenerator = (data={})=>{
  return {
    type: SWITCH_COUNTRY_SUCCESS,
    payload: data
  }
};

