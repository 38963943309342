import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";

import {userSignOutGenerator, userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    ATTENDANCE_ADD_BULK_EMPLOYEES,
    ATTENDANCE_ADD_EMPLOYEE,
    ATTENDANCE_DELETE_EMPLOYEE,
    ATTENDANCE_FETCH_EMPLOYEE,
    ATTENDANCE_UPDATE_EMPLOYEE, attendanceAddBulkEmployeesSuccessGenerator,
    attendanceAddEmployeeSuccessGenerator,
    attendanceDeleteEmployeeSuccessGenerator, attendanceFetchEmployeesGenerator,
    attendanceFetchEmployeesSuccessGenerator,
    attendanceUpdateEmployeeSuccessGenerator, BULK_ASSIGN_SHIFTS_TO_EMPLOYEE, BULK_DELETE__ATTENDANCE_EMPLOYEES
} from "../../actions/attendance/EmployeesActions";
import { CLIENT_GENERATE_QRCODE, GENERATE_QRCODE } from "../../actions/admin/CompanyActions";
//bulk
const bulkAssignShiftEmployeesRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+"/attendance/bulk/assign-shift-employees",requestData)
    .then(response=>response)
    .catch(error=>error.response)

const bulkDeleteAttendanceEmployeesRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/bulk-delete-employees',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const generateQrCodeRequest =async (requestData)=>
  await axios.post(API_ENDPOINT+'/client/create/qrcode',requestData)
    .then(response=>response)
    .catch(error=>error.response);

function* generateQrCode({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(generateQrCodeRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                window.open(API_ADDRESS+response.data.data,'_blank');
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* generateQrCodeDefault() {
    yield takeLatest(CLIENT_GENERATE_QRCODE,generateQrCode);
}

function* bulkDeleteAttendanceEmployees({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response =yield(call(bulkDeleteAttendanceEmployeesRequest,requestParams));
        if(response.status === 200 || response.status ===201){
            if(response.data.success){
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(attendanceFetchEmployeesGenerator());
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutGenerator());
        }
        else{
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* bulkDeleteAttendanceEmployeesDefault() {
 yield takeLatest(BULK_DELETE__ATTENDANCE_EMPLOYEES,bulkDeleteAttendanceEmployees)
}
function* bulkAssignShiftEmployee({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response =yield(call(bulkAssignShiftEmployeesRequest,requestParams));
        if(response.status === 200 || response.status ===201){
            if(response.data.success){
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(attendanceFetchEmployeesGenerator());
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutGenerator());
        }
        else{
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* bulkAssignShiftEmployeeDefault() {
   yield takeLatest(BULK_ASSIGN_SHIFTS_TO_EMPLOYEE,bulkAssignShiftEmployee);
}
/*fetch employees*/
const attendanceFetchEmployeesRequest = async (requestParameters)=>
    await axios.get(API_ENDPOINT+"/attendance/employees",{params:requestParameters})
        .then(response => response)
        .catch(error=>error.response);

function* fetchEmployees({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParams ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response =yield(call(attendanceFetchEmployeesRequest,requestParams));
        //console.log(response);
        if(response.status === 200 || response.status ===201){
            if(response.data.success){
                yield put(attendanceFetchEmployeesSuccessGenerator(response.data.data));
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutGenerator());
        }
        else{
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* attendanceFetchEmployeesDefault(){
    yield(takeLatest(ATTENDANCE_FETCH_EMPLOYEE,fetchEmployees));
}
/*End:Fetch Employees*/

const attendanceAddEmployeeRequest =async (requestParameters)=>
    await axios.post(API_ENDPOINT+"/attendance/employee/add",requestParameters)
        .then(response=>response).catch(error =>error.response);

function* addEmployee({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response =yield call(attendanceAddEmployeeRequest,requestParameters);

        if(response.status ===201 || response.status ===200){

            if(response.data.success){
                yield put(attendanceFetchEmployeesGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else {
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* attendanceAddEmployeeDefault() {
    yield (takeLatest(ATTENDANCE_ADD_EMPLOYEE,addEmployee));
}
/*End:Add Employees*/

/*Start:Edit Employees*/
const attendanceUpdateEmployeeRequest=async (request)=>await axios.post(API_ENDPOINT+"/attendance/employee/update",request)
    .then(response =>response).catch(error=>error.response);

function* attendanceUpdateEmployee({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};

        let response =yield call(attendanceUpdateEmployeeRequest,requestParameters);

        if(response.status ===201 || response.status ===200){
            if(response.data.success){
                yield put(attendanceFetchEmployeesGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }

        }else if(response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutGenerator());
        }else{
            yield put(showMessageGenerator(requestParameters.data.message,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* attendanceUpdateEmployeeDefault() {
    yield (takeLatest(ATTENDANCE_UPDATE_EMPLOYEE,attendanceUpdateEmployee));
}
/*End:Edit Employees*/

/*Start:Add Bulk Employees*/
const addBulkEmployeesRequest =async (requestParameters)=>
    await axios.post(API_ENDPOINT+"/attendance/batch-upload/employee",requestParameters, {headers: {
            'content-type': `multipart/form-data`
        }})
        .then(response=>response).catch(error =>error.response);

/*start: delete employee*/
const attendanceDeleteEmployeeRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/employee/delete/',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* attendanceDeleteEmployee({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(attendanceDeleteEmployeeRequest,requestParameters);
        if(response.status === 201 || response.status===200){
            if(response.data.success){
                yield put(attendanceDeleteEmployeeSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message));
            }else {
                yield put(showMessageGenerator(response.data.message,'success'));
            }
        }else if(response.status === 401){
            yield put(showMessageGenerator(response.statusText,'error'));
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* addBulkEmployees({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let formData = Helpers.getFormData(requestParameters);
        let response =yield call(addBulkEmployeesRequest,formData);
        if(response.status ===201 || response.status ===200){
            if(response.data.success){
                yield put(attendanceFetchEmployeesGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else {
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* addBulkEmployeesDefault() {
    yield (takeLatest(ATTENDANCE_ADD_BULK_EMPLOYEES,addBulkEmployees));
}

export function* attendanceDeleteEmployeeDefault() {
    yield (takeLatest(ATTENDANCE_DELETE_EMPLOYEE,attendanceDeleteEmployee));
}

/*end set status*/
export default function* rootSaga() {
    yield all([
        fork(addBulkEmployeesDefault),
        fork(attendanceDeleteEmployeeDefault),
        fork(attendanceUpdateEmployeeDefault),
        fork(attendanceAddEmployeeDefault),
        fork(attendanceFetchEmployeesDefault),
        fork(bulkAssignShiftEmployeeDefault),
        fork(bulkDeleteAttendanceEmployeesDefault),
        fork(generateQrCodeDefault),
    ]);
}
