import {
    ACTIVATE_PROJECT_SUCCESS,
    ADD_BULK_PROJECT_SUCCESS,
    ADD_PROJECT_SUCCESS,
    DELETE_PROJECT_SUCCESS,
    FETCH_EMPLOYEE_ASSIGNED_PROJECT_SUCCESS,
    FETCH_PROJECT_BLACKLIST_SUCCESS,
    FETCH_PROJECT_SUCCESS, TERMINATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_SUCCESS
} from "../../actions/attendance/ProjectActions";

const INIT_STATE = {
    projectsByID:{},
    employeeProject:{},
    blacklist:[]
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
        case FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                projectsByID: action.payload
            };
        case ADD_PROJECT_SUCCESS:
        case UPDATE_PROJECT_SUCCESS:
        case ACTIVATE_PROJECT_SUCCESS:
        case TERMINATE_PROJECT_SUCCESS:
            let project_id = action.payload.id;
            return {
                ...state,
                projectsByID: {
                    ...state.projectsByID,
                    [project_id]:action.payload
                }
            };
        case DELETE_PROJECT_SUCCESS:
            let projects = {...state.projectsByID};
            delete projects[action.payload.id];
            return {
                ...state,
                projectsByID:projects
            };
        case FETCH_EMPLOYEE_ASSIGNED_PROJECT_SUCCESS:
            return {
                ...state,
                employeeProject: action.payload
            };
        case FETCH_PROJECT_BLACKLIST_SUCCESS:
            return {
                ...state,
                blacklist: action.payload
            };
        default:
            return state;
    }
}
