import React from 'react';
import { render } from 'react-dom';
import SentryErrorBoundary from 'react-sentry-error-boundary';
import { SENTRY_KEY } from './constants/ActionTypes';
import "./axiosConfig";
const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
const Render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  render(
    <SentryErrorBoundary dsn={SENTRY_KEY}>
      <MainApp />
    </SentryErrorBoundary>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(<MainApp />, rootEl);
  });
}

Render();
