import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
    FETCH_SUBSCRIPTION_PRICINGS,
    fetchSubscriptionPricingSuccessGenerator, UPDATE_SUBSCRIPTION_PRICING, fetchSubscriptionPricingGenerator
} from "../../actions/admin/SubscriptionPricingActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";

const fetchSubscriptionPricingsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/tozza/subscription-prices',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editSubscriptionPricingRequest = async (requestParameters) =>
    await axios.post(API_ENDPOINT+'/tozza/subscription-prices/update',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchSubscriptionPricings({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchSubscriptionPricingsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let pricings = response.data.data;
                yield put(fetchSubscriptionPricingSuccessGenerator(pricings));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* editSubscriptionPricing({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(editSubscriptionPricingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSubscriptionPricingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* fetchSubscriptionPricingsDefault() {
    yield takeLatest(FETCH_SUBSCRIPTION_PRICINGS, fetchSubscriptionPricings)
}
export function* editSubscriptionPricingDefault() {
    yield takeLatest(UPDATE_SUBSCRIPTION_PRICING, editSubscriptionPricing);
}
export default function* rootSaga() {
    yield all([
        fork(fetchSubscriptionPricingsDefault),
        fork(editSubscriptionPricingDefault)
    ]);
}
