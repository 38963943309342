import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import {Helpers as Helper} from '../../../util/Helpers';
import FormControl from '@material-ui/core/FormControl';
import {Button} from '@material-ui/core';
import { connect } from "react-redux";
import { bankTransferSubscriptionGenerator } from "../../../appRedux/actions/admin/SubscriptionActions";
import { fetchBanksGenerator } from "../../../appRedux/actions/GeneralActions";

const styles = theme => ({
  card: {
    margin: 10,
    padding: 10
  },
  body1: {
    padding: 5,
  },
  body2: {
    padding: 5,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

const Branches = [{label: "Head Office", value: 1},{label: "CBD Office", value: 2},{label: "Astrol Office", value: 3}];

class BankTransfer extends React.Component {
  state = {
    amount: this.props.amount || this.props.amount_mpesa  || '',
    bank_name: this.props.bank_name,
    subscription_from:''
  };

  updateState = (e) => {
    const { id, name, value } = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState });
  };

  updateState1 = (name, e) => {
    const newState = Object.assign({}, this.state);
    newState[name] = e.value;
    this.setState({ ...newState });
  };

  atSubscriptionTopUp=()=>{
    return window.location.pathname === '/top-up';
  };
  componentDidMount() {
    this.props.fetchBanksGenerator();
 }
 onSubmit = ()=>{
    let data = {...this.state};
    if (this.atSubscriptionTopUp()){
      data['from_subscription'] = 1
    }else{
      data['from_subscription'] = 0
    }
   this.props.bankTransferSubscriptionGenerator(data)
 }

  updateState1 = (name, e) => {
    const newState = Object.assign({}, this.state);
    newState[name] = e.value;
    this.setState({ ...newState });
  };

  render() {
    const { classes,banks } = this.props;
    const { amount, bank_name } = this.state;
    return (
      <div className={classes.card}>
        <div className="row">
          <div className="jr-revenu">
            <div className="jr-revenu-row">
              <div className="jr-revenu-col col-xl-6 col-lg-6 col-md-6 col-12">
                <h4 className="text-secondary">Bank Transfer (Equity Bank)</h4>
                <Typography variant="body2">Payment Details:</Typography>
                <Typography variant="body2">Name: Workpay Africa Limited</Typography>
                <Typography variant="body2">Branch: Garden City</Typography>
                <Typography variant="body2">Acc No.: 1750279969480</Typography>
              </div>
              <div className="jr-revenu-col col-xl-6 col-lg-6 col-md-6 col-12">
                <h4 className="text-secondary">Bank Transfer (NCBA)</h4>
                <Typography variant="body2">Payment Details:</Typography>
                <Typography variant="body2">Name: WorkPay Africa Limited</Typography>
                <Typography variant="body2">Branch: TRM</Typography>
                <Typography variant="body2">Acc No.: 8393300024</Typography>
              </div>
            </div>
          </div>
        </div>
          <div className="row">
            <div className="col-12 text-muted" style={{ marginTop: 30, marginBottom: -10  }}>
              <h5>If you have already made a bank payment, kindly input the details below to get a subscription.</h5>
            </div>
            <div className="col-12">
              <FormControl fullWidth placeholder="Select Type" margin="normal" required>
                <InputLabel htmlFor="branch_id" className="select-label">
                  Select Branch
                </InputLabel>
                <Select
                  onChange={this.updateState1.bind(this,'branch_id')}
                  name="branch_id"
                  id="branch_id"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={Branches}
                  isSearchable={true}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    color: 'green',
                    colors: {
                      ...theme.colors,
                      primary: '#3CB534',
                      primary25: '#E2F4E0',
                      primary50: '#E2F4E0'
                    }
                  })}
                />
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth placeholder="Bank Name" margin="normal" required>
                <InputLabel htmlFor="bank_name" className="select-label">Bank Name</InputLabel>
                <Select
                  options={Helper.renderMenuItems(banks)}
                  isSearchable={true}
                  styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                  menuPortalTarget={document.body}
                  name='bank_name'
                  id="bank_name"
                  onChange={this.updateState1.bind(this, 'bank_name')}
                  defaultValue={bank_name}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    color: 'green',
                    colors: {
                      ...theme.colors,
                      primary: '#3CB534',
                      primary25: '#E2F4E0',
                      primary50: '#E2F4E0'
                    }
                  })}
                >
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                type="number"
                required
                id="amount"
                label="Amount"
                value={amount}
                fullWidth
                placeholder="Amount"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-12">
              <Button
                onClick={this.onSubmit}
                color="primary"
                variant="contained"
                size="small"
                style={{ textDecoration: 'none', marginTop: 10 }}>
                Submit
              </Button>
              {/*<Button
               onClick={() => Helper.choosePlanRedirect()}
                color="primary"
                variant="contained"
                size="small"
                style={{ textDecoration: 'none', marginTop: 10, marginLeft: 50 }}>
                Change current plan
              </Button>*/}
            </div>
          </div>
      </div>
    );
  }
}
const mapStateToProps = ({ general })=>{
 const {banksByID} = general;
 const banks = Object.values(banksByID);
 return {banks};
}
export default withStyles(styles)(connect(mapStateToProps,{fetchBanksGenerator,bankTransferSubscriptionGenerator})(BankTransfer));
