
import {
  BULK_DELETE_FINGERPRINTS_SUCCESS,
  DELETE_FINGERPRINTS_SUCCESS,
  FETCH_FINGERPRINTS_SUCCESS
} from "../../actions/attendance/FingerPrintsActions";

const INIT_STATE = {
  FingerPrintsByID:{},
};

export default(state=INIT_STATE,action)=>{
  switch (action.type){
    case FETCH_FINGERPRINTS_SUCCESS:
      return {
        ...state,
        FingerPrintsByID:action.payload
      };
    case DELETE_FINGERPRINTS_SUCCESS:
      return {
        ...state,
        FingerPrintsByID:action.payload
      };
    case BULK_DELETE_FINGERPRINTS_SUCCESS:
      return {
        ...state,
        FingerPrintsByID:action.payload
      };
    default:
      return state;
  }
}