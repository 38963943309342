
import {
  CREATE_HELB_SUCCESS,
  DELETE_HELB_SUCCESS,
  EDIT_HELB_SUCCESS,
  FETCH_HELBS_SUCCESS
} from "../../actions/payroll/HelbActions";
const INIT_STATE = {
 helbByID:{}
};
export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_HELBS_SUCCESS:
      return {
        ...state,
        helbByID: action.payload
      };
    case CREATE_HELB_SUCCESS:
    case EDIT_HELB_SUCCESS:
      let id = action.payload.id;
      return {
        ...state,
        helbByID: {
          ...state.helbByID,
          [id]:action.payload
        }
      };
    case DELETE_HELB_SUCCESS:
      let helbByID = {...state.helbByID};
      delete helbByID[action.payload.id];
      return {
        ...state,
        helbByID:helbByID
      };
    default:
      return state;
  }
}
