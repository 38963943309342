import { FETCH_CUSTOM_HOURS_SUCCESS, FETCH_TEAMS_SUCCESS } from "../../actions/attendance/CustomHoursActions";


const INIT_STATE ={
  customHours:{},
  teams:{}
};
export default (state =INIT_STATE,action) =>{
  if (action.type === FETCH_CUSTOM_HOURS_SUCCESS) {
    return {
      ...state,
      customHours:action.payload
    };
  }else if(action.type === FETCH_TEAMS_SUCCESS){
    return {
      ...state,
      teams:action.payload
    };
  } else {
    return state;
  }
}
