export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const SHOW_ROLE_CREATE_FORM = 'SHOW_ROLE_CREATE_FORM';
export const HIDE_ROLE_CREATE_FORM = 'HIDE_ROLE_CREATE_FORM';
export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const SHOW_ROLE_EDIT_FORM = 'SHOW_ROLE_EDIT_FORM';
export const HIDE_ROLE_EDIT_FORM = 'HIDE_ROLE_EDIT_FORM';
export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const SHOW_ROLE_DELETE_FORM = 'SHOW_ROLE_DELETE_FORM';
export const HIDE_ROLE_DELETE_FORM = 'HIDE_ROLE_DELETE_FORM';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";

export const fetchRolesGenerator = (data=[])=>{
  return {
      type:FETCH_ROLES,
      payload:data
    };
};

export const fetchRolesSuccessGenerator = (dataById)=> {
    return {
      type:FETCH_ROLES_SUCCESS,
      payload:dataById
    }
};

export const showRoleCreateFormGenerator = ()=>{
  return {
    type:SHOW_ROLE_CREATE_FORM
  };
};
export const hideRoleCreateFormGenerator = ()=>{
  return {
    type:HIDE_ROLE_CREATE_FORM
  };
};
export const addRoleGenerator = (data)=>{
  return {
    type: ADD_ROLE,
    payload: data
  }
};

export const addRoleSuccessGenerator = (data)=>{
  return {
    type: ADD_ROLE_SUCCESS,
    payload: data
  }
};
export const showRoleEditFormGenerator = (data)=>{
  return {
    type:SHOW_ROLE_EDIT_FORM,
    payload:data
  };
};
export const hideRoleEditFormGenerator = ()=>{
  return {
    type:HIDE_ROLE_EDIT_FORM
  };
};
export const editRoleGenerator = (data)=>{
  return {
    type: EDIT_ROLE,
    payload: data
  }
};
export const editRoleSuccessGenerator = (data)=>{
  return {
    type: EDIT_ROLE_SUCCESS,
    payload: data
  }
};
export const showRoleDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_ROLE_DELETE_FORM,
    payload:data
  };
};
export const hideRoleDeleteFormGenerator = ()=>{
  return {
    type:HIDE_ROLE_DELETE_FORM
  };
};
export const deleteRoleGenerator = (data)=>{
  return {
    type: DELETE_ROLE,
    payload: data
  }
};
export const deleteRoleSuccessGenerator = (data)=>{
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: data
  }
};
export const fetchPermissionsGenerator = ()=>{
   return {
       type: FETCH_PERMISSIONS
   }
};
export const fetchPermissionsSuccessGenerator = (data)=>{
  return {
    type: FETCH_PERMISSIONS_SUCCESS,
    payload: data
  }
};
