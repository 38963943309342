import {
  FETCH_LOAN_EARNED_INTEREST_REPORT_SUCCESS, FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT_SUCCESS,
  FETCH_LOAN_REPAYMENTS_REPORT_SUCCESS,
  FETCH_REPORTS_MONTHS_SUCCESS
} from "../../actions/admin/LoanReportsActions";

const INIT_STATE = {
  monthYearMap:{},
  repaymentsReport:{},
  earnedInterestReport:{},
  earnedPenaltyAndFeesReport:{}
};

export default (status=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_REPORTS_MONTHS_SUCCESS:
      return{
        ...status,
        monthYearMap:action.payload
      };
    case FETCH_LOAN_REPAYMENTS_REPORT_SUCCESS:
      return{
        ...status,
        repaymentsReport:action.payload
      };
    case FETCH_LOAN_EARNED_INTEREST_REPORT_SUCCESS:
      return{
        ...status,
        earnedInterestReport:action.payload
      };
    case FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT_SUCCESS:
      return{
        ...status,
        earnedPenaltyAndFeesReport:action.payload
      };
    default:
      return status;
  }
}
