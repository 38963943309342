import {all, call, fork, put} from "redux-saga/effects";
import {
    ADD_SNAPCHECK, DELETE_SNAPCHECK, deleteSnapCheckSuccessGenerator, EDIT_SNAPCHECK, editSnapCheckSuccessGenerator,
    FETCH_SNAPCHECKS,
    fetchSnapChecksSuccessGenerator,addSnapCheckSuccessGenerator
} from "../../actions/attendance/SnapCheckActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchSnapChecksRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/attendance/snap-check-requests',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addSnapCheckRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/snap-check-request/add',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteSnapCheckRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/snap-check-request/delete',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const editSnapCheckRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/snap-check-request/update',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchSnapChecks({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchSnapChecksRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchSnapChecksSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addSnapCheck({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addSnapCheckRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(addSnapCheckSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteSnapCheck({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteSnapCheckRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(deleteSnapCheckSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editSnapCheck({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(editSnapCheckRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(editSnapCheckSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchSnapChecksDefault() {
    yield takeLatest(FETCH_SNAPCHECKS, fetchSnapChecks)
}
export function* addSnapCheckDefault() {
    yield takeLatest(ADD_SNAPCHECK, addSnapCheck);
}
export function* deleteSnapCheckDefault() {
    yield takeLatest(DELETE_SNAPCHECK, deleteSnapCheck);
}
export function* editSnapCheckDefault() {
    yield takeLatest(EDIT_SNAPCHECK, editSnapCheck);
}
export default function* rootSaga() {
    yield all([fork(fetchSnapChecksDefault),
        fork(addSnapCheckDefault),
        fork(deleteSnapCheckDefault),
        fork(editSnapCheckDefault)
    ]);
}
