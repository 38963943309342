import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
  MANUAL_CLOCK,
  DELETE_ATTENDANCE_RECORD,
  FETCH_ATTENDANCE,
  fetchAttendanceSuccessGenerator,
  UPDATE_ATTENDANCE_RECORD,
  fetchAttendanceGenerator,
  fetchAttendanceDashboardSuccessGenerator,
  FETCH_ATTENDANCE_DASHBOARD,
  fetchAttendanceKeysSuccessGenerator, FETCH_ATTENDANCE_KEYS, updateAttendanceKeySuccessGenerator, UPDATE_ATTENDANCE_KEY
} from "../../actions/attendance/AttendanceActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";

const fetchAttendanceRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/day/details',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchAttendanceKeysRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/keys',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchAttendanceDashboardRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/dashboard',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addAttendanceRequest = async (requestParameters) =>{
  let path = '/attendance/manual/clock-in';
  if(requestParameters.action === 'manual-clock-out'){
    path = '/attendance/manual/clock-out';
  }
  return await  axios.post(API_ENDPOINT+path,requestParameters)
    .then(response => response)
    .catch(error => error.response);
};

const editAttendanceKeyRequest = async (requestParameters) =>{
  return await  axios.post(API_ENDPOINT+'/attendance/key/update',requestParameters)
    .then(response => response)
    .catch(error => error.response);
};

const deleteAttendanceRequest = async (requestParameters) =>{
  let path = '';
  if(requestParameters.action === 'delete-absence'){
    path = '/attendance/absence/delete';
  }else if(requestParameters.action === 'delete'){
    path = '/attendance/attendance/delete';
  }
  return await  axios.post(API_ENDPOINT+path,requestParameters)
    .then(response => response)
    .catch(error => error.response);
};

const editAttendanceRequest = async (requestParameters) =>{
  let path = '';
  if(requestParameters.action === 'approve'){
    path = '/attendance/attendance/approve';
  }else if(requestParameters.action === 'disapprove'){
    path = '/attendance/attendance/disapprove';
  }else if(requestParameters.action === 'authorise-absence'){
    path = '/attendance/absence/authorise';
  }else if(requestParameters.action === 'update-absence'){
    path = '/attendance/absence/edit';
  }else if(requestParameters.action === 'update' || requestParameters.action === 'check-out'){
    path = '/attendance/attendance/edit';
  }
  return await  axios.post(API_ENDPOINT+path,requestParameters)
    .then(response => response)
    .catch(error => error.response);
  };

function* fetchAttendance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAttendanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchAttendanceKeys({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAttendanceKeysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceKeysSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editAttendanceKey({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAttendanceKeyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(updateAttendanceKeySuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchAttendanceDashboard({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAttendanceDashboardRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceDashboardSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addAttendance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addAttendanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteAttendance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteAttendanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editAttendance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAttendanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchAttendanceDefault() {
  yield takeLatest(FETCH_ATTENDANCE, fetchAttendance)
}
export function* fetchAttendanceKeysDefault() {
  yield takeLatest(FETCH_ATTENDANCE_KEYS, fetchAttendanceKeys)
}
export function* editAttendanceKeyDefault() {
  yield takeLatest(UPDATE_ATTENDANCE_KEY, editAttendanceKey)
}
export function* fetchAttendanceDashboardDefault() {
  yield takeLatest(FETCH_ATTENDANCE_DASHBOARD, fetchAttendanceDashboard)
}
export function* addAttendanceDefault() {
  yield takeLatest(MANUAL_CLOCK, addAttendance);
}
export function* deleteAttendanceDefault() {
  yield takeLatest(DELETE_ATTENDANCE_RECORD, deleteAttendance);
}
export function* editAttendanceDefault() {
  yield takeLatest(UPDATE_ATTENDANCE_RECORD, editAttendance);
}

export default function* rootSaga() {
  yield all([fork(fetchAttendanceDefault),
    fork(addAttendanceDefault),
    fork(deleteAttendanceDefault),
    fork(editAttendanceDefault),
    fork(fetchAttendanceDashboardDefault),
    fork(fetchAttendanceKeysDefault),
    fork(editAttendanceKeyDefault)
  ]);
}
