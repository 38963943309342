export const ADD_EMPLOYEE_NUMBER_FORMAT = 'ADD_EMPLOYEE_NUMBER_FORMAT';
export const ADD_EMPLOYEE_NUMBER_FORMAT_SUCCESS = 'ADD_EMPLOYEE_NUMBER_FORMAT_SUCCESS';
export const SHOW_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM = 'SHOW_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM';
export const HIDE_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM = 'HIDE_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM';
/// fetch
export const FETCH_EMPLOYEE_NUMBER_FORMAT = 'FETCH_EMPLOYEE_NUMBER_FORMAT';
export const FETCH_EMPLOYEE_NUMBER_FORMAT_SUCCESS = 'FETCH_EMPLOYEE_NUMBER_FORMAT_SUCCESS';
// ////edit
export const EDIT_EMPLOYEE_NUMBER_FORMAT = 'EDIT_EMPLOYEE_NUMBER_FORMAT';
export const EDIT_EMPLOYEE_NUMBER_FORMAT_SUCCESS = 'EDIT_EMPLOYEE_NUMBER_FORMAT_SUCCESS';
export const SHOW_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM = 'SHOW_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM';
export const HIDE_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM = 'HIDE_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM';
// ////delete
export const DELETE_EMPLOYEE_NUMBER_FORMAT = 'DELETE_EMPLOYEE_NUMBER_FORMAT';
export const DELETE_EMPLOYEE_NUMBER_FORMAT_SUCCESS ='DELETE_EMPLOYEE_NUMBER_FORMAT_SUCCESS';
export const SHOW_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL = 'SHOW_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL';
export const HIDE_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL = 'HIDE_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL';
/////CREATE
export const addEmployeeNumberFormatGenerator = (data)=>{
  return {
    type:ADD_EMPLOYEE_NUMBER_FORMAT ,
    payload: data
  }
};
export const showEmployeeNumberFormatCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM,
    payload:data
  }
};
export const hideEmployeeNumberFormatCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM,
    payload:data
  }
};
export const addEmployeeNumberFormatSuccessGenerator =(data)=>{
  return {
    type:ADD_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchEmployeeNumberFormatGenerator=(data=[])=>{
  return {
    type:FETCH_EMPLOYEE_NUMBER_FORMAT,
    payload:data
  }
};

export const fetchEmployeeNumberFormatSuccessGenerator =(data)=>{
  return {
    type:FETCH_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
    payload:data
  }
};


///EDIT
export const editEmployeeNumberFormatGenerator = (data)=>{
  return {
    type: EDIT_EMPLOYEE_NUMBER_FORMAT,
    payload: data
  }
};

export const editEmployeeNumberFormatSuccessGenerator=(data)=>{
  return {
    type:EDIT_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
    payload:data
  }
};
export const showEmployeeNumberFormatEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM,
    payload:data
  }
};
export const hideEmployeeNumberFormatEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM,
    payload:data
  }
};
// /////DELETE
export const deleteEmployeeNumberFormatGenerator=(data)=>{
  return {
    type:DELETE_EMPLOYEE_NUMBER_FORMAT,
    payload:data
  }
};

export const deleteEmployeeNumberFormatSuccessGenerator = (data)=>{
  return {
    type:DELETE_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
    payload:data
  }
};
export const showEmployeeNumberFormatDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL,
    payload:data
  }
};
export const hideEmployeeNumberFormatDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL,
    payload:data
  }
};


