export const FETCH_EXPENSE_CATEGORIES = "FETCH_EXPENSE_CATEGORIES";
export const FETCH_EXPENSE_CATEGORIES_SUCCESS = "FETCH_EXPENSE_CATEGORIES_SUCCESS";
export const SHOW_EXPENSE_CATEGORY_CREATE_FORM = 'SHOW_EXPENSE_CATEGORY_CREATE_FORM';
export const HIDE_EXPENSE_CATEGORY_CREATE_FORM = 'HIDE_EXPENSE_CATEGORY_CREATE_FORM';
export const ADD_EXPENSE_CATEGORY = 'ADD_EXPENSE_CATEGORY';
export const ADD_EXPENSE_CATEGORY_SUCCESS = 'ADD_EXPENSE_CATEGORY_SUCCESS';
export const SHOW_EXPENSE_CATEGORY_EDIT_FORM = 'SHOW_EXPENSE_CATEGORY_EDIT_FORM';
export const HIDE_EXPENSE_CATEGORY_EDIT_FORM = 'HIDE_EXPENSE_CATEGORY_EDIT_FORM';
export const EDIT_EXPENSE_CATEGORY = 'EDIT_EXPENSE_CATEGORY';
export const EDIT_EXPENSE_CATEGORY_SUCCESS = 'EDIT_EXPENSE_CATEGORY_SUCCESS';
export const SHOW_EXPENSE_CATEGORY_DELETE_FORM = 'SHOW_EXPENSE_CATEGORY_DELETE_FORM';
export const HIDE_EXPENSE_CATEGORY_DELETE_FORM = 'HIDE_EXPENSE_CATEGORY_DELETE_FORM';
export const DELETE_EXPENSE_CATEGORY = 'DELETE_EXPENSE_CATEGORY';
export const DELETE_EXPENSE_CATEGORY_SUCCESS = 'DELETE_EXPENSE_CATEGORY_SUCCESS';

export const FETCH_EXPENSE_SUB_CATEGORIES = "FETCH_EXPENSE_SUB_CATEGORIES";
export const FETCH_EXPENSE_SUB_CATEGORIES_SUCCESS = "FETCH_EXPENSE_SUB_CATEGORIES_SUCCESS";

export const fetchExpenseSubCategoriesGenerator = (data=[])=>{
  return {
    type:FETCH_EXPENSE_SUB_CATEGORIES,
    payload:data
  };
};
export const fetchExpenseSubCategoriesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_EXPENSE_SUB_CATEGORIES_SUCCESS,
    payload:dataById
  }
};

export const fetchExpenseCategoriesGenerator = (data=[])=>{
  return {
    type:FETCH_EXPENSE_CATEGORIES,
    payload:data
  };
};
export const fetchExpenseCategoriesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_EXPENSE_CATEGORIES_SUCCESS,
    payload:dataById
  }
};

export const showExpenseCategoryCreateFormGenerator = ()=>{
  return {
    type:SHOW_EXPENSE_CATEGORY_CREATE_FORM
  };
};
export const hideExpenseCategoryCreateFormGenerator = ()=>{
  return {
    type:HIDE_EXPENSE_CATEGORY_CREATE_FORM
  };
};
export const addExpenseCategoryGenerator = (data)=>{
  return {
    type: ADD_EXPENSE_CATEGORY,
    payload: data
  }
};

export const addExpenseCategorySuccessGenerator = (data)=>{
  return {
    type: ADD_EXPENSE_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showExpenseCategoryEditFormGenerator = (data)=>{
  return {
    type:SHOW_EXPENSE_CATEGORY_EDIT_FORM,
    payload:data
  };
};
export const hideExpenseCategoryEditFormGenerator = ()=>{
  return {
    type:HIDE_EXPENSE_CATEGORY_EDIT_FORM
  };
};
export const editExpenseCategoryGenerator = (data)=>{
  return {
    type: EDIT_EXPENSE_CATEGORY,
    payload: data
  }
};
export const editExpenseCategorySuccessGenerator = (data)=>{
  return {
    type: EDIT_EXPENSE_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showExpenseCategoryDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_EXPENSE_CATEGORY_DELETE_FORM,
    payload:data
  };
};
export const hideExpenseCategoryDeleteFormGenerator = ()=>{
  return {
    type:HIDE_EXPENSE_CATEGORY_DELETE_FORM
  };
};
export const deleteExpenseCategoryGenerator = (data)=>{
  return {
    type: DELETE_EXPENSE_CATEGORY,
    payload: data
  }
};
export const deleteExpenseCategorySuccessGenerator = (data)=>{
  return {
    type: DELETE_EXPENSE_CATEGORY_SUCCESS,
    payload: data
  }
};
