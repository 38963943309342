import {
  ADD_FROZEN_TO_MUSTER_ROLL_SUCCESS,
  DELETE_SALARY_ADVANCE_REPORT_SUCCESS, EDIT_PAROLL_VARIANCE_REMARK_SUCCESS,
  FETCH_ALLOWANCE_LEDGER_SUCCESS,
  FETCH_AUDIT_LOG_REPORT_SUCCESS,
  FETCH_DEDUCTION_LEDGER_REPORT_SUCCESS, FETCH_EMPLOYEE_PAY_VARIANCE_REPORT_SUCCESS,
  FETCH_LEAVE_BALANCE_REPORT_SUCCESS,
  FETCH_LEAVES_ATTENDED_REPORT_SUCCESS,
  FETCH_LOAN_PAYMENT_REPORT_SUCCESS,
  FETCH_MUSTER_ROLL_SUCCESS,
  FETCH_NET_PAY_SUCCESS,
  FETCH_PAYROLL_VARIANCE_REPORT_SUCCESS,
  FETCH_QUEUED_MUSTER_ROLL_EXPORTS_SUCCESS,
  FETCH_SALARY_ADVANCE_REPORT_SUCCESS,
  FETCH_STATUTORY_REPORT_SUCCESS,
  FETCH_VIEW_PAYSLIPS_SUCCESS,
  GENERATE_INSURANCE_PROVIDENT_SUCCESS,
  GENERATE_PAYROLL_JOURNAL_SUCCESS,
  GENERATE_PENSION_SUCCESS,
  HIDE_ALLOWANCE_LEDGER_FORM,
  HIDE_AUDIT_LOG_REPORT_FORM,
  HIDE_DEDUCTION_LEDGER_REPORT_FORM,
  HIDE_MUSTER_ROLL_FORM,
  HIDE_NET_PAY_FORM,
  HIDE_SALARY_ADVANCE_REPORT_FORM,
  HIDE_STATUTORY_FORM,
  HIDE_VIEW_PAYSLIPS_FORM,
  SHOW_ALLOWANCE_LEDGER_FORM,
  SHOW_AUDIT_LOG_REPORT_FORM,
  SHOW_DEDUCTION_LEDGER_REPORT_FORM,
  SHOW_MUSTER_ROLL_FORM,
  SHOW_NET_PAY_FORM,
  SHOW_SALARY_ADVANCE_REPORT_FORM,
  SHOW_STATUTORY_FORM,
  SHOW_VIEW_PAYSLIPS_FORM
} from "../../actions/payroll/PayrollReportAction";

const INIT_STATE = {
  leaveBalanceReportByID: {},
  statutoryByID: {},
  allowanceLedgerByID: {},
  musterRollByID: {},
  viewPayslipsByID: {},
  netPayByID: {},
  auditLogByID: {},
  loanPaymentByID: {},
  salaryAdvanceReportById: {},
  deductionLedgerReportById: {},
  payrollJournalByID: {},
  insuranceProvidentData: {},
  showStatutoryForm: false,
  showAllowanceLedgerForm: false,
  showMusterRollForm: false,
  showViewPayslipsForm: false,
  showNetPayForm: false,
  showAuditLogForm: false,
  showSalaryAdvanceReportForm: false,
  showDeductionLedgerReportForm: false,
  year:'',
  type:'',
  month:'',
  batchId:'',
  activeBenefits:[],
  queuedMusterRollExportsByID:{},
  pensionData:{},
  leavesAttendedReport:{},
  payrollVarianceReport:{},
  employeePayVarianceReport:{},
  has_consultant:false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GENERATE_PENSION_SUCCESS:{
      return {
        ...state,
        pensionData: action.payload
      }
    }
    case GENERATE_INSURANCE_PROVIDENT_SUCCESS  : {
      return{
        ...state,
        insuranceProvidentData: action.payload
      }
    }
    case GENERATE_PAYROLL_JOURNAL_SUCCESS:{
      return {
        ...state,
        payrollJournalByID: action.payload
      }
    }
    case FETCH_LEAVE_BALANCE_REPORT_SUCCESS:
      return {
        ...state,
        leaveBalanceReportByID: action.payload
      };
    case FETCH_STATUTORY_REPORT_SUCCESS:
      return {
        ...state,
        statutoryByID: action.payload.statutory,
        year:action.payload.year,
        month:action.payload.month,
        type:action.payload.type,
      };
    case SHOW_STATUTORY_FORM:
      return {
        ...state,
        showStatutoryForm: true
      };
    case HIDE_STATUTORY_FORM:
      return {
        ...state,
        showStatutoryForm: false
      };
    case FETCH_MUSTER_ROLL_SUCCESS:
      return {
        ...state,
        musterRollByID: action.payload,
        year:action.payload.year,
        month:action.payload.month,
        batchId:action.payload.batch_id,
        activeBenefits:action.payload.active_benefits,
        has_consultant:action.payload.has_consultant
      };
    case SHOW_MUSTER_ROLL_FORM:
      return {
        ...state,
        showMusterRollForm: true
      };
    case HIDE_MUSTER_ROLL_FORM:
      return {
        ...state,
        showMusterRollForm: false
      };
    case FETCH_ALLOWANCE_LEDGER_SUCCESS:
      return {
        ...state,
        allowanceLedgerByID: action.payload
      };
    case SHOW_ALLOWANCE_LEDGER_FORM:
      return {
        ...state,
        showAllowanceLedgerForm: true
      };
    case HIDE_ALLOWANCE_LEDGER_FORM:
      return {
        ...state,
        showAllowanceLedgerForm: false
      };
    case FETCH_VIEW_PAYSLIPS_SUCCESS:
      return {
        ...state,
        viewPayslipsByID: action.payload
      };
    case SHOW_VIEW_PAYSLIPS_FORM:
      return {
        ...state,
        showViewPayslipsForm: true
      };
    case HIDE_VIEW_PAYSLIPS_FORM:
      return {
        ...state,
        showViewPayslipsForm: false
      };
    case FETCH_NET_PAY_SUCCESS:
      return {
        ...state,
        netPayByID: action.payload
      };
    case SHOW_NET_PAY_FORM:
      return {
        ...state,
        showNetPayForm: true
      };
    case HIDE_NET_PAY_FORM:
      return {
        ...state,
        showNetPayForm: false
      };
    case FETCH_AUDIT_LOG_REPORT_SUCCESS:
      return {
        ...state,
        auditLogByID: action.payload
      };
    case SHOW_AUDIT_LOG_REPORT_FORM:
      return {
        ...state,
        showAuditLogForm: true,
      };
    case HIDE_AUDIT_LOG_REPORT_FORM:
      return {
        ...state,
        showAuditLogForm: false,
      };
    case FETCH_LOAN_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        loanPaymentByID: action.payload
      };
    case FETCH_SALARY_ADVANCE_REPORT_SUCCESS:
      return {
        ...state,
        salaryAdvanceReportById: action.payload
      };
    case SHOW_SALARY_ADVANCE_REPORT_FORM:
      return {
        ...state,
        showSalaryAdvanceReportForm: true
      };
    case HIDE_SALARY_ADVANCE_REPORT_FORM:
      return {
        ...state,
        showSalaryAdvanceReportForm: false
      };
    case FETCH_DEDUCTION_LEDGER_REPORT_SUCCESS:
      return {
        ...state,
        deductionLedgerReportById: action.payload
      };
    case SHOW_DEDUCTION_LEDGER_REPORT_FORM:
      return {
        ...state,
        showDeductionLedgerReportForm: true
      };
    case HIDE_DEDUCTION_LEDGER_REPORT_FORM:
      return {
        ...state,
        showDeductionLedgerReportForm: false
      };
    case DELETE_SALARY_ADVANCE_REPORT_SUCCESS:
      let salaryAdvanceReportById = {...state.salaryAdvanceReportById};
      delete salaryAdvanceReportById[action.payload.id];
      return {
        ...state,
        salaryAdvanceReportById:salaryAdvanceReportById
      };
    case ADD_FROZEN_TO_MUSTER_ROLL_SUCCESS:
      let muster = {...state.musterRollByID};
      muster['muster'][action.payload.id] = action.payload;
      return {
        ...state,
        musterRollByID:muster
      };
    case FETCH_QUEUED_MUSTER_ROLL_EXPORTS_SUCCESS:
        return {
          ...state,
          queuedMusterRollExportsByID: action.payload
        };
    case FETCH_LEAVES_ATTENDED_REPORT_SUCCESS:
      return {
        ...state,
        leavesAttendedReport: action.payload
      };
    case FETCH_PAYROLL_VARIANCE_REPORT_SUCCESS:
      return {
        ...state,
        payrollVarianceReport: action.payload
      };
    case FETCH_EMPLOYEE_PAY_VARIANCE_REPORT_SUCCESS:
      return {
        ...state,
        employeePayVarianceReport: action.payload
      };
    case EDIT_PAROLL_VARIANCE_REMARK_SUCCESS:
      let employeePayVarianceReport = {...state.employeePayVarianceReport};
      let employees = [...employeePayVarianceReport.report.data];
      employees = employees.map(record=>{
        if(record.id === action.payload.employee_id){
          record.payrolls = record.payrolls.map(payroll=>{
            if(payroll.id === action.payload.id){
              return action.payload
            }else{
              return payroll;
            }
          });
          return record;
        }else{
          return record;
        }
      });
      employeePayVarianceReport.report.data = employees;
      return {
        ...state,
        employeePayVarianceReport
      };
    default:
      return state;
  }
};
