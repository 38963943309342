import {
    ATTENDANCE_ADD_EMPLOYEE_SUCCESS,
    ATTENDANCE_DELETE_EMPLOYEE_SUCCESS,
    ATTENDANCE_FETCH_EMPLOYEE_SUCCESS,
    ATTENDANCE_UPDATE_EMPLOYEE_SUCCESS,
    ATTENDANCE_ADD_BULK_EMPLOYEES_SUCCESS
} from "../../actions/attendance/EmployeesActions";
import { EDIT_EMPLOYEE_SHARES_SUCCESS } from "../../actions/payroll/EmployeeActions";

const INIT_STATE = {
    employeesByID: {data:[]}//This was finally paginated
};

export default (state=INIT_STATE,action)=> {
    let employees;
    switch (action.type) {
        case ATTENDANCE_FETCH_EMPLOYEE_SUCCESS:
        case ATTENDANCE_ADD_BULK_EMPLOYEES_SUCCESS:
            return{
                ...state,
                employeesByID: action.payload
            };
        case ATTENDANCE_ADD_EMPLOYEE_SUCCESS:
            employees = {...state.employeesByID.data};
            employees.push(action.payload);
            return {
                ...state,
                employeesByID: employees
            };
        case ATTENDANCE_UPDATE_EMPLOYEE_SUCCESS:
            employees = {...state.employeesByID.data};
            employees = employees.map(record=>{
                if(record.id === action.payload.id){
                    return action.payload;
                }
                return employee;
            });
            return {
                ...state,
                employeesByID: employees
            };
        case ATTENDANCE_DELETE_EMPLOYEE_SUCCESS:
            employees ={...state.employeesByID.data};
            employees = employees.where((record)=>{
                return record.id !== action.payload.id;
            });
            return{
                ...state,
                employeesByID:employees
            };
        case EDIT_EMPLOYEE_SHARES_SUCCESS:
            let paginatedData ={...state.employeesByID};
            if(paginatedData && paginatedData.hasOwnProperty('data')){
                let data = paginatedData.data.map(employee=>{
                    return employee.id === action.payload.id?action.payload:employee;
                });
                return {
                    ...state,
                    employeesByID: {...paginatedData, data:data}
                };
            }
            return state;
        default:
            return state;
    }
}
