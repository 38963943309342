export const ADD_DESIGNATION = 'ADD_DESIGNATION';
export const ADD_DESIGNATION_SUCCESS = 'ADD_DESIGNATION_SUCCESS';
export const SHOW_CREATE_DESIGNATION_FORM = 'SHOW_CREATE_DESIGNATION_FORM';
export const HIDE_CREATE_DESIGNATION_FORM = 'HIDE_CREATE_DESIGNATION_FORM';
/// fetch
export const FETCH_DESIGNATIONS = 'FETCH_DESIGNATIONS';
export const FETCH_DESIGNATION_SUCCESS = 'FETCH_DESIGNATION_SUCCESS';
////delete
export const DELETE_DESIGNATION = 'DELETE_DESIGNATION';
export const DELETE_DESIGNATION_SUCCESS ='DELETE_DESIGNATION_SUCCESS';
export const SHOW_DELETE_DESIGNATION_MODAL = 'SHOW_DELETE_DESIGNATION_MODAL';
export const HIDE_DELETE_DESIGNATION_MODAL = 'HIDE_DELETE_DESIGNATION_MODAL';
////edit
export const EDIT_DESIGNATION = 'EDIT_DESIGNATION';
export const EDIT_DESIGNATION_SUCCESS = 'EDIT_DESIGNATION_SUCCESS';
export const SHOW_EDIT_DESIGNATION_FORM = 'SHOW_EDIT_DESIGNATION_FORM';
export const HIDE_EDIT_DESIGNATION_FORM = 'HIDE_EDIT_DESIGNATION_FORM';

/////CREATE
export const addDesignationGenerator = (data)=>{
  return {
    type: ADD_DESIGNATION,
    payload: data
  }
};
export const showDesignationCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_DESIGNATION_FORM,
    payload:data
  }
};
export const hideDesignationCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_DESIGNATION_FORM,
    payload:data
  }
};
export const addDesignationSuccessGenerator =(data)=>{
  return {
    type:ADD_DESIGNATION_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchDesignationGenerator=(data=[])=>{
  return {
    type:FETCH_DESIGNATIONS,
    payload:data
  }
};

export const fetchDesignationSuccessGenerator =(data)=>{
  return {
    type:FETCH_DESIGNATION_SUCCESS,
    payload:data
  }
};


///EDIT
export const editDesignationGenerator = (data)=>{
  return {
    type: EDIT_DESIGNATION,
    payload: data
  }
};

export const editDesignationSuccessGenerator=(data)=>{
  return {
    type:EDIT_DESIGNATION_SUCCESS,
    payload:data
  }
};
export const showDesignationEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_DESIGNATION_FORM,
    payload:data
  }
};
export const hideDesignationEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_DESIGNATION_FORM,
    payload:data
  }
};
/////DELETE
export const deletedDesignationGenerator=(data)=>{
  return {
    type:DELETE_DESIGNATION,
    payload:data
  }
};

export const deleteDesignationSuccessGenerator = (data)=>{
  return {
    type:DELETE_DESIGNATION_SUCCESS,
    payload:data
  }
};
export const showDesignationDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_DESIGNATION_MODAL,
    payload:data
  }
};
export const hideDesignationDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_DESIGNATION_MODAL,
    payload:data
  }
};
