//Contact Module const
import {APP_START} from "../../constants/ActionTypes";
import { Helpers } from "../../util/Helpers";

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_BANKS = 'FETCH_BANKS';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_SECTORS = 'FETCH_SECTORS';
export const FETCH_SECTORS_SUCCESS = 'FETCH_SECTORS_SUCCESS';
export const FETCH_NHIF_RATES = 'FETCH_NHIF_RATES';
export const FETCH_NHIF_RATES_SUCCESS = 'FETCH_NHIF_RATES_SUCCESS';
export const FETCH_NSSF_RATES = 'FETCH_NSSF_RATES';
export const FETCH_NSSF_RATES_SUCCESS = 'FETCH_NSSF_RATES_SUCCESS';
export const FETCH_PAYE_RATES = 'FETCH_PAYE_RATES';
export const FETCH_PAYE_RATES_SUCCESS = 'FETCH_PAYE_RATES_SUCCESS';
export const FILTER_PORTAL_DATA = 'FILTER_PORTAL_DATA';
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER';
export const ADD_BANK = 'ADD_BANK';
export const ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS';
export const EDIT_BANK = 'EDIT_BANK';
export const EDIT_BANK_SUCCESS = 'EDIT_BANK_SUCCESS';
export const DELETE_BANK = 'DELETE_BANK';
export const DELETE_BANK_SUCCESS = 'DELETE_BANK';

export const ADD_BANK_BRANCH = 'ADD_BANK_BRANCH';
export const ADD_BANK_BRANCH_SUCCESS = 'ADD_BANK_BRANCH_SUCCESS';
export const EDIT_BANK_BRANCH = 'EDIT_BANK_BRANCH';
export const EDIT_BANK_BRANCH_SUCCESS = 'EDIT_BANK_BRANCH_SUCCESS';

export const FETCH_ACCOUNT_SETTINGS = 'FETCH_ACCOUNT_SETTINGS';
export const FETCH_ACCOUNT_SETTINGS_SUCCESS = 'FETCH_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';

export const FETCH_ADMIN_SYSTEM_SETTINGS = "FETCH_ADMIN_SYSTEM_SETTINGS";
export const FETCH_ADMIN_SYSTEM_SETTINGS_SUCCESS = "FETCH_ADMIN_SYSTEM_SETTINGS_SUCCESS";
export const UPDATE_ADMIN_SYSTEM_SETTINGS = "UPDATE_ADMIN_SYSTEM_SETTINGS";

export const FETCH_SUBSCRIPTION_PLANS = "FETCH_SUBSCRIPTION_PLANS";
export const FETCH_SUBSCRIPTION_PLANS_SUCCESS = "FETCH_SUBSCRIPTION_PLANS_SUCCESS";

export const fetchSubscriptionPlansGenerator = (data = [])=>{
  return {
    type: FETCH_SUBSCRIPTION_PLANS,
    payload: data
  };
};
export const fetchSubscriptionPlansSuccessGenerator = (data = [])=>{
  return {
    type: FETCH_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data
  };
};

export const appStartGenerator =(data=[])=>{
  return {
    type: APP_START,
    payload: data
  };
};
export const showMessageGenerator = (message,type='info') => {
  Helpers.pushNotification(message,type);
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showAppLoaderGenerator = (actionType=null) => {
  return {
    type: SHOW_APP_LOADER,
    payload: actionType
  };
};

export const hideAppLoaderGenerator = (actionType=null) => {
  return {
    type: HIDE_APP_LOADER,
    payload: actionType
  };
};

export const fetchCurrenciesGenerator = (data=[]) => {
  return {
    type: FETCH_CURRENCIES,
    payload:data
  };
};
export const fetchCurrenciesSuccessGenerator = (data) => {
  return {
    type: FETCH_CURRENCIES_SUCCESS,
    payload:data
  };
};
export const fetchCountriesGenerator = (data) => {
  return {
    type: FETCH_COUNTRIES,
    payload:data
  };
};
export const fetchCountriesSuccessGenerator = (data) => {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    payload:data
  };
};
export const fetchBanksGenerator = (data=[]) => {
  return {
    type: FETCH_BANKS,
    payload:data
  };
};
export const fetchBanksSuccessGenerator = (data) => {
  return {
    type: FETCH_BANKS_SUCCESS,
    payload:data
  };
};
export const fetchSectorsGenerator = (data=[]) => {
  return {
    type: FETCH_SECTORS,
    payload:data
  };
};
export const fetchSectorsSuccessGenerator = (data) => {
  return {
    type: FETCH_SECTORS_SUCCESS,
    payload:data
  };
};
export const fetchNHIFRatesGenerator = (data=[]) => {
  return {
    type: FETCH_NHIF_RATES,
    payload:data
  };
};
export const fetchNHIFRatesSuccessGenerator = (data=[]) => {
  return {
    type: FETCH_NHIF_RATES_SUCCESS,
    payload:data
  };
};
export const fetchNSSFRatesGenerator = (data=[]) => {
  return {
    type: FETCH_NSSF_RATES,
    payload:data
  };
};
export const fetchNSSFRatesSuccessGenerator = (data=[]) => {
  return {
    type: FETCH_NSSF_RATES_SUCCESS,
    payload:data
  };
};
export const fetchPAYERatesGenerator = (data=[]) => {
  return {
    type: FETCH_PAYE_RATES,
    payload:data
  };
};
export const fetchPAYERatesSuccessGenerator = (data) => {
  return {
    type: FETCH_PAYE_RATES_SUCCESS,
    payload:data
  };
};
export const addBankGenerator = (data) => {
  return {
    type: ADD_BANK,
    payload:data
  };
};
export const addBankSuccessGenerator = (data) => {
  return {
    type: ADD_BANK_SUCCESS,
    payload:data
  };
};
export const editBankSuccessGenerator = (data) => {
  return {
    type: EDIT_BANK_SUCCESS,
    payload:data
  };
};
export const editBankGenerator = (data) => {
  return {
    type: EDIT_BANK,
    payload:data
  };
};
export const deleteBankGenerator = (data) => {
  return {
    type: DELETE_BANK,
    payload:data
  };
};

export const deleteBankSuccessGenerator = (data) => {
  return {
    type: DELETE_BANK_SUCCESS,
    payload:data
  };
};

export const addBankBranchGenerator = (data) => {
  return {
    type: ADD_BANK_BRANCH,
    payload:data
  };
};
export const addBankBranchSuccessGenerator = (data) => {
  return {
    type: ADD_BANK_BRANCH_SUCCESS,
    payload:data
  };
};
export const editBankBranchGenerator = (data) => {
  return {
    type: EDIT_BANK_BRANCH,
    payload:data
  };
};
export const editBankBranchSuccessGenerator = (data) => {
  return {
    type: EDIT_BANK_BRANCH_SUCCESS,
    payload:data
  };
};

export const fetchAccountSettingsGenerator = (data=[]) => {
  return {
    type: FETCH_ACCOUNT_SETTINGS,
    payload:data
  };
};
export const fetchAccountSettingsSuccessGenerator = (data) => {
  return {
    type: FETCH_ACCOUNT_SETTINGS_SUCCESS,
    payload:data
  };
};
export const updateAccountSettingsSuccessGenerator = (data) => {
  return {
    type: UPDATE_ACCOUNT_SETTINGS,
    payload:data
  };
};
export const fetchAdminSystemSettingsSuccessGenerator = (data) => {
  return {
    type: FETCH_ADMIN_SYSTEM_SETTINGS_SUCCESS,
    payload:data
  };
};
export const fetchAdminSystemSettingsGenerator = (data) => {
  return {
    type: FETCH_ADMIN_SYSTEM_SETTINGS,
    payload:data
  };
};
export const updateAdminSystemSettingsGenerator = (data) => {
  return {
    type: UPDATE_ADMIN_SYSTEM_SETTINGS,
    payload:data
  };
};

