import { FETCH_ATTENDANCE_MAPPING_SUCCESS } from "../../actions/attendance/AttendanceMappingActions";

const INIT_STATE={
  attendanceMappingByID:{}
};

export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_ATTENDANCE_MAPPING_SUCCESS:
      // let mapping = {...state.attendanceMappingByID};
      // mapping=action.payload;
      return{
        ...state,
        attendanceMappingByID: action.payload
      };
    default:
      return state;
  }
}