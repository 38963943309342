import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import { push } from  "connected-react-router";
import axios from 'axios';
import {
  closePasswordChangeModal,
  FETCH_AUTH_USER_ROLE,
  fetchAuthUserRoleGenerator,
  fetchAuthUserRoleSuccessGenerator,
  FORGOT_PASSWORD,
  forgotPasswordSuccessGenerator, LOG_IN_AS_CLIENT, openPasswordChangeModal, CONFIRM_TOKEN, RESEND_VERIFY_CODE,
  SET_DEFAULT_APP,
  setDefaultAppSuccessGenerator, USE_REST_CODE,
  userSignInSuccessGenerator,
  userSignOutSuccessGenerator,
  userSignUpSuccessGenerator, VERIFY_EMAIL_ON_REGISTER
} from '../actions/AuthActions';
import {
  AUTH_DEFAULT_APP,
  AUTH_HEAD_OFFICE_ID_KEY,
  AUTH_ROLE_KEY,
  AUTH_SWITCHED_APP,
  AUTH_SWITCHED_COMPANY_ID_KEY,
  AUTH_USER_KEY,
  BANKS_KEY,
  COUNTRIES_KEY,
  CURRENCIES_KEY,
  NHIF_RATES_KEY,
  NSSF_RATES_KEY,
  PAYE_RATES_KEY,
  SECTORS_KEY,
  SELECTED_EMPLOYEE_ID_FOR_PORTAL,
  SUBSCRIPTION_KEY,
  SYSTEM_ADMIN,
  USER_EMAIL
} from "../../constants/SessionKeys";
import {
  fetchAccountSettingsGenerator, fetchAdminSystemSettingsGenerator,
  hideAppLoaderGenerator, showAppLoaderGenerator,
  showMessageGenerator
} from "../actions/GeneralActions";
import {Helpers as userData, Helpers} from '../../util/Helpers';
import {
  API_ENDPOINT,
  APP_START, SIGNIN_USER,
  SIGNIN_USER_SUCCESS, SIGNOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS
} from "../../constants/ActionTypes";
import { fetchCompaniesGenerator, SWITCH_COMPANY_SUCCESS } from "../actions/common/CompanyActions";
import { fetchSubscriptionGenerator } from "../actions/common/SubscriptionActions";
import {USE_OTP} from "../actions/common/UserActions";
const resendVerifyEmailCodeRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/user/verify/resend',requestData)
    .then(response=>response)
    .catch(error=>error.response);
function* resendVerifyEmailCode({type,payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload};
    const response = yield call(resendVerifyEmailCodeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(push('/verify-email',{data:response.data.data}))
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
const verifyEmailRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/user/verify-email',requestData)
    .then(response=>response)
    .catch(error=>error.response);

function* verifyEmail({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload};
    const response = yield call(verifyEmailRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      let user = response.data.data;
      if (response.data.success) {
        let authUser = response.data.data;
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0,is_system_admin:authUser.is_system_admin}//to save on localStorage resource usage
        };
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        Helpers.saveItem(USER_EMAIL, authUser.email);
        yield put(userSignUpSuccessGenerator(authUser));//subscription reducer will act on firing this action
        yield put(push('/onboarding'))
      } else {
        yield put(push('/verify-email',{data:user}))
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

const createUserWithEmailPasswordRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/user/register',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const logInAsClientRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/user/authenticate',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const signInUserWithEmailPasswordRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/user/login',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchUserRoleRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/user/role',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const setDefaultAppRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/set/default/app',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const signOutRequest = async () =>
  await  new Promise(resolve => {
      resolve(true);// A simple promise returning immediately with a boolean result
  });
const forgetPasswordRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/send/reset/email',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const useResetCodeRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/recover/account/email',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const useOtpRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/user/useotp',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const confirmTokenRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/user/token/authentication',requestData)
        .then(response=>response)
        .catch(error=>error.response);

function* confirmToken({type,payload}) {
    yield put(showAppLoaderGenerator(type));
  try {
    Helpers.clearAuthData();
    let requestParameters = {...payload};
    const response = yield call(confirmTokenRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        let authUser = response.data.data;
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0,is_system_admin:authUser.is_system_admin}//to save on localStorage resource usage
        };
        //const subscriptionStatus = authUser.subscription.subscription
        // save app state with user date in local storage
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(SYSTEM_ADMIN,authUser.is_system_admin);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_DEFAULT_APP, authUser.default_app);
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        yield put(userSignInSuccessGenerator(authUser));
        if(payload.hasOwnProperty('target') && payload.target){
          let redirectTo = '';
          switch (payload.target) {
            case  'time_and_attendance':
              redirectTo = '/attendance/dashboard';
              break;
            case 'performance_appraisal':
              redirectTo = '/appraisals/dashboard';
              break;
            default:
              redirectTo = '/home';
              break;
          }
          yield put(push(redirectTo));
        }else{
          yield put(push('/home'));
        }
      } else {
        yield put(push('/signin'));
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
    yield put(hideAppLoaderGenerator(type));
}

function* useOtp({type,payload}) {
    yield put(showAppLoaderGenerator(type));
  try {
    Helpers.clearAuthData();
    let requestParameters = {...payload};
    const response = yield call(useOtpRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        let authUser = response.data.data;
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0,is_system_admin:authUser.is_system_admin}//to save on localStorage resource usage
        };
        // save app state with user date in local storage
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(SYSTEM_ADMIN,authUser.is_system_admin);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_DEFAULT_APP, authUser.default_app);
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        yield put(userSignInSuccessGenerator(authUser));//subscription reducer will act on firing this action
        yield put(push('/dashboard'));
        //
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
        yield put(push('/signin'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
    yield put(hideAppLoaderGenerator(type));
}

function* UseResetCode({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    Helpers.clearAuthData();
    let requestParameters = {...payload};
    const response = yield call(useResetCodeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        let authUser = response.data.data;
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0,is_system_admin:authUser.is_system_admin}//to save on localStorage resource usage
        };
        // save app state with user date in local storage
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(SYSTEM_ADMIN,authUser.is_system_admin);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_DEFAULT_APP, authUser.default_app);
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        yield put(userSignInSuccessGenerator(authUser));//subscription reducer will act on firing this action
        yield put(push('/common/profile'));
        //
      } else {
        yield put(push('/forgot-password'));
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

function* forgetPassword({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    Helpers.clearAuthData();
    let requestParameters = {...payload};
    const response = yield call(forgetPasswordRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(forgotPasswordSuccessGenerator(response.data.data));
        yield put(push('/recover/code',{user_data:response.data.data}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

function* createUserWithEmailPassword({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    Helpers.clearAuthData();
    const response = yield call(createUserWithEmailPasswordRequest, payload);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let authUser = response.data.data;
        if (authUser.is_email_verified === 0){
          yield put(push("/verify-email",{data:authUser}));
          yield put(showMessageGenerator(response.data.message,'success'));
        }
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0}//to save on localStorage resource usage
        };
        // save app state with user date in local storage
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        yield put(userSignUpSuccessGenerator(authUser));//subscription reducer will act on firing this action

        let userProperties = {
          user_name: authUser.name,
          email: authUser.email,
          mobile_number: authUser.address.mobile_no
        };
        amplitude.getInstance().setUserProperties(userProperties);
        //amplitude.getInstance().logEvent('new user');
        amplitude.getInstance().logEvent('sign up new user');

      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* signInUserWithEmailPassword({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    Helpers.clearAuthData();
    const response = yield call(signInUserWithEmailPasswordRequest, payload);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let authUser = response.data.data;
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0,is_system_admin:authUser.is_system_admin}//to save on localStorage resource usage
        };
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(SYSTEM_ADMIN,authUser.is_system_admin);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_DEFAULT_APP, authUser.default_app);
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        Helpers.saveItem(USER_EMAIL, authUser.email);
        let auth_emp_id = Number(authUser.role.hasOwnProperty('employee_id')?authUser.role.employee_id:0);
        Helpers.saveItem(SELECTED_EMPLOYEE_ID_FOR_PORTAL, {id: auth_emp_id, for:'auth_employee'});
        yield put(userSignInSuccessGenerator(authUser));//subscription reducer will act on firing this action
        if ('opt' in authUser){
          yield put(push('/verify-account',{data:authUser}));
          yield put(showMessageGenerator(response.data.message,'success'));
        }else if(authUser.is_system_admin){
          yield put(push('/admin/companies'));
        }else if (authUser.first_time_login){
            yield put(openPasswordChangeModal());
        }else{
          yield put(closePasswordChangeModal());
        }
      } else {
        let pathname = window.location.pathname;
        if(pathname !== '/signin' && pathname !== '/signup'){
          yield put(userSignOutSuccessGenerator());
        }
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      let pathname = window.location.pathname;
      if(pathname !== '/signin' && pathname !== '/signup'){
        yield put(userSignOutSuccessGenerator());
      }
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }

  yield put(hideAppLoaderGenerator(type));

}
function* logInAsClient({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters  = {...payload,token:Helpers.token()};
    let adminEmail = Helpers.authUserEmail();
    const response = yield call(logInAsClientRequest, requestParameters);
    const openNewUi = (payload.hasOwnProperty('open_new_ui') && payload.open_new_ui);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        // save app state with user date in local storage
        Helpers.clearAuthData();
        let authUser = response.data.data;
        let authState = {
          user: {...authUser, role:0, subscription:0, default_app:0,is_system_admin:authUser.is_system_admin}//to save on localStorage resource usage
        };
        Helpers.saveItem(AUTH_USER_KEY, authState);
        Helpers.saveItem(SYSTEM_ADMIN,authUser.is_system_admin);
        Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, authUser.company_id);
        Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, authUser.head_office_id);
        Helpers.saveItem(AUTH_DEFAULT_APP, authUser.default_app);
        Helpers.saveItem(AUTH_SWITCHED_APP, "payouts");
        Helpers.saveItem(AUTH_ROLE_KEY, authUser.role);
        Helpers.saveItem(SUBSCRIPTION_KEY,authUser.subscription.subscription);
        authUser.productAdminSignedInAsClient = true;
        authUser.productAdminEmail = adminEmail;
        yield put(userSignInSuccessGenerator(authUser));//subscription reducer will act on firing this action
        if(openNewUi && (response.data.data.hasOwnProperty('has_beta_access') && response.data.data.has_beta_access)){////uncomment for auto redirect to beta system
          //let encryptedToken = Helpers.encrypt(Helpers.token());//javascript encrypted token not working yet
          //console.log({ENCRYPTED: encryptedToken, DECRYPTED: Helpers.decrypt(encryptedToken)});
          //window.open('https://dashboard.myworkpay.com/login?utm_source=legacy&utm_content='+Helpers.token()+'&company_id='+authUser.company_id, '_self');
        }
        yield put(push('/payouts/dashboard'));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser) {
      let pathname = window.location.pathname;
      if(pathname !== '/signin' && pathname !== '/signup'){
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator({from:'user_click'}));
      }
    } else {
      yield put(showMessageGenerator(signOutUser.message,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }

}

function* fetchUserRole({payload}) {
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchUserRoleRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        Helpers.saveItem(AUTH_ROLE_KEY, role);
        if(role.hasOwnProperty('company_id')){
          Helpers.saveItem(AUTH_SWITCHED_COMPANY_ID_KEY, role.company_id);
        }
        if(role.hasOwnProperty('head_office_id')){
          Helpers.saveItem(AUTH_HEAD_OFFICE_ID_KEY, role.head_office_id);
        }
        yield put(fetchAuthUserRoleSuccessGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      if(response?.data?.message == "Resource not available") {
        Helpers.saveItem(AUTH_ROLE_KEY, null); //Invalidate role
      }
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }

}

function* setDefaultApp({payload}) {
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(setDefaultAppRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let default_app = response.data.data;
        Helpers.saveItem(AUTH_DEFAULT_APP, default_app);
        yield put(setDefaultAppSuccessGenerator(default_app));
        //yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }

}

function* triggerAppDataFetching() {
  if(Helpers.cachedMinutes() > 60){//1 hour
    Helpers.updateCacheStamp();
    Helpers.removeItem(CURRENCIES_KEY);
    Helpers.removeItem(COUNTRIES_KEY);
    Helpers.removeItem(BANKS_KEY);
    Helpers.removeItem(SECTORS_KEY);
    Helpers.removeItem(NHIF_RATES_KEY);
    Helpers.removeItem(NSSF_RATES_KEY);
    Helpers.removeItem(PAYE_RATES_KEY);
  }
  if(Helpers.isLoggedIn()){
    yield put(fetchCompaniesGenerator());
    yield put(fetchAuthUserRoleGenerator());
    yield put(fetchSubscriptionGenerator());
    yield put(fetchAccountSettingsGenerator());
    if (Helpers.isSystemAdmin()){
      yield put(fetchAdminSystemSettingsGenerator());
    }
  }
}

export function* createUserAccount() {
  yield takeLatest(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword);
}export function* logInAsUserDefault() {
  yield takeLatest(LOG_IN_AS_CLIENT, logInAsClient);
}

export function* signOutUser() {
  yield takeLatest(SIGNOUT_USER, signOut);
}

export function* fetchUserRoleDefault() {
  yield takeLatest(FETCH_AUTH_USER_ROLE, fetchUserRole);
}

export function* setDefaultAppDefault() {
  yield takeLatest(SET_DEFAULT_APP, setDefaultApp);
}
export function* forgotPasswordDefault(){
  yield takeLatest(FORGOT_PASSWORD,forgetPassword);
}
export function* useOtpDefault(){
  yield takeLatest(USE_OTP,useOtp);
}
export function* useResetCodeDefault(){
  yield takeLatest(USE_REST_CODE,UseResetCode);
}
export function* verifyEmailDefault() {
  yield takeLatest(VERIFY_EMAIL_ON_REGISTER,verifyEmail);
}
export function* triggerAppDataFetchingDefault() {
  yield takeLatest(SIGNIN_USER_SUCCESS, triggerAppDataFetching);
  yield takeLatest(SIGNUP_USER_SUCCESS, triggerAppDataFetching);
  yield takeLatest(APP_START, triggerAppDataFetching);
  yield takeLatest(SWITCH_COMPANY_SUCCESS, triggerAppDataFetching);
}
export function* resendVerifyEmailCodeDefault() {
yield takeLatest(RESEND_VERIFY_CODE,resendVerifyEmailCode)
}
export function* confirmTokenDefault() {
yield takeLatest(CONFIRM_TOKEN,confirmToken)
}
export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(useOtpDefault),
    fork(createUserAccount),
    fork(signOutUser),
    fork(triggerAppDataFetchingDefault),
    fork(fetchUserRoleDefault),
    fork(setDefaultAppDefault),
    fork(forgotPasswordDefault),
    fork(useResetCodeDefault),
    fork(logInAsUserDefault),
    fork(verifyEmailDefault),
    fork(resendVerifyEmailCodeDefault),
    fork(confirmTokenDefault),
  ]);
}
