import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import {Button} from '@material-ui/core';


class Appraisal extends Component {
    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;// get current path

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open');
                    }
                }
                this.classList.toggle('open');
            };
        }

        const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        const {history} = nextProps;
        const pathname = `${history.location.pathname}`;// get current path

        const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
          <ul className="nav-menu">
              <li className="nav-header">
                  <IntlMessages id="sidebar.Appraisals" />
              </li>
              <li className="menu no-arrow">
                  <NavLink to="/appraisals/dashboard">
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.dashboard" />
                          {' '}
                      </span>
                  </NavLink>
              </li>
              <li className="menu no-arrow">
                  <NavLink to="/hrm/dashboard">
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.hrmDashboard" />
                          {' '}
                      </span>
                  </NavLink>
              </li>
              <li className="menu no-arrow">
                  <NavLink to="/appraisals/department-kpi">
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.DepartmentKPI" />
                          {' '}
                      </span>
                  </NavLink>
              </li>
              <li className="menu no-arrow">
                  <NavLink to="/appraisals/employee-kpi">
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.EmployeeKPI" />
                          {' '}
                      </span>
                  </NavLink>
              </li>
              <li className="menu no-arrow">
                  <NavLink to="/appraisals/appraisal">
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.Appraisals" />
                          {' '}
                      </span>
                  </NavLink>
              </li>
              <li className="menu collapse-box">
                  <Button>
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.reports" />
                      </span>
                  </Button>
                  <ul className="sub-menu">
                      <li>
                          <NavLink className="prepend-icon" to="/appraisals/pip">
                              <span className="nav-text"><IntlMessages id="sidebar.pip" /></span>
                          </NavLink>
                      </li>
                  </ul>
              </li>
              <li className="menu collapse-box">
                  <Button>
                      <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.settings" />
                      </span>
                  </Button>
                  <ul className="sub-menu">
                      <li>
                          <NavLink className="prepend-icon" to="/appraisals/companies">
                              <span className="nav-text"><IntlMessages id="sidebar.settings.companies" /></span>
                          </NavLink>
                      </li>
                      <li>
                          <NavLink className="prepend-icon" to="/appraisals/users">
                              <span className="nav-text"><IntlMessages id="sidebar.settings.users" /></span>
                          </NavLink>
                      </li>
                      <li>
                          <NavLink className="prepend-icon" to="/appraisals/key-result-areas">
                              <span className="nav-text"><IntlMessages id="sidebar.keyResultAreas" /></span>
                          </NavLink>
                      </li>
                      <li>
                          <NavLink className="prepend-icon" to="/appraisals/performance-rating">
                              <span className="nav-text"><IntlMessages id="sidebar.performanceRating" /></span>
                          </NavLink>
                      </li>
                      <li>
                          <NavLink className="prepend-icon" to="/appraisals/review-period">
                              <span className="nav-text"><IntlMessages id="sidebar.reviewPeriod" /></span>
                          </NavLink>
                      </li>
                  </ul>
              </li>
          </ul>
        );
    }
}

export default withRouter(Appraisal);
