import React from 'react';
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PlanCard from "./PlanCard";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import withRouter from "react-router/withRouter";
import { connect } from "react-redux";
import { fetchSubscriptionPlansGenerator, showMessageGenerator } from "../../appRedux/actions/GeneralActions";
import {
  renewSubscriptionGenerator,
  sendNotificationOnRequestUpgradeOfSubscriptionGenerator
} from "../../appRedux/actions/common/SubscriptionActions";
import { Helpers } from "../../util/Helpers";
import { AUTH_HEAD_OFFICE_ID_KEY, SUBSCRIPTION_KEY } from "../../constants/SessionKeys";

class SubscriptionPlansDialog extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedPlan: this.props.plan_id || '',
      selectedPlanType:this.props.subscribed_plan || 'monthly',
      amount:this.props.amount ||'',
      balance:this.props.balance ||'',
      action:this.props.action || '',
      plan_name:this.props.plan_name_ || ""
    };
  }
  componentDidMount() {
    this.props.fetchSubscriptionPlansGenerator();
  }

  handleSelectPlan = (_this) =>{
    const { value } = _this.target;
    let employees_active =this.props.active_company.all_active_employees_count
    let balance = this.props.active_company.balance;
    let selected_plan = this.props.subscriptionById[value]
    let plan_name = selected_plan.plan_name;
    let amount;
    if (this.state.selectedPlanType === "monthly") {
      let amount_ = selected_plan.amount_per_user * employees_active;
      if (amount_ < selected_plan.minimum_amount) {
        amount = selected_plan.minimum_amount;
      } else {
        amount = amount_;
      }

    } else {
      let amount_ = selected_plan.amount_per_user * employees_active * 12;
      let total_amount = amount_ - selected_plan.discount_given;
      if (total_amount < selected_plan.yearly_charge) {
        amount = selected_plan.yearly_charge;
      }else{
        amount=total_amount
      }
    }
    this.setState({
      selectedPlan: value,
      amount,
      balance,
      plan_name
    });
  }

  handleSelectPlanType = (_this) =>{
    const { value } = _this.target;
    let employees_active =this.props.active_company.all_active_employees_count
    let balance = this.props.active_company.balance;
    let selected_plan = this.props.subscriptionById[this.state.selectedPlan]
    let amount;
    if (value === "monthly") {
      let amount_ = selected_plan.amount_per_user * employees_active;
      if (amount_ < selected_plan.minimum_amount) {
        amount = selected_plan.minimum_amount;
      } else {
        amount = amount_;
      }

    } else {
      let amount_ = selected_plan.amount_per_user * employees_active * 12;
      let total_amount = amount_ - selected_plan.discount_given;
      if (total_amount < selected_plan.yearly_charge) {
        amount = selected_plan.yearly_charge;
      }else{
        amount=total_amount
      }
    }
    this.setState({
      selectedPlanType: value,
      amount,
      balance
    });
  }

  handleSubmit = () =>{
    let data = {...this.state}
    const {isTrail,action,days_remaining,balance} =this.props;
    if (Helpers.isNull(data.selectedPlan) || Helpers.isNull(data.selectedPlanType)){
      this.props.showMessageGenerator("Plan and Plan Type Are Required Fields",'error');
      return;
    }
    data.plan_id = data.selectedPlan;
    data.plan_type =data.selectedPlanType;

    if (action == "upgrade" && isTrail === 0 && days_remaining > 5){
      this.props.sendNotificationOnRequestUpgradeOfSubscriptionGenerator(data);
    }else {
      //console.log(data);
      //console.log(balance);
      if (Number(balance) >= Number(this.state.amount)){
        this.props.renewSubscriptionGenerator(data)
      }else{
        data.request_from='renew'
        data.amount =data.amount-balance

        this.props.history.push({
          pathname: '/common/subscription-payment',
          state:data
        });
      }
    }
    this.props.handleClose();
  }

  render(){
    const {handleClose,modalOpen,plan_id,days_remaining,isTrail,action} = this.props;
    let plans= Object.values(this.props.subscriptionById);
    let plans_ =[];

    if (days_remaining <= 5 && isTrail === 0 && action != 'upgrade'){
      plans = plans.filter(plan=>plan.id == plan_id)
    }else if(days_remaining > 5 && isTrail === 0){
      plans = plans.filter(plan=>plan.id != plan_id)
    }else if (days_remaining <= 5 && isTrail === 0 && action =='upgrade'){
      plans = plans.filter(plan=>plan.id != plan_id)
    }

    plans.forEach((item)=>{
      if (item.plan_name !== "Go Enterprise"){
        plans_.push(item)
      }
    })

    plans=plans_;
    const cont_length = plans.length >= 3 ? '4' : '6';

    let this_ =this;

    return (<Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalOpen}
      fullWidth
      maxWidth={ plans.length <= 2 ? "sm" :'md' }
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>

        { action=="upgrade" ? "Upgrade Your Plan" : (action=="renew") ? "Renew Your Plan" : "Pick a Plan" }
      </DialogTitle>
      <DialogContent>
        <div className="row">
          {plans && plans.map(function(item,index) {
            return (<div className={"col-xl-"+cont_length+" col-lg-"+cont_length+" col-md-"+cont_length} key={item.title}>
              <PlanCard
                data={{
                  title: item.plan_name,
                  id:item.id
                }}
                handleSelectPlan={this_.handleSelectPlan}
                isSelected={item.id==this_.state.selectedPlan}
                checkedPlan={this_.state.selectedPlan}
                selectedPlanType={this_.state.selectedPlanType}
                handleSelectPlanType={this_.handleSelectPlanType}
                key={item.id}
              />
            </div>)
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={ this_.handleSubmit}
        >
          Submit
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>);
  }
}
const mapStateToProps =({general,company})=>{
  const {subscriptionById}=general;
  const {companiesByID}=company;
  const active_company = companiesByID[Helpers.getItem(AUTH_HEAD_OFFICE_ID_KEY)];
  let plans_str =  Helpers.getItem(SUBSCRIPTION_KEY);
  try {
    plans_str = JSON.parse(plans_str);
  } catch (e) {
    plans_str = Helpers.getItem(SUBSCRIPTION_KEY);
  }
  plans_str = plans_str?plans_str:{};
  const plan_id = plans_str?.plan_id;
  const subscribed_plan = plans_str?.plan_type;
  const plan_name_ = plans_str.plan?.plan_name;
  const days_remaining = plans_str?.days_remaining;
  const isTrail = plans_str?.is_trial;
  let active_employees;
  let balance;
  if (active_company){
    balance = active_company.balance;
    active_employees = active_company.all_active_employees_count;
  }else {
    balance =1;
    active_employees =1
  }

  let amount;
  if (plans_str?.plan?.plan_type === "MONTHLY") {
    let amount_ = plans_str.plan.amount_per_user * active_employees;
    if (amount_ < plans_str.plan.minimum_amount) {
      amount = plans_str.plan.minimum_amount;
    } else {
      amount = amount_;
    }

  } else {
    let amount_ = plans_str?.plan?.amount_per_user * active_employees * 12;
    let total_amount = amount_ - plans_str?.plan?.discount_given;
    if (total_amount < plans_str?.plan?.yearly_charge) {
      amount = plans_str.plan.yearly_charge;
    } else {
      amount = total_amount;
    }
  }
  return {subscriptionById,plan_id,subscribed_plan,plan_name_,days_remaining,active_company,balance,amount,isTrail}
}
export default withRouter(connect(mapStateToProps,{fetchSubscriptionPlansGenerator,renewSubscriptionGenerator,sendNotificationOnRequestUpgradeOfSubscriptionGenerator,showMessageGenerator})(SubscriptionPlansDialog));
