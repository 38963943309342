export const FETCH_WITHDRAWALS = "FETCH_WITHDRAWALS";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const SHOW_WITHDRAWAL_CREATE_FORM = 'SHOW_WITHDRAWAL_CREATE_FORM';
export const HIDE_WITHDRAWAL_CREATE_FORM = 'HIDE_WITHDRAWAL_CREATE_FORM';
export const ADD_WITHDRAWAL = 'ADD_WITHDRAWAL';
export const ADD_WITHDRAWAL_SUCCESS = 'ADD_WITHDRAWAL_SUCCESS';
export const SHOW_WITHDRAWAL_EDIT_FORM = 'SHOW_WITHDRAWAL_EDIT_FORM';
export const HIDE_WITHDRAWAL_EDIT_FORM = 'HIDE_WITHDRAWAL_EDIT_FORM';
export const EDIT_WITHDRAWAL = 'EDIT_WITHDRAWAL';
export const EDIT_WITHDRAWAL_SUCCESS = 'EDIT_WITHDRAWAL_SUCCESS';
export const SHOW_WITHDRAWAL_DELETE_FORM = 'SHOW_WITHDRAWAL_DELETE_FORM';
export const HIDE_WITHDRAWAL_DELETE_FORM = 'HIDE_WITHDRAWAL_DELETE_FORM';
export const DELETE_WITHDRAWAL = 'DELETE_WITHDRAWAL';
export const DELETE_WITHDRAWAL_SUCCESS = 'DELETE_WITHDRAWAL_SUCCESS';

export const fetchWithdrawalsGenerator = (data=[])=>{
  return {
    type:FETCH_WITHDRAWALS,
    payload:data
  };
};

export const fetchWithdrawalsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_WITHDRAWALS_SUCCESS,
    payload:dataById
  }
};

export const showWithdrawalCreateFormGenerator = ()=>{
  return {
    type:SHOW_WITHDRAWAL_CREATE_FORM
  };
};
export const hideWithdrawalCreateFormGenerator = ()=>{
  return {
    type:HIDE_WITHDRAWAL_CREATE_FORM
  };
};
export const addWithdrawalGenerator = (data)=>{
  return {
    type: ADD_WITHDRAWAL,
    payload: data
  }
};

export const addWithdrawalSuccessGenerator = (data)=>{
  return {
    type: ADD_WITHDRAWAL_SUCCESS,
    payload: data
  }
};
export const showWithdrawalEditFormGenerator = (data)=>{
  return {
    type:SHOW_WITHDRAWAL_EDIT_FORM,
    payload:data
  };
};
export const hideWithdrawalEditFormGenerator = ()=>{
  return {
    type:HIDE_WITHDRAWAL_EDIT_FORM
  };
};
export const editWithdrawalGenerator = (data)=>{
  return {
    type: EDIT_WITHDRAWAL,
    payload: data
  }
};
export const editWithdrawalSuccessGenerator = (data)=>{
  return {
    type: EDIT_WITHDRAWAL_SUCCESS,
    payload: data
  }
};
export const showWithdrawalDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_WITHDRAWAL_DELETE_FORM,
    payload:data
  };
};
export const hideWithdrawalDeleteFormGenerator = ()=>{
  return {
    type:HIDE_WITHDRAWAL_DELETE_FORM
  };
};
export const deleteWithdrawalGenerator = (data)=>{
  return {
    type: DELETE_WITHDRAWAL,
    payload: data
  }
};
export const deleteWithdrawalSuccessGenerator = (data)=>{
  return {
    type: DELETE_WITHDRAWAL_SUCCESS,
    payload: data
  }
};
