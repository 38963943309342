import {Helpers} from "../../../util/Helpers";
import axios from "axios/index";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_MORTGAGE_RELIEF,
  addMortgageReliefSuccessGenerator, BULK_MORTGAGE_RELIEF, bulkMortgageReliefSuccessGenerator,
  DELETE_MORTGAGE_RELIEF,
  deleteMortgageReliefSuccessGenerator,
  EDIT_MORTGAGE_RELIEF,
  editMortgageReliefSuccessGenerator,
  FETCH_MORTGAGE_RELIEF, fetchMortgageReliefGenerator,
  fetchMortgageReliefSuccessGenerator
} from "../../actions/payroll/MortgageReliefActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

//create bulk loans
const uploadBulkMortgageInterestPaymentRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/mortgage-reliefs/upload/bulk',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

function* uploadBulkMortgageReliefs({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadBulkMortgageInterestPaymentRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let MortgageReliefs = response.data.data;
        yield put(bulkMortgageReliefSuccessGenerator(MortgageReliefs));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

const fetchMortgageReliefsRequests = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/mortgage-reliefs',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);

function* fetchMortgageReliefs({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchMortgageReliefsRequests, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let MortgageReliefs = response.data.data;
        yield put(fetchMortgageReliefSuccessGenerator(MortgageReliefs));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchMortgageReliefsDefault() {
  yield takeLatest(FETCH_MORTGAGE_RELIEF,fetchMortgageReliefs)
}

function* uploadBulkMortgageReliefsDefault() {
  yield takeLatest(BULK_MORTGAGE_RELIEF,uploadBulkMortgageReliefs)
}
////end fetch
///edit addition relief
const editMortgageReliefsRequest = async (requestParameters)=>
  await axios.put(API_ENDPOINT+'/hrm/mortgage-reliefs/'+requestParameters.id,requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* updateMortgageRelief({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editMortgageReliefsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let MortgageReliefs = response.data.data;
        yield put(fetchMortgageReliefGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateMortgageReliefDefault() {
  yield takeLatest(EDIT_MORTGAGE_RELIEF,updateMortgageRelief)
}
//// add new addition relief
const addMortgageReliefRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/mortgage-reliefs',requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* addMortgageRelief({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addMortgageReliefRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let MortgageReliefs = response.data.data;
        yield put(fetchMortgageReliefGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addMortgageReliefDefault() {
  yield takeLatest(ADD_MORTGAGE_RELIEF,addMortgageRelief)
}
const deleteMortgageReliefRequest = async (requestParameters)=>
  await axios.delete(API_ENDPOINT+'/hrm/mortgage-reliefs/'+requestParameters.id,{params:requestParameters})
    .then(response=>response)
    .catch(error => error.message);

function* deleteMortgageRelief({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteMortgageReliefRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let MortgageReliefs = response.data.data;
        yield put(fetchMortgageReliefGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteMortgageReliefDefault() {
  yield takeLatest(DELETE_MORTGAGE_RELIEF,deleteMortgageRelief)
}
////end delete relief
export default function* rootSagas(){
  yield all([
    fork(fetchMortgageReliefsDefault),
    fork(updateMortgageReliefDefault),
    fork(addMortgageReliefDefault),
    fork(deleteMortgageReliefDefault),
    fork(uploadBulkMortgageReliefsDefault)
  ]);
}
