export const FETCH_CUSTOMER_STATEMENT = 'FETCH_CUSTOMER_STATEMENT';
export const FETCH_CUSTOMER_STATEMENT_SUCCESS = 'FETCH_CUSTOMER_STATEMENT_SUCCESS';
export const SEND_CUSTOMER_STATEMENT_SUCCESS = 'SEND_CUSTOMER_STATEMENT_SUCCESS';
export const SEND_CUSTOMER_STATEMENT = 'SEND_CUSTOMER_STATEMENT';
export const DOWNLOAD_BULK_CUSTOMER_STATEMENT = 'DOWNLOAD_BULK_CUSTOMER_STATEMENT';
export const DOWNLOAD_BULK_CUSTOMER_STATEMENT_SUCCESS = 'DOWNLOAD_BULK_CUSTOMER_STATEMENT_SUCCESS';

export const fetchCustomerStatementGenerator = (data)=>{
    return {
        type:FETCH_CUSTOMER_STATEMENT,
        payload:data
    }
};
export const fetchCustomerStatementSuccessGenerator = (dataById)=>{
    return {
        type:FETCH_CUSTOMER_STATEMENT_SUCCESS,
        payload:dataById
    }
};

export const sendCustomerStatementGenerator = (dataById)=>{
    return {
        type:SEND_CUSTOMER_STATEMENT,
        payload:dataById
    }
};
export const sendCustomerStatementSuccessGenerator = (dataById)=>{
    return {
        type:SEND_CUSTOMER_STATEMENT_SUCCESS,
        payload:dataById
    }
};

export const downloadBulkCustomerStatementGenerator = (dataById)=>{
    return {
        type:DOWNLOAD_BULK_CUSTOMER_STATEMENT,
        payload:dataById
    }
};
export const downloadBulkCustomerStatementSuccessGenerator = (dataById)=>{
    return {
        type:DOWNLOAD_BULK_CUSTOMER_STATEMENT_SUCCESS,
        payload:dataById
    }
};