export const ADD_EMPLOYEE_RENT ="ADD_EMPLOYEE_RENT";
export const ADD_EMPLOYEE_RENT_SUCCESS ="ADD_EMPLOYEE_RENT_SUCCESS";

export const FETCH_EMPLOYEE_RENT ="FETCH_EMPLOYEE_RENT";
export const FETCH_EMPLOYEE_RENT_SUCCESS ="FETCH_EMPLOYEE_RENT_SUCCESS";

export const EDIT_EMPLOYEE_RENT ="EDIT_EMPLOYEE_RENT";
export const EDIT_EMPLOYEE_RENT_SUCCESS ="EDIT_EMPLOYEE_RENT_SUCCESS";

export const DELETE_EMPLOYEE_RENT ="DELETE_EMPLOYEE_RENT";
export const DELETE_EMPLOYEE_RENT_SUCCESS ="DELETE_EMPLOYEE_RENT_SUCCESS";
export const SHOW_EMPLOYEE_RENT_DELETE_FORM ="SHOW_EMPLOYEE_RENT_DELETE_FORM";
export const HIDE_EMPLOYEE_RENT_DELETE_FORM ="HIDE_EMPLOYEE_RENT_DELETE_FORM";

export const DELETE_EMPLOYEE_RENT_MULTIPLE ="DELETE_EMPLOYEE_RENT_MULTIPLE";

export const UPLOAD_EMPLOYEE_RENT_IN_BULK = "UPLOAD_EMPLOYEE_RENT_IN_BULK";

export const FETCH_EMPLOYEE_RENT_HISTORY = "FETCH_EMPLOYEE_RENT_HISTORY";
export const FETCH_EMPLOYEE_RENT_HISTORY_SUCCESS = "FETCH_EMPLOYEE_RENT_HISTORY_SUCCESS";


export const uploadEmployeeRentInBulkGenerator = (data)=>{
  return {
    type:UPLOAD_EMPLOYEE_RENT_IN_BULK,
    payload:data
  }
};

export const deleteEmployeeRentMultiple =(data)=>{
  return {
    type:DELETE_EMPLOYEE_RENT_MULTIPLE,
    payload:data
  }
};

export const fetchEmployeeRentGenerator = (data=[]) =>{
  return {
    type:FETCH_EMPLOYEE_RENT,
    payload:data
  }
};

export const fetchEmployeeRentSuccessGenerator = (data) =>{
  return {
    type:FETCH_EMPLOYEE_RENT_SUCCESS,
    payload:data
  }
};
export const addEmployeeRentGenerator =(data) =>{
  return {
    type:ADD_EMPLOYEE_RENT,
    payload:data
  }
};

export const addEmployeeRentSuccessGenerator = (data) => {
  return {
    type:ADD_EMPLOYEE_RENT_SUCCESS,
    payload:data
  }
};
export const editEmployeeRentGenerator = (data) =>{
  return {
    type:EDIT_EMPLOYEE_RENT,
    payload:data
  }
};
export const editEmployeeRentSuccessGenerator = (data) =>{
  return {
    type:EDIT_EMPLOYEE_RENT_SUCCESS,
    payload:data
  }
};
export const deleteEmployeeRentGenerator = (data) =>{
  return {
    type:DELETE_EMPLOYEE_RENT,
    payload:data
  }
};
export const deleteEmployeeRentSuccessGenerator = (data) =>{
  return {
    type:DELETE_EMPLOYEE_RENT_SUCCESS,
    payload:data
  }
};
export const fetchEmployeeRentHistoryGenerator = (data) =>{
  return {
    type:FETCH_EMPLOYEE_RENT_HISTORY,
    payload:data
  }
};
export const fetchEmployeeRentHistorySuccessGenerator = (data) =>{
  return {
    type:FETCH_EMPLOYEE_RENT_HISTORY_SUCCESS,
    payload:data
  }
};
