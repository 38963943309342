import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_EXPENSE_CATEGORY,
  DELETE_EXPENSE_CATEGORY,
  deleteExpenseCategorySuccessGenerator,
  EDIT_EXPENSE_CATEGORY,
  editExpenseCategorySuccessGenerator,
  FETCH_EXPENSE_CATEGORIES, FETCH_EXPENSE_SUB_CATEGORIES,
  fetchExpenseCategoriesSuccessGenerator, fetchExpenseSubCategoriesGenerator,
  fetchExpenseSubCategoriesSuccessGenerator
} from "../../actions/payroll/ExpenseCategoryActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addExpenseCategorySuccessGenerator
} from "../../actions/payroll/ExpenseCategoryActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchExpenseCategoriesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/petty-cash/expense/categories',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchExpenseSubCategoriesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/petty-cash/expense/sub-categories',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addExpenseCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/expense/category/add',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteExpenseCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/expense/category/delete',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editExpenseCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/expense/category/edit',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchExpenseCategories({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchExpenseCategoriesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchExpenseCategoriesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchExpenseSubCategories({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchExpenseSubCategoriesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchExpenseSubCategoriesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addExpenseCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addExpenseCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        if(!Helpers.isNull(requestParameters.type))  {
            yield put(fetchExpenseSubCategoriesGenerator())
        }else{
          yield put(addExpenseCategorySuccessGenerator(role));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteExpenseCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteExpenseCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        if(!Helpers.isNull(requestParameters.type)){
               yield put(fetchExpenseSubCategoriesGenerator());
        } else{
          yield put(deleteExpenseCategorySuccessGenerator(role));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editExpenseCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editExpenseCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        if(!Helpers.isNull(requestParameters.type)) {
          yield put(fetchExpenseSubCategoriesGenerator());
        }else{
          yield put(editExpenseCategorySuccessGenerator(role));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchExpenseCategoriesDefault() {
  yield takeLatest(FETCH_EXPENSE_CATEGORIES, fetchExpenseCategories)
}export function* fetchExpenseSubCategoriesDefault() {
  yield takeLatest(FETCH_EXPENSE_SUB_CATEGORIES,fetchExpenseSubCategories)
}
export function* addExpenseCategoryDefault() {
  yield takeLatest(ADD_EXPENSE_CATEGORY, addExpenseCategory);
}
export function* deleteExpenseCategoryDefault() {
  yield takeLatest(DELETE_EXPENSE_CATEGORY, deleteExpenseCategory);
}
export function* editExpenseCategoryDefault() {
  yield takeLatest(EDIT_EXPENSE_CATEGORY, editExpenseCategory);
}
export default function* rootSaga() {
  yield all([fork(fetchExpenseCategoriesDefault),
    fork(addExpenseCategoryDefault),
    fork(deleteExpenseCategoryDefault),
    fork(editExpenseCategoryDefault),
    fork(fetchExpenseSubCategoriesDefault),
  ]);
}
