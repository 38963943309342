export const FETCH_SCHEDULES_GROUPS = 'FETCH_SCHEDULES_GROUPS';
export const FETCH_SCHEDULES_GROUP_SUCCESS = 'FETCH_SCHEDULES_GROUP_SUCCESS';

export  const ADD_SCHEDULE_GROUP = 'ADD_SCHEDULE_GROUP';
export  const ADD_SCHEDULE_SUCCESS_GROUP = 'ADD_SCHEDULE_SUCCESS_GROUP';

export const EDIT_SCHEDULE_GROUP = 'EDIT_SCHEDULE_GROUP';
export const EDIT_SCHEDULE_SUCCESS_GROUP = 'EDIT_SCHEDULE_SUCCESS_GROUP';

export const FETCH_SHIFTS = 'FETCH_SHIFTS';
export const FETCH_SHIFTS_SUCCESS = 'FETCH_SHIFTS_SUCCESS';

export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';
export const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS';

export const DELETE_SCHEDULES_GROUP = 'DELETE_SCHEDULES_GROUP';
export const DELETE_SCHEDULES_SUCCESS_GROUP = 'DELETE_SCHEDULES_SUCCESS_GROUP';

export const FETCH_SCHEDULE_GROUP_SHIFTS = 'FETCH_SCHEDULE_GROUP_SHIFTS';
export const FETCH_SCHEDULE_GROUP_SHIFTS_SUCCESS = 'FETCH_SCHEDULE_GROUP_SHIFTS_SUCCESS';

export const FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS = 'FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS';
export const FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS_SUCCESS = 'FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS_SUCCESS';

export const ADD_SCHEDULE_GROUP_SHIFT = 'ADD_SCHEDULE_GROUP_SHIFT';
export const ADD_SCHEDULE_GROUP_SHIFT_SUCCESS = 'ADD_SCHEDULE_GROUP_SHIFT_SUCCESS';

export const EDIT_SCHEDULE_GROUP_SHIFT = 'EDIT_SCHEDULE_GROUP_SHIFT';
export const EDIT_SCHEDULE_GROUP_SHIFT_SUCCESS = 'EDIT_SCHEDULE_GROUP_SHIFT_SUCCESS';

export const DELETE_SCHEDULE_GROUP_SHIFT = 'DELETE_SCHEDULE_GROUP_SHIFT';
export const DELETE_SCHEDULE_GROUP_SHIFT_SUCCESS = 'DELETE_SCHEDULE_GROUP_SHIFT_SUCCESS';

export const ASSIGN_EMPLOYEES = 'ASSIGN_EMPLOYEES';
export const ASSIGN_EMPLOYEES_SUCCESS = 'ASSIGN_EMPLOYEES_SUCCESS';

export const DEASSIGN_EMPLOYEES = 'DEASSIGN_EMPLOYEES';
export const DEASSIGN_EMPLOYEES_SUCCESS = 'DEASSIGN_EMPLOYEES_SUCCESS';

export const FETCH_SCHEDULE_GROUP_EMPLOYEES = 'FETCH_SCHEDULE_GROUP_EMPLOYEES';
export const FETCH_SCHEDULE_GROUP_EMPLOYEES_SUCCESS = 'FETCH_SCHEDULE_GROUP_EMPLOYEES_SUCCESS';

export const FETCH_SHIFT_WORKING_HOURS = 'FETCH_SHIFT_WORKING_HOURS';
export const FETCH_SHIFT_WORKING_HOURS_SUCCESS = 'FETCH_SHIFT_WORKING_HOURS_SUCCESS';

export const ADD_SHIFT_WORKING_HOURS = 'ADD_SHIFT_WORKING_HOURS';
export const ADD_SHIFT_WORKING_HOURS_SUCCESS = 'ADD_WORKING_HOURS_SUCCESS';

export const DELETE_SHIFT_WORKING_HOURS = 'DELETE_SHIFT_WORKING_HOURS';
export const DELETE_SHIFT_WORKING_HOURS_SUCCESS = 'DELETE_WORKING_HOURS_SUCCESS';

export const BULK_DE_ASSIGN_EMPLOYEES ='BULK_DE_ASSIGN_EMPLOYEES';

export const bulkDeAssignEmployeesGenerator = payload =>{
  return {
    type:BULK_DE_ASSIGN_EMPLOYEES,
    payload
  }
}

export const fetchScheduleGroupEmployeesGenerator = (data)=>{
  return {
    type:FETCH_SCHEDULE_GROUP_EMPLOYEES,
    payload:data
  }
};
export const fetchScheduleGroupEmployeesSuccessGenarator = (data)=>{
  return {
    type:FETCH_SCHEDULE_GROUP_EMPLOYEES_SUCCESS,
    payload:data
  }
};

export const assignEmployeesScheduleGenerator = (data)=>{
  return {
    type:ASSIGN_EMPLOYEES,
    payload:data
  }
};
export const assignEmployeesScheduleSuccessGenerator = (data)=>{
  return {
    type:ASSIGN_EMPLOYEES_SUCCESS,
    payload:data
  }
};
export const deassignEmployeesScheduleGenerator = (data)=>{
  return {
    type:DEASSIGN_EMPLOYEES,
    payload:data
  }
};
export const deassignEmployeesScheduleSuccessGenerator = (data)=>{
  return {
    type:DEASSIGN_EMPLOYEES_SUCCESS,
    payload:data
  }
};

export const deleteScheduleGroupShiftGenerator = (data)=>{
  return {
    type:DELETE_SCHEDULE_GROUP_SHIFT,
    payload:data
  }
};
export const deleteScheduleGroupShiftSuccessGenerator = (data)=>{
  return {
    type:DELETE_SCHEDULE_GROUP_SHIFT_SUCCESS,
    payload:data
  }
};

export const editScheduleGroupShiftGenerator = (data)=>{
  return {
    type:EDIT_SCHEDULE_GROUP_SHIFT,
    payload:data
  }
};
export const editScheduleGroupShiftSuccessGenerator = (data)=>{
  return {
    type:EDIT_SCHEDULE_GROUP_SHIFT_SUCCESS,
    payload:data
  }
};

export const addScheduleGroupShiftGenerator =(data)=>{
  return {
    type:ADD_SCHEDULE_GROUP_SHIFT,
    payload:data
  }
};
export const addScheduleGroupShiftSuccessGenerator =(data)=>{
  return {
    type:ADD_SCHEDULE_GROUP_SHIFT_SUCCESS,
    payload:data
  }
};
export const fetchScheduleGroupShiftsGenerator =(data)=>{
  return {
    type:FETCH_SCHEDULE_GROUP_SHIFTS,
    payload:data
  }
};
export const fetchScheduleGroupShiftsSuccessGenerator =(data)=>{
  return {
    type:FETCH_SCHEDULE_GROUP_SHIFTS_SUCCESS,
    payload:data
  }
};
export const fetchGlobalScheduleGroupShiftsGenerator =(data)=>{
  return {
    type:FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS,
    payload:data
  }
};
export const fetchGlobalScheduleGroupShiftsSuccessGenerator =(data)=>{
  return {
    type:FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS_SUCCESS,
    payload:data
  }
};
export const fetchScheduleGenerator = (data)=>{
  return {
    type:FETCH_SCHEDULES_GROUPS,
    payload:data
  }
};
export const fetchScheduleSuccessGenerator = (data)=>{
  return {
    type:FETCH_SCHEDULES_GROUP_SUCCESS,
    payload:data
  }
};
export const addScheduleGenerator = (data)=>{
  return {
    type:ADD_SCHEDULE_GROUP,
    payload:data
  }
};
export const addScheduleSuccessGenerator = (data)=>{
  return {
    type:ADD_SCHEDULE_SUCCESS_GROUP,
    payload:data
  }
};
export const editScheduleGenerator = (data)=>{
  return {
    type:EDIT_SCHEDULE_GROUP,
    payload:data
  }
};
export const editScheduleSuccessGenerator = (data)=>{
  return {
    type:EDIT_SCHEDULE_SUCCESS_GROUP,
    payload:data
  }
};

export const fetchShiftGenerator = (data) =>{
   return {
     type:FETCH_SHIFTS,
     payload:data
   }
};
export const fetchShiftSuccessGenerator = (data) =>{
   return {
     type:FETCH_SHIFTS_SUCCESS,
     payload:data
   }
};

export const fetchSchedulesGenerator = (data)=>{
     return {
       type:FETCH_SCHEDULES,
       payload:data
     }
};
export const fetchSchedulesGroupGenerator = (data)=>{
     return {
       type:FETCH_SCHEDULES_SUCCESS,
       payload:data
     }
};
export const deleteScheduleGroupGenerator =(data)=>{
  return {
    type:DELETE_SCHEDULES_GROUP,
    payload:data
  }
};
export const deleteScheduleSuccessGroupGenerator =(data)=>{
  return {
    type:DELETE_SCHEDULES_SUCCESS_GROUP,
    payload:data
  }
};

export const fetchShiftWorkingHoursGenerator = (data)=>{
  return {
    type:FETCH_SHIFT_WORKING_HOURS,
    payload:data
  }
};
export const fetchShiftWorkingHoursSuccessGenerator = (data)=>{
  return {
    type:FETCH_SHIFT_WORKING_HOURS_SUCCESS,
    payload:data
  }
};

export const addShiftWorkingHoursGenerator = (data)=>{
  return {
    type:ADD_SHIFT_WORKING_HOURS,
    payload:data
  }
};
export const addShiftWorkingHoursSuccessGenerator = (data)=>{
  return {
    type:ADD_SHIFT_WORKING_HOURS_SUCCESS,
    payload:data
  }
};

export const deleteShiftWorkingHoursGenerator = (data)=>{
  return {
    type:DELETE_SHIFT_WORKING_HOURS,
    payload:data
  }
};
export const deleteShiftWorkingHoursSuccessGenerator = (data)=>{
  return {
    type:DELETE_SHIFT_WORKING_HOURS_SUCCESS,
    payload:data
  }
};
