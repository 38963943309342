import {API_ENDPOINT} from "../../../constants/ActionTypes";
import axios from 'axios';
import {Helpers} from "../../../util/Helpers";
import {call, fork, put, all, takeLatest} from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    CREATE_REVIEW_PERIODS,
    createReviewPeriodsSuccessGenerator, DELETE_REVIEW_PERIODS, deleteReviewPeriodsSuccessGenerator,
    EDIT_REVIEW_PERIODS,
    editReviewPeriodsSuccessGenerator,
    FETCH_REVIEW_PERIODS, fetchReviewPeriodsGenerator,
    fetchReviewPeriodsSuccessGenerator
} from "../../actions/appraisal/ReviewPeriodActions";

const fetchReviewPeriodRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/appraisal/review-period',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const editReviewPeriodRequest = async (requestData)=>
    await axios.put(API_ENDPOINT+'/appraisal/review-period/'+requestData.id,requestData)
        .then(response=>response)
        .catch(error=>error.response);

const createReviewPeriodRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/appraisal/review-period',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const deleteReviewPeriodRequest = async (requestData)=>
    await axios.delete(API_ENDPOINT+'/appraisal/review-period/'+requestData.id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);
function* deleteReviewPeriod({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response = yield call(deleteReviewPeriodRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(deleteReviewPeriodsSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* createReviewPeriod({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()}
        let response = yield call(createReviewPeriodRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
               // yield put(createReviewPeriodsSuccessGenerator(response.data.data));
                yield put(fetchReviewPeriodsGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editReviewPeriod({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()}
        let response = yield call(editReviewPeriodRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(editReviewPeriodsSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchReviewPeriod({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response = yield call(fetchReviewPeriodRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(fetchReviewPeriodsSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
   yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchReviewPeriodDefault() {
    yield takeLatest(FETCH_REVIEW_PERIODS,fetchReviewPeriod);
}
function* editReviewPeriodDefault() {
    yield takeLatest(EDIT_REVIEW_PERIODS,editReviewPeriod)
}
function* createReviewPeriodDefault() {
    yield takeLatest(CREATE_REVIEW_PERIODS,createReviewPeriod)
}
function* deleteReviewPeriodDefault() {
    yield takeLatest(DELETE_REVIEW_PERIODS,deleteReviewPeriod)
}
export default function* rootSaga() {
    yield all([
        fork(fetchReviewPeriodDefault),
        fork(editReviewPeriodDefault),
        fork(createReviewPeriodDefault),
        fork(deleteReviewPeriodDefault)
    ]);
}

