
export const FETCH_COMPANIES_WITH_APPRAISAL = 'FETCH_COMPANIES_WITH_APPRAISAL';
export const FETCH_COMPANIES_WITH_APPRAISAL_SUCCESS = 'FETCH_COMPANIES_WITH_APPRAISAL_SUCCESS';

export const DISABLE_APPRAISAL = 'DISABLE_APPRAISAL';

export const disableAppraisalGenerator =(data)=>{
    return {
        type:DISABLE_APPRAISAL,
        payload:data
    }
};

export const fetchCompaniesWithAppraisalGenerator =(data)=>{
    return {
        type:FETCH_COMPANIES_WITH_APPRAISAL,
        payload:data
    }
};
export const fetchCompaniesWithAppraisalSuccessGenerator =(data)=>{
    return {
        type:FETCH_COMPANIES_WITH_APPRAISAL_SUCCESS,
        payload:data
    }
};