import {
    FETCH_DETECTION_MODES_SUCCESS,
    HIDE_DETECTION_MODE_ENABLE_FORM,
    SHOW_DETECTION_MODE_ENABLE_FORM, ENABLE_DETECTION_MODE_SUCCESS,
    DISABLE_DETECTION_MODE_SUCCESS,
    HIDE_DETECTION_MODE_DISABLE_FORM,
    SHOW_DETECTION_MODE_DISABLE_FORM
} from "../../actions/attendance/DetectionModeActions";

const INIT_STATE = {
    detectionModesByID:{},
    companyDetectionModesByID:{},
    showDetectionModeCreateForm:false,
    showDetectionModeEnableForm:false,
    DetectionModeToEnable:false,
    showDetectionModeDisableForm:false,
    DetectionModeToDisable:{}
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
        case FETCH_DETECTION_MODES_SUCCESS:
            return {
                ...state,
                detectionModesByID: action.payload.detection_modes,
                companyDetectionModesByID: action.payload.company_detection_modes
            };
        case SHOW_DETECTION_MODE_ENABLE_FORM:
            return {
                ...state,
                showDetectionModeCreateForm: true
            };
        case HIDE_DETECTION_MODE_ENABLE_FORM:
            return {
                ...state,
                showDetectionModeCreateForm: false
            };
        case SHOW_DETECTION_MODE_DISABLE_FORM:
            return {
                ...state,
                showDetectionModeEnableForm: true,
                DetectionModeToEnable: action.payload
            };
        case HIDE_DETECTION_MODE_DISABLE_FORM:
            return {
                ...state,
                showDetectionModeEnableForm: false
            };
        case ENABLE_DETECTION_MODE_SUCCESS:
            let detectionModes ={...state.companyDetectionModesByID};
            detectionModes[action.payload.attendance_detection_mode_id]=action.payload;
            return {
                ...state,
                companyDetectionModesByID: detectionModes
            };
        case DISABLE_DETECTION_MODE_SUCCESS:
            let detectionMode = {...state.companyDetectionModesByID};
            delete detectionMode[action.payload.attendance_detection_mode_id];
            return {
                ...state,
                companyDetectionModesByID:detectionMode
            };
        default:
            return state;
    }
}
