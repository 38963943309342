export const FETCH_SALARY_ADVANCE_FEES = "FETCH_SALARY_ADVANCE_FEES";
export const FETCH_SALARY_ADVANCE_FEES_SUCCESS = "FETCH_SALARY_ADVANCE_FEES_SUCCESS";
export const ADD_SALARY_ADVANCE_FEE = "ADD_SALARY_ADVANCE_FEE";
export const ADD_SALARY_ADVANCE_FEE_SUCCESS = "ADD_SALARY_ADVANCE_FEE_SUCCESS";
export const EDIT_SALARY_ADVANCE_FEE = "EDIT_SALARY_ADVANCE_FEE";
export const EDIT_SALARY_ADVANCE_FEE_SUCCESS = "EDIT_SALARY_ADVANCE_FEE_SUCCESS";
export const DELETE_SALARY_ADVANCE_FEE = "DELETE_SALARY_ADVANCE_FEE";
export const DELETE_SALARY_ADVANCE_FEE_SUCCESS = "DELETE_SALARY_ADVANCE_FEE_SUCCESS";

export const FETCH_DEFAULT_SALARY_ADVANCE_FEES = "FETCH_DEFAULT_SALARY_ADVANCE_FEES";
export const FETCH_DEFAULT_SALARY_ADVANCE_FEES_SUCCESS = "FETCH_DEFAULT_SALARY_ADVANCE_FEES_SUCCESS";
export const ADD_DEFAULT_SALARY_ADVANCE_FEE = "ADD_DEFAULT_SALARY_ADVANCE_FEE";
export const ADD_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS = "ADD_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS";
export const EDIT_DEFAULT_SALARY_ADVANCE_FEE = "EDIT_DEFAULT_SALARY_ADVANCE_FEE";
export const EDIT_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS = "EDIT_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS";
export const DELETE_DEFAULT_SALARY_ADVANCE_FEE = "DELETE_DEFAULT_SALARY_ADVANCE_FEE";
export const DELETE_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS = "DELETE_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS";

export const fetchSalaryAdvanceFeesGenerator =(data=[])=>{
  return{
    type:FETCH_SALARY_ADVANCE_FEES,
    payload:data
  }
};
export const fetchSalaryAdvanceFeesSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_SALARY_ADVANCE_FEES_SUCCESS,
    payload:dataByID
  }
};
export const addSalaryAdvanceFeeGenerator =(data=[])=>{
  return{
    type:ADD_SALARY_ADVANCE_FEE,
    payload:data
  }
};
export const addSalaryAdvanceFeeSuccessGenerator =(data)=>{
  return{
    type:ADD_SALARY_ADVANCE_FEE_SUCCESS,
    payload:data
  }
};
export const editSalaryAdvanceFeeGenerator =(data=[])=>{
  return{
    type:EDIT_SALARY_ADVANCE_FEE,
    payload:data
  }
};
export const editSalaryAdvanceFeeSuccessGenerator =(data)=>{
  return{
    type:EDIT_SALARY_ADVANCE_FEE_SUCCESS,
    payload:data
  }
};
export const deleteSalaryAdvanceFeeGenerator =(data=[])=>{
  return{
    type:DELETE_SALARY_ADVANCE_FEE,
    payload:data
  }
};
export const deleteSalaryAdvanceFeeSuccessGenerator=(data)=>{
  return{
    type:DELETE_SALARY_ADVANCE_FEE_SUCCESS,
    payload:data
  }
};






export const fetchDefaultSalaryAdvanceFeesGenerator =(data=[])=>{
  return{
    type:FETCH_DEFAULT_SALARY_ADVANCE_FEES,
    payload:data
  }
};
export const fetchDefaultSalaryAdvanceFeesSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_DEFAULT_SALARY_ADVANCE_FEES_SUCCESS,
    payload:dataByID
  }
};
export const addDefaultSalaryAdvanceFeeGenerator =(data=[])=>{
  return{
    type:ADD_DEFAULT_SALARY_ADVANCE_FEE,
    payload:data
  }
};
export const addDefaultSalaryAdvanceFeeSuccessGenerator =(data)=>{
  return{
    type:ADD_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS,
    payload:data
  }
};
export const editDefaultSalaryAdvanceFeeGenerator =(data=[])=>{
  return{
    type:EDIT_DEFAULT_SALARY_ADVANCE_FEE,
    payload:data
  }
};
export const editDefaultSalaryAdvanceFeeSuccessGenerator =(data)=>{
  return{
    type:EDIT_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS,
    payload:data
  }
};
export const deleteDefaultSalaryAdvanceFeeGenerator =(data=[])=>{
  return{
    type:DELETE_DEFAULT_SALARY_ADVANCE_FEE,
    payload:data
  }
};
export const deleteDefaultSalaryAdvanceFeeSuccessGenerator =(data)=>{
  return{
    type:DELETE_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS,
    payload:data
  }
};

