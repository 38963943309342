import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from 'material-ui-pickers';
import { connect } from 'react-redux';
import {
  addCompanyGenerator,
  editCompanyGenerator,
  switchCompanyGenerator
} from '../../../appRedux/actions/common/CompanyActions';
import { Helpers } from '../../../util/Helpers';
import {
  fetchBanksGenerator,
  fetchCountriesGenerator,
  fetchCurrenciesGenerator, fetchSectorsGenerator
} from "../../../appRedux/actions/GeneralActions";
import { SELECTED_COMPANY_KEY } from "../../../constants/SessionKeys";
import moment from "moment";

const dateFormat = 'YYYY-MM-DD';
const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  halfWidth: {
    width: '45%',
    margin: 8
  },
  thirdWidth: {
    width: '33%',
    margin: 8
  },
  twoThirdWidth: {
    width: '66%',
    margin: 8
  },
  formControl: {
    width: '100%'
  }
});

class CreateCompanyForm extends React.Component {
  constructor(props) {
    super(props);
    const bankDetails = props.bank_details ? props.bank_details : {};
    const address = props.address ? props.address : {};
    const userAddress = Helpers.authUserAddress();
    const mobile_no = userAddress.mobile_no;
    const email = userAddress.email;
    const selected_company_id = Helpers.getItem(SELECTED_COMPANY_KEY, Helpers.authSwitchedCompanyID());
    const company = this.props.companiesByID[selected_company_id];
    this.state = {
      id: props.id,
      name: props.name,
      action: props.action,
      value: 0,
      employee_no_prefix: props.employee_no_prefix || '',
      pin_no: props.pin_no || '',
      nssf_no: props.nssf_no || '',
      nhif_code: props.nhif_code || '',
      sector_id: props.sector_id,
      date_started: props.date_started || moment().format(),
      //currency_id: Helpers.isNull(props.currency_id) ? 56 : props.currency_id,
      city: address.city || '',
      county: address.county || '',
      email: props.action === 'create' ? email : address.email,
      mobile_no: props.action === 'create' ? mobile_no : address.mobile_no,
      website: address.website || '',
      postal_address: address.postal_address || '',
      zip_code: address.zip_code || '',
      road: address.road || '',
      country_id: Helpers.isNull(props.country_id) ? 110 : props.country_id,
      bank_id: bankDetails.bank_id||'',
      branch_id: bankDetails.branch_id||'',
      account_name: bankDetails.account_name||props.name,
      account_no: bankDetails.account_no || '',
      head_office_id:company?.id
    };
  }

  componentDidMount() {
    this.props.fetchCurrenciesGenerator();
    this.props.fetchCountriesGenerator();
    this.props.fetchBanksGenerator();
    this.props.fetchSectorsGenerator();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.submitClicked) {
      const formDataIsValid = true;// A CHECK CAN BE DONE HERE
      let dataSubmitted = false;
      if (formDataIsValid) {
        switch (prevProps.action) {
          case 'create':
            let info = {...this.state};
            if(this.props.country_pending_switch){
              info.country_pending_switch = this.props.country_pending_switch;
              info.switch_to = true;
            }
            prevProps.addCompanyGenerator(info);
            dataSubmitted = true;
            break;
          case 'create_branch':
            let data_ = {...this.state};
            data_.head_office_id = Helpers.authCurrentHeadOfficeID();
            data_.is_branch= true;
            prevProps.addCompanyGenerator(data_);
            break;

          case 'create_branch_from_list':
            let datum = {...this.state};
            datum.is_branch= true;
            prevProps.addCompanyGenerator(datum);
            break;
          case 'update':
          case 'update_company':
            const data = {...this.state};
            if (data.date_started) {
              try {
                data.date_started = data.date_started.format(dateFormat);
              } catch (e) {}
            }
            if (prevProps.action ==="update_company"){
              delete data['head_office_id'];//this form is used updating both company and branch
            }
            prevProps.editCompanyGenerator(data);
            dataSubmitted = true;
            break;
        }
        prevProps.closeModal(dataSubmitted);
      }
    }
  }

  updateState = (e) => {
    const { id, name, value } = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState });
  };

  switchTabs = (event, value) => {
    this.setState({ value });
  };

  updateStartDateState = (date) => {
    this.setState({ date_started: date });
  };

  renderMenuItems = data => data.map(s => (
    <MenuItem key={s.id} value={s.id}>
      {s.name}
    </MenuItem>
  ));

  render() {
    let {
      name,
      action,
      value,
      employee_no_prefix,
      pin_no,
      nssf_no,
      nhif_code,
      sector_id,
      date_started,
      //currency_id,
      city,
      county,
      email,
      mobile_no,
      website,
      postal_address,
      zip_code,
      road,
      country_id,
      bank_id,
      branch_id,
      account_name,
      account_no
    } = this.state;

    //let currenciesByID = Helpers.currencies();if(!currenciesByID){currenciesByID={};}
    let sectorsByID = Helpers.sectors();if(!sectorsByID){sectorsByID={};}
    let countriesByID = Helpers.countries();if(!countriesByID){countriesByID={};}
    let banksByID = Helpers.banks();if(!banksByID){banksByID={};}
    let country = Object.values(countriesByID).whereFirst(record=>record.id === country_id);
    let isKenyan = country && country.name.toLowerCase().includes('kenya');
    const bank_branches = Helpers.bank_branches(banksByID, bank_id);
    let isBranch = (this.props.action &&( this.props.action === 'create_branch'|| this.props.action === 'create_branch_from_list' || this.props.action === 'update'));
    let label = "Company Name";
    if (isBranch){
      label = "Branch Name";
    }
    return (
      <div>
        <Tabs value={value} onChange={this.switchTabs} indicatorColor="primary">
          <Tab label="About" />
          <Tab label="Address" />
          {
            isKenyan && <Tab label="Bank Details" />
          }
        </Tabs>
        {value === 0 && (
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                required
                id="name"
                label={label}
                value={name}
                fullWidth
                placeholder={label}
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                id="employee_no_prefix"
                label="Employee No. Prefix"
                value={employee_no_prefix}
                fullWidth
                placeholder="Employee No. Prefix"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                id="pin_no"
                label="Pin Number"
                value={pin_no}
                fullWidth
                placeholder="Pin Number"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                id="nssf_no"
                label="NSSF No"
                value={nssf_no}
                fullWidth
                placeholder="NSSF No"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                id="nhif_code"
                label="NHIF No"
                value={nhif_code}
                fullWidth
                placeholder="NHIF No"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <FormControl fullWidth margin="normal" placeholder="Sector">
                <InputLabel htmlFor="sector">Sector</InputLabel>
                <Select
                  value={sector_id}
                  onChange={this.updateState}
                  input={<Input name="sector_id" id="sector_id" />}
                >
                  {this.renderMenuItems(Object.values(sectorsByID))}
                </Select>
              </FormControl>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <DatePicker
                views={['day', 'month', 'year']}
                value={date_started}
                label="Date Started"
                placeholder="Date Started"
                onChange={this.updateStartDateState}
                fullWidth
                style={{ marginTop: 16}}
                margin="normal"
                autoOk={true}
              />
            </div>
            {/*<div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <FormControl fullWidth margin="normal" placeholder="Currency">
                <InputLabel htmlFor="currency_id">Currency</InputLabel>
                <Select
                  value={currency_id}
                  onChange={this.updateState}
                  input={<Input name="currency_id" id="currency_id" />}
                >
                  {this.renderMenuItems(Object.values(currenciesByID))}
                </Select>
              </FormControl>
            </div>*/}
          </div>
        )}
        {value === 1 && (
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="city"
                label="City"
                value={city}
                fullWidth
                placeholder="City"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="county"
                label="County/State"
                value={county}
                fullWidth
                placeholder="County/State"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="email"
                label="Email"
                value={email}
                fullWidth
                placeholder="Email"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="mobile_no"
                label="Mobile No"
                value={mobile_no}
                fullWidth
                placeholder="Mobile No"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="website"
                label="Website"
                value={website}
                fullWidth
                placeholder="Website"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="postal_address"
                label="Post Address"
                value={postal_address}
                fullWidth
                placeholder="Post Address"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="zip_code"
                label="Post Code"
                value={zip_code}
                fullWidth
                placeholder="Post Code"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <TextField
                id="road"
                label="Street"
                value={road}
                fullWidth
                placeholder="Street"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            {
              !isBranch &&
              <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                <FormControl fullWidth margin="normal" placeholder="Country">
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <Select
                    value={country_id}
                    onChange={this.updateState}
                    input={<Input name="country_id" id="country_id" />}
                  >
                    {this.renderMenuItems(Object.values(countriesByID))}
                  </Select>
                </FormControl>
              </div>
            }
          </div>
        )}
        {value === 2 && (
          <div className="row">
            <div className="col-12">
              <FormControl fullWidth margin="normal" placeholder="Bank">
                <InputLabel htmlFor="bank_id">Bank</InputLabel>
                <Select
                  value={bank_id}
                  onChange={this.updateState}
                  input={<Input name="bank_id" id="bank_id" />}
                >
                  {this.renderMenuItems(Object.values(banksByID))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth margin="normal" placeholder="Bank Branch">
                <InputLabel htmlFor="paypointBranch">Bank Branch</InputLabel>
                <Select
                  value={branch_id}
                  onChange={this.updateState}
                  input={<Input name="branch_id" id="branch_id" />}
                >
                  {this.renderMenuItems(bank_branches)}
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                id="account_name"
                label="Account Name"
                value={account_name}
                fullWidth
                placeholder="Account Name"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-12">
              <TextField
                id="account_no"
                label="Account Number"
                value={account_no}
                fullWidth
                placeholder="Account Number"
                margin="normal"
                onChange={this.updateState}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({general,company}) => {
  const {
    currenciesByID, sectorsByID, countriesByID, banksByID
  } = general;
  const {companiesByID} = company;
  return {
    currenciesByID, sectorsByID, countriesByID, banksByID,companiesByID
  };
};
export default withStyles(styles)(connect(mapStateToProps, {addCompanyGenerator, editCompanyGenerator, switchCompanyGenerator, fetchCurrenciesGenerator, fetchCountriesGenerator,fetchBanksGenerator,fetchSectorsGenerator})(CreateCompanyForm));
