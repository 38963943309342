import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {all, call, fork, put,takeLatest} from "redux-saga/effects";
import {
    DOWNLOAD_BULK_CUSTOMER_STATEMENT,
    FETCH_CUSTOMER_STATEMENT,
    fetchCustomerStatementSuccessGenerator,
    SEND_CUSTOMER_STATEMENT,
    sendCustomerStatementSuccessGenerator
} from "../../actions/admin/CustomerStatementActions";

const  fetchCustomerStatementRequest = async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/customer-statement',requestParameters)
        .then(response => response)
        .catch(error=>error.response);

const  sendCustomerStatementRequest = async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/customer-statement',requestParameters)
        .then(response => response)
        .catch(error=>error.response);

const  bulkCustomerStatementRequest = async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/bulk-customer-statement',requestParameters)
        .then(response => response)
        .catch(error=>error.response);

function* bulkCustomerStatements({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(bulkCustomerStatementRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                if(response.data.data.length > 0) {
                    Helpers.openInNewTab(API_ENDPOINT + "/admin/company/loan/bulk-customer-statement/"+response.data.data+ "?token=" + Helpers.token()+ "&company_loan_id=" + requestParameters.company_loan_id);
                }else{
                    yield put(showMessageGenerator("No Customer Loan Statement Found",'error'));
                }
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* sendCustomerStatementReports({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(sendCustomerStatementRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let employee_payslip = response.data.data;
                if(employee_payslip.id){
                    yield put(sendCustomerStatementSuccessGenerator(employee_payslip));
                }
                yield put(showMessageGenerator(response.data.message,'info'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchCustomerStatementReports({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchCustomerStatementRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let employee_payslip = response.data.data;
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(fetchCustomerStatementSuccessGenerator(employee_payslip));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* bulkCustomerStatementDefault() {
    yield takeLatest(DOWNLOAD_BULK_CUSTOMER_STATEMENT,bulkCustomerStatements);
}

export function* fetchCustomerStatementDefault() {
    yield takeLatest(FETCH_CUSTOMER_STATEMENT,fetchCustomerStatementReports);
}

export function* sendCustomerStatementDefault() {
    yield takeLatest(SEND_CUSTOMER_STATEMENT,sendCustomerStatementReports);
}

export default function* rootSaga() {
    yield all([
        fork(fetchCustomerStatementDefault),
        fork(sendCustomerStatementDefault),
        fork(bulkCustomerStatementDefault)
    ]);
}
