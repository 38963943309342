export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const SHOW_NOTIFICATION_CREATE_FORM = 'SHOW_NOTIFICATION_CREATE_FORM';
export const HIDE_NOTIFICATION_CREATE_FORM = 'HIDE_NOTIFICATION_CREATE_FORM';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';

export const fetchNotificationGenerator = (data=[])=>{
  return {
    type:FETCH_NOTIFICATION,
    payload:data
  };
};

export const fetchNotificationSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_NOTIFICATION_SUCCESS,
    payload:dataById
  }
};

export const showNotificationCreateFormGenerator = ()=>{
  return {
    type:SHOW_NOTIFICATION_CREATE_FORM
  };
};
export const hideNotificationCreateFormGenerator = ()=>{
  return {
    type:HIDE_NOTIFICATION_CREATE_FORM
  };
};
export const addNotificationGenerator = (data)=>{
  return {
    type: ADD_NOTIFICATION,
    payload: data
  }
};

export const addNotificationSuccessGenerator = (data)=>{
  return {
    type: ADD_NOTIFICATION_SUCCESS,
    payload: data
  }
};
export const markAsReadNotificationGenerator = (data)=>{
  return {
    type: MARK_AS_READ,
    payload: data
  }
};
export const markAsReadNotificationSuccessGenerator = (data)=>{
  return {
    type: MARK_AS_READ_SUCCESS,
    payload: data
  }
};