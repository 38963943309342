import { all, call, fork, put, take, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";

import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
  ACTIVATE_BULK_LOANS,
  ADD_LOANS, CERTIFY_EMPLOYEE_LOAN, CREATE_MY_COMPANY_LOAN,
  DEACTIVATE_BULK_LOANS,
  DELETE_LOANS,
  EDIT_LOANS,
  FETCH_LOANS,
  fetchLoanGenerator,
  fetchLoanSuccessGenerator,
  GET_MY_COMPANY_LOAN,
  getMyCompanyLoanGenerator,
  getMyCompanyLoanSuccessGenerator, MARK_AS_PAID_EMPLOYEE_LOAN, PAY_EMPLOYEE_LOAN,
  UPLOAD_BULK_LOANS
} from "../../actions/payroll/LoansActions";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { fetchRecipientsSuccessGenerator } from "../../actions/payouts/BulkPayoutActions";
import { push } from "connected-react-router";

const payEmployeeLoanRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/home/make/payment',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const markAsPaidEmployeeLoansRequest =async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/loans/mark_as_paid',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const certifyEmployeeLoanRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/loans/certify',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const getMyCompanyLoansRequest = async (requestData)=>
  await axios.get(API_ENDPOINT+'/hrm/payroll-financing',{params:requestData})
    .then(response=>response)
    .catch(error=>error.response);
const createCompanyLoansRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/payroll-financing',requestData)
        .then(response=>response)
        .catch(error=>error.response);

function* payEmployeeLoan({type,payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(payEmployeeLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsSuccessGenerator(response.data.data));
        yield put(push('/payouts/bulk-payouts'));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* certifyEmployeeLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(certifyEmployeeLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* markAsPaidEmployeeLoans({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(markAsPaidEmployeeLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* createCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(createCompanyLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(getMyCompanyLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* getMyCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(getMyCompanyLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let loans = response.data.data;
        yield put(getMyCompanyLoanSuccessGenerator(loans));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* getMyCompanyLoanDefault() {
yield takeLatest(GET_MY_COMPANY_LOAN,getMyCompanyLoan);
}
export function* createCompanyLoanDefault() {
yield takeLatest(CREATE_MY_COMPANY_LOAN,createCompanyLoan);
}

//activate
const activateEmployeeLoansRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/loan/activate/multiple',requestData)
        .then(response => response)
        .catch(error=>error.response);

function* activateEmployeeLoans({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(activateEmployeeLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* activateEmployeeLoansDefault() {
  yield takeLatest(ACTIVATE_BULK_LOANS,activateEmployeeLoans);
}
const deActivateEmployeeLoansRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/loan/deactivate/multiple',requestData)
        .then(response => response)
        .catch(error=>error.response);

function* deActivateEmployeeLoans({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deActivateEmployeeLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deActivateEmployeeLoansDefault() {
  yield takeLatest(DEACTIVATE_BULK_LOANS,deActivateEmployeeLoans);
}
////fetch loans
const fetchLoansRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/loans',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);

function* fetchLoans({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let loans = response.data.data;
        yield put(fetchLoanSuccessGenerator(loans));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchLoansDefault() {
  yield takeLatest(FETCH_LOANS,fetchLoans)
}
////end fetch loans
///create loans
const addLoansRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/loans',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* addLoans({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addLoansDefault() {
  yield takeLatest(ADD_LOANS,addLoans)
}
///end of create loans
//create bulk loans
const uploadBulkLoansRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/loans/upload/bulk',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

function* uploadBulkLoans({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadBulkLoansRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* uploadBulkLoansDefault() {
  yield takeLatest(UPLOAD_BULK_LOANS,uploadBulkLoans)
}
//end create bulk loans
///edit loans
const editLoansRequest = async (requestParameters)=>
  await axios.put(API_ENDPOINT+'/hrm/loans/'+requestParameters.id,requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* updateLoans({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let loans = response.data.data;
        //yield put(editLoanSuccessGenerator(loans));
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateLoansDefault() {
  yield takeLatest(EDIT_LOANS,updateLoans)
}
///end update loans
/// start delete loans
const deleteLoansRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/loans/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* deleteLoans({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let loans = response.data.data;
        //yield put(deleteLoanSuccessGenerator(loans));
        yield put(fetchLoanGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* deleteLoansDefault() {
  yield takeLatest(DELETE_LOANS,deleteLoans)
}

export function* certifyEmployeeLoanDefault(){
  yield takeLatest(CERTIFY_EMPLOYEE_LOAN,certifyEmployeeLoan)
}
 export function* markAsPaidEmployeeLoansDefault(){
  yield takeLatest(MARK_AS_PAID_EMPLOYEE_LOAN,markAsPaidEmployeeLoans)
 }
 export function* payEmployeeLoanDefault(){
  yield takeLatest(PAY_EMPLOYEE_LOAN,payEmployeeLoan)
 }
export default function* rootSaga() {
  yield all([
      fork(fetchLoansDefault),
      fork(addLoansDefault),
      fork(updateLoansDefault),
      fork(deleteLoansDefault),
      fork(uploadBulkLoansDefault),
      fork(activateEmployeeLoansDefault),
      fork(deActivateEmployeeLoansDefault),
     fork(getMyCompanyLoanDefault),
     fork(createCompanyLoanDefault),
    fork(certifyEmployeeLoanDefault),
    fork(markAsPaidEmployeeLoansDefault),
    fork(payEmployeeLoanDefault)
  ]);
}
