import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_PRODUCT,
  DELETE_PRODUCT, DOWNLOAD_PRODUCTS_DELIVERED_TEMPLATE,
  EDIT_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_DELIVERED,
  fetchProductsDeliveredGenerator,
  fetchProductsDeliveredSuccessGenerator,
  fetchProductsGenerator,
  fetchProductsSuccessGenerator, SET_PRODUCTS_DELIVERED,
  UPLOAD_PRODUCTS_DELIVERED
} from "../../actions/payroll/ProductActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import { fetchDocumentsGenerator } from "../../actions/payroll/DocumentActions";

const fetchProductsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/products',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addProductRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/products',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteProductRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/products/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editProductRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/products/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const uploadProductsDeliveredRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/products-delivered',requestParameters, {headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const setProductsDeliveredRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/products-delivered/set',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchProductsDeliveredRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/products-delivered',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const downloadProductsDeliveredTemplateRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/products-delivered/template',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* uploadProductsDelivered({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadProductsDeliveredRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsDeliveredGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchProducts({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchProductsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchProductsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchProductsDelivered({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchProductsDeliveredRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchProductsDeliveredSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* downloadProductsDeliveredTemplate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(downloadProductsDeliveredTemplateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addProduct({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addProductRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteProduct({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteProductRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editProduct({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editProductRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* setProductsDelivered({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(setProductsDeliveredRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsDeliveredGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchProductsDefault() {
  yield takeLatest(FETCH_PRODUCTS, fetchProducts)
}
export function* addProductDefault() {
  yield takeLatest(ADD_PRODUCT, addProduct);
}
export function* deleteProductDefault() {
  yield takeLatest(DELETE_PRODUCT, deleteProduct);
}
export function* editProductDefault() {
  yield takeLatest(EDIT_PRODUCT, editProduct);
}
export function* uploadProductsDeliveredDefault() {
  yield takeLatest(UPLOAD_PRODUCTS_DELIVERED, uploadProductsDelivered);
}
export function* fetchProductsDeliveredDefault() {
  yield takeLatest(FETCH_PRODUCTS_DELIVERED, fetchProductsDelivered);
}
export function* downloadProductsDeliveredTemplateDefault() {
  yield takeLatest(DOWNLOAD_PRODUCTS_DELIVERED_TEMPLATE, downloadProductsDeliveredTemplate);
}
export function* setProductsDeliveredDefault() {
  yield takeLatest(SET_PRODUCTS_DELIVERED, setProductsDelivered);
}
export default function* rootSaga() {
  yield all([fork(fetchProductsDefault),
    fork(addProductDefault),
    fork(deleteProductDefault),
    fork(editProductDefault),
    fork(uploadProductsDeliveredDefault),
    fork(fetchProductsDeliveredDefault),
    fork(downloadProductsDeliveredTemplateDefault),
    fork(setProductsDeliveredDefault)
  ]);
}
