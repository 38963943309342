import {all, call, fork, put} from "redux-saga/effects";
import {
    ACTIVATE_LEAVE_MULTIPLE,
    ADD_LEAVE, APPROVE_LEAVE_MULTIPLE,
    CERTIFY_LEAVE_MULTIPLE,
    DELETE_LEAVE,
    deleteLeaveSuccessGenerator,
    EDIT_LEAVE,
    editLeaveSuccessGenerator,
    FETCH_LEAVE_DETAILS,
    FETCH_LEAVES,
    fetchLeaveDetailsSuccessGenerator, fetchLeavesGenerator,
    fetchLeavesSuccessGenerator, getDepartmentSupervisorsGenerator, RECALL_LEAVE_MULTIPLE
} from "../../actions/payroll/LeaveActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const certifyLeaveMultipleRequest = async  (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/leave/certify/multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const approveLeaveMultipleRequest = async  (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/leave/approve/multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const activateLeaveMultipleRequest = async  (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/leave/activate/multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const recallLeaveMultipleRequest = async  (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/leave/deactivate/multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const fetchLeavesRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/hrm/leaves',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const fetchLeaveDetailsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/leave/details',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addLeaveRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/leaves',requestParameters,{headers: {
            'content-type': `multipart/form-data`
        }})
        .then(response => response)
        .catch(error => error.response);

const deleteLeaveRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/leaves/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editLeaveRequest = async (id,requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/leaves/'+id,requestParameters,{
        headers:{
            'content-type': `multipart/form-data`
        }
    })
        .then(response => response)
        .catch(error => error.response);

function* certifyLeaveMultiple({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(certifyLeaveMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let rolesById = response.data.data;
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(fetchLeavesGenerator(payload.params));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* activateLeaveMultiple({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(activateLeaveMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let rolesById = response.data.data;
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(fetchLeavesGenerator(payload.params));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* approveLeaveMultiple({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(approveLeaveMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let rolesById = response.data.data;
                yield put(fetchLeavesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* recallLeaveMultiple({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(recallLeaveMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let rolesById = response.data.data;
                yield put(fetchLeavesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchLeaves({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchLeavesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                let supervisors = response.data.supervisors;
                yield put(fetchLeavesSuccessGenerator(rolesById));
                yield put(getDepartmentSupervisorsGenerator(supervisors));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchLeaveDetails({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchLeaveDetailsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let data = response.data.data;
                yield put(fetchLeaveDetailsSuccessGenerator(data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addLeave({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        let formData = Helpers.getFormData(requestParameters);
        const response = yield call(addLeaveRequest, formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let role = response.data.data;
                //yield put(addLeaveSuccessGenerator(role));
                yield put(fetchLeavesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteLeave({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteLeaveRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let role = response.data.data;
                //yield put(deleteLeaveSuccessGenerator(role));
                yield put(fetchLeavesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editLeave({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let formData = Helpers.getFormData(requestParameters);
        const response = yield call(editLeaveRequest,requestParameters.id,formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let role = response.data.data;
                //yield put(editLeaveSuccessGenerator(role));
                yield put(fetchLeavesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchLeavesDefault() {
    yield takeLatest(FETCH_LEAVES, fetchLeaves)
}
export function* fetchLeaveDetailsDefault() {
    yield takeLatest(FETCH_LEAVE_DETAILS, fetchLeaveDetails)
}
export function* addLeaveDefault() {
    yield takeLatest(ADD_LEAVE, addLeave);
}
export function* deleteLeaveDefault() {
    yield takeLatest(DELETE_LEAVE, deleteLeave);
}
export function* editLeaveDefault() {
    yield takeLatest(EDIT_LEAVE, editLeave);
}
export function* certifyLeaveMultipleDefault() {
    yield takeLatest(CERTIFY_LEAVE_MULTIPLE,certifyLeaveMultiple);
}
export function* activateLeaveMultipleDefault() {
    yield takeLatest(ACTIVATE_LEAVE_MULTIPLE,activateLeaveMultiple);
}
export function* approveLeaveMultipleDefault() {
    yield takeLatest(APPROVE_LEAVE_MULTIPLE,approveLeaveMultiple);
}
export function* recallLeaveMultipleDefault() {
    yield takeLatest(RECALL_LEAVE_MULTIPLE,recallLeaveMultiple);
}
export default function* rootSaga() {
    yield all([fork(fetchLeavesDefault),
        fork(addLeaveDefault),
        fork(deleteLeaveDefault),
        fork(editLeaveDefault),
        fork(fetchLeaveDetailsDefault),
        fork(certifyLeaveMultipleDefault),
        fork(activateLeaveMultipleDefault),
        fork(approveLeaveMultipleDefault),
        fork(recallLeaveMultipleDefault)
    ]);
}
