import {
  ADD_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS,
  ADD_SALARY_ADVANCE_FEE_SUCCESS, DELETE_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS,
  DELETE_SALARY_ADVANCE_FEE_SUCCESS, EDIT_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS,
  EDIT_SALARY_ADVANCE_FEE_SUCCESS, FETCH_DEFAULT_SALARY_ADVANCE_FEES, FETCH_DEFAULT_SALARY_ADVANCE_FEES_SUCCESS,
  FETCH_SALARY_ADVANCE_FEES_SUCCESS
} from "../../actions/admin/SalaryAdvanceFeesActions";

const INIT_STATE = {
  salaryAdvanceFeesByID:{},
  defaultSalaryAdvanceFeesByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_SALARY_ADVANCE_FEES_SUCCESS:
      return {
        ...state,
        salaryAdvanceFeesByID: action.payload
      };
    case ADD_SALARY_ADVANCE_FEE_SUCCESS:
    case EDIT_SALARY_ADVANCE_FEE_SUCCESS:
      let id = action.payload.id;
      return {
        ...state,
        salaryAdvanceFeesByID: {...state.salaryAdvanceFeesByID, [id]:action.payload}
      };
    case DELETE_SALARY_ADVANCE_FEE_SUCCESS:
      let salaryAdvanceFeesByID = {...state.salaryAdvanceFeesByID};
      delete salaryAdvanceFeesByID[action.payload.id];
      return {
        ...state,
        salaryAdvanceFeesByID: salaryAdvanceFeesByID
      };


    case FETCH_DEFAULT_SALARY_ADVANCE_FEES_SUCCESS:
      return {
        ...state,
        defaultSalaryAdvanceFeesByID: action.payload
      };
    case ADD_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS:
    case EDIT_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS:
      let id_ = action.payload.id;
      return {
        ...state,
        defaultSalaryAdvanceFeesByID: {...state.defaultSalaryAdvanceFeesByID, [id_]:action.payload}
      };
    case DELETE_DEFAULT_SALARY_ADVANCE_FEE_SUCCESS:
      let defaultSalaryAdvanceFeesByID = {...state.defaultSalaryAdvanceFeesByID};
      delete defaultSalaryAdvanceFeesByID[action.payload.id];
      return {
        ...state,
        defaultSalaryAdvanceFeesByID: defaultSalaryAdvanceFeesByID
      };

    default:
      return state;
  }
}
