import AttendanceReportReducer from "./AttendanceReportReducer";
import SnapCheckReducer from "./SnapCheckReducer";
import DetectionModeReducer from "./DetectionModeReducer";
import FingerPrintsReducer from "./FingerPrintsReducer";
import SyncedDevicesReducer from "./SyncedDevicesReducer";
import AttendanceMappingReducer from "./AttendanceMappingReducer";
import SchedulesReducer from "./SchedulesReducer";
import CheckPointReducer from "./CheckPointReducer"
import ProjectReducer from "./ProjectReducer";
import SchedulesReportsReducer from "./SchedulesReportsReducer";
import WorkedDaysReducer from "./WorkedDaysReducer";
import AttendanceReducer from "./AttendanceReducer";
import EmployeeReducer from "./EmployeeReducer"
import RelieverReducer from "./RelieverReducer";
import CustomHoursReducer from "./CustomHoursReducer";

const Attendance = {
   attendanceReport: AttendanceReportReducer,
   snapCheck:SnapCheckReducer,
   detectionMode:DetectionModeReducer,
   fingerPrints:FingerPrintsReducer,
   syncedDevices:SyncedDevicesReducer,
   attendanceMapping:AttendanceMappingReducer,
   schedules: SchedulesReducer,
   checkPoints: CheckPointReducer,
   project:ProjectReducer,
   schedulesReports: SchedulesReportsReducer,
   worked:WorkedDaysReducer,
   attendance: AttendanceReducer,
   attendanceEmployees: EmployeeReducer,
   reliever: RelieverReducer,
   customHour: CustomHoursReducer
};

export default Attendance;
