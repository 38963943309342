import {
  CHANGE_PROFILE_PHOTO_SUCCESS,
  FETCH_EMPLOYEE_PROFILE_DETAILS_SUCCESS,
  FETCH_MY_TRAINING_SUCCESS,
  MY_DAILY_ATTENDANCE_SUCCESS,
  UPDATE_PROFILE_INFO_SUCCESS
} from "../../actions/profiles/EmployeeProfileActions";
import { Helpers } from "../../../util/Helpers";

const INIT_STATE = {
  employeeProfileDetails:[],
  my_attendance:[],
  clockins:[],
  clockout:[],
  date:'',
  shifts:[],
  project_id:'',
  my_training:[],
 };

export default (state=INIT_STATE,action)=>{
  switch (action.type){
    case FETCH_MY_TRAINING_SUCCESS:
      return {
        ...state,
        my_training: action.payload
      }
    case MY_DAILY_ATTENDANCE_SUCCESS:
      return {
        ...state,
        my_attendance: action.payload,
        clockins:action.payload.clockins,
        clockout:action.payload.clockout,
        date:action.payload.date
      }
    case FETCH_EMPLOYEE_PROFILE_DETAILS_SUCCESS:
    case UPDATE_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        employeeProfileDetails:action.payload,
        shifts: action.payload.schedule_group_shifts,
        project_id:action.payload.project_id
      };
    case CHANGE_PROFILE_PHOTO_SUCCESS:
      let employee_id = action.payload.employee_id;
      let isCurrentEmployee = !Helpers.isNull(employee_id) && (Number(employee_id) === Number(state.employeeProfileDetails.id));
      if(isCurrentEmployee){
        return {
          ...state,
          employeeProfileDetails:{...state.employeeProfileDetails, profile_picture:action.payload.path}
        };
      }else{
        return state
      }
    default: return state;
  }
}
