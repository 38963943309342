import {
    FETCH_INFO_TO_SELF_APPRAISE_SUCCESS,
    FETCH_SELF_APPRAISALS_SUCCESS,
    SELF_APPRAISE_SUCCESS,
    UPDATE_IS_GROUPED
} from '../../actions/appraisal/SelfAppraisalActions';

const INIT_STATE ={
    selfAppraisalsByID:{},
    isGrouped:true,
    infoToSelfAppraise:{}
};
export default (state = INIT_STATE,action)=>{
    switch (action.type) {
        case SELF_APPRAISE_SUCCESS:
        case FETCH_SELF_APPRAISALS_SUCCESS:
            return {
                ...state,
                selfAppraisalsByID: action.payload.data,
                isGrouped:action.payload.grouped
            };
        case FETCH_INFO_TO_SELF_APPRAISE_SUCCESS:
            return {
                ...state,
                infoToSelfAppraise:action.payload
            };
        case UPDATE_IS_GROUPED:
            return {
                ...state,
                isGrouped:true
            };
        default:
            return state;
    }
}
