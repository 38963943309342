import {all, call, fork, put} from "redux-saga/effects";
import {
    ADD_COMPANY_LOAN_PAYMENT,
    DELETE_COMPANY_LOAN_PAYMENT,
    deleteCompanyLoanPaymentSuccessGenerator,
    UPDATE_COMPANY_LOAN_PAYMENT,
    FETCH_COMPANY_LOAN_PAYMENT,
    fetchCompanyLoanPaymentGenerator,
    fetchCompanyLoanPaymentSuccessGenerator
} from "../../actions/admin/CompanyLoanPaymentActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import {hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator} from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { fetchCompanyLoansGenerator } from "../../actions/admin/CompanyLoansActions";

const fetchCompanyLoanPaymentRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/tozza/company-loan-payments',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addCompanyLoanPaymentRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/tozza/company-loan-payments',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteCompanyLoanPaymentRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/tozza/company-loan-payments/'+requestParameters.id, {params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const updateCompanyLoanPaymentRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/tozza/company-loan-payments/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);


function* fetchCompanyLoanPayment({type,payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchCompanyLoanPaymentRequest, requestParameters);

        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchCompanyLoanPaymentSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type)) ;

}
function* addCompanyLoanPayment({type,payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addCompanyLoanPaymentRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                /*let role = response.data.data;*/
                if(window.location.pathname === '/admin/companies-loans'){
                    yield put(fetchCompanyLoansGenerator());
                }else{
                    let params = payload.params?payload.params:{};
                    yield put(fetchCompanyLoanPaymentGenerator(params));
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteCompanyLoanPayment({type, payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteCompanyLoanPaymentRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(showMessageGenerator(response.data.message,'success'));
                let params = payload.params?payload.params:{};
                yield put(fetchCompanyLoanPaymentGenerator(params));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* updateCompanyLoanPayment({type, payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try {
        let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(updateCompanyLoanPaymentRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(showMessageGenerator(response.data.message,'success'));
                let params = payload.params?payload.params:{};
                yield put(fetchCompanyLoanPaymentGenerator(params));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchCompanyLoanPaymentDefault() {
    yield takeLatest(FETCH_COMPANY_LOAN_PAYMENT, fetchCompanyLoanPayment)
}
export function* addCompanyLoanPaymentDefault() {
    yield takeLatest(ADD_COMPANY_LOAN_PAYMENT, addCompanyLoanPayment);
}
export function* deleteCompanyLoanPaymentDefault() {
    yield takeLatest(DELETE_COMPANY_LOAN_PAYMENT, deleteCompanyLoanPayment);
}
export function* updateCompanyLoanPaymentDefault() {
    yield takeLatest(UPDATE_COMPANY_LOAN_PAYMENT, updateCompanyLoanPayment);
}

export default function* rootSaga() {
    yield all([fork(fetchCompanyLoanPaymentDefault),
        fork(addCompanyLoanPaymentDefault),
        fork(deleteCompanyLoanPaymentDefault),
        fork(updateCompanyLoanPaymentDefault),
    ]);
}
