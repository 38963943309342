import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App";
import { appStartGenerator } from "./appRedux/actions/GeneralActions";
import Raven from "raven-js";

export const store = configureStore();
store.dispatch(appStartGenerator());

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  Raven.config("https://da56756fe828483bafb3226b4b55f39c@sentry.io/1478391").install();
  Raven.captureMessage(' ');
  Raven.setDataCallback(function (data) {
    // means an error was triggered
    Raven.showReportDialog(); // uses last event id
  });
}



const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history} key={'ConnectedRouter'}>
      <Switch key={'Switch'}>
        <Route path="/" component={App} key={'Route'}/>
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
