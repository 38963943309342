export const CREATE_TRAINING='CREATE_TRAINING';
export const CREATE_TRAINING_SUCCESS='CREATE_TRAINING_SUCCESS';

export const EDIT_TRAINING = 'EDIT_TRAINING';
export const EDIT_TRAINING_SUCCESS = 'EDIT_TRAINING_SUCCESS';

export const DELETE_TRAINING = 'DELETE_TRAINING';
export const DELETE_TRAINING_SUCCESS = 'DELETE_TRAINING_SUCCESS';

export const ASSIGN_EMPLOYEE_TO_TRAINING ='ASSIGN_EMPLOYEE_TO_TRAINING';
export const ASSIGN_EMPLOYEE_TO_TRAINING_SUCCESS ='ASSIGN_EMPLOYEE_TO_TRAINING_SUCCESS';

export const FETCH_TRAINING ='FETCH_TRAINING';
export const FETCH_TRAINING_SUCCESS ='FETCH_TRAINING_SUCCESS';

export const UPLOAD_TRAINING_CERTIFICATE ='UPLOAD_TRAINING_CERTIFICATE';
export const UPLOAD_TRAINING_CERTIFICATE_SUCCESS ='UPLOAD_TRAINING_CERTIFICATE_SUCCESS';

export const DOWNLOAD_TRAINING_CERTIFICATE ='DOWNLOAD_TRAINING_CERTIFICATE';

export const GET_TRAINING_KEYED = 'GET_TRAINING_KEYED';
export const GET_TRAINING_KEYED_SUCCESS = 'GET_TRAINING_KEYED_SUCCESS';

export const COMPLETE_TRAINING = 'COMPLETE_TRAINING';
export const EDIT_EMPLOYEE_TRAINING = 'EDIT_EMPLOYEE_TRAINING';
export const DELETE_EMPLOYEE_TRAINING = 'DELETE_EMPLOYEE_TRAINING';

export const ACKNOWLEDGE_COMPLETION = 'ACKNOWLEDGE_COMPLETION';

export const acknowledgeTrainingCompletion = (payload=[])=>{
    return {
        type:ACKNOWLEDGE_COMPLETION,
        payload
    }
}

export const completeTrainingGenerator = (payload=[])=>{
    return {
        type:COMPLETE_TRAINING,
        payload
    }
};
export const editEmployeeTrainingGenerator = (payload=[])=>{
    return {
        type:EDIT_EMPLOYEE_TRAINING,
        payload
    }
};
export const deleteEmployeeTrainingGenerator = (payload=[])=>{
    return {
        type:DELETE_EMPLOYEE_TRAINING,
        payload
    }
};

export const getTrainingsKeyedGenerator = (payload=[])=>{
    return{
        type:GET_TRAINING_KEYED,
        payload
    }
};
export const getTrainingsKeyedSuccessGenerator = (payload=[])=>{
    return{
        type:GET_TRAINING_KEYED_SUCCESS,
        payload
    }
}

export const uploadTrainingCertificateGenerator =(payload=[])=>{
    return {
        type:UPLOAD_TRAINING_CERTIFICATE,
        payload,
    }
};
export const uploadTrainingCertificateSuccessGenerator =(payload=[])=>{
    return {
        type:UPLOAD_TRAINING_CERTIFICATE,
        payload,
    }
};
export const downloadTrainingCertificateGenerator = (payload=[])=>{
    return {
        type:DOWNLOAD_TRAINING_CERTIFICATE,
        payload
    }
}
export const fetchTrainingGenerator = (payload=[])=>{
    return {
        type:FETCH_TRAINING,
        payload
    }
};
export const fetchTrainingSuccessGenerator = (payload=[])=>{
    return {
        type:FETCH_TRAINING_SUCCESS,
        payload
    }
};
export const editTrainingGenerator = (payload=[])=>{
    return {
        type:EDIT_TRAINING,
        payload
    }
};
export const editTrainingSuccessGenerator = (payload=[])=>{
    return {
        type:EDIT_TRAINING,
        payload
    }
};
export const deleteTrainingGenerator = (payload=[])=>{
    return {
        type:DELETE_TRAINING,
        payload
    }
};
export const deleteTrainingSuccessGenerator = (payload=[])=>{
    return {
        type:DELETE_TRAINING,
        payload
    }
};
export const createTrainingGenerator = (payload=[])=>{
    return {
        type:CREATE_TRAINING,
        payload
    }
};
export const createTrainingSuccessGenerator = (payload=[])=>{
    return {
        type:CREATE_TRAINING_SUCCESS,
        payload
    }
};
export const assignEmployeeToTrainingGenerator=(payload=[])=>{
    return {
        type:ASSIGN_EMPLOYEE_TO_TRAINING,
        payload
    }
};
export const assignEmployeeToTrainingSuccessGenerator=(payload=[])=>{
    return {
        type:ASSIGN_EMPLOYEE_TO_TRAINING,
        payload
    }
}
