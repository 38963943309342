import {
  FETCH_LOAN_BOOK_BALANCE_SUCCESS, FETCH_LOANS_ISSUED_SUCCESS,
  FETCH_OVERDUE_LOANS_SUCCESS,
  FETCH_TOP_BORROWERS_SUCCESS, UPDATE_LOAN_DASHBOARD_YEAR_SUCCESS
} from "../../actions/admin/LoanDashboardActions";
import { Helpers } from "../../../util/Helpers";
import { LOAN_DASHBOARD_YEAR } from "../../../constants/SessionKeys";
import moment from "moment";

const dashboard_year = Helpers.getItem(LOAN_DASHBOARD_YEAR);
const INIT_STATE = {
  loan_book:null,
  loans_issued:[],
  loans_overdue:{},
  top_borrowers:[],
  dashboard_year:(dashboard_year && !isNaN(dashboard_year))?dashboard_year:moment().year()
};

export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_LOAN_BOOK_BALANCE_SUCCESS:
      return {
        ...state,
        loan_book: action.payload
      };
    case FETCH_LOANS_ISSUED_SUCCESS:
      return {
        ...state,
        loans_issued: action.payload
      };
    case FETCH_OVERDUE_LOANS_SUCCESS:
      return {
        ...state,
        loans_overdue: action.payload
      };
    case FETCH_TOP_BORROWERS_SUCCESS:
      return {
        ...state,
        top_borrowers: action.payload
      };
    case UPDATE_LOAN_DASHBOARD_YEAR_SUCCESS:
      return {
        ...state,
        dashboard_year: action.payload,
        loan_book:null,
        loans_issued:[],
        loans_overdue:{},
        top_borrowers:[]
      };

    default: return state;
  }
}
