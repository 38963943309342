export const ADD_CUSTOM_HOURS ="ADD_CUSTOM_HOURS";
export const ADD_CUSTOM_HOURS_SUCCESS ="ADD_CUSTOM_HOURS_SUCCESS";

export const FETCH_CUSTOM_HOURS ="FETCH_CUSTOM_HOURS";
export const FETCH_CUSTOM_HOURS_SUCCESS ="FETCH_CUSTOM_HOURS_SUCCESS";

export const EDIT_CUSTOM_HOURS ="EDIT_CUSTOM_HOURS";
export const EDIT_CUSTOM_HOURS_SUCCESS ="EDIT_CUSTOM_HOURS_SUCCESS";

export const DELETE_CUSTOM_HOURS ="DELETE_CUSTOM_HOURS";
export const DELETE_CUSTOM_HOURS_SUCCESS ="DELETE_CUSTOM_HOURS_SUCCESS";

export const UPLOAD_CUSTOM_HOURS = "UPLOAD_CUSTOM_HOURS";

export const FETCH_TEAMS = "FETCH_TEAMS";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";


export const uploadCustomHoursGenerator = (data)=>{
  return {
    type:UPLOAD_CUSTOM_HOURS,
    payload:data
  }
};

export const fetchCustomHoursGenerator = (data=[]) =>{
  return {
    type:FETCH_CUSTOM_HOURS,
    payload:data
  }
};

export const fetchCustomHoursSuccessGenerator = (data) =>{
  return {
    type:FETCH_CUSTOM_HOURS_SUCCESS,
    payload:data
  }
};
export const addCustomHoursGenerator =(data) =>{
  return {
    type:ADD_CUSTOM_HOURS,
    payload:data
  }
};

export const addCustomHoursSuccessGenerator = (data) => {
  return {
    type:ADD_CUSTOM_HOURS_SUCCESS,
    payload:data
  }
};
export const editCustomHoursGenerator = (data) =>{
  return {
    type:EDIT_CUSTOM_HOURS,
    payload:data
  }
};
export const editCustomHoursSuccessGenerator = (data) =>{
  return {
    type:EDIT_CUSTOM_HOURS_SUCCESS,
    payload:data
  }
};
export const deleteCustomHoursGenerator = (data) =>{
  return {
    type:DELETE_CUSTOM_HOURS,
    payload:data
  }
};
export const deleteCustomHoursSuccessGenerator = (data) =>{
  return {
    type:DELETE_CUSTOM_HOURS_SUCCESS,
    payload:data
  }
};
export const fetchTeamsGenerator = (data=[]) =>{
  return {
    type:FETCH_TEAMS,
    payload:data
  }
};

export const fetchTeamsSuccessGenerator = (data) =>{
  return {
    type:FETCH_TEAMS_SUCCESS,
    payload:data
  }
};
