import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_DOCUMENT, DELETE_DOCUMENT, deleteDocumentSuccessGenerator, EDIT_DOCUMENT, editDocumentSuccessGenerator,
  FETCH_DOCUMENTS, fetchDocumentsGenerator,
  fetchDocumentsSuccessGenerator
} from "../../actions/payroll/DocumentActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addDocumentSuccessGenerator
} from "../../actions/payroll/DocumentActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchDocumentsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/documents',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addDocumentRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/documents',requestParameters, {headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const deleteDocumentRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/documents/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editDocumentRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/home/documents/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchDocuments({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDocumentsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchDocumentsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDocument({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(addDocumentRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchDocumentsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteDocument({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDocumentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(deleteDocumentSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editDocument({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editDocumentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editDocumentSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchDocumentsDefault() {
  yield takeLatest(FETCH_DOCUMENTS, fetchDocuments)
}
export function* addDocumentDefault() {
  yield takeLatest(ADD_DOCUMENT, addDocument);
}
export function* deleteDocumentDefault() {
  yield takeLatest(DELETE_DOCUMENT, deleteDocument);
}
export function* editDocumentDefault() {
  yield takeLatest(EDIT_DOCUMENT, editDocument);
}
export default function* rootSaga() {
  yield all([fork(fetchDocumentsDefault),
    fork(addDocumentDefault),
    fork(deleteDocumentDefault),
    fork(editDocumentDefault)
  ]);
}
