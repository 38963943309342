///create
export const ADD_ADMIN_HOLIDAY = 'ADD_ADMIN_HOLIDAY';
export const ADD_ADMIN_HOLIDAY_SUCCESS = 'ADD_ADMIN_HOLIDAY_SUCCESS';
export const SHOW_CREATE_ADMIN_HOLIDAY_FORM = 'SHOW_CREATE_ADMIN_HOLIDAY_FORM';
export const HIDE_CREATE_ADMIN_HOLIDAY_FORM = 'HIDE_CREATE_ADMIN_HOLIDAY_FORM';
/// fetch
export const FETCH_ADMIN_HOLIDAYS = 'FETCH_ADMIN_HOLIDAYS';
export const FETCH_ADMIN_HOLIDAY_SUCCESS = 'FETCH_ADMIN_HOLIDAY_SUCCESS';
////delete
export const DELETE_ADMIN_HOLIDAY = 'DELETE_ADMIN_HOLIDAY';
export const DELETE_ADMIN_HOLIDAY_SUCCESS ='DELETE_ADMIN_HOLIDAY_SUCCESS';
export const SHOW_DELETE_ADMIN_HOLIDAY_MODAL = 'SHOW_DELETE_ADMIN_HOLIDAY_MODAL';
export const HIDE_DELETE_ADMIN_HOLIDAY_MODAL = 'HIDE_DELETE_ADMIN_HOLIDAY_MODAL';
////edit
export const EDIT_ADMIN_HOLIDAY = 'EDIT_ADMIN_HOLIDAY';
export const EDIT_ADMIN_HOLIDAY_SUCCESS = 'EDIT_ADMIN_HOLIDAY_SUCCESS';
export const SHOW_EDIT_ADMIN_HOLIDAY_FORM = 'SHOW_EDIT_ADMIN_HOLIDAY_FORM';
export const HIDE_EDIT_ADMIN_HOLIDAY_FORM = 'HIDE_EDIT_ADMIN_HOLIDAY_FORM';

/////CREATE
export const addAdminHolidayGenerator = (data)=>{
  return {
    type: ADD_ADMIN_HOLIDAY,
    payload: data
  }
};
export const showAdminHolidayCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_ADMIN_HOLIDAY_FORM,
    payload:data
  }
};
export const hideAdminHolidayCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_ADMIN_HOLIDAY_FORM,
    payload:data
  }
};
export const addAdminHolidaySuccess =(data)=>{
  return {
    type:ADD_ADMIN_HOLIDAY_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchAdminHolidaysGenerator=(data=[])=>{
  return {
    type:FETCH_ADMIN_HOLIDAYS,
    payload:data
  }
};

export const fetchAdminHolidaySuccess =(data)=>{
  return {
    type:FETCH_ADMIN_HOLIDAY_SUCCESS,
    payload:data
  }
};


///EDIT
export const editAdminHolidayGenerator = (data)=>{
  return {
    type: EDIT_ADMIN_HOLIDAY,
    payload: data
  }
};

export const editAdminHolidaySuccess=(data)=>{
  return {
    type:EDIT_ADMIN_HOLIDAY_SUCCESS,
    payload:data
  }
};
export const showAdminHolidayEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_ADMIN_HOLIDAY_FORM,
    payload:data
  }
};
export const hideAdminHolidayEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_ADMIN_HOLIDAY_FORM,
    payload:data
  }
};
/////DELETE
export const deleteAdminHoliday=(data)=>{
 return {
   type:DELETE_ADMIN_HOLIDAY,
   payload:data
 }
};

export const deleteAdminHolidaySuccess = (data)=>{
  return {
    type:DELETE_ADMIN_HOLIDAY_SUCCESS,
    payload:data
  }
};
export const showAdminHolidayDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_ADMIN_HOLIDAY_MODAL,
    payload:data
  }
};
export const hideAdminHolidayDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_ADMIN_HOLIDAY_MODAL,
    payload:data
  }
};



