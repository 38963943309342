import {
  CONFIRM_PAYMENT_SUCCESS, CONFIRM_SUBSCRIPTION_PAYMENT_SUCCESS,
  SEND_REQUEST_SUCCESS, SEND_SUBSCRIPTION_REQUEST_SUCCESS, CONFIRM_CARD_PAYMENT_TRANSACTION_SUCCESS,
  REQUEST_CONFIRM_CARD_PAYMENT_TRANSACTION, REQUEST_CREATE_CARD_PAYMENT_TRANSACTION_RECORD, 
  CREATE_CARD_PAYMENT_TRANSACTION_RECORD_SUCCESS
} from "../../actions/payouts/RechargePayoutActions";

const INIT_STATE ={
  path:'',
  account:'',
  checkout:'',
  mobile:'',
  type:'',
  referenceNumber:''
};

export default (state = INIT_STATE, action)=>{
  switch (action.type){
    case SEND_SUBSCRIPTION_REQUEST_SUCCESS:
      return {...state,
        checkout:action.payload.checkoutid,
      }
    case CONFIRM_SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        path:'',
      };
    case SEND_REQUEST_SUCCESS:
     return{
       ...state,
       path:action.payload.path,
       account:action.payload.account,
       checkout:action.payload.checkoutid,
       mobile:action.payload.mobile,
       type:action.payload.type
     };
    case CONFIRM_PAYMENT_SUCCESS:
      return {
        path:'',
      };
    case REQUEST_CONFIRM_CARD_PAYMENT_TRANSACTION:
      return {
        ...state,
        requestingConfirmation: true,
        paymentConfirmed: false
      };
    case CONFIRM_CARD_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        paymentConfirmed: true,
        requestingConfirmation: false
      };
    case REQUEST_CREATE_CARD_PAYMENT_TRANSACTION_RECORD:
      return {
        ...state,
        requestingPaymentReference: true
      };
    case CREATE_CARD_PAYMENT_TRANSACTION_RECORD_SUCCESS:
      return {
        ...state,
        referenceNumber: action.payload.reference_number,
        requestingPaymentReference: false
      };
    default:
      return state;
  }
}
