export const FETCH_DETECTION_MODES = "FETCH_DETECTION_MODES";
export const FETCH_DETECTION_MODES_SUCCESS = "FETCH_DETECTION_MODES_SUCCESS";
export const SHOW_DETECTION_MODE_ENABLE_FORM = 'SHOW_DETECTION_MODE_ENABLE_FORM';
export const HIDE_DETECTION_MODE_ENABLE_FORM = 'HIDE_DETECTION_MODE_ENABLE_FORM';
export const ENABLE_DETECTION_MODE = 'ENABLE_DETECTION_MODE';
export const ENABLE_DETECTION_MODE_SUCCESS = 'ENABLE_DETECTION_MODE_SUCCESS';
export const SHOW_DETECTION_MODE_DISABLE_FORM = 'SHOW_DETECTION_MODE_DISABLE_FORM';
export const HIDE_DETECTION_MODE_DISABLE_FORM = 'HIDE_DETECTION_MODE_DISABLE_FORM';
export const DISABLE_DETECTION_MODE = 'DISABLE_DETECTION_MODE';
export const DISABLE_DETECTION_MODE_SUCCESS = 'DISABLE_DETECTION_MODE_SUCCESS';

export const fetchDetectionModesGenerator = (data=[])=>{
    return {
        type:FETCH_DETECTION_MODES,
        payload:data
    };
};

export const fetchDetectionModesSuccessGenerator = (dataById)=> {
    return {
        type:FETCH_DETECTION_MODES_SUCCESS,
        payload:dataById
    }
};

export const showDetectionModeEnableFormGenerator = ()=>{
    return {
        type:SHOW_DETECTION_MODE_ENABLE_FORM
    };
};
export const hideDetectionModeEnableFormGenerator = ()=>{
    return {
        type:HIDE_DETECTION_MODE_ENABLE_FORM
    };
};
export const enableDetectionModeGenerator = (data)=>{
    return {
        type: ENABLE_DETECTION_MODE,
        payload: data
    }
};

export const enableDetectionModeSuccessGenerator = (data)=>{
    return {
        type: ENABLE_DETECTION_MODE_SUCCESS,
        payload: data
    }
};
export const showDetectionModeDisableFormGenerator = (data)=>{
    return {
        type:SHOW_DETECTION_MODE_DISABLE_FORM,
        payload:data
    };
};
export const hideDetectionModeDisableFormGenerator = ()=>{
    return {
        type:HIDE_DETECTION_MODE_DISABLE_FORM
    };
};
export const disableDetectionModeGenerator = (data)=>{
    return {
        type: DISABLE_DETECTION_MODE,
        payload: data
    }
};
export const disableDetectionModeSuccessGenerator = (data)=>{
    return {
        type: DISABLE_DETECTION_MODE_SUCCESS,
        payload: data
    }
};
