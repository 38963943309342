import {
    EDIT_WITHHOLDING_TAX_SUCCESS,
    FETCH_WITHHOLDING_TAX_SUCCESS,
    HIDE_WITHHOLDING_TAX_EDIT_FORM,
    SHOW_WITHHOLDING_TAX_EDIT_FORM,
    ADD_WITHHOLDING_TAX_SUCCESS,
    SHOW_WITHHOLDING_TAX_ADD_FORM,
    HIDE_WITHHOLDING_TAX_ADD_FORM,
    DELETE_WITHHOLDING_TAX_SUCCESS,
    BULK_WITHHOLDING_TAX_SUCCESS
  } from '../../actions/common/WithholdingTaxActions';
  
  const INIT_STATE = {
    withholdingTaxByID:{},
    withholdingTaxToEdit:[],
    showWithholdingTaxEditForm:false,
    showWithholdingTaxAddForm:false,
  };
  
  export default (state = INIT_STATE, action)=>{
    let withholding_tax;
    switch (action.type){
      case  ADD_WITHHOLDING_TAX_SUCCESS:
      case EDIT_WITHHOLDING_TAX_SUCCESS:
        withholding_tax = {...state.withholdingTaxByID};
        withholding_tax[action.payload.id]=action.payload;
        return {
          ...state,
          withholdingTaxByID:withholding_tax
        };
      case FETCH_WITHHOLDING_TAX_SUCCESS:
        return {
          ...state,
          withholdingTaxByID:action.payload
        };
  
      case SHOW_WITHHOLDING_TAX_EDIT_FORM:
        return {
          ...state,
          withholdingTaxToEdit:action.payload,
          showWithholdingTaxEditForm:true
        };
      case HIDE_WITHHOLDING_TAX_EDIT_FORM:
        return {
          ...state,
          showWithholdingTaxEditForm:false
        };
        case SHOW_WITHHOLDING_TAX_ADD_FORM:
            return {
                ...state,
                showWithholdingTaxAddForm:true
            };
        case HIDE_WITHHOLDING_TAX_ADD_FORM:
            return {
                ...state,
                showWithholdingTaxAddForm:false
            };
        case DELETE_WITHHOLDING_TAX_SUCCESS:
        let withholdingTaxByID = {...state.withholdingTaxByID};
        delete withholdingTaxByID[action.payload.id];
        return {
            ...state,
            withholdingTaxByID:withholdingTaxByID
        };
        case BULK_WITHHOLDING_TAX_SUCCESS:
        return {
            ...state,
            withholdingTaxByID: action.payload
        };
      default:
        return {
          ...state
        };
    }
  }
  