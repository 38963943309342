import {FETCH_LEAVES_DASHBOARD_SUCCESS} from '../../actions/payroll/LeaveDashboardActions';

const INIT_STATE={
    dashboardLeavesByID:[],
  leaves:0,
  activeLeaves:0,
  inactiveLeaves:0,
  leaveData:[["Month","Leaves"]],
  newLeaves:0,
  rejectedLeaves:0,
  approvedLeaves:0,
};
export default (state=INIT_STATE,action)=>{
    switch (action.type){
        case FETCH_LEAVES_DASHBOARD_SUCCESS:
            return{
              ...state,
                dashboardLeavesByID:action.payload,
                leaves:action.payload.leaves,
              activeLeaves:action.payload.active,
              inactiveLeaves:action.payload.inactive,
              leaveData:action.payload.leave_per_month,
              newLeaves:action.payload.new,
              rejectedLeaves:action.payload.rejected,
              approvedLeaves:action.payload.approved,
            };
        default:
          return state;
    }
}
