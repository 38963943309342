import {
  ADD_IMPREST_SUCCESS,
  DELETE_IMPREST_SUCCESS,
  EDIT_IMPREST_SUCCESS,
  FETCH_IMPREST_SUCCESS,
  HIDE_IMPREST_CREATE_FORM,
  HIDE_IMPREST_DELETE_FORM,
  HIDE_IMPREST_EDIT_FORM,
  SHOW_IMPREST_CREATE_FORM,
  SHOW_IMPREST_DELETE_FORM,
  SHOW_IMPREST_EDIT_FORM
} from '../../actions/payroll/ImprestActions';

const INIT_STATE = {
  imprestByID:{},
  showImprestCreateForm:false,
  showImprestEditForm:false,
  imprestToEdit:false,
  showImprestDeleteForm:false,
  imprestToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_IMPREST_SUCCESS:
      return {
        ...state,
        imprestByID: action.payload
      };
    case SHOW_IMPREST_CREATE_FORM:
      return {
        ...state,
        showImprestCreateForm: true
      };
    case HIDE_IMPREST_CREATE_FORM:
      return {
        ...state,
        showImprestCreateForm: false
      };
    case SHOW_IMPREST_EDIT_FORM:
      return {
        ...state,
        showImprestEditForm: true,
        imprestToEdit: action.payload
      };
    case HIDE_IMPREST_EDIT_FORM:
      return {
        ...state,
        showImprestEditForm: false
      };
    case ADD_IMPREST_SUCCESS:
    case EDIT_IMPREST_SUCCESS:
      let imprest_id = action.payload.id;
      return {
        ...state,
        imprestByID: {
          ...state.imprestByID,
          [imprest_id]:action.payload
        }
      };
    case SHOW_IMPREST_DELETE_FORM:
      return {
        ...state,
        showImprestDeleteForm: true,
        imprestToDelete: action.payload
      };
    case HIDE_IMPREST_DELETE_FORM:
      return {
        ...state,
        showImprestDeleteForm: false
      };
    case DELETE_IMPREST_SUCCESS:
      let imprestByID = {...state.imprestByID};
      delete imprestByID[action.payload.id];
      return {
        ...state,
        imprestByID:imprestByID
      };
    default:
      return state;
  }
}
