import {
  CONFIRM_TOPUP_TRANSACTION_SUCCESS, FETCH_DAILY_RECONCILIATION_SUCCESS,
  FETCH_TRANSACTION_DETAILS_SUCCESS,
  FETCH_TRANSACTIONS_SUCCESS, TRANSACTION_CUSTOM_FILTERS, TRANSACTION_FILTERS
} from "../../actions/payouts/TransactionActions";
import { FETCH_TRANSACTION_ACCOUNTS_SUCCESS } from "../../actions/admin/CompanyActions";

const INIT_STATE = {
  transactionsByID:[],
  transactionDetailsByID:{},
  unclearedAmount:0,
  dailyReconciliation:{},
  transactionAccounts:[],
  transactionFilters:[],
  transactionCustomFilters:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case TRANSACTION_FILTERS:
      return {
        ...state,
        transactionFilters: action.payload
      };
    case TRANSACTION_CUSTOM_FILTERS:
      return {
        ...state,
        transactionCustomFilters: action.payload
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsByID: action.payload.transactions ,
        unclearedAmount: action.payload.uncleared
      };
    case FETCH_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        transactionDetailsByID: action.payload
      };
    case CONFIRM_TOPUP_TRANSACTION_SUCCESS:
      let transactions = {...state.transactionDetailsByID};
      transactions[action.payload.id]=action.payload;
      return {
        ...state,
        transactionDetailsByID:transactions
      };
    case FETCH_DAILY_RECONCILIATION_SUCCESS:
      return {
        ...state,
        dailyReconciliation:action.payload
      };
    case FETCH_TRANSACTION_ACCOUNTS_SUCCESS:
      return {
        ...state,
        transactionAccounts:action.payload
      };
    default:
      return state;
  }
}
