import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isIOS, isMobile } from "react-device-detect";
import Sidebar from "../containers/SideNav/index";
import Footer from "../containers/Footer";
import LoadingComponent from "../containers/Loading";
import Loadable from "react-loadable";
import Header from "../containers/Header";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "../constants/ActionTypes";
import { Helpers } from "../util/Helpers";
const errorFeatureDenied = import("../components/ErrorFeatureDenied");
const permissionDeniedImport = import("../components/ErrorPermissionDenied");

const FeaturesActivation = Loadable({
  loader:() =>{
    return import("../containers/admin/features");
  },
  loading: LoadingComponent
});
const MarketLendingRate = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.employee.view")?import("../containers/payroll/fridgeBenefit/marketRate"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const InsurancePremium = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.employee.view")?import("../containers/payroll/insurancePremium/insurancePremium"):permissionDeniedImport;
  },
  loading:LoadingComponent
});
const EmployeesBasedQrCodes = Loadable({
  loader: () => {
    return import("../containers/admin/QRCodeGeneration/EmployeesBasedQrCodes");
  },
  loading: LoadingComponent
});
const EmployeeInvoices = Loadable({
  loader: () => {
    return import("../containers/payroll/employeeInvoices/index");
  },
  loading: LoadingComponent
});const DailyReconciliation = Loadable({
  loader: () => {
    return Helpers.authUserCan("admin.cash.transactions.view")?import("../containers/admin/reconciliation/Reconciliation"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const uploadEmployeeNoEdit = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.employee.view")?import("../containers/payroll/employees/uploadEmployeeNoEdit"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const uploadEmployeeEdit = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.employee.view")?import("../containers/payroll/employees/uploadEmployeeEdit"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const EmployeeAppraisal = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/employeeAppraisals"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SelfAppraise = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/appraise/self"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DoAppraisal = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/appraise/index"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DepartmentKpi = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/departmentKpi"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Pip = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/pip"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const EmployeeKpi = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/employeeKpi"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AppraisalDashboard = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/appraisalDashboard"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const KeyResultAreas = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/keyResultAreas"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PerformanceRating = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/performanceRating"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const ReviewPeriod = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/reviewPeriod"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Appraisal = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() ||
      Helpers.authUserCan("hrm.employee.view"))?import("../containers/appraisals/index"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Error404 = Loadable({
  loader: () => import("../components/Error404"),
  loading: LoadingComponent
});
const Home = Loadable({
  loader: () => import("../containers/home"),
  loading: LoadingComponent
});
const RunPayroll = Loadable({
  loader: () => {
    if(!Helpers.planCan("hrm.payroll.run")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.payroll.run")? import("../containers/payroll/runPayroll"): permissionDeniedImport;
  },
  loading: LoadingComponent
});
const JobTitleIndex = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.settings.access_hrm")
    ? import("../containers/payroll/jobTitles"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Holidays = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.holiday.view","global.holiday.add","global.holiday.edit","global.holiday.delete"])
    ? import("../containers/payroll/holidays") : permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AdminHolidays = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.holiday.view","global.holiday.add","global.holiday.edit","global.holiday.delete"])
    ? import("../containers/admin/holidays") : permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DeductionCategories = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.settings.access_payroll")?import("../containers/payroll/deductionCategories"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DocumentCategories = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.document.view","hrm.document.add","hrm.document.edit","hrm.document.delete"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.document.view","hrm.document.add","hrm.document.edit","hrm.document.delete"])?import("../containers/payroll/documentCategories"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const EmployeePaymentRate = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.settings.access_employee_payment_rate")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.settings.access_employee_payment_rate")?import("../containers/payroll/employeePaymentRate"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SalaryHistory = Loadable({
  loader: () => {
    return import("../containers/payroll/employeePaymentRate/salaryHistory")
  },
  loading: LoadingComponent
});
const Departments = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.settings.access_hrm")?import("../containers/payroll/departments"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Leaves = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"]))
    ? import("../containers/payroll/leaves") : permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LeavesPortal = Loadable({
  loader: () => {
    if( !Helpers.planCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"]) ){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ? import("../containers/payroll/leaves") : permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PaidLeaves = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])
    ? import("../containers/payroll/paidLeaves") : permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Deductions = Loadable({
  loader: () => {
    return Helpers.authUserCan(["hrm.deduction.view", "hrm.deduction.add","hrm.deduction.edit","hrm.deduction.delete"])
    ? import("../containers/payroll/deductions"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Savings = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.saving.view","hrm.saving.add","hrm.saving.edit","hrm.saving.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.saving.view","hrm.saving.add","hrm.saving.edit","hrm.saving.delete"]))
    ? import("../containers/payroll/savings"):permissionDeniedImport
  },
  loading: LoadingComponent
});
const SavingsPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.saving.view","hrm.saving.add","hrm.saving.edit","hrm.saving.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ? import("../containers/payroll/savings"):permissionDeniedImport
  },
  loading: LoadingComponent
});
const Assets = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.asset.view","hrm.asset.add","hrm.asset.edit","hrm.asset.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.asset.view","hrm.asset.add","hrm.asset.edit","hrm.asset.delete"]))
    ? import("../containers/payroll/assets"):permissionDeniedImport;
  },
  loading: LoadingComponent
});

const AssetsCategories = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.asset.view","hrm.asset.add","hrm.asset.edit","hrm.asset.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.asset.view","hrm.asset.add","hrm.asset.edit","hrm.asset.delete"]))
    ? import("../containers/payroll/assets/assetsCategories"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AssetsPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.asset.view","hrm.asset.add","hrm.asset.edit","hrm.asset.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ? import("../containers/payroll/assets"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Withdrawals = Loadable({
  loader: () => {
    return Helpers.authUserCan(["hrm.withdrawal.view","hrm.withdrawal.add","hrm.withdrawal.edit","hrm.withdrawal.delete"])
    ? import("../containers/payroll/withdrawals"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const ExitReasons = Loadable({
  loader: () => {
    return Helpers.authUserCan(["hrm.exit.view","hrm.exit.add","hrm.exit.edit","hrm.exit.delete","hrm.exit.certify","hrm.exit.approve"])?import("../containers/payroll/exitReasons"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const EmployeeExits = Loadable({
  loader: () => {
    return import("../containers/payroll/employeeExits")
  },
  loading: LoadingComponent
});
const Users = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.user.view","global.user.invite","global.user.activate","global.user.assign_role","global.user.remove"])?import("../containers/payroll/users"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CompanyPaymentRate = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.settings.access_company_payment_rate"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan("attendance.settings.access_company_payment_rate") && Helpers.authUserCan(["hrm.exit.view","hrm.exit.add","hrm.exit.edit","hrm.exit.delete","hrm.exit.certify","hrm.exit.approve"]))?
      import("../containers/payroll/companyPaymentRate"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const TopNav = Loadable({
  loader: () => import("../containers/TopNav"),
  loading: LoadingComponent
});
const Branches = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.branch.view","global.branch.add","global.branch.edit","global.branch.delete"])
    ? import("../containers/payroll/branches"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Companies = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.branch.view","global.branch.add","global.branch.edit","global.branch.delete"])
    ? import("../containers/payroll/companies"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Documents = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.document.view","hrm.document.add","hrm.document.edit","hrm.document.delete","hrm.employee.view"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.document.view","hrm.document.add","hrm.document.edit","hrm.document.delete","hrm.employee.view"]))
    ? import("../containers/payroll/documents"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DocumentsPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.document.view","hrm.document.add","hrm.document.edit","hrm.document.delete","hrm.employee.view"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ? import("../containers/payroll/documents"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Loans = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.loan.view","hrm.loan.add","hrm.loan.edit","hrm.loan.activate","hrm.loan.deactivate","hrm.loan.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.loan.view","hrm.loan.add","hrm.loan.edit","hrm.loan.activate","hrm.loan.deactivate","hrm.loan.delete"]))
    ? import("../containers/payroll/loans"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const MyLoans = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.financing.loans.view", "hrm.payroll.financing.loans.add", "hrm.payroll.financing.loans.edit", "hrm.payroll.financing.loans.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.payroll.financing.loans.view", "hrm.payroll.financing.loans.add", "hrm.payroll.financing.loans.edit", "hrm.payroll.financing.loans.delete"]))
      ? import("../containers/payroll/loans/myLoans"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LoansPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.loan.view","hrm.loan.add","hrm.loan.edit","hrm.loan.activate","hrm.loan.deactivate","hrm.loan.delete"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ? import("../containers/payroll/loans") : permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LoanCategories = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.loan.view","hrm.loan.add","hrm.loan.edit","hrm.loan.activate","hrm.loan.deactivate","hrm.loan.delete"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.settings.access_payroll")
    ? import("../containers/payroll/loans/categories") :permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Allowances = Loadable({
  loader: () => {
    return (Helpers.authUserCan("hrm.allowance.view") ||
      Helpers.authUserCan("hrm.allowance.add") ||
      Helpers.authUserCan("hrm.allowance.edit") ||
      Helpers.authUserCan("hrm.allowance.delete")) ? import("../containers/payroll/allowance") : permissionDeniedImport;
  },
  loading: LoadingComponent
});

const AllowanceCategories = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.settings.access_payroll")?import("../containers/payroll/allowance/categories"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SalaryAdvance = Loadable({
  loader: () => {
    return (Helpers.authUserCan(["hrm.salary_advance.view","hrm.salary_advance.add","hrm.salary_advance.edit","hrm.salary_advance.delete","hrm.salary_advance.certify","hrm.salary_advance.approve"]))?
      import("../containers/payroll/salaryAdvance"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SalaryAdvancePortal = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal())?
      import("../containers/payroll/salaryAdvance"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Reports = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])
    ? import("../containers/payroll/reports"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const EmployeeNoFormats = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.settings.access_hrm")?import("../containers/payroll/employeeNoFormats"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PensionRates = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.settings.pension_rate")
    ? import("../containers/payroll/pensionRates"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PayrollBatches = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])?import("../containers/payroll/payrollBatches"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PettyCash = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"]))
    ?import("../containers/payroll/pettyCash"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PettyCashPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ?import("../containers/payroll/pettyCash"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PettyCashAccount = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])
    ?import("../containers/payroll/pettyCash/pettyCashAccount"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PettyCashCategories = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])
    ? import("../containers/payroll/pettyCash/pettyCashCategories"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PettyCashSubCategories = Loadable({
  loader: () =>{
      if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
        return errorFeatureDenied;
      }
      return Helpers.authUserCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])
      ? import("../containers/payroll/pettyCash/pettyCashSubCategories"):permissionDeniedImport;
    },
  loading: LoadingComponent
});
const PettyCashReceipts = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"]))
    ? import("../containers/payroll/pettyCash/pettyCashReceipts"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PettyCashReceiptsPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.petty_cash.view","hrm.petty_cash.add","hrm.petty_cash.edit","hrm.petty_cash.delete","hrm.petty_cash.certify","hrm.petty_cash.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())
      ? import("../containers/payroll/pettyCash/pettyCashReceipts"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Notifications = Loadable({
  loader: () => import("../containers/payroll/notifications"),
  loading: LoadingComponent
});
const MortgageInterestPayments = Loadable({
  loader: () => import("../containers/payroll/mortgageInterestPayments"),
  loading: LoadingComponent
});
const PayrollDashboard = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.dashboard.view")? import("../containers/payroll/dashboard"): permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LeavesDashboard = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view", "hrm.leave.add", "hrm.leave.edit", "hrm.leave.certify", "hrm.leave.approve", "hrm.leave.delete"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.leave.view", "hrm.leave.add", "hrm.leave.edit", "hrm.leave.certify", "hrm.leave.approve", "hrm.leave.delete"])
      ? import("../containers/payroll/leaves/dashboard"): permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LeaveBalances = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view", "hrm.leave.add", "hrm.leave.edit", "hrm.leave.certify", "hrm.leave.approve", "hrm.leave.delete"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve","hrm.leave_balance.view","hrm.leave_balance.add","hrm.leave_balance.edit","hrm.leave_balance.delete"])
      ?import("../containers/payroll/leaves/balances"): permissionDeniedImport;
    },
  loading: LoadingComponent
});
const LeaveBalancesReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view", "hrm.leave.add", "hrm.leave.edit", "hrm.leave.certify", "hrm.leave.approve", "hrm.leave.delete"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_leave_balance_report")?import("../containers/payroll/reports/leaveBalancesReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LeaveCategories = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])
    ? import("../containers/payroll/leaves/categories")  :permissionDeniedImport;
  },
  loading: LoadingComponent
});
const MusterRoll = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_muster_roll_report")?import("../containers/payroll/musterRoll"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const QueuedMusterRoll = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_muster_roll_report")?import("../containers/payroll/musterRoll/queuedMusterRoll"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const QueuedPayslips = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_muster_roll_report")?import("../containers/payroll/payslips/queuedPayslips"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const QueuedPayrolls = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])?
      import("../containers/payroll/payrollBatches/queuedPayrolls"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PensionReport = Loadable({
  loader: () => {
    return import("../containers/payroll/reports/pensionReport")
  },
  loading: LoadingComponent
});
const AllowanceLedger = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.report.view_allowance_ledger_report")?import("../containers/payroll/allowanceLedger"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SalaryAdvanceReport = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.report.view_advance_salary_report")?import("../containers/payroll/reports/salaryAdvanceReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const NssfReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_nssf_report")?import("../containers/payroll/reports/nssfReport/nssfReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const NhifReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_nhif_report")?import("../containers/payroll/reports/nhifReport/nhifReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PayeReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_paye_report")?import("../containers/payroll/reports/payeReport/payeReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const LoanRepaymentReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.loan.view","hrm.loan.add","hrm.loan.edit","hrm.loan.activate","hrm.loan.deactivate","hrm.loan.delete"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_loan_repayment_report")?import("../containers/payroll/reports/loanRepaymentReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const NetPayReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_net_pay_report")?import("../containers/payroll/reports/netPayReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AuditLogReport = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.report.view_audit_log_report")?import("../containers/payroll/reports/auditLogReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DeductionLedger = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.report.view_deduction_ledger_report")?import("../containers/payroll/reports/deductionLedger"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const WagesReport = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.report.view_wages_report")?import("../containers/timeAndAttendance/reports/wagesReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Employees = Loadable({
  loader: () => {
    return Helpers.authUserCan(["hrm.employee.view","hrm.employee.add","hrm.employee.invite","hrm.employee.set_status","hrm.employee.edit","hrm.employee.delete"])?
      import("../containers/payroll/employees"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Roles = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.role.view","global.role.add","global.role.edit","global.role.delete"])?import("../containers/payroll/roles"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CreateRole = Loadable({
  loader: () => {
    return Helpers.authUserCan(["global.role.view","global.role.add","global.role.edit","global.role.delete",
    "admin.administrators.view","admin.administrators.edit","admin.administrators.delete"])?import("../containers/payroll/roles/createRole"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SMS = Loadable({
  loader: () => {
    return import("../containers/payroll/sms")
  },
  loading: LoadingComponent
});
const NewText = Loadable({
  loader: () => {
    return import("../containers/payroll/sms/sendNewText")
  },
  loading: LoadingComponent
});
const Payslips = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.payroll.payslip.view"]))?import("../containers/payroll/payslips"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PayslipsPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal() || Helpers.authUserCan(["hrm.payroll.payslip.view"]))?import("../containers/payroll/payslips"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Fingerprints = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.fingerprints.view")?import("../containers/timeAndAttendance/fingerprints"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AttendanceEmployees = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.employee.view","hrm.employee.add","hrm.employee.invite","hrm.employee.set_status","hrm.employee.edit","hrm.employee.delete"])?
      import("../containers/timeAndAttendance/employees"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Checkpoints = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return import("../containers/timeAndAttendance/checkpoints")
  },
  loading: LoadingComponent
});
const BlacklistedEmployees = Loadable({
  loader: () => {
    import("../containers/timeAndAttendance/projects/blacklistedEmployees")
  },
  loading: LoadingComponent
});
const Shifts = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.schedule.view","attendance.schedule.add","attendance.schedule.edit","attendance.schedule.assign","attendance.schedule.delete"])?
      import("../containers/timeAndAttendance/shifts"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const ShiftWorkingHours = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.schedule.view","attendance.schedule.add","attendance.schedule.edit","attendance.schedule.assign","attendance.schedule.delete"])?
      import("../containers/timeAndAttendance/shifts/shiftWorkingHours"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const QueuedReports = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.report.view_hours_report","attendance.report.view_wages_report","attendance.report.view_hours_and_wages_report","attendance.report.view_attendance_report"])?
      import("../containers/timeAndAttendance/reports/allQueuedReports"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const HoursReport = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.report.view_hours_report")?import("../containers/timeAndAttendance/reports/hoursReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CustomHours = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.attendance.view")?import("../containers/timeAndAttendance/reports/customHours"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Teams = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.attendance.view")?import("../containers/timeAndAttendance/reports/teams"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const HoursWagesReport = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.report.view_wages_report")?import("../containers/timeAndAttendance/reports/hoursWagesReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const TAWagesReport = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.report.view_wages_report")?import("../containers/timeAndAttendance/reports/wagesReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AttendanceReport = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.report.view_attendance_report")?
      import("../containers/timeAndAttendance/reports/attendance"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
/*const GuardAttendanceReport = Loadable({
  loader: () => {
    return Helpers.authUserCan("attendance.report.view_attendance_report")?import("../containers/timeAndAttendance/reports/guardAttendanceReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});*/
const GuardRegisterReport = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.report.view_attendance_report")?import("../containers/timeAndAttendance/reports/guardRegisterReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SnapChecks = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.snap_check.view","attendance.snap_check.add","attendance.snap_check.edit","attendance.snap_check.delete"])?
      import("../containers/timeAndAttendance/snapChecks"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Keys = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.settings.access_branch_api_key","attendance.settings.access_branch_pass_key","attendance.settings.access_multi_company_api_key","attendance.settings.access_multi_company_pass_key"])?
      import("../containers/timeAndAttendance/keys"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const TADashboard = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.dashboard.view")?import("../containers/timeAndAttendance/dashboard"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
/*const Scheduling = Loadable({
  loader: () => {
    return Helpers.authUserCan("attendance.report.view_scheduling_report")?import("../containers/timeAndAttendance/reports/scheduling"):permissionDeniedImport;
  },
  loading: LoadingComponent
});*/
/*const GuardScheduling = Loadable({
  loader: () => {
    return Helpers.authUserCan("attendance.report.view_scheduling_report")?import("../containers/timeAndAttendance/reports/guardSchedulingReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});*/
/*const AttendanceDayDetails = Loadable({
  loader: () => {return Helpers.authUserCan(["attendance.attendance.view","attendance.attendance.manual_check","attendance.attendance.edit","attendance.attendance.approve",
    "attendance.attendance.authorise_absence","attendance.attendance.delete"])?
    import("../containers/timeAndAttendance/attendanceDayDetails"):permissionDeniedImport;
  },
  loading: LoadingComponent
});*/
const DailyOvertime = Loadable({
  loader:()=>{
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }

    return Helpers.authUserCan(["attendance.attendance.view","attendance.attendance.manual_check","attendance.attendance.edit","attendance.attendance.approve",
      "attendance.attendance.authorise_absence","attendance.attendance.delete"])?
        import("../containers/timeAndAttendance/dailyOvertime"):permissionDeniedImport;
},
  loading: LoadingComponent
});
const Projects = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.project.view","attendance.project.add","attendance.project.edit","attendance.project.assign","attendance.project.delete"])?
      import("../containers/timeAndAttendance/projects"):permissionDeniedImport;
  },
  loading: LoadingComponent
});

const CreateProject = Loadable({
  loader: () => {
    if( !Helpers.planCan("attendance.dashboard.view") ){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.project.add")?import("../containers/timeAndAttendance/projects/createProject"):permissionDeniedImport;
  },
  loading: LoadingComponent
});

const ProjectAssignEmployees = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.project.assign")?import("../containers/timeAndAttendance/projects/assignedEmployees"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const ScheduleAssignEmployees = Loadable({
  loader: () => {
    if(!Helpers.planCan("attendance.dashboard.view")){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("attendance.schedule.assign")?import("../containers/timeAndAttendance/schedules/assignEmployees"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SyncedDevices = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.synced_devices.view","attendance.synced_devices.edit","attendance.synced_devices.delete"])?
      import("../containers/timeAndAttendance/syncedDevices"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AttendanceMapping = Loadable({
  loader: () => {
    if( !Helpers.planCan(["attendance.dashboard.view"]) ){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.attendance.view","attendance.attendance.manual_check","attendance.attendance.edit","attendance.attendance.approve",
      "attendance.attendance.authorise_absence","attendance.attendance.delete"])?
      import("../containers/timeAndAttendance/attendanceMapping"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AssignmentRelievers = Loadable({
  loader: () => {
    if(!Helpers.planCan(["attendance.dashboard.view"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["attendance.project.view","attendance.project.add","attendance.project.edit","attendance.project.assign","attendance.project.delete"])?
      import("../containers/timeAndAttendance/assignmentRelievers"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const ManualWorkedDays = Loadable({
  loader: () => {
    if( !Helpers.planCan("attendance.dashboard.view") ){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(["hrm.worked_days.delete","hrm.worked_days.edit","hrm.worked_days.add","hrm.worked_days.view"])?import("../containers/timeAndAttendance/manualWorkedDays"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DynamicOffDays = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"]))?import("../containers/payroll/dynamicOffDays"):permissionDeniedImport;
    },
  loading: LoadingComponent
});
const DynamicOffDaysPortal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.leave.view","hrm.leave.add","hrm.leave.edit","hrm.leave.delete","hrm.leave.certify","hrm.leave.approve"])){
      return errorFeatureDenied;
    }
    return (Helpers.authUserCanAccessPortal())?import("../containers/payroll/dynamicOffDays"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PayoutsDashboard = Loadable({
  loader: () => {
    return Helpers.authUserCan("payouts.dashboard.view")?import("../containers/payouts/dashboard"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const TransactionHistory = Loadable({
  loader: () => {
    return Helpers.authUserCan(["payouts.wallet.view_transactions","admin.cash.transactions.view"])?import("../containers/payouts/transactionHistory"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const TransactionDetails = Loadable({
  loader: () => {
    return Helpers.authUserCan(["payouts.wallet.view_transactions","admin.cash.transactions.view"])?import("../containers/payouts/transactionDetails"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Recharge = Loadable({
  loader: () => {
    return Helpers.authUserCan("payouts.wallet.recharge")?import("../containers/payouts/recharge"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const BulkPayouts = Loadable({
  loader: () => {
    return Helpers.authUserCan("payouts.single_payout.run")?import("../containers/payouts/bulkPayouts"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Subscription = Loadable({
  loader: () => {
    return Helpers.authUserCan("payouts.wallet.subscribe")?import("../containers/subscription"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SubscriptionPayment = Loadable({
  loader: () => {
    return Helpers.authUserCan("payouts.wallet.subscribe")?import("../containers/subscription/subscriptionMakePayment"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const EmployeeProfile = Loadable({
  loader: () => {
    return (Helpers.authUserCanAccessPortal() || Helpers.authUserCan("hrm.employee.view"))?import("../containers/profiles/employeeProfile"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const UserProfile = Loadable({
  loader: () => {
    return import("../containers/profiles/userProfile")
  },
  loading: LoadingComponent
});
const TransferEmployeeForm = Loadable({
  loader: () => {
    return Helpers.authUserCan("hrm.employee.edit")?import("../components/payroll/transferEmployee/transferEmployeeForm"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AdminDashboard = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.users_summary.view","admin.companies_summary.view","admin.mpesa_summary.view","admin.banks_summary.view","admin.top_balance_summary.view"])?
      import("../containers/admin/dashboard"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CompaniesAdmin = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.users.companies.view","admin.users.companies.edit","admin.users.companies.delete","admin.users.companies.deactivate"
      ,"admin.users.companies.view_profile","admin.users.companies.qr_code"])?
      import("../containers/admin/companies"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
// const CompanyPayments = Loadable({
//   loader: () => {
//     return Helpers.authUserCan(["global.settings.view","global.settings.edit"])?
//       import("../containers/admin/companies/paymentActivations"):permissionDeniedImport;
//   },
//   loading: LoadingComponent
// });
const CompaniesWithSenderIDs = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.users.sender_ids.view","admin.users.sender_ids.approve","admin.users.sender_ids.reject"])?
      import("../containers/admin/companies/withSenderID"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CompaniesWithLoanRates = Loadable({
  loader: () => {
    return import("../containers/admin/companies/withLoanRates")
  },
  loading: LoadingComponent
});
const CompaniesWithAppraisal = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.users.appraisals.view","admin.users.appraisals.add","admin.users.appraisals.edit","admin.users.appraisals.delete"])?
      import("../containers/admin/companies/withAppraisal"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CompaniesWithLoans = Loadable({
  loader: () => {
    return import("../containers/admin/companies/withLoans")
  },
  loading: LoadingComponent
});
const CompanyProfile = Loadable({
  loader: () => {
    return Helpers.authUserCan("admin.users.companies.view_profile")?import("../containers/admin/companies/companyProfile"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AllTransaction = Loadable({
  loader: () => {
    return Helpers.authUserCan("admin.cash.transactions.view")?import("../containers/admin/AllTransactions"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const BankPayments = Loadable({
  loader: () => {
    return import("../containers/admin/AllTransactions/bankPayments")
  },
  loading: LoadingComponent
});
const Pricing = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.accounts.pricing.view","admin.accounts.pricing.add","admin.accounts.pricing.edit"])?import("../containers/admin/pricing"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const Admins = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.administrators.view","admin.administrators.edit","admin.administrators.delete"])?import("../containers/admin/admins"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SystemUsers = Loadable({
  loader: () => {
    return Helpers.authUserCan(["admin.users.view","admin.users.edit","admin.users.delete","admin.users.deactivate"])?import("../containers/admin/systemUsers"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const AdminSubscription = Loadable({
  loader: () => {
    return Helpers.authUserCan("admin.users.company_owners.subscriptions")?import("../containers/admin/subscription"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const CompaniesLoansRepayment = Loadable({
  loader: () => {
    return import("../containers/admin/loans/loanRepayment")
  },
  loading: LoadingComponent
});
const MpesaSettings = Loadable({
  loader: () => {
    return Helpers.authUserCan("admin.cash.mpesa.view_settings")?import("../containers/admin/mpesasettings"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PayoutsChargeRates = Loadable({
  loader: () => {
    return  Helpers.authUserCan(["admin.cash.global_rates.view","admin.cash.global_rates.add","admin.cash.global_rates.edit","admin.cash.global_rates.delete"])?
      import("../containers/admin/payoutsChargeRates"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const BankDetails = Loadable({
  loader: () => {
    return  Helpers.authUserCan(["admin.banks.view","admin.banks.add","admin.banks.edit","admin.banks.delete","admin.banks.branches.view","admin.banks.branches.add",
      "admin.banks.branches.edit","admin.banks.branches.delete","payouts.recipient.add"])?import("../containers/banks"):permissionDeniedImport;
  },
  loading: LoadingComponent
});

const SalaryAdvanceSettings = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvances/setting");
  },
  loading: LoadingComponent
});

const AdminSalaryAdvances = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvances");
  },
  loading: LoadingComponent
});

const SystemSettings = Loadable({
  loader: () => {
    return Helpers.authUserCan("global.settings.view")
      ? import("../containers/systemSettings"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const QRCodeGeneration = Loadable({
  loader: () => {
    return import("../containers/admin/QRCodeGeneration")
  },
  loading: LoadingComponent
});
const InsuranceProvident = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }

    return Helpers.authUserCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])?
        import("../containers/payroll/reports/insuranceProvident"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const PayrollJournal = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }

    return Helpers.authUserCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])?
        import("../containers/payroll/reports/payrollJournal"):permissionDeniedImport;
  },
  loading: LoadingComponent
});

const LoanRequests = Loadable({
  loader: () => {
    return import("../containers/admin/loans/loanRequests")
  },
  loading: LoadingComponent
});

const LendLoan = Loadable({
  loader: () => {
    return import("../components/admin/loans/loanRequests/lendLoan")
  },
  loading: LoadingComponent
});

const LoanLenders = Loadable({
  loader: () => {
    return import("../containers/admin/loans/loanLenders")
  },
  loading: LoadingComponent
});

const LoanReports = Loadable({
  loader: () => import("../containers/admin/loans/loanReports"),
  loading: LoadingComponent
});

const PaymentReport = Loadable({
  loader: () => import("../containers/admin/loans/loanReports/paymentReport"),
  loading: LoadingComponent
});

const AccruedInterestReport = Loadable({
  loader: () => import("../containers/admin/loans/loanReports/accruedInterestReport"),
  loading: LoadingComponent
});

const PenaltiesFeesReport = Loadable({
  loader: () => import("../containers/admin/loans/loanReports/penaltiesFeesReport"),
  loading: LoadingComponent
});

const CustomerStatement = Loadable({
  loader: () => import("../containers/admin/loans/customerStatement"),
  loading: LoadingComponent
});

const LoansDashboard = Loadable({
  loader: () => import("../containers/admin/loans/dashboard"),
  loading: LoadingComponent
});

const LenderWalletTransactions = Loadable({
  loader: () => import("../containers/admin/loans/loanLenders/lenderWalletTransactions"),
  loading: LoadingComponent
});

const AttendanceAttempts = Loadable({
  loader: () => import("../containers/timeAndAttendance/attendanceAttempts"),
  loading:LoadingComponent
});

const TransactionReports = Loadable({
  loader: () => import("../containers/admin/AllTransactions/report"),
  loading: LoadingComponent
});

const StatutoryPayments = Loadable({
  loader: () => {
    return import("../containers/payouts/statutoryPayments")
  },
  loading: LoadingComponent
});

const Trainings = Loadable({
  loader: () => {
    return import("../containers/payroll/trainings")
  },
  loading: LoadingComponent
});

const TrainingOnPortal = Loadable({
  loader: () => {
    return import("../containers/payroll/trainings/trainingOnPortal")
  },
  loading: LoadingComponent
});

const P10Report = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }
    return import("../containers/payroll/reports/p10Report")
  },
  loading: LoadingComponent
});
const NitaReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }
    return import("../containers/payroll/reports/NitaReport")
  },
  loading: LoadingComponent
});
const OverdueLoansReport = Loadable({
  loader: () => import("../containers/admin/loans/loanReports/overdueLoansReport"),
  loading: LoadingComponent
});
const AdminSettings = Loadable({
  loader: () => import("../containers/systemSettings/adminSettings"),
  loading:LoadingComponent
});

const Products = Loadable({
  loader: () => {
    return import("../containers/payroll/products")
  },
  loading: LoadingComponent
});

const MobileNotifications = Loadable({
  loader: () => import("../containers/admin/mobileNotifications"),
  loading: LoadingComponent
});

const LeavesAttended = Loadable({
  loader: () => {
    if(!Helpers.planCan(['hrm.leave.view', 'hrm.leave.add', 'hrm.leave.edit', 'hrm.leave.certify', 'hrm.leave.approve', 'hrm.leave.delete', 'hrm.report.view_leave_balance_report'])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan(['hrm.leave.view', 'hrm.leave.add', 'hrm.leave.edit', 'hrm.leave.certify', 'hrm.leave.approve', 'hrm.leave.delete', 'hrm.report.view_leave_balance_report'])?import("../containers/payroll/reports/leavesAttended"):permissionDeniedImport;
  },loading: LoadingComponent
});

const ProductsDelivered = Loadable({
  loader: () => {
    return import("../containers/payroll/products/productsDelivered")
  },
  loading: LoadingComponent
});

const PayoutsAuditTrails = Loadable({
  loader: () => {
    return Helpers.authUserCan("admin.cash.transactions.view")?import("../containers/admin/AllTransactions/auditTrails"):permissionDeniedImport
  },
  loading: LoadingComponent
});

const PayrollVariance = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_muster_roll_report")?import("../containers/payroll/reports/payrollVariance"):permissionDeniedImport;
  },loading: LoadingComponent
});

const KYC = Loadable({
  loader: () => import("../containers/kyc"),loading: LoadingComponent
});

const EmployeeRents = Loadable({
  loader: () => {
    return import("../containers/payroll/employeeRent");
  },
  loading: LoadingComponent
});

const ESOP = Loadable({
  loader: () => {
    return Helpers.authUserCan(["hrm.employee.view","hrm.employee.add","hrm.employee.invite","hrm.employee.set_status","hrm.employee.edit","hrm.employee.delete"])?
      import("../containers/payroll/esop"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const HELB = Loadable({
  loader: () => {
    return Helpers.authUserCan(["hrm.employee.view","hrm.employee.add","hrm.employee.invite","hrm.employee.set_status","hrm.employee.edit","hrm.employee.delete"])?
      import("../containers/payroll/helb"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const DefaultPayoutsChargeRates = Loadable({
  loader: () => {
    return  Helpers.authUserCan(["admin.cash.global_rates.view","admin.cash.global_rates.add","admin.cash.global_rates.edit","admin.cash.global_rates.delete"])?
      import("../containers/admin/payoutsChargeRates/DefaultChargeRates"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const P9Report = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.report.view_nssf_report","hrm.report.view_nhif_report","hrm.report.view_paye_report",
      "hrm.report.view_nssf_report","hrm.report.view_muster_roll_report","hrm.report.view_audit_log_report","hrm.report.view_advance_salary_report","hrm.report.view_net_pay_report",
      "hrm.report.view_leave_balance_report","hrm.report.view_deduction_ledger_report","hrm.report.view_allowance_ledger_report","hrm.report.view_loan_repayment_report"])){
      return errorFeatureDenied;
    }
    return import("../containers/payroll/reports/p9Report")
  },
  loading: LoadingComponent
});
const WithholdingTax = Loadable({
  loader: () => {
    return import("../containers/admin/withholdingTax");
  },
  loading: LoadingComponent
});
const HelbReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_helb_report")?import("../containers/payroll/reports/helbReport/helbReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});

const StatutoryFillingRequests = Loadable({
  loader: () => {
    return import("../containers/admin/statutoryFillingRequests/index.js")
  },
  loading: LoadingComponent
});

const WithholdingTaxReport = Loadable({
  loader: () => {
    if(!Helpers.planCan(["hrm.payroll.view","hrm.payroll.run","hrm.payroll.certify","hrm.payroll.approve","hrm.payroll.cancel","hrm.payroll.nssf.view","hrm.payroll.nhif.view",
      "hrm.payroll.taxes.view","hrm.payroll.pension.view","hrm.payroll.other_deductions.view","hrm.payroll.payslip.view","hrm.payroll.payslip.email"])){
      return errorFeatureDenied;
    }
    return Helpers.authUserCan("hrm.report.view_paye_report")?import("../containers/payroll/reports/withholdingTaxReport/withholdingTaxReport"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SubscriptionPricing = Loadable({
  loader: () => {
    return import("../containers/admin/subscriptionPricing");
  },
  loading: LoadingComponent
});
const EOR = Loadable({
  loader: () => {
    return import("../containers/admin/eor/templates");
  },
  loading: LoadingComponent
});
const SalaryAdvanceDefaultSettings = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvances/DefaultSettings");
  },
  loading: LoadingComponent
});
const SalaryAdvanceFees = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvanceFees");
  },
  loading: LoadingComponent
});
const DefaultSalaryAdvanceFees = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvanceFees/DefaultSalaryAdvanceFees");
  },
  loading: LoadingComponent
});
const CompaniesOnboarding = Loadable({
  loader: () => {
    return import("../containers/admin/onboarding");
  },
  loading: LoadingComponent
});
const CompaniesOnboardingWorkflow = Loadable({
  loader: () => {
    return import("../containers/admin/onboarding/OnboardingWorkflow");
  },
  loading: LoadingComponent
});
const ExchangeRates = Loadable({
  loader: () => {
    return  Helpers.authUserCan(["admin.cash.global_rates.view","admin.cash.global_rates.add","admin.cash.global_rates.edit","admin.cash.global_rates.delete"])?
      import("../containers/admin/exchangeRates"):permissionDeniedImport;
  },
  loading: LoadingComponent
});
const SalaryAdvanceCompanySettings = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvances/companySettings");
  },
  loading: LoadingComponent
});
const SalaryGlobalAdvanceSettings = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvances/globalSettings");
  },
  loading: LoadingComponent
});
const SalaryAdvanceLoans = Loadable({
  loader: () => {
    return import("../containers/admin/salaryAdvances/loans")
  },
  loading: LoadingComponent
});

class App extends React.Component {
  render() {
    const { match, drawerType } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "collapsible-drawer"
        : "mini-drawer";

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div className="app-header app-header-horizontal">
            {/*<CountryCurrency/>*/}
            <Header />
            <TopNav styleName="app-top-header" />
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <div className="app-wrapper">
                <Switch>
                  <Route path={`${match.url}home`} component={Home} />
                  <Route path={`${match.url}dashboard`} component={Home} />
                  <Route
                    path={`${match.url}hrm/run-payroll`}
                    component={RunPayroll}
                  />
                  <Route
                    path={`${match.url}hrm/market-Lending-rate`}
                    component={MarketLendingRate}
                  />

                  <Route
                    path={`${match.url}hrm/dashboard`}
                    component={PayrollDashboard}
                  />

                  <Route
                    path={`${match.url}leaves-dashboard`}
                    component={LeavesDashboard}
                  />
                  <Route
                    path={`${match.url}hrm/departments`}
                    component={Departments}
                  />
                  <Route
                    path={`${match.url}hrm/deduction-categories`}
                    component={DeductionCategories}
                  />
                  <Route
                    path={`${match.url}hrm/mortgage-interest-payments`}
                    component={MortgageInterestPayments}
                  />
                  <Route
                    path={`${match.url}hrm/document-categories`}
                    component={DocumentCategories}
                  />
                  <Route
                    path={`${match.url}hrm/allowance-categories`}
                    component={AllowanceCategories}
                  />

                  <Route
                    path={`${match.url}hrm/allowances`}
                    component={Allowances}
                  />
                  <Route
                    path={`${match.url}hrm/salary-advance`}
                    component={SalaryAdvance}
                  />
                  <Route
                    path={`${match.url}hrm/employee-no-formats`}
                    component={EmployeeNoFormats}
                  />
                  <Route
                    path={`${match.url}common/company-payment-rate`}
                    component={CompanyPaymentRate}
                  />
                  <Route
                    path={`${match.url}hrm/company-payment-rate`}
                    component={CompanyPaymentRate}
                  />
                  <Route
                    path={`${match.url}common/employee-payment-rate`}
                    component={EmployeePaymentRate}
                  />
                  <Route
                    path={`${match.url}hrm/employee-payment-rate`}
                    component={EmployeePaymentRate}
                  />
                  <Route
                    path={`${match.url}hrm/salary-history`}
                    component={SalaryHistory}
                  />
                  <Route
                    path={`${match.url}hrm/exit-reasons`}
                    component={ExitReasons}
                  />
                  <Route
                    path={`${match.url}hrm/employee-exits`}
                    component={EmployeeExits}
                  />
                  <Route
                    path={`${match.url}common/users`}
                    component={Users}
                  />
                  <Route
                    path={`${match.url}attendance/users`}
                    component={Users}
                  />
                  <Route
                    path={`${match.url}hrm/users`}
                    component={Users}
                  />
                  <Route
                    path={`${match.url}payouts/users`}
                    component={Users}
                  />
                  <Route
                    path={`${match.url}appraisals/users`}
                    component={Users}
                  />
                  <Route
                    path={`${match.url}hrm/payroll-batches`}
                    component={PayrollBatches}
                  />
                  <Route
                    path={`${match.url}hrm/queued-payrolls`}
                    component={QueuedPayrolls}
                  />

                  <Route
                    path={`${match.url}common/notifications`}
                    component={Notifications}
                  />

                  <Route
                    path={`${match.url}hrm/leave-balances`}
                    component={LeaveBalances}
                  />

                  <Route
                    path={`${match.url}hrm/leaves`}
                    component={Leaves}
                  />

                  <Route
                    path={`${match.url}hrm/paid-leaves`}
                    component={PaidLeaves}
                  />
                  <Route
                    path={`${match.url}hrm/insurance-premium`}
                    component={InsurancePremium}
                  />

                  <Route
                    path={`${match.url}hrm/leave-categories`}
                    component={LeaveCategories}
                  />

                  <Route
                    path={`${match.url}hrm/expenses`}
                    component={PettyCash}
                  />

                  <Route
                    path={`${match.url}hrm/expenses-account`}
                    component={PettyCashAccount}
                  />
                  <Route
                    path={`${match.url}hrm/expenses-categories`}
                    component={PettyCashCategories}
                  />
                  <Route
                    path={`${match.url}hrm/expenses-subcategories`}
                    component={PettyCashSubCategories}
                  />
                  <Route
                    path={`${match.url}hrm/expenses-receipts`}
                    component={PettyCashReceipts}
                  />
                  <Route
                    path={`${match.url}hrm/deductions`}
                    component={Deductions}
                  />
                  <Route
                    path={`${match.url}hrm/savings`}
                    component={Savings}
                  />
                  <Route
                    path={`${match.url}hrm/withdrawals`}
                    component={Withdrawals}
                  />
                  <Route path={`${match.url}hrm/assets`} component={Assets} />
                  <Route
                    path={`${match.url}common/branches`}
                    component={Branches}
                  />
                  <Route
                    path={`${match.url}attendance/branches`}
                    component={Branches}
                  />
                  <Route
                    path={`${match.url}hrm/branches`}
                    component={Branches}
                  />
                  <Route
                    path={`${match.url}payouts/branches`}
                    component={Branches}
                  />
                  <Route
                    path={`${match.url}appraisals/branches`}
                    component={Branches}
                  />
                  <Route
                    path={`${match.url}common/companies`}
                    component={Companies}
                  />
                  <Route
                    path={`${match.url}attendance/companies`}
                    component={Companies}
                  />
                  <Route
                    path={`${match.url}hrm/companies`}
                    component={Companies}
                  />
                  <Route
                    path={`${match.url}payouts/companies`}
                    component={Companies}
                  />
                  <Route
                    path={`${match.url}appraisals/companies`}
                    component={Companies}
                  />
                  <Route
                    path={`${match.url}hrm/documents`}
                    component={Documents}
                  />
                  <Route path={`${match.url}hrm/loans`} component={Loans} />
                  <Route path={`${match.url}hrm/payroll-financing`} component={MyLoans} />
                  <Route
                    path={`${match.url}hrm/loan-categories`}
                    component={LoanCategories}
                  />
                  <Route
                    path={`${match.url}hrm/job-titles`}
                    component={JobTitleIndex}
                  />
                  <Route
                    path={`${match.url}hrm/holidays`}
                    component={Holidays}
                  />
                  <Route
                    path={`${match.url}admin/holidays`}
                    component={AdminHolidays}
                  />
                  <Route
                    path={`${match.url}hrm/pension-rates`}
                    component={PensionRates}
                  />
                  <Route
                    path={`${match.url}hrm/reports`}
                    component={Reports}
                  />
                  <Route
                    path={`${match.url}hrm/leave-balances-report`}
                    component={LeaveBalancesReport}
                  />
                  <Route
                  path={`${match.url}hrm/muster-roll`}
                  component={MusterRoll}
                  />
                  <Route
                    path={`${match.url}hrm/queued-muster-roll-exports`}
                    component={QueuedMusterRoll}
                  />
                  <Route
                    path={`${match.url}hrm/queued-payslips-exports`}
                    component={QueuedPayslips}
                  />
                  <Route
                    path={`${match.url}hrm/allowance-ledger`}
                    component={AllowanceLedger}
                  />
                  <Route
                    path={`${match.url}hrm/pension-report`}
                    component={PensionReport}
                  />
                  <Route
                    path={`${match.url}hrm/salary-advance-report`}
                    component={SalaryAdvanceReport}
                  />
                  <Route
                    path={`${match.url}hrm/nssf-report`}
                    component={NssfReport}
                  />
                  <Route
                    path={`${match.url}hrm/nhif-report`}
                    component={NhifReport}
                  />
                  <Route
                    path={`${match.url}hrm/helb-report`}
                    component={HelbReport}
                  />
                  <Route
                    path={`${match.url}hrm/paye-report`}
                    component={PayeReport}
                  />
                  <Route
                    path={`${match.url}hrm/loan-repayment-report`}
                    component={LoanRepaymentReport}
                  />
                  <Route
                    path={`${match.url}hrm/net-pay-report`}
                    component={NetPayReport}
                  />
                  <Route
                    path={`${match.url}hrm/audit-log-report`}
                    component={AuditLogReport}
                  />
                  <Route
                    path={`${match.url}hrm/deduction-ledger`}
                    component={DeductionLedger}
                  />
                  <Route
                    path={`${match.url}transfer-employee`}
                    component={TransferEmployeeForm}
                  />
                  <Route
                    path={`${match.url}hrm/wages-report`}
                    component={WagesReport}
                  />
                  <Route
                    path={`${match.url}hrm/employees`}
                    component={Employees}
                  />
                  <Route
                    path={`${match.url}attendance/employees`}
                    component={AttendanceEmployees}
                  />
                  <Route
                    path={`${match.url}common/roles`}
                    component={Roles}
                  />
                  <Route
                    path={`${match.url}attendance/roles`}
                    component={Roles}
                  />
                  <Route
                    path={`${match.url}hrm/roles`}
                    component={Roles}
                  />
                  <Route
                    path={`${match.url}payouts/roles`}
                    component={Roles}
                  />
                  <Route
                    path={`${match.url}appraisals/roles`}
                    component={Roles}
                  />
                  <Route
                    path={`${match.url}common/create-role`}
                    component={CreateRole}
                  />
                  <Route
                    path={`${match.url}attendance/create-role`}
                    component={CreateRole}
                  />
                  <Route
                    path={`${match.url}hrm/create-role`}
                    component={CreateRole}
                  />
                  <Route
                    path={`${match.url}payouts/create-role`}
                    component={CreateRole}
                  />
                  <Route
                    path={`${match.url}appraisals/create-role`}
                    component={CreateRole}
                  />

                  <Route path={`${match.url}hrm/sms`} component={SMS} />

                  <Route
                    path={`${match.url}hrm/new-text`}
                    component={NewText}
                  />
                  <Route
                    path={`${match.url}hrm/payslips`}
                    component={Payslips}
                  />
                  <Route
                    path={`${match.url}hrm/off-days`}
                    component={DynamicOffDays}
                  />
                  <Route
                    path={`${match.url}attendance/off-days`}
                    component={DynamicOffDays}
                  />
                  <Route
                    path={`${match.url}portal/off-days`}
                    component={DynamicOffDaysPortal}
                  />
                  <Route
                    path={`${match.url}attendance/fingerprints`}
                    component={Fingerprints}
                  />
                  <Route
                    path={`${match.url}attendance/checkpoints`}
                    component={Checkpoints}
                  />
                  <Route
                    path={`${match.url}attendance/blacklisted-employees`}
                    component={BlacklistedEmployees}
                  />
                  <Route
                    path={`${match.url}attendance/shifts`}
                    component={Shifts}
                  />
                  <Route
                    path={`${match.url}attendance/shift-working-hours`}
                    component={ShiftWorkingHours}
                  />
                  <Route
                    path={`${match.url}attendance/hours-report`}
                    component={HoursReport}
                  />
                  <Route
                    path={`${match.url}attendance/custom-hours`}
                    component={CustomHours}
                  />
                  <Route
                    path={`${match.url}attendance/teams`}
                    component={Teams}
                  />
                  <Route
                    path={`${match.url}attendance/queued-reports`}
                    component={QueuedReports}
                  />
                  <Route
                    path={`${match.url}hrm/queued-reports`}
                    component={QueuedReports}
                  />
                  <Route
                    path={`${match.url}attendance/hours-wages-report`}
                    component={HoursWagesReport}
                  />
                  <Route
                    path={`${match.url}attendance/ta-wages-report`}
                    component={TAWagesReport}
                  />
                  <Route
                    path={`${match.url}attendance/attendance-report`}
                    component={AttendanceReport}
                  />
                  {/*<Route
                    path={`${match.url}attendance/guard-attendance-report`}
                    component={GuardAttendanceReport}
                  />*/}
                  <Route
                    path={`${match.url}attendance/attendance-register-report`}
                    component={GuardRegisterReport}
                  />
                  <Route
                    path={`${match.url}attendance/snap-checks`}
                    component={SnapChecks}
                  />
                  <Route
                    path={`${match.url}attendance/dashboard`}
                    component={TADashboard}
                  />
                  <Route
                    path={`${match.url}attendance/keys`}
                    component={Keys}
                  />
                  {/*<Route
                    path={`${match.url}attendance/scheduling`}
                    component={Scheduling}
                  />*/}
                  {/*<Route
                    path={`${match.url}attendance/guard-scheduling`}
                    component={GuardScheduling}
                  />*/}
                  <Route
                    path={`${match.url}attendance/attendance-day-details`}
                    component={AttendanceReport}
                  />
                  <Route
                    path={`${match.url}attendance/daily-overtime`}
                    component={DailyOvertime}
                  />
                  <Route
                    path={`${match.url}attendance/attendance-mapping`}
                    component={AttendanceMapping}
                  />
                  <Route
                    path={`${match.url}attendance/projects`}
                    component={Projects}
                  />
                  <Route
                    path={`${match.url}attendance/assignment-relievers`}
                    component={AssignmentRelievers}
                  />
                  <Route
                    path={`${match.url}attendance/create-project`}
                    component={CreateProject}
                  />
                  <Route
                    path={`${match.url}attendance/project-assign-employees`}
                    component={ProjectAssignEmployees}
                  />
                  <Route
                    path={`${match.url}attendance/shift-assign-employees`}
                    component={ScheduleAssignEmployees}
                  />
                  <Route
                    path={`${match.url}attendance/synced-devices`}
                    component={SyncedDevices}
                  />
                  <Route
                    path={`${match.url}hrm/manual-worked-days`}
                    component={ManualWorkedDays}
                  />
                  <Route
                    path={`${match.url}payouts/dashboard`}
                    component={PayoutsDashboard}
                  />
                  <Route
                    path={`${match.url}payouts/transaction-history`}
                    component={TransactionHistory}
                  />
                  <Route
                    path={`${match.url}payouts/transaction-details`}
                    component={TransactionDetails}
                  />
                  <Route
                    path={`${match.url}payouts/recharge`}
                    component={Recharge}
                  />
                  <Route
                    path={`${match.url}payouts/bulk-payouts`}
                    component={BulkPayouts}
                  />
                  <Route
                    path={`${match.url}common/subscription`}
                    component={Subscription}
                  />
                  <Route
                    path={`${match.url}common/subscription-payment`}
                    component={SubscriptionPayment}
                  />
                  <Route
                    path={`${match.url}common/profile`}
                    component={UserProfile}
                  />
                  <Route
                    path={`${match.url}portal/profile`}
                    component={EmployeeProfile}
                  />
                  <Route
                    path={`${match.url}portal/documents`}
                    component={DocumentsPortal}
                  />
                  <Route
                    path={`${match.url}portal/payslip`}
                    component={PayslipsPortal}
                  />
                  <Route
                    path={`${match.url}portal/leaves`}
                    component={LeavesPortal}
                  />
                  <Route
                    path={`${match.url}portal/assets`}
                    component={AssetsPortal}
                  />
                  <Route
                    path={`${match.url}portal/expenses`}
                    component={PettyCashPortal}
                  />
                  <Route
                    path={`${match.url}portal/expenses-receipts`}
                    component={PettyCashReceiptsPortal}
                  />
                  <Route
                    path={`${match.url}portal/salary-advance`}
                    component={SalaryAdvancePortal}
                  />
                  <Route
                    path={`${match.url}portal/loans`}
                    component={LoansPortal}
                  />
                  <Route
                    path={`${match.url}portal/savings`}
                    component={SavingsPortal}
                  />
                  <Route
                    path={`${match.url}admin/dashboard`}
                    component={AdminDashboard}
                  />
                  <Route
                    path={`${match.url}admin/companies/onboarding`}
                    component={CompaniesOnboarding}
                  />
                  <Route
                    path={`${match.url}admin/client-onboarding-workflow`}
                    component={CompaniesOnboardingWorkflow}
                  />
                  <Route
                    path={`${match.url}admin/companies/:status?`}
                    component={CompaniesAdmin}
                  />
                  <Route
                    path={`${match.url}admin/companies-appraisal`}
                    component={CompaniesWithAppraisal}
                  />
                  {/*<Route*/}
                  {/*  path={`${match.url}admin/company-payments`}*/}
                  {/*  component={CompanyPayments}*/}
                  {/*/>*/}
                  <Route
                    path={`${match.url}admin/companies-sender-ids`}
                    component={CompaniesWithSenderIDs}
                  />
                  <Route
                    path={`${match.url}admin/companies-loan-rates`}
                    component={CompaniesWithLoanRates}
                  />
                  <Route
                    path={`${match.url}admin/companies-loans`}
                    component={CompaniesWithLoans}
                  />
                  <Route
                    path={`${match.url}admin/company-profile`}
                    component={CompanyProfile}
                  />
                  <Route
                    path={`${match.url}admin/transactions`}
                    component={AllTransaction}
                  />
                  <Route
                    path={`${match.url}admin/bank-payments`}
                    component={BankPayments}
                  />
                  <Route
                    path={`${match.url}admin/company/transaction-history`}
                    component={TransactionHistory}
                  />
                  <Route
                    path={`${match.url}admin/company/features`}
                    component={FeaturesActivation}
                  />
                  <Route
                    path={`${match.url}admin/company/transaction-details`}
                    component={TransactionDetails}
                  />
                  <Route
                    path={`${match.url}admin/accounts/pricing`}
                    component={Pricing}
                  />
                  <Route
                    path={`${match.url}admin/companies-loans`}
                    component={Pricing}
                  />
                  <Route
                    path={`${match.url}admin/companies-loans-repayment`}
                    component={CompaniesLoansRepayment}
                  />
                  <Route
                    path={`${match.url}admin/administrators`}
                    component={Admins}
                  />
                  <Route
                    path={`${match.url}admin/users`}
                    component={SystemUsers}
                  />
                  <Route
                    path={`${match.url}admin/assign-permissions`}
                    component={CreateRole}
                  />
                  <Route
                    path={`${match.url}admin/payouts-charge-rates`}
                    component={PayoutsChargeRates}
                  />
                  <Route
                    path={`${match.url}admin/default-payouts-charge-rates`}
                    component={DefaultPayoutsChargeRates}
                  />
                  <Route
                    path={`${match.url}admin/subscription/:status`}
                    component={AdminSubscription}
                  />
                  <Route
                    path={`${match.url}admin/banks`}
                    component={BankDetails}
                  />
                  <Route
                    path={`${match.url}admin/salary-advance-settings`}
                    component={SalaryAdvanceSettings}
                  />
                  <Route
                    path={`${match.url}admin/salary-advances`}
                    component={AdminSalaryAdvances}
                  />
                  <Route
                    path={`${match.url}payouts/banks`}
                    component={BankDetails}
                  />
                  <Route
                    path={`${match.url}hrm/banks`}
                    component={BankDetails}
                  />
                  <Route
                    path={`${match.url}common/banks`}
                    component={BankDetails}
                  />
                  <Route
                    path={`${match.url}admin/accounts/mpesa-settings`}
                    component={MpesaSettings}
                  />
                  <Route
                    path={`${match.url}portal/self-appraise`}
                    component={SelfAppraise}
                  />
                  <Route
                    path={`${match.url}portal/my-appraisals`}
                    component={Appraisal}
                  />
                  <Route path={`${match.url}portal/pip`} component={Pip} />
                  <Route
                    path={`${match.url}portal/kpi`}
                    component={EmployeeKpi}
                  />
                  <Route
                    path={`${match.url}appraisals/appraisal`}
                    component={Appraisal}
                  />
                  <Route
                    path={`${match.url}appraisals/appraise`}
                    component={DoAppraisal}
                  />
                  <Route
                    path={`${match.url}appraisals/employee-appraisal`}
                    component={EmployeeAppraisal}
                  />
                  <Route
                    path={`${match.url}appraisals/department-kpi`}
                    component={DepartmentKpi}
                  />
                  <Route
                    path={`${match.url}appraisals/employee-kpi`}
                    component={EmployeeKpi}
                  />
                  <Route
                    path={`${match.url}appraisals/dashboard`}
                    component={AppraisalDashboard}
                  />
                  <Route
                    path={`${match.url}appraisals/pip`}
                    component={Pip}
                  />
                  <Route
                    path={`${match.url}appraisals/key-result-areas`}
                    component={KeyResultAreas}
                  />
                  <Route
                    path={`${match.url}appraisals/performance-rating`}
                    component={PerformanceRating}
                  />
                  <Route
                    path={`${match.url}appraisals/review-period`}
                    component={ReviewPeriod}
                  />
                  <Route
                    path={`${match.url}hrm/upload/employee/no-edit`}
                    component={uploadEmployeeNoEdit}
                  />
                  <Route
                    path={`${match.url}hrm/upload/employee/edit`}
                    component={uploadEmployeeEdit}
                  />
                  <Route
                    path={`${match.url}common/system-settings`}
                    component={SystemSettings}
                  />
                  <Route
                    path={`${match.url}attendance/system-settings`}
                    component={SystemSettings}
                  />
                  <Route
                    path={`${match.url}hrm/system-settings`}
                    component={SystemSettings}
                  />
                  <Route
                    path={`${match.url}payouts/system-settings`}
                    component={SystemSettings}
                  />
                  <Route
                    path={`${match.url}admin/daily/reconciliation`}
                    component={DailyReconciliation}
                  />
                  <Route
                    path={`${match.url}admin/qr-code`}
                    component={QRCodeGeneration}
                    />
                  <Route
                    path={`${match.url}admin/employee-based/qr-code`}
                    component={EmployeesBasedQrCodes}
                  />
                  <Route
                    path={`${match.url}admin/loan-requests`}
                    component={LoanRequests}
                    />
                  <Route
                    path={`${match.url}admin/lend-loans`}
                    component={LendLoan}
                    />
                  <Route
                    path={`${match.url}admin/loan-lenders`}
                    component={LoanLenders}
                    />
                  <Route
                    path={`${match.url}admin/loan-reports`}
                    component={LoanReports}
                    />
                  <Route
                    path={`${match.url}admin/payment-report`}
                    component={PaymentReport}
                    />
                  <Route
                    path={`${match.url}admin/accrued-interest-report`}
                    component={AccruedInterestReport}
                    />
                  <Route
                    path={`${match.url}admin/penalties-fees-report`}
                    component={PenaltiesFeesReport}
                    />
                  <Route
                    path={`${match.url}admin/customer-statement`}
                    component={CustomerStatement}
                    />
                  <Route
                    path={`${match.url}admin/loans-dashboard`}
                    component={LoansDashboard}
                    />
                  <Route
                    path={`${match.url}admin/lenders-wallet-transactions`}
                    component={LenderWalletTransactions}/>
                  <Route
                    path={`${match.url}admin/transaction-reports`}
                    component={TransactionReports}/>
                    <Route
                    path={`${match.url}hrm/insurance-relief`}
                    component={InsuranceProvident}
                    />
                    <Route
                    path={`${match.url}hrm/voluntary-provident`}
                    component={InsuranceProvident}
                    />
                    <Route
                    path={`${match.url}hrm/payroll-journal`}
                    component={PayrollJournal}
                    />
                    <Route
                    path={`${match.url}portal/attendance-attempts`}
                    component={AttendanceAttempts}
                    />
                    <Route
                    path={`${match.url}payouts/statutory-payments`}
                    component={StatutoryPayments}
                    />
                    <Route
                    path={`${match.url}hrm/trainings`}
                    component={Trainings}
                    />
                    <Route
                    path={`${match.url}portal/trainings`}
                    component={TrainingOnPortal}
                    />
                    <Route
                    path={`${match.url}hrm/p10-report`}
                    component={P10Report}
                    />
                    <Route
                    path={`${match.url}hrm/nita-report`}
                    component={NitaReport}
                    />
                    <Route
                      path={`${match.url}admin/overdue-loans-report`}
                      component={OverdueLoansReport}
                    />
                    <Route
                    path={`${match.url}admin/system-settings`}
                    component={AdminSettings}
                    />
                    <Route path={`${match.url}hrm/products`}
                           component={Products} />
                    <Route
                    path={`${match.url}hrm/products-delivered`}
                    component={ProductsDelivered}
                    />
                    <Route
                    path={`${match.url}hrm/leaves-attended`}
                    component={LeavesAttended}
                    />
                    <Route
                    path={`${match.url}admin/audit-trails`}
                    component={PayoutsAuditTrails}
                    />
                    <Route
                      path={`${match.url}hrm/payroll-variance-report`}
                      component={PayrollVariance}
                    />
                    <Route
                    path={`${match.url}admin/mobile-notifications`}
                    component={MobileNotifications}
                    />
                    <Route
                    path={`${match.url}hrm/kyc`}
                    component={KYC}
                    />
                  <Route
                    path={`${match.url}hrm/employee-rent`}
                    component={EmployeeRents}
                  />
                  <Route
                    path={`${match.url}hrm/esop`}
                    component={ESOP}
                  />
                  <Route
                    path={`${match.url}hrm/helb`}
                    component={HELB}
                  />
                   <Route
                    path={`${match.url}hrm/p9-report`}
                    component={P9Report}
                    />
                  <Route
                    path={`${match.url}hrm/assets-categories`}
                    component={AssetsCategories}
                  />
                  <Route
                    path={`${match.url}attendance/generate-assignment-qr-codes`}
                    component={QRCodeGeneration}
                  />
                  <Route
                    path={`${match.url}attendance/generate-employees-qr-codes`}
                    component={EmployeesBasedQrCodes}
                  />
                  <Route
                    path={`${match.url}hrm/employee-invoices`}
                    component={EmployeeInvoices}
                  />
                  <Route
                    path={`${match.url}portal/employee-invoices`}
                    component={EmployeeInvoices}
                  />
                   <Route
                    path={`${match.url}admin/withholding-tax-rates`}
                    component={WithholdingTax}
                    />
                  <Route
                    path={`${match.url}admin/statutory-filling-requests`}
                    component={StatutoryFillingRequests}
                  />
                   <Route
                    path={`${match.url}hrm/withholding-tax-report`}
                    component={WithholdingTaxReport}
                  />
                  <Route
                    path={`${match.url}admin/subscription-pricing`}
                    component={SubscriptionPricing}
                  />
                  <Route
                    path={`${match.url}admin/eor/templates`}
                    component={EOR}
                  />
                  <Route
                    path={`${match.url}admin/default-salary-advance-settings`}
                    component={SalaryAdvanceDefaultSettings}
                  />
                  <Route
                    path={`${match.url}admin/salary-advance-fees`}
                    component={SalaryAdvanceFees}
                  />
                  <Route
                    path={`${match.url}admin/default-salary-advance-fees`}
                    component={DefaultSalaryAdvanceFees}
                  />
                  <Route
                    path={`${match.url}admin/exchange-rates`}
                    component={ExchangeRates}
                  />
                  <Route
                    path={`${match.url}admin/salary-advance-company-settings`}
                    component={SalaryAdvanceCompanySettings}
                  />
                  <Route
                    path={`${match.url}admin/salary-advance-global-settings`}
                    component={SalaryGlobalAdvanceSettings}
                  />
                  <Route
                    path={`${match.url}admin/salary-advance-loans`}
                    component={SalaryAdvanceLoans}
                  />
                  <Route component={Error404} />
                </Switch>
              </div>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { role } = auth;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    role
  };
};
export default withRouter(connect(mapStateToProps)(App));
