import {
  ADD_LEAVE_TYPE_CATEGORY_SUCCESS,
  DELETE_LEAVE_TYPE_CATEGORY,
  EDIT_LEAVE_TYPE_CATEGORY_SUCCESS,
  FETCH_LEAVE_TYPE_CATEGORY_SUCCESS,
  HIDE_CREATE_LEAVE_TYPE_CATEGORY_FORM,
  HIDE_DELETE_LEAVE_TYPE_CATEGORY_MODAL,
  HIDE_EDIT_LEAVE_TYPE_CATEGORY_FORM,
  SHOW_CREATE_LEAVE_TYPE_CATEGORY_FORM,
  SHOW_DELETE_LEAVE_TYPE_CATEGORY_MODAL,
  SHOW_EDIT_LEAVE_TYPE_CATEGORY_FORM
} from '../../actions/payroll/LeaveTypeCategoryActions';

const INIT_STATE = {
  leaveTypeCategoriesByID:[],
  showLeaveTypeCategoryCreateForm:false,
  LeaveTypeCategoryToEdit:[],
  showLeaveTypeCategoryEditForm:false,
  showLeaveTypeCategoryDeleteModal:false,
};
export default (state = INIT_STATE, action)=>{
  let leave_type_categories =[];
  switch (action.type){
    case ADD_LEAVE_TYPE_CATEGORY_SUCCESS:
    case EDIT_LEAVE_TYPE_CATEGORY_SUCCESS:
      leave_type_categories = {...state.leaveTypeCategoriesByID};
      leave_type_categories[action.payload.id]=action.payload;
      return {
        ...state,
        leaveTypeCategoriesByID:leave_type_categories
      };
    case SHOW_CREATE_LEAVE_TYPE_CATEGORY_FORM:
      return {
        ...state,
        showLeaveTypeCategoryCreateForm:true
      };
    case HIDE_CREATE_LEAVE_TYPE_CATEGORY_FORM:
      return {
        ...state,
        showLeaveTypeCategoryCreateForm:false
      };
    case FETCH_LEAVE_TYPE_CATEGORY_SUCCESS:
      return {
        ...state,
        leaveTypeCategoriesByID:action.payload
      };
    case SHOW_EDIT_LEAVE_TYPE_CATEGORY_FORM:
      return {
        ...state,
        LeaveTypeCategoryToEdit:action.payload,
        showLeaveTypeCategoryEditForm:true
      };
    case HIDE_EDIT_LEAVE_TYPE_CATEGORY_FORM:
      return{
        ...state,
        showLeaveTypeCategoryEditForm:false
      };
    case SHOW_DELETE_LEAVE_TYPE_CATEGORY_MODAL:
      return {
        ...state,
        LeaveTypeCategoryToEdit:action.payload,
        showLeaveTypeCategoryDeleteModal:true
      };
    case HIDE_DELETE_LEAVE_TYPE_CATEGORY_MODAL:
      return {
        ...state,
        showLeaveTypeCategoryDeleteModal:false
      };
    case DELETE_LEAVE_TYPE_CATEGORY:
      leave_type_categories = {...state.leaveTypeCategoriesByID};
      delete leave_type_categories[action.payload.id];
      return {
        ...state,
        leaveTypeCategoriesByID:leave_type_categories
      };
    default:
      return state;

  }
}
