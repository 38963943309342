import {
    FETCH_COMPANY_LOAN_PAYMENT_SUCCESS,
    ADD_COMPANY_LOAN_PAYMENT_SUCCESS, DELETE_COMPANY_LOAN_PAYMENT_SUCCESS,
    UPDATE_COMPANY_LOAN_PAYMENT_SUCCESS,

} from "../../actions/admin/CompanyLoanPaymentActions";

const INIT_STATE = {
    companyLoanPaymentsByID:{},

};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {

        case FETCH_COMPANY_LOAN_PAYMENT_SUCCESS:
            return {
                ...state,
                companyLoanPaymentsByID: action.payload
            };
        case ADD_COMPANY_LOAN_PAYMENT_SUCCESS:
        case UPDATE_COMPANY_LOAN_PAYMENT_SUCCESS:
            let companyLoan = {...state.companyLoanPaymentsByID};
            companyLoan[action.payload.id]=action.payload;
            return {
                ...state,
                companyLoanPaymentsByID:companyLoan
            };

        case DELETE_COMPANY_LOAN_PAYMENT_SUCCESS:
            let companyLoanPaymentsByID = {...state.allowancesByID};
            delete companyLoanPaymentsByID[action.payload.id];
            return {
                ...state,
                companyLoanPaymentsByID:companyLoanPaymentsByID
            };
        default:
            return state;
    }
}
