import {
  ADD_COMPANY_LOAN_RATES_SUCCESS,
  EDIT_COMPANY_LOAN_RATES_SUCCESS,
  FETCH_COMPANY_LOAN_RATES_SUCCESS, FETCH_ONE_COMPANY_LOAN_RATE_SUCCESS
} from "../../actions/admin/CompanyRatesActions";

const INIT_STATE = {

  CompanyLoanRates:{},
  oneCompanyLoanRate:{},
  interest_rate:'',
  defaulter_rate:'',
  min_days : 10,
  loan_days :  30,
  loan_fee : '',
  interest_on_interest : '',
};

export default (state=INIT_STATE,action)=>{
  let companyRates ={...state.CompanyLoanRates}
  switch (action.type) {
    case FETCH_ONE_COMPANY_LOAN_RATE_SUCCESS:
      return {
        ...state,
        interest_rate:action.payload.rate_before,
        defaulter_rate:action.payload.rate_after,
        min_days : action.payload.minimum_interest_days,
        loan_days : action.payload.maximum_loan_days,
        loan_fee : action.payload.loan_fee ,
        interest_on_interest : action.payload.interest_earn_interest_day,
        oneCompanyLoanRate: action.payload
      }
    case FETCH_COMPANY_LOAN_RATES_SUCCESS:
      return {
        ...state,
        CompanyLoanRates:action.payload
      };
    case ADD_COMPANY_LOAN_RATES_SUCCESS:
    case EDIT_COMPANY_LOAN_RATES_SUCCESS:
      companyRates[action.payload.id] = action.payload
      return {
        ...state,
        CompanyLoanRates:companyRates
      };
    default: return state;
  }
}