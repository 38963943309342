import {
    ADD_CHECKPOINT,
    ADD_CHECKPOINT_SUCCESS, DELETE_CHECKPOINT_SUCCESS,
    FETCH_CHECKPOINT_SUCCESS,
    UPDATE_CHECKPOINT_SUCCESS
} from "../../actions/attendance/CheckPointActions";

const INIT_STATE = {
    checkPointsByID:{},
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
        case FETCH_CHECKPOINT_SUCCESS:
            return {
                ...state,
                checkPointsByID: action.payload
            };
        case ADD_CHECKPOINT_SUCCESS:
        case UPDATE_CHECKPOINT_SUCCESS:
            let checkpoint_id = action.payload.id;
            return {
                ...state,
                checkPointsByID: {
                    ...state.checkPointsByID,
                    [checkpoint_id]:action.payload
                }
            };
        case DELETE_CHECKPOINT_SUCCESS:
            let checkPoints = {...state.checkPointsByID};
            delete checkPoints[action.payload.id];
            return {
                ...state,
                checkPointsByID:checkPoints
            };
        default:
            return state;
    }
}
