import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import {
  ADD_BANK,
  addBankSuccessGenerator,
  DELETE_BANK,
  deleteBankSuccessGenerator,
  EDIT_BANK,
  editBankSuccessGenerator,
  FETCH_ACCOUNT_SETTINGS, FETCH_ADMIN_SYSTEM_SETTINGS,
  FETCH_BANKS,
  FETCH_COUNTRIES,
  FETCH_CURRENCIES,
  FETCH_NHIF_RATES,
  FETCH_NSSF_RATES,
  FETCH_PAYE_RATES,
  FETCH_SECTORS, FETCH_SUBSCRIPTION_PLANS,
  fetchAccountSettingsSuccessGenerator, fetchAdminSystemSettingsSuccessGenerator,
  fetchBanksSuccessGenerator,
  fetchCountriesSuccessGenerator,
  fetchNHIFRatesSuccessGenerator,
  fetchNSSFRatesSuccessGenerator,
  fetchPAYERatesSuccessGenerator,
  fetchSectorsSuccessGenerator, fetchSubscriptionPlansSuccessGenerator,
  hideAppLoaderGenerator,
  showAppLoaderGenerator,
  showMessageGenerator, UPDATE_ACCOUNT_SETTINGS, UPDATE_ADMIN_SYSTEM_SETTINGS, ADD_BANK_BRANCH, EDIT_BANK_BRANCH, addBankBranchSuccessGenerator, editBankBranchSuccessGenerator
} from "../actions/GeneralActions";
import {Helpers} from "../../util/Helpers";
import {fetchCurrenciesSuccessGenerator} from "../actions/GeneralActions";
import {
  ACCOUNT_SETTINGS_KEY, ADMIN_SETTINGS_KEY,
  BANKS_KEY,
  COUNTRIES_KEY,
  CURRENCIES_KEY, NHIF_RATES_KEY, NSSF_RATES_KEY, PAYE_RATES_KEY,
  SECTORS_KEY
} from "../../constants/SessionKeys";
import {API_ENDPOINT} from "../../constants/ActionTypes";

const fetchCurrenciesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/currencies',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchAdminSystemSettingsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/admin/user/get-settings',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchSubscriptionPlansRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/plans',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const updateAdminSystemSettingsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/admin/user/get-settings',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchCountriesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/countries',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchBanksRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/paypoints',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchSectorsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/sectors',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchNHIFRatesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/nhif-rates',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchNSSFRatesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/nssf-rates',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchPAYERatesRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/paye-tax-rates',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchAccountSettingsRequest = async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/home/company/account-settings',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);
const addBankRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/setup/paypoints',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const editBankRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/setup/paypoints/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);
const addBankBranchRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/setup/paypoints/add-branches',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const editBankBranchRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/setup/paypoints/edit-branches/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);
const updateAccountSettingsRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/home/company/account-settings/update',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const deleteBankRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/setup/paypoints/delete',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchAccountSettings({payload}) {
  try {
    const pay = {...payload,auth_company_id:Helpers.authSwitchedCompanyID(),token:Helpers.token()};
    const response = yield call(fetchAccountSettingsRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let settings = response.data.data;
        Helpers.saveItem(ACCOUNT_SETTINGS_KEY, settings);
        yield put(fetchAccountSettingsSuccessGenerator(settings));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchSubscriptionPlans({payload}) {
  try {
    const pay = {...payload,token:Helpers.token()};
    const response = yield call(fetchSubscriptionPlansRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let settings = response.data.data.plans;
        yield put(fetchSubscriptionPlansSuccessGenerator(settings));
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchAdminSystemSettings({payload}) {
  try {
    const requestData = {...payload,token:Helpers.token()};
    const response = yield call(fetchAdminSystemSettingsRequest, requestData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let settings = response.data.data;
        Helpers.saveItem(ADMIN_SETTINGS_KEY, settings);
        yield put(fetchAdminSystemSettingsSuccessGenerator(settings));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchCurrencies({payload}) {
  /*let cached_currencies = Helpers.currencies();
  if(!Helpers.isNull(cached_currencies)){
    yield put(fetchCurrenciesSuccessGenerator(cached_currencies));
    return;
  }*/
  try {
    const pay = {...payload};
    const response = yield call(fetchCurrenciesRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let currencies = response.data.data;
        Helpers.saveItem(CURRENCIES_KEY, currencies);
        yield put(fetchCurrenciesSuccessGenerator({0:{}}));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchCountries({payload}) {
  let cached_countries = Helpers.countries();
  if(!Helpers.isNull(cached_countries)){
    yield put(fetchCountriesSuccessGenerator(cached_countries));
    return;
  }
  try {
    const pay = {...payload};
    const response = yield call(fetchCountriesRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let countries = response.data.data;
        Helpers.saveItem(COUNTRIES_KEY, countries);
        yield put(fetchCountriesSuccessGenerator({0:{}}));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchBanks({payload}) {
  if(!payload.forceFetch){
    let cached_banks = Helpers.banks();
    if(!Helpers.isNull(cached_banks)){
      yield put(fetchBanksSuccessGenerator(cached_banks));
      return;
    }
  }
  try {
    const pay = {...payload};
    const response = yield call(fetchBanksRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let banks = response.data.data;
        Helpers.saveItem(BANKS_KEY, banks);
        yield put(fetchBanksSuccessGenerator(banks));//to save on redux storage, work a way to just use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchSectors({payload}) {
  /*let cached_sectors = Helpers.sectors();
  if(!Helpers.isNull(cached_sectors)){
    yield put(fetchSectorsSuccessGenerator(cached_sectors));
    return;
  }*///this may not work when someone is already logged in: Removed.
  try {
    const pay = {...payload};
    const response = yield call(fetchSectorsRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let sectors = response.data.data;
        Helpers.saveItem(SECTORS_KEY, sectors);
        yield put(fetchSectorsSuccessGenerator(sectors));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchNHIFRates({payload}) {
  let cached_nhif_rates = Helpers.nhif_rates();
  if(!Helpers.isNull(cached_nhif_rates)){
    yield put(fetchNHIFRatesSuccessGenerator(cached_nhif_rates));
    return;
  }
  try {
    const pay = {...payload};
    const response = yield call(fetchNHIFRatesRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let nhif_rates = response.data.data;
        Helpers.saveItem(NHIF_RATES_KEY, nhif_rates);
        yield put(fetchNHIFRatesSuccessGenerator({0:{}}));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchNSSFRates({payload}) {
  let cached_nssf_rates = Helpers.nssf_rates();
  if(!Helpers.isNull(cached_nssf_rates)){
    yield put(fetchNSSFRatesSuccessGenerator(cached_nssf_rates));
    return;
  }
  try {
    const pay = {...payload};
    const response = yield call(fetchNSSFRatesRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let nssf_rates = response.data.data;
        Helpers.saveItem(NSSF_RATES_KEY, nssf_rates);
        yield put(fetchNSSFRatesSuccessGenerator({0:{}}));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* fetchPAYERates({payload}) {
  /*let cached_paye_rates = Helpers.paye_rates();
  if(!Helpers.isNull(cached_paye_rates)){
    yield put(fetchPAYERatesSuccessGenerator(cached_paye_rates));
    return;
  }*/
  try {
    const pay = {...payload};
    const response = yield call(fetchPAYERatesRequest, pay);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let paye_rates = response.data.data;
        Helpers.saveItem(PAYE_RATES_KEY, paye_rates);
        yield put(fetchPAYERatesSuccessGenerator(paye_rates));//to save on redux storage, use localStorage value
      }
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator("Unknown Error",'error'));
  }

}
function* addBank({type,payload}) {
  yield put(showAppLoaderGenerator(type));
     try {
        let request = {...payload,token:Helpers.token()} ;
        let response = yield call(addBankRequest,request);
        if(response.status === 200 || response.status===201){
            if(response.data.success){
              let data = response.data.data;
              yield put(addBankSuccessGenerator(data));
              yield put(showMessageGenerator(response.data.message,'success')) ;
            }else{
              yield put(showMessageGenerator(response.data.message,'error'))
            }
        }else{
          yield put(showMessageGenerator(response.statusText,'error'))
        }
     }  catch (e) {
         yield put(showMessageGenerator(e,'error'))
     }
  yield put(hideAppLoaderGenerator(type));
}
function* editBank({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let request = {...payload,token:Helpers.token()} ;
    let response = yield call(editBankRequest,request);
    if(response.status === 200 || response.status===201){
      if(response.data.success){
        let data = response.data.data;
        yield put(editBankSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success')) ;
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }
    }else{
      yield put(showMessageGenerator(response.statusText,'error'))
    }
  }  catch (e) {
    yield put(showMessageGenerator(e,'error'))
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addBankBranch({type,payload}) {
  yield put(showAppLoaderGenerator(type));
     try {
        let request = {...payload,token:Helpers.token()} ;
        let response = yield call(addBankBranchRequest,request);
        if(response.status === 200 || response.status===201){
            if(response.data.success){
              let data = response.data.data;
              yield put(addBankBranchSuccessGenerator(data));
              yield put(showMessageGenerator(response.data.message,'success')) ;
            }else{
              yield put(showMessageGenerator(response.data.message,'error'))
            }
        }else{
          yield put(showMessageGenerator(response.statusText,'error'))
        }
     }  catch (e) {
         yield put(showMessageGenerator(e,'error'))
     }
  yield put(hideAppLoaderGenerator(type));
}
function* editBankBranch({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let request = {...payload,token:Helpers.token()} ;
    let response = yield call(editBankBranchRequest,request);
    if(response.status === 200 || response.status===201){
      if(response.data.success){
        let data = response.data.data;
        yield put(editBankBranchSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success')) ;
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }
    }else{
      yield put(showMessageGenerator(response.statusText,'error'))
    }
  }  catch (e) {
    yield put(showMessageGenerator(e,'error'))
  }
  yield put(hideAppLoaderGenerator(type));
}

function* updateAccountSettings({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let request = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()} ;
    let response = yield call(updateAccountSettingsRequest,request);
    if(response.status === 200 || response.status===201){
      if(response.data.success){
        let data = response.data.data;
        Helpers.saveItem(ACCOUNT_SETTINGS_KEY, data);
        yield put(fetchAccountSettingsSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success')) ;
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }
    }else{
      yield put(showMessageGenerator(response.statusText,'error'))
    }
  }  catch (e) {
    yield put(showMessageGenerator(e,'error'))
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateAdminSystemSettings({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let request = {...payload,token:Helpers.token()} ;
    let response = yield call(updateAdminSystemSettingsRequest,request);
    if(response.status === 200 || response.status===201){
      if(response.data.success){
        let data = response.data.data;
        Helpers.saveItem(ADMIN_SETTINGS_KEY, data);
        yield put(fetchAdminSystemSettingsSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success')) ;
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }
    }else{
      yield put(showMessageGenerator(response.statusText,'error'))
    }
  }  catch (e) {
    yield put(showMessageGenerator(e,'error'))
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteBank({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let request = {...payload,token:Helpers.token()} ;
    let response = yield call(deleteBankRequest,request);
    if(response.status === 200 || response.status===201){
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message,'success')) ;
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }
    }else{
      yield put(showMessageGenerator(response.statusText,'error'))
    }
  }  catch (e) {
    yield put(showMessageGenerator(e,'error'))
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* addBankDefault() {
 yield takeLatest(ADD_BANK,addBank);
}
export function* editBankDefault() {
 yield takeLatest(EDIT_BANK,editBank);
}
export function* addBankBranchDefault() {
  yield takeLatest(ADD_BANK_BRANCH,addBankBranch);
 }
 export function* editBankBranchDefault() {
  yield takeLatest(EDIT_BANK_BRANCH,editBankBranch);
 }
export function* deleteBankDefault() {
 yield takeLatest(DELETE_BANK,deleteBank);
}
export function* fetchCurrenciesDefault() {
  yield takeLatest(FETCH_CURRENCIES, fetchCurrencies);
}
export function* fetchCountriesDefault() {
  yield takeLatest(FETCH_COUNTRIES, fetchCountries);
}
export function* fetchBanksDefault() {
  yield takeLatest(FETCH_BANKS, fetchBanks);
}
export function* fetchSectorsDefault() {
  yield takeLatest(FETCH_SECTORS, fetchSectors);
}
export function* fetchNHIFRatesDefault() {
  yield takeLatest(FETCH_NHIF_RATES, fetchNHIFRates);
}
export function* fetchNSSFRatesDefault() {
  yield takeLatest(FETCH_NSSF_RATES, fetchNSSFRates);
}
export function* fetchPAYERatesDefault() {
  yield takeLatest(FETCH_PAYE_RATES, fetchPAYERates);
}
export function* fetchAdminSystemSettingsDefault() {
  yield takeLatest(FETCH_ADMIN_SYSTEM_SETTINGS, fetchAdminSystemSettings);
}
export function* fetchAccountSettingsDefault() {
  yield takeLatest(FETCH_ACCOUNT_SETTINGS, fetchAccountSettings);
}export function* updateAccountSettingsDefault() {
  yield takeLatest(UPDATE_ACCOUNT_SETTINGS, updateAccountSettings);
}
export function* updateAdminSystemSettingsDefault() {
  yield takeLatest(UPDATE_ADMIN_SYSTEM_SETTINGS, updateAdminSystemSettings);
}
export function*  fetchSubscriptionPlansDefault() {
  yield takeLatest(FETCH_SUBSCRIPTION_PLANS, fetchSubscriptionPlans);

}

export default function* rootSaga() {
  yield all([fork(fetchCurrenciesDefault),
    fork(fetchCountriesDefault),
    fork(fetchBanksDefault),
    fork(fetchSectorsDefault),
    fork(fetchNHIFRatesDefault),
    fork(fetchNSSFRatesDefault),
    fork(fetchPAYERatesDefault)  ,
    fork(addBankDefault)  ,
    fork(editBankDefault)  ,
    fork(deleteBankDefault),
    fork(fetchAccountSettingsDefault),
    fork(updateAccountSettingsDefault),
    fork(fetchAdminSystemSettingsDefault),
    fork(updateAdminSystemSettingsDefault),
    fork(fetchSubscriptionPlansDefault),
    fork(addBankBranchDefault),
    fork(editBankBranchDefault),
  ]);
}
