import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_ROLE, DELETE_ROLE, deleteRoleSuccessGenerator, EDIT_ROLE, editRoleSuccessGenerator,
  FETCH_PERMISSIONS,
  FETCH_ROLES,
  fetchPermissionsSuccessGenerator,
  fetchRolesSuccessGenerator
} from "../../actions/common/RoleActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addRoleSuccessGenerator
} from "../../actions/common/RoleActions";
import { push } from 'connected-react-router';
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchRolesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/roles',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchPermissionsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/permissions',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addRoleRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/roles',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteRoleRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/roles/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editRoleRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/home/roles/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchRoles({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchRolesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchRolesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchPermissions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPermissionsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let permissionsByAppNameByItemByID = response.data.data;
        yield put(fetchPermissionsSuccessGenerator(permissionsByAppNameByItemByID));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addRole({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addRoleRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(addRoleSuccessGenerator(role));
        let pathname = window.location.pathname;
        if(pathname.startsWith('/attendance')){
          yield put(push('/attendance/roles'));
        }else if(pathname.startsWith('/hrm')){
          yield put(push('/hrm/roles'));
        }else if(pathname.startsWith('/payouts')){
          yield put(push('/payouts/roles'));
        }else if(pathname.startsWith('/appraisals')){
          yield put(push('/appraisals/roles'));
        }else if(pathname.startsWith('/common')){
          yield put(push('/common/roles'));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteRole({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteRoleRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(deleteRoleSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editRole({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editRoleRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(payload.action === 'update-admin'){
          yield put(push('/admin/administrators'));
        }else{
          let role = response.data.data;
          yield put(editRoleSuccessGenerator(role));
          let pathname = window.location.pathname;
          if(pathname.startsWith('/attendance')){
            yield put(push('/attendance/roles'));
          }else if(pathname.startsWith('/hrm')){
            yield put(push('/hrm/roles'));
          }else if(pathname.startsWith('/payouts')){
            yield put(push('/payouts/roles'));
          }else if(pathname.startsWith('/appraisals')){
            yield put(push('/appraisals/roles'));
          }else if(pathname.startsWith('/common')){
            yield put(push('/common/roles'));
          }
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchRolesDefault() {
  yield takeLatest(FETCH_ROLES, fetchRoles)
}
export function* fetchPermissionsDefault() {
  yield takeLatest(FETCH_PERMISSIONS, fetchPermissions)
}
export function* addRoleDefault() {
  yield takeLatest(ADD_ROLE, addRole);
}
export function* deleteRoleDefault() {
  yield takeLatest(DELETE_ROLE, deleteRole);
}
export function* editRoleDefault() {
  yield takeLatest(EDIT_ROLE, editRole);
}
export default function* rootSaga() {
  yield all([fork(fetchRolesDefault),
    fork(fetchPermissionsDefault),
    fork(addRoleDefault),
    fork(deleteRoleDefault),
    fork(editRoleDefault)
  ]);
}
