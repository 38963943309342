import {all, call, fork, put, takeLatest} from "redux-saga/effects";

import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {
  ADD_CUSTOM_HOURS,
  DELETE_CUSTOM_HOURS,
  EDIT_CUSTOM_HOURS,
  FETCH_CUSTOM_HOURS, FETCH_TEAMS,
  fetchCustomHoursGenerator,
  fetchCustomHoursSuccessGenerator, fetchTeamsSuccessGenerator,
  UPLOAD_CUSTOM_HOURS
} from "../../actions/attendance/CustomHoursActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import { BETA_API_ENDPOINT } from "../../../constants/ActionTypes";


const fetchCustomHoursRequest = async (requestParameters)=>
  await axios.get(BETA_API_ENDPOINT+"/hrm/employees/custom-hours",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

const uploadCustomHoursRequest = async (requestData) =>
  await axios.post(BETA_API_ENDPOINT+'/hrm/employees/custom-hours/upload',requestData,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);
const addCustomHoursRequest = async (requestData) =>
  await  axios.post(BETA_API_ENDPOINT+'/hrm/employees/custom-hours',requestData)
    .then(response => response)
    .catch(error => error.response);
const editCustomHoursRequest = async (requestData) =>
  await  axios.put(BETA_API_ENDPOINT+'/hrm/employees/custom-hours/'+requestData.id,requestData)
    .then(response => response)
    .catch(error => error.response);
const deleteCustomHoursRequest = async (requestParameters) =>
  await  axios.delete(BETA_API_ENDPOINT+'/hrm/employees/custom-hours/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchTeamsRequest = async (requestParameters)=>
  await axios.get(BETA_API_ENDPOINT+"/hrm/employees/teams",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);


function* uploadCustomHours({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParams);
    const response = yield call(uploadCustomHoursRequest,formData);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchCustomHoursGenerator(payload));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


function* fetchCustomHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchCustomHoursRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        //yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchCustomHoursSuccessGenerator(response.data.data));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchTeams({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchTeamsRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        //yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchTeamsSuccessGenerator(response.data.data));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editCustomHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(editCustomHoursRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchCustomHoursGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteCustomHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteCustomHoursRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchCustomHoursGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addCustomHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(addCustomHoursRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchCustomHoursGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


export function* uploadCustomHoursDefault(){
  yield takeLatest(UPLOAD_CUSTOM_HOURS,uploadCustomHours);
}

export function* fetchCustomHoursDefault(){
  yield(takeLatest(FETCH_CUSTOM_HOURS,fetchCustomHours));
}
export function* editCustomHoursDefault(){
  yield(takeLatest(EDIT_CUSTOM_HOURS,editCustomHours));
}
export function* deleteCustomHoursDefault(){
  yield(takeLatest(DELETE_CUSTOM_HOURS,deleteCustomHours));
}
export function* addCustomHoursDefault(){
  yield(takeLatest(ADD_CUSTOM_HOURS,addCustomHours));
}
export function* fetchTeamsDefault(){
  yield(takeLatest(FETCH_TEAMS,fetchTeams));
}

export default function* rootSaga() {
  yield all([
    fork(fetchCustomHoursDefault),
    fork(uploadCustomHoursDefault),
    fork(editCustomHoursDefault),
    fork(deleteCustomHoursDefault),
    fork(addCustomHoursDefault),
    fork(fetchTeamsDefault)
  ]);
}
