import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  APPROVE_COMPANIES_SALARY_ADVANCE_SETTINGS,
  APPROVE_GLOBAL_SALARY_ADVANCE_SETTINGS,
  CREATE_ADMIN_SALARY_ADVANCE_SETTINGS, CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS, CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS, DELETE_ADMIN_SALARY_ADVANCE_SETTINGS,
  FETCH_ADMIN_SALARY_ADVANCE_SETTINGS,
  FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS,
  FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS,
  fetchAdminSalaryAdvanceGenerator,
  fetchAdminSalaryAdvanceSuccessGenerator, fetchCompaniesSalaryAdvanceSettingsGenerator, fetchCompaniesSalaryAdvanceSettingsSuccessGenerator, fetchGlobalSalaryAdvanceSettingsGenerator, fetchGlobalSalaryAdvanceSettingsSuccessGenerator, REJECT_COMPANIES_SALARY_ADVANCE_SETTINGS, REJECT_GLOBAL_SALARY_ADVANCE_SETTINGS, UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS, UPDATE_COMPANIES_SALARY_ADVANCE_SETTINGS, UPDATE_GLOBAL_SALARY_ADVANCE_SETTINGS
} from "../../actions/admin/SalaryAdvancesSettingsAction";

const fetchAdminSalaryAdvanceRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/companies-salary-advances',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addAdminSalaryAdvanceRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/companies-salary-advances',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteAdminSalaryAdvanceRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/companies-salary-advances/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editAdminSalaryAdvanceRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/companies-salary-advances/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

/** Salary advance companies settings */
const fetchCompaniesSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/companies-salary-advances-settings',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addCompaniesSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/companies-salary-advances-settings',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editCompaniesSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+`/companies-salary-advances-settings/${requestParameters.id}`,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const approveCompaniesSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+`/companies-salary-advances-settings/${requestParameters.id}/approve`,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const rejectCompaniesSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+`/companies-salary-advances-settings/${requestParameters.id}/reject`,requestParameters)
    .then(response => response)
    .catch(error => error.response);

/** Salary advance global settings */
const fetchGlobalSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/global-salary-advances-settings',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addGlobalSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/global-salary-advances-settings',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editGlobalSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+`/global-salary-advances-settings/${requestParameters.id}`,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const approveGlobalSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+`/global-salary-advances-settings/${requestParameters.id}/approve`,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const rejectGlobalSalaryAdvanceSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+`/global-salary-advances-settings/${requestParameters.id}/reject`,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchAdminSalaryAdvance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAdminSalaryAdvanceRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchAdminSalaryAdvanceSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addAdminSalaryAdvance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addAdminSalaryAdvanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAdminSalaryAdvanceGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteAdminSalaryAdvance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteAdminSalaryAdvanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAdminSalaryAdvanceGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editAdminSalaryAdvance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAdminSalaryAdvanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAdminSalaryAdvanceGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

/** Salary advance companies settings */
function* fetchCompaniesSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchCompaniesSalaryAdvanceSettingsRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let dataById = response.data.data;
        yield put(fetchCompaniesSalaryAdvanceSettingsSuccessGenerator(dataById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addCompaniesSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addCompaniesSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompaniesSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editCompaniesSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editCompaniesSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompaniesSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* approveCompaniesSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(approveCompaniesSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompaniesSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* rejectCompaniesSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(rejectCompaniesSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompaniesSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

/** Salary advance global settings */
function* fetchGlobalSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchGlobalSalaryAdvanceSettingsRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let dataById = response.data.data;
        yield put(fetchGlobalSalaryAdvanceSettingsSuccessGenerator(dataById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addGlobalSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addGlobalSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchGlobalSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editGlobalSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editGlobalSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchGlobalSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* approveGlobalSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(approveGlobalSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchGlobalSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* rejectGlobalSalaryAdvanceSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(rejectGlobalSalaryAdvanceSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchGlobalSalaryAdvanceSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchAdminSalaryAdvanceDefault() {
  yield takeLatest(FETCH_ADMIN_SALARY_ADVANCE_SETTINGS, fetchAdminSalaryAdvance)
}
export function* addAdminSalaryAdvanceDefault() {
  yield takeLatest(CREATE_ADMIN_SALARY_ADVANCE_SETTINGS, addAdminSalaryAdvance);
}
export function* deleteAdminSalaryAdvanceDefault() {
  yield takeLatest(DELETE_ADMIN_SALARY_ADVANCE_SETTINGS, deleteAdminSalaryAdvance);
}
export function* editAdminSalaryAdvanceDefault() {
  yield takeLatest(UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS, editAdminSalaryAdvance);
}

/** Salary advance companies settings */
export function* fetchCompaniesSalaryAdvanceSettingsDefault() {
  yield takeLatest(FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS, fetchCompaniesSalaryAdvanceSettings);
}
export function* addCompaniesSalaryAdvanceSettingsDefault() {
  yield takeLatest(CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS, addCompaniesSalaryAdvanceSettings);
}
export function* editCompaniesSalaryAdvanceSettingsDefault() {
  yield takeLatest(UPDATE_COMPANIES_SALARY_ADVANCE_SETTINGS, editCompaniesSalaryAdvanceSettings);
}
export function* approveCompaniesSalaryAdvanceSettingsDefault() {
  yield takeLatest(APPROVE_COMPANIES_SALARY_ADVANCE_SETTINGS, approveCompaniesSalaryAdvanceSettings);
}
export function* rejectCompaniesSalaryAdvanceSettingsDefault() {
  yield takeLatest(REJECT_COMPANIES_SALARY_ADVANCE_SETTINGS, rejectCompaniesSalaryAdvanceSettings);
}

/** Salary advance global settings */
export function* fetchGlobalSalaryAdvanceSettingsDefault() {
  yield takeLatest(FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS, fetchGlobalSalaryAdvanceSettings);
}
export function* addGlobalSalaryAdvanceSettingsDefault() {
  yield takeLatest(CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS, addGlobalSalaryAdvanceSettings);
}
export function* editGlobalSalaryAdvanceSettingsDefault() {
  yield takeLatest(UPDATE_GLOBAL_SALARY_ADVANCE_SETTINGS, editGlobalSalaryAdvanceSettings);
}
export function* approveGlobalSalaryAdvanceSettingsDefault() {
  yield takeLatest(APPROVE_GLOBAL_SALARY_ADVANCE_SETTINGS, approveGlobalSalaryAdvanceSettings);
}
export function* rejectGlobalSalaryAdvanceSettingsDefault() {
  yield takeLatest(REJECT_GLOBAL_SALARY_ADVANCE_SETTINGS, rejectGlobalSalaryAdvanceSettings);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAdminSalaryAdvanceDefault),
    fork(addAdminSalaryAdvanceDefault),
    fork(deleteAdminSalaryAdvanceDefault),
    fork(editAdminSalaryAdvanceDefault),
    
    fork(fetchCompaniesSalaryAdvanceSettingsDefault),
    fork(addCompaniesSalaryAdvanceSettingsDefault),
    fork(editCompaniesSalaryAdvanceSettingsDefault),
    fork(approveCompaniesSalaryAdvanceSettingsDefault),
    fork(rejectCompaniesSalaryAdvanceSettingsDefault),

    fork(fetchGlobalSalaryAdvanceSettingsDefault),
    fork(addGlobalSalaryAdvanceSettingsDefault),
    fork(editGlobalSalaryAdvanceSettingsDefault),
    fork(approveGlobalSalaryAdvanceSettingsDefault),
    fork(rejectGlobalSalaryAdvanceSettingsDefault),
  ]);
}
