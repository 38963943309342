import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {TextField} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import {connect} from 'react-redux';
import {Helpers} from '../../util/Helpers';
import {addCompanyGenerator} from '../../appRedux/actions/common/CompanyActions';
import CompanyLogo from './companyLogo';
import {
  fetchBanksGenerator,
  fetchCountriesGenerator,
  fetchCurrenciesGenerator,
  fetchSectorsGenerator
} from '../../appRedux/actions/GeneralActions';
import { AUTH_DEFAULT_APP, AUTH_SWITCHED_APP, SELECTED_COMPANY_KEY } from "../../constants/SessionKeys";
import {setDefaultAppGenerator} from "../../appRedux/actions/AuthActions";
import moment from 'moment';

const styles = theme => ({
  paper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)"
  },
  title: {
    display: "flex"
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  fab: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 150,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    size: "small",
    maxWidth: "auto",
    maxHeight: "20px",
    minWidth: "auto",
    minHeight: "20px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  halfWidth: {
    width: "45%",
    margin: 8
  },
  oneThirdWidth: {
    width: "30%",
    margin: 5
  },
  formControl: {
    width: "100%"
  }
});

const apps = Helpers.apps();

class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    const bankDetails = props.bankDetails ? props.bankDetails : {};
    const address = props.address ? props.address : {};
    const userAddress = Helpers.authUserAddress();
    const mobile_no = userAddress.mobile_no;
    const email = userAddress.email;
    const selected_company_id = Helpers.getItem(SELECTED_COMPANY_KEY, Helpers.authSwitchedCompanyID());
    let company;
    if (!Helpers.isNull(selected_company_id)){
       company = this.props.companiesByID[selected_company_id];
    }else{
      company = {}
    }
    this.state = {
      id: props.id,
      name: props.name,
      action: props.action,
      value: 0,
      employee_no_prefix: props.employee_no_prefix,
      pin_no: props.pin_no,
      nssf_no: props.nssf_no,
      nhif_no: props.nhif_no,
      sector_id: props.sector_id|| 21,
      app_id: props.defaultApp?props.defaultApp.app_id: 0,
      date_started: props.date_started||moment().format("YYYY-MM-DD"),
      currency_id: props.currency_id|| 56,
      city: address.city,
      county: address.county || '',
      email: address.email || email,
      mobile_no: address.mobile_no || mobile_no,
      website: address.website,
      postal_address: address.postal_address,
      zip_code: address.zip_code,
      road: address.road,
      country_id: address.country_id||110,
      bank_id: bankDetails.bank_id,
      branch_id: bankDetails.branch_id,
      account_name: bankDetails.account_name,
      account_no: bankDetails.account_no,
      head_office_id: company.id||''
    };
  }

  componentDidMount() {
    this.props.fetchCurrenciesGenerator();
    this.props.fetchCountriesGenerator();
    this.props.fetchBanksGenerator();
    this.props.fetchSectorsGenerator();
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  atHRM=()=>{
    return window.location.pathname === '/hrm/companies';
  };

  atTA=()=>{
    return window.location.pathname === '/attendance/companies';
  };

  atPayouts=()=>{
    return window.location.pathname === '/payouts/companies';
  };

  atOnboarding=()=>{
    return window.location.pathname === '/onboarding';
  };

  handleSubmit = () => {
    this.setState({
      nextClicked:true
    });
    const validateFields = {name:{},sector_id: {},country_id:{}};
    if(!Helpers.formValidations(validateFields,this.state)){
      return;
    }
    const data = { ...this.state };
    data.switch_to = "yes";

    this.props.addCompanyGenerator(data);

    if(!this.atOnboarding && (this.atHRM || this.atTA || this.atPayouts)){
      let dataSubmitted = true;
      this.props.closeModal(dataSubmitted);
    }
  };

  updateState = e => {
    const { id, name, value } = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState });
  };

  updateLogoState =(keyValueObject)=>{
    const newState = Object.assign({}, this.state, keyValueObject);
    this.setState({ ...newState });
  } ;
  renderMenuItems = data =>
    data.map(s => (
      <MenuItem key={s.id} value={s.id}>
        {s.name}
      </MenuItem>
    ));

   updateSelectChange =(name,e)=>{
      this.setState({
         [name]:e.value
      }) ;
      if(name === 'app_id'){
        const data = { selected_app_id: e.value };
        Helpers.saveItem(AUTH_DEFAULT_APP, {app_id: data.selected_app_id});
        const app = Helpers.app(data.selected_app_id);
        Helpers.saveItem(AUTH_SWITCHED_APP, app);
        this.props.setDefaultAppGenerator(data);
      }
   };
  render() {
    const { classes } = this.props;
    let {
      id,
      name,
      action,
      value,
      employee_no_prefix,
      pin_no,
      nssf_no,
      nhif_no,
      sector_id,
      app_id,
      date_started,
      currency_id,
      city,
      county,
      email,
      mobile_no,
      website,
      postal_address,
      zip_code,
      road,
      country_id,
      bank_id,
      branch_id,
      account_name,
      account_no ,logo,
      nextClicked
    } = this.state;
    let currenciesByID = Helpers.currencies();
    if (!currenciesByID) {
      currenciesByID = {};
    }
    let sectorsByID = Helpers.sectors();
    if (!sectorsByID) {
      sectorsByID = {};
    }
    let countriesByID = Helpers.countries();
    if (!countriesByID) {
      countriesByID = {};
    }
    let banksByID = Helpers.banks();
    if (!banksByID) {
      banksByID = {};
    }
    if (!country_id) {
      Object.keys(countriesByID).map(key => {
        country_id = key;
      });
    }
    if (!currency_id) {
      Object.keys(currenciesByID).map(key => {
        currency_id = key;
      });
    }

    const bank_branches = Helpers.bank_branches(banksByID, bank_id);
    const defaultCurrency = {label:Helpers.recordFromIndexedData(currenciesByID,currency_id).name,value:currency_id};
    const defaultCountry = {label:Helpers.recordFromIndexedData(countriesByID,country_id).name,value:country_id};
    const defaultSector= {label:Helpers.recordFromIndexedData(sectorsByID,sector_id).name,value:sector_id};
    const appDefault = {label:Helpers.recordFromIndexedData(apps,app_id).name,value:app_id}  ;
    const validationMessage = "This field is required!";
    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off">
          <div className="row">
            <div className="col-12">
              <CompanyLogo {...this.state} updateLogoState={this.updateLogoState}/>
            </div>
            <div className="col-12">
              <TextField
                label="Company Name"
                placeholder="Company Name"
                id="name"
                name="name"
                value={name}
                required
                fullWidth
                margin="dense"
                onChange={this.updateState}
              />
              <span className="error text-danger">
              {nextClicked && (!name || name.length === 0)
                ? validationMessage
                : ' '}
            </span>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <FormControl fullWidth margin="normal" placeholder="Sector">
                <InputLabel htmlFor="sector_id" className="select-label">Sector</InputLabel>
                <Select
                    name="sector_id"
                    defaultValue={defaultSector}
                    onChange={this.updateSelectChange.bind(this,'sector_id')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    options={Helpers.renderMenuItems(Object.values(sectorsByID))}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      color: 'green',
                      colors: {
                        ...theme.colors,
                        primary: '#3CB534',
                        primary25: '#E2F4E0',
                        primary50: '#E2F4E0'
                      }
                    })}
                />
              </FormControl>
              <span className="error text-danger">
              {nextClicked && (!sector_id || sector_id.length === 0)
                ? validationMessage
                : ' '}
            </span>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <FormControl fullWidth margin="normal" placeholder="Choose Default App">
                <InputLabel htmlFor="currency" className="select-label">Choose Default App</InputLabel>
                <Select
                  name="app_id"
                  defaultValue={appDefault}
                  onChange={this.updateSelectChange.bind(this,'app_id')}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={Helpers.renderMenuItems(Object.values(apps))}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    color: 'green',
                    colors: {
                      ...theme.colors,
                      primary: '#3CB534',
                      primary25: '#E2F4E0',
                      primary50: '#E2F4E0'
                    }
                  })}
                />
              </FormControl>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <FormControl fullWidth margin="normal" placeholder="Country">
                <InputLabel htmlFor="currency" className="select-label">Country</InputLabel>
                <Select
                  name="country_id"
                  defaultValue={defaultCountry}
                  onChange={this.updateSelectChange.bind(this,'country_id')}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={Helpers.renderMenuItems(Object.values(countriesByID))}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    color: 'green',
                    colors: {
                      ...theme.colors,
                      primary: '#3CB534',
                      primary25: '#E2F4E0',
                      primary50: '#E2F4E0'
                    }
                  })}
                />
              </FormControl>
              <span className="error text-danger">
              {nextClicked && (!country_id || country_id.length === 0)
                ? validationMessage
                : ' '}
            </span>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <FormControl fullWidth margin="normal" placeholder="Currency">
                <InputLabel htmlFor="currency" className="select-label">Currency</InputLabel>
                <Select
                    name="currency_id"
                    isSearchable={true}
                    defaultValue={defaultCurrency}
                    onChange={this.updateSelectChange.bind(this,'currency_id')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    options={Helpers.renderMenuItems(Object.values(currenciesByID))}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      color: 'green',
                      colors: {
                        ...theme.colors,
                        primary: '#3CB534',
                        primary25: '#E2F4E0',
                        primary50: '#E2F4E0'
                      }
                    })}
                />
              </FormControl>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                label="Address"
                placeholder="Address"
                id="postal_address"
                name="postal_address"
                value={postal_address}
                fullWidth
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                label="City"
                placeholder="City"
                id="city"
                name="city"
                value={city}
                fullWidth
                margin="normal"
                onChange={this.updateState}
              />
            </div>{/*
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                label="Email Address"
                placeholder="Email Address"
                id="email"
                name="email"
                value={email}
                type="email"
                fullWidth
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                label="Mobile Number"
                placeholder="Mobile Number"
                id="mobile_no"
                name="mobile_no"
                value={mobile_no}
                type="number"
                fullWidth
                margin="normal"
                onChange={this.updateState}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <TextField
                label="Postal Code"
                placeholder="Postal Code"
                id="zip_code"
                name="zip_code"
                value={zip_code}
                fullWidth
                margin="normal"
                onChange={this.updateState}
              />
            </div>*/}
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                style={{ marginTop: 10 }}
              >
                {
                  Helpers.isNull(Helpers.getItem(SELECTED_COMPANY_KEY, Helpers.authSwitchedCompanyID()))?'Next':'Submit'
                }
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = ({ general,auth,company }) => {
  const { currenciesByID, sectorsByID, countriesByID, banksByID } = general;
  const {companiesByID}= company;
  const {defaultApp} =auth;
  return {
    currenciesByID,
    sectorsByID,
    countriesByID,
    banksByID ,
    defaultApp,
    companiesByID
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, {
    addCompanyGenerator,
    fetchCurrenciesGenerator,
    fetchCountriesGenerator,
    fetchBanksGenerator,
    fetchSectorsGenerator ,
    setDefaultAppGenerator
  })(AddCompany)
);
