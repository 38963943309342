import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_LOAN_BOOK_BALANCE,
  FETCH_LOANS_ISSUED,
  FETCH_OVERDUE_LOANS,
  FETCH_TOP_BORROWERS,
  fetchLoanBookBalanceSuccessGenerator,
  fetchLoansIssuedSuccessGenerator,
  fetchOverdueLoansSuccessGenerator,
  fetchTopBorrowersSuccessGenerator
} from "../../actions/admin/LoanDashboardActions";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";

const fetchLoanBookBalanceRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/dashboard/loan_book',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchLoansIssuedRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/dashboard/loans_issued',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchOverdueLoansRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/dashboard/loans_overdue',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchTopBorrowersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/dashboard/top_borrowers',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchLoanBookBalance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoanBookBalanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchLoanBookBalanceSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLoansIssued({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoansIssuedRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchLoansIssuedSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchOverdueLoans({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchOverdueLoansRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchOverdueLoansSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchTopBorrowers({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchTopBorrowersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchTopBorrowersSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchLoanBookBalanceDefault() {
  yield takeLatest(FETCH_LOAN_BOOK_BALANCE, fetchLoanBookBalance);
}
export function* fetchLoansIssuedDefault() {
  yield takeLatest(FETCH_LOANS_ISSUED, fetchLoansIssued);
}
export function* fetchOverdueLoansDefault() {
  yield takeLatest(FETCH_OVERDUE_LOANS, fetchOverdueLoans);
}
export function* fetchTopBorrowersDefault() {
  yield takeLatest(FETCH_TOP_BORROWERS, fetchTopBorrowers);
}
export default function* rootSaga() {
  yield all([fork(fetchLoanBookBalanceDefault),
    fork(fetchLoansIssuedDefault),
    fork(fetchOverdueLoansDefault),
    fork(fetchTopBorrowersDefault)
  ]);
}
