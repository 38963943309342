import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_PAID_LEAVE, DELETE_PAID_LEAVE, deletePaidLeaveSuccessGenerator, EDIT_PAID_LEAVE, editPaidLeaveSuccessGenerator,
  FETCH_PAID_LEAVES, fetchPaidLeavesGenerator,
  fetchPaidLeavesSuccessGenerator
} from "../../actions/payroll/PaidLeaveActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addPaidLeaveSuccessGenerator
} from "../../actions/payroll/PaidLeaveActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";


const fetchPaidLeavesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/paidleaves',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPaidLeaveRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/paidleaves',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePaidLeaveRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/paidleaves/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPaidLeaveRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/paidleaves/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPaidLeaves({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPaidLeavesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchPaidLeavesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPaidLeave({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addPaidLeaveRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let role = response.data.data;
        yield put(fetchPaidLeavesGenerator());
        //yield put(addPaidLeaveSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePaidLeave({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePaidLeaveRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(fetchPaidLeavesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPaidLeave({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editPaidLeaveRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchPaidLeavesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPaidLeavesDefault() {
  yield takeLatest(FETCH_PAID_LEAVES, fetchPaidLeaves)
}
export function* addPaidLeaveDefault() {
  yield takeLatest(ADD_PAID_LEAVE, addPaidLeave);
}
export function* deletePaidLeaveDefault() {
  yield takeLatest(DELETE_PAID_LEAVE, deletePaidLeave);
}
export function* editPaidLeaveDefault() {
  yield takeLatest(EDIT_PAID_LEAVE, editPaidLeave);
}
export default function* rootSaga() {
  yield all([fork(fetchPaidLeavesDefault),
    fork(addPaidLeaveDefault),
    fork(deletePaidLeaveDefault),
    fork(editPaidLeaveDefault)
  ]);
}
