import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import {
  ALERT_ADMIN_TO_CLEAR,
  ALERT_ADMIN_TO_RETRY,
  APPROVE_TOPUP,
  CONFIRM_TOPUP_TRANSACTION,
  confirmTopUpTransactionSuccessGenerator,
  EDIT_TRANSACTION, EXPORT_ACCOUNT_STATEMENT, EXPORT_ADMIN_TRANSACTIONS,
  EXPORT_DAILY_RECONCILIATION,
  EXPORT_TRANSACTIONS,
  FETCH_DAILY_RECONCILIATION,
  FETCH_TRANSACTION_DETAILS,
  FETCH_TRANSACTIONS,
  fetchDailyReconciliationSuccessGenerator,
  fetchTransactionDetailsSuccessGenerator,
  fetchTransactionsSuccessGenerator,
  RECONCILE,
  REJECT_TOPUP,
  RETRY_TRANSACTION, transactionCustomFiltersGenerator, transactionFiltersGenerator
} from "../../actions/payouts/TransactionActions";
import {
  fetchAllTransactionsGenerator,
  fetchAllTransactionsSuccessGenerator
} from "../../actions/admin/CompanyActions";

const fetchTransactionsRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/transactions',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchDailyReconciliationRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/daily/reconciliation',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const exportDailyReconciliationRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/daily/reconciliation/export',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const reconcileRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/daily/reconcile',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const retryTransactionRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/transaction/retry',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const editTransactionRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/transaction/edit',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchTransactionDetailsRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/transaction-details',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const confirmTopUpTransactionRequest =  async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/transaction/confirm',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const exportTransactionsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/transaction/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const exportAccountStatementRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/transactions/export/'+requestParameters.company_id+'/'+requestParameters.export_type,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const exportAdminTransactionsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/admin/transactions/export',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const alertAdminToRetryTransactionRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/transaction/alert/retry',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const alertAdminToClearTransactionRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/transaction/alert/clear',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const approveTopupRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/topups/approve',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const rejectTopupRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/topups/reject',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* confirmTopUpTransaction({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(confirmTopUpTransactionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(confirmTopUpTransactionSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
        if(location.pathname.startsWith('/admin/transactions')){
          yield put(fetchAllTransactionsGenerator({recordsPerPage:20}));
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchTransactions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchTransactionsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchTransactionsSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchDailyReconciliation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDailyReconciliationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchDailyReconciliationSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportDailyReconciliation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportDailyReconciliationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportAdminTransactions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportAdminTransactionsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* reconcile({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(reconcileRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchDailyReconciliationSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* retryTransactions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(retryTransactionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let isTransactionLogs = payload.data_type && payload.data_type === 'transaction_log';
        if(isTransactionLogs){
           //do nothing
        }else{
          let isFromTransactionDetails = payload.from && payload.from === 'details';
          if(isFromTransactionDetails){
            yield put(fetchTransactionDetailsSuccessGenerator(response.data.data));
          }else{
            yield put(fetchAllTransactionsSuccessGenerator(response.data.data));//this fine, do not panic
          }
        }
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editTransaction({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editTransactionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAllTransactionsGenerator({recordsPerPage:20}));
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportAccountStatement({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportAccountStatementRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        // window.open(API_ADDRESS+response.data.data, '_blank');
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportTransactions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportTransactionsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        // window.open(API_ADDRESS+response.data.data, '_blank');
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchTransactionDetails({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchTransactionDetailsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield  put(transactionFiltersGenerator(response.data.filters));
        yield put(fetchTransactionDetailsSuccessGenerator(response.data.data));
        yield put(transactionCustomFiltersGenerator(requestParameters));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* alertAdminToRetryTransaction({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(alertAdminToRetryTransactionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* alertAdminToClearTransaction({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(alertAdminToClearTransactionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* approveTopup({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(approveTopupRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAllTransactionsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* rejectTopup({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(rejectTopupRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAllTransactionsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* confirmTopUpTransactionsDefault() {
  yield takeLatest(CONFIRM_TOPUP_TRANSACTION, confirmTopUpTransaction);
}
export function* fetchTransactionsDefault() {
  yield takeLatest(FETCH_TRANSACTIONS, fetchTransactions);
}
export function* fetchDailyReconciliationDefault() {
  yield takeLatest(FETCH_DAILY_RECONCILIATION, fetchDailyReconciliation);
}
export function* exportDailyReconciliationDefault() {
  yield takeLatest(EXPORT_DAILY_RECONCILIATION, exportDailyReconciliation);
}
export function* reconcileDefault() {
  yield takeLatest(RECONCILE, reconcile);
}
export function* retryTransactionDefault() {
  yield takeLatest(RETRY_TRANSACTION, retryTransactions);
}
export function* editTransactionDefault() {
  yield takeLatest(EDIT_TRANSACTION, editTransaction);
}
export function* fetchTransactionDetailsDefault() {
  yield takeLatest(FETCH_TRANSACTION_DETAILS, fetchTransactionDetails);
}
export function* exportAccountStatementDefault() {
  yield takeLatest(EXPORT_ACCOUNT_STATEMENT, exportAccountStatement);
}
export function* exportTransactionsDefault() {
  yield takeLatest(EXPORT_TRANSACTIONS, exportTransactions);
}
export function* alertAdminToRetryTransactionDefault() {
  yield takeLatest(ALERT_ADMIN_TO_RETRY, alertAdminToRetryTransaction);
}
export function* alertAdminToClearTransactionDefault() {
  yield takeLatest(ALERT_ADMIN_TO_CLEAR, alertAdminToClearTransaction);
}
export function* exportAdminTransactionsDefault() {
  yield takeLatest(EXPORT_ADMIN_TRANSACTIONS, exportAdminTransactions);
}
export function* approveTopupDefault() {
  yield takeLatest(APPROVE_TOPUP, approveTopup);
}
export function* rejectTopupDefault() {
  yield takeLatest(REJECT_TOPUP, rejectTopup);
}

export default function* rootSaga() {
  yield all([fork(fetchTransactionsDefault),
    fork(fetchTransactionDetailsDefault),
    fork(confirmTopUpTransactionsDefault),
    fork(retryTransactionDefault),
    fork(exportTransactionsDefault),
    fork(editTransactionDefault),
    fork(alertAdminToRetryTransactionDefault),
    fork(alertAdminToClearTransactionDefault),
    fork(fetchDailyReconciliationDefault),
    fork(exportDailyReconciliationDefault),
    fork(reconcileDefault),
    fork(exportAdminTransactionsDefault),
    fork(exportAccountStatementDefault),
    fork(approveTopupDefault),
    fork(rejectTopupDefault),
  ]);
}
