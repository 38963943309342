import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import IntlMessages from 'util/IntlMessages';
import {Helpers} from '../util/Helpers';
import BackGroundLoader from '../components/common/BackGroundLoader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useOtpGenerator} from "../appRedux/actions/common/UserActions";

const useStyles = theme => ({
  buttonProgress: {
    color: '#fff',
    marginRight: 5,
  },
  eye: {
    cursor: 'pointer',
  },

});

class OTPonLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      buttonLoading:false,
    };
  }

  render() {
    const {
      password,
      buttonLoading,
    } = this.state;

    const {classes } =this.props;

    const loginClicked = () => {
      this.setState({buttonLoading:true});
       let data ={...this.state};
       data.info = this.props.location.state.data ? this.props.location.state.data : { };
      this.props.useOtpGenerator(data);
    };

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          {
            Helpers.loading(this.props)
            && (
              <BackGroundLoader />
            )
          }

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="">
              <img src={require('assets/images/logo.png')} alt="" title="" style={{ height:"40px"}} />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-1 text-center text-muted">
              <p><IntlMessages id="appModule.verifyDesc" /></p>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.verificationCode" />}
                    placeholder="Verification Code"
                    fullWidth
                    onChange={event => this.setState({ password: event.target.value })}
                    defaultValue={password}
                    margin="normal"
                    className="mt-0 mb-3"
                  />
                  <div className="d-flex align-items-center justify-content-between">
                    <Button
                      onClick={loginClicked}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={buttonLoading&&Helpers.loading(this.props)}>
                      {(Helpers.loading(this.props)&&buttonLoading)&&<CircularProgress size={24} className={classes.buttonProgress} />} <IntlMessages id="appModule.submit" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({general}) => {
  let {appLoading} = general;
  return {
    appLoading
  };
};

export default withStyles(useStyles)(connect(mapStateToProps,{useOtpGenerator})(OTPonLogin));
