import {
    FETCH_SUBSCRIPTION_PRICINGS_SUCCESS,
    UPDATE_SUBSCRIPTION_PRICING_SUCCESS
} from "../../actions/admin/SubscriptionPricingActions";

const INIT_STATE ={
    pricing:{}
};
export default (state = INIT_STATE,action)=>{
    let pricing;
    switch (action.type) {
        case FETCH_SUBSCRIPTION_PRICINGS_SUCCESS:
            return {
                ...state,
                pricing: action.payload
            };
        case UPDATE_SUBSCRIPTION_PRICING_SUCCESS:
            pricing = {...state.pricing};
            pricing[action.payload.id] = action.payload;
            return{
                ...state,
                pricing:pricing
            };
        default:
            return {...state};
    }
}