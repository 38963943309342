import {
  ADD_DEPARTMENT_KPI_SUCCESS,
  DELETE_DEPARTMENT_KPI_SUCCESS,
  EDIT_DEPARTMENT_KPI_SUCCESS,
  FETCH_DEPARTMENT_KPIs_SUCCESS
} from '../../actions/appraisal/DepartmentKPIActions';

const INIT_STATE = {
  departmentKPIsByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_DEPARTMENT_KPIs_SUCCESS:
      return {
        ...state,
        departmentKPIsByID: action.payload
      };
    case ADD_DEPARTMENT_KPI_SUCCESS:
    case EDIT_DEPARTMENT_KPI_SUCCESS:
      let departmentKPI_id = action.payload.id;
      return {
        ...state,
        departmentKPIsByID: {
          ...state.departmentKPIsByID,
          [departmentKPI_id]:action.payload
        }
      };
    case DELETE_DEPARTMENT_KPI_SUCCESS:
      let departmentKPIsByID = {...state.departmentKPIsByID};
      delete departmentKPIsByID[action.payload.id];
      return {
        ...state,
        departmentKPIsByID:departmentKPIsByID
      };
    default:
      return state;
  }
}
