import {
  ADD_LOANS_SUCCESS,
  DELETE_LOANS_SUCCESS, DELETE_MY_COMPANY_LOAN_SUCCESS,
  EDIT_LOANS_SUCCESS, EDIT_MY_COMPANY_LOAN_SUCCESS,
  FETCH_LOANS_SUCCESS, GET_MY_COMPANY_LOAN_SUCCESS,
  HIDE_ACTIVATE_LOAN_FORM,
  HIDE_CREATE_LOANS_FORM,
  HIDE_DEACTIVATE_LOAN_FORM,
  HIDE_DELETE_LOANS_MODAL,
  HIDE_EDIT_LOANS_FORM,
  SHOW_ACTIVATE_LOAN_FORM,
  SHOW_CREATE_LOANS_FORM,
  SHOW_DEACTIVATE_LOAN_FORM,
  SHOW_DELETE_LOANS_MODAL,
  SHOW_EDIT_LOANS_FORM,
  UPLOAD_BULK_LOANS_SUCCESS
} from "../../actions/payroll/LoansActions";

const INIT_STATE = {
  loansByID:[],
  loanToEdit:[],
  myCompanyLoan:[],
  showCreateLoanForm:false,
  showEditLoanForm:false,
  showDeleteLoanModal:false,
  showActivateLoanModal:false,
  showDeactivateLoanModal:false
};

export default (state = INIT_STATE, action)=>{
  let loans;
  let companyLoan;
  switch (action.type){
    case GET_MY_COMPANY_LOAN_SUCCESS:
      return {
        ...state,
        myCompanyLoan: action.payload
      };
    case EDIT_MY_COMPANY_LOAN_SUCCESS:
      companyLoan = {...state.myCompanyLoan};
      companyLoan[action.payload.id]=action.payload;
      return {
        ...state,
        myCompanyLoan: companyLoan
      }
    case DELETE_MY_COMPANY_LOAN_SUCCESS:
      companyLoan = {...state.myCompanyLoan};
      delete companyLoan[action.payload.id];
      return {
        ...state,
        myCompanyLoan: companyLoan
      }
    case ADD_LOANS_SUCCESS:
    case EDIT_LOANS_SUCCESS:
      loans = {...state.loansByID};
      loans[action.payload.id]=action.payload;
      return {
        ...state,
        loansByID:loans
      };
    case FETCH_LOANS_SUCCESS:
    case  UPLOAD_BULK_LOANS_SUCCESS:
      return {
        ...state,
        loansByID:action.payload
      };
    case SHOW_CREATE_LOANS_FORM:
      return {
        ...state,
        showCreateLoanForm:true
      };
    case HIDE_CREATE_LOANS_FORM:
      return {
        ...state,
        showCreateLoanForm:false
      };
    case SHOW_EDIT_LOANS_FORM:
      return {
        ...state,
        loanToEdit:action.payload,
        showEditLoanForm:true
      };
    case HIDE_EDIT_LOANS_FORM:
      return {
        ...state,
        showEditLoanForm:false
      };
    case SHOW_DELETE_LOANS_MODAL:
      return {
        ...state,
        loanToEdit:action.payload,
        showDeleteLoanModal:true
      };
    case HIDE_DELETE_LOANS_MODAL:
      return {
        ...state,
        showDeleteLoanModal:false
      };
    case DELETE_LOANS_SUCCESS:
      loans = {...state.loansByID};
      delete loans[action.payload.id]
      return {
        ...state,
        loansByID:loans,
      };
    case SHOW_DEACTIVATE_LOAN_FORM:
      return {
        ...state,
        loanToEdit:action.payload,
        showDeactivateLoanModal:true
      };
    case HIDE_DEACTIVATE_LOAN_FORM:
      return {
        ...state,
        showDeactivateLoanModal:false
      };
    case SHOW_ACTIVATE_LOAN_FORM:
      return {
        ...state,
        loanToEdit:action.payload,
        showActivateLoanModal:true
      };
    case HIDE_ACTIVATE_LOAN_FORM:
      return {
        ...state,
        showActivateLoanModal:false
      };
    default:
      return {
        ...state
      };
  }
}
