export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPLOAD_PRODUCTS_DELIVERED = "UPLOAD_PRODUCTS_DELIVERED";
export const SET_PRODUCTS_DELIVERED = "SET_PRODUCTS_DELIVERED";
export const FETCH_PRODUCTS_DELIVERED = "FETCH_PRODUCTS_DELIVERED";
export const FETCH_PRODUCTS_DELIVERED_SUCCESS = "FETCH_PRODUCTS_DELIVERED_SUCCESS";
export const DOWNLOAD_PRODUCTS_DELIVERED_TEMPLATE = "DOWNLOAD_PRODUCTS_DELIVERED_TEMPLATE";

export const fetchProductsGenerator = (data=[])=>{
  return {
    type:FETCH_PRODUCTS,
    payload:data
  };
};

export const fetchProductsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PRODUCTS_SUCCESS,
    payload:dataById
  }
};


export const addProductGenerator = (data)=>{
  return {
    type: ADD_PRODUCT,
    payload: data
  }
};

export const editProductGenerator = (data)=>{
  return {
    type: EDIT_PRODUCT,
    payload: data
  }
};

export const deleteProductGenerator = (data)=>{
  return {
    type: DELETE_PRODUCT,
    payload: data
  }
};

export const uploadProductsDeliveredGenerator = (data)=>{
  return {
    type: UPLOAD_PRODUCTS_DELIVERED,
    payload: data
  }
};

export const fetchProductsDeliveredGenerator = (data)=>{
  return {
    type: FETCH_PRODUCTS_DELIVERED,
    payload: data
  }
};

export const fetchProductsDeliveredSuccessGenerator = (data)=>{
  return {
    type: FETCH_PRODUCTS_DELIVERED_SUCCESS,
    payload: data
  }
};

export const downloadProductsDeliveredTemplateGenerator = (data)=>{
  return {
    type: DOWNLOAD_PRODUCTS_DELIVERED_TEMPLATE,
    payload: data
  }
};

export const setProductsDeliveredGenerator = (data)=>{
  return {
    type: SET_PRODUCTS_DELIVERED,
    payload: data
  }
};

