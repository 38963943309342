import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  ADD_PETTY_CASH_RECEIPT, addPettyCashReceiptSuccessGenerator,
  DELETE_PETTY_CASH_RECEIPT,
  deletePettyCashReceiptSuccessGenerator,
  EDIT_PETTY_CASH_RECEIPT,
  editPettyCashReceiptSuccessGenerator,
  FETCH_PETTY_CASH_RECEIPT, fetchPettyCashReceiptGenerator, fetchPettyCashReceiptSuccessGenerator
} from "../../actions/payroll/PettyCashReceiptActions";
import { API_ENDPOINT } from "../../../constants/ActionTypes";

const fetchPettyCashReceiptRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/petty-cash/receipts/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPettyCashReceiptRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/receipt/upload',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const deletePettyCashReceiptRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/receipt/delete',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editPettyCashReceiptRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/receipt/edit',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPettyCashReceipt({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPettyCashReceiptRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let petty_cash_receipts = response.data.data;
        yield put(fetchPettyCashReceiptSuccessGenerator(petty_cash_receipts));
        //yield put(showMessageGenerator(response.data.message));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPettyCashReceipt({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(addPettyCashReceiptRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let petty_cash_receipts = response.data.data.payable_id;
        yield put(fetchPettyCashReceiptGenerator({id: petty_cash_receipts}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePettyCashReceipt({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePettyCashReceiptRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let petty_cash_receipts = response.data.data;
        yield put(fetchPettyCashReceiptGenerator({id: petty_cash_receipts.payable_id}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPettyCashReceipt({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editPettyCashReceiptRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let petty_cash_receipts = response.data.data;
        yield put(editPettyCashReceiptSuccessGenerator(petty_cash_receipts));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPettyCashReceiptDefault() {
  yield takeLatest(FETCH_PETTY_CASH_RECEIPT, fetchPettyCashReceipt)
}
export function* addPettyCashReceiptDefault() {
  yield takeLatest(ADD_PETTY_CASH_RECEIPT, addPettyCashReceipt);
}
export function* deletePettyCashReceiptDefault() {
  yield takeLatest(DELETE_PETTY_CASH_RECEIPT, deletePettyCashReceipt);
}
export function* editPettyCashReceiptDefault() {
  yield takeLatest(EDIT_PETTY_CASH_RECEIPT, editPettyCashReceipt);
}
export default function* rootSaga() {
  yield all([fork(fetchPettyCashReceiptDefault),
    fork(addPettyCashReceiptDefault),
    fork(deletePettyCashReceiptDefault),
    fork(editPettyCashReceiptDefault)
  ]);
}
