import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
  ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME,
  addPerformanceImprovementProgrammeSuccessGenerator, DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME,
  deletePerformanceImprovementProgrammeSuccessGenerator, EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME,
  editPerformanceImprovementProgrammeSuccessGenerator, FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMME_DETAILS,
  FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES, fetchPerformanceImprovementProgrammeDetailsSuccessGenerator,
  fetchPerformanceImprovementProgrammesSuccessGenerator
} from "../../actions/appraisal/PerformanceImprovementProgrammeActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchPerformanceImprovementProgrammesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/appraisal/pip',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPerformanceImprovementProgrammeRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/appraisal/pip',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePerformanceImprovementProgrammeRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/appraisal/pip/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPerformanceImprovementProgrammeRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/appraisal/pip/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchPerformanceImprovementProgrammeDetailsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/appraisal/pip/details',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPerformanceImprovementProgrammes({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPerformanceImprovementProgrammesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let PERFORMANCE_IMPROVEMENT_PROGRAMMES = response.data.data;
        yield put(fetchPerformanceImprovementProgrammesSuccessGenerator(PERFORMANCE_IMPROVEMENT_PROGRAMMES));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPerformanceImprovementProgramme({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addPerformanceImprovementProgrammeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let groupedPerformanceImprovementProgrammes = response.data.data;
        yield put(addPerformanceImprovementProgrammeSuccessGenerator(groupedPerformanceImprovementProgrammes));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePerformanceImprovementProgramme({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePerformanceImprovementProgrammeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let PERFORMANCE_IMPROVEMENT_PROGRAMMES = response.data.data;
        yield put(deletePerformanceImprovementProgrammeSuccessGenerator(PERFORMANCE_IMPROVEMENT_PROGRAMMES));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPerformanceImprovementProgramme({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editPerformanceImprovementProgrammeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let PERFORMANCE_IMPROVEMENT_PROGRAMMES = response.data.data;
        yield put(editPerformanceImprovementProgrammeSuccessGenerator(PERFORMANCE_IMPROVEMENT_PROGRAMMES));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchPerformanceImprovementProgrammeDetails({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPerformanceImprovementProgrammeDetailsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let PERFORMANCE_IMPROVEMENT_PROGRAMMES = response.data.data;
        yield put(fetchPerformanceImprovementProgrammeDetailsSuccessGenerator(PERFORMANCE_IMPROVEMENT_PROGRAMMES));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPerformanceImprovementProgrammesDefault() {
  yield takeLatest(FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES, fetchPerformanceImprovementProgrammes)
}
export function* addPerformanceImprovementProgrammeDefault() {
  yield takeLatest(ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME, addPerformanceImprovementProgramme);
}
export function* deletePerformanceImprovementProgrammeDefault() {
  yield takeLatest(DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME, deletePerformanceImprovementProgramme);
}
export function* editPerformanceImprovementProgrammeDefault() {
  yield takeLatest(EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME, editPerformanceImprovementProgramme);
}
export function* fetchPerformanceImprovementProgrammeDetailsDefault() {
  yield takeLatest(FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMME_DETAILS, fetchPerformanceImprovementProgrammeDetails)
}
export default function* rootSaga() {
  yield all([fork(fetchPerformanceImprovementProgrammesDefault),
    fork(addPerformanceImprovementProgrammeDefault),
    fork(deletePerformanceImprovementProgrammeDefault),
    fork(editPerformanceImprovementProgrammeDefault),
    fork(fetchPerformanceImprovementProgrammeDetailsDefault),
  ]);
}
