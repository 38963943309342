import {
  ADD_MORTGAGE_RELIEF_SUCCESS, BULK_MORTGAGE_RELIEF_SUCCESS,
  DELETE_MORTGAGE_RELIEF,
  EDIT_MORTGAGE_RELIEF_SUCCESS,
  FETCH_MORTGAGE_RELIEF_SUCCESS,
  HIDE_ACTIVATE_MORTGAGE_RELIEF_FORM,
  HIDE_DEACTIVATE_MORTGAGE_RELIEF_FORM,
  SHOW_ACTIVATE_MORTGAGE_RELIEF_FORM,
  SHOW_DEACTIVATE_MORTGAGE_RELIEF_FORM
} from "../../actions/payroll/MortgageReliefActions";

const INIT_STATE = {
  mortgageReliefsByID:[],
  showMortgageReliefCreateForm:false,
  MortgageReliefToEdit:[],
  showMortgageReliefEditForm:false,
  showMortgageReliefDeleteModal:false,
  showActivateMortgageReliefModal:false,
  showDeactivateMortgageReliefModal:false
};
export default (state = INIT_STATE, action)=>{
  let MORTGAGE_RELIEF =[];
  switch (action.type){
    case ADD_MORTGAGE_RELIEF_SUCCESS:
    case EDIT_MORTGAGE_RELIEF_SUCCESS:
      MORTGAGE_RELIEF = {...state.mortgageReliefsByID};
      MORTGAGE_RELIEF[action.payload.id]=action.payload;
      return {
        ...state,
        mortgageReliefsByID:MORTGAGE_RELIEF
      };
    case BULK_MORTGAGE_RELIEF_SUCCESS:
    case FETCH_MORTGAGE_RELIEF_SUCCESS:
      return {
        ...state,
        mortgageReliefsByID:action.payload
      };
    case DELETE_MORTGAGE_RELIEF:
      MORTGAGE_RELIEF = {...state.mortgageReliefsByID};
      delete MORTGAGE_RELIEF[action.payload.id];
      return {
        ...state,
        mortgageReliefsByID:MORTGAGE_RELIEF
      };
    case SHOW_DEACTIVATE_MORTGAGE_RELIEF_FORM:
      return {
        ...state,
        MortgageReliefToEdit:action.payload,
        showDeactivateMortgageReliefModal:true
      };
    case HIDE_DEACTIVATE_MORTGAGE_RELIEF_FORM:
      return {
        ...state,
        showDeactivateMortgageReliefModal:false
      };
    case SHOW_ACTIVATE_MORTGAGE_RELIEF_FORM:
      return {
        ...state,
        MortgageReliefToEdit:action.payload,
        showActivateMortgageReliefModal:true
      };
    case HIDE_ACTIVATE_MORTGAGE_RELIEF_FORM:
      return {
        ...state,
        showActivateMortgageReliefModal:false
      };
    default:
      return state;

  }
}
