import {
  ACTIVATE_USER_SUCCESS,
  ADD_USER_SUCCESS,
  DEACTIVATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  FETCH_USERS_SUCCESS,
  HIDE_USER_CREATE_FORM,
  HIDE_USER_DELETE_FORM,
  HIDE_USER_EDIT_FORM,
  SHOW_USER_CREATE_FORM,
  SHOW_USER_DELETE_FORM,
  SHOW_USER_EDIT_FORM,
  TRANSFER_USER_SUCCESS, USERS_WITH_CERTAIN_PERMISSION_SUCCESS
} from "../../actions/common/UserActions";

const INIT_STATE ={
    usersByID:[],
    showUserCreateForm:false,
    showUserDeleteForm:false,
    showUserEditForm:false,
    userToEdit:[],
    userToDelete:[],
    usersWithCertainPermissions:[]
};

export default (state=INIT_STATE,action)=>{
    let users;
    switch(action.type){
        case ADD_USER_SUCCESS:
        case EDIT_USER_SUCCESS:
        case TRANSFER_USER_SUCCESS:
        case DEACTIVATE_USER_SUCCESS:
      case ACTIVATE_USER_SUCCESS:
          users = {...state}.usersByID;
          users[action.payload.id]=action.payload;
          return {
            ...state,
            usersByID:users
          };
        case FETCH_USERS_SUCCESS:
          return {
            ...state,
            usersByID:action.payload
          };
        case SHOW_USER_CREATE_FORM:
          return {
            ...state,
            showUserCreateForm:true
          };
        case SHOW_USER_EDIT_FORM:
          return {
            ...state,
            userToEdit:action.payload,
            showUserEditForm:true
          };
        case HIDE_USER_EDIT_FORM:
          return {
            ...state,
            showUserEditForm:false
          };
          case SHOW_USER_DELETE_FORM:
          return {
            ...state,
            userToDelete:action.payload,
            showUserDeleteForm:true
          };
        case HIDE_USER_CREATE_FORM:
          return {
            ...state,
            showUserCreateForm:false
          };
        case DELETE_USER_SUCCESS:
          users = {...state}.usersByID;
          delete users[action.payload.id];
          return {
            ...state,
            usersByID:users,
          };
      case HIDE_USER_DELETE_FORM:
        return{
          ...state,
          showUserDeleteForm:false
        };
      case USERS_WITH_CERTAIN_PERMISSION_SUCCESS:
        return {
          ...state,
          usersWithCertainPermissions: action.payload
        }
        default:
          return {
            ...state
          };
      }
}
