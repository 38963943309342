export const FETCH_SNAPCHECKS = "FETCH_SNAPCHECKS";
export const FETCH_SNAPCHECKS_SUCCESS = "FETCH_SNAPCHECKS_SUCCESS";
export const SHOW_SNAPCHECK_CREATE_FORM = 'SHOW_SNAPCHECK_CREATE_FORM';
export const HIDE_SNAPCHECK_CREATE_FORM = 'HIDE_SNAPCHECK_CREATE_FORM';
export const ADD_SNAPCHECK = 'ADD_SNAPCHECK';
export const ADD_SNAPCHECK_SUCCESS = 'ADD_SNAPCHECK_SUCCESS';
export const SHOW_SNAPCHECK_EDIT_FORM = 'SHOW_SNAPCHECK_EDIT_FORM';
export const HIDE_SNAPCHECK_EDIT_FORM = 'HIDE_SNAPCHECK_EDIT_FORM';
export const EDIT_SNAPCHECK = 'EDIT_SNAPCHECK';
export const EDIT_SNAPCHECK_SUCCESS = 'EDIT_SNAPCHECK_SUCCESS';
export const SHOW_SNAPCHECK_DELETE_FORM = 'SHOW_SNAPCHECK_DELETE_FORM';
export const HIDE_SNAPCHECK_DELETE_FORM = 'HIDE_SNAPCHECK_DELETE_FORM';
export const DELETE_SNAPCHECK = 'DELETE_SNAPCHECK';
export const DELETE_SNAPCHECK_SUCCESS = 'DELETE_SNAPCHECK_SUCCESS';

export const fetchSnapChecksGenerator = (data=[])=>{
    return {
        type:FETCH_SNAPCHECKS,
        payload:data
    };
};

export const fetchSnapChecksSuccessGenerator = (dataById)=> {
    return {
        type:FETCH_SNAPCHECKS_SUCCESS,
        payload:dataById
    }
};

export const showSnapCheckCreateFormGenerator = ()=>{
    return {
        type:SHOW_SNAPCHECK_CREATE_FORM
    };
};
export const hideSnapCheckCreateFormGenerator = ()=>{
    return {
        type:HIDE_SNAPCHECK_CREATE_FORM
    };
};
export const addSnapCheckGenerator = (data)=>{
    return {
        type: ADD_SNAPCHECK,
        payload: data
    }
};

export const addSnapCheckSuccessGenerator = (data)=>{
    return {
        type: ADD_SNAPCHECK_SUCCESS,
        payload: data
    }
};
export const showSnapCheckEditFormGenerator = (data)=>{
    return {
        type:SHOW_SNAPCHECK_EDIT_FORM,
        payload:data
    };
};
export const hideSnapCheckEditFormGenerator = ()=>{
    return {
        type:HIDE_SNAPCHECK_EDIT_FORM
    };
};
export const editSnapCheckGenerator = (data)=>{
    return {
        type: EDIT_SNAPCHECK,
        payload: data
    }
};
export const editSnapCheckSuccessGenerator = (data)=>{
    return {
        type: EDIT_SNAPCHECK_SUCCESS,
        payload: data
    }
};
export const showSnapCheckDeleteFormGenerator = (data)=>{
    return {
        type:SHOW_SNAPCHECK_DELETE_FORM,
        payload:data
    };
};
export const hideSnapCheckDeleteFormGenerator = ()=>{
    return {
        type:HIDE_SNAPCHECK_DELETE_FORM
    };
};
export const deleteSnapCheckGenerator = (data)=>{
    return {
        type: DELETE_SNAPCHECK,
        payload: data
    }
};
export const deleteSnapCheckSuccessGenerator = (data)=>{
    return {
        type: DELETE_SNAPCHECK_SUCCESS,
        payload: data
    }
};
