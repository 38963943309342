import {FETCH_SUPER_ADMIN_DASHBOARD_SUCCESS} from "../../actions/admin/SuperAdminDashBoard";

const INIT_STATE = {
    superAdminDashboardData:{},
    paid:{},
    companies:{},
    loans:{},
    wallet:{},
    data_attendance:[],
    employees:{},
    data_charges:[],
    attendance_biometric:{},
    attendance_qrcode:{},
    attendance_manual:{},
    title:''

};

export default (state = INIT_STATE,action)=>{
    switch (action.type) {
        case FETCH_SUPER_ADMIN_DASHBOARD_SUCCESS:
            return {
                ...state,
                paid: action.payload.data.paid,
                companies:action.payload.data.companies,
                loans:action.payload.data.loans,
                wallet:action.payload.data.wallet,
                data_attendance:action.payload.data.data_attendance,
                employees:action.payload.data.employees,
                data_charges:action.payload.data.data_charges,
                attendance_biometric:action.payload.data.attendance.attendance_biometric,
                attendance_qrcode:action.payload.data.attendance.attendance_qrcode,
                attendance_manual:action.payload.data.attendance.attendance_manual,
                title:action.payload.title
            };
        default:
            return {...state};
    }
}