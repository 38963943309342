const  INIT_STATE = {

    companyWithSenderID:[]
};

export default (state=INIT_STATE,action)=>{
   switch (action.type) {
       case 'FETCH_SENDER_ID_SUCCESS':
           return {
               ...state,
               companyWithSenderID: action.payload
           };
       default: return {...state}

   }
}
