export const ADD_EMPLOYEE_RATE ="ADD_EMPLOYEE_RATE";
export const ADD_EMPLOYEE_RATE_SUCCESS ="ADD_EMPLOYEE_RATE_SUCCESS";

export const FETCH_EMPLOYEE_RATE ="FETCH_EMPLOYEE_RATE";
export const FETCH_EMPLOYEE_RATE_SUCCESS ="FETCH_EMPLOYEE_RATE_SUCCESS";

export const EDIT_EMPLOYEE_RATE ="EDIT_EMPLOYEE_RATE";
export const EDIT_EMPLOYEE_RATE_SUCCESS ="EDIT_EMPLOYEE_RATE_SUCCESS";
export const SHOW_EMPLOYEE_RATE_EDIT_FORM ="SHOW_EMPLOYEE_RATE_EDIT_FORM";
export const HIDE_EMPLOYEE_RATE_EDIT_FORM ="HIDE_EMPLOYEE_RATE_EDIT_FORM";

export const DELETE_EMPLOYEE_RATE ="DELETE_EMPLOYEE_RATE";
export const DELETE_EMPLOYEE_RATE_SUCCESS ="DELETE_EMPLOYEE_RATE_SUCCESS";
export const SHOW_EMPLOYEE_RATE_DELETE_FORM ="SHOW_EMPLOYEE_RATE_DELETE_FORM";
export const HIDE_EMPLOYEE_RATE_DELETE_FORM ="HIDE_EMPLOYEE_RATE_DELETE_FORM";

export const SHOW_EMPLOYEE_RATE_CREATE_FORM ="SHOW_EMPLOYEE_RATE_CREATE_FORM";
export const HIDE_EMPLOYEE_RATE_CREATE_FORM ="HIDE_EMPLOYEE_RATE_CREATE_FORM";

export const DELETE_EMPLOYEE_RATE_MULTIPLE ="DELETE_EMPLOYEE_RATE_MULTIPLE";

export const  UPDATE_BULK_EMPLOYEE_RATE = "UPDATE_BULK_EMPLOYEE_RATE";

export const UPLOAD_SALARY_IN_BULK = "UPLOAD_SALARY_IN_BULK";

export const FETCH_SALARY_HISTORY = "FETCH_SALARY_HISTORY";
export const FETCH_SALARY_HISTORY_SUCCESS = "FETCH_SALARY_HISTORY_SUCCESS";

export const FETCH_SINGLE_EMPLOYEE_ALLOWANCES = 'FETCH_SINGLE_EMPLOYEE_ALLOWANCES';
export const FETCH_SINGLE_EMPLOYEE_ALLOWANCES_SUCCESS = 'FETCH_SINGLE_EMPLOYEE_ALLOWANCES_SUCCESS';

export const fetchSingleEmployeeAllowanceGenerator = (payload)=>{
  return {
    type:FETCH_SINGLE_EMPLOYEE_ALLOWANCES,
    payload
  }
}
export const fetchSingleEmployeeAllowanceSuccessGenerator = (payload)=>{
  return {
    type:FETCH_SINGLE_EMPLOYEE_ALLOWANCES_SUCCESS,
    payload
  }
}
export const uploadSalaryInBulkGenerator = (data)=>{
  return {
    type:UPLOAD_SALARY_IN_BULK,
    payload:data
  }
};

export const updateBulkEmployeeRateGenerator =(data)=>{
  return {
    type:UPDATE_BULK_EMPLOYEE_RATE,
    payload:data
  }
};

export const deleteEmployeeRateMultiple =(data)=>{
  return {
    type:DELETE_EMPLOYEE_RATE_MULTIPLE,
    payload:data
  }
};

export const showEmployeeRateDeleteFormGenerator=(data)=>{
  return{
    type:SHOW_EMPLOYEE_RATE_DELETE_FORM,
    payload:data
  }
};
export const hideEmployeeRateDeleteFormGenerator=()=>{
  return{
    type:HIDE_EMPLOYEE_RATE_DELETE_FORM,
  }
};
export const fetchEmployeeRateGenerator = (data=[]) =>{
  return {
    type:FETCH_EMPLOYEE_RATE,
    payload:data
  }
};

export const fetchEmployeeRateSuccessGenerator = (data) =>{
  return {
    type:FETCH_EMPLOYEE_RATE_SUCCESS,
    payload:data
  }
};
export const addEmployeeRateGenerator =(data) =>{
  return {
    type:ADD_EMPLOYEE_RATE,
    payload:data
  }
};

export const addEmployeeRateSuccessGenerator = (data) => {
  return {
    type:ADD_EMPLOYEE_RATE_SUCCESS,
    payload:data
  }
};
export const editEmployeeRateGenerator = (data) =>{
  return {
    type:EDIT_EMPLOYEE_RATE,
    payload:data
  }
};
export const editEmployeeRateSuccessGenerator = (data) =>{
  return {
    type:EDIT_EMPLOYEE_RATE_SUCCESS,
    payload:data
  }
};
export const deleteEmployeeRateGenerator = (data) =>{
  return {
    type:DELETE_EMPLOYEE_RATE,
    payload:data
  }
};
export const deleteEmployeeRateSuccessGenerator = (data) =>{
  return {
    type:DELETE_EMPLOYEE_RATE_SUCCESS,
    payload:data
  }
};
export const showEmployeeRateCreateFormGenerator =() =>{
  return {
    type:SHOW_EMPLOYEE_RATE_CREATE_FORM
  }
};
export const showEmployeeRateEditFormGenerator =(data) =>{
  return {
    type:SHOW_EMPLOYEE_RATE_EDIT_FORM,
    payload:data
  }
};
export const hideEmployeeRateEditFormGenerator =() =>{
  return {
    type:HIDE_EMPLOYEE_RATE_EDIT_FORM
  }
};
export const hideEmployeeRateCreateFormGenerator =() =>{
  return {
    type:HIDE_EMPLOYEE_RATE_CREATE_FORM
  }
};
export const fetchSalaryHistoryGenerator = (data) =>{
  return {
    type:FETCH_SALARY_HISTORY,
    payload:data
  }
};
export const fetchSalaryHistorySuccessGenerator = (data) =>{
  return {
    type:FETCH_SALARY_HISTORY_SUCCESS,
    payload:data
  }
};
