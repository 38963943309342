import {
  ADD_COMPANY_RATE_SUCCESS,
  DELETE_COMPANY_RATE_SUCCESS,
  EDIT_COMPANY_RATE_SUCCESS,
  FETCH_COMPANY_RATE_SUCCESS,
  HIDE_COMPANY_RATE_CREATE_FORM,
  HIDE_COMPANY_RATE_DELETE_FORM,
  HIDE_COMPANY_RATE_EDIT_FORM,
  SHOW_COMPANY_RATE_CREATE_FORM,
  SHOW_COMPANY_RATE_DELETE_FORM,
  SHOW_COMPANY_RATE_EDIT_FORM
} from '../../actions/payroll/CompanyPaymentRateActions';

const INIT_STATE ={
     companyRateByID:[],
    showCompanyRateCreateForm:false,
    showCompanyRateEditForm:false,
    EditingCompanyRate:[] ,
    DeletingCompanyRate:[],
    showCompanyRateDeleteForm:false
};
export default (state =INIT_STATE,action) =>{
     switch(action.type){
         case ADD_COMPANY_RATE_SUCCESS:
         case EDIT_COMPANY_RATE_SUCCESS:
               let companyRateByID = {...state.companyRateByID};
             companyRateByID= Object.assign({},action.payload);
               return {
                    ...state,
                   companyRateByID:companyRateByID
               };
          case FETCH_COMPANY_RATE_SUCCESS:
               return {
                    ...state,
                   companyRateByID:action.payload
               };
         case SHOW_COMPANY_RATE_CREATE_FORM:
              return{
                  ...state,
                 showCompanyRateCreateForm:true
              };
         case HIDE_COMPANY_RATE_CREATE_FORM:
             return {
                 ...state,
                 showCompanyRateCreateForm: false
             };
         case SHOW_COMPANY_RATE_EDIT_FORM:
             return{
                 ...state,
                 showCompanyRateEditForm:true,
                 EditingCompanyRate:action.payload
             };
         case HIDE_COMPANY_RATE_EDIT_FORM:
             return {
                 ...state,
                 showCompanyRateEditForm: false
             };
         case SHOW_COMPANY_RATE_DELETE_FORM:
             return{
                 ...state,
                 showCompanyRateDeleteForm:true,
                 DeletingCompanyRate:action.payload
             };
         case HIDE_COMPANY_RATE_DELETE_FORM:
             return{
                 ...state,
                 showCompanyRateDeleteForm:false,
             };
         case DELETE_COMPANY_RATE_SUCCESS:
             let company_rates ={...state.companyRateByID};
             delete company_rates[action.payload.id];
             return{
                 ...state,
                 companyRateByID:company_rates
             };
          default:
          return state;
     }
}
