export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTION_DETAILS = "FETCH_TRANSACTION_DETAILS";
export const FETCH_TRANSACTION_DETAILS_SUCCESS = "FETCH_TRANSACTION_DETAILS_SUCCESS";
export const CONFIRM_TOPUP_TRANSACTION = "CONFIRM_TOPUP_TRANSACTION";
export const CONFIRM_TOPUP_TRANSACTION_SUCCESS = "CONFIRM_TOPUP_TRANSACTION_SUCCESS";
export const RETRY_TRANSACTION = "RETRY_TRANSACTION";
export const EXPORT_TRANSACTIONS = "EXPORT_TRANSACTIONS";
export const EXPORT_ACCOUNT_STATEMENT = "EXPORT_ACCOUNT_STATEMENT";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const ALERT_ADMIN_TO_RETRY = "ALERT_ADMIN_TO_RETRY";
export const ALERT_ADMIN_TO_CLEAR = "ALERT_ADMIN_TO_CLEAR";
export const FETCH_DAILY_RECONCILIATION = "FETCH_DAILY_RECONCILIATION";
export const FETCH_DAILY_RECONCILIATION_SUCCESS = "FETCH_DAILY_RECONCILIATION_SUCCESS";
export const RECONCILE = "RECONCILE";
export const RECONCILE_SUCCESS = "RECONCILE_SUCCESS";
export const EXPORT_DAILY_RECONCILIATION = "EXPORT_DAILY_RECONCILIATION";
export const EXPORT_ADMIN_TRANSACTIONS = "EXPORT_ADMIN_TRANSACTIONS";
export const TRANSACTION_FILTERS ="TRANSACTION_FILTERS";
export const TRANSACTION_CUSTOM_FILTERS = 'TRANSACTION_CUSTOM_FILTERS';

export const APPROVE_TOPUP = "APPROVE_TOPUP";
export const APPROVE_TOPUP_SUCCESS = "APPROVE_TOPUP_SUCCESS";

export const REJECT_TOPUP = "REJECT_TOPUP";
export const REJECT_TOPUP_SUCCESS = "REJECT_TOPUP_SUCCESS";

export const exportAccountStatementGenerator =(data)=>{
  return{
    type:EXPORT_ACCOUNT_STATEMENT,
    payload:data
  }
}
export const transactionCustomFiltersGenerator =(data)=>{
  return{
    type:TRANSACTION_CUSTOM_FILTERS,
    payload:data
  }
}

export const transactionFiltersGenerator =(data)=>{
  return{
    type:TRANSACTION_FILTERS,
    payload:data
  }
}

export const confirmTopUpTransactionGenerator =(data)=>{
  return {
    type:CONFIRM_TOPUP_TRANSACTION,
    payload:data
  }
};
export const confirmTopUpTransactionSuccessGenerator =(data)=>{
  return {
    type:CONFIRM_TOPUP_TRANSACTION_SUCCESS,
    payload:data
  }
};
export const fetchTransactionsGenerator =(data=[])=>{
  return{
    type:FETCH_TRANSACTIONS,
    payload:data
  }
};
export const fetchTransactionsSuccessGenerator =(data)=>{
  return{
    type:FETCH_TRANSACTIONS_SUCCESS,
    payload:data
  }
};
export const fetchTransactionDetailsGenerator =(data=[])=>{
  return{
    type:FETCH_TRANSACTION_DETAILS,
    payload:data
  }
};
export const fetchTransactionDetailsSuccessGenerator =(data)=>{
  return{
    type:FETCH_TRANSACTION_DETAILS_SUCCESS,
    payload:data
  }
};
export const retryTransactionGenerator =(data=[])=>{
  return{
    type:RETRY_TRANSACTION,
    payload:data
  }
};
export const editTransactionGenerator =(data=[])=>{
  return{
    type:EDIT_TRANSACTION,
    payload:data
  }
};
export const exportTransactionsGenerator =(data=[])=>{
  return{
    type:EXPORT_TRANSACTIONS,
    payload:data
  }
};
export const alertAdminToRetryGenerator =(data=[])=>{
  return{
    type:ALERT_ADMIN_TO_RETRY,
    payload:data
  }
};
export const alertAdminToClearGenerator =(data=[])=>{
  return{
    type:ALERT_ADMIN_TO_CLEAR,
    payload:data
  }
};
export const fetchDailyReconciliationGenerator =(data=[])=>{
  return{
    type:FETCH_DAILY_RECONCILIATION,
    payload:data
  }
};
export const fetchDailyReconciliationSuccessGenerator =(data)=>{
  return{
    type:FETCH_DAILY_RECONCILIATION_SUCCESS,
    payload:data
  }
};
export const reconcileGenerator =(data=[])=>{
  return{
    type:RECONCILE,
    payload:data
  }
};
export const reconcileSuccessGenerator =(data)=>{
  return{
    type:RECONCILE_SUCCESS,
    payload:data
  }
};
export const exportDailyReconciliationGenerator =(data=[])=>{
  return{
    type:EXPORT_DAILY_RECONCILIATION,
    payload:data
  }
};
export const exportAdminTransactionsGenerator =(data=[])=>{
  return{
    type:EXPORT_ADMIN_TRANSACTIONS,
    payload:data
  }
};

export const approveTopupGenerator = data => {
  return {
    type: APPROVE_TOPUP,
    payload: data
  };
};

export const rejectTopupGenerator = data => {
  return {
    type: REJECT_TOPUP,
    payload: data
  };
};
