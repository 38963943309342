
import axios from 'axios';
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {Helpers} from "../../../util/Helpers";
import {call, fork, put, takeLatest,all} from "redux-saga/effects";
import {hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator} from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    DISABLE_APPRAISAL,
    FETCH_COMPANIES_WITH_APPRAISAL, fetchCompaniesWithAppraisalGenerator,
    fetchCompaniesWithAppraisalSuccessGenerator
} from "../../actions/admin/AppraisalActions";


const fetchAppraisalAdminCompaniesRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/tozza/appraisal/companies',{params:requestData})
       .then(response=>response)
       .catch(error=>error.response);

const disableAppraisalRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/tozza/appraisal/companies/disable/'+requestData.id,requestData)
        .then(response=>response)
        .catch(error=>error.response);
function* disableAppraisal({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(disableAppraisalRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchCompaniesWithAppraisalGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchAppraisalAdminCompanies({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchAppraisalAdminCompaniesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchCompaniesWithAppraisalSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchAppraisalAdminCompaniesDefault() {
    yield takeLatest(FETCH_COMPANIES_WITH_APPRAISAL,fetchAppraisalAdminCompanies);
}
export function* disableAppraisalDefault() {
    yield takeLatest(DISABLE_APPRAISAL,disableAppraisal)
}

export default function* rootSaga() {
    yield all([
        fork(fetchAppraisalAdminCompaniesDefault),
        fork(disableAppraisalDefault)
    ]);
}