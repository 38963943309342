import axios from "axios";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {Helpers} from "../../../util/Helpers";
import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import { push } from  "connected-react-router";
import {
    CREATE_SUPERVISOR_LEVEL_APPRAISAL, createSupervisorLevelAppraisalSuccessGenerator,
    FETCH_SUPERVISOR_LEVEL_APPRAISAL,
    fetchSupervisorLevelAppraisalSuccessGenerator
} from "../../actions/appraisal/SupervisorLevelAppraisalActions";

const fetchSupervisorAppraisalRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+requestParameters.path,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const employeeSupervisorAppraisalRequest= async (requestParameters) =>
    await  axios.post(API_ENDPOINT+requestParameters.path,requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchSupervisorAppraisals({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        if(Helpers.isNull(requestParameters.path)){
          requestParameters.path = '/appraisal/supervisor/appraise';
        }
        const response = yield call(fetchSupervisorAppraisalRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSupervisorLevelAppraisalSuccessGenerator(response.data.data));
                let KPI =response.data.data;
                let from;
                let employee_id;
                if(requestParameters.path !=="/appraisal/supervisor/appraise"){
                    from = "admin_level";
                    employee_id =  requestParameters.employee_id
                }else{
                    from ="supervisor";
                    employee_id = requestParameters.employee;
                }
                yield put(push('appraise',{"from":from,"selfAppraisals":KPI,employee_id:employee_id}));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchSupervisorAppraisalsDefault() {
    yield takeLatest(FETCH_SUPERVISOR_LEVEL_APPRAISAL, fetchSupervisorAppraisals)
}

function* employeeSupervisorAppraisal({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        if(Helpers.isNull(requestParameters.path)){
            requestParameters.path = "/appraisal/supervisor/appraisal/rate/multiple";
        }
        const response = yield call(employeeSupervisorAppraisalRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(push('/appraisals/appraisal'));
                yield put(fetchSupervisorLevelAppraisalSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
                // if( requestParameters.path !== "/appraisal/supervisor/appraisal/rate/multiple"){
                //     yield put(push('/appraisals/appraisal'));
                // }
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* employeeSupervisorAppraisalDefault() {
    yield takeLatest(CREATE_SUPERVISOR_LEVEL_APPRAISAL, employeeSupervisorAppraisal)
}
export default function* rootSaga() {
    yield all([
        fork(fetchSupervisorAppraisalsDefault),
        fork(employeeSupervisorAppraisalDefault)
    ])
};
