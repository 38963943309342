import BulkPayoutSaga from "./BulkPayoutSaga";
import TransactionSaga from "./TransactionSaga";
import RechargePayoutSaga from "./RechargePayoutSaga";
import PayoutChargeSaga from "./PayoutChargeSaga";

const Payouts =[
  BulkPayoutSaga(),
  TransactionSaga(),
  RechargePayoutSaga(),
  PayoutChargeSaga()
];
export default Payouts;
