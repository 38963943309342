export const FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS = "FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS";
export const FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS = "FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS";

export const UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS = "UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS";
export const UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS = "UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS";

export const fetchAdminSalaryAdvanceDefaultSettingsGenerator = (data=[])=>{
  return {
    type:FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS,
    payload:data
  };
};

export const fetchAdminSalaryAdvanceDefaultSettingsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS,
    payload:dataById
  }
};

export const editAdminSalaryAdvanceDefaultSettingsGenerator = (data)=>{
  return {
    type: UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS,
    payload: data
  }
};

export const editAdminSalaryAdvanceDefaultSettingsSuccessGenerator = (data)=>{
  return {
    type: UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS_SUCCESS,
    payload: data
  }
};
