import {API_ENDPOINT, NODE_API_ENDPOINT} from "../../../constants/ActionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import axios from 'axios';
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  ADD_COMPANY_LOAN_RATES,
  addCompaniesLoanRatesSuccessGenerator, EDIT_COMPANY_LOAN_RATES, editCompaniesLoansRatesSuccessGenerator,
  FETCH_COMPANY_LOAN_RATES, FETCH_ONE_COMPANY_LOAN_RATE, fetchCompaniesLoanRatesGenerator,
  fetchCompaniesLoanRatesSuccessGenerator, fetchOneCompanyLoanRateGenerator, fetchOneCompanyLoanRateSuccessGenerator
} from "../../actions/admin/CompanyRatesActions";

const fetchOneCompanyLoanRateRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/admin/company/loan/rate/'+requestData.company_id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const fetchCompaniesLoanRatesRequest = async (requestData)=>
  await axios.get(API_ENDPOINT+'/company/loan/rate',{params:requestData})
       .then(response=>response)
       .catch(error=>error.response)

const addCompanyLoanRateRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/company/loan/rate/store',requestData)
    .then(response=>response)
    .catch(error=>error.response);

const editCompanyLoanRateRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/company/loan/rate/'+requestData.id,requestData)
    .then(response=>response)
    .catch(error=>error.response);

function* editCompanyLoanRate({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editCompanyLoanRateRequest, requestParameters)
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        let data= response.data.data;
        if ('from_lend_page' in data){
          yield put(fetchOneCompanyLoanRateGenerator({company_id:data.company_id}));
        }
        yield put(fetchCompaniesLoanRatesGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(response.data.message,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addCompanyLoanRates({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),user_id:Helpers.authUserID()};
    const response = yield call(addCompanyLoanRateRequest, requestParameters)
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchCompaniesLoanRatesGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(response.data.message,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchCompaniesLoanRates({type,payload}) {
     yield put(showAppLoaderGenerator(type));
     try {
       let requestParameters = {...payload, token:Helpers.token()};
       const response = yield call(fetchCompaniesLoanRatesRequest, requestParameters);
       if (response.status === 200 || response.status === 201) {
         if (response.data.success) {
           yield put(fetchCompaniesLoanRatesSuccessGenerator(response.data.data));
         } else {
           yield put(showMessageGenerator(response.data.message,'error'));
         }
       }else if(response.status === 401){
         Helpers.clearAuthData();
         yield put(userSignOutSuccessGenerator());
       }else {
         yield put(showMessageGenerator(response.statusText,'error'));
       }
     }catch (e) {
       yield put(showMessageGenerator(response.data.message,'error'));
     }
     yield put(hideAppLoaderGenerator(type));
}

function* fetchOneCompaniesLoanRates({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchOneCompanyLoanRateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOneCompanyLoanRateSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(response.data.message,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchCompaniesLoanRatesDefault() {
yield takeEvery(FETCH_COMPANY_LOAN_RATES,fetchCompaniesLoanRates);
}
export function* fetchOneCompaniesLoanRatesDefault() {
yield takeEvery(FETCH_ONE_COMPANY_LOAN_RATE,fetchOneCompaniesLoanRates);
}
export function* addCompaniesLoanRatesDefault() {
yield takeEvery(ADD_COMPANY_LOAN_RATES,addCompanyLoanRates)
}
export function* editCompanyLoanRateDefault() {
yield takeEvery(EDIT_COMPANY_LOAN_RATES,editCompanyLoanRate);
}
export default function* rootSaga() {
yield all([
  fork(fetchCompaniesLoanRatesDefault),
  fork(addCompaniesLoanRatesDefault),
  fork(editCompanyLoanRateDefault),
    fork(fetchOneCompaniesLoanRatesDefault)
])
}