import {
  ADD_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_PACKAGE_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  GET_TRIAL_PERIOD_SUCCESS,
  PLAN_TYPE_SWITCHER
} from '../../actions/common/SubscriptionActions';
import {Helpers} from '../../../util/Helpers';
import {SIGNIN_USER_SUCCESS, SIGNUP_USER_SUCCESS} from '../../../constants/ActionTypes';

const INIT_STATE ={
  subscriptionByID :{},
  company:{},
  packagesByID:{},
  packageCosts:{},
  noOfEmployees:0,
  planType:"Yearly",
  planId:'',
  amount:0,
  quarterlyAmount:0,
  plan:{}
};

export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_SUBSCRIPTION_SUCCESS:
      if(action.payload.subscription && action.payload.subscription.plan){
        plan = action.payload.subscription.plan;
        amount = plan.amount_per_user * state.noOfEmployees
      }else{
        plan = {};
        amount = 0;
      }

      return{
        ...state,
        subscriptionByID: action.payload.subscription,
        company: action.payload.subscription ? action.payload.subscription.company : {},
        packagesByID: action.payload.packages,
        plan: plan,
        amount: amount,
      };
    case SIGNUP_USER_SUCCESS:
    case SIGNIN_USER_SUCCESS: {
      return{
        ...state,
        subscriptionByID: action.payload.subscription.subscription,
        company: action.payload.subscription.company,
        packagesByID: action.payload.subscription.packages,
      };
    }
    case ADD_SUBSCRIPTION_SUCCESS:
      state.subscriptionByID = undefined;
      return {
        ...state,
        subscriptionByID:action.payload
      };
    case FETCH_SUBSCRIPTION_PACKAGE_SUCCESS:
      let plan = action.payload;
      /*let employees = parseFloat(plan.employees)+1;*///to make it inclusive of the boundary;
        let employees = parseFloat(plan.employees);
      /*let employeesExceeding = employees-parseFloat(action.payload.users_from);
      const amountDueToExceeds = employeesExceeding * action.payload.amount_per_user;*/
      const amountDueEmployees = employees * plan.amount_per_user;
      let  amount = parseFloat(action.payload.amount)+parseFloat(amountDueEmployees);
      let  quarterlyAmount = parseFloat(action.payload.amount)+parseFloat(amountDueEmployees);
      /*added when subscription calculations*/
      amount = amount < plan.minimum_amount ? plan.minimum_amount:amount;
      quarterlyAmount = quarterlyAmount < plan.minimum_amount ? plan.minimum_amount:quarterlyAmount;
      /*End added when subscription calculations*/
      if(state.planType ==="Yearly"){
        let percentDiscount = Helpers.isNull(plan.discount) || plan.discount ===" " ? 0 :plan.discount;
        let discount = amount * 4 *percentDiscount/100;
        amount = amount * 4 - parseFloat(discount);
      }
      return {
        ...state,
        packageCosts:action.payload,
        noOfEmployees: action.payload.employees,
        planId: action.payload.id,
        amount:amount,
        quarterlyAmount:quarterlyAmount,
      };
    case PLAN_TYPE_SWITCHER:
      let timeAmount = state.quarterlyAmount;
      if(action.payload === "Yearly"){
        let timePercentDiscount = Helpers.isNull(state.packageCosts.discount) || state.packageCosts.discount ===" " ? 0 :state.packageCosts.discount;
        let timeDiscount = timeAmount * 4 *timePercentDiscount/100;
        timeAmount = timeAmount * 4 - parseFloat(timeDiscount);
      }
      return{
        ...state,
        planType: action.payload,
        amount:timeAmount
      };
    case GET_TRIAL_PERIOD_SUCCESS:
      return {
        ...state,
        subscriptionByID:action.payload
      };
    default:
      return state;
  }
}
