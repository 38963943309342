import AdminCompanyReducer from "./AdminCompanyReducer";
import PricingReducer from "./PricingReducer";
import AdminUsersReducer from "./AdminUsersReducer";
import AdminSubscriptionReducer from "./AdminSubscriptionReducer";
import SuperAdminDashBoard from "./SuperAdminDashBoard";
import AppraisalAdminReducer from "./AppraisalAdminReducer";
import SenderIDReducer from "./SenderIDReducer";
import MpesaSettingReducer from "./MpesaSettingReducer";
import CompanyLoanPaymentReducer from "./CompanyLoanPaymentReducer";
import CompanyLoans from "./CompanyLoansReducer";
import CompanyRatesReducers from "./CompanyRatesReducers";
import BankTransferReducer from "./BankTransferReducer";
import SystemUsersReducer from "./SystemUsersReducer";
import LoanLenderReducer from "./LoanLenderReducer";
import LoanReportReducer from "./LoanReportReducer";
import LoanRequestReducer from "./LoanRequestReducer";
import LoanDashboardReducer from "./LoanDashboardReducer";
import CustomerStatementReducer from "./CustomerStatementReducer";
import MobileNotificationsReducer from "./MobileNotificationsReducer";
import StatutoryFillingRequestsReducer from "./StatutoryFillingRequestsReducer";
import SalaryAdvanceSettingsReducer from "./SalaryAdvanceSettingsReducer";
import CompaniesFinancedSalaryAdvanceReducer from "./CompaniesFinancedSalaryAdvanceReducer";
import SubscriptionPricingReducer from "./SubscriptionPricingReducer";
import CompanyAccountSettingsReducer from "./CompanyAccountSettingsReducer";
import SalaryAdvanceDefaultSettingsReducer from "./SalaryAdvanceDefaultSettingsReducer";
import SalaryAdvanceFeesReducer from "./SalaryAdvanceFeesReducer";
import EnableDisableFeaturesReducer from "./EnableDisableFeaturesReducer";
import PaymentActivationsReducer from "./PaymentActivationsReducer";
import CompanyOnboardingReducer from "./CompanyOnboardingReducer";
import CompanyOnboardingWorkflowReducer from "./CompanyOnboardingWorkflowReducer";
import AdminHolidayReducers from "./AdminHolidayReducers";
import ExchangeRatesReducer from "./ExchangeRatesReducer";


const Admin = {
    adminCompany:AdminCompanyReducer,
    pricing:PricingReducer ,
    adminSubscription:AdminSubscriptionReducer,
    SuperAdminDashBoard,
    AppraisalAdminReducer,
    SenderIDReducer,
    adminUser:AdminUsersReducer,
    mpesaSettings:MpesaSettingReducer,
    companyLoanPayment:CompanyLoanPaymentReducer,
    bankTransfer:BankTransferReducer,
    systemUser:SystemUsersReducer,
    companyLoanRate:CompanyRatesReducers,
    companyLoan:CompanyLoans,
    loanLender:LoanLenderReducer,
    loanReport:LoanReportReducer,
    loanRequest:LoanRequestReducer,
    loanDashboard:LoanDashboardReducer,
    customerStatement: CustomerStatementReducer,
    firebaseUsersReducer: MobileNotificationsReducer,
    adminSalaryAdvance: SalaryAdvanceSettingsReducer,
    AdminFinancedSalaryAdvances: CompaniesFinancedSalaryAdvanceReducer,
    adminStatutoryFillingRequests: StatutoryFillingRequestsReducer,
    subscriptionPricing: SubscriptionPricingReducer,
    adminCompanySettings: CompanyAccountSettingsReducer,
    adminDefaultSalaryAdvanceSettings: SalaryAdvanceDefaultSettingsReducer,
    salaryAdvanceFees: SalaryAdvanceFeesReducer,
    paymentReducer: PaymentActivationsReducer,
    enableDisableFeatures:EnableDisableFeaturesReducer,
    companyOnboardingReducer:CompanyOnboardingReducer,
    companyOnboardingWorkflowReducer:CompanyOnboardingWorkflowReducer,
    adminHolidaySettings:AdminHolidayReducers,
    exchangeRatesReducer:ExchangeRatesReducer
};
export default Admin;
