import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  EDIT_PENSION_RATE,
  editPensionRateSuccessGenerator, FETCH_PENSION_RATES,
  fetchPensionRatesSuccessGenerator
} from "../../actions/common/PensionRateActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchPensionRatesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/pension-rates',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPensionRateRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/setup/pension-rates/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPensionRates({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPensionRatesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let pensionsById = response.data.data;
        yield put(fetchPensionRatesSuccessGenerator(pensionsById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editRole({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(editPensionRateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rate = response.data.data;
        yield put(editPensionRateSuccessGenerator(rate));
        yield put(showMessageGenerator(response.data.message),'success');
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPensionRatesDefault() {
  yield takeLatest(FETCH_PENSION_RATES, fetchPensionRates)
}

export function* editPensionRateDefault() {
  yield takeLatest(EDIT_PENSION_RATE, editRole);
}
export default function* rootSaga() {
  yield all([fork(fetchPensionRatesDefault),
    fork(editPensionRateDefault),
  ]);
}
