export const FETCH_PAYSLIP = 'FETCH_PAYSLIP';
export const FETCH_PAYSLIP_SUCCESS = 'FETCH_PAYSLIP_SUCCESS';
export const SEND_PAYSLIP_SUCCESS = 'SEND_PAYSLIP_SUCCESS';
export const SEND_PAYSLIP = 'SEND_PAYSLIP';
export const DOWNLOAD_BULK_PAYSLIPS = 'DOWNLOAD_BULK_PAYSLIPS';
export const DOWNLOAD_BULK_PAYSLIPS_SUCCESS = 'DOWNLOAD_BULK_PAYSLIPS_SUCCESS';

export const fetchPayslipGenerator = (data)=>{
    return {
        type:FETCH_PAYSLIP,
        payload:data
    }
};
export const fetchPayslipSuccessGenerator = (dataById)=>{
    return {
        type:FETCH_PAYSLIP_SUCCESS,
        payload:dataById
    }
};

export const sendPayslipGenerator = (dataById)=>{
    return {
        type:SEND_PAYSLIP,
        payload:dataById
    }
};
export const sendPayslipSuccessGenerator = (dataById)=>{
    return {
        type:SEND_PAYSLIP_SUCCESS,
        payload:dataById
    }
};

export const downloadBulkPayslipGenerator = (dataById)=>{
    return {
        type:DOWNLOAD_BULK_PAYSLIPS,
        payload:dataById
    }
};
export const downloadBulkPayslipSuccessGenerator = (dataById)=>{
    return {
        type:DOWNLOAD_BULK_PAYSLIPS_SUCCESS,
        payload:dataById
    }
};