export const FETCH_COMPANY_LOAN_PAYMENT = "FETCH_COMPANY_LOAN_PAYMENT";
export const FETCH_COMPANY_LOAN_PAYMENT_SUCCESS = "FETCH_COMPANY_LOAN_PAYMENT_SUCCESS";

export const ADD_COMPANY_LOAN_PAYMENT = "ADD_COMPANY_LOAN_PAYMENT";
export const ADD_COMPANY_LOAN_PAYMENT_SUCCESS = "ADD_COMPANY_LOAN_PAYMENT_SUCCESS";

export const UPDATE_COMPANY_LOAN_PAYMENT = "UPDATE_COMPANY_LOAN_PAYMENT";
export const UPDATE_COMPANY_LOAN_PAYMENT_SUCCESS = "UPDATE_COMPANY_LOAN_PAYMENT_SUCCESS";

export const DELETE_COMPANY_LOAN_PAYMENT = "DELETE_COMPANY_LOAN_PAYMENT";
export const DELETE_COMPANY_LOAN_PAYMENT_SUCCESS = "DELETE_COMPANY_LOAN_PAYMENT_SUCCESS";

export const fetchCompanyLoanPaymentGenerator = (data=[])=>{
    return {
        type:FETCH_COMPANY_LOAN_PAYMENT,
        payload:data
    }
} ;
export const fetchCompanyLoanPaymentSuccessGenerator = (data=[])=>{
    return {
        type:FETCH_COMPANY_LOAN_PAYMENT_SUCCESS,
        payload:data
    }
} ;
export const addCompanyLoanPaymentGenerator = (data=[])=>{
    return {
        type:ADD_COMPANY_LOAN_PAYMENT,
        payload:data
    }
} ;
export const addCompanyLoanPaymentSuccessGenerator = (data=[])=>{
    return {
        type:ADD_COMPANY_LOAN_PAYMENT_SUCCESS,
        payload:data
    }
} ;
export const updateCompanyLoanPaymentGenerator = (data=[])=>{
    return {
        type:UPDATE_COMPANY_LOAN_PAYMENT,
        payload:data
    }
} ;
export const updateCompanyLoanPaymentSuccessGenerator = (data=[])=>{
    return {
        type:UPDATE_COMPANY_LOAN_PAYMENT_SUCCESS,
        payload:data
    }
} ;
export const deleteCompanyLoanPaymentGenerator = (data=[])=>{
    return {
        type:DELETE_COMPANY_LOAN_PAYMENT,
        payload:data
    }
} ;
export const deleteCompanyLoanPaymentSuccessGenerator = (data=[])=>{
    return {
        type:DELETE_COMPANY_LOAN_PAYMENT_SUCCESS,
        payload:data
    }
} ;