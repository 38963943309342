import {
  ADD_DEDUCTION_CATEGORY_SUCCESS,
  DELETE_DEDUCTION_CATEGORY_SUCCESS,
  EDIT_DEDUCTION_CATEGORY_SUCCESS,
  FETCH_DEDUCTION_CATEGORIES_SUCCESS,
  HIDE_DEDUCTION_CATEGORY_CREATE_FORM,
  HIDE_DEDUCTION_CATEGORY_DELETE_FORM,
  HIDE_DEDUCTION_CATEGORY_EDIT_FORM,
  SHOW_DEDUCTION_CATEGORY_CREATE_FORM,
  SHOW_DEDUCTION_CATEGORY_DELETE_FORM,
  SHOW_DEDUCTION_CATEGORY_EDIT_FORM
} from '../../actions/payroll/DeductionCategoryActions';

const INIT_STATE = {
  deductionCategoriesByID:{},
  showDeductionCategoryCreateForm:false,
  showDeductionCategoryEditForm:false,
  deductionCategoryToEdit:false,
  showDeductionCategoryDeleteForm:false,
  deductionCategoryToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_DEDUCTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        deductionCategoriesByID: action.payload
      };
    case SHOW_DEDUCTION_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showDeductionCategoryCreateForm: true
      };
    case HIDE_DEDUCTION_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showDeductionCategoryCreateForm: false
      };
    case SHOW_DEDUCTION_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showDeductionCategoryEditForm: true,
        deductionCategoryToEdit: action.payload
      };
    case HIDE_DEDUCTION_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showDeductionCategoryEditForm: false
      };
    case ADD_DEDUCTION_CATEGORY_SUCCESS:
    case EDIT_DEDUCTION_CATEGORY_SUCCESS:
      let loan_category_id = action.payload.id;
      return {
        ...state,
        deductionCategoriesByID: {
          ...state.deductionCategoriesByID,
          [loan_category_id]:action.payload
        }
      };
    case SHOW_DEDUCTION_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showDeductionCategoryDeleteForm: true,
        deductionCategoryToDelete: action.payload
      };
    case HIDE_DEDUCTION_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showDeductionCategoryDeleteForm: false
      };
    case DELETE_DEDUCTION_CATEGORY_SUCCESS:
      let deductionCategoriesByID = {...state.deductionCategoriesByID};
      delete deductionCategoriesByID[action.payload.id];
      return {
        ...state,
        deductionCategoriesByID:deductionCategoriesByID
      };
    default:
      return state;
  }
}
