import axios from "axios";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {call, fork, put, takeLatest,all} from "redux-saga/effects";
import {hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator} from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    ASSIGN_SENDER_ID, DE_ASSIGN_SENDER_ID,
    FETCH_SENDER_ID,
    fetchSenderIdGenerator,
    fetchSenderIdSuccessGenerator
} from "../../actions/admin/SenderIDActions";

const fetchSenderIDRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/tozza/company/sender_id',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const assignSenderIDRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/tozza/assign/sender_id/company',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const de_assginSenderIDRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/tozza/assign/sender_id/company',requestData)
        .then(response=>response)
        .catch(error=>error.response);

function* de_assignSenderID({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(de_assginSenderIDRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSenderIdGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* assignSenderID({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(assignSenderIDRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSenderIdGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchSenderID({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchSenderIDRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSenderIdSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchSenderIDDefault() {
    yield takeLatest(FETCH_SENDER_ID,fetchSenderID)
}

export function* assignSenderIDDefault() {
    yield takeLatest(ASSIGN_SENDER_ID,assignSenderID)
}

export function* de_assignSenderIDDefault() {
    yield takeLatest(DE_ASSIGN_SENDER_ID,de_assignSenderID);
}
export default function* rootSaga() {
    yield all([
        fork(fetchSenderIDDefault),
        fork(assignSenderIDDefault),
        fork(de_assignSenderIDDefault),
    ]);
}