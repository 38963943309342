import axios from "axios";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {call, fork, put, takeLatest,all} from "redux-saga/effects";
import {hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator} from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";

import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    FETCH_SUPER_ADMIN_DASHBOARD,
    fetchSuperAdminDashboardSuccessGenerator
} from "../../actions/admin/SuperAdminDashBoard";

const fetchSuperAdminDashBoardRequest= async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/tozza/admin/dashboard',{params:requestParameters})
        .then(response=>response)
        .catch(error=>error.response);

function* fetchSuperAdminDashBoard({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchSuperAdminDashBoardRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
               yield put(fetchSuperAdminDashboardSuccessGenerator({'data':response.data.data,'title':response.data.message}));

            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* fetchSuperAdminDashBoardDefault() {
    yield takeLatest(FETCH_SUPER_ADMIN_DASHBOARD,fetchSuperAdminDashBoard);
}
export default function* rootSaga(){
    yield all([
         fork(fetchSuperAdminDashBoardDefault),
        ])
}