import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helpers } from "../../util/Helpers";

const DialogTitle = withStyles(theme => ({
    paper: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500]
    }
}))((props) => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.paper}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
   /* paper: {
        margin: 0,
        padding: theme.spacing.unit * 2
    }*/
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    /*paper: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit
    }*/
}))(MuiDialogActions);

class CustomizedDialogDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {submitClicked: false};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {submitClicked} = this.state;
        if (submitClicked) {
            this.submitRequestReceived();
        }
    }

    requestSubmit = () => {
        this.setState({submitClicked: true});
    };

    submitRequestReceived = () => {
        this.setState({submitClicked: false});
    };


    render() {
        const {
            modalTitle,
            submitButton,
            openModal,
            closeModal,
            modalData: ModalData,
            populatedInputFields,
            extraInfo,
            showSubmit,
            modalWidth,
        } = this.props;

        const {submitClicked} = this.state;
        let dialogProps = {
            'className': Helpers.isNull(modalWidth) ? "modal-width" : '',
            'onClose':closeModal,
            'aria-labelledby': "customized-dialog-title",
            'open':openModal
        };
        !Helpers.isNull(modalWidth) && (dialogProps.maxWidth=modalWidth);

        return (
            <div>
                <Dialog
                  { ...dialogProps}
                >
                    <DialogTitle id="customized-dialog-title" onClose={closeModal} style={{ color: '#205926'}}>
                        {modalTitle}
                    </DialogTitle>
                    <DialogContent>
                        {extraInfo?extraInfo:''}
                        {ModalData ? (
                            <ModalData
                                {...populatedInputFields}
                                submitClicked={submitClicked}
                                closeModal={closeModal}
                            />
                        ) : ''}
                    </DialogContent>
                    <DialogActions>
                        {showSubmit && (
                            <Button variant="contained" onClick={this.requestSubmit} color="primary" size="small" disabled={submitClicked}>
                                {submitClicked&&<CircularProgress size={24}/>} &nbsp; {submitButton}
                            </Button>
                        )}
                        <Button onClick={closeModal} color="primary" size="small">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default CustomizedDialogDemo;
