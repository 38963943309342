import React from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Payroll from './payroll'
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from '../../constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from '../../appRedux/actions/SettingActions';
import withRouter from 'react-router/es/withRouter';
import { Helpers } from '../../util/Helpers';
import {
  AUTH_SWITCHED_APP
} from "../../constants/SessionKeys";
import TimeAndAttendance from './timeAndAttendance';
import Payouts from './payouts';
import EmployeePortal from './employeePortal';
import Admin from './admin';
import Appraisal from './appraisal';
import GlobalVariable from "../../util/GlobalVariable";

class SideNav extends React.PureComponent {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }

  render() {
    const {
      navCollapsed, drawerType, width, navigationStyle
    } = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    const pathname = this.props.history.location.pathname;
    let app = Helpers.getItem(AUTH_SWITCHED_APP);
    if (pathname.startsWith('/portal')) {
      app = 'employee-portal';
    } else if (pathname.startsWith('/attendance')) {
      app = 'attendance';
    } else if (pathname.startsWith('/hrm')) {
      app = 'hrm';
    } else if (pathname.startsWith('/payouts')) {
      app = 'payouts';
    } else if (pathname.startsWith('/home')) {
      app = 'home';
    }else if(pathname.startsWith('/admin')){
      app='admin';
    }else if(pathname.startsWith('/appraisals')){
      app='appraisals';
    }
    if(app === 'payroll'){//injected from database on log in
      app = 'hrm';
    }
    if((!app || app === '')){
      app = 'home';
    }

    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav'
          }}
        >
          {
            app==='hrm' ? <Payroll />:app==='attendance'?<TimeAndAttendance/>:
              app==='payouts' ?<Payouts/>:app==='employee-portal'?<EmployeePortal/>:app==='admin'?<Admin/>:app==='appraisals'?<Appraisal/>:''
          }
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {
    navCollapsed, drawerType, width, navigationStyle
  } = settings;
  return {
    navCollapsed,
    drawerType,
    width,
    navigationStyle
  };
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav));
