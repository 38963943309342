import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    DELETE_SYNCED_DEVICES,
    deleteSyncedDevicesSuccessGenerator,
    FETCH_SYNCED_DEVICES,
    fetchSyncedDevicesSuccessGenerator,
    UPDATE_SYNCED_DEVICES,
    updateSyncedDevicesSuccessGenerator
} from "../../actions/attendance/SyncedDevicesActions";

const fetchSyncedDevicesRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/attendance/detection-devices',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const deleteSyncedDevicesRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/detection-device/delete/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editSyncedDevicesRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/attendance/detection-device/update/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchSyncedDevices({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchSyncedDevicesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchSyncedDevicesSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* deleteSyncedDevices({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteSyncedDevicesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(deleteSyncedDevicesSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editSyncedDevices({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(editSyncedDevicesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(updateSyncedDevicesSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchSyncedDevicesDefault() {
    yield takeLatest(FETCH_SYNCED_DEVICES, fetchSyncedDevices)
}
export function* deleteSyncedDevicesDefault() {
    yield takeLatest(DELETE_SYNCED_DEVICES, deleteSyncedDevices);
}
export function* editSyncedDevicesDefault() {
    yield takeLatest(UPDATE_SYNCED_DEVICES, editSyncedDevices);
}
export default function* rootSaga() {
    yield all([fork(fetchSyncedDevicesDefault),
        fork(deleteSyncedDevicesDefault),
        fork(editSyncedDevicesDefault)
    ]);
}
