export const FETCH_SUPER_ADMIN_DASHBOARD = 'FETCH_SUPER_ADMIN_DASHBOARD';
export const FETCH_SUPER_ADMIN_DASHBOARD_SUCCESS = 'FETCH_SUPER_ADMIN_DASHBOARD_SUCCESS';

export const  fetchSuperAdminDashboardGenerator = (data)=>{
   return {
       type:FETCH_SUPER_ADMIN_DASHBOARD,
       payload:data
   }
};

export const fetchSuperAdminDashboardSuccessGenerator = (data)=>{
   return {
       type:FETCH_SUPER_ADMIN_DASHBOARD_SUCCESS,
       payload:data
   }
};