export const FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS =
  'FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS';
export const FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS =
  'FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS';

export const FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS =
  'FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS';
export const FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS =
  'FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS';

export const ADD_SUBSCRIPTION_DAYS = 'ADD_SUBSCRIPTION_DAYS';
export const ADD_SUBSCRIPTION_DAYS_SUCCESS = 'ADD_SUBSCRIPTION_DAYS_SUCCESS';

export const BANK_TRANSFER_SUBSCRIPTION = "BANK_TRANSFER_SUBSCRIPTION";

export const bankTransferSubscriptionGenerator = (payload=[])=>{
  return {
    type:BANK_TRANSFER_SUBSCRIPTION,
    payload
  }
};

export const fetchActiveCompaniesSubscriptionsGenerator = (data = []) => {
  return {
    type: FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS,
    payload: data
  };
};
export const fetchActiveCompaniesSubscriptionsSuccessGenerator = data => {
  return {
    type: FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS,
    payload: data
  };
};
export const fetchInActiveCompaniesSubscriptionsGenerator = (data = []) => {
  return {
    type: FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS,
    payload: data
  };
};
export const fetchInActiveCompaniesSubscriptionsSuccessGenerator = data => {
  return {
    type: FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS,
    payload: data
  };
};
export const addSubscriptionDaysGenerator = (data = []) => {
  return {
    type: ADD_SUBSCRIPTION_DAYS,
    payload: data
  };
};
export const addSubscriptionDaysSuccessGenerator = data => {
  return {
    type: ADD_SUBSCRIPTION_DAYS_SUCCESS,
    payload: data
  };
};
