import {
  FETCH_APPRAISAL_DASHBOARD_SUCCESS,
  FETCH_HRM_DASHBOARD_SUCCESS,
  FETCH_PAYOUT_DASHBOARD_SUCCESS
} from '../../actions/common/DashboardsActions';
import moment from 'moment';

const INIT_STATE = {
  payrollDashboard:[],
  payoutsDashboard:{},
  payrollBatches:0,//changed from an object to number
  activeEmployees:0,
  inactiveEmployees:0,
  month:moment().format('F'),
  year:moment().format('Y'),
  paye:0,
  nssf:0,
  nhif:0,
  gross:0,
  onLeave:0,
  employees:0,
  rate:0,
  leaveRequest:0,
  advanceRequest:0,
  chartData:{},
  otherDeductions:0,
  pipEmployees:0,
  selfAppraisals:0,
  graphData:{},
  supervisorAppraisal:0,
  notInPip:0
};
export default (state=INIT_STATE,action)=>{
  let payrolls;
  switch (action.type) {
    case FETCH_HRM_DASHBOARD_SUCCESS:
      payrolls ={...state.payrollDashboard};
      payrolls =action.payload;
      return{
        ...state,
        payrollDashboard: payrolls,
        payrollBatches:payrolls.payrollBatches,
        activeEmployees:payrolls.activeemployees,
        employees:payrolls.employees,
        inactiveEmployees:payrolls.inactiveemployees,
        month:payrolls.month,
        year:payrolls.year,
        paye:payrolls.paye,
        nssf:payrolls.nssf,
        rate:payrolls.rate,
        nhif:payrolls.nhif,
        gross:payrolls.gross,
        leaveRequest:payrolls.leaves_requests,
        advanceRequest:payrolls.advance_requests,
        chartData:payrolls.payroll_data,
        onLeave:payrolls.onleave,
        otherDeductions:payrolls.other_deductions,
      };
    case FETCH_PAYOUT_DASHBOARD_SUCCESS:
      return{
        ...state,
        payoutsDashboard: action.payload
      };
    case FETCH_APPRAISAL_DASHBOARD_SUCCESS:
      return {
        ...state,
        pipEmployees:action.payload.pip_employees,
        selfAppraisals:action.payload.self_appraisals,
        graphData:action.payload.graph_data,
        supervisorAppraisal:action.payload.supervisor_appraisals,
        notInPip:action.payload.not_in_pip
      };
    default:
      /*main system dashboard goes here*/
      return{
        ...state
      };

  }
}
