
////create
export const ADD_MORTGAGE_RELIEF = 'ADD_MORTGAGE_RELIEF';
export const ADD_MORTGAGE_RELIEF_SUCCESS = 'ADD_MORTGAGE_RELIEF_SUCCESS';
/// fetch
export const FETCH_MORTGAGE_RELIEF = 'FETCH_MORTGAGE_RELIEF';
export const FETCH_MORTGAGE_RELIEF_SUCCESS = 'FETCH_MORTGAGE_RELIEF_SUCCESS';
// ////edit
export const EDIT_MORTGAGE_RELIEF = 'EDIT_MORTGAGE_RELIEF';
export const EDIT_MORTGAGE_RELIEF_SUCCESS = 'EDIT_MORTGAGE_RELIEF_SUCCESS';
// ////delete
export const DELETE_MORTGAGE_RELIEF = 'DELETE_MORTGAGE_RELIEF';
export const DELETE_MORTGAGE_RELIEF_SUCCESS ='DELETE_MORTGAGE_RELIEF_SUCCESS';
////activate
export const SHOW_DEACTIVATE_MORTGAGE_RELIEF_FORM = 'SHOW_DEACTIVATE_MORTGAGE_RELIEF_FORM';
export const HIDE_DEACTIVATE_MORTGAGE_RELIEF_FORM = 'HIDE_DEACTIVATE_MORTGAGE_RELIEF_FORM';
////deactivate
export const SHOW_ACTIVATE_MORTGAGE_RELIEF_FORM = 'SHOW_ACTIVATE_MORTGAGE_RELIEF_FORM';
export const HIDE_ACTIVATE_MORTGAGE_RELIEF_FORM = 'HIDE_ACTIVATE_MORTGAGE_RELIEF_FORM';

export const BULK_MORTGAGE_RELIEF = 'BULK_MORTGAGE_RELIEF';
export const BULK_MORTGAGE_RELIEF_SUCCESS = 'BULK_MORTGAGE_RELIEF_SUCCESS';
/////CREATE
export const addMortgageReliefGenerator = (data)=>{
  return {
    type:ADD_MORTGAGE_RELIEF ,
    payload: data
  }
};

export const addMortgageReliefSuccessGenerator =(data)=>{
  return {
    type:ADD_MORTGAGE_RELIEF_SUCCESS,
    payload:data
  }
};

export const bulkMortgageReliefGenerator = (data)=>{
  return {
    type:BULK_MORTGAGE_RELIEF ,
    payload: data
  }
};

export const bulkMortgageReliefSuccessGenerator =(data)=>{
  return {
    type:BULK_MORTGAGE_RELIEF_SUCCESS,
    payload:data
  }
};

//////FETCH
export const fetchMortgageReliefGenerator=(data=[])=>{
  return {
    type:FETCH_MORTGAGE_RELIEF,
    payload:data
  }
};

export const fetchMortgageReliefSuccessGenerator =(data)=>{
  return {
    type:FETCH_MORTGAGE_RELIEF_SUCCESS,
    payload:data
  }
};


///EDIT
export const editMortgageReliefGenerator = (data)=>{
  return {
    type: EDIT_MORTGAGE_RELIEF,
    payload: data
  }
};

export const editMortgageReliefSuccessGenerator=(data)=>{
  return {
    type:EDIT_MORTGAGE_RELIEF_SUCCESS,
    payload:data
  }
};
// /////DELETE
export const deleteMortgageReliefGenerator=(data)=>{
  return {
    type:DELETE_MORTGAGE_RELIEF,
    payload:data
  }
};

export const deleteMortgageReliefSuccessGenerator = (data)=>{
  return {
    type:DELETE_MORTGAGE_RELIEF_SUCCESS,
    payload:data
  }
};
export const showDeactivateMortgageReliefModalGenerator = (data) =>{
  return {
    type:SHOW_DEACTIVATE_MORTGAGE_RELIEF_FORM,
    payload:data
  }
};
export const hideDeactivateMortgageReliefModalGenerator = (data) =>{
  return {
    type:HIDE_DEACTIVATE_MORTGAGE_RELIEF_FORM,
    payload:data
  }
};
export const showActivateMortgageReliefModalGenerator = (data) =>{
  return {
    type:SHOW_ACTIVATE_MORTGAGE_RELIEF_FORM,
    payload:data
  }
};
export const hideActivateMortgageReliefModalGenerator = (data) =>{
  return {
    type:HIDE_ACTIVATE_MORTGAGE_RELIEF_FORM,
    payload:data
  }
};
