import {all, call, fork, put} from "redux-saga/effects";
import {
    ADD_SALARYADVANCE,
    APPROVE_SALARY_ADVANCE_MULTIPLE,
    CERTIFY_SALARY_ADVANCE_MULTIPLE,
    DELETE_SALARY_ADVANCE_MULTIPLE,
    DELETE_SALARYADVANCE,
    DISAPPROVE_SALARY_ADVANCE_MULTIPLE, EDIT_SALARY_ADVANCE_LIMIT,
    EDIT_SALARYADVANCE,
    FETCH_SALARYADVANCES,
    fetchSalaryAdvancesGenerator,
    fetchSalaryAdvancesSuccessGenerator,
    MARK_SALARY_ADVANCE_AS_PAID,
    PAY_SALARY_ADVANCE,
    REJECT_SALARY_ADVANCE_MULTIPLE,
    UPLOAD_SALARY_ADVANCE,
    uploadSalaryAdvanceSuccessGenerator
} from "../../actions/payroll/SalaryAdvanceActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import {
    fetchAccountSettingsGenerator,
    hideAppLoaderGenerator,
    showAppLoaderGenerator,
    showMessageGenerator
} from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";

import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { push } from 'connected-react-router';
import { fetchRecipientsSuccessGenerator } from "../../actions/payouts/BulkPayoutActions";

const certifySalaryAdvanceMultipleRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/salary_advance/certify/multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const approveSalaryAdvanceMultipleRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/salary_advance/approve/multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const fetchSalaryAdvancesRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/hrm/salary',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addSalaryAdvanceRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/salary',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteSalaryAdvanceRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/salary/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editSalaryAdvanceRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/hrm/salary/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);

const editSalaryAdvanceLimitRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/salary/limit/edit',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const paySalaryAdvanceRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/make/payment',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const markSalaryAdvanceAsPaidRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/salary_advance/mark_as_paid',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const rejectSalaryAdvanceMultipleRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/salary_advance/reject_multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const disapproveSalaryAdvanceMultipleRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/salary_advance/disapprove_multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const deleteSalaryAdvanceMultipleRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/salary_advance/delete_multiple',requestData)
        .then(response=>response)
        .catch(error=>error.response);

function* rejectSalaryAdvanceMultiple({type,payload}) {
 yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(rejectSalaryAdvanceMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* disapproveSalaryAdvanceMultiple({type,payload}) {
 yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(disapproveSalaryAdvanceMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteSalaryAdvanceMultiple({type,payload}) {
 yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteSalaryAdvanceMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}


function* certifySalaryAdvanceMultiple({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(certifySalaryAdvanceMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let advances = response.data.data;
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* approveSalaryAdvanceMutliple({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(approveSalaryAdvanceMultipleRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let advances = response.data.data;
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchSalaryAdvances({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchSalaryAdvancesRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let advances = response.data.data;
                yield put(fetchSalaryAdvancesSuccessGenerator(advances));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addSalaryAdvance({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addSalaryAdvanceRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let advance = response.data.data;
                //yield put(addSalaryAdvanceSuccessGenerator(advance));
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editSalaryAdvanceLimit({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editSalaryAdvanceLimitRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchAccountSettingsGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteSalaryAdvance({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteSalaryAdvanceRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let advance = response.data.data;
                //yield put(deleteSalaryAdvanceSuccessGenerator(advance));
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editSalaryAdvance({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editSalaryAdvanceRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let advance = response.data.data;
                //yield put(editSalaryAdvanceSuccessGenerator(advance));
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* paySalaryAdvance({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(paySalaryAdvanceRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchRecipientsSuccessGenerator(response.data.data));
                yield put(push('/payouts/bulk-payouts'));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* markSalaryAdvanceAsPaid({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(markSalaryAdvanceAsPaidRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchSalaryAdvancesGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
const uploadBulkAdvanceRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/hrm/salary",requestParameters, {headers: {
          'content-type': `multipart/form-data`
      }})
    .then(response=>response).catch(error =>error.response);
function* uploadSalaryAdvance({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const formData = Helpers.getFormData(requestParameters);
        const response = yield call(uploadBulkAdvanceRequest, formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let salaryAdvance = response.data.data;
               // yield put(uploadSalaryAdvanceSuccessGenerator(salaryAdvance));
                yield   put(fetchSalaryAdvancesGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* rejectSalaryAdvanceMultipleDefault(){
    yield takeLatest(REJECT_SALARY_ADVANCE_MULTIPLE,rejectSalaryAdvanceMultiple)
}
export function* disapproveSalaryAdvanceMultipleDefault(){
    yield takeLatest(DISAPPROVE_SALARY_ADVANCE_MULTIPLE,disapproveSalaryAdvanceMultiple)
}
export function* deleteSalaryAdvanceMultipleDefault(){
    yield takeLatest(DELETE_SALARY_ADVANCE_MULTIPLE,deleteSalaryAdvanceMultiple)
}

export function* certifySalaryAdvanceMultipleDefault(){
yield takeLatest(CERTIFY_SALARY_ADVANCE_MULTIPLE,certifySalaryAdvanceMultiple);
}
export function* approveSalaryAdvanceMultipleDefault(){
yield takeLatest(APPROVE_SALARY_ADVANCE_MULTIPLE,approveSalaryAdvanceMutliple);
}
export function* uploadSalaryAdvancesDefault() {
    yield takeLatest(UPLOAD_SALARY_ADVANCE, uploadSalaryAdvance);
}
export function* fetchSalaryAdvancesDefault() {
    yield takeLatest(FETCH_SALARYADVANCES, fetchSalaryAdvances)
}
export function* addSalaryAdvanceDefault() {
    yield takeLatest(ADD_SALARYADVANCE, addSalaryAdvance);
}
export function* editSalaryAdvanceLimitDefault() {
    yield takeLatest(EDIT_SALARY_ADVANCE_LIMIT, editSalaryAdvanceLimit);
}
export function* deleteSalaryAdvanceDefault() {
    yield takeLatest(DELETE_SALARYADVANCE, deleteSalaryAdvance);
}
export function* editSalaryAdvanceDefault() {
    yield takeLatest(EDIT_SALARYADVANCE, editSalaryAdvance);
}
export function* paySalaryAdvanceDefault() {
    yield takeLatest(PAY_SALARY_ADVANCE, paySalaryAdvance);
}
export function* markSalaryAdvanceAsPaidDefault() {
    yield takeLatest(MARK_SALARY_ADVANCE_AS_PAID, markSalaryAdvanceAsPaid);
}
export default function* rootSaga() {
    yield all([fork(fetchSalaryAdvancesDefault),
        fork(addSalaryAdvanceDefault),
        fork(deleteSalaryAdvanceDefault),
        fork(editSalaryAdvanceDefault),
        fork(uploadSalaryAdvancesDefault),
        fork(paySalaryAdvanceDefault),
        fork(certifySalaryAdvanceMultipleDefault),
        fork(approveSalaryAdvanceMultipleDefault),
        fork(rejectSalaryAdvanceMultipleDefault),
        fork(disapproveSalaryAdvanceMultipleDefault),
        fork(deleteSalaryAdvanceMultipleDefault),
        fork(markSalaryAdvanceAsPaidDefault),
        fork(editSalaryAdvanceLimitDefault)
    ]);
}
