import { DELETE_SYSTEM_USERS_SUCCESS, FETCH_SYSTEM_USERS_SUCCESS } from "../../actions/admin/SystemUsersActions";

const INIT_STATE = {
  systemUsers:{},
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {

    case FETCH_SYSTEM_USERS_SUCCESS:
      return {
        ...state,
        systemUsers: action.payload
      };
    case DELETE_SYSTEM_USERS_SUCCESS:
      let systemUsers = {...state.systemUsers};
      delete systemUsers[action.payload.id];
      return {
        ...state,
        systemUsers:systemUsers
      };
    default:
      return state;
  }
}
