import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  fetchExchangeRatesGenerator, fetchExchangeRatesSuccessGenerator,
  UPDATE_EXCHANGE_RATES, DELETE_EXCHANGE_RATES,
  FETCH_EXCHANGE_RATES, ADD_EXCHANGE_RATES, UPLOAD_EXCHANGE_RATES
} from "../../actions/admin/ExchangeRatesActions";

const fetchExchangeRatesRequest= async (requestParameters) =>
  await axios.get(API_ENDPOINT+'/tozza/exchange-rates',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addExchangeRatesRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT+'/tozza/exchange-rates',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editExchangeRatesRequest = async (requestParameters) =>
  await axios.put(API_ENDPOINT+'/tozza/exchange-rates/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteExchangeRatesRequest = async (requestParameters) =>
  await axios.delete(API_ENDPOINT+'/tozza/exchange-rates/'+(requestParameters.id ? requestParameters.id : 0),{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const uploadExchangeRatesRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT+'/tozza/exchange-rates/upload',requestParameters, {headers: {
          'content-type': `multipart/form-data`
      }})
    .then(response => response)
    .catch(error => error.response);

function* fetchExchangeRates({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchExchangeRatesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchExchangeRatesSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addExchangeRates({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addExchangeRatesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchExchangeRatesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editExchangeRates({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editExchangeRatesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchExchangeRatesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteExchangeRates({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteExchangeRatesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchExchangeRatesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* uploadExchangeRates({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
      let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
      const formData = Helpers.getFormData(requestParameters);
      const response = yield call(uploadExchangeRatesRequest, formData);
      if (response.status === 200 || response.status === 201) {
          if (response.data.success) {
              yield put(fetchExchangeRatesGenerator(payload.params));
              yield put(showMessageGenerator(response.data.message,'success'));
          } else {
              yield put(showMessageGenerator(response.data.message,'error'));
          }
      }else if(response.status === 401){
          Helpers.clearAuthData();
          yield put(userSignOutSuccessGenerator());
      }else {
          yield put(showMessageGenerator(response.statusText,'error'));
      }
  } catch (error) {
      yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


export function* fetchExchangeRatesDefault() {
  yield takeLatest(FETCH_EXCHANGE_RATES, fetchExchangeRates)
}
export function* addExchangeRatesDefault() {
  yield takeLatest(ADD_EXCHANGE_RATES, addExchangeRates);
}
export function* deleteExchangeRatesDefault() {
  yield takeLatest(DELETE_EXCHANGE_RATES, deleteExchangeRates);
}
export function* editExchangeRatesDefault() {
  yield takeLatest(UPDATE_EXCHANGE_RATES, editExchangeRates);
}
export function* uploadExchangeRatesDefault() {
  yield takeLatest(UPLOAD_EXCHANGE_RATES, uploadExchangeRates)
}

export default function* rootSaga() {
  yield all([
    fork(fetchExchangeRatesDefault),
    fork(addExchangeRatesDefault),
    fork(deleteExchangeRatesDefault),
    fork(editExchangeRatesDefault),
    fork(uploadExchangeRatesDefault)
  ]);
}
