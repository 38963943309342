import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { confirmPaymentGenerator, sendRequestGenerator } from '../../../appRedux/actions/payouts/RechargePayoutActions';
import { Helpers as Helper, Helpers } from "../../../util/Helpers";
import BackGroundLoader from "../../../components/common/BackGroundLoader";
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import { SUBSCRIPTION_KEY } from '../../../constants/SessionKeys';
import { userSignOutGenerator } from "../../../appRedux/actions/AuthActions";

const styles = theme => ({
  card: {
    margin: 10,
    padding: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  title: {
    display: 'flex'
  },
});



class Mpesa extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mobile_mpesa: this.props.mobile_mpesa || '',
      amount_mpesa: this.props.amount_mpesa || '',
      type: 'mpesa',
      page:this.props.page,
      head_office_id: this.props.head_office_id || Helpers.authCurrentHeadOfficeID,
      branch_id: this.props.branch_id || Helpers.authSwitchedCompanyID()
    };
  }
  formValidate = () =>{
    const fieldsToValidate = { branch_id:{} };

    if(!Helper.formValidations(fieldsToValidate,this.state)){
      return false;
    }else{
      return true;
    }
  };

  updateState = (e) => {
    const { id, name, value } = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState });
  };

  proceed = () => {
    this.setState({
      page:null
    }) ;
  if (!this.formValidate()){
    return ;
  }
    this.props.sendRequestGenerator(this.state);
  };

  confirm =() => {
    const data = {...this.props};
    data.account = this.props.account;
    data.mobile = this.props.mobile;
    data.checkout = this.props.checkout;
    if (this.props.page){
      if (this.props.upgrading_employees) {
        data.upgrading_employees = true;
        data.noOfEmployees = this.props.noOfEmployees;
      }else{
        data.is_for_subscription = true;
      }
    }
    this.props.confirmPaymentGenerator(data);
  };

  updateState1 = (name, e) => {
    const newState = Object.assign({}, this.state);
    newState[name] = e.value;
    this.setState({ ...newState });
  };

  render() {
    const validationMessage='This field is required!';
    const { classes,companiesByID} = this.props;
    const { mobile_mpesa, amount_mpesa, branch_id,head_office_id } = this.state;
    const branches = Helpers.companyBranches(companiesByID, head_office_id);
    const branch_idd = this.state.branch_id;

    let defaultBranch = Helpers.recordFromNonIndexedDataWithDefault(branches, branch_idd, null);

    if(defaultBranch && defaultBranch.id === head_office_id){
      defaultBranch = {label:'Head Office', value:defaultBranch.id};
    }else if(defaultBranch){
      defaultBranch = {label:defaultBranch.name, value:defaultBranch.id};
    }else{}
    return (
      <div className={classes.card}>
        {
         Helpers.isNull(this.state.page) && (Helpers.loading(this.props)
             && (
                 <BackGroundLoader />
             ))
        }
        <br/>
        {this.props.path === '' || this.props.type !== 'mpesa' ? (
          <form className="profile-container">
            <div className="row">
              <div className="col-12">
                <FormControl fullWidth placeholder="Select Type" margin="normal" required>
                  <InputLabel htmlFor="branch_id" className="select-label">
                    Select Branch
                  </InputLabel>
                  <Select
                    onChange={this.updateState1.bind(this,'branch_id')}
                    name="branch_id"
                    id="branch_id"
                    value={defaultBranch}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    options={Helpers.renderBranchMenuItems(branches)}
                    isSearchable={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      color: 'green',
                      colors: {
                        ...theme.colors,
                        primary: '#3CB534',
                        primary25: '#E2F4E0',
                        primary50: '#E2F4E0'
                      }
                    })}
                  />
                  <span className="error text-danger">{this.proceed  && branch_id === "" ? validationMessage : ' '}</span>
                </FormControl>
              </div>
              <div className="col-12">
                <TextField
                  required
                  id="mobile_mpesa"
                  label="Mobile Number (format 254712345678)"
                  value={mobile_mpesa}
                  fullWidth
                  placeholder="254712345678"
                  margin="normal"
                  onChange={this.updateState}
                />
              </div>
              <div className="col-12">
                <TextField
                  required
                  id="amount_mpesa"
                  label="Amount"
                  value={amount_mpesa}
                  fullWidth
                  placeholder="Amount"
                  margin="normal"
                  onChange={this.updateState}
                />
              </div>
              <div className="col-12">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{textDecoration: 'none', marginTop: 10 }}
                  onClick={this.proceed}>
                  Proceed with payment
                </Button>
                {
                  this.state.page &&[
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{textDecoration: 'none', marginTop: 10, marginLeft: 50 }}
                      onClick={() => Helpers.choosePlanRedirect()}>
                      Change current plan
                    </Button>,
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{textDecoration: 'none', marginTop: 10, marginLeft: 50 }}
                      onClick={() => {
                        Helper.clearAuthData();
                       return this.props.userSignOutGenerator();
                      }}>
                      Sign Out
                    </Button>,
                  ]
                }
              </div>
            </div>

          </form>
        ) : (
          <form>
            <p>
A payment request Account Number {' '}
              {this.props.account}
              {' '}
has been sent to your mobile . Please complete the request and confirm the payment by clicking on the Confirm payment button below.
            </p>
            <div className={classes.title}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={{ textDecoration: 'none',marginRight: 20, marginTop: 10 }}
                onClick={this.confirm}
                >
                   Confirm
              </Button>
              {'    '}
              <Button
                color="primary"
                variant="outlined"
                size="small"
                style={{textDecoration: 'none', marginRight: 20, marginTop: 10 }}
                onClick={this.proceed}>
                    Retry
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({recharge, general,company, auth}) => {
  const {companiesByID} = company;
  let head_office_id = Helpers.authCurrentHeadOfficeID()
  const {
    mobile, account, path, checkout, type
  } = recharge;
  const {appLoading} = general;
  return {
    mobile, account, path, checkout, type, appLoading,head_office_id,companiesByID, auth
  };
};
export default withStyles(styles)(connect(mapStateToProps, {sendRequestGenerator, confirmPaymentGenerator,userSignOutGenerator})(Mpesa));
