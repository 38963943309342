import {
    FETCH_ALL_FEATURES_SUCCESS,
    FETCH_COMPANY_ENABLED_FEATURES_SUCCESS
} from "../../actions/admin/EnableDisableFeaturesAction";

const INIT_STATE ={
    allFeatures:{},
    companyEnabledFeatures:{}
};


export default (state =INIT_STATE,action) =>{
    switch (action.type) {
        case FETCH_ALL_FEATURES_SUCCESS:
            return {
                ...state,
                allFeatures: action.payload
            };
        case FETCH_COMPANY_ENABLED_FEATURES_SUCCESS:
            return {
                ...state,
                companyEnabledFeatures: action.payload
            }
        default:
            return state
    }
}
