import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_PRODUCT_VARIETY, DELETE_PRODUCT_VARIETY, EDIT_PRODUCT_VARIETY
} from "../../actions/payroll/ProductVarietyActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { fetchProductsGenerator } from "../../actions/payroll/ProductActions";

const addProductVarietyRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/product_varieties',requestParameters, {headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const deleteProductVarietyRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/product_varieties/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editProductVarietyRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/product_varieties/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* addProductVariety({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(addProductVarietyRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteProductVariety({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteProductVarietyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editProductVariety({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editProductVarietyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchProductsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* addProductVarietyDefault() {
  yield takeLatest(ADD_PRODUCT_VARIETY, addProductVariety);
}
export function* deleteProductVarietyDefault() {
  yield takeLatest(DELETE_PRODUCT_VARIETY, deleteProductVariety);
}
export function* editProductVarietyDefault() {
  yield takeLatest(EDIT_PRODUCT_VARIETY, editProductVariety);
}
export default function* rootSaga() {
  yield all([
    fork(addProductVarietyDefault),
    fork(editProductVarietyDefault),
    fork(deleteProductVarietyDefault),
  ]);
}
