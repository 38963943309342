import React from 'react';

import Loader from 'react-loader-spinner'
export default class CustomLoader extends React.Component {
    //other logic
    render() {
        return(
            <Loader
                type="Bars"
                color="#3D8E3C"
                height="50"
                width="40"
            />
        );
    }
}