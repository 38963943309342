import {Helpers} from "../../../util/Helpers";
import axios from "axios/index";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {all, call, fork, put} from "redux-saga/effects";
import {takeLatest} from "redux-saga/effects";
import {
  ADD_LEAVE_TYPE_CATEGORY, addLeaveTypeCategorySuccessGenerator,
  DELETE_LEAVE_TYPE_CATEGORY,
  deleteLeaveTypeCategorySuccessGenerator,
  EDIT_LEAVE_TYPE_CATEGORY,
  editLeaveTypeCategorySuccessGenerator,
  FETCH_LEAVE_TYPE_CATEGORY,
  fetchLeaveTypeCategorySuccessGenerator
} from "../../actions/payroll/LeaveTypeCategoryActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
///fetch
const fetchLeaveTypeCategoryRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/leavetypes',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);

function* fetchLeaveTypeCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLeaveTypeCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(fetchLeaveTypeCategorySuccessGenerator(leaveTypeCategory));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLeaveTypeCategoryDefault() {
  yield takeLatest(FETCH_LEAVE_TYPE_CATEGORY,fetchLeaveTypeCategory)
}
////end fetch
///edit
const editLeaveTypeCategoryRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/leavetypes/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* editLeaveTypeCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editLeaveTypeCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(editLeaveTypeCategorySuccessGenerator(leaveTypeCategory));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editLeaveTypeCategoryDefault() {
  yield takeLatest(EDIT_LEAVE_TYPE_CATEGORY,editLeaveTypeCategory)
}
////end edit
///delete
const deleteLeaveTypeCategoryRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/leavetypes/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* deleteLeaveTypeCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteLeaveTypeCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(deleteLeaveTypeCategorySuccessGenerator(leaveTypeCategory));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteLeaveTypeCategoryDefault() {
  yield takeLatest(DELETE_LEAVE_TYPE_CATEGORY,deleteLeaveTypeCategory)
}
//end delete
////creating
const createLeaveTypeCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/leavetypes',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* createLeaveTypeCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(createLeaveTypeCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(addLeaveTypeCategorySuccessGenerator(leaveTypeCategory));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* createLeaveTypeCategoryDefault() {
  yield takeLatest(ADD_LEAVE_TYPE_CATEGORY,createLeaveTypeCategory)
}
//end create
export default function* rootSagas(){
  yield all(
    [
    fork(fetchLeaveTypeCategoryDefault),
    fork(editLeaveTypeCategoryDefault),
    fork(deleteLeaveTypeCategoryDefault),
    fork(createLeaveTypeCategoryDefault)
    ])
}
