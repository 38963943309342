import BulkPayoutReducer from "./BulkPayoutReducer";
import TransactionReducer from "./TransactionReducer";
import RechargePayoutReducer from "./RechargePayoutReducer";
import PayoutChargeReducer from "./PayoutChargeReducer";
const Payouts ={
  bulkPayout:BulkPayoutReducer,
  transaction:TransactionReducer,
  recharge:RechargePayoutReducer,
  payoutCharge:PayoutChargeReducer
};

export default Payouts;
