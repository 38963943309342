import { FETCH_EMPLOYEE_RENT_SUCCESS} from "../../actions/payroll/EmployeeRentActions";

const INIT_STATE ={
  employeeRents:{},
};
export default (state =INIT_STATE,action) =>{
  switch(action.type){
    case FETCH_EMPLOYEE_RENT_SUCCESS:
      return {
        ...state,
        employeeRents:action.payload
      };
    default:
      return state;
  }
}
