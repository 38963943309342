import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";

import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  CREATE_MARKET_LENDING_RATES,
  createMarketLendingRatesSuccessGenerator, DELETE_MARKET_LENDING_RATES,
  deleteMarketLendingRatesSuccessGenerator,
  EDIT_MARKET_LENDING_RATES,
  editMarketLendingRatesSuccessGenerator,
  FETCH_MARKET_LENDING_RATES,
  fetchMarketLendingRatesSuccessGenerator
} from "../../actions/payroll/FringeBenefitActions";


const fetchMarketLendingRateRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/market/lending/benefit',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const createMarketLendingRateRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/market/lending/benefit/create',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editMarketLendingRateRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/market/lending/benefit/edit/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteMarketLendingRateRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/market/lending/benefit/delete/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);


function* fetchMarketLendingRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchMarketLendingRateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchMarketLendingRatesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* createMarketLendingRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(createMarketLendingRateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(createMarketLendingRatesSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editMarketLendingRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editMarketLendingRateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(editMarketLendingRatesSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteMarketLendingRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteMarketLendingRateRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(deleteMarketLendingRatesSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


export function* fetchMarketLendingRateDefault(){
  yield  takeLatest(FETCH_MARKET_LENDING_RATES,fetchMarketLendingRate);
}

export function* createMarketLendingRateDefault(){
  yield  takeLatest(CREATE_MARKET_LENDING_RATES,createMarketLendingRate);
}

export function* editMarketLendingRateDefault(){
  yield  takeLatest(EDIT_MARKET_LENDING_RATES,editMarketLendingRate);
}

export function* deleteMarketLendingRateDefault(){
  yield  takeLatest(DELETE_MARKET_LENDING_RATES,deleteMarketLendingRate);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMarketLendingRateDefault),
    fork(createMarketLendingRateDefault),
    fork(editMarketLendingRateDefault),
    fork(deleteMarketLendingRateDefault)
  ]);
}
