export const FETCH_DOCUMENT_CATEGORY = "FETCH_DOCUMENT_CATEGORY";
export const FETCH_DOCUMENT_CATEGORY_SUCCESS = "FETCH_DOCUMENT_CATEGORY_SUCCESS";
export const SHOW_DOCUMENT_CATEGORY_CREATE_FORM = 'SHOW_DOCUMENT_CATEGORY_CREATE_FORM';
export const HIDE_DOCUMENT_CATEGORY_CREATE_FORM = 'HIDE_DOCUMENT_CATEGORY_CREATE_FORM';
export const ADD_DOCUMENT_CATEGORY = 'ADD_DOCUMENT_CATEGORY';
export const ADD_DOCUMENT_CATEGORY_SUCCESS = 'ADD_DOCUMENT_CATEGORY_SUCCESS';
export const SHOW_DOCUMENT_CATEGORY_EDIT_FORM = 'SHOW_DOCUMENT_CATEGORY_EDIT_FORM';
export const HIDE_DOCUMENT_CATEGORY_EDIT_FORM = 'HIDE_DOCUMENT_CATEGORY_EDIT_FORM';
export const EDIT_DOCUMENT_CATEGORY = 'EDIT_DOCUMENT_CATEGORY';
export const EDIT_DOCUMENT_CATEGORY_SUCCESS = 'EDIT_DOCUMENT_CATEGORY_SUCCESS';
export const SHOW_DOCUMENT_CATEGORY_DELETE_FORM = 'SHOW_DOCUMENT_CATEGORY_DELETE_FORM';
export const HIDE_DOCUMENT_CATEGORY_DELETE_FORM = 'HIDE_DOCUMENT_CATEGORY_DELETE_FORM';
export const DELETE_DOCUMENT_CATEGORY = 'DELETE_DOCUMENT_CATEGORY';
export const DELETE_DOCUMENT_CATEGORY_SUCCESS = 'DELETE_DOCUMENT_CATEGORY_SUCCESS';

export const fetchDocumentCategoryGenerator = (data=[])=>{
  return {
    type:FETCH_DOCUMENT_CATEGORY,
    payload:data
  };
};

export const fetchDocumentCategorySuccessGenerator = (dataById)=> {
  return {
    type:FETCH_DOCUMENT_CATEGORY_SUCCESS,
    payload:dataById
  }
};

export const showDocumentCategoryCreateFormGenerator = ()=>{
  return {
    type:SHOW_DOCUMENT_CATEGORY_CREATE_FORM
  };
};
export const hideDocumentCategoryCreateFormGenerator = ()=>{
  return {
    type:HIDE_DOCUMENT_CATEGORY_CREATE_FORM
  };
};
export const addDocumentCategoryGenerator = (data)=>{
  return {
    type: ADD_DOCUMENT_CATEGORY,
    payload: data
  }
};

export const addDocumentCategorySuccessGenerator = (data)=>{
  return {
    type: ADD_DOCUMENT_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showDocumentCategoryEditFormGenerator = (data)=>{
  return {
    type:SHOW_DOCUMENT_CATEGORY_EDIT_FORM,
    payload:data
  };
};
export const hideDocumentCategoryEditFormGenerator = ()=>{
  return {
    type:HIDE_DOCUMENT_CATEGORY_EDIT_FORM
  };
};
export const editDocumentCategoryGenerator = (data)=>{
  return {
    type: EDIT_DOCUMENT_CATEGORY,
    payload: data
  }
};
export const editDocumentCategorySuccessGenerator = (data)=>{
  return {
    type: EDIT_DOCUMENT_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showDocumentCategoryDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_DOCUMENT_CATEGORY_DELETE_FORM,
    payload:data
  };
};
export const hideDocumentCategoryDeleteFormGenerator = ()=>{
  return {
    type:HIDE_DOCUMENT_CATEGORY_DELETE_FORM
  };
};
export const deleteDocumentCategoryGenerator = (data)=>{
  return {
    type: DELETE_DOCUMENT_CATEGORY,
    payload: data
  }
};
export const deleteDocumentCategorySuccessGenerator = (data)=>{
  return {
    type: DELETE_DOCUMENT_CATEGORY_SUCCESS,
    payload: data
  }
};
