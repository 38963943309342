import {
  ADD_EXIT_SUCCESS,
  APPROVE_EXIT_SUCCESS,
  BULK_EXIT_SUCCESS,
  CERTIFY_EXIT_SUCCESS,
  DELETE_EXIT_SUCCESS,
  EDIT_EXIT_SUCCESS,
  FETCH_EXITS_SUCCESS,
  HIDE_EXIT_CREATE_FORM,
  HIDE_EXIT_DELETE_FORM,
  HIDE_EXIT_EDIT_FORM,
  REJECT_EXIT_SUCCESS,
  SHOW_EXIT_CREATE_FORM,
  SHOW_EXIT_DELETE_FORM,
  SHOW_EXIT_EDIT_FORM
} from '../../actions/payroll/ExitActions';

const INIT_STATE = {
  exitsByID:{},
  showExitCreateForm:false,
  showExitEditForm:false,
  exitToEdit:false,
  showExitDeleteForm:false,
  exitToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_EXITS_SUCCESS:
      return {
        ...state,
        exitsByID: action.payload
      };
    case SHOW_EXIT_CREATE_FORM:
      return {
        ...state,
        showExitCreateForm: true
      };
    case HIDE_EXIT_CREATE_FORM:
      return {
        ...state,
        showExitCreateForm: false
      };
    case SHOW_EXIT_EDIT_FORM:
      return {
        ...state,
        showExitEditForm: true,
        exitToEdit: action.payload
      };
    case HIDE_EXIT_EDIT_FORM:
      return {
        ...state,
        showExitEditForm: false
      };
    case ADD_EXIT_SUCCESS:
    case EDIT_EXIT_SUCCESS:
      let exit_id = action.payload.id;
      return {
        ...state,
        exitsByID: {
          ...state.exitsByID,
          [exit_id]:action.payload
        }
      };
    case BULK_EXIT_SUCCESS:
      return {
        ...state,
        exitsByID: action.payload
      };
    case SHOW_EXIT_DELETE_FORM:
      return {
        ...state,
        showExitDeleteForm: true,
        exitToDelete: action.payload
      };
    case HIDE_EXIT_DELETE_FORM:
      return {
        ...state,
        showExitDeleteForm: false
      };
    case DELETE_EXIT_SUCCESS:
      let exitsByID = {...state.exitsByID};
      delete exitsByID[action.payload.id];
      return {
        ...state,
        exitsByID:exitsByID
      };
    case CERTIFY_EXIT_SUCCESS:
    case APPROVE_EXIT_SUCCESS:
      return {
        ...state,
        exitsByID: action.payload
      };
    case REJECT_EXIT_SUCCESS:
      let exitsByIDs = {...state.exitsByID};
      exitsByIDs[action.payload.id]=action.payload;
      return {
        ...state,
        exitsByID:exitsByIDs
      };
    default:
      return state;
  }
}
