let GlobalCache = {};
export function setGlobalVariable(key, value) {
  if(key === undefined || key === null || key ===""){
    return false;
  }
  if(typeof value !== "string"){
    value = JSON.stringify(value);
  }
  GlobalCache = Object.assign({}, GlobalCache, {[key]:value});
  return true;
}
export function getGlobalVariable(key) {
  if(key === undefined || key === null || key ===""){
    return null;
  }
  if(!GlobalCache.hasOwnProperty(key)){
    return null;
  }
  let value = Object.assign({}, GlobalCache)[key];
  if(typeof value === "string"){
    return value;
  }
  return JSON.stringify(value);
}
export function hasGlobalVariable(key){
  if(key === undefined || key === null || key ===""){
    return false;
  }
  return GlobalCache.hasOwnProperty(key);
}
