import {
  FETCH_ATTENDANCE_DASHBOARD_SUCCESS, FETCH_ATTENDANCE_KEYS_SUCCESS,
  FETCH_ATTENDANCE_SUCCESS, UPDATE_ATTENDANCE_KEY_SUCCESS
} from "../../actions/attendance/AttendanceActions";

const INIT_STATE = {
  attendanceDayDetails:{},
  attendanceDashboard:{},
  attendanceKeys:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendanceDayDetails: action.payload
      };
    case FETCH_ATTENDANCE_DASHBOARD_SUCCESS:
      return {
        ...state,
        attendanceDashboard: action.payload
      };
    case FETCH_ATTENDANCE_KEYS_SUCCESS:
    case UPDATE_ATTENDANCE_KEY_SUCCESS:
      return {
        ...state,
        attendanceKeys: action.payload
      };
    default:
      return state;
  }
}
