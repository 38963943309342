
////create
export const ADD_ADDITION_CATEGORIES = 'ADD_ADDITION_CATEGORIES';
export const ADD_ADDITION_CATEGORIES_SUCCESS = 'ADD_ADDITION_CATEGORIES_SUCCESS';
export const SHOW_CREATE_ADDITION_CATEGORIES_FORM = 'SHOW_CREATE_ADDITION_CATEGORIES_FORM';
export const HIDE_CREATE_ADDITION_CATEGORIES_FORM = 'HIDE_CREATE_ADDITION_CATEGORIES_FORM';
/// fetch
export const FETCH_ADDITION_CATEGORIES = 'FETCH_ADDITION_CATEGORIES';
export const FETCH_ADDITION_CATEGORIES_SUCCESS = 'FETCH_ADDITION_CATEGORIES_SUCCESS';

/// fetch cat cc bands
export const FETCH_ADDITION_CAR_CC_BAND_CATEGORIES = 'FETCH_ADDITION_CAR_CC_BAND_CATEGORIES';
export const FETCH_ADDITION_CAR_CC_BAND_CATEGORIES_SUCCESS = 'FETCH_ADDITION_CAR_CC_BAND_CATEGORIES_SUCCESS';

// ////edit
export const EDIT_ADDITION_CATEGORIES = 'EDIT_ADDITION_CATEGORIES';
export const EDIT_ADDITION_CATEGORIES_SUCCESS = 'EDIT_ADDITION_CATEGORIES_SUCCESS';
export const SHOW_EDIT_ADDITION_CATEGORIES_FORM = 'SHOW_EDIT_ADDITION_CATEGORIES_FORM';
export const HIDE_EDIT_ADDITION_CATEGORIES_FORM = 'HIDE_EDIT_ADDITION_CATEGORIES_FORM';
// ////delete
export const DELETE_ADDITION_CATEGORIES = 'DELETE_ADDITION_CATEGORIES';
export const DELETE_ADDITION_CATEGORIES_SUCCESS ='DELETE_ADDITION_CATEGORIES_SUCCESS';
export const SHOW_DELETE_ADDITION_CATEGORIES_MODAL = 'SHOW_DELETE_ADDITION_CATEGORIES_MODAL';
export const HIDE_DELETE_ADDITION_CATEGORIES_MODAL = 'HIDE_DELETE_ADDITION_CATEGORIES_MODAL';
////activate
export const SHOW_DEACTIVATE_ADDITION_CATEGORY_FORM = 'SHOW_DEACTIVATE_ADDITION_CATEGORY_FORM';
export const HIDE_DEACTIVATE_ADDITION_CATEGORY_FORM = 'HIDE_DEACTIVATE_ADDITION_CATEGORY_FORM';
////deactivate
export const SHOW_ACTIVATE_ADDITION_CATEGORY_FORM = 'SHOW_ACTIVATE_ADDITION_CATEGORY_FORM';
export const HIDE_ACTIVATE_ADDITION_CATEGORY_FORM = 'HIDE_ACTIVATE_ADDITION_CATEGORY_FORM';
/////CREATE
export const addAdditionCategoryGenerator = (data)=>{
  return {
    type:ADD_ADDITION_CATEGORIES ,
    payload: data
  }
};
export const showAdditionCategoryCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_ADDITION_CATEGORIES_FORM,
    payload:data
  }
};
export const hideAdditionCategoryCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_ADDITION_CATEGORIES_FORM,
    payload:data
  }
};
export const addAdditionCategorySuccessGenerator =(data)=>{
  return {
    type:ADD_ADDITION_CATEGORIES_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchAdditionCategoryGenerator=(data=[])=>{
  return {
    type:FETCH_ADDITION_CATEGORIES,
    payload:data
  }
};

export const fetchAdditionCategorySuccessGenerator =(data)=>{
  return {
    type:FETCH_ADDITION_CATEGORIES_SUCCESS,
    payload:data
  }
};

//////FETCH CAR CC BANDS
export const fetchAdditionCarCCBandsGenerator=(data=[])=>{
  return {
    type:FETCH_ADDITION_CAR_CC_BAND_CATEGORIES,
    payload:data
  }
};

export const fetchAdditionCarCCBandsSuccessGenerator =(data)=>{
  return {
    type:FETCH_ADDITION_CAR_CC_BAND_CATEGORIES_SUCCESS,
    payload:data
  }
};


///EDIT
export const editAdditionCategoryGenerator = (data)=>{
  return {
    type: EDIT_ADDITION_CATEGORIES,
    payload: data
  }
};

export const editAdditionCategorySuccessGenerator=(data)=>{
  return {
    type:EDIT_ADDITION_CATEGORIES_SUCCESS,
    payload:data
  }
};
export const showAdditionCategoryEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_ADDITION_CATEGORIES_FORM,
    payload:data
  }
};
export const hideAdditionCategoryEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_ADDITION_CATEGORIES_FORM,
    payload:data
  }
};
// /////DELETE
export const deleteAdditionCategoryGenerator=(data)=>{
  return {
    type:DELETE_ADDITION_CATEGORIES,
    payload:data
  }
};

export const deleteAdditionCategorySuccessGenerator = (data)=>{
  return {
    type:DELETE_ADDITION_CATEGORIES_SUCCESS,
    payload:data
  }
};
export const showAdditionCategoryDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_ADDITION_CATEGORIES_MODAL,
    payload:data
  }
};
export const hideAdditionCategoryDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_ADDITION_CATEGORIES_MODAL,
    payload:data
  }
};
export const showDeactivateAdditionCategoryModalGenerator = (data) =>{
  return {
    type:SHOW_DEACTIVATE_ADDITION_CATEGORY_FORM,
    payload:data
  }
};
export const hideDeactivateAdditionCategoryModalGenerator = (data) =>{
  return {
    type:HIDE_DEACTIVATE_ADDITION_CATEGORY_FORM,
    payload:data
  }
};
export const showActivateAdditionCategoryModalGenerator = (data) =>{
  return {
    type:SHOW_ACTIVATE_ADDITION_CATEGORY_FORM,
    payload:data
  }
};
export const hideActivateAdditionCategoryModalGenerator = (data) =>{
  return {
    type:HIDE_ACTIVATE_ADDITION_CATEGORY_FORM,
    payload:data
  }
};
