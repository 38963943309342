/*actions*/
export const FETCH_EMPLOYEES="FETCH_EMPLOYEES";
export const FETCH_EMPLOYEES_ADMIN_SUCCESS="FETCH_EMPLOYEES_ADMIN_SUCCESS";
export const FETCH_EMPLOYEES_ADMIN="FETCH_EMPLOYEES_ADMIN";

export const FETCH_EMPLOYEES_SUCCESS="FETCH_EMPLOYEES_SUCCESS";

export const ADD_EMPLOYEE="ADD_EMPLOYEE";
export const ADD_EMPLOYEE_SUCCESS="ADD_EMPLOYEE_SUCCESS";

export const UPDATE_EMPLOYEE="UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS ="UPDATE_EMPLOYEE_SUCCESS";
export const SHOW_UPDATE_EMPLOYEE_FORM ="SHOW_EMPLOYEE_UPDATE_FORM";
export const HIDE_UPDATE_EMPLOYEE_FORM ="HIDE_EMPLOYEE_UPDATE_FORM";

export const DELETE_EMPLOYEE ="EDIT_COMPANY_DELETE";
export const DELETE_EMPLOYEE_SUCCESS ="DELETE_EMPLOYEE_SUCCESS";
export const SHOW_EMPLOYEE_DELETE_FORM ="SHOW_EMPLOYEE_DELETE_FORM";
export const HIDE_EMPLOYEE_DELETE_FORM ="HIDE_EMPLOYEE_DELETE_FORM";

export const HARD_DELETE_EMPLOYEE ="HARD_DELETE_EMPLOYEE";
export const HARD_DELETE_EMPLOYEE_SUCCESS ="HARD_DELETE_EMPLOYEE_SUCCESS";
export const SHOW_EMPLOYEE_HARD_DELETE_FORM ="SHOW_EMPLOYEE_HARD_DELETE_FORM";
export const HIDE_EMPLOYEE_HARD_DELETE_FORM ="HIDE_EMPLOYEE_HARD_DELETE_FORM";

export const SHOW_CREATE_EMPLOYEE_FORM ="SHOW_EMPLOYEE_CREATE_FORM";
export const HIDE_CREATE_EMPLOYEE_FORM ="HIDE_EMPLOYEE_CREATE_FORM";

//employee transfer
export const SHOW_TRANSFER_EMPLOYEE_FORM = "SHOW_TRANSFER_EMPLOYEE_FORM";
export const HIDE_TRANSFER_EMPLOYEE_FORM = "HIDE_TRANSFER_EMPLOYEE_FORM";
export const TRANSFER_EMPLOYEE = "TRANSFER_EMPLOYEE";
export const TRANSFER_EMPLOYEE_SUCCESS = "TRANSFER_EMPLOYEE_SUCCESS";

export const SHOW_BULK_EMPLOYEES_ADD_FORM = "SHOW_BULK_EMPLOYEES_ADD_FORM";
export const HIDE_BULK_EMPLOYEES_ADD_FORM = "HIDE_BULK_EMPLOYEES_ADD_FORM";
export const ADD_BULK_EMPLOYEES = "ADD_BULK_EMPLOYEES";
export const ADD_BULK_EMPLOYEES_SUCCESS = "ADD_BULK_EMPLOYEES_SUCCESS";
/*Generators*/

///// invite employees
export const SHOW_INVITE_EMPLOYEES_FORM = 'SHOW_INVITE_EMPLOYEES_FORM';
export const HIDE_INVITE_EMPLOYEES_FORM = 'HIDE_INVITE_EMPLOYEES_FORM';
export const INVITE_EMPLOYEES = 'INVITE_EMPLOYEES';
export const INVITE_EMPLOYEES_SUCCESS = 'INVITE_EMPLOYEES_SUCCESS';

///send credentials
export const SHOW_SEND_EMPLOYEE_CREDENTIALS = 'SHOW_SEND_EMPLOYEE_CREDENTIALS';
export const HIDE_SEND_EMPLOYEE_CREDENTIALS = 'HIDE_SEND_EMPLOYEE_CREDENTIALS';
export const SEND_CREDENTIALS ='SEND_CREDENTIALS';
export const SEND_CREDENTIALS_SUCCESS ='SEND_CREDENTIALS_SUCCESS';

///reset password
export const SHOW_RESET_EMPLOYEE_PASSWORD_FORM = 'SHOW_RESET_EMPLOYEE_PASSWORD_FORM';
export const HIDE_RESET_EMPLOYEE_PASSWORD_FORM = 'HIDE_RESET_EMPLOYEE_PASSWORD_FORM';
export const REST_EMPLOYEE_PASSWORD='REST_EMPLOYEE_PASSWORD';
export const REST_EMPLOYEE_PASSWORD_SUCCESS='REST_EMPLOYEE_PASSWORD_SUCCESS';

export const SET_EMPLOYEE_STATUS='SET_EMPLOYEE_STATUS';
export const SET_EMPLOYEE_STATUS_SUCCESS='SET_EMPLOYEE_STATUS_SUCCESS';

export const UPLOAD_EMPLOYEE_NO_EDIT = 'UPLOAD_EMPLOYEE_NO_EDIT';
export const UPLOAD_EMPLOYEE_EDIT = 'UPLOAD_EMPLOYEE_EDIT';

export const ACTIVATE_BULK_EMPLOYEES ="ACTIVATE_BULK_EMPLOYEES";
export const BULK_EDIT_EMPLOYEES ="BULK_EDIT_EMPLOYEES";

export const FETCH_DYNAMIC_OFF_DAYS = "FETCH_DYNAMIC_OFF_DAYS";
export const FETCH_DYNAMIC_OFF_DAYS_SUCCESS = "FETCH_DYNAMIC_OFF_DAYS_SUCCESS";

export const ADD_DYNAMIC_OFF_DAYS = "ADD_DYNAMIC_OFF_DAYS";

export const DELETE_DYNAMIC_OFF_DAYS = "DELETE_DYNAMIC_OFF_DAYS";

export const BULK_DELETE_EMPLOYEES = "BULK_DELETE_EMPLOYEES";

export const UPDATE_EXCEL_BULK_EMPLOYEES = "UPDATE_EXCEL_BULK_EMPLOYEES";

export const RATE_EMPLOYEE="RATE_EMPLOYEE";

export const SET_EMPLOYEE_ON_PROBATION = "SET_EMPLOYEE_ON_PROBATION";
export const CONFIRM_EMPLOYEE_FROM_PROBATION = "CONFIRM_EMPLOYEE_FROM_PROBATION";

export const EDIT_EMPLOYEE_SHARES = "EDIT_EMPLOYEE_SHARES";
export const EDIT_EMPLOYEE_SHARES_SUCCESS = "EDIT_EMPLOYEE_SHARES_SUCCESS";
export const FETCH_PENDING_EMPLOYEES = "FETCH_PENDING_EMPLOYEES";
export const FETCH_PENDING_EMPLOYEES_SUCCESS = "FETCH_PENDING_EMPLOYEES_SUCCESS";
export const fetchPendingEmployeesGenerator = (payload={}) =>{
  return{
    type:FETCH_PENDING_EMPLOYEES,
    payload
  }
};
export const fetchPendingEmployeesSuccessGenerator = payload =>{
  return {
    type:FETCH_PENDING_EMPLOYEES_SUCCESS,
    payload
  }
};

export const FETCH_EMPLOYEE_SHARES = "FETCH_EMPLOYEE_SHARES";
export const FETCH_EMPLOYEE_SHARES_SUCCESS = "FETCH_EMPLOYEE_SHARES_SUCCESS";

export const ADD_EMPLOYEE_SHARES = "ADD_EMPLOYEE_SHARES";
export const ADD_EMPLOYEE_SHARES_SUCCESS = "ADD_EMPLOYEE_SHARES_SUCCESS";

export const addEmployeeSharesGenerator = payload =>{
  return{
    type:ADD_EMPLOYEE_SHARES,
    payload
  }
};
export const addEmployeeSharesSuccessGenerator = payload =>{
  return{
    type:ADD_EMPLOYEE_SHARES_SUCCESS,
    payload
  }
};
export const setEmployeeOnProbationGenerator = payload =>{
  return{
    type:SET_EMPLOYEE_ON_PROBATION,
    payload
  }
};
export const confirmEmployeeFromProbationGenerator = payload =>{
  return {
    type:CONFIRM_EMPLOYEE_FROM_PROBATION,
    payload
  }
};
export const updateExcelBulkEmployeesGenerator = payload =>{
  return {
    type:UPDATE_EXCEL_BULK_EMPLOYEES,
    payload
  }
}

export const BulkDeleteEmployeesGenerator = payload =>{
  return {
    type:BULK_DELETE_EMPLOYEES,
    payload
  }
};

export const bulkEditEmployeesGenerator =(data)=>{
  return {
    type:BULK_EDIT_EMPLOYEES,
    payload:data
  }
};
export const activateBulkEmployeesGenerator =(data)=>{
  return {
    type:ACTIVATE_BULK_EMPLOYEES,
    payload:data
  }
};

export const uploadEmployeeNoEditGenerator = (data)=>{
  return {
    type:UPLOAD_EMPLOYEE_NO_EDIT,
    payload:data
  }
};
export const uploadEmployeeEditGenerator = (data)=>{
  return {
    type:UPLOAD_EMPLOYEE_EDIT,
    payload:data
  }
};
export const deleteEmployeeGenerator =(data)=>{
  return {
    type:DELETE_EMPLOYEE,
    payload:data
  }
};
export const hardDeleteEmployeeGenerator =(data)=>{
  return {
    type:HARD_DELETE_EMPLOYEE,
    payload:data
  }
};
export const hardDeleteEmployeeSuccessGenerator =(data)=>{
  return {
    type:HARD_DELETE_EMPLOYEE_SUCCESS,
    payload:data
  }
};
export const setEmployeeStatusGenerator =(data)=>{
  return{
    type:SET_EMPLOYEE_STATUS,
    payload:data
  }
};
export const setEmployeeStatusSuccessGenerator =(data)=>{
  return{
    type:SET_EMPLOYEE_STATUS_SUCCESS,
    payload:data
  }
};
export const deleteEmployeeSuccessGenerator =(data)=>{
  return{
    type:DELETE_EMPLOYEE_SUCCESS,
    payload:data
  }
};
export const showEmployeeDeleteFormGenerator =(data)=>{
  return {
    type:SHOW_EMPLOYEE_DELETE_FORM,
    payload:data
  }
};
export const hideEmployeeDeleteFormGenerator =(data)=>{
  return{
    type:HIDE_EMPLOYEE_DELETE_FORM,
    payload:data
  }
};
/////////////////////////////

export const updateEmployeeGenerator =(data)=>{
  return {
    type:UPDATE_EMPLOYEE,
    payload:data
  }
};
export const updateEmployeeSuccessGenerator =(data)=>{
  return{
    type:UPDATE_EMPLOYEE_SUCCESS,
    payload:data
  }
};
export const showUpdateEmployeeFormGenerator =(data)=>{
  return {
    type:SHOW_UPDATE_EMPLOYEE_FORM,
    payload:data
  }
};
export const hideUpdateEmployeeFormGenerator =(data)=>{
  return{
    type:HIDE_UPDATE_EMPLOYEE_FORM,
    payload:data
  }
};
export  const showCreateEmployeeFormGenerator =(data)=>{
  return {
    type :SHOW_CREATE_EMPLOYEE_FORM,
    payload:data
  }
};
export  const hideCreateEmployeeFormGenerator =(data)=>{
  return {
    type :HIDE_CREATE_EMPLOYEE_FORM,
    payload:data
  }
};
export const fetchEmployeesGenerator =(data={})=>{
  return{
    type :FETCH_EMPLOYEES,
    payload:data
  }

};

export const fetchEmployeesAdminGenerator =(data={})=>{
  return{
    type :FETCH_EMPLOYEES_ADMIN,
    payload:data
  }

};

export const fetchEmployeesSuccessGenerator =(data=[])=>{
  return{
    type :FETCH_EMPLOYEES_SUCCESS,
    payload:data
  }

};

export const fetchEmployeesAdminSuccessGenerator =(data=[])=>{
  return{
    type :FETCH_EMPLOYEES_ADMIN_SUCCESS,
    payload:data
  }

};

export const addEmployeeGenerator=(data)=>{
  return{
    type:ADD_EMPLOYEE,
    payload:data
  }
};
export const addEmployeeSuccessGenerator =(data)=>{
  return{
    type:ADD_EMPLOYEE_SUCCESS,
    payload:data
  }
};
export const transferEmployeeGenerator = (data)=>{
  return {
    type:TRANSFER_EMPLOYEE,
    payload:data
  }
};
export const transferEmployeeSuccessGenerator = (data)=>{
  return {
    type:TRANSFER_EMPLOYEE_SUCCESS,
    payload:data
  }
};
export const showTransferEmployeeFormGenerator = (data)=>{
  return {
    type:SHOW_TRANSFER_EMPLOYEE_FORM,
    payload:data
  }
};
export const hideTransferEmployeeFormGenerator = (data)=>{
  return {
    type:HIDE_TRANSFER_EMPLOYEE_FORM,
    payload:data
  }
};
export const showBulkEmployeesAddFormGenerator = (data)=>{
  return {
    type:SHOW_BULK_EMPLOYEES_ADD_FORM,
    payload:data
  }
};
export const hideBulkEmployeesAddFormGenerator = (data)=>{
  return {
    type:HIDE_BULK_EMPLOYEES_ADD_FORM,
    payload:data
  }
};
export const addBulkEmployeesGenerator = (data)=>{
  return {
    type:ADD_BULK_EMPLOYEES,
    payload:data
  }
};
export const addBulkEmployeesSuccessGenerator = (data)=>{
  return {
    type:ADD_BULK_EMPLOYEES_SUCCESS,
    payload:data
  }
};
////
export const showIniviteEmployeeFormGenerator =(data)=>{
  return {
    type:SHOW_INVITE_EMPLOYEES_FORM,
    payload:data
  }
};
export const hideIniviteEmployeeFormGenerator =(data)=>{
  return {
    type:HIDE_INVITE_EMPLOYEES_FORM,
    payload:data
  }
};
export const showSendEmployeeCredentialGenerator =(data)=>{
  return {
    type:SHOW_SEND_EMPLOYEE_CREDENTIALS,
    payload:data
  }
};
export const hideSendEmployeeCredentialGenerator =(data)=>{
  return {
    type:HIDE_SEND_EMPLOYEE_CREDENTIALS,
    payload:data
  }
};
export const showResetEmployeeFormGenerator =(data)=>{
  return {
    type:SHOW_RESET_EMPLOYEE_PASSWORD_FORM,
    payload:data
  }
};
export const hideResetEmployeeFormGenerator =(data)=>{
  return {
    type:HIDE_RESET_EMPLOYEE_PASSWORD_FORM,
    payload:data
  }
};
export const inviteEmployeesGenerator = (data)=>{
  return{
    type: INVITE_EMPLOYEES,
    payload:data
  }
};
export const inviteEmployeesSuccessGenerator = (data)=>{
  return{
    type: INVITE_EMPLOYEES_SUCCESS,
    payload:data
  }
};
export const sendEmployeeCredentialGenerator = (data)=>{
  return{
    type: SEND_CREDENTIALS,
    payload:data
  }
};
export const sendEmployeeCredentialSuccessGenerator = (data)=>{
  return{
    type: SEND_CREDENTIALS_SUCCESS,
    payload:data
  }
};
export const resetEmployeePasswordGenerator = (data)=>{
  return{
    type: REST_EMPLOYEE_PASSWORD,
    payload:data
  }
};
export const resetEmployeePasswordSuccessGenerator = (data)=>{
  return{
    type: REST_EMPLOYEE_PASSWORD_SUCCESS,
    payload:data
  }
};
export const fetchDynamicOffDaysGenerator =(data=[])=>{
  return{
    type :FETCH_DYNAMIC_OFF_DAYS,
    payload:data
  }

};
export const fetchDynamicOffDaysSuccessGenerator =(data=[])=>{
  return{
    type :FETCH_DYNAMIC_OFF_DAYS_SUCCESS,
    payload:data
  }

};

export const addDynamicOffDaysGenerator =(data=[])=>{
  return{
    type :ADD_DYNAMIC_OFF_DAYS,
    payload:data
  }

};

export const deleteDynamicOffDaysGenerator =(data=[])=>{
  return{
    type :DELETE_DYNAMIC_OFF_DAYS,
    payload:data
  }

};

export const rateEmployeeGenerator =(data={})=>{
  return{
    type :RATE_EMPLOYEE,
    payload:data
  }

};

export const editEmployeeSharesGenerator =(data={})=>{
  return{
    type :EDIT_EMPLOYEE_SHARES,
    payload:data
  }
};
export const editEmployeeSharesSuccessGenerator =(data={})=>{
  return{
    type :EDIT_EMPLOYEE_SHARES_SUCCESS,
    payload:data
  }
};
export const fetchEmployeeSharesGenerator =(data={})=>{
  return{
    type :FETCH_EMPLOYEE_SHARES,
    payload:data
  }
};
export const fetchEmployeeSharesSuccessGenerator =(data={})=>{
  return{
    type :FETCH_EMPLOYEE_SHARES_SUCCESS,
    payload:data
  }
};
