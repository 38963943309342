import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import {Helpers} from '../../util/Helpers';
import {connect} from 'react-redux';
import {fetchSubscriptionGenerator} from '../../appRedux/actions/common/SubscriptionActions';
import {userSignOutSuccessGenerator} from '../../appRedux/actions/AuthActions';
import {SUBSCRIPTION_KEY} from '../../constants/SessionKeys';
import SubscriptionPlansDialog from "../home/subscriptionPlansDialog";

const styles = theme => ({
  card: {
    margin: 10,
    padding: 20
  }
});


class DaysLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  handleClickOpen = (value) => {
    this.setState({
      modalOpen: true,
      action:value
    });
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  }

  render() {
    const subscription = Helpers.activeSubscription(true);

    const is_trial  = subscription ? subscription.is_trial : 1;
    const daysLeft = subscription ? subscription.days_remaining : 0;
    const subscriptionStatus = subscription ? subscription.status : '';
    const { classes } = this.props;
    return (
      <div>
        <ul align="center" style={{'listStyleType': 'none' }}>
          <li>
            <h6>You have <span className="text-secondary">{daysLeft} days</span> left</h6>
          </li>
          {
            subscriptionStatus == 'UNPAID' || is_trial === 1 ?
              <li>
                <Button size="small" onClick={ ()=> { this.handleClickOpen('select_plan')} } variant="outlined" style={{ marginTop: -10, borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:0, paddingBottom:0 }}>Pick a Plan</Button>
              </li> :
              <li>
                <Button size="small" onClick={ ()=> { this.handleClickOpen('upgrade')} } variant="outlined" style={{ marginTop: -10, borderRadius: 20, paddingLeft:10, paddingRight:10, paddingTop:0, paddingBottom:0 }}>Upgrade</Button>
              </li>
          }
        </ul>
        <SubscriptionPlansDialog
          handleClose={this.handleClose}
          modalOpen={this.state.modalOpen}
          action={this.state.action}
        />

      </div>
    );
  }
}

DaysLeft.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({subscription}) => {
  const {
    subscriptionByID, noOfEmployees, planType, packageCosts, planId, amount, is_trial
  } = subscription;
  return {
    subscriptionByID, noOfEmployees, planType, packageCosts, planId, amount, is_trial
  };
};

export default withStyles(styles)(connect(mapStateToProps, {fetchSubscriptionGenerator})(DaysLeft));
