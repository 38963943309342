import {
  ADD_SMS_SUCCESS,
  FETCH_SMS_RECIPIENTS_SUCCESS,
  FETCH_SMS_SUCCESS,
  HIDE_SMS_CREATE_FORM,
  HIDE_SMS_RECIPIENTS,
  SHOW_SMS_CREATE_FORM,
  SHOW_SMS_RECIPIENTS
} from '../../actions/payroll/SmsActions';

const INIT_STATE = {
  smsByID:{},
  showSmsCreateForm:false,
  showSmsDeleteForm:false,
  showSmsRecipients:false,

};

export default (state=INIT_STATE,action)=>{
  switch (action.type){
    case FETCH_SMS_SUCCESS:
      return {
        ...state,
        smsByID:action.payload
      };
    case SHOW_SMS_CREATE_FORM:
      return {
        ...state,
        showSmsCreateForm:true
      };
    case HIDE_SMS_CREATE_FORM:
      return {
        ...state,
        showSmsCreateForm:false
      };
    case FETCH_SMS_RECIPIENTS_SUCCESS:
    case ADD_SMS_SUCCESS:
      let sms_id = action.payload.id;
      return {
        ...state,
        smsByID: {
          ...state.smsByID,
          [sms_id]:action.payload
        }
      };
    case SHOW_SMS_RECIPIENTS:
      return {
        ...state,
        showSmsRecipients:true
      };
    case HIDE_SMS_RECIPIENTS:
      return {
        ...state,
        showSmsRecipients:false
      };
      default:
        return state;
  }
}
