import {
  FETCH_LOAN_LENDER_TRANSACTIONS_SUCCESS,
  FETCH_LOAN_LENDERS_SUCCESS
} from "../../actions/admin/LoanLenderActions";


const INIT_STATE = {
   lenders:{},
   transactions:{}
};

export default (status=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_LOAN_LENDERS_SUCCESS:
      return{
        ...status,
        lenders:action.payload
      };
    case FETCH_LOAN_LENDER_TRANSACTIONS_SUCCESS:
      return{
        ...status,
        transactions:action.payload
      };
    default:
      return status;
  }
}
