import React, { useState } from "react";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { grey, orange } from "@material-ui/core/colors";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  root: {
      width: '100%',
      marginBottom: '20px'
  },
  avatar: {
    backgroundColor: orange[500],
  },
});

const PlanCard = (props) => {

  const {data, classes, handleSelectPlan, isSelected, handleSelectPlanType, selectedPlanType,checkedPlan} = props;
  const { title,id } = data;
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}> { title.charAt(0) } </Avatar>
        }
        title={title}
        action={
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            style={{
              transform: "scale(2)"
            }}
            checked={isSelected}
            onChange={handleSelectPlan}
            value={id}
          />
        }
      />

      <CardActionArea style={isSelected ? {background:'#F2F2F2'} : {}}>
        <CardContent>
            <FormControl component="fieldset">
              <RadioGroup aria-label="plan_type" name="plan_type" value={isSelected ? selectedPlanType : ''} onChange={ isSelected ? handleSelectPlanType : ()=>{}}>
                <FormControlLabel value="monthly" control={<Radio style={{color: 'black'}} />} label="Monthly" />
                <FormControlLabel value="yearly" control={<Radio style={{color: 'black'}} />} label="Yearly" />
              </RadioGroup>
            </FormControl>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Button size="small" color="primary" onClick={()=>{ window.open("https://workpay.co.ke/pricing", "_blank")}}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(connect(null, {})(PlanCard));
