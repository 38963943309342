import {
  ADD_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_SUCCESS,
  EDIT_HOLIDAY_SUCCESS,
  FETCH_HOLIDAY_SUCCESS,
  HIDE_CREATE_HOLIDAY_FORM,
  HIDE_DELETE_HOLIDAY_MODAL,
  HIDE_EDIT_HOLIDAY_FORM,
  SHOW_CREATE_HOLIDAY_FORM,
  SHOW_DELETE_HOLIDAY_MODAL,
  SHOW_EDIT_HOLIDAY_FORM
} from '../../actions/payroll/HolidayAction';

const INIT_STATE = {
  holidaysByID:[],
  showHolidayCreateForm:false,
  holidayToEdit:[],
  showHolidayEditForm:false,
  showDeleteHolidayModal:false
};
export default (state = INIT_STATE, action)=>{
  let holidays;
  switch (action.type) {
    case ADD_HOLIDAY_SUCCESS:
    case EDIT_HOLIDAY_SUCCESS:
      holidays = {...state.holidaysByID};
      holidays[action.payload.id]=action.payload;
      return {
        ...state,
        holidaysByID:holidays
      };
    case FETCH_HOLIDAY_SUCCESS:
      return {
        ...state,
        holidaysByID:action.payload
      };
    case SHOW_CREATE_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayCreateForm:true
      };
      case HIDE_CREATE_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayCreateForm:false
      };
      case SHOW_EDIT_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayEditForm:true,
        holidayToEdit:action.payload
      };
      case HIDE_EDIT_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayEditForm:false
      };
    case SHOW_DELETE_HOLIDAY_MODAL:
      return {
        ...state,
        showDeleteHolidayModal:true,
        holidayToEdit:action.payload
      };
      case HIDE_DELETE_HOLIDAY_MODAL:
      return {
        ...state,
        showDeleteHolidayModal:false
      };
    case DELETE_HOLIDAY_SUCCESS:
      holidays = {...state.holidaysByID};
      delete holidays[action.payload.id];
      return {
        ...state,
        holidaysByID:holidays
      };
    default:
      return state;
  }
}
