import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_PAYROLL,
  DELETE_PAYROLL,
  DELETE_QUEUED_PAYROLL,
  deletePayrollSuccessGenerator,
  deleteQueuedPayrollSuccessGenerator, DOWNLOAD_EMPLOYEE_P9,
  EDIT_PAYROLL,
  editPayrollSuccessGenerator,
  FETCH_PAYROLLS,
  FETCH_QUEUED_PAYROLLS, fetchPayrollsGenerator,
  fetchPayrollsSuccessGenerator, fetchQueuedPayrollsGenerator,
  fetchQueuedPayrollsSuccessGenerator, MARK_PAYROLL_AS_PAID,
  PAY_PAYROLL, SEND_EMPLOYEE_P9,
  SEND_PAYSLIPS,
  sendPayslipsSuccessGenerator
} from "../../actions/payroll/PayrollActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addPayrollSuccessGenerator
} from "../../actions/payroll/PayrollActions";
import { API_ENDPOINT} from "../../../constants/ActionTypes";
import { push } from 'connected-react-router';
import {fetchRecipientsGenerator } from "../../actions/payouts/BulkPayoutActions";
import { fetchMusterRollGenerator } from "../../actions/payroll/PayrollReportAction";

const downloadEmployeeP9Request = async (requestData)=>
    await axios.get(API_ENDPOINT+'/home/statutory/export/'+requestData.url_,{params:requestData,responseType: 'blob'})
        .then(response=>{
          if(response.data.size < 100){
            response.data.message="No Payroll found";
            response.data.success = false;
            return response;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          let filename = requestData.year+requestData.employee_id+'employee_p9';
          link.setAttribute('download', filename+'.pdf');
          document.body.appendChild(link);
          link.click();
          response.data.message="P9 form Generated successfully";
          response.data.success = true;
          return response;
        })
        .catch(error=>error.response);
function* downloadEmployeeP9({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    requestParameters.url_ ='PAYE/'+requestParameters.employee_id+"/"+requestParameters.year;
    const response = yield call(downloadEmployeeP9Request, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        /*window.open(API_ADDRESS+response.data.data,'_blank');*/
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

const fetchPayrollsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/payroll-batches',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const sendP9FormsRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/send/p9/forms',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const addPayrollRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/payroll-batches',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const payPayrollRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/payroll/payout',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePayrollRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/payroll-batches/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPayrollRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/payroll-batches/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchQueuedPayrollsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/queued-payrolls',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const sendPayslipsRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/send/payslips',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const deleteQueuedPayrollRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/queued-payroll/delete/'+requestParameters['id'],{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const markPayrollAsPaidRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/payroll/mark/paid',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* sendP9Forms({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendP9FormsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchPayrolls({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPayrollsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let payrollsById = response.data.data;
        yield put(fetchPayrollsSuccessGenerator(payrollsById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPayroll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    const response = yield call(addPayrollRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let payroll = response.data.data;
        yield put(fetchPayrollsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchQueuedPayrollsGenerator());
        yield put(push('/hrm/payroll-batches'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* payPayroll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(payPayrollRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
       // yield put(fetchRecipientsSuccessGenerator(response.data.data));
        yield put(fetchRecipientsGenerator());
        yield put(push('/payouts/bulk-payouts'));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePayroll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePayrollRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let payroll = response.data.data;
        yield put(fetchPayrollsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPayroll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editPayrollRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let payroll = response.data.data;
        yield put(fetchPayrollsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchQueuedPayrolls({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchQueuedPayrollsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let payrollsById = response.data.data;
        yield put(fetchQueuedPayrollsSuccessGenerator(payrollsById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* sendPayslips({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendPayslipsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(sendPayslipsSuccessGenerator(data));
      }
      yield put(showMessageGenerator(response.data.message,'success'));
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteQueuedPayroll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteQueuedPayrollRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let payroll = response.data.data;
        yield put(fetchQueuedPayrollsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* markPayrollAsPaid({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(markPayrollAsPaidRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchMusterRollGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPayrollsDefault() {
  yield takeLatest(FETCH_PAYROLLS, fetchPayrolls)
}
export function* sendPayslipsDefault() {
  yield takeLatest(SEND_PAYSLIPS, sendPayslips)
}
export function* addPayrollDefault() {
  yield takeLatest(ADD_PAYROLL, addPayroll);
}
export function* payPayrollDefault() {
  yield takeLatest(PAY_PAYROLL, payPayroll);
}
export function* deletePayrollDefault() {
  yield takeLatest(DELETE_PAYROLL, deletePayroll);
}
export function* editPayrollDefault() {
  yield takeLatest(EDIT_PAYROLL, editPayroll);
}
export function* fetchQueuedPayrollsDefault() {
  yield takeLatest(FETCH_QUEUED_PAYROLLS, fetchQueuedPayrolls)
}
export function* deleteQueuedPayrollDefault() {
  yield takeLatest(DELETE_QUEUED_PAYROLL, deleteQueuedPayroll);
}export function* downloadEmployeeP9Default() {
  yield takeLatest(DOWNLOAD_EMPLOYEE_P9, downloadEmployeeP9);
}
export function* sendEmployeeP9Default() {
  yield takeLatest(SEND_EMPLOYEE_P9, sendP9Forms);
}
export function* markPayrollAsPaidDefault() {
  yield takeLatest(MARK_PAYROLL_AS_PAID, markPayrollAsPaid);
}
export default function* rootSaga() {
  yield all([fork(fetchPayrollsDefault),
    fork(addPayrollDefault),
    fork(deletePayrollDefault),
    fork(editPayrollDefault),
    fork(fetchQueuedPayrollsDefault),
    fork(deleteQueuedPayrollDefault),
    fork(payPayrollDefault),
    fork(sendPayslipsDefault) ,
    fork(downloadEmployeeP9Default),
    fork(sendEmployeeP9Default),
    fork(markPayrollAsPaidDefault),
  ]);
}
