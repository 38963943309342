import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import {Button} from '@material-ui/core';


class EmployeePortal extends Component {
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    return (
      <ul className="nav-menu">
        <li className="nav-header">
          <IntlMessages id="sidebar.portal" />
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/profile">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="popup.profile" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/attendance-attempts">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="popup.attendance" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/documents">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.documents" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/payslip">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.Payslips" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu collapse-box">
          <Button>
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.leaves" />
            </span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon" to="/portal/leaves">
                <span className="nav-text"><IntlMessages id="sidebar.leavesListing" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/portal/off-days">
                <span className="nav-text"><IntlMessages id="sidebar.dynamicOffDays" /></span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/assets">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollExtras.assets" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/expenses">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollExtras.pettyCash" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/salary-advance">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollExtras.salaryAdvance" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/loans">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollData.loans" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/portal/savings">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollExtras.savings" />
              {' '}
            </span>
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default withRouter(EmployeePortal);
