export const FETCH_SUBSCRIPTION_PRICINGS = "FETCH_SUBSCRIPTION_PRICINGS";
export const FETCH_SUBSCRIPTION_PRICINGS_SUCCESS = "FETCH_SUBSCRIPTION_PRICINGS_SUCCESS";
export const UPDATE_SUBSCRIPTION_PRICING = "UPDATE_SUBSCRIPTION_PRICING";
export const UPDATE_SUBSCRIPTION_PRICING_SUCCESS = "UPDATE_SUBSCRIPTION_PRICING_SUCCESS";

export const fetchSubscriptionPricingGenerator = (data=[])=>{
    return {
        type:FETCH_SUBSCRIPTION_PRICINGS,
        payload:data
    };
};

export const fetchSubscriptionPricingSuccessGenerator = (data)=> {
    return {
        type:FETCH_SUBSCRIPTION_PRICINGS_SUCCESS,
        payload:data
    }
};

export const updateSubscriptionPricingGenerator = (data)=>{
    return {
        type:UPDATE_SUBSCRIPTION_PRICING,
        payload:data
    }
};

export const updateSubscriptionPricingSuccessGenerator = (data)=>{
    return {
        type:UPDATE_SUBSCRIPTION_PRICING_SUCCESS,
        payload:data
    }
};
