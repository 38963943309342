export const FETCH_PENSION_RATES= "FETCH_PENSION_RATES";
export const FETCH_PENSION_RATES_SUCCESS = "FETCH_PENSION_RATES_SUCCESS";

export const SHOW_PENSION_RATE_EDIT_FORM = 'SHOW_PENSION_RATE_EDIT_FORM';
export const HIDE_PENSION_RATE_EDIT_FORM = 'HIDE_PENSION_RATE_EDIT_FORM';
export const EDIT_PENSION_RATE = 'EDIT_PENSION_RATE';
export const EDIT_PENSION_RATE_SUCCESS = 'EDIT_PENSION_RATE_SUCCESS';

export const fetchPensionRatesGenerator = (data=[])=>{
  return {
    type:FETCH_PENSION_RATES,
    payload:data
  };
};

export const fetchPensionRatesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PENSION_RATES_SUCCESS,
    payload:dataById
  }
};
export const showPensionRateEditFormGenerator = (data)=>{
  return {
    type:SHOW_PENSION_RATE_EDIT_FORM,
    payload:data
  };
};
export const hidePensionRateEditFormGenerator = ()=>{
  return {
    type:HIDE_PENSION_RATE_EDIT_FORM
  };
};
export const editPensionRateGenerator = (data)=>{
  return {
    type: EDIT_PENSION_RATE,
    payload: data
  }
};
export const editPensionRateSuccessGenerator = (data)=>{
  return {
    type: EDIT_PENSION_RATE_SUCCESS,
    payload: data
  }
};

