import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import axios from 'axios';
import { Helpers } from "../../../util/Helpers";
import {
  ADD_WORKED_DAYS,
  addWorkedDaysSuccessGenerator, BULK_DATED_WORK_DAYS, BULK_UPLOAD_WORKED_DAYS,
  DELETE_WORKED_DAYS,
  deleteWorkedDaySuccessGenerator, DOWNLOAD_EMPLOYEES_WITHOUT_DAYS, DOWNLOAD_WORKED_DAYS,
  EDIT_WORKED_DAYS,
  editWorkedDaySuccessGenerator,
  FETCH_WORKED_DAYS, fetchWorkedDaysGenerator,
  fetchWorkedDaySuccessGenerator
} from "../../actions/attendance/WorkedDaysAction";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import { call, put,all,fork,takeLatest } from "redux-saga/effects";

const downloadWorkedDaysRequest = async (requestParameters)=>
  await axios.get(API_ADDRESS+'/download/employees/projects/'+requestParameters.id,{params:requestParameters}).then(response=>response).catch(error=>error.response);

const fetchWorkedDaysRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/worked-days',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const addWorkedDaysRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/worked-days',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const deleteWorkedDaysRequest =async (requestParameters)=>
  await axios.delete(API_ENDPOINT+'/attendance/worked-days/'+requestParameters.id,{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const editWorkedDaysRequest = async (requestParameters)=>
  await axios.put(API_ENDPOINT+'/attendance/worked-days/'+requestParameters.id,requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const bulkUploadWorkedDaysRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/upload/employees/projects',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);

const downloadEmployeesWithoutDaysRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/attendance/down/employees/without/days',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const uploadDatedWorkedDaysRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/attendance/upload/dated/working/days',requestData,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response=>response)
        .catch(error=>error.response);

function* uploadDatedWorkedDays({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadDatedWorkedDaysRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchWorkedDaysGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


function* downloadEmployeesWithoutDays({type,payload}) {
     yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(downloadEmployeesWithoutDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        window.open(API_ADDRESS+response.data.data,'_blank');
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
   yield put(hideAppLoaderGenerator(type));
}
function* bulkUploadWorkedDays({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(bulkUploadWorkedDaysRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchWorkedDaysGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editWorkedDays ({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editWorkedDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(editWorkedDaySuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteWorkedDays({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteWorkedDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchWorkedDaysGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addWorkedDays({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addWorkedDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchWorkedDaysGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchWorkedDays({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchWorkedDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchWorkedDaySuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* downloadWorkedDays({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
   const response = yield call(downloadWorkedDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        // yield put(fetchWorkedDaySuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchWorkedDaysDefault(){
  yield takeLatest(FETCH_WORKED_DAYS,fetchWorkedDays);
}
function* addWorkedDaysDefault(){
  yield takeLatest(ADD_WORKED_DAYS,addWorkedDays);
}
function* deleteWorkedDaysDefault () {
yield takeLatest(DELETE_WORKED_DAYS,deleteWorkedDays);
}
function* editWorkedDaysDefault (){
  yield takeLatest(EDIT_WORKED_DAYS,editWorkedDays);
}
function* bulkUploadWorkedDaysDefault() {
  yield takeLatest(BULK_UPLOAD_WORKED_DAYS,bulkUploadWorkedDays);
}
function* downloadWorkedDaysDefault(){
  yield takeLatest(DOWNLOAD_WORKED_DAYS,downloadWorkedDays);
}
function* downloadEmployeesWithoutDaysDefault() {
  yield takeLatest(DOWNLOAD_EMPLOYEES_WITHOUT_DAYS,downloadEmployeesWithoutDays)
}
function* uploadDatedWorkedDaysDefault() {
  yield takeLatest(BULK_DATED_WORK_DAYS,uploadDatedWorkedDays)
}
export default function* rootSaga() {
  yield all([
    fork(fetchWorkedDaysDefault),
    fork(addWorkedDaysDefault),
    fork(deleteWorkedDaysDefault),
    fork(editWorkedDaysDefault),
    fork(bulkUploadWorkedDaysDefault),
    fork(downloadWorkedDaysDefault),
    fork(downloadEmployeesWithoutDaysDefault),
      fork(uploadDatedWorkedDaysDefault)
  ]);
}
