export const FETCH_LEAVES_DASHBOARD="FETCH_LEAVES_DASHBOARD";
export const FETCH_LEAVES_DASHBOARD_SUCCESS="FETCH_LEAVES_DASHBOARD_SUCCESS";

export const fetchLeaveDashboardGenerator =(data=[])=>{
    return{
        type:FETCH_LEAVES_DASHBOARD,
        payload:data
    }
};
export const fetchLeaveDashboardSuccessGenerator =(data=[])=>{
    return{
        type:FETCH_LEAVES_DASHBOARD_SUCCESS,
        payload:data
    }
};