export const FETCH_LOAN_LENDERS = "FETCH_LOAN_LENDERS";
export const FETCH_LOAN_LENDERS_SUCCESS = "FETCH_LOAN_LENDERS_SUCCESS";

export const ADD_LOAN_LENDERS = "ADD_LOAN_LENDERS";
export const ADD_LOAN_LENDERS_SUCCESS = "ADD_LOAN_LENDERS_SUCCESS";

export const UPDATE_LOAN_LENDERS = "UPDATE_LOAN_LENDERS";
export const UPDATE_LOAN_LENDERS_SUCCESS = "UPDATE_LOAN_LENDERS_SUCCESS";

export const DELETE_LOAN_LENDERS = "DELETE_LOAN_LENDERS";
export const DELETE_LOAN_LENDERS_SUCCESS = "DELETE_LOAN_LENDERS_SUCCESS";

export const FETCH_LOAN_LENDER_TRANSACTIONS = "FETCH_LOAN_LENDER_TRANSACTIONS";
export const FETCH_LOAN_LENDER_TRANSACTIONS_SUCCESS = "FETCH_LOAN_LENDER_TRANSACTIONS_SUCCESS";

export const TOP_UP_LOAN_LENDER_WALLET = "TOP_UP_LOAN_LENDER_WALLET";
export const TOP_UP_LOAN_LENDER_WALLET_SUCCESS = "TOP_UP_LOAN_LENDER_WALLET_SUCCESS";

export const fetchLoanLendersGenerator = (data=[])=> {
  return {
    type:FETCH_LOAN_LENDERS,
    payload:data
  }
};
export const fetchLoanLendersSuccessGenerator = (data=[])=> {
  return {
    type:FETCH_LOAN_LENDERS_SUCCESS,
    payload:data
  }
};


export const addLoanLendersGenerator = (data=[])=> {
  return {
    type:ADD_LOAN_LENDERS,
    payload:data
  }
};
export const addLoanLendersSuccessGenerator = (data=[])=> {
  return {
    type:ADD_LOAN_LENDERS_SUCCESS,
    payload:data
  }
};

export const updateLoanLendersGenerator = (data=[])=> {
  return {
    type:UPDATE_LOAN_LENDERS,
    payload:data
  }
};
export const updateLoanLendersSuccessGenerator = (data=[])=> {
  return {
    type:UPDATE_LOAN_LENDERS_SUCCESS,
    payload:data
  }
};

export const deleteLoanLendersGenerator = (data=[])=> {
  return {
    type:DELETE_LOAN_LENDERS,
    payload:data
  }
};
export const deleteLoanLendersSuccessGenerator = (data=[])=> {
  return {
    type:DELETE_LOAN_LENDERS_SUCCESS,
    payload:data
  }
};
export const fetchLoanLenderTransactionsGenerator = (data=[])=> {
  return {
    type:FETCH_LOAN_LENDER_TRANSACTIONS,
    payload:data
  }
};
export const fetchLoanLenderTransactionsSuccessGenerator = (data=[])=> {
  return {
    type:FETCH_LOAN_LENDER_TRANSACTIONS_SUCCESS,
    payload:data
  }
};
export const topUpLoanLenderWalletGenerator = (data=[])=> {
  return {
    type:TOP_UP_LOAN_LENDER_WALLET,
    payload:data
  }
};
export const topUpLoanLenderWalletSuccessGenerator = (data=[])=> {
  return {
    type:TOP_UP_LOAN_LENDER_WALLET_SUCCESS,
    payload:data
  }
};
