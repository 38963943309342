export const FETCH_KEY_RESULTS = "FETCH_KEY_RESULTS";
export const FETCH_KEY_RESULTS_SUCCESS = "FETCH_KEY_RESULTS_SUCCESS";
export const ADD_KEY_RESULT = "ADD_KEY_RESULT";
export const ADD_KEY_RESULT_SUCCESS = "ADD_KEY_RESULT_SUCCESS";
export const UPDATE_KEY_RESULT = "UPDATE_KEY_RESULT";
export const UPDATE_KEY_RESULT_SUCCESS = "UPDATE_KEY_RESULT_SUCCESS";
export const DELETE_KEY_RESULT = "DELETE_KEY_RESULT";
export const DELETE_KEY_RESULT_SUCCESS = "DELETE_KEY_RESULT_SUCCESS";
export const fetchKeyResultGenerator = (data=[])=>{
    return {
        type:FETCH_KEY_RESULTS,
        payload:data
    };
};

export const fetchKeyResultSuccessGenerator = (data)=> {
    return {
        type:FETCH_KEY_RESULTS_SUCCESS,
        payload:data
    }
};

export const deleteKeyResultGenerator = (data)=> {
    return {
        type:DELETE_KEY_RESULT,
        payload:data
    }
};

export const deleteKeyResultSuccessGenerator = (data)=> {
    return {
        type:DELETE_KEY_RESULT_SUCCESS,
        payload:data
    }
};

export const addKeyResultSuccessGenerator = (data)=> {
    return {
        type:ADD_KEY_RESULT_SUCCESS,
        payload:data
    }
};

export const addKeyResultGenerator = (data)=> {
    return {
        type:ADD_KEY_RESULT,
        payload:data
    }
};

export const updateKeyResultGenerator =(data)=>{
    return {
        type:UPDATE_KEY_RESULT,
        payload:data
    }
};

export const updateKeyResultSuccessGenerator =(data)=>{
    return {
        type:UPDATE_KEY_RESULT_SUCCESS,
        payload:data
    }
};