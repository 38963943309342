////create
export const ADD_LEAVE_BALANCE = 'ADD_LEAVE_BALANCE';
export const ADD_LEAVE_BALANCE_SUCCESS = 'ADD_LEAVE_BALANCE_SUCCESS';
export const SHOW_CREATE_LEAVE_BALANCE_FORM = 'SHOW_CREATE_LEAVE_BALANCE_FORM';
export const HIDE_CREATE_LEAVE_BALANCE_FORM = 'HIDE_CREATE_LEAVE_BALANCE_FORM';
/// fetch
export const FETCH_LEAVE_BALANCE = 'FETCH_LEAVE_BALANCE';
export const FETCH_LEAVE_BALANCE_SUCCESS = 'FETCH_LEAVE_BALANCE_SUCCESS';
// ////edit
export const EDIT_LEAVE_BALANCE = 'EDIT_LEAVE_BALANCE';
export const EDIT_LEAVE_BALANCE_SUCCESS = 'EDIT_LEAVE_BALANCE_SUCCESS';
export const SHOW_EDIT_LEAVE_BALANCE_FORM = 'SHOW_EDIT_LEAVE_BALANCE_FORM';
export const HIDE_EDIT_LEAVE_BALANCE_FORM = 'HIDE_EDIT_LEAVE_BALANCE_FORM';
// ////delete
export const DELETE_LEAVE_BALANCE = 'DELETE_LEAVE_BALANCE';
export const DELETE_LEAVE_BALANCE_SUCCESS ='DELETE_LEAVE_BALANCE_SUCCESS';
export const SHOW_DELETE_LEAVE_BALANCE_MODAL = 'SHOW_DELETE_LEAVE_BALANCE_MODAL';
export const HIDE_DELETE_LEAVE_BALANCE_MODAL = 'HIDE_DELETE_LEAVE_BALANCE_MODAL';

/////CREATE
export const FETCH_EMPLOYEE_LEAVE_DAYS ="FETCH_EMPLOYEE_LEAVE_DAYS";
export const FETCH_EMPLOYEE_LEAVE_DAYS_SUCCESS ="FETCH_EMPLOYEE_LEAVE_DAYS_SUCCESS";

export const BULK_ASSIGN_LEAVE_BALANCE = 'BULK_ASSIGN_LEAVE_BALANCE';

export const FETCH_EMPLOYEE_ENTITLED_LEAVES =   "FETCH_EMPLOYEE_ENTITLED_LEAVES";
export const FETCH_EMPLOYEE_ENTITLED_LEAVES_SUCCESS =   "FETCH_EMPLOYEE_ENTITLED_LEAVES_SUCCESS";

export const SINGLE_EDIT_LEAVE_BALANCE ='SINGLE_EDIT_LEAVE_BALANCE';

export const singleEditLeaveBalance = payload =>{
  return {
    type:SINGLE_EDIT_LEAVE_BALANCE,
    payload
  }
}
export const fetchEntitledLeavesGenerator =(data)=>{
  return {
    type:FETCH_EMPLOYEE_ENTITLED_LEAVES,
    payload:data
  }
};
export const fetchEntitledLeavesSuccessGenerator =(data)=>{
  return {
    type:FETCH_EMPLOYEE_ENTITLED_LEAVES_SUCCESS,
    payload:data
  }
};
export const bulkAssignLeaveBalanceGenerator = (data)=>{
  return {
    type:BULK_ASSIGN_LEAVE_BALANCE,
    payload:data,
  }
};
export const fetchEmployeeLeaveDaysByCategoryGenerator = (data=[])=>{
  return {
    type:FETCH_EMPLOYEE_LEAVE_DAYS,
    payload:data
  }
};
export const fetchEmployeeLeaveDaysByCategorySuccessGenerator = (data)=>{
  return {
    type:FETCH_EMPLOYEE_LEAVE_DAYS_SUCCESS,
    payload:data
  }
};

export const addLeaveBalanceGenerator = (data)=>{
  return {
    type:ADD_LEAVE_BALANCE ,
    payload: data
  }
};
export const showLeaveBalanceCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_LEAVE_BALANCE_FORM,
    payload:data
  }
};
export const hideLeaveBalanceCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_LEAVE_BALANCE_FORM,
    payload:data
  }
};
export const addLeaveBalanceSuccessGenerator =(data)=>{
  return {
    type:ADD_LEAVE_BALANCE_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchLeaveBalanceGenerator=(data=[])=>{
  return {
    type:FETCH_LEAVE_BALANCE,
    payload:data
  }
};

export const fetchLeaveBalanceSuccessGenerator =(data)=>{
  return {
    type:FETCH_LEAVE_BALANCE_SUCCESS,
    payload:data
  }
};


///EDIT
export const editLeaveBalanceGenerator = (data)=>{
  return {
    type: EDIT_LEAVE_BALANCE,
    payload: data
  }
};

export const editLeaveBalanceSuccessGenerator=(data)=>{
  return {
    type:EDIT_LEAVE_BALANCE_SUCCESS,
    payload:data
  }
};
export const showLeaveBalanceEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_LEAVE_BALANCE_FORM,
    payload:data
  }
};
export const hideLeaveBalanceEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_LEAVE_BALANCE_FORM,
    payload:data
  }
};
// /////DELETE
export const deleteLeaveBalanceGenerator=(data)=>{
  return {
    type:DELETE_LEAVE_BALANCE,
    payload:data
  }
};
export const deleteLeaveBalanceSuccessGenerator = (data)=>{
  return {
    type:DELETE_LEAVE_BALANCE_SUCCESS,
    payload:data
  }
};
export const showLeaveBalanceDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_LEAVE_BALANCE_MODAL,
    payload:data
  }
};
export const hideLeaveBalanceDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_LEAVE_BALANCE_MODAL,
    payload:data
  }
};

