export const FETCH_ADMIN_STATUTORY_FILLING_REQUESTS = "FETCH_ADMIN_STATUTORY_FILLING_REQUESTS";
export const FETCH_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS = "FETCH_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS";

export const UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS = "UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS";
export const UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS = "UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS";

export const fetchAdminStatutoryFillingRequestsGenerator = (data=[])=>{
  return {
    type:FETCH_ADMIN_STATUTORY_FILLING_REQUESTS,
    payload:data
  };
};

export const fetchAdminStatutoryFillingRequestsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS,
    payload:dataById
  }
};

export const editAdminStatutoryFillingRequestsGenerator = (data)=>{
  return {
    type: UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS,
    payload: data
  }
};

export const editAdminStatutoryFillingRequestsSuccessGenerator = (data)=>{
  return {
    type: UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS_SUCCESS,
    payload: data
  }
};
