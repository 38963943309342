import {
  ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS,
  DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS,
  EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS,
  FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_DETAILS_SUCCESS,
  FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_SUCCESS
} from '../../actions/appraisal/PerformanceImprovementProgrammeActions';

const INIT_STATE = {
  groupedPerformanceImprovementProgrammes:[],
  performanceImprovementProgrammeDetailsByID:{},
  isGrouped:true
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case ADD_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS:
    case EDIT_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS:
    case DELETE_PERFORMANCE_IMPROVEMENT_PROGRAMME_SUCCESS:
    case FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_SUCCESS:
      return {
        ...state,
        groupedPerformanceImprovementProgrammes: action.payload,
        isGrouped:true
      };
    case FETCH_PERFORMANCE_IMPROVEMENT_PROGRAMMES_DETAILS_SUCCESS:
      return {
        ...state,
        performanceImprovementProgrammeDetailsByID: action.payload,
        isGrouped:false
      };
    default:
      return state;
  }
}
