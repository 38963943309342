import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_DEDUCTION_CATEGORY, DELETE_DEDUCTION_CATEGORY, deleteDeductionCategorySuccessGenerator, EDIT_DEDUCTION_CATEGORY, editDeductionCategorySuccessGenerator,
  FETCH_DEDUCTION_CATEGORIES,
  fetchDeductionCategoriesSuccessGenerator
} from "../../actions/payroll/DeductionCategoryActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addDeductionCategorySuccessGenerator
} from "../../actions/payroll/DeductionCategoryActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchDeductionCategoriesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/deduction-categories',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addDeductionCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/deduction-categories',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteDeductionCategoryRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/deduction-categories/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editDeductionCategoryRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/deduction-categories/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchDeductionCategories({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDeductionCategoriesRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchDeductionCategoriesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDeductionCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addDeductionCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let deduction_category = response.data.data;
        yield put(addDeductionCategorySuccessGenerator(deduction_category));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteDeductionCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDeductionCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(deleteDeductionCategorySuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editDeductionCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editDeductionCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editDeductionCategorySuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchDeductionCategoriesDefault() {
  yield takeLatest(FETCH_DEDUCTION_CATEGORIES, fetchDeductionCategories)
}
export function* addDeductionCategoryDefault() {
  yield takeLatest(ADD_DEDUCTION_CATEGORY, addDeductionCategory);
}
export function* deleteDeductionCategoryDefault() {
  yield takeLatest(DELETE_DEDUCTION_CATEGORY, deleteDeductionCategory);
}
export function* editDeductionCategoryDefault() {
  yield takeLatest(EDIT_DEDUCTION_CATEGORY, editDeductionCategory);
}
export default function* rootSaga() {
  yield all([fork(fetchDeductionCategoriesDefault),
    fork(addDeductionCategoryDefault),
    fork(deleteDeductionCategoryDefault),
    fork(editDeductionCategoryDefault)
  ]);
}
