import {
  ADD_WITHDRAWAL_SUCCESS,
  DELETE_WITHDRAWAL_SUCCESS,
  EDIT_WITHDRAWAL_SUCCESS,
  FETCH_WITHDRAWALS_SUCCESS,
  HIDE_WITHDRAWAL_CREATE_FORM,
  HIDE_WITHDRAWAL_DELETE_FORM,
  HIDE_WITHDRAWAL_EDIT_FORM,
  SHOW_WITHDRAWAL_CREATE_FORM,
  SHOW_WITHDRAWAL_DELETE_FORM,
  SHOW_WITHDRAWAL_EDIT_FORM
} from '../../actions/payroll/WithdrawalActions';

const INIT_STATE = {
  withdrawalsByID:{},
  showWithdrawalCreateForm:false,
  showWithdrawalEditForm:false,
  withdrawalToEdit:false,
  showWithdrawalDeleteForm:false,
  withdrawalToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        withdrawalsByID: action.payload
      };
    case SHOW_WITHDRAWAL_CREATE_FORM:
      return {
        ...state,
        showWithdrawalCreateForm: true
      };
    case HIDE_WITHDRAWAL_CREATE_FORM:
      return {
        ...state,
        showWithdrawalCreateForm: false
      };
    case SHOW_WITHDRAWAL_EDIT_FORM:
      return {
        ...state,
        showWithdrawalEditForm: true,
        withdrawalToEdit: action.payload
      };
    case HIDE_WITHDRAWAL_EDIT_FORM:
      return {
        ...state,
        showWithdrawalEditForm: false
      };
    case ADD_WITHDRAWAL_SUCCESS:
    case EDIT_WITHDRAWAL_SUCCESS:
      let withdrawal_id = action.payload.id;
      return {
        ...state,
        withdrawalsByID: {
          ...state.withdrawalsByID,
          [withdrawal_id]:action.payload
        }
      };
    case SHOW_WITHDRAWAL_DELETE_FORM:
      return {
        ...state,
        showWithdrawalDeleteForm: true,
        withdrawalToDelete: action.payload
      };
    case HIDE_WITHDRAWAL_DELETE_FORM:
      return {
        ...state,
        showWithdrawalDeleteForm: false
      };
    case DELETE_WITHDRAWAL_SUCCESS:
      let withdrawalsByID = {...state.withdrawalsByID};
      delete withdrawalsByID[action.payload.id];
      return {
        ...state,
        withdrawalsByID:withdrawalsByID
      };
    default:
      return state;
  }
}
