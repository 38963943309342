import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {takeLatest} from "redux-saga/effects";
import {API_ADDRESS, API_ENDPOINT} from "../../../constants/ActionTypes";
import {hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator} from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    ACKNOWLEDGE_COMPLETION,
    ASSIGN_EMPLOYEE_TO_TRAINING,
    COMPLETE_TRAINING,
    CREATE_TRAINING,
    DELETE_EMPLOYEE_TRAINING,
    DELETE_TRAINING,
    deleteTrainingSuccessGenerator,
    DOWNLOAD_TRAINING_CERTIFICATE, EDIT_EMPLOYEE_TRAINING,
    EDIT_TRAINING,
    FETCH_TRAINING,
    fetchTrainingGenerator,
    fetchTrainingSuccessGenerator, GET_TRAINING_KEYED,
    getTrainingsKeyedSuccessGenerator,
    UPLOAD_TRAINING_CERTIFICATE
} from "../../actions/payroll/TrainingActions";

const  acknowledgeTrainingCompletionRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/acknowledge/training',requestData)
    .then(response=>response)
    .catch(error=>error.response)

const fetchTrainingsRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/hrm/training',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const createTrainingRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/create/training',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const editTrainingRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/edit/training/'+requestData.id,requestData)
        .then(response=>response)
        .catch(error=>error.response);

const deleteTrainingRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/delete/training/'+requestData.id,requestData)
        .then(response=>response)
        .catch(error=>error.response);

const assignEmployeeToTrainingRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/assign/training/employee',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const uploadTrainingCertificateRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/upload/training/certificate',requestData,{headers: {
            'content-type': `multipart/form-data`
        }})
        .then(response=>response)
        .catch(error=>error.response);

const downloadTrainingCertificateRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/hrm/download/training/certificate/'+requestData.id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const getTrainingsKeyedRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/hrm/get/training',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const completeTrainingRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/complete/training',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const editEmployeeTrainingRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/edit/employee/training',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const deleteEmployeeTrainingRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/delete/employee/training/'+requestData.id,requestData)
        .then(response=>response)
        .catch(error=>error.response);

function* acknowledgeTrainingCompletion({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(acknowledgeTrainingCompletionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
               // let trainings = response.data.data;
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* completeTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(completeTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
               // let trainings = response.data.data;
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editEmployeeTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editEmployeeTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteEmployeeTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteEmployeeTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* getTrainingsKeyed({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(getTrainingsKeyedRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let trainings = response.data.data;
                yield put(getTrainingsKeyedSuccessGenerator(trainings));
                //yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* downloadTrainingCertificate({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(downloadTrainingCertificateRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let trainings = response.data.data;
                if (trainings == null){
                    yield put(showMessageGenerator('No certificate found for download','error'));
                }else{
                    Helpers.openInNewTab(API_ADDRESS+trainings);
                }
                //yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* uploadTrainingCertificate({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const sentData = Helpers.getFormData(requestParameters);
        const response = yield call(uploadTrainingCertificateRequest, sentData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* assignEmployeeToTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(assignEmployeeToTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let trainings = response.data.data;
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* deleteTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
               // let trainings = response.data.data;
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* editTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let trainings = response.data.data;
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* createTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(createTrainingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let trainings = response.data.data;
                yield put(fetchTrainingGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchTraining({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchTrainingsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let trainings = response.data.data;
                yield put(fetchTrainingSuccessGenerator(trainings));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error){
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* acknowledgeTrainingCompletionDefault(){
    yield takeLatest(ACKNOWLEDGE_COMPLETION,acknowledgeTrainingCompletion);
}
export function* createTrainingDefault(){
    yield takeLatest(CREATE_TRAINING,createTraining);
}
export function* fetchTrainingsDefault(){
    yield takeLatest(FETCH_TRAINING,fetchTraining);
}
export function* editTrainingDefault(){
    yield takeLatest(EDIT_TRAINING,editTraining);
}
export function* deleteTrainingDefault(){
    yield takeLatest(DELETE_TRAINING,deleteTraining);
}
export function* assignEmployeeToTrainingDefault(){
    yield takeLatest(ASSIGN_EMPLOYEE_TO_TRAINING,assignEmployeeToTraining);
}
export function* uploadTrainingCertificateDefault(){
    yield takeLatest(UPLOAD_TRAINING_CERTIFICATE,uploadTrainingCertificate);
}
export function* downloadTrainingCertificateDefault(){
    yield takeLatest(DOWNLOAD_TRAINING_CERTIFICATE,downloadTrainingCertificate);
}
export function* getTrainingsKeyedDefault(){
    yield takeLatest(GET_TRAINING_KEYED,getTrainingsKeyed);
}
export function* completeTrainingDefault(){
    yield takeLatest(COMPLETE_TRAINING,completeTraining);
}
export function* editEmployeeTrainingDefault(){
    yield takeLatest(EDIT_EMPLOYEE_TRAINING,editEmployeeTraining);
}
export function* deleteEmployeeTrainingDefault(){
    yield takeLatest(DELETE_EMPLOYEE_TRAINING,deleteEmployeeTraining);
}

export default function* rootSaga(){
    yield all([
        fork(fetchTrainingsDefault),
        fork(createTrainingDefault),
        fork(editTrainingDefault),
        fork(deleteTrainingDefault),
        fork(assignEmployeeToTrainingDefault),
        fork(uploadTrainingCertificateDefault),
        fork(downloadTrainingCertificateDefault),
        fork(getTrainingsKeyedDefault),
        fork(completeTrainingDefault),
        fork(editEmployeeTrainingDefault),
        fork(deleteEmployeeTrainingDefault),
        fork(acknowledgeTrainingCompletionDefault)
    ])
}
