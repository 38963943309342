export const FETCH_WORKED_DAYS = 'FETCH_WORKED_DAYS';
export const FETCH_WORKED_DAYS_SUCCESS = 'FETCH_WORKED_DAYS_SUCCESS';

export const ADD_WORKED_DAYS = 'ADD_WORKED_DAYS';
export const ADD_WORKED_DAYS_SUCCESS = 'ADD_WORKED_DAYS_SUCCESS';

export const EDIT_WORKED_DAYS = 'EDIT_WORKED_DAYS';
export const EDIT_WORKED_DAYS_SUCCESS = 'EDIT_WORKED_DAYS_SUCCESS';

export const DELETE_WORKED_DAYS = 'DELETE_WORKED_DAYS';
export const DELETE_WORKED_DAYS_SUCCESS = 'DELETE_WORKED_DAYS_SUCCESS';

export const BULK_UPLOAD_WORKED_DAYS = 'BULK_UPLOAD_WORKED_DAYS';
export const DOWNLOAD_WORKED_DAYS = 'DOWNLOAD_WORKED_DAYS';

export const DOWNLOAD_EMPLOYEES_WITHOUT_DAYS ='DOWNLOAD_EMPLOYEES_WITHOUT_DAYS';

export const BULK_DATED_WORK_DAYS = 'BULK_DATED_WORK_DAYS';


export const bulkUploadDatedWorkDaysGenerator = payload =>{
  return {
    type: BULK_DATED_WORK_DAYS,
    payload
  }
};


export const downloadEmployeesWithoutDaysGenerator = (data)=>{
  return {
    type:DOWNLOAD_EMPLOYEES_WITHOUT_DAYS,
    payload:data
  }
};

export const downloadWorkedDays = (data)=>{
  return {
    type:DOWNLOAD_WORKED_DAYS,
    payload:data
  }
};

export const bulkUploadWorkedDays =(data)=>{
  return {
    type:BULK_UPLOAD_WORKED_DAYS,
    payload:data
  }
};

export const fetchWorkedDaysGenerator =(data)=>{
  return {
    type:FETCH_WORKED_DAYS,
    payload:data
  }
};
export const fetchWorkedDaySuccessGenerator =(data)=>{
  return {
    type:FETCH_WORKED_DAYS_SUCCESS,
    payload:data
  }
};

export const addWorkedDaysGenerator =(data)=>{
 return {
   type:ADD_WORKED_DAYS,
   payload:data
 }
};
export const addWorkedDaysSuccessGenerator =(data)=>{
 return {
   type:ADD_WORKED_DAYS_SUCCESS,
   payload:data
 }
};
export const deleteWorkedDaysGenerator =(data)=>{
  return {
    type:DELETE_WORKED_DAYS,
    payload:data
  }
};
export const deleteWorkedDaySuccessGenerator =(data)=>{
  return {
    type:DELETE_WORKED_DAYS_SUCCESS,
    payload:data
  }
};
export const editWorkedDaysGenerator = (data)=>{
  return {
    type:EDIT_WORKED_DAYS,
    payload:data
  }
};
export const editWorkedDaySuccessGenerator = (data)=>{
  return {
    type:EDIT_WORKED_DAYS_SUCCESS,
    payload:data
  }
};