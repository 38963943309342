///create
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const SHOW_CREATE_DEPARTMENT_FORM = 'SHOW_CREATE_DEPARTMENT_FORM';
export const HIDE_CREATE_DEPARTMENT_FORM = 'HIDE_CREATE_DEPARTMENT_FORM';
/// fetch
export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS';
export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS';
////delete
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const DELETE_DEPARTMENT_SUCCESS ='DELETE_DEPARTMENT_SUCCESS';
export const SHOW_DELETE_DEPARTMENT_MODAL = 'SHOW_DELETE_DEPARTMENT_MODAL';
export const HIDE_DELETE_DEPARTMENT_MODAL = 'HIDE_DELETE_DEPARTMENT_MODAL';
////edit
export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENT';
export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const SHOW_EDIT_DEPARTMENT_FORM = 'SHOW_EDIT_DEPARTMENT_FORM';
export const HIDE_EDIT_DEPARTMENT_FORM = 'HIDE_EDIT_DEPARTMENT_FORM';

/////CREATE
export const addDepartmentGenerator = (data)=>{
  return {
    type: ADD_DEPARTMENT,
    payload: data
  }
};
export const showDepartmentCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_DEPARTMENT_FORM,
    payload:data
  }
};
export const hideDepartmentCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_DEPARTMENT_FORM,
    payload:data
  }
};
export const addDepartmentSuccessGenerator =(data)=>{
  return {
    type:ADD_DEPARTMENT_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchDepartmentGenerator=(data=[])=>{
  return {
    type:FETCH_DEPARTMENTS,
    payload:data
  }
};

export const fetchDepartmentSuccessGenerator =(data)=>{
  return {
    type:FETCH_DEPARTMENT_SUCCESS,
    payload:data
  }
};


///EDIT
export const editDepartmentGenerator = (data)=>{
  return {
    type: EDIT_DEPARTMENT,
    payload: data
  }
};

export const editDepartmentSuccessGenerator=(data)=>{
  return {
    type:EDIT_DEPARTMENT_SUCCESS,
    payload:data
  }
};
export const showDepartmentEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_DEPARTMENT_FORM,
    payload:data
  }
};
export const hideDepartmentEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_DEPARTMENT_FORM,
    payload:data
  }
};
/////DELETE
export const deletedDepartmentGenerator=(data)=>{
  return {
    type:DELETE_DEPARTMENT,
    payload:data
  }
};

export const deleteDepartmentSuccessGenerator = (data)=>{
  return {
    type:DELETE_DEPARTMENT_SUCCESS,
    payload:data
  }
};
export const showDepartmentDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_DEPARTMENT_MODAL,
    payload:data
  }
};
export const hideDepartmentDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_DEPARTMENT_MODAL,
    payload:data
  }
};



