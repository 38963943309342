import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

export default {
  palette: {
    primary: {
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: '#fff'
    },
    secondary: {
      light: yellow[300],
      main: yellow.A200,
      dark: yellow[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    }
  }
};
