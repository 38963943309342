export const FETCH_LEAVES = "FETCH_LEAVES";
export const FETCH_LEAVES_SUCCESS = "FETCH_LEAVES_SUCCESS";
export const SHOW_LEAVE_CREATE_FORM = 'SHOW_LEAVE_CREATE_FORM';
export const HIDE_LEAVE_CREATE_FORM = 'HIDE_LEAVE_CREATE_FORM';
export const ADD_LEAVE = 'ADD_LEAVE';
export const ADD_LEAVE_SUCCESS = 'ADD_LEAVE_SUCCESS';
export const SHOW_LEAVE_EDIT_FORM = 'SHOW_LEAVE_EDIT_FORM';
export const HIDE_LEAVE_EDIT_FORM = 'HIDE_LEAVE_EDIT_FORM';
export const EDIT_LEAVE = 'EDIT_LEAVE';
export const EDIT_LEAVE_SUCCESS = 'EDIT_LEAVE_SUCCESS';
export const SHOW_LEAVE_DELETE_FORM = 'SHOW_LEAVE_DELETE_FORM';
export const HIDE_LEAVE_DELETE_FORM = 'HIDE_LEAVE_DELETE_FORM';
export const DELETE_LEAVE = 'DELETE_LEAVE';
export const DELETE_LEAVE_SUCCESS = 'DELETE_LEAVE_SUCCESS';
export const FETCH_LEAVE_DETAILS = "FETCH_LEAVE_DETAILS";
export const FETCH_LEAVE_DETAILS_SUCCESS = "FETCH_LEAVE_DETAILS_SUCCESS";
export const CERTIFY_LEAVE_MULTIPLE = "CERTIFY_LEAVE_MULTIPLE";
export const APPROVE_LEAVE_MULTIPLE = "APPROVE_LEAVE_MULTIPLE";
export const ACTIVATE_LEAVE_MULTIPLE = "ACTIVATE_LEAVE_MULTIPLE";
export const RECALL_LEAVE_MULTIPLE = "RECALL_LEAVE_MULTIPLE";
export const GET_DEPARTMENT_SUPERVISORS = "GET_DEPARTMENT_SUPERVISORS";

export const getDepartmentSupervisorsGenerator =(data)=>{
  return{
    type:GET_DEPARTMENT_SUPERVISORS,
    payload:data
  }
};

export const certifyLeaveMultipleGenerator = (data)=>{
  return {
     type:CERTIFY_LEAVE_MULTIPLE,
     payload:data
  }
};
export const approveLeaveMultipleGenerator = (data)=>{
  return {
     type:APPROVE_LEAVE_MULTIPLE,
     payload:data
  }
};
export const activateLeaveMultipleGenerator = (data)=>{
  return {
     type:ACTIVATE_LEAVE_MULTIPLE,
     payload:data
  }
};
export const recallLeaveMultipleGenerator = (data)=>{
  return {
     type:RECALL_LEAVE_MULTIPLE,
     payload:data
  }
};
export const fetchLeavesGenerator = (data=[])=>{
  return {
    type:FETCH_LEAVES,
    payload:data
  };
};

export const fetchLeavesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_LEAVES_SUCCESS,
    payload:dataById
  }
};

export const showLeaveCreateFormGenerator = ()=>{
  return {
    type:SHOW_LEAVE_CREATE_FORM
  };
};
export const hideLeaveCreateFormGenerator = ()=>{
  return {
    type:HIDE_LEAVE_CREATE_FORM
  };
};
export const addLeaveGenerator = (data)=>{
  return {
    type: ADD_LEAVE,
    payload: data
  }
};

export const addLeaveSuccessGenerator = (data)=>{
  return {
    type: ADD_LEAVE_SUCCESS,
    payload: data
  }
};
export const showLeaveEditFormGenerator = (data)=>{
  return {
    type:SHOW_LEAVE_EDIT_FORM,
    payload:data
  };
};
export const hideLeaveEditFormGenerator = ()=>{
  return {
    type:HIDE_LEAVE_EDIT_FORM
  };
};
export const editLeaveGenerator = (data)=>{
  return {
    type: EDIT_LEAVE,
    payload: data
  }
};
export const editLeaveSuccessGenerator = (data)=>{
  return {
    type: EDIT_LEAVE_SUCCESS,
    payload: data
  }
};
export const showLeaveDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_LEAVE_DELETE_FORM,
    payload:data
  };
};
export const hideLeaveDeleteFormGenerator = ()=>{
  return {
    type:HIDE_LEAVE_DELETE_FORM
  };
};
export const deleteLeaveGenerator = (data)=>{
  return {
    type: DELETE_LEAVE,
    payload: data
  }
};
export const deleteLeaveSuccessGenerator = (data)=>{
  return {
    type: DELETE_LEAVE_SUCCESS,
    payload: data
  }
};
export const fetchLeaveDetailsGenerator = (data)=>{
  return {
    type: FETCH_LEAVE_DETAILS,
    payload: data
  }
};
export const fetchLeaveDetailsSuccessGenerator = (data)=>{
  return {
    type: FETCH_LEAVE_DETAILS_SUCCESS,
    payload: data
  }
};
