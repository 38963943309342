import { API_ENDPOINT } from "../../../constants/ActionTypes";
import axios from "axios/index";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {all, call, fork, put} from "redux-saga/effects";
import {
  BULK_DELETE_FINGERPRINTS,
  bulkDeleteFingerPrintsSuccessGenerator,
  DELETE_FINGERPRINTS,
  FETCH_FINGERPRINTS,
  fetchFingerPrintsSuccessGenerator
} from "../../actions/attendance/FingerPrintsActions";
import { attendanceFetchEmployeesGenerator } from "../../actions/attendance/EmployeesActions";


const fetchFingerPrintsRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/fingerprints',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const deleteFingerPrintsRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/fingerprints/remove',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const bulkDeleteFingerPrintsRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/fingerprints/remove-bulk',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchFingerPrints({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchFingerPrintsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let fingerprints = response.data.data;
        yield put(fetchFingerPrintsSuccessGenerator(fingerprints));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* bulkDeleteFingerPrints({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(bulkDeleteFingerPrintsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(bulkDeleteFingerPrintsSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteFingerPrints({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteFingerPrintsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(attendanceFetchEmployeesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* deleteFingerPrintsDefault() {
  yield takeLatest(DELETE_FINGERPRINTS, deleteFingerPrints);
}

export function* bulkDeleteFingerPrintsDefault() {
  yield takeLatest(BULK_DELETE_FINGERPRINTS, bulkDeleteFingerPrints);
}

export function* fetchFingerPrintsDefault() {
  yield takeLatest(FETCH_FINGERPRINTS, fetchFingerPrints)
}
export default function* rootSaga() {
  yield all([
      fork(fetchFingerPrintsDefault),
      fork(deleteFingerPrintsDefault),
      fork(bulkDeleteFingerPrintsDefault)
  ]);
}
