export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS";
export const SHOW_DOCUMENT_CREATE_FORM = 'SHOW_DOCUMENT_CREATE_FORM';
export const HIDE_DOCUMENT_CREATE_FORM = 'HIDE_DOCUMENT_CREATE_FORM';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const SHOW_DOCUMENT_EDIT_FORM = 'SHOW_DOCUMENT_EDIT_FORM';
export const HIDE_DOCUMENT_EDIT_FORM = 'HIDE_DOCUMENT_EDIT_FORM';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS';
export const SHOW_DOCUMENT_DELETE_FORM = 'SHOW_DOCUMENT_DELETE_FORM';
export const HIDE_DOCUMENT_DELETE_FORM = 'HIDE_DOCUMENT_DELETE_FORM';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';

export const fetchDocumentsGenerator = (data=[])=>{
  return {
    type:FETCH_DOCUMENTS,
    payload:data
  };
};

export const fetchDocumentsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_DOCUMENTS_SUCCESS,
    payload:dataById
  }
};

export const showDocumentCreateFormGenerator = ()=>{
  return {
    type:SHOW_DOCUMENT_CREATE_FORM
  };
};
export const hideDocumentCreateFormGenerator = ()=>{
  return {
    type:HIDE_DOCUMENT_CREATE_FORM
  };
};
export const addDocumentGenerator = (data)=>{
  return {
    type: ADD_DOCUMENT,
    payload: data
  }
};

export const addDocumentSuccessGenerator = (data)=>{
  return {
    type: ADD_DOCUMENT_SUCCESS,
    payload: data
  }
};
export const showDocumentEditFormGenerator = (data)=>{
  return {
    type:SHOW_DOCUMENT_EDIT_FORM,
    payload:data
  };
};
export const hideDocumentEditFormGenerator = ()=>{
  return {
    type:HIDE_DOCUMENT_EDIT_FORM
  };
};
export const editDocumentGenerator = (data)=>{
  return {
    type: EDIT_DOCUMENT,
    payload: data
  }
};
export const editDocumentSuccessGenerator = (data)=>{
  return {
    type: EDIT_DOCUMENT_SUCCESS,
    payload: data
  }
};
export const showDocumentDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_DOCUMENT_DELETE_FORM,
    payload:data
  };
};
export const hideDocumentDeleteFormGenerator = ()=>{
  return {
    type:HIDE_DOCUMENT_DELETE_FORM
  };
};
export const deleteDocumentGenerator = (data)=>{
  return {
    type: DELETE_DOCUMENT,
    payload: data
  }
};
export const deleteDocumentSuccessGenerator = (data)=>{
  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: data
  }
};
