import AdminCompanySaga from "./AdminCompanySaga";
import PricingSaga from "./PricingSaga";
import AdminSubscriptionSaga from "./AdminSubscriptionSaga";
import DashboardSaga from "./DashboardSaga";
import AppraisalAdminSaga from "./AppraisalAdminSaga";
import SenderIdSagas from "./SenderIdSagas";
import AdminUsersSaga from "./AdminUsersSaga";
import MpesaSettingSaga from "./MpesaSettingSaga";
import CompanyLoanPaymentSaga from "./CompanyLoanPaymentSaga";
import CompanyLoansSaga from "./CompanyLoansSaga";
import CompanyLoanRatesSaga from "./CompanyLoanRatesSaga";
import BankTransferSaga from "./BankTransferSaga";
import SystemUsersSaga from "./SystemUsersSaga";
import LoanLenderSaga from "./LoanLenderSaga";
import LoanReportsSaga from "./LoanReportsSaga";
import LoanRequestSaga from "./LoanRequestSaga";
import LoanDashboardSaga from "./LoanDashboardSaga";
import CustomerStatementSaga from "./CustomerStatementSaga";
import MobileNotificationSaga from "./MobileNotificationSaga";
import AdminEmployeesSaga from "./AdminEmployeesSaga";
import StatutoryFillingRequestSaga from "./StatutoryFillingRequestSaga";
import SalaryAdvanceSettingsSaga from "./SalaryAdvanceSettingsSaga";
import CompaniesFinancedSalaryAdvanceSaga from "./CompaniesFinancedSalaryAdvanceSaga";
import SubscriptionPricingSaga from "./SubscriptionPricingSaga";
import CompanyAccountSettingsSaga from "./CompanyAccountSettingsSaga";
import SalaryAdvanceDefaultSettingsSaga from "./SalaryAdvanceDefaultSettingsSaga";
import SalaryAdvanceFeesSaga from "./SalaryAdvanceFeesSaga";
import PaymentActivationSaga from "./PaymentActivationSaga";
import EnableDisableFeaturesSaga from "./EnableDisableFeaturesSaga";
import CompanyOnboardingSaga from "./CompanyOnboardingSaga";
import AdminHolidaySagas from "./AdminHolidaySagas";
import ExchangeRatesSaga from "./ExchangeRatesSaga";

const Admin = [
    AdminCompanySaga(),
    PricingSaga(),
    AdminSubscriptionSaga(),
    DashboardSaga(),
    AppraisalAdminSaga(),
    SenderIdSagas(),
    AdminUsersSaga(),
    MpesaSettingSaga(),
    CompanyLoanPaymentSaga(),
    CompanyLoansSaga(),
    CompanyLoanRatesSaga(),
    BankTransferSaga(),
    SystemUsersSaga(),
    LoanLenderSaga(),
    LoanReportsSaga(),
    LoanRequestSaga(),
    LoanDashboardSaga(),
    CustomerStatementSaga(),
    MobileNotificationSaga(),
    AdminEmployeesSaga(),
    CompaniesFinancedSalaryAdvanceSaga(),
    StatutoryFillingRequestSaga(),
    SalaryAdvanceSettingsSaga(),
    SubscriptionPricingSaga(),
    CompanyAccountSettingsSaga(),
    SalaryAdvanceDefaultSettingsSaga(),
    SalaryAdvanceFeesSaga(),
    PaymentActivationSaga(),
    EnableDisableFeaturesSaga(),
    CompanyOnboardingSaga(),
    AdminHolidaySagas(),
    ExchangeRatesSaga()
];
export default Admin;
