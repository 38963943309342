import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  ACTIVE_DEACTIVATE_HELBS,
  BULK_HELBS,
  CREATE_HELB,
  DELETE_HELB, EDIT_HELB,
  FETCH_HELBS, fetchHelbGenerator,
  fetchHelbSuccessGenerator
} from "../../actions/payroll/HelbActions";



const activateDeactivateHelbRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/helb/active/deactivate',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchHelbRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/helb',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addHelbRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/helb',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editHelbRequest= async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/helb/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteHelbRequest= async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/helb/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const bulkUploadHelbRequest= async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/bulk/upload/helb',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

function* activateDeactivateHelb({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(activateDeactivateHelbRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchHelbGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* bulkUploadHelb({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParams);
    const response = yield call(bulkUploadHelbRequest,formData);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchHelbGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteHelb({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteHelbRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchHelbGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editHelb({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editHelbRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchHelbGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addHelb({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addHelbRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchHelbGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchHelb({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchHelbRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchHelbSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* activateDeactivateHelbDefault() {
  yield takeLatest(ACTIVE_DEACTIVATE_HELBS, activateDeactivateHelb)
}
export function* bulkUploadHelbDefault() {
  yield takeLatest(BULK_HELBS, bulkUploadHelb)
}
export function* deleteHelbDefault() {
  yield takeLatest(DELETE_HELB, deleteHelb)
}
export function* editHelbDefault() {
  yield takeLatest(EDIT_HELB, editHelb)
}
export function* addHelbDefault() {
  yield takeLatest(CREATE_HELB, addHelb)
}
export function* fetchHelbDefault() {
  yield takeLatest(FETCH_HELBS, fetchHelb)
}
export default function* rootSaga() {
  yield all([fork(fetchHelbDefault),
    fork(editHelbDefault),
    fork(addHelbDefault),
    fork(deleteHelbDefault),
    fork(bulkUploadHelbDefault),
    fork(activateDeactivateHelbDefault)

  ]);
}
