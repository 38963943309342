import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import {Helpers} from '../../util/Helpers';


class Payroll extends Component {
  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    return (
      <ul className="nav-menu">
        <li className="nav-header">
          <IntlMessages id="sidebar.hRpayroll" />
        </li>
        <li className="menu no-arrow">
          <NavLink to="/hrm/dashboard">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.dashboard" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu collapse-box">
          <Button>
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.employees" />
            </span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon" to="/hrm/employees">
                <span className="nav-text"><IntlMessages id="sidebar.employees.viewEmployees" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/employee-exits">
                <span className="nav-text"><IntlMessages id="sidebar.employees.employeeExits" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/trainings">
                <span className="nav-text"><IntlMessages id="sidebar.employeeTrainings" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/esop">
                <span className="nav-text"><IntlMessages id="sidebar.esop" /></span>
              </NavLink>
            </li>
          </ul>
        </li>

        {
          this.props.countryName !== 'Kenya' && !Helpers.isEthiopian(this.props.countryName) &&
          <li className="menu no-arrow">
            <NavLink to="/hrm/kyc">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.payroll" />
                {' '}
            </span>
            </NavLink>
          </li>
        }

        {
          (this.props.countryName === 'Kenya' || Helpers.isEthiopian(this.props.countryName)) &&
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.payroll" />
            </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/hrm/payroll-batches">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.previousPayrolls" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/payslips">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.viewPayslips" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/manual-worked-days">
                  <span className="nav-text"><IntlMessages id="sidebar.attendance.WorkedDays" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/products-delivered">
                  <span className="nav-text"><IntlMessages id="sidebar.productsDelivered" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/allowances">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollData.allowances" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/deductions">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollData.deductions" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/loans">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollData.loans" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/paid-leaves">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollData.paidLeaves" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="market-Lending-rate">
                      <span className="nav-text">
                        <IntlMessages id="sidebar.payroll.payrollData.marketLendingRates" />
                        </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/insurance-premium">
                      <span className="nav-text">
                        <IntlMessages id="sidebar.insurance-premium" />
                      </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/mortgage-interest-payments">
                      <span className="nav-text">
                        <IntlMessages id="sidebar.payroll.payrollData.mortgage" />
                      </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/employee-rent">
                      <span className="nav-text">
                        <IntlMessages id="sidebar.payroll.employee-rent" />
                      </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/esop">
                        <span className="nav-text">
                          <IntlMessages id="sidebar.esop" />
                        </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/salary-advance">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollExtras.salaryAdvance" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/assets">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollExtras.assets" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/hrm/savings">
                  <span className="nav-text"><IntlMessages id="sidebar.payroll.payrollExtras.savings" /></span>
                </NavLink>
              </li>
            </ul>
          </li>
        }


        <li className="menu no-arrow">
          <NavLink to="/hrm/expenses">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollExtras.pettyCash" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu collapse-box">
          <Button>
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.leaves" />
            </span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon" to="/hrm/leaves">
                <span className="nav-text"><IntlMessages id="sidebar.leavesListing" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/off-days">
                <span className="nav-text"><IntlMessages id="sidebar.dynamicOffDays" /></span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/hrm/documents">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.documents" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/hrm/reports">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.reports" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/hrm/sms">
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.bulkMessages" />
              {' '}
            </span>
          </NavLink>
        </li>
        <li className="menu collapse-box">
          <Button>
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.settings" />
            </span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon" to="/hrm/companies">
                <span className="nav-text"><IntlMessages id="sidebar.settings.companies" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/users">
                <span className="nav-text"><IntlMessages id="sidebar.settings.users" /></span>
              </NavLink>
            </li>
            {/*<li>
              <NavLink className="prepend-icon" to="/hrm/banks">
                <span className="nav-text"><IntlMessages id="sidebar.banks" /></span>
              </NavLink>
            </li>*/}
           {/* <li>
              <NavLink className="prepend-icon" to="/common/roles">
                <span className="nav-text"><IntlMessages id="sidebar.settings.roles" /></span>
              </NavLink>
            </li>*/}
            <li>
              <NavLink className="prepend-icon" to="/hrm/system-settings">
                <span className="nav-text"><IntlMessages id="sidebar.systemSettings" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/departments">
                <span className="nav-text"><IntlMessages id="sidebar.settings.hrmSettings" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/employee-payment-rate">
                <span className="nav-text"><IntlMessages id="sidebar.settings.salaryRates" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/leave-categories">
                <span className="nav-text"><IntlMessages id="sidebar.settings.leaveSettings" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/hrm/loan-categories">
                <span className="nav-text"><IntlMessages id="sidebar.settings.payrollSettings" /></span>
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = ({ company}) => {
  const {companiesByID} = company;
  const branch = Helpers.currentSwitchedBranch(companiesByID);
  const currencyCode = branch.currency_code||'KSH';
  const countryName = branch.country_name||'Kenya';
  return { companiesByID, currencyCode,countryName};
};

export default withRouter(connect(mapStateToProps)(Payroll));
