export const FETCH_SELF_APPRAISALS ="FETCH_SELF_APPRAISALS";
export const FETCH_SELF_APPRAISALS_SUCCESS ="FETCH_SELF_APPRAISALS_SUCCESS";
export const FETCH_INFO_TO_SELF_APPRAISE = "FETCH_INFO_TO_SELF_APPRAISE";
export const FETCH_INFO_TO_SELF_APPRAISE_SUCCESS = "FETCH_INFO_TO_SELF_APPRAISE_SUCCESS";
export const SELF_APPRAISE = "SELF_APPRAISE";
export const SELF_APPRAISE_SUCCESS = "SELF_APPRAISE_SUCCESS";
export const UPDATE_IS_GROUPED = 'UPDATE_IS_GROUPED';

export const fetchSelfAppraisalsGenerator =(data=[])=>{
    return {
        type:FETCH_SELF_APPRAISALS,
        payload:data
    }
};
export const fetchSelfAppraisalsSuccessGenerator =(data=[])=>{
    return {
        type:FETCH_SELF_APPRAISALS_SUCCESS,
        payload:data
    }
};

export const updateIsGroupedProp = (data)=>{
    return {
        type:UPDATE_IS_GROUPED,
        payload:data
    }
};


export const fetchInfoToSelfAppraiseGenerator =(data=[])=>{
    return {
        type:FETCH_INFO_TO_SELF_APPRAISE,
        payload:data
    }
};
export const fetchInfoToSelfAppraiseSuccessGenerator =(data=[])=>{
    return {
        type:FETCH_INFO_TO_SELF_APPRAISE_SUCCESS,
        payload:data
    }
};

export const selfAppraiseGenerator =(data=[])=>{
    return {
        type:SELF_APPRAISE,
        payload:data
    }
};
export const selfAppraiseSuccessGenerator =(data=[])=>{
    return {
        type:SELF_APPRAISE_SUCCESS,
        payload:data
    }
};
