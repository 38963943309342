import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_PAYOUT_CHARGE,
  DELETE_PAYOUT_CHARGE,
  deletePayoutChargeSuccessGenerator,
  EDIT_PAYOUT_CHARGE,
  editPayoutChargeSuccessGenerator,
  FETCH_PAYOUT_CHARGES,
  fetchPayoutChargesSuccessGenerator,
  addPayoutChargeSuccessGenerator,
  fetchDefaultPayoutChargesSuccessGenerator,
  addDefaultPayoutChargeSuccessGenerator,
  deleteDefaultPayoutChargeSuccessGenerator,
  editDefaultPayoutChargeSuccessGenerator,
  FETCH_DEFAULT_PAYOUT_CHARGES,
  ADD_DEFAULT_PAYOUT_CHARGE, DELETE_DEFAULT_PAYOUT_CHARGE, EDIT_DEFAULT_PAYOUT_CHARGE
} from "../../actions/payouts/PayoutChargeActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchPayoutChargesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/payout-charges',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPayoutChargeRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/payout-charges',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePayoutChargeRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/payout-charges/'+(requestParameters.id ? requestParameters.id : 0),{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPayoutChargeRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/home/payout-charges/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchDefaultPayoutChargesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/default-payout-charges',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addDefaultPayoutChargeRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/default-payout-charges',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteDefaultPayoutChargeRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/default-payout-charges/'+(requestParameters.id ? requestParameters.id : 0),{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editDefaultPayoutChargeRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/home/default-payout-charges/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPayoutCharges({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPayoutChargesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchPayoutChargesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPayoutCharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addPayoutChargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(addPayoutChargeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePayoutCharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePayoutChargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(deletePayoutChargeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPayoutCharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editPayoutChargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(editPayoutChargeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


function* fetchDefaultPayoutCharges({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDefaultPayoutChargesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchDefaultPayoutChargesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDefaultPayoutCharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addDefaultPayoutChargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(addDefaultPayoutChargeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteDefaultPayoutCharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDefaultPayoutChargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(deleteDefaultPayoutChargeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editDefaultPayoutCharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editDefaultPayoutChargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(editDefaultPayoutChargeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPayoutChargesDefault() {
  yield takeLatest(FETCH_PAYOUT_CHARGES, fetchPayoutCharges)
}
export function* addPayoutChargeDefault() {
  yield takeLatest(ADD_PAYOUT_CHARGE, addPayoutCharge);
}
export function* deletePayoutChargeDefault() {
  yield takeLatest(DELETE_PAYOUT_CHARGE, deletePayoutCharge);
}
export function* editPayoutChargeDefault() {
  yield takeLatest(EDIT_PAYOUT_CHARGE, editPayoutCharge);
}

export function* fetchDefaultPayoutChargesDefault() {
  yield takeLatest(FETCH_DEFAULT_PAYOUT_CHARGES, fetchDefaultPayoutCharges)
}
export function* addDefaultPayoutChargeDefault() {
  yield takeLatest(ADD_DEFAULT_PAYOUT_CHARGE, addDefaultPayoutCharge);
}
export function* deleteDefaultPayoutChargeDefault() {
  yield takeLatest(DELETE_DEFAULT_PAYOUT_CHARGE, deleteDefaultPayoutCharge);
}
export function* editDefaultPayoutChargeDefault() {
  yield takeLatest(EDIT_DEFAULT_PAYOUT_CHARGE, editDefaultPayoutCharge);
}
export default function* rootSaga() {
  yield all([
    fork(fetchPayoutChargesDefault),
    fork(addPayoutChargeDefault),
    fork(deletePayoutChargeDefault),
    fork(editPayoutChargeDefault),

    fork(fetchDefaultPayoutChargesDefault),
    fork(addDefaultPayoutChargeDefault),
    fork(deleteDefaultPayoutChargeDefault),
    fork(editDefaultPayoutChargeDefault),
  ]);
}
