import {
  ADD_QUEUED_REPORT_SUCCESS,
  DELETE_QUEUED_REPORT_SUCCESS,
  FETCH_ATTENDANCE_REPORT_SUCCESS,
  FETCH_GUARD_REGISTER_REPORT_SUCCESS,
  FETCH_GUARD_SCHEDULE_REPORT_SUCCESS, FETCH_HOURS_OR_WAGES_REPORT_SUCCESS,
  FETCH_QUEUED_REPORTS_SUCCESS, QUEUED_REPORT_REFRESHED_SUCCESS
} from "../../actions/attendance/AttendanceReportActions";


const INIT_STATE ={
  queuedReportsByID:[],//queuedReportsByID is no longer indexed from the API so adapt the reducer appropriately
  guardSchedulesByID:{},
  guardRegisterReport:[],
  attendanceReportPresentData:{},
  attendanceReportNotReportedData:{},
  attendanceReportDayOffData:{},
  attendanceReportLeaveData:{},
  attendanceReportHolidayData:{},
  attendanceStatistics:{},
  hoursReport:{},
  wagesReport:{},
  hoursAndWagesReport:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_QUEUED_REPORTS_SUCCESS:
      return {
        ...state,
        queuedReportsByID:action.payload
      };
    case QUEUED_REPORT_REFRESHED_SUCCESS:
      let queuedReports = [...state.queuedReportsByID];
      let reports = queuedReports.map(report=>{
         return (report.id === action.payload.id)?action.payload:report;
      });
      return {
        ...state,
        queuedReportsByID:reports
      };
    case DELETE_QUEUED_REPORT_SUCCESS:
      let queuedReportsByID = {...state.queuedReportsByID};
      delete queuedReportsByID[action.payload.id];
      return {
        ...state,
        queuedReportsByID:queuedReportsByID
      };
    case ADD_QUEUED_REPORT_SUCCESS:
      let id = action.payload.id;
      return {
        ...state,
        queuedReportsByID:{
          ...state.queuedReportsByID,
        [id]:action.payload}
      };
    case FETCH_GUARD_SCHEDULE_REPORT_SUCCESS:
      return{
        ...state,
        guardSchedulesByID: action.payload
      };
    case FETCH_GUARD_REGISTER_REPORT_SUCCESS:
      return{
        ...state,
        guardRegisterReport:action.payload
      };
    case FETCH_ATTENDANCE_REPORT_SUCCESS:
      let employee_state = action.payload.employee_state;
      switch (employee_state) {
        case 'Present':
          return {
            ...state,
            attendanceReportPresentData:action.payload.report,
            attendanceStatistics: action.payload.statistics
          };
        case 'Not_Reported':
          return {
            ...state,
            attendanceReportNotReportedData:action.payload.report,
            attendanceStatistics: action.payload.statistics
          };
        case 'On_Day_Off':
          return {
            ...state,
            attendanceReportDayOffData:action.payload.report,
            attendanceStatistics: action.payload.statistics
          };
        case 'On_Leave':
          return {
            ...state,
            attendanceReportLeaveData:action.payload.report,
            attendanceStatistics: action.payload.statistics
          };
        case 'On_Holiday':
          return {
            ...state,
            attendanceReportHolidayData:action.payload.report,
            attendanceStatistics: action.payload.statistics
          };
        default:
          return state;
      }
    case FETCH_HOURS_OR_WAGES_REPORT_SUCCESS:
      let report_type = action.payload.report_type;
      switch (report_type) {
        case 'HOURS':
          return {
            ...state,
            hoursReport: action.payload.report
          };
        case 'WAGES':
          return {
            ...state,
            wagesReport: action.payload.report
          };
        case 'HOURS_AND_WAGES':
          return {
            ...state,
            hoursAndWagesReport: action.payload.report
          };
        default:
          return state;
      }
    default:
      return state;
  }
}
