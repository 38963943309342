export const  FETCH_QUEUED_REPORTS = 'FETCH_QUEUED_REPORTS';
export const QUEUED_REPORT_REFRESHED_SUCCESS = 'QUEUED_REPORT_REFRESHED_SUCCESS';
export const  FETCH_QUEUED_REPORTS_SUCCESS = 'FETCH_QUEUED_REPORTS_SUCCESS';
export const DELETE_QUEUED_REPORT="DELETE_QUEUED_REPORT";
export const DELETE_QUEUED_REPORT_SUCCESS="DELETE_QUEUED_REPORT_SUCCESS";
export const  ADD_QUEUED_REPORT_SUCCESS = 'ADD_QUEUED_REPORT_SUCCESS';
export const  GENERATE_ATTENDANCE_REPORT = 'GENERATE_ATTENDANCE_REPORT';
export const FETCH_GUARD_SCHEDULE_REPORT ='FETCH_GUARD_SCHEDULE_REPORT';
export const FETCH_GUARD_SCHEDULE_REPORT_SUCCESS ='FETCH_GUARD_SCHEDULE_REPORT_SUCCESS';
export const FETCH_GUARD_REGISTER_REPORT = 'FETCH_GUARD_REGISTER_REPORT';
export const FETCH_GUARD_REGISTER_REPORT_SUCCESS = 'FETCH_GUARD_REGISTER_REPORT_SUCCESS';

export const GENERATE_GUARD_REGISTER_REPORT = 'GENERATE_GUARD_REGISTER_REPORT';
export const EXPORT_ATTENDANCE_REPORT = 'EXPORT_ATTENDANCE_REPORT';
export const FETCH_ATTENDANCE_PRESENT_REPORT = 'FETCH_ATTENDANCE_PRESENT_REPORT';
export const FETCH_ATTENDANCE_NOT_REPORTED_REPORT = 'FETCH_ATTENDANCE_NOT_REPORTED_REPORT';
export const FETCH_ATTENDANCE_ON_DAY_OFF_REPORT = 'FETCH_ATTENDANCE_ON_DAY_OFF_REPORT';
export const FETCH_ATTENDANCE_ON_LEAVE_REPORT = 'FETCH_ATTENDANCE_ON_LEAVE_REPORT';
export const FETCH_ATTENDANCE_REPORT_SUCCESS = 'FETCH_ATTENDANCE_REPORT_SUCCESS';
export const FETCH_HOURS_REPORT = "FETCH_HOURS_REPORT";
export const FETCH_WAGES_REPORT = "FETCH_WAGES_REPORT";
export const FETCH_HOURS_AND_WAGES_REPORT = "FETCH_HOURS_AND_WAGES_REPORT";
export const FETCH_HOURS_OR_WAGES_REPORT_SUCCESS = "FETCH_HOURS_OR_WAGES_REPORT_SUCCESS";

export const APPROVE_DAILY_OVERTIME = "APPROVE_DAILY_OVERTIME";
export const APPROVE_DAILY_OVERTIME_SUCCESS = "APPROVE_DAILY_OVERTIME_SUCCESS";

export const APPROVE_ATTENDANCE_REPORT = "APPROVE_ATTENDANCE_REPORT";
export const APPROVE_ATTENDANCE_REPORT_SUCCESS = "APPROVE_ATTENDANCE_REPORT_SUCCESS";


export const generateGuardRegisterReportGenerator = (data)=>{
   return {
     type:GENERATE_GUARD_REGISTER_REPORT,
     payload:data
   }
};
export const fetchQueuedReportsGenerator = (data=[])=>{
  return {
    type:FETCH_QUEUED_REPORTS,
    payload:data
  };
};
export const fetchQueuedReportsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_QUEUED_REPORTS_SUCCESS,
    payload:dataById
  }
};
export const queuedReportRefreshedSuccessGenerator = (data)=> {
  return {
    type:QUEUED_REPORT_REFRESHED_SUCCESS,
    payload:data
  }
};

export const deleteQueuedReportGenerator = (data=[])=>{
  return {
    type:DELETE_QUEUED_REPORT,
    payload:data
  };
};
export const deleteQueuedReportSuccessGenerator = (dataById)=> {
  return {
    type:DELETE_QUEUED_REPORT_SUCCESS,
    payload:dataById
  }
};

export const generateAttendanceReportGenerator = (data=[])=>{
  return {
    type:GENERATE_ATTENDANCE_REPORT,
    payload:data
  };
};
export const addQueuedReportSuccessGenerator = (dataById)=> {
  return {
    type:ADD_QUEUED_REPORT_SUCCESS,
    payload:dataById
  }
};
export const fetchGuardScheduleReportGenerator =(data=[])=>{
  return {
    type:FETCH_GUARD_SCHEDULE_REPORT,
    payload:data
  }
};
export const fetchGuardScheduleReportSuccessGenerator =(data)=>{
  return {
    type:FETCH_GUARD_SCHEDULE_REPORT_SUCCESS,
    payload:data
  }
};
export const fetchGuardRegisterReportGenerator =(data=[])=>{
  return {
    type:FETCH_GUARD_REGISTER_REPORT,
    payload:data
  }
};
export const fetchGuardRegisterSuccessReportGenerator =(data)=>{
  return {
    type:FETCH_GUARD_REGISTER_REPORT_SUCCESS,
    payload:data
  }
};
export const exportAttendanceReportGenerator =(data)=>{
  return {
    type:EXPORT_ATTENDANCE_REPORT,
    payload:data
  }
};
export const fetchAttendancePresentReportGenerator = (data=[])=>{
  return {
    type:FETCH_ATTENDANCE_PRESENT_REPORT,
    payload:data
  };
};
export const fetchAttendanceNotReportedReportGenerator = (data=[])=>{
  return {
    type:FETCH_ATTENDANCE_NOT_REPORTED_REPORT,
    payload:data
  };
};
export const fetchAttendanceOnDayOffReportGenerator = (data=[])=>{
  return {
    type:FETCH_ATTENDANCE_ON_DAY_OFF_REPORT,
    payload:data
  };
};
export const fetchAttendanceOnLeaveReportGenerator = (data=[])=>{
  return {
    type:FETCH_ATTENDANCE_ON_LEAVE_REPORT,
    payload:data
  };
};
export const fetchAttendanceReportSuccessGenerator = (data)=> {
  return {
    type:FETCH_ATTENDANCE_REPORT_SUCCESS,
    payload:data
  }
};
export const fetchHoursReportGenerator = (data)=> {
  return {
    type:FETCH_HOURS_REPORT,
    payload:data
  }
};
export const fetchWagesReportGenerator = (data)=> {
  return {
    type:FETCH_WAGES_REPORT,
    payload:data
  }
};
export const fetchHoursAndWagesReportGenerator = (data)=> {
  return {
    type:FETCH_HOURS_AND_WAGES_REPORT,
    payload:data
  }
};
export const fetchHoursOrWagesReportSuccessGenerator = (data)=> {
  return {
    type:FETCH_HOURS_OR_WAGES_REPORT_SUCCESS,
    payload:data
  }
};
export const approveDailyOvertimeGenerator =(data=[])=>{
  return {
    type:APPROVE_DAILY_OVERTIME,
    payload:data
  }
};
export const approveDailyOvertimeSuccessGenerator =(data)=>{
  return {
    type:APPROVE_DAILY_OVERTIME_SUCCESS,
    payload:data
  }
};
export const approveAttendanceReportGenerator =(data=[])=>{
  return {
    type:APPROVE_ATTENDANCE_REPORT,
    payload:data
  }
};
export const approveAttendanceReportSuccessGenerator =(data)=>{
  return {
    type:APPROVE_ATTENDANCE_REPORT_SUCCESS,
    payload:data
  }
};
