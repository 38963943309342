import {
  all, call, fork, put
} from 'redux-saga/effects';
import axios from 'axios';
import {takeLatest} from 'redux-saga/effects';
import {Helpers as userData, Helpers} from '../../../util/Helpers';
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from '../../actions/AuthActions';
import { API_ENDPOINT } from '../../../constants/ActionTypes';
import {
  ACCEPT_TOP_UP_SUBSCRIBE,
  ADD_SUBSCRIPTION,
  addSubscriptionSuccessGenerator,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_PACKAGE,
  fetchSubscriptionGenerator,
  fetchSubscriptionPackageSuccessGenerator,
  fetchSubscriptionSuccessGenerator,
  GET_TRIAL_PERIOD,
  getTrialPeriodSuccessGenerator,
  RENEW_SUBSCRIPTION, SEND_NOTIFICATION_ON_UPGRADE_SUBSCRIPTION, TOP_UP_AND_SUBSCRIBE,
  UPGRADE_SUBSCRIPTION
} from "../../actions/common/SubscriptionActions";
import { SUBSCRIPTION_KEY } from "../../../constants/SessionKeys";
import { push } from 'connected-react-router';

const fetchSubscriptionRequest = async request => await axios.get(`${API_ENDPOINT}/home/subscription`, {params: request})
  .then(response => response).catch(error => error.response);
const fetchSubscriptionPackageRequest = async request => await axios.get(`${API_ENDPOINT}/home/get/package/employees/${request.noOfEmployees}`)
  .then(response => response).catch(error => error.response);
const addSubscriptionRequest = async request => await axios.get(`${API_ENDPOINT}/home/subscribe/${request.plan_id}/${request.plan_type}`, {params: request})
  .then(response => response).catch(error => error.response);
const getTrialPeriodRequest = async request => await axios.post(`${API_ENDPOINT}/home/subscription/get/trial`, request)
  .then(response => response).catch(error => error.response);

const renewSubscriptionRequest = async request => await axios.post(`${API_ENDPOINT}/home/renew/subscription`, request)
  .then(response => response).catch(error => error.response);

const upgradeSubscriptionRequest = async request => await axios.post(`${API_ENDPOINT}/home/upgrade/subscription`, request)
  .then(response => response).catch(error => error.response);
const sendNotificationOnUpgradeRequest =async request =>await axios.post(`${API_ENDPOINT}/home/upgrade-notify-admin`,request)
  .then(response=>response).catch(error=>error.response);
const topUpSubscriptionRequest = async request => await axios.post(`${API_ENDPOINT}/home/subscription-topup `,request)
  .then(response=>response).catch(error=>error.response)
const acceptTopUpSubscriptionRequest = async request => await axios.post(`${API_ENDPOINT}/home/confirm-subscription-topup`,request)
  .then(response=>response).catch(error=>error.response)

function* acceptTopUpSubscription({type, payload}) {
  if (Helpers.isLoggedIn()) {
    yield put(showAppLoaderGenerator(type));
    try {
      const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
      const response = yield call(acceptTopUpSubscriptionRequest, request);
      if (response.status === 201 || response.status === 200) {
        if (response.data.success) {
          yield put(push('/home'))
          yield put(showMessageGenerator(response.data.message,'success'));
        }else{
          yield put(showMessageGenerator(response.data.message,'error'));
        }
      } else if (response.status === 401) {
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
      } else {
        yield put(showMessageGenerator(response.statusText,'error'));
      }
    } catch (e) {
      yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
  }
}
function* topUpSubscription({type, payload}) {
  if (Helpers.isLoggedIn()) {
    yield put(showAppLoaderGenerator(type));
    try {
      const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
      const response = yield call(topUpSubscriptionRequest, request);
      if (response.status === 201 || response.status === 200) {
        if (response.data.success) {
          yield put(push('/home'))
          yield put(showMessageGenerator(response.data.message,'success'));
        }else{
          yield put(showMessageGenerator(response.data.message,'error'));
        }
      } else if (response.status === 401) {
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
      } else {
        yield put(showMessageGenerator(response.statusText,'error'));
      }
    } catch (e) {
      yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
  }
}
function* sendNotificationOnUpgrade({type, payload}) {
  if (Helpers.isLoggedIn()) {
    yield put(showAppLoaderGenerator(type));
    try {
      const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
      const response = yield call(sendNotificationOnUpgradeRequest, request);
      if (response.status === 201 || response.status === 200) {
        if (response.data.success) {
          yield put(push('/home'))
          yield put(showMessageGenerator(response.data.message,'success'));
        }else{
          yield put(showMessageGenerator(response.data.message,'error'));
        }
      } else if (response.status === 401) {
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
      } else {
        yield put(showMessageGenerator(response.statusText,'error'));
      }
    } catch (e) {
      yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
  }
}
function* upgradeSubscription({type, payload}) {
  if (Helpers.isLoggedIn()) {
    yield put(showAppLoaderGenerator(type));
    try {
      const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
      const response = yield call(upgradeSubscriptionRequest, request);
      if (response.status === 201 || response.status === 200) {
        if (response.data.success) {
          yield put(showMessageGenerator(response.statusText,'success'));
          let obj = response.data.data;
          if (obj){
            if (obj.hasOwnProperty('id')){
              Helpers.saveItem(SUBSCRIPTION_KEY,obj);
            }else{
              Helpers.saveItem(SUBSCRIPTION_KEY,Object.values(obj));
            }
          }
          yield put(push('/hrm/employees'))

        }else{
          yield put(showMessageGenerator(response.data.message,'error'));
        }
      } else if (response.status === 401) {
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
      } else {
        yield put(showMessageGenerator(response.statusText,'error'));
      }
    } catch (e) {
      yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
  }

}

function* renewSubscription({type, payload}) {
  if (Helpers.isLoggedIn()) {
    yield put(showAppLoaderGenerator(type));
    try {
      const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
      const response = yield call(renewSubscriptionRequest, request);
      if (response.status === 201 || response.status === 200) {
        if (response.data.success) {
          yield put(fetchSubscriptionGenerator());
          // yield put(push('/home'));
          // window.open( 'https://dashboard.myworkpay.com/login?utm_source=legacy&utm_content='+Helpers.token()+'&company_id='+Helpers.authSwitchedCompanyID(), '_self');
          yield put(showMessageGenerator(response.data.message,'success'));
        }else{
          yield put(showMessageGenerator(response.data.message,'error'));
        }
      } else if (response.status === 401) {
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
      } else {
        yield put(showMessageGenerator(response.statusText,'error'));
      }
    } catch (e) {
      yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
  }

}

function* fetchSubscription({type, payload}) {
  if (Helpers.isLoggedIn()) {
    yield put(showAppLoaderGenerator(type));
    try {
      const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
      const response = yield call(fetchSubscriptionRequest, request);
      if (response.status === 201 || response.status === 200) {
        if (response.data.success) {
          Helpers.saveItem(SUBSCRIPTION_KEY,response.data.data.subscription);
          yield put(fetchSubscriptionSuccessGenerator(response.data.data));
        }
      } else if (response.status === 401) {
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
      } else {
        yield put(showMessageGenerator(response.statusText,'error'));
      }
    } catch (e) {
      yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
  }

}
function* fetchSubscriptionPackage({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const request = {...payload};
    const response = yield call(fetchSubscriptionPackageRequest, request);
    if (response.status === 201 || response.status === 200) {
      if (response.data.success) {
        yield put(fetchSubscriptionPackageSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addSubscription({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
    const response = yield call(addSubscriptionRequest, request);
    if (response.status === 201 || response.status === 200) {
      if (response.data.success) {
        let  data = response.data.data;
        yield put(addSubscriptionSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
        Helpers.saveItem(SUBSCRIPTION_KEY,data);

        amplitude.getInstance().logEvent('get subscription');

        yield put(push("/home"));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* getTrialPeriod({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const request = {...payload, auth_company_id: Helpers.authSwitchedCompanyID(), token: Helpers.token()};
    const response = yield call(getTrialPeriodRequest, request);
    if (response.status === 201 || response.status === 200) {
      if (response.data.success) {
        let  data = response.data.data;
        yield put(getTrialPeriodSuccessGenerator(data));

        //amplitude.getInstance().logEvent('new 14');
        amplitude.getInstance().logEvent('get trial period');

        yield put(showMessageGenerator(response.data.message,'success'));
        Helpers.saveItem(SUBSCRIPTION_KEY,data);
        yield put(push("/home"));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* getTrialPeriodDefault() {
  yield takeLatest(GET_TRIAL_PERIOD,getTrialPeriod);
}
function* fetchSubscriptionDefault() {
  yield takeLatest(FETCH_SUBSCRIPTION, fetchSubscription);
} function* addSubscriptionDefault() {
  yield takeLatest(ADD_SUBSCRIPTION, addSubscription);
}
function* fetchSubscriptionPackageDefault() {
  yield takeLatest(FETCH_SUBSCRIPTION_PACKAGE, fetchSubscriptionPackage);
}
function* upgradeSubscriptionDefault() {
  yield takeLatest(UPGRADE_SUBSCRIPTION, upgradeSubscription);
}
function* renewSubscriptionDefault() {
  yield takeLatest(RENEW_SUBSCRIPTION, renewSubscription);
}
function* sendNotificationOnUpgradeDefault() {
  yield takeLatest(SEND_NOTIFICATION_ON_UPGRADE_SUBSCRIPTION, sendNotificationOnUpgrade);
}
function* acceptTopUpSubscriptionDefault() {
  yield takeLatest(TOP_UP_AND_SUBSCRIBE, acceptTopUpSubscription);
}
function* topUpSubscriptionDefault() {
  yield takeLatest(ACCEPT_TOP_UP_SUBSCRIBE, topUpSubscription);
}
export default function* rootSaga() {
  yield all([
    fork(fetchSubscriptionDefault),
    fork(fetchSubscriptionPackageDefault),
    fork(addSubscriptionDefault),
    fork(getTrialPeriodDefault),
    fork(upgradeSubscriptionDefault),
    fork(renewSubscriptionDefault),
    fork(sendNotificationOnUpgradeDefault),
    fork(acceptTopUpSubscriptionDefault),
    fork(topUpSubscriptionDefault),
  ]);
}
