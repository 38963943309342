export const FETCH_EMPLOYEE_INVOICES = "FETCH_EMPLOYEE_INVOICES";
export const FETCH_EMPLOYEE_INVOICES_SUCCESS = "FETCH_EMPLOYEE_INVOICES_SUCCESS";
export const ADD_EMPLOYEE_INVOICE = "ADD_EMPLOYEE_INVOICE";
export const ADD_EMPLOYEE_INVOICES_SUCCESS = "ADD_EMPLOYEE_INVOICES_SUCCESS";
export const EDIT_EMPLOYEE_INVOICES = "EDIT_EMPLOYEE_INVOICES";
export const EDIT_EMPLOYEE_INVOICES_SUCCESS = "EDIT_EMPLOYEE_INVOICES_SUCCESS";
export const DELETE_EMPLOYEE_INVOICES = "DELETE_EMPLOYEE_INVOICES";
export const DELETE_EMPLOYEE_INVOICES_SUCCESS = "DELETE_EMPLOYEE_INVOICES_SUCCESS";

export const fetchEmployeeInvoicesGenerator = (data=[])=>{
  return {
    type:FETCH_EMPLOYEE_INVOICES,
    payload:data
  };
};

export const fetchEmployeeInvoicesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_EMPLOYEE_INVOICES_SUCCESS,
    payload:dataById
  }
};

export const addAEmployeeInvoicesGenerator = (data)=>{
  return {
    type: ADD_EMPLOYEE_INVOICE,
    payload: data
  }
};

export const addEmployeeInvoicesSuccessGenerator = (data)=>{
  return {
    type: ADD_EMPLOYEE_INVOICES_SUCCESS,
    payload: data
  }
};

export const editEmployeeInvoicesGenerator = (data)=>{
  return {
    type: EDIT_EMPLOYEE_INVOICES,
    payload: data
  }
};
export const editEmployeeInvoicesSuccessGenerator = (data)=>{
  return {
    type: EDIT_EMPLOYEE_INVOICES_SUCCESS,
    payload: data
  }
};

export const deleteEmployeeInvoicesGenerator = (data)=>{
  return {
    type: DELETE_EMPLOYEE_INVOICES,
    payload: data
  }
};
export const deleteEmployeeInvoicesSuccessGenerator = (data)=>{
  return {
    type: DELETE_EMPLOYEE_INVOICES_SUCCESS,
    payload: data
  }
};
