import {
  ADD_DEFAULT_PAYOUT_CHARGE_SUCCESS,
  ADD_PAYOUT_CHARGE_SUCCESS, DELETE_DEFAULT_PAYOUT_CHARGE_SUCCESS,
  DELETE_PAYOUT_CHARGE_SUCCESS, EDIT_DEFAULT_PAYOUT_CHARGE_SUCCESS,
  EDIT_PAYOUT_CHARGE_SUCCESS, FETCH_DEFAULT_PAYOUT_CHARGES, FETCH_DEFAULT_PAYOUT_CHARGES_SUCCESS,
  FETCH_PAYOUT_CHARGES_SUCCESS
} from "../../actions/payouts/PayoutChargeActions";

const INIT_STATE = {
  payoutChargesByID:{},
  defaultPayoutChargesByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PAYOUT_CHARGES_SUCCESS:
      return {
        ...state,
        payoutChargesByID: action.payload
      };
    case ADD_PAYOUT_CHARGE_SUCCESS:
    case EDIT_PAYOUT_CHARGE_SUCCESS:
      let id = action.payload.id;
      return {
        ...state,
        payoutChargesByID: {...state.payoutChargesByID, [id]:action.payload}
      };
    case DELETE_PAYOUT_CHARGE_SUCCESS:
      let payoutChargesByID = {...state.payoutChargesByID};
      delete payoutChargesByID[action.payload.id];
      return {
        ...state,
        payoutChargesByID: payoutChargesByID
      };


    case FETCH_DEFAULT_PAYOUT_CHARGES_SUCCESS:
      return {
        ...state,
        defaultPayoutChargesByID: action.payload
      };
    case ADD_DEFAULT_PAYOUT_CHARGE_SUCCESS:
    case EDIT_DEFAULT_PAYOUT_CHARGE_SUCCESS:
      let id_ = action.payload.id;
      return {
        ...state,
        defaultPayoutChargesByID: {...state.defaultPayoutChargesByID, [id_]:action.payload}
      };
    case DELETE_DEFAULT_PAYOUT_CHARGE_SUCCESS:
      let defaultPayoutChargesByID = {...state.defaultPayoutChargesByID};
      delete defaultPayoutChargesByID[action.payload.id];
      return {
        ...state,
        defaultPayoutChargesByID: defaultPayoutChargesByID
      };

    default:
      return state;
  }
}
