export const ADD_COMPANY_RATE ="ADD_COMPANY_RATE";
export const ADD_COMPANY_RATE_SUCCESS ="ADD_COMPANY_RATE_SUCCESS";

export const FETCH_COMPANY_RATE ="FETCH_COMPANY_RATE";
export const FETCH_COMPANY_RATE_SUCCESS ="FETCH_COMPANY_RATE_SUCCESS";

export const EDIT_COMPANY_RATE ="EDIT_COMPANY_RATE";
export const EDIT_COMPANY_RATE_SUCCESS ="EDIT_COMPANY_RATE_SUCCESS";
export const SHOW_COMPANY_RATE_EDIT_FORM ="SHOW_COMPANY_RATE_EDIT_FORM";
export const HIDE_COMPANY_RATE_EDIT_FORM ="HIDE_COMPANY_RATE_EDIT_FORM";

export const DELETE_COMPANY_RATE ="EDIT_COMPANY_DELETE";
export const DELETE_COMPANY_RATE_SUCCESS ="EDIT_COMPANY_DELETE_SUCCESS";
export const SHOW_COMPANY_RATE_DELETE_FORM ="SHOW_COMPANY_RATE_DELETE_FORM";
export const HIDE_COMPANY_RATE_DELETE_FORM ="HIDE_COMPANY_RATE_DELETE_FORM";

export const SHOW_COMPANY_RATE_CREATE_FORM ="SHOW_COMPANY_RATE_CREATE_FORM";
export const HIDE_COMPANY_RATE_CREATE_FORM ="HIDE_COMPANY_RATE_CREATE_FORM";

export const showCompanyRateDeleteFormGenerator=(data)=>{
    return{
        type:SHOW_COMPANY_RATE_DELETE_FORM,
        payload:data
    }
};
export const hideCompanyRateDeleteFormGenerator=()=>{
    return{
        type:HIDE_COMPANY_RATE_DELETE_FORM,
    }
};
export const fetchCompanyRateGenerator = (data=[]) =>{
     return {
          type:FETCH_COMPANY_RATE,
          payload:data
     }
};

export const fetchCompanyRateSuccessGenerator = (data) =>{
     return {
          type:FETCH_COMPANY_RATE_SUCCESS,
          payload:data
     }
};
export const addCompanyRateGenerator =(data) =>{
     return {
          type:ADD_COMPANY_RATE,
          payload:data
     }
};

export const addCompanyRateSuccessGenerator = (data) => {
     return {
          type:ADD_COMPANY_RATE_SUCCESS,
          payload:data
     }
};
export const editCompanyRateGenerator = (data) =>{
     return {
          type:EDIT_COMPANY_RATE,
          payload:data
     }
};
export const editCompanyRateSuccessGenerator = (data) =>{
     return {
          type:EDIT_COMPANY_RATE_SUCCESS,
          payload:data
     }
};
export const deleteCompanyRateGenerator = (data) =>{
     return {
          type:DELETE_COMPANY_RATE,
          payload:data
     }
};
export const deleteCompanyRateSuccessGenerator = (data) =>{
     return {
          type:DELETE_COMPANY_RATE_SUCCESS,
          payload:data
     }
};
export const showCompanyRateCreateFormGenerator =() =>{
     return {
          type:SHOW_COMPANY_RATE_CREATE_FORM
     }
};
export const showCompanyRateEditFormGenerator =(data) =>{
    return {
        type:SHOW_COMPANY_RATE_EDIT_FORM,
        payload:data
    }
};
export const hideCompanyRateEditFormGenerator =() =>{
    return {
        type:HIDE_COMPANY_RATE_EDIT_FORM
    }
};
export const hideCompanyRateCreateFormGenerator =() =>{
    return {
        type:HIDE_COMPANY_RATE_CREATE_FORM
    }
};