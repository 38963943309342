import {
    CREATE_EMPLOYEE_KPI_SUCCESS,
    DELETE_EMPLOYEE_KPI_SUCCESS,
    EDIT_EMPLOYEE_KPI_SUCCESS,
    FETCH_EMPLOYEE_KPI_SUCCESS
} from '../../actions/appraisal/EmployeeKPIActions';

const INIT_STATE = {
  EmployeeKPIByID:{}
};

export default (state=INIT_STATE,action)=>{
    let employeeKPI = {...state.EmployeeKPIByID};
    switch (action.type) {
        case FETCH_EMPLOYEE_KPI_SUCCESS:
            return {
                ...state,
                EmployeeKPIByID:action.payload
            };
        case CREATE_EMPLOYEE_KPI_SUCCESS:
        case EDIT_EMPLOYEE_KPI_SUCCESS:
            employeeKPI[action.payload.id]=action.payload;
            return {
                ...state,
                EmployeeKPIByID:employeeKPI
            };
        case DELETE_EMPLOYEE_KPI_SUCCESS:
            delete employeeKPI[action.payload.id];
            return {
                ...state,
                EmployeeKPIByID:employeeKPI
            };
        default: return {...state};
    }
}
