import {
  ADD_ADDITION_CATEGORIES_SUCCESS,
  DELETE_ADDITION_CATEGORIES,
  EDIT_ADDITION_CATEGORIES_SUCCESS, FETCH_ADDITION_CAR_CC_BAND_CATEGORIES_SUCCESS,
  FETCH_ADDITION_CATEGORIES_SUCCESS,
  HIDE_ACTIVATE_ADDITION_CATEGORY_FORM,
  HIDE_CREATE_ADDITION_CATEGORIES_FORM,
  HIDE_DEACTIVATE_ADDITION_CATEGORY_FORM,
  HIDE_DELETE_ADDITION_CATEGORIES_MODAL,
  HIDE_EDIT_ADDITION_CATEGORIES_FORM,
  SHOW_ACTIVATE_ADDITION_CATEGORY_FORM,
  SHOW_CREATE_ADDITION_CATEGORIES_FORM,
  SHOW_DEACTIVATE_ADDITION_CATEGORY_FORM,
  SHOW_DELETE_ADDITION_CATEGORIES_MODAL,
  SHOW_EDIT_ADDITION_CATEGORIES_FORM
} from "../../actions/payroll/AdditionalCategoriesActions";

const INIT_STATE = {
  additionCategoriesByID:[],
  additionsCarCCID:[],
  showAdditionCategoryCreateForm:false,
  additionCategoryToEdit:[],
  showAdditionCategoryEditForm:false,
  showAdditionCategoryDeleteModal:false,
  showActivateAdditionCategoryModal:false,
  showDeactivateAdditionCategoryModal:false
};
export default (state = INIT_STATE, action)=>{
  let addition_categories =[];
  switch (action.type){
    case ADD_ADDITION_CATEGORIES_SUCCESS:
    case EDIT_ADDITION_CATEGORIES_SUCCESS:
      addition_categories = {...state.additionCategoriesByID};
      addition_categories[action.payload.id]=action.payload;
      return {
        ...state,
        additionCategoriesByID:addition_categories
      };
    case SHOW_CREATE_ADDITION_CATEGORIES_FORM:
      return {
        ...state,
        showAdditionCategoryCreateForm:true
      };
    case HIDE_CREATE_ADDITION_CATEGORIES_FORM:
      return {
        ...state,
        showAdditionCategoryCreateForm:false
      };
    case FETCH_ADDITION_CAR_CC_BAND_CATEGORIES_SUCCESS:
      return {
        ...state,
        additionsCarCCID:action.payload
      };
    case FETCH_ADDITION_CATEGORIES_SUCCESS:
      return {
        ...state,
        additionCategoriesByID:action.payload
      };
    case SHOW_EDIT_ADDITION_CATEGORIES_FORM:
      return {
        ...state,
        additionCategoryToEdit:action.payload,
        showAdditionCategoryEditForm:true
      };
    case HIDE_EDIT_ADDITION_CATEGORIES_FORM:
      return{
        ...state,
        showAdditionCategoryEditForm:false
      };
    case SHOW_DELETE_ADDITION_CATEGORIES_MODAL:
      return {
        ...state,
        additionCategoryToEdit:action.payload,
        showAdditionCategoryDeleteModal:true
      };
    case HIDE_DELETE_ADDITION_CATEGORIES_MODAL:
      return {
        ...state,
        showAdditionCategoryDeleteModal:false
      };
    case DELETE_ADDITION_CATEGORIES:
      addition_categories = {...state.additionCategoriesByID};
      delete addition_categories[action.payload.id];
      return {
        ...state,
        additionCategoriesByID:addition_categories
      };
    case SHOW_DEACTIVATE_ADDITION_CATEGORY_FORM:
      return {
        ...state,
        additionCategoryToEdit:action.payload,
        showDeactivateAdditionCategoryModal:true
      };
    case HIDE_DEACTIVATE_ADDITION_CATEGORY_FORM:
      return {
        ...state,
        showDeactivateAdditionCategoryModal:false
      };
      case SHOW_ACTIVATE_ADDITION_CATEGORY_FORM:
      return {
        ...state,
        additionCategoryToEdit:action.payload,
        showActivateAdditionCategoryModal:true
      };
    case HIDE_ACTIVATE_ADDITION_CATEGORY_FORM:
      return {
        ...state,
        showActivateAdditionCategoryModal:false
      };
    default:
       return state;

  }
}
