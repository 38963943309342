import {
  ADD_BULK_EMPLOYEES_SUCCESS,
  ADD_EMPLOYEE_SUCCESS,

  DELETE_EMPLOYEE_SUCCESS,
  FETCH_DYNAMIC_OFF_DAYS_SUCCESS,
  FETCH_EMPLOYEE_SHARES_SUCCESS,
  FETCH_EMPLOYEES_ADMIN,
  FETCH_EMPLOYEES_ADMIN_SUCCESS,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_PENDING_EMPLOYEES_SUCCESS,

  HARD_DELETE_EMPLOYEE_SUCCESS,
  HIDE_BULK_EMPLOYEES_ADD_FORM,
  HIDE_CREATE_EMPLOYEE_FORM,
  HIDE_EMPLOYEE_DELETE_FORM,
  HIDE_INVITE_EMPLOYEES_FORM,
  HIDE_RESET_EMPLOYEE_PASSWORD_FORM,
  HIDE_SEND_EMPLOYEE_CREDENTIALS,
  HIDE_TRANSFER_EMPLOYEE_FORM,
  HIDE_UPDATE_EMPLOYEE_FORM,
  INVITE_EMPLOYEES_SUCCESS,
  REST_EMPLOYEE_PASSWORD_SUCCESS,
  SEND_CREDENTIALS_SUCCESS,
  SET_EMPLOYEE_STATUS_SUCCESS,
  SHOW_BULK_EMPLOYEES_ADD_FORM,
  SHOW_CREATE_EMPLOYEE_FORM,
  SHOW_EMPLOYEE_DELETE_FORM,
  SHOW_INVITE_EMPLOYEES_FORM,
  SHOW_RESET_EMPLOYEE_PASSWORD_FORM,
  SHOW_SEND_EMPLOYEE_CREDENTIALS,
  SHOW_TRANSFER_EMPLOYEE_FORM,
  SHOW_UPDATE_EMPLOYEE_FORM,
  TRANSFER_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_SUCCESS
} from "../../actions/payroll/EmployeeActions";

const INIT_STATE ={
  employeesByID:{},
  employeePermissions:{},
  showCreateEmployeeForm:false,
  employeeToEdit:[],
  showUpdateEmployeeForm:false,
  employeeToDelete:[],
  showDeleteEmployeeForm:false,
  showTransferEmployeeForm:false,
  showBulkEmployeesAddForm:false,
  showInviteEmployeesForm:false,
  showSendEmployeeCredentialsForm:false,
  showResetEmployeePasswordForm:false,
  dynamicOffDays:{},
  pendingEmployeesByID:{},
  employeeSharesById:{}
};

export default (state=INIT_STATE,action)=>{
  let employees = {};
  let employeePermissions = {};
  switch (action.type) {
    case FETCH_PENDING_EMPLOYEES_SUCCESS:
      return{
        ...state,
        pendingEmployeesByID: action.payload
      };
    case FETCH_EMPLOYEES_SUCCESS:
    case FETCH_EMPLOYEES_ADMIN_SUCCESS:
      if(action.payload.hasOwnProperty('employees')){
        employees = action.payload.employees;
      }
      if(action.payload.hasOwnProperty('permission')){
        employeePermissions = action.payload.permission;
      }
      return{
        ...state,
        employeesByID: employees,
        employeePermissions: employeePermissions
      };
    case ADD_EMPLOYEE_SUCCESS:
    case UPDATE_EMPLOYEE_SUCCESS:
    case TRANSFER_EMPLOYEE_SUCCESS:
    case SET_EMPLOYEE_STATUS_SUCCESS:
      employees ={...state.employeesByID};
      employees[action.payload.id] =action.payload;
      return{
        ...state,
        employeesByID:employees
      };
    case SHOW_CREATE_EMPLOYEE_FORM:
      return{
        ...state,
        showCreateEmployeeForm:true
      };
    case HIDE_CREATE_EMPLOYEE_FORM:
      return{
        ...state,
        showCreateEmployeeForm:false
      };
    case FETCH_EMPLOYEE_SHARES_SUCCESS:
      return{
        ...state,
        employeeSharesById: action.payload
      };
    case SHOW_UPDATE_EMPLOYEE_FORM:
      return{
        ...state,
        showUpdateEmployeeForm: true,
        employeeToEdit: action.payload
      };
    case HIDE_UPDATE_EMPLOYEE_FORM:
      return{
        ...state,
        showUpdateEmployeeForm:false
      };
    case SHOW_EMPLOYEE_DELETE_FORM:
      return {
        ...state,
        showDeleteEmployeeForm: true,
        employeeToDelete: action.payload
      };
    case HIDE_EMPLOYEE_DELETE_FORM:
      return {
        ...state,
        showDeleteEmployeeForm:false
      };
    case DELETE_EMPLOYEE_SUCCESS:
      let employees ={...state.employeesByID};
      delete employees[action.payload.id];
      return{
        ...state,
        employeesByID:employees
      };
    case SHOW_TRANSFER_EMPLOYEE_FORM:
      return {
        ...state,
        employeesByID:action.payload,
        showTransferEmployeeForm:true,
      };
    case HIDE_TRANSFER_EMPLOYEE_FORM:
      return {
        ...state,
        showTransferEmployeeForm:false,
        employeesByID:action.payload,
      };
    case SHOW_BULK_EMPLOYEES_ADD_FORM:
      return {
        ...state,
        showBulkEmployeesAddForm: true
      };
    case HIDE_BULK_EMPLOYEES_ADD_FORM:
      return {
        ...state,
        showBulkEmployeesAddForm: false
      };
    case ADD_BULK_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesByID:Object.assign(...state.employeesByID, action.payload)
      };
    ////
    case SEND_CREDENTIALS_SUCCESS:
      employees = {...state.employeesByID};
      employees[action.payload.id] = action.payload;
      return {
        ...state,
        employeesByID:employees
      };
    case REST_EMPLOYEE_PASSWORD_SUCCESS:
      return {
        ...state,
        employeeToEdit:action.payload
      };
    case INVITE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesByID:action.payload
      };
    case SHOW_INVITE_EMPLOYEES_FORM:
      return {
        ...state,
        showInviteEmployeesForm:true
      };
    case HIDE_INVITE_EMPLOYEES_FORM:
      return {
        ...state,
        showInviteEmployeesForm:false
      };
    case SHOW_SEND_EMPLOYEE_CREDENTIALS:
      return {
        ...state,
        showSendEmployeeCredentialsForm:true
      };
    case HIDE_SEND_EMPLOYEE_CREDENTIALS:
      return {
        ...state,
        showSendEmployeeCredentialsForm:false
      };
    case SHOW_RESET_EMPLOYEE_PASSWORD_FORM:
      return {
        ...state,
        showResetEmployeePasswordForm:true
      };
    case HIDE_RESET_EMPLOYEE_PASSWORD_FORM:
      return {
        ...state,
        showResetEmployeePasswordForm:false
      };
    case HARD_DELETE_EMPLOYEE_SUCCESS:
      let employeesByID ={...state.employeesByID};
      delete employeesByID[action.payload.id];
      return{
        ...state,
        employeesByID:employeesByID
      };
    case FETCH_DYNAMIC_OFF_DAYS_SUCCESS:
      return{
        ...state,
        dynamicOffDays:action.payload
      };
    default:
      return state;

  }
}
