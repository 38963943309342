import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS,
  UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS,
  fetchAdminSalaryAdvanceDefaultSettingsGenerator,
  fetchAdminSalaryAdvanceDefaultSettingsSuccessGenerator
} from "../../actions/admin/SalaryAdvancesDefaultSettingsAction";

const fetchAdminSalaryAdvanceDefaultSettingsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/default-salary-advances',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editAdminSalaryAdvanceDefaultSettingsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/default-salary-advances/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchAdminSalaryAdvanceDefaultSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAdminSalaryAdvanceDefaultSettingsRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchAdminSalaryAdvanceDefaultSettingsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editAdminSalaryAdvanceDefaultSettings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAdminSalaryAdvanceDefaultSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAdminSalaryAdvanceDefaultSettingsGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchAdminSalaryAdvanceDefaultSettingsDefault() {
  yield takeLatest(FETCH_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS, fetchAdminSalaryAdvanceDefaultSettings)
}
export function* editAdminSalaryAdvanceDefaultSettingsDefault() {
  yield takeLatest(UPDATE_ADMIN_SALARY_ADVANCE_DEFAULT_SETTINGS, editAdminSalaryAdvanceDefaultSettings);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAdminSalaryAdvanceDefaultSettingsDefault),
    fork(editAdminSalaryAdvanceDefaultSettingsDefault)
  ]);
}
