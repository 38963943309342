import {all, call, fork, put} from "redux-saga/effects";
import {
    ADD_DEDUCTION, BULK_DEACTIVATE_DEDUCTION, BULK_DEDUCTION,
    CANCEL_DEDUCTION,
    DELETE_DEDUCTION,
    EDIT_DEDUCTION,
    FETCH_DEDUCTIONS,
    FETCH_SAVINGS, fetchDeductionsGenerator,
    fetchDeductionsSuccessGenerator, fetchSavingsGenerator,
    fetchSavingsSuccessGenerator
} from "../../actions/payroll/DeductionActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";

import {API_ENDPOINT} from "../../../constants/ActionTypes";

const bulkDeactivateDeductionRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/deductions/deactivate/bulk',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const UploadDeductionRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/deductions/upload/bulk',requestParameters,{headers: {
            'content-type': `multipart/form-data`
        }})
        .then(response => response)
        .catch(error => error.response);

const fetchDeductionsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/hrm/deductions',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addDeductionRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/deductions',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteDeductionRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/deductions/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const cancelDeductionRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/deductions/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editDeductionRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/hrm/deductions/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);
const fetchSavingsRequest =async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/hrm/savings',{params:requestParameters})
    .then(response =>response)
    .catch(error=>error.response);


function* bulkDeactivateDeduction({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(bulkDeactivateDeductionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                if(requestParameters.ACTION === "SAVING"){
                    yield put(fetchSavingsGenerator());
                }else{
                    yield put(fetchDeductionsGenerator());
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* UploadDeduction({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const formData = Helpers.getFormData(requestParameters);
        const response = yield call(UploadDeductionRequest, formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let rolesById = response.data.data;
                if(requestParameters.type ==="SAVING"){
                    yield put(fetchSavingsGenerator());
                }else{
                    yield put(fetchDeductionsGenerator());
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchSavings({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchSavingsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let savingsById = response.data.data;
                yield put(fetchSavingsSuccessGenerator(savingsById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchDeductions({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchDeductionsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchDeductionsSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addDeduction({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addDeductionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let role = response.data.data;
                if(requestParameters.type ==="SAVING"){
                    yield put(fetchSavingsGenerator(payload.params));
                }else{
                    yield put(fetchDeductionsGenerator(payload.params));
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* cancelDeduction({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(cancelDeductionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                //yield put(cancelDeductionSuccessGenerator(role));
                if(role.type === 'SAVING'){
                    yield put(fetchSavingsGenerator(payload.params));
                }else{
                    yield put(fetchDeductionsGenerator(payload.params));
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* deleteDeduction({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteDeductionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                //let role = response.data.data;
                //yield put(deleteDeductionSuccessGenerator(role));
                yield put(fetchDeductionsGenerator(payload.params));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editDeduction({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editDeductionRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                //yield put(editDeductionSuccessGenerator(role));
                if(role.type === 'SAVING'){
                    yield put(fetchSavingsGenerator(payload.params));
                }else{
                    yield put(fetchDeductionsGenerator(payload.params));
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* bulkDeactivateDeductionDefault(){
    yield takeLatest(BULK_DEACTIVATE_DEDUCTION,bulkDeactivateDeduction);
}
export function* fetchDeductionsDefault() {
    yield takeLatest(FETCH_DEDUCTIONS, fetchDeductions)
}
export function* fetchSavingsDefault() {
    yield takeLatest(FETCH_SAVINGS, fetchSavings)
}
export function* addDeductionDefault() {
    yield takeLatest(ADD_DEDUCTION, addDeduction);
}
export function* deleteDeductionDefault() {
    yield takeLatest(DELETE_DEDUCTION, deleteDeduction);
}
export function* cancelDeductionDefault() {
    yield takeLatest(CANCEL_DEDUCTION, cancelDeduction);
}

export function* editDeductionDefault() {
    yield takeLatest(EDIT_DEDUCTION, editDeduction);
}

export function* UploadDeductionDefault() {
    yield takeLatest(BULK_DEDUCTION, UploadDeduction);
}

export default function* rootSaga() {
    yield all([
        fork(fetchDeductionsDefault),
        fork(fetchSavingsDefault),
        fork(addDeductionDefault),
        fork(deleteDeductionDefault),
        fork(editDeductionDefault),
        fork(UploadDeductionDefault),
        fork(cancelDeductionDefault),
        fork(bulkDeactivateDeductionDefault)
    ]);
}
