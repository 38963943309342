export const FETCH_EXCHANGE_RATES = "FETCH_EXCHANGE_RATES";
export const FETCH_EXCHANGE_RATES_SUCCESS = "FETCH_EXCHANGE_RATES_SUCCESS";

export const ADD_EXCHANGE_RATES = "ADD_EXCHANGE_RATES";
export const ADD_EXCHANGE_RATES_SUCCESS = "ADD_EXCHANGE_RATES_SUCCESS";

export const UPDATE_EXCHANGE_RATES = "UPDATE_EXCHANGE_RATES";
export const UPDATE_EXCHANGE_RATES_SUCCESS = "UPDATE_EXCHANGE_RATES_SUCCESS";

export const DELETE_EXCHANGE_RATES = "DELETE_EXCHANGE_RATES";
export const DELETE_EXCHANGE_RATES_SUCCESS = "DELETE_EXCHANGE_RATES_SUCCESS";

export const UPLOAD_EXCHANGE_RATES = "UPLOAD_EXCHANGE_RATES";

export const fetchExchangeRatesGenerator = (data=[])=>{
  return {
    type:FETCH_EXCHANGE_RATES,
    payload:data
  };
};

export const fetchExchangeRatesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_EXCHANGE_RATES_SUCCESS,
    payload:dataById
  }
};

export const addExchangeRatesGenerator = (data)=>{
  return {
    type: ADD_EXCHANGE_RATES,
    payload: data
  }
};

export const addExchangeRatesSuccessGenerator = (data)=>{
  return {
    type: ADD_EXCHANGE_RATES_SUCCESS,
    payload: data
  }
};

export const editExchangeRatesGenerator = (data)=>{
  return {
    type: UPDATE_EXCHANGE_RATES,
    payload: data
  }
};

export const editExchangeRatesSuccessGenerator = (data)=>{
  return {
    type: UPDATE_EXCHANGE_RATES_SUCCESS,
    payload: data
  }
};

export const deleteExchangeRatesGenerator = (data)=>{
  return {
    type: DELETE_EXCHANGE_RATES,
    payload: data
  }
};

export const deleteExchangeRatesSuccessGenerator = (data)=>{
  return {
    type: DELETE_EXCHANGE_RATES_SUCCESS,
    payload: data
  }
};

export const uploadExchangeRatesGenerator = data => {
  return {
    type: UPLOAD_EXCHANGE_RATES,
    payload: data
  };
};
