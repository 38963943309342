import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_LOAN_REQUEST,
  DISAPPROVE_LOAN_REQUESTS,
  FETCH_LOAN_REQUESTS, fetchLoanRequestsGenerator,
  fetchLoanRequestsSuccessGenerator
} from "../../actions/admin/LoanRequestActions";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import {
  DELETE_ADMIN_LOAN,
  EDIT_ADMIN_LOAN,
  fetchCompanyLoansGenerator
} from "../../actions/admin/CompanyLoansActions";

const fetchLoanRequestsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/requests',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const disapproveLoanRequestsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/requests/disapprove',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const createLoanRequestRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/requests/create',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const editCompanyLoanRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/edit',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const deleteCompanyLoanRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/admin/company/loan/delete',requestData)
        .then(response=>response)
        .catch(error=>error.response);

function* editCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editCompanyLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //const loan = response.data.data;
        //yield put(editLoanSuccessGenerator(loan));
        yield put(showMessageGenerator(response.data.message,'success'));
        if(window.location.pathname === '/admin/companies-loans'){
          yield put(fetchCompanyLoansGenerator());
        }else{
          yield put(fetchLoanRequestsGenerator());
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteCompanyLoan({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(deleteCompanyLoanRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        if(window.location.pathname === '/admin/loan-requests'){
          yield put(fetchLoanRequestsGenerator());
        }else{
          yield put(fetchCompanyLoansGenerator());
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLoanRequests({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoanRequestsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(fetchLoanRequestsSuccessGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* disapproveLoanRequests({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(disapproveLoanRequestsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLoanRequestsGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* createLoanRequest({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(createLoanRequestRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLoanRequestsGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchLoanRequestsDefault() {
  yield takeLatest(FETCH_LOAN_REQUESTS, fetchLoanRequests);
}

export function* disapproveLoanRequestsDefault() {
  yield takeLatest(DISAPPROVE_LOAN_REQUESTS, disapproveLoanRequests);
}
export function* createLoanRequestDefault() {
  yield takeLatest(CREATE_LOAN_REQUEST, createLoanRequest);
}
export function* editCompanyLoanDefault() {
  yield takeLatest(EDIT_ADMIN_LOAN,editCompanyLoan);
}
export function* deleteCompanyLoanDefault() {
  yield takeLatest(DELETE_ADMIN_LOAN,deleteCompanyLoan);
}
export default function* rootSaga() {
  yield all([fork(fetchLoanRequestsDefault),
    fork(disapproveLoanRequestsDefault),
    fork(createLoanRequestDefault),
      fork(editCompanyLoanDefault),
      fork(deleteCompanyLoanDefault)
  ]);
}
