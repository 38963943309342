import React from "react";
import CustomLoader from "../components/common/CustomLoader";
import withRouter from "react-router/es/withRouter";
import Link from "@material-ui/core/Link";

const LoadingComponent = (props) => {
  if (props.error) {
    return <div  className="profile-container">
      <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
        <div className="page-error-content">
          <div className="error-code animated zoomInDown" style={{fontSize:'40px'}}>Network Error</div>
          <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
            Network error, please click retry below
          </h2>
          <div className="text-center zoomIn animation-delay-20 animated">
            <Link className="btn btn-default retry-container" href="/home">Retry</Link>
          </div>
        </div>
      </div>
    </div>;
  } else{
    return <div  className="profile-container">
      <div className="loader-view">
        <CustomLoader />
      </div>
    </div>;
  }
};
export default withRouter(LoadingComponent);
