export const FETCH_LOAN_CATEGORIES = "FETCH_LOAN_CATEGORIES";
export const FETCH_LOAN_CATEGORIES_SUCCESS = "FETCH_LOAN_CATEGORIES_SUCCESS";
export const SHOW_LOAN_CATEGORY_CREATE_FORM = 'SHOW_LOAN_CATEGORY_CREATE_FORM';
export const HIDE_LOAN_CATEGORY_CREATE_FORM = 'HIDE_LOAN_CATEGORY_CREATE_FORM';
export const ADD_LOAN_CATEGORY = 'ADD_LOAN_CATEGORY';
export const ADD_LOAN_CATEGORY_SUCCESS = 'ADD_LOAN_CATEGORY_SUCCESS';
export const SHOW_LOAN_CATEGORY_EDIT_FORM = 'SHOW_LOAN_CATEGORY_EDIT_FORM';
export const HIDE_LOAN_CATEGORY_EDIT_FORM = 'HIDE_LOAN_CATEGORY_EDIT_FORM';
export const EDIT_LOAN_CATEGORY = 'EDIT_LOAN_CATEGORY';
export const EDIT_LOAN_CATEGORY_SUCCESS = 'EDIT_LOAN_CATEGORY_SUCCESS';
export const SHOW_LOAN_CATEGORY_DELETE_FORM = 'SHOW_LOAN_CATEGORY_DELETE_FORM';
export const HIDE_LOAN_CATEGORY_DELETE_FORM = 'HIDE_LOAN_CATEGORY_DELETE_FORM';
export const DELETE_LOAN_CATEGORY = 'DELETE_LOAN_CATEGORY';
export const DELETE_LOAN_CATEGORY_SUCCESS = 'DELETE_LOAN_CATEGORY_SUCCESS';

export const fetchLoanCategoriesGenerator = (data=[])=>{
  return {
    type:FETCH_LOAN_CATEGORIES,
    payload:data
  };
};

export const fetchLoanCategoriesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_LOAN_CATEGORIES_SUCCESS,
    payload:dataById
  }
};

export const showLoanCategoryCreateFormGenerator = ()=>{
  return {
    type:SHOW_LOAN_CATEGORY_CREATE_FORM
  };
};
export const hideLoanCategoryCreateFormGenerator = ()=>{
  return {
    type:HIDE_LOAN_CATEGORY_CREATE_FORM
  };
};
export const addLoanCategoryGenerator = (data)=>{
  return {
    type: ADD_LOAN_CATEGORY,
    payload: data
  }
};

export const addLoanCategorySuccessGenerator = (data)=>{
  return {
    type: ADD_LOAN_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showLoanCategoryEditFormGenerator = (data)=>{
  return {
    type:SHOW_LOAN_CATEGORY_EDIT_FORM,
    payload:data
  };
};
export const hideLoanCategoryEditFormGenerator = ()=>{
  return {
    type:HIDE_LOAN_CATEGORY_EDIT_FORM
  };
};
export const editLoanCategoryGenerator = (data)=>{
  return {
    type: EDIT_LOAN_CATEGORY,
    payload: data
  }
};
export const editLoanCategorySuccessGenerator = (data)=>{
  return {
    type: EDIT_LOAN_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showLoanCategoryDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_LOAN_CATEGORY_DELETE_FORM,
    payload:data
  };
};
export const hideLoanCategoryDeleteFormGenerator = ()=>{
  return {
    type:HIDE_LOAN_CATEGORY_DELETE_FORM
  };
};
export const deleteLoanCategoryGenerator = (data)=>{
  return {
    type: DELETE_LOAN_CATEGORY,
    payload: data
  }
};
export const deleteLoanCategorySuccessGenerator = (data)=>{
  return {
    type: DELETE_LOAN_CATEGORY_SUCCESS,
    payload: data
  }
};
