import KeyResultReducer from "./KeyResultReducer";
import DepartmentKPIReducer from "./DepartmentKPIReducer";
import EmployeeKPIReducer from "./EmployeeKPIReducer";
import ReviewPeriodReducer from "./ReviewPeriodReducer";
import SupervisorLevelAppraisalReducer from "./SupervisorLevelAppraisalReducer";
import SelfAppraisalReducer from "./SelfAppraisalReducer";
import PerformanceRatingReducer from "./PerformanceRatingReducer";
import PerformanceImprovementProgrammeReducer from "./PerformanceImprovementProgrammeReducer";

const Appraisal = {
    keyResult:KeyResultReducer,
    departmentKPI:DepartmentKPIReducer,
    employeeKPI:EmployeeKPIReducer,
    reviewPeriods:ReviewPeriodReducer,
    supervisorLevelAppraisal:SupervisorLevelAppraisalReducer,
    selfAppraisal:SelfAppraisalReducer,
    performanceRating:PerformanceRatingReducer,
    performanceImprovementProgramme:PerformanceImprovementProgrammeReducer,
};
export default Appraisal;
