export const FETCH_WITHHOLDING_TAX= "FETCH_WITHHOLDING_TAX";
export const FETCH_WITHHOLDING_TAX_SUCCESS = "FETCH_WITHHOLDING_TAX_SUCCESS";

export const SHOW_WITHHOLDING_TAX_EDIT_FORM = 'SHOW_WITHHOLDING_TAX_EDIT_FORM';
export const HIDE_WITHHOLDING_TAX_EDIT_FORM = 'HIDE_WITHHOLDING_TAX_EDIT_FORM';
export const EDIT_WITHHOLDING_TAX = 'EDIT_WITHHOLDING_TAX';
export const EDIT_WITHHOLDING_TAX_SUCCESS = 'EDIT_WITHHOLDING_TAX_SUCCESS';
export const ADD_WITHHOLDING_TAX = 'ADD_WITHHOLDING_TAX';
export const ADD_WITHHOLDING_TAX_SUCCESS = 'ADD_WITHHOLDING_TAX_SUCCESS';
export const SHOW_WITHHOLDING_TAX_ADD_FORM = 'SHOW_WITHHOLDING_TAX_ADD_FORM';
export const HIDE_WITHHOLDING_TAX_ADD_FORM = 'HIDE_WITHHOLDING_TAX_ADD_FORM';
export const DELETE_WITHHOLDING_TAX = 'DELETE_WITHHOLDING_TAX';
export const DELETE_WITHHOLDING_TAX_SUCCESS = 'DELETE_WITHHOLDING_TAX_SUCCESS';
export const BULK_WITHHOLDING_TAX = 'BULK_WITHHOLDING_TAX_';
export const BULK_WITHHOLDING_TAX_SUCCESS = 'BULK_WITHHOLDING_TAX__SUCCESS';

export const fetchWithholdingTaxGenerator = (data=[])=>{
  return {
    type:FETCH_WITHHOLDING_TAX,
    payload:data
  };
};

export const fetchWithholdingTaxSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_WITHHOLDING_TAX_SUCCESS,
    payload:dataById
  }
};
export const showWithholdingTaxEditFormGenerator = (data)=>{
  return {
    type:SHOW_WITHHOLDING_TAX_EDIT_FORM,
    payload:data
  };
};
export const hideWithholdingTaxEditFormGenerator = ()=>{
  return {
    type:HIDE_WITHHOLDING_TAX_EDIT_FORM
  };
};
export const editWithholdingTaxGenerator = (data)=>{
  return {
    type: EDIT_WITHHOLDING_TAX,
    payload: data
  }
};
export const editWithholdingTaxSuccessGenerator = (data)=>{
  return {
    type: EDIT_WITHHOLDING_TAX_SUCCESS,
    payload: data
  }
};
export const addWithholdingTaxGenerator = (data)=>{
    return {
       type: ADD_WITHHOLDING_TAX,
       payload: data
    }
};
export const addWithholdingTaxGeneratorSuccess = (data)=>{
    return {
       type: ADD_WITHHOLDING_TAX_SUCCESS,
       payload: data
    }
};

export const showWithholdingTaxAddFormGenerator = (data)=>{
    return {
      type:SHOW_WITHHOLDING_TAX_ADD_FORM,
      payload:data
    };
  };

export const hideWithholdingTaxAddFormGenerator = ()=>{
return {
    type:HIDE_WITHHOLDING_TAX_ADD_FORM
};
};

export const deleteWithholdingTaxGenerator = (data)=>{
    return {
      type: DELETE_WITHHOLDING_TAX,
      payload: data
    }
  };
export const deleteWithholdingTaxSuccessGenerator = (data)=>{
return {
    type: DELETE_WITHHOLDING_TAX_SUCCESS,
    payload: data
}
};

export const bulkWithholdingTaxGenerator = (data)=>{
    return {
      type: BULK_WITHHOLDING_TAX,
      payload: data
    }
  };
  
export const bulkWithholdingTaxSuccessGenerator = (data)=>{
return {
    type: BULK_WITHHOLDING_TAX_SUCCESS,
    payload: data
}
};
