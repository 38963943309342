import {
  ADD_LEAVE_BALANCE_SUCCESS,
  DELETE_LEAVE_BALANCE,
  EDIT_LEAVE_BALANCE_SUCCESS,
  FETCH_EMPLOYEE_ENTITLED_LEAVES_SUCCESS,
  FETCH_EMPLOYEE_LEAVE_DAYS_SUCCESS,
  FETCH_LEAVE_BALANCE_SUCCESS,
  HIDE_CREATE_LEAVE_BALANCE_FORM,
  HIDE_DELETE_LEAVE_BALANCE_MODAL,
  HIDE_EDIT_LEAVE_BALANCE_FORM,
  SHOW_CREATE_LEAVE_BALANCE_FORM,
  SHOW_DELETE_LEAVE_BALANCE_MODAL,
  SHOW_EDIT_LEAVE_BALANCE_FORM
} from '../../actions/payroll/LeaveBalanceActions';


const INIT_STATE = {
  leaveBalancesByID:[],
  showLeaveBalanceCreateForm:false,
  leaveBalanceToEdit:[],
  showLeaveBalanceEditForm:false,
  showLeaveBalanceDeleteModal:false,
  showActivateLeaveBalanceModal:false,
  showDeactivateLeaveBalanceModal:false,
  leaveBalancesWithLeaveTypeId:[],
  employeeEntitledLeaveByID :{}
};
export default (state = INIT_STATE, action)=>{
  let leave_balances =[];
  switch (action.type){
    case ADD_LEAVE_BALANCE_SUCCESS:
    case EDIT_LEAVE_BALANCE_SUCCESS:
      leave_balances = {...state.leaveBalancesByID};
      leave_balances[action.payload.id]=action.payload;
      return {
        ...state,
        leaveBalancesByID:leave_balances
      };
    case FETCH_EMPLOYEE_LEAVE_DAYS_SUCCESS:
      return {
        ...state,
        leaveBalancesWithLeaveTypeId:action.payload
      };
      case FETCH_EMPLOYEE_ENTITLED_LEAVES_SUCCESS:
      return {
        ...state,
        employeeEntitledLeaveByID:action.payload
      };
    case SHOW_CREATE_LEAVE_BALANCE_FORM:
      return {
        ...state,
        showLeaveBalanceCreateForm:true
      };
    case HIDE_CREATE_LEAVE_BALANCE_FORM:
      return {
        ...state,
        showLeaveBalanceCreateForm:false
      };
    case FETCH_LEAVE_BALANCE_SUCCESS:
      return {
        ...state,
        leaveBalancesByID:action.payload
      };
    case SHOW_EDIT_LEAVE_BALANCE_FORM:
      return {
        ...state,
        leaveBalanceToEdit:action.payload,
        showLeaveBalanceEditForm:true
      };
    case HIDE_EDIT_LEAVE_BALANCE_FORM:
      return{
        ...state,
        showLeaveBalanceEditForm:false
      };
    case SHOW_DELETE_LEAVE_BALANCE_MODAL:
      return {
        ...state,
        leaveBalanceToEdit:action.payload,
        showLeaveBalanceDeleteModal:true
      };
    case HIDE_DELETE_LEAVE_BALANCE_MODAL:
      return {
        ...state,
        showLeaveBalanceDeleteModal:false
      };
    case DELETE_LEAVE_BALANCE:
      leave_balances = {...state.leaveBalancesByID};
      delete leave_balances[action.payload.id];
      return {
        ...state,
        leaveBalancesByID:leave_balances
      };
    default:
      return state;

  }
}
