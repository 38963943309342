import {
  ADD_DOCUMENT_CATEGORY_SUCCESS,
  DELETE_DOCUMENT_CATEGORY_SUCCESS,
  EDIT_DOCUMENT_CATEGORY_SUCCESS,
  FETCH_DOCUMENT_CATEGORY_SUCCESS,
  HIDE_DOCUMENT_CATEGORY_CREATE_FORM,
  HIDE_DOCUMENT_CATEGORY_DELETE_FORM,
  HIDE_DOCUMENT_CATEGORY_EDIT_FORM,
  SHOW_DOCUMENT_CATEGORY_CREATE_FORM,
  SHOW_DOCUMENT_CATEGORY_DELETE_FORM,
  SHOW_DOCUMENT_CATEGORY_EDIT_FORM
} from '../../actions/payroll/DocumentCategoryActions';

const INIT_STATE = {
  documentCategoriesByID:{},
  showDocumentCategoryCreateForm:false,
  showDocumentCategoryEditForm:false,
  documentCategoryToEdit:false,
  showDocumentCategoryDeleteForm:false,
  documentCategoryToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_DOCUMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        documentCategoriesByID: action.payload
      };
    case SHOW_DOCUMENT_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showDocumentCategoryCreateForm: true
      };
    case HIDE_DOCUMENT_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showDocumentCategoryCreateForm: false
      };
    case SHOW_DOCUMENT_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showDocumentCategoryEditForm: true,
        documentCategoryToEdit: action.payload
      };
    case HIDE_DOCUMENT_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showDocumentCategoryEditForm: false
      };
    case ADD_DOCUMENT_CATEGORY_SUCCESS:
    case EDIT_DOCUMENT_CATEGORY_SUCCESS:
      let documentCategory_id = action.payload.id;
      return {
        ...state,
        documentCategoriesByID: {
          ...state.documentCategoriesByID,
          [documentCategory_id]:action.payload
        }
      };
    case SHOW_DOCUMENT_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showDocumentCategoryDeleteForm: true,
        documentCategoryToDelete: action.payload
      };
    case HIDE_DOCUMENT_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showDocumentCategoryDeleteForm: false
      };
    case DELETE_DOCUMENT_CATEGORY_SUCCESS:
      let documentCategoriesByID = {...state.documentCategoriesByID};
      delete documentCategoriesByID[action.payload.id];
      return {
        ...state,
        documentCategoriesByID:documentCategoriesByID
      };
    default:
      return state;
  }
}
