import {
  ADD_BANK_BRANCH_SUCCESS,
  ADD_BANK_SUCCESS,
  DELETE_BANK_SUCCESS,
  EDIT_BANK_BRANCH_SUCCESS,
  EDIT_BANK_SUCCESS,
  FETCH_ACCOUNT_SETTINGS_SUCCESS, FETCH_ADMIN_SYSTEM_SETTINGS_SUCCESS,
  FETCH_BANKS_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_NHIF_RATES_SUCCESS,
  FETCH_NSSF_RATES_SUCCESS,
  FETCH_PAYE_RATES_SUCCESS,
  FETCH_SECTORS_SUCCESS, FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  SHOW_MESSAGE
} from "../actions/GeneralActions";
import {SIGNOUT_USER_SUCCESS} from '../../constants/ActionTypes';

const INIT_STATE = {
  appLoading: {},
  alertMessage: '',
  showMessage: 0,
  currenciesByID:{},
  countriesByID:{},
  banksByID:{},
  sectorsByID:{},
  nhifRatesById:{},
  nssfRatesById:{},
  payeRatesById:{} ,
  accountSettingsByID:{},
  adminSystemSettingsByID:{},
  subscriptionById :{}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_USER_SUCCESS://on sign out a message should be shown thus the use of this line
    case SHOW_MESSAGE: {
      let prevMessage = state.showMessage;//this concept ensures that the showMessage does not need to be turned on and off on incoming message and after display respectively; a numeric change is enough
      prevMessage++;
      if(prevMessage > 2000){
        prevMessage = 0;
      }
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: prevMessage,
      }
    }
    case FETCH_SUBSCRIPTION_PLANS_SUCCESS:{
      return {
        ...state,
        subscriptionById: action.payload
      };
    }
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currenciesByID: action.payload
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesByID: action.payload
      };
    case FETCH_BANKS_SUCCESS:
      return {
        ...state,
        banksByID: action.payload
      };
    case FETCH_SECTORS_SUCCESS:
      return {
        ...state,
        sectorsByID: action.payload
      };
    case FETCH_NHIF_RATES_SUCCESS:
      return {
        ...state,
        nhifRatesById: action.payload
      };
    case FETCH_NSSF_RATES_SUCCESS:
      return {
        ...state,
        nssfRatesById: action.payload
      };
    case FETCH_PAYE_RATES_SUCCESS:
      return {
        ...state,
        payeRatesById: action.payload
      };
    case ADD_BANK_SUCCESS:
    case EDIT_BANK_SUCCESS:
    case ADD_BANK_BRANCH_SUCCESS:
    case EDIT_BANK_BRANCH_SUCCESS:
      let banks = {...state.banksByID} ;
      banks[action.payload.id] = action.payload;
      return {
        ...state,
        banksByID:banks
      } ;
    case DELETE_BANK_SUCCESS:
      let bnk = {...state.banksByID};
      delete bnk[action.payload.id];
      return {
           ...state,
        banksByID:bnk
      };
    case SHOW_APP_LOADER:
      let actionTypeToShow = action.payload;
      if(actionTypeToShow){
        let appLoading = {...state.appLoading};
        appLoading[actionTypeToShow] = true;
        return {
          ...state,
          appLoading: appLoading
        };
      }
      return state;
    case HIDE_APP_LOADER:
      let actionTypeToHide = action.payload;
      if(actionTypeToHide){
        let appLoading = {...state.appLoading};
        delete appLoading[actionTypeToHide];
        return {
          ...state,
          appLoading: appLoading
        };
      }
      return state;
    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        accountSettingsByID: action.payload
      };
    case FETCH_ADMIN_SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        adminSystemSettingsByID: action.payload
      }
    default:
      return state;
  }

}
