import {
    FETCH_MPESA_SETTINGS_SUCCESS,
    UPDATE_MPESA_SETTING_SUCCESS
} from "../../actions/admin/MpesaSettingActions";

const INIT_STATE ={
     mpesaSettingsData :{}
} ;
export default (state=INIT_STATE,action) =>{
    switch (action.type) {
        case FETCH_MPESA_SETTINGS_SUCCESS:
        case UPDATE_MPESA_SETTING_SUCCESS:
            return{
                ...state,
                mpesaSettingsData: action.payload
            };
        default:
            return state;
    }
}