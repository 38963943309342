export const FETCH_LOAN_BOOK_BALANCE = 'FETCH_LOAN_BOOK_BALANCE';
export const FETCH_LOAN_BOOK_BALANCE_SUCCESS = 'FETCH_LOAN_BOOK_BALANCE_SUCCESS';

export const FETCH_LOANS_ISSUED = 'FETCH_LOANS_ISSUED';
export const FETCH_LOANS_ISSUED_SUCCESS = 'FETCH_LOANS_ISSUED_SUCCESS';

export const FETCH_OVERDUE_LOANS = 'FETCH_OVERDUE_LOANS';
export const FETCH_OVERDUE_LOANS_SUCCESS = 'FETCH_OVERDUE_LOANS_SUCCESS';

export const FETCH_TOP_BORROWERS = 'FETCH_TOP_BORROWERS';
export const FETCH_TOP_BORROWERS_SUCCESS = 'FETCH_TOP_BORROWERS_SUCCESS';

export const UPDATE_LOAN_DASHBOARD_YEAR = "UPDATE_LOAN_DASHBOARD_YEAR";
export const UPDATE_LOAN_DASHBOARD_YEAR_SUCCESS = "UPDATE_LOAN_DASHBOARD_YEAR_SUCCESS";

export const fetchLoanBookBalanceGenerator =(payload=[])=>{
  return {
    type:FETCH_LOAN_BOOK_BALANCE,
    payload
  }
};
export const fetchLoanBookBalanceSuccessGenerator =(payload=[])=>{
  return {
    type:FETCH_LOAN_BOOK_BALANCE_SUCCESS,
    payload
  }
};
export const fetchLoansIssuedGenerator =(payload=[])=>{
  return {
    type:FETCH_LOANS_ISSUED,
    payload
  }
};
export const fetchLoansIssuedSuccessGenerator =(payload=[])=>{
  return {
    type:FETCH_LOANS_ISSUED_SUCCESS,
    payload
  }
};
export const fetchOverdueLoansGenerator =(payload=[])=>{
  return {
    type:FETCH_OVERDUE_LOANS,
    payload
  }
};
export const fetchOverdueLoansSuccessGenerator =(payload=[])=>{
  return {
    type:FETCH_OVERDUE_LOANS_SUCCESS,
    payload
  }
};
export const fetchTopBorrowersGenerator =(payload=[])=>{
  return {
    type:FETCH_TOP_BORROWERS,
    payload
  }
};
export const fetchTopBorrowersSuccessGenerator =(payload=[])=>{
  return {
    type:FETCH_TOP_BORROWERS_SUCCESS,
    payload
  }
};
export const updateLoanDashboardYearGenerator =(payload=[])=>{
  return {
    type:UPDATE_LOAN_DASHBOARD_YEAR,
    payload
  }
};
export const updateLoanDashboardYearSuccessGenerator =(payload=[])=>{
  return {
    type:UPDATE_LOAN_DASHBOARD_YEAR_SUCCESS,
    payload
  }
};
