import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
    ADD_PRICING,
    addPricingSuccessGenerator, DELETE_PRICING, deletePricingSuccessGenerator, FETCH_PRICINGS,
    fetchPricingSuccessGenerator, UPDATE_PRICING, updatePricingSuccessGenerator
} from "../../actions/admin/PricingActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";

const fetchPricingsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/tozza/accounts/pricing',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addPricingRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/tozza/pricing/create',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deletePricingRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/tozza/pricing/delete',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const editPricingRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/tozza/pricing/update',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchPricings({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchPricingsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let pricingsByID = response.data.data;
                yield put(fetchPricingSuccessGenerator(pricingsByID));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addPricing({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(addPricingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(addPricingSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deletePricing({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(deletePricingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(deletePricingSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editPricing({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(editPricingRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(updatePricingSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchPricingsDefault() {
    yield takeLatest(FETCH_PRICINGS, fetchPricings)
}
export function* addPricingDefault() {
    yield takeLatest(ADD_PRICING, addPricing);
}
export function* deletePricingDefault() {
    yield takeLatest(DELETE_PRICING, deletePricing);
}
export function* editPricingDefault() {
    yield takeLatest(UPDATE_PRICING, editPricing);
}
export default function* rootSaga() {
    yield all([fork(fetchPricingsDefault),
        fork(addPricingDefault),
        fork(deletePricingDefault),
        fork(editPricingDefault)
    ]);
}
