import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
    ADD_KEY_RESULT,
    addKeyResultSuccessGenerator,
    DELETE_KEY_RESULT,
    deleteKeyResultSuccessGenerator,
    FETCH_KEY_RESULTS,
    fetchKeyResultGenerator,
    fetchKeyResultSuccessGenerator,
    UPDATE_KEY_RESULT,
    updateKeyResultSuccessGenerator
} from "../../actions/appraisal/KeyResultActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";

const fetchKeyResultsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/appraisal/key-results',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addKeyResultRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/appraisal/key-results',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteKeyResultRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/appraisal/key-results/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editKeyResultRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/appraisal/key-results/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchKeyResults({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchKeyResultsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchKeyResultSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addKeyResult({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addKeyResultRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                /*let role = response.data.data;
                yield put(addKeyResultSuccessGenerator(role));*/
                yield put(fetchKeyResultGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteKeyResult({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteKeyResultRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(deleteKeyResultSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editKeyResult({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(editKeyResultRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(updateKeyResultSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchKeyResultsDefault() {
    yield takeLatest(FETCH_KEY_RESULTS, fetchKeyResults)
}
export function* addKeyResultDefault() {
    yield takeLatest(ADD_KEY_RESULT, addKeyResult);
}
export function* deleteKeyResultDefault() {
    yield takeLatest(DELETE_KEY_RESULT, deleteKeyResult);
}
export function* editKeyResultDefault() {
    yield takeLatest(UPDATE_KEY_RESULT, editKeyResult);
}
export default function* rootSaga() {
    yield all([fork(fetchKeyResultsDefault),
        fork(addKeyResultDefault),
        fork(deleteKeyResultDefault),
        fork(editKeyResultDefault)
    ]);
}
