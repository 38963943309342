import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
    ADD_RELIEVERS,
    DELETE_RELIEVERS,
    deleteRelieversSuccessGenerator,
    FETCH_RELIEVERS,
    fetchRelieversGenerator,
    fetchRelieversSuccessGenerator,
    UPDATE_RELIEVERS,
    updateRelieversSuccessGenerator
} from "../../actions/attendance/RelieversActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {fetchProjectGenerator} from "../../actions/attendance/ProjectActions";

const fetchRelieversRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/attendance/relievers',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addRelieversRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/relievers',requestParameters)
        .then(response => response)
        .catch(error => error.response);


const deleteRelieversRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/relievers/delete',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const editRelieversRequest = async (requestParameters) =>{
    return await  axios.post(API_ENDPOINT+"/attendance/relievers/update",requestParameters)
        .then(response => response)
        .catch(error => error.response);
};
function* fetchRelievers({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchRelieversRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchRelieversSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addRelievers({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addRelieversRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectGenerator());
                /*yield put(fetchRelieversGenerator());*/
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteRelievers({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteRelieversRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectGenerator());
                yield put(deleteRelieversSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editRelievers({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editRelieversRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {

                yield put(updateRelieversSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchRelieversDefault() {
    yield takeLatest(FETCH_RELIEVERS, fetchRelievers)
}
export function* addRelieversDefault() {
    yield takeLatest(ADD_RELIEVERS, addRelievers);
}
export function* deleteRelieversDefault() {
    yield takeLatest(DELETE_RELIEVERS, deleteRelievers);
}
export function* editRelieversDefault() {
    yield takeLatest(UPDATE_RELIEVERS, editRelievers);
}
export default function* rootSaga() {
    yield all([fork(fetchRelieversDefault),
        fork(addRelieversDefault),
        fork(deleteRelieversDefault),
        fork(editRelieversDefault),
    ]);
}
