export const  FETCH_PROJECT = 'FETCH_PROJECT';
export const  FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const  DELETE_PROJECT="DELETE_PROJECT";
export const  DELETE_PROJECT_SUCCESS="DELETE_PROJECT_SUCCESS";
export const  ADD_PROJECT="ADD_PROJECT";
export const  ADD_PROJECT_SUCCESS="ADD_PROJECT_SUCCESS";
export const  ADD_BULK_PROJECT="ADD_BULK_PROJECT";
export const  ADD_BULK_PROJECT_SUCCESS="ADD_BULK_PROJECT_SUCCESS";
export const  UPDATE_PROJECT_SUCCESS="UPDATE_PROJECT_SUCCESS";
export const  UPDATE_PROJECT="UPDATE_PROJECT";

export const FETCH_EMPLOYEE_ASSIGNED_PROJECT ="FETCH_EMPLOYEE_ASSIGNED_PROJECT";
export const FETCH_EMPLOYEE_ASSIGNED_PROJECT_SUCCESS ="FETCH_EMPLOYEE_ASSIGNED_PROJECT_SUCCESS";

export const FETCH_PROJECT_BLACKLIST = "FETCH_PROJECT_BLACKLIST";
export const FETCH_PROJECT_BLACKLIST_SUCCESS = "FETCH_PROJECT_BLACKLIST_SUCCESS";
export const ADD_TO_PROJECT_BLACKLIST = "ADD_TO_PROJECT_BLACKLIST";
export const REMOVE_FROM_PROJECT_BLACKLIST = "REMOVE_FROM_PROJECT_BLACKLIST";

export const EXPORT_PROJECTS = "EXPORT_PROJECTS";

export const TERMINATE_PROJECT = "TERMINATE_PROJECT";
export const TERMINATE_PROJECT_SUCCESS = "TERMINATE_PROJECT_SUCCESS";
export const ACTIVATE_PROJECT = "ACTIVATE_PROJECT";
export const ACTIVATE_PROJECT_SUCCESS = "ACTIVATE_PROJECT_SUCCESS";
export const BULK_DELETE_PROJECTS = "BULK_DELETE_PROJECTS";

export const bulkDeleteProjectsGenerator = payload =>{
    return {
        type: BULK_DELETE_PROJECTS,
        payload
    }
}

export const fetchEmployeeAssignedProjectGenerator = (data=[])=>{
    return {
        type:FETCH_EMPLOYEE_ASSIGNED_PROJECT,
        payload:data
    };
};
export const fetchEmployeeAssignedProjectSuccessGenerator = (data)=>{
    return {
        type:FETCH_EMPLOYEE_ASSIGNED_PROJECT_SUCCESS,
        payload:data
    };
};
export const fetchProjectGenerator = (data=[])=>{
    return {
        type:FETCH_PROJECT,
        payload:data
    };
};

export const fetchProjectSuccessGenerator = (data)=> {
    return {
        type:FETCH_PROJECT_SUCCESS,
        payload:data
    }
};

export const deleteProjectGenerator = (data)=> {
    return {
        type:DELETE_PROJECT,
        payload:data
    }
};

export const deleteProjectSuccessGenerator = (data)=> {
    return {
        type:DELETE_PROJECT_SUCCESS,
        payload:data
    }
};

export const addProjectGenerator = (data)=> {
    return {
        type:ADD_PROJECT,
        payload:data
    }
};

export const addProjectSuccessGenerator = (data)=> {
    return {
        type:ADD_PROJECT_SUCCESS,
        payload:data
    }
};

export const addBulkProjectGenerator = (data)=> {
    return {
        type:ADD_BULK_PROJECT,
        payload:data
    }
};

export const addBulkProjectSuccessGenerator = (data)=> {
    return {
        type:ADD_BULK_PROJECT_SUCCESS,
        payload:data
    }
};

export const updateProjectGenerator =(data)=>{
    return {
        type:UPDATE_PROJECT,
        payload:data
    }
};

export const updateProjectSuccessGenerator =(data)=>{
    return {
        type:UPDATE_PROJECT_SUCCESS,
        payload:data
    }
};

export const fetchProjectBlacklistGenerator = (data=[])=>{
    return {
        type:FETCH_PROJECT_BLACKLIST,
        payload:data
    };
};

export const fetchProjectBlacklistSuccessGenerator = (data=[])=>{
    return {
        type:FETCH_PROJECT_BLACKLIST_SUCCESS,
        payload:data
    };
};

export const addToProjectBlacklistGenerator = (data=[])=>{
    return {
        type:ADD_TO_PROJECT_BLACKLIST,
        payload:data
    };
};

export const removeFromProjectBlacklistGenerator = (data=[])=>{
    return {
        type:REMOVE_FROM_PROJECT_BLACKLIST,
        payload:data
    };
};

export const exportProjectsGenerator = (data=[])=>{
    return {
        type:EXPORT_PROJECTS,
        payload:data
    };
};

export const terminateProjectsGenerator = (data=[])=>{
    return {
        type:TERMINATE_PROJECT,
        payload:data
    };
};
export const terminateProjectsSuccessGenerator = (data=[])=>{
    return {
        type:TERMINATE_PROJECT_SUCCESS,
        payload:data
    };
};
export const activateProjectsGenerator = (data=[])=>{
    return {
        type:ACTIVATE_PROJECT,
        payload:data
    };
};
export const activateProjectsSuccessGenerator = (data=[])=>{
    return {
        type:ACTIVATE_PROJECT_SUCCESS,
        payload:data
    };
};
