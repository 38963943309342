import {
    FETCH_CUSTOMER_STATEMENT_SUCCESS,
    SEND_CUSTOMER_STATEMENT_SUCCESS
} from "../../actions/admin/CustomerStatementActions";

const INIT_STATE = {
    customerStatements: []
};

export default(state = INIT_STATE,action)=> {
    switch (action.type) {
        case FETCH_CUSTOMER_STATEMENT_SUCCESS:
        case SEND_CUSTOMER_STATEMENT_SUCCESS:
            return {
                ...state,
                customerStatements: action.payload
            };
        default:
            return state;
    }
};
