import {
  ADD_PAID_LEAVE_SUCCESS,
  DELETE_PAID_LEAVE_SUCCESS,
  EDIT_PAID_LEAVE_SUCCESS,
  FETCH_PAID_LEAVES_SUCCESS,
  HIDE_PAID_LEAVE_CREATE_FORM,
  HIDE_PAID_LEAVE_DELETE_FORM,
  HIDE_PAID_LEAVE_EDIT_FORM,
  SHOW_PAID_LEAVE_CREATE_FORM,
  SHOW_PAID_LEAVE_DELETE_FORM,
  SHOW_PAID_LEAVE_EDIT_FORM
} from '../../actions/payroll/PaidLeaveActions';

const INIT_STATE = {
  paidLeavesByID:{},
  showPaidLeaveCreateForm:false,
  showPaidLeaveEditForm:false,
  paidLeaveToEdit:false,
  showPaidLeaveDeleteForm:false,
  paidLeaveToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PAID_LEAVES_SUCCESS:
      return {
        ...state,
        paidLeavesByID: action.payload
      };
    case SHOW_PAID_LEAVE_CREATE_FORM:
      return {
        ...state,
        showPaidLeaveCreateForm: true
      };
    case HIDE_PAID_LEAVE_CREATE_FORM:
      return {
        ...state,
        showPaidLeaveCreateForm: false
      };
    case SHOW_PAID_LEAVE_EDIT_FORM:
      return {
        ...state,
        showPaidLeaveEditForm: true,
        paidLeaveToEdit: action.payload
      };
    case HIDE_PAID_LEAVE_EDIT_FORM:
      return {
        ...state,
        showPaidLeaveEditForm: false
      };
    case ADD_PAID_LEAVE_SUCCESS:
    case EDIT_PAID_LEAVE_SUCCESS:
      let paidLeave_id = action.payload.id;
      return {
        ...state,
        paidLeavesByID: {
          ...state.paidLeavesByID,
          [paidLeave_id]:action.payload
        }
      };
    case SHOW_PAID_LEAVE_DELETE_FORM:
      return {
        ...state,
        showPaidLeaveDeleteForm: true,
        paidLeaveToDelete: action.payload
      };
    case HIDE_PAID_LEAVE_DELETE_FORM:
      return {
        ...state,
        showPaidLeaveDeleteForm: false
      };
    case DELETE_PAID_LEAVE_SUCCESS:
      let paidLeavesByID = {...state.paidLeavesByID};
      delete paidLeavesByID[action.payload.id];
      return {
        ...state,
        paidLeavesByID:paidLeavesByID
      };
    default:
      return state;
  }
}
