export const FETCH_PETTY_CASH_RECEIPT = "FETCH_PETTY_CASH_RECEIPT";
export const FETCH_PETTY_CASH_RECEIPT_SUCCESS = "FETCH_PETTY_CASH_RECEIPT_SUCCESS";
export const SHOW_PETTY_CASH_RECEIPT_CREATE_FORM = 'SHOW_PETTY_CASH_RECEIPT_CREATE_FORM';
export const HIDE_PETTY_CASH_RECEIPT_CREATE_FORM = 'HIDE_PETTY_CASH_RECEIPT_CREATE_FORM';
export const ADD_PETTY_CASH_RECEIPT = 'ADD_PETTY_CASH_RECEIPT';
export const ADD_PETTY_CASH_RECEIPT_SUCCESS = 'ADD_PETTY_CASH_RECEIPT_SUCCESS';
export const SHOW_PETTY_CASH_RECEIPT_EDIT_FORM = 'SHOW_PETTY_CASH_RECEIPT_EDIT_FORM';
export const HIDE_PETTY_CASH_RECEIPT_EDIT_FORM = 'HIDE_PETTY_CASH_RECEIPT_EDIT_FORM';
export const EDIT_PETTY_CASH_RECEIPT = 'EDIT_PETTY_CASH_RECEIPT';
export const EDIT_PETTY_CASH_RECEIPT_SUCCESS = 'EDIT_PETTY_CASH_RECEIPT_SUCCESS';
export const SHOW_PETTY_CASH_RECEIPT_DELETE_FORM = 'SHOW_PETTY_CASH_RECEIPT_DELETE_FORM';
export const HIDE_PETTY_CASH_RECEIPT_DELETE_FORM = 'HIDE_PETTY_CASH_RECEIPT_DELETE_FORM';
export const DELETE_PETTY_CASH_RECEIPT = 'DELETE_PETTY_CASH_RECEIPT';
export const DELETE_PETTY_CASH_RECEIPT_SUCCESS = 'DELETE_PETTY_CASH_RECEIPT_SUCCESS';

export const fetchPettyCashReceiptGenerator = (data=[])=>{
  return {
    type:FETCH_PETTY_CASH_RECEIPT,
    payload:data
  };
};

export const fetchPettyCashReceiptSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PETTY_CASH_RECEIPT_SUCCESS,
    payload:dataById
  }
};

export const showPettyCashReceiptCreateFormGenerator = ()=>{
  return {
    type:SHOW_PETTY_CASH_RECEIPT_CREATE_FORM
  };
};
export const hidePettyCashReceiptCreateFormGenerator = ()=>{
  return {
    type:HIDE_PETTY_CASH_RECEIPT_CREATE_FORM
  };
};
export const addPettyCashReceiptGenerator = (data)=>{
  return {
    type: ADD_PETTY_CASH_RECEIPT,
    payload: data
  }
};

export const addPettyCashReceiptSuccessGenerator = (data)=>{
  return {
    type: ADD_PETTY_CASH_RECEIPT_SUCCESS,
    payload: data
  }
};
export const showPettyCashReceiptEditFormGenerator = (data)=>{
  return {
    type:SHOW_PETTY_CASH_RECEIPT_EDIT_FORM,
    payload:data
  };
};
export const hidePettyCashReceiptEditFormGenerator = ()=>{
  return {
    type:HIDE_PETTY_CASH_RECEIPT_EDIT_FORM
  };
};
export const editPettyCashReceiptGenerator = (data)=>{
  return {
    type: EDIT_PETTY_CASH_RECEIPT,
    payload: data
  }
};
export const editPettyCashReceiptSuccessGenerator = (data)=>{
  return {
    type: EDIT_PETTY_CASH_RECEIPT_SUCCESS,
    payload: data
  }
};
export const showPettyCashReceiptDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_PETTY_CASH_RECEIPT_DELETE_FORM,
    payload:data
  };
};
export const hidePettyCashReceiptDeleteFormGenerator = ()=>{
  return {
    type:HIDE_PETTY_CASH_RECEIPT_DELETE_FORM
  };
};
export const deletePettyCashReceiptGenerator = (data)=>{
  return {
    type: DELETE_PETTY_CASH_RECEIPT,
    payload: data
  }
};
export const deletePettyCashReceiptSuccessGenerator = (data)=>{
  return {
    type: DELETE_PETTY_CASH_RECEIPT_SUCCESS,
    payload: data
  }
};
