
export const FETCH_FINGERPRINTS = 'FETCH_FINGERPRINTS';
export const FETCH_FINGERPRINTS_SUCCESS = 'FETCH_FINGERPRINTS_SUCCESS';

export const  DELETE_FINGERPRINTS="DELETE_FINGERPRINTS";
export const  DELETE_FINGERPRINTS_SUCCESS="DELETE_FINGERPRINTS_SUCCESS";

export const BULK_DELETE_FINGERPRINTS="BULK_DELETE_FINGERPRINTS";
export const BULK_DELETE_FINGERPRINTS_SUCCESS="BULK_DELETE_FINGERPRINTS_SUCCESS";

export const fetchFingerPrintsGenerator =(data)=>{
   return {
     type:FETCH_FINGERPRINTS,
     payload:data
   }
};

export const fetchFingerPrintsSuccessGenerator =(data)=>{
   return {
     type:FETCH_FINGERPRINTS_SUCCESS,
     payload:data
   }
};

export const deleteFingerPrintsGenerator = (data)=> {
    return {
        type:DELETE_FINGERPRINTS,
        payload:data
    }
};

export const deleteFingerPrintsSuccessGenerator = (data)=> {
    return {
        type:DELETE_FINGERPRINTS_SUCCESS,
        payload:data
    }
};

export const bulkDeleteFingerPrintsGenerator = (data)=> {
    return {
        type:BULK_DELETE_FINGERPRINTS,
        payload:data
    }
};

export const bulkDeleteFingerPrintsSuccessGenerator = (data)=> {
    return {
        type:BULK_DELETE_FINGERPRINTS_SUCCESS,
        payload:data
    }
};