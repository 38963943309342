import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  fullWidth: {
    width: '100%'
  },
  halfWidth: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '45%'
  },
  oneThirdWidth: {
    width: '30%'
  }
});

class CompanyLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  onUpdateFiles = (fileItems) => {
    this.setState({ files: fileItems.map(fileItem => fileItem.file) });
    let logo = {logo:this.state.files[0]};
    this.props.updateLogoState(logo);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <InputLabel style={{fontSize:12}}>Upload your company logo</InputLabel>
        <FilePond
          files={this.state.files}
          allowMultiple={false}
          maxFiles={1}
          onupdatefiles={this.onUpdateFiles} />
      </div>
    );
  }
}
export default withStyles(styles)(CompanyLogo);
