import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_PETTY_CASH,
  DELETE_PETTY_CASH,
  deletePettyCashSuccessGenerator,
  EDIT_PETTY_CASH,
  editPettyCashSuccessGenerator,
  EXPORT_FOR_ACCOUNTING,
  FETCH_PETTY_CASH,
  fetchPettyCashGenerator,
  fetchPettyCashSuccessGenerator,
  PAY_PETTY_CASH,
  payPettyCashGenerator
} from "../../actions/payroll/PettyCashActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { push } from 'connected-react-router';
import { fetchRecipientsSuccessGenerator } from "../../actions/payouts/BulkPayoutActions";


const fetchPettyCashRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/petty-cash',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const exportExpensesForAccountingRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/petty-cash/expense/export-accounting',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPettyCashRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePettyCashRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/petty-cash/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPettyCashRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/petty-cash/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const payPettyCashRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/make/payment',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPettyCash({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPettyCashRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchPettyCashSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* exportExpensesForAccounting({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(exportExpensesForAccountingRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        if(data.type && data.type==='url'){
          Helpers.openInNewTab(data.url);
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPettyCash({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addPettyCashRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let expense = response.data.data;
        /*yield put(addPettyCashSuccessGenerator(role));*/
        if(payload.hasOwnProperty('action') && payload.action === 'create-from-payouts'){
          yield put(payPettyCashGenerator({expense: expense.id}));
        }else{
          yield put(fetchPettyCashGenerator(payload.params));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePettyCash({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePettyCashRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        /*let role = response.data.data;
        yield put(deletePettyCashSuccessGenerator(role));*/
        yield put(fetchPettyCashGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPettyCash({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editPettyCashRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        /*let role = response.data.data;
        yield put(editPettyCashSuccessGenerator(role));*/
        yield put(showMessageGenerator(response.data.message,'success'));
        if(payload.action === 'approve_and_pay'){
          yield put(fetchRecipientsSuccessGenerator(response.data.data));
          yield put(push('/payouts/bulk-payouts'));
        }else{
          yield put(fetchPettyCashGenerator(payload.params));
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
        if(payload.action === 'approve_and_pay'){
          yield put(fetchPettyCashGenerator(payload.params));
        }
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* payPettyCash({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(payPettyCashRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchRecipientsSuccessGenerator(response.data.data));
        yield put(push('/payouts/bulk-payouts'));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPettyCashDefault() {
  yield takeLatest(FETCH_PETTY_CASH, fetchPettyCash)
}
export function* addPettyCashDefault() {
  yield takeLatest(ADD_PETTY_CASH, addPettyCash);
}
export function* deletePettyCashDefault() {
  yield takeLatest(DELETE_PETTY_CASH, deletePettyCash);
}
export function* editPettyCashDefault() {
  yield takeLatest(EDIT_PETTY_CASH, editPettyCash);
}
export function* payPettyCashDefault() {
  yield takeLatest(PAY_PETTY_CASH, payPettyCash);
}
export function* exportExpensesForAccountingDefault() {
  yield takeLatest(EXPORT_FOR_ACCOUNTING, exportExpensesForAccounting);
}
export default function* rootSaga() {
  yield all([fork(fetchPettyCashDefault),
    fork(addPettyCashDefault),
    fork(deletePettyCashDefault),
    fork(editPettyCashDefault),
    fork(payPettyCashDefault),
    fork(exportExpensesForAccountingDefault),
  ]);
}
