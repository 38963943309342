import { call, put,all,fork,takeLatest } from "redux-saga/effects";
import  axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  FETCH_FIREBASE_USERS,
  fetchFirebaseUsersSuccessGenerator,
  SEND_FIREBASE_BY_AUTH_VALUE,
  sendFirebaseByAuthValueSuccessGenerator
} from "../../actions/admin/MobileNotificationsAction";

const fetchFirebaseUsersRequest = async (requestData)=>
  await axios.get(API_ENDPOINT+"/get/registered/devices/",{params:requestData})
    .then(response => response)
    .catch(error => error.response);

const sendFirebaseByAuthValueRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+"/send/firebase/notifications",requestData)
    .then(response => response)
    .catch(error => error.response);

function* fetchFirebaseUsers({type,payload}) {
  yield put(showAppLoaderGenerator(type)) ;
  try{
    let request = {...payload,token:Helpers.token()} ;
    const response = yield call(fetchFirebaseUsersRequest,request);
    if(response.status === 200 || response.status === 201) {
      if(response.data.success){
        const data  = response.data.data;
        yield put(fetchFirebaseUsersSuccessGenerator(data));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }

    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield showMessageGenerator(e.statusText,'error') ;
  }
  yield put(hideAppLoaderGenerator(type));
}

function* sendFirebaseByAuthValue({type,payload}) {
  yield put(showAppLoaderGenerator(type)) ;
  try{
    let request = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendFirebaseByAuthValueRequest,request);
    if(response.status === 200 || response.status === 201) {
      if(response.data.success){
        const data  = response.data.data;
        yield put(sendFirebaseByAuthValueSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }

    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield showMessageGenerator(e.statusText,'error') ;
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchNotificationRequestsDefault() {
  yield takeLatest(FETCH_FIREBASE_USERS, fetchFirebaseUsers);
}

export function* sendFirebaseByAuthValueRequestsDefault() {
  yield takeLatest(SEND_FIREBASE_BY_AUTH_VALUE, sendFirebaseByAuthValue);
}

export default function* rootSaga () {
  yield all([
    fork(fetchNotificationRequestsDefault),
    fork(sendFirebaseByAuthValueRequestsDefault)
  ]);
}
