import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_LOAN_CATEGORY, DELETE_LOAN_CATEGORY, deleteLoanCategorySuccessGenerator, EDIT_LOAN_CATEGORY, editLoanCategorySuccessGenerator,
  FETCH_LOAN_CATEGORIES,
  fetchLoanCategoriesSuccessGenerator
} from "../../actions/payroll/LoanCategoryActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addLoanCategorySuccessGenerator
} from "../../actions/payroll/LoanCategoryActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchLoanCategoriesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/loan-types',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addLoanCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/loan-types',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteLoanCategoryRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/loan-types/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editLoanCategoryRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/loan-types/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchLoanCategories({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLoanCategoriesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchLoanCategoriesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addLoanCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addLoanCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(addLoanCategorySuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteLoanCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteLoanCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(deleteLoanCategorySuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editLoanCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editLoanCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editLoanCategorySuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchLoanCategoriesDefault() {
  yield takeLatest(FETCH_LOAN_CATEGORIES, fetchLoanCategories)
}
export function* addLoanCategoryDefault() {
  yield takeLatest(ADD_LOAN_CATEGORY, addLoanCategory);
}
export function* deleteLoanCategoryDefault() {
  yield takeLatest(DELETE_LOAN_CATEGORY, deleteLoanCategory);
}
export function* editLoanCategoryDefault() {
  yield takeLatest(EDIT_LOAN_CATEGORY, editLoanCategory);
}
export default function* rootSaga() {
  yield all([fork(fetchLoanCategoriesDefault),
    fork(addLoanCategoryDefault),
    fork(deleteLoanCategoryDefault),
    fork(editLoanCategoryDefault)
  ]);
}
