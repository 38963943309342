import {BULK_EXIT, BULK_EXIT_SUCCESS} from "./ExitActions";

export const FETCH_ALLOWANCES = "FETCH_ALLOWANCES";
export const FETCH_ALLOWANCES_SUCCESS = "FETCH_ALLOWANCES_SUCCESS";
export const SHOW_ALLOWANCE_CREATE_FORM = 'SHOW_ALLOWANCE_CREATE_FORM';
export const HIDE_ALLOWANCE_CREATE_FORM = 'HIDE_ALLOWANCE_CREATE_FORM';
export const ADD_ALLOWANCE = 'ADD_ALLOWANCE';
export const ADD_ALLOWANCE_SUCCESS = 'ADD_ALLOWANCE_SUCCESS';
export const SHOW_ALLOWANCE_EDIT_FORM = 'SHOW_ALLOWANCE_EDIT_FORM';
export const HIDE_ALLOWANCE_EDIT_FORM = 'HIDE_ALLOWANCE_EDIT_FORM';
export const EDIT_ALLOWANCE = 'EDIT_ALLOWANCE';
export const EDIT_ALLOWANCE_SUCCESS = 'EDIT_ALLOWANCE_SUCCESS';
export const SHOW_ALLOWANCE_DELETE_FORM = 'SHOW_ALLOWANCE_DELETE_FORM';
export const HIDE_ALLOWANCE_DELETE_FORM = 'HIDE_ALLOWANCE_DELETE_FORM';
export const DELETE_ALLOWANCE = 'DELETE_ALLOWANCE';
export const DELETE_ALLOWANCE_SUCCESS = 'DELETE_ALLOWANCE_SUCCESS';
export const CANCEL_ALLOWANCES_SUCCESS = 'CANCEL_ALLOWANCES_SUCCESS';
export const  CANCEL_ALLOWANCES = 'CANCEL_ALLOWANCES';
export const BULK_ALLOWANCE = 'BULK_ALLOWANCE';
export const BULK_ALLOWANCE_SUCCESS = 'BULK_ALLOWANCE_SUCCESS';
export const BULK_DEACTIVATE_ALLOWANCE = 'BULK_DEACTIVATE_ALLOWANCE';
export const FETCH_HOUSING_TYPES = "FETCH_HOUSING_TYPES";
export const FETCH_HOUSING_TYPES_SUCCESS = "FETCH_HOUSING_TYPES_SUCCESS";

export const bulkDeactivateAllowanceGenerator = payload =>{
  return{
    type:BULK_DEACTIVATE_ALLOWANCE,
    payload
  }
};
export const fetchAllowancesGenerator = (data=[])=>{
  return {
    type:FETCH_ALLOWANCES,
    payload:data
  };
};

export const fetchAllowancesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_ALLOWANCES_SUCCESS,
    payload:dataById
  }
};

export const cancelAllowanceGenerator = (data=[])=>{
  return {
    type:CANCEL_ALLOWANCES,
    payload:data
  };
};

export const cancelAllowancesSuccessGenerator = (dataById)=> {
  return {
    type:CANCEL_ALLOWANCES_SUCCESS,
    payload:dataById
  }
};

export const showAllowanceCreateFormGenerator = ()=>{
  return {
    type:SHOW_ALLOWANCE_CREATE_FORM
  };
};
export const hideAllowanceCreateFormGenerator = ()=>{
  return {
    type:HIDE_ALLOWANCE_CREATE_FORM
  };
};
export const addAllowanceGenerator = (data)=>{
  return {
    type: ADD_ALLOWANCE,
    payload: data
  }
};

export const addAllowanceSuccessGenerator = (data)=>{
  return {
    type: ADD_ALLOWANCE_SUCCESS,
    payload: data
  }
};
export const showAllowanceEditFormGenerator = (data)=>{
  return {
    type:SHOW_ALLOWANCE_EDIT_FORM,
    payload:data
  };
};
export const hideAllowanceEditFormGenerator = ()=>{
  return {
    type:HIDE_ALLOWANCE_EDIT_FORM
  };
};
export const editAllowanceGenerator = (data)=>{
  return {
    type: EDIT_ALLOWANCE,
    payload: data
  }
};
export const editAllowanceSuccessGenerator = (data)=>{
  return {
    type: EDIT_ALLOWANCE_SUCCESS,
    payload: data
  }
};
export const showAllowanceDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_ALLOWANCE_DELETE_FORM,
    payload:data
  };
};
export const hideAllowanceDeleteFormGenerator = ()=>{
  return {
    type:HIDE_ALLOWANCE_DELETE_FORM
  };
};
export const deleteAllowanceGenerator = (data)=>{
  return {
    type: DELETE_ALLOWANCE,
    payload: data
  }
};
export const deleteAllowanceSuccessGenerator = (data)=>{
  return {
    type: DELETE_ALLOWANCE_SUCCESS,
    payload: data
  }
};

export const bulkAllowanceGenerator = (data)=>{
  return {
    type: BULK_ALLOWANCE,
    payload: data
  }
};

export const bulkAllowanceSuccessGenerator = (data)=>{
  return {
    type: BULK_ALLOWANCE_SUCCESS,
    payload: data
  }
};

export const fetchHousingTypesGenerator = (data)=>{
  return {
    type: FETCH_HOUSING_TYPES,
    payload: data
  }
};
export const fetchHousingTypesSuccessGenerator = (data)=>{
  return {
    type: FETCH_HOUSING_TYPES_SUCCESS,
    payload: data
  }
};
