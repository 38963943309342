import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
  FETCH_COMPANY_ACCOUNT_SETTINGS,
  fetchCompanyAccountSettingsSuccessGenerator, UPDATE_COMPANY_ACCOUNT_SETTINGS
} from "../../actions/admin/CompanyAccountSettingsActions";

const fetchCompanyAccountSettingsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/company-account-settings',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);


const updateCompanyAccountSettingsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/update/company-account-settings', {params: requestParameters })
    .then(response => response)
    .catch(error => error.response);

function* fetchCompanyAccountSettings({payload}) {
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchCompanyAccountSettingsRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchCompanyAccountSettingsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }

}

function* updateCompanyAccountSettings({type,payload}) {
  yield put (showAppLoaderGenerator(type))
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(updateCompanyAccountSettingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompanyAccountSettingsSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type)) ;

}
export function* fetchCompanyAccountSettingsDefault() {
  yield takeLatest(FETCH_COMPANY_ACCOUNT_SETTINGS, fetchCompanyAccountSettings)
}

export function* updateCompanyAccountSettingsDefault() {
  yield takeLatest(UPDATE_COMPANY_ACCOUNT_SETTINGS, updateCompanyAccountSettings);
}

export default function* rootSaga() {
  yield all([fork(fetchCompanyAccountSettingsDefault),
    fork(updateCompanyAccountSettingsDefault),
  ]);
}
