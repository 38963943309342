export const FETCH_SYSTEM_USERS = "FETCH_SYSTEM_USERS";
export const FETCH_SYSTEM_USERS_SUCCESS = "FETCH_SYSTEM_USERS_SUCCESS";

export const DELETE_SYSTEM_USERS = "DELETE_SYSTEM_USERS";
export const DELETE_SYSTEM_USERS_SUCCESS = "DELETE_SYSTEM_USERS_SUCCESS";

export const EXPORT_SYSTEM_USERS = "EXPORT_SYSTEM_USERS";

export const fetchSystemUsersGenerator = (data=[])=>{
  return {
    type:FETCH_SYSTEM_USERS,
    payload:data
  }
} ;
export const fetchSystemUsersSuccessGenerator = (data=[])=>{
  return {
    type:FETCH_SYSTEM_USERS_SUCCESS,
    payload:data
  }
} ;

export const deleteSystemUsersGenerator = (data=[])=>{
  return {
    type:DELETE_SYSTEM_USERS,
    payload:data
  }
} ;
export const deleteSystemUsersSuccessGenerator = (data=[])=>{
  return {
    type:FETCH_SYSTEM_USERS_SUCCESS,
    payload:data
  }
} ;
export const exportSystemUsersGenerator = (data=[])=>{
  return {
    type:EXPORT_SYSTEM_USERS,
    payload:data
  }
} ;
