import {
  ADD_RECIPIENT_SUCCESS,
  DELETE_RECIPIENT,
  EDIT_RECIPIENT_SUCCESS,
  FETCH_FAVOURITE_RECIPIENTS_SUCCESS, FETCH_ONGOING_BULK_TRANSACTIONS_SUCCESS,
  FETCH_RECIPIENTS_SUCCESS,
  VERIFY_TRANSACTION_SUCCESS
} from "../../actions/payouts/BulkPayoutActions";
import {Helpers} from '../../../util/Helpers';

const INIT_STATE = {
  recipientsByID:[],
  favouriteRecipientsByID:[],
  paymentCacheId:'',
  feeSender:0,
  twoWayVerified:0,
  feeType:'SENDER',
  receiverPercent:0,
  senderPercent:0,
  feeReceiver:0,
  totalToSend:0,
  transactionCacheId:'',
  amount:0,
  fee:0,
  activeStep:0,
  companyBalance:0,
  balanceLowerLimit:0,
  accountNumber:'',
  ongoingBulkTransactions:[]
};
export default (state = INIT_STATE, action)=>{
  switch (action.type){
    case FETCH_ONGOING_BULK_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        ongoingBulkTransactions:action.payload
      };
    case ADD_RECIPIENT_SUCCESS:
      let data1 = {...state.recipientsByID};
      data1[action.payload.id]=action.payload;
      return {
        ...state,
        recipientsByID:data1,
        fee: parseFloat(state.fee) + parseFloat( action.payload.fee),
        feeSender: parseFloat(state.feeSender ) + parseFloat( action.payload.fee_sender),
        amount: parseFloat(state.amount ) + parseFloat( action.payload.amount),
        totalToSend: parseFloat(state.totalToSend) + parseFloat(action.payload.fee)+parseFloat(action.payload.amount),
      };
    case EDIT_RECIPIENT_SUCCESS:
      let data = {...state.recipientsByID};
      let previousRecord = data[action.payload.id];
      let fee = parseFloat(state.fee);
      let feeSender = parseFloat(state.feeSender );
      let amount = parseFloat(state.amount);
      let totalToSend = parseFloat(state.totalToSend);
      if(previousRecord){
        fee-=parseFloat( previousRecord.fee);
        feeSender-=parseFloat( previousRecord.fee_sender);
        amount-=parseFloat( previousRecord.amount);
        totalToSend-=(parseFloat(previousRecord.fee_sender)+parseFloat(previousRecord.amount));
      }
      fee+=parseFloat( action.payload.fee);
      feeSender+=parseFloat( action.payload.fee_sender);
      amount+=parseFloat( action.payload.amount);
      totalToSend+=(parseFloat(action.payload.fee_sender)+parseFloat(action.payload.amount));
      data[action.payload.id]=action.payload;
      return {
        ...state,
        recipientsByID:data,
        fee: fee,
        feeSender: feeSender,
        amount:amount,
        totalToSend: totalToSend,
      };
    case FETCH_RECIPIENTS_SUCCESS:
      let activeStep = Helpers.isNull(action.payload.payment_cache.two_way_auth)?0:1;
      return {
        ...state,
        recipientsByID:action.payload.caches,
        paymentCacheId:action.payload.payment_cache.id,
        feeSender: action.payload.fee_sender,
        feeReceiver:action.payload.fee_receiver,
        twoWayVerified: action.payload.payment_cache.two_way_verified,
        receiverPercent: action.payload.receiver_percent,
        senderPercent: action.payload.sender_percent,
        feeType: action.payload.fee_type,
        totalToSend: action.payload.total_to_send,
        fee: action.payload.fee,
        amount: action.payload.amount,
        transactionCacheId: action.payload.payment_cache.cache_id,
        activeStep:activeStep,
        companyBalance: action.payload.balance,
        balanceLowerLimit:action.payload.balance_lower_limit,
        accountNumber: action.payload.account_number,
      };
    case VERIFY_TRANSACTION_SUCCESS:{
      return{
        ...state,
        twoWayVerified:1
      }
    }
    case DELETE_RECIPIENT:
      let recipients = {...state.recipientsByID};
      let previousRecord2 = recipients[action.payload.id];
      let fee2 = parseFloat(state.fee);
      let feeSender2 = parseFloat(state.feeSender );
      let amount2 = parseFloat(state.amount);
      let totalToSend2 = parseFloat(state.totalToSend);
      if(previousRecord2){
        fee2-=parseFloat( previousRecord2.fee);
        feeSender2-=parseFloat( previousRecord2.fee_sender);
        amount2-=parseFloat( previousRecord2.amount);
        totalToSend2-=(parseFloat(previousRecord2.fee_sender)+parseFloat(previousRecord2.amount));
      }
      delete recipients[action.payload.id];
      return {
        ...state,
        recipientsByID:recipients,
        fee: fee2,
        feeSender: feeSender2,
        amount:amount2,
        totalToSend: totalToSend2,
      };
    case FETCH_FAVOURITE_RECIPIENTS_SUCCESS:
      return {
         ...state,
        favouriteRecipientsByID: action.payload
      } ;
    default:
      return state;

  }
}
