import axios from 'axios';
import {Helpers} from './util/Helpers';
import { AUTH_USER_KEY } from "constants/SessionKeys";

axios.interceptors.response.use(response => {
    if(response && response.headers) {
        if(response.headers.authorization) {
            let existingAuthState = Helpers.getItem(AUTH_USER_KEY);

            if(existingAuthState) {
                existingAuthState = JSON.parse(existingAuthState);
    
                const newAuthState = {
                    ...existingAuthState,
                    user: {
                        ...existingAuthState.user,
                        auth_token: response.headers.authorization.split(" ")[1]
                    } 
                };
    
                Helpers.saveItem(AUTH_USER_KEY, newAuthState);
            }
        }
    }
    // Edit response config
    return response;
}, error => {
    return Promise.reject(error);
});