import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_WITHDRAWAL, DELETE_WITHDRAWAL, deleteWithdrawalSuccessGenerator, EDIT_WITHDRAWAL, editWithdrawalSuccessGenerator,
  FETCH_WITHDRAWALS,
  fetchWithdrawalsSuccessGenerator
} from "../../actions/payroll/WithdrawalActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addWithdrawalSuccessGenerator
} from "../../actions/payroll/WithdrawalActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";



const fetchWithdrawalsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/withdrawals',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addWithdrawalRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/withdrawals',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteWithdrawalRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/withdrawals/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editWithdrawalRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/withdrawals/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchWithdrawals({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchWithdrawalsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchWithdrawalsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addWithdrawal({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addWithdrawalRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(addWithdrawalSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteWithdrawal({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteWithdrawalRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(deleteWithdrawalSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editWithdrawal({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editWithdrawalRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editWithdrawalSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchWithdrawalsDefault() {
  yield takeLatest(FETCH_WITHDRAWALS, fetchWithdrawals)
}
export function* addWithdrawalDefault() {
  yield takeLatest(ADD_WITHDRAWAL, addWithdrawal);
}
export function* deleteWithdrawalDefault() {
  yield takeLatest(DELETE_WITHDRAWAL, deleteWithdrawal);
}
export function* editWithdrawalDefault() {
  yield takeLatest(EDIT_WITHDRAWAL, editWithdrawal);
}
export default function* rootSaga() {
  yield all([fork(fetchWithdrawalsDefault),
    fork(addWithdrawalDefault),
    fork(deleteWithdrawalDefault),
    fork(editWithdrawalDefault)
  ]);
}
