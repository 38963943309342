export const FETCH_ASSETS = "FETCH_ASSETS";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const SHOW_ASSET_CREATE_FORM = 'SHOW_ASSET_CREATE_FORM';
export const HIDE_ASSET_CREATE_FORM = 'HIDE_ASSET_CREATE_FORM';
export const ADD_ASSET = 'ADD_ASSET';
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';
export const SHOW_ASSET_EDIT_FORM = 'SHOW_ASSET_EDIT_FORM';
export const HIDE_ASSET_EDIT_FORM = 'HIDE_ASSET_EDIT_FORM';
export const EDIT_ASSET = 'EDIT_ASSET';
export const EDIT_ASSET_SUCCESS = 'EDIT_ASSET_SUCCESS';
export const SHOW_ASSET_DELETE_FORM = 'SHOW_ASSET_DELETE_FORM';
export const HIDE_ASSET_DELETE_FORM = 'HIDE_ASSET_DELETE_FORM';
export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const BULK_UPLOAD_ASSETS = 'BULK_UPLOAD_ASSETS';

export const bulkUploadAssetsGenerator = (data=[])=>{
  return {
    type:BULK_UPLOAD_ASSETS,
    payload:data
  }
};
export const fetchAssetsGenerator = (data=[])=>{
  return {
    type:FETCH_ASSETS,
    payload:data
  };
};

export const fetchAssetsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_ASSETS_SUCCESS,
    payload:dataById
  }
};

export const showAssetCreateFormGenerator = ()=>{
  return {
    type:SHOW_ASSET_CREATE_FORM
  };
};
export const hideAssetCreateFormGenerator = ()=>{
  return {
    type:HIDE_ASSET_CREATE_FORM
  };
};
export const addAssetGenerator = (data)=>{
  return {
    type: ADD_ASSET,
    payload: data
  }
};

export const addAssetSuccessGenerator = (data)=>{
  return {
    type: ADD_ASSET_SUCCESS,
    payload: data
  }
};
export const showAssetEditFormGenerator = (data)=>{
  return {
    type:SHOW_ASSET_EDIT_FORM,
    payload:data
  };
};
export const hideAssetEditFormGenerator = ()=>{
  return {
    type:HIDE_ASSET_EDIT_FORM
  };
};
export const editAssetGenerator = (data)=>{
  return {
    type: EDIT_ASSET,
    payload: data
  }
};
export const editAssetSuccessGenerator = (data)=>{
  return {
    type: EDIT_ASSET_SUCCESS,
    payload: data
  }
};
export const showAssetDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_ASSET_DELETE_FORM,
    payload:data
  };
};
export const hideAssetDeleteFormGenerator = ()=>{
  return {
    type:HIDE_ASSET_DELETE_FORM
  };
};
export const deleteAssetGenerator = (data)=>{
  return {
    type: DELETE_ASSET,
    payload: data
  }
};
export const deleteAssetSuccessGenerator = (data)=>{
  return {
    type: DELETE_ASSET_SUCCESS,
    payload: data
  }
};
