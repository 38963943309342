export const FETCH_COMPANY_LOANS = "FETCH_COMPANY_LOANS";
export const FETCH_COMPANY_LOANS_SUCCESS = "FETCH_COMPANY_LOANS_SUCCESS";

export const ADD_COMPANY_LOANS = "ADD_COMPANY_LOANS";
export const ADD_COMPANY_LOANS_SUCCESS = "ADD_COMPANY_LOANS_SUCCESS";

export const FETCH_LENDERS = 'FETCH_LENDERS';
export const FETCH_LENDERS_SUCCESS = 'FETCH_LENDERS_SUCCESS';

export const FETCH_LOAN_BY_NEW = 'FETCH_LOAN_BY_NEW';
export const FETCH_LOAN_BY_PENDING = 'FETCH_LOAN_BY_PENDING';
export const FETCH_LOAN_BY_DEFAULT = 'FETCH_LOAN_BY_DEFAULT';
export const FETCH_LOAN_BY_PENDING_SUCCESS = 'FETCH_LOAN_BY_PENDING_SUCCESS';
export const FETCH_LOAN_BY_NEW_SUCCESS = 'FETCH_LOAN_BY_NEW_SUCCESS';
export const FETCH_LOAN_BY_DEFAULTED_SUCCESS = 'FETCH_LOAN_BY_DEFAULTED_SUCCESS';
export const EDIT_ADMIN_LOAN = 'EDIT_ADMIN_LOAN';
export const EDIT_ADMIN_LOAN_SUCCESS = 'EDIT_ADMIN_LOAN_SUCCESS';
export const DELETE_ADMIN_LOAN = 'DELETE_ADMIN_LOAN';
export const DELETE_ADMIN_LOAN_SUCCESS = 'DELETE_ADMIN_LOAN_SUCCESS';

export const AUTO_CALCULATE_REPAYMENT = 'AUTO_CALCULATE_REPAYMENT';
export const AUTO_CALCULATE_REPAYMENT_SUCCESS = 'AUTO_CALCULATE_REPAYMENT_SUCCESS';
export const PAUSE_UNPAUSE_COMPANY_LOAN = 'PAUSE_UNPAUSE_COMPANY_LOAN';

export const pauseUnpauseCompanyLoanGenerator = (payload=[])=>{
  return {
    type:PAUSE_UNPAUSE_COMPANY_LOAN,
    payload
  }
};
export const autoCalculateRepaymentGenerator = (payload=[])=>{
  return {
    type:AUTO_CALCULATE_REPAYMENT,
    payload
  }
};
export const autoCalculateRepaymentSuccessGenerator = (payload=[])=>{
  return {
    type:AUTO_CALCULATE_REPAYMENT_SUCCESS,
    payload
  }
};
export const editLoanGenerator = (payload=[])=>{
  return{
    type:EDIT_ADMIN_LOAN,
    payload
  }
}
export const editLoanSuccessGenerator = (payload=[])=>{
  return{
    type:EDIT_ADMIN_LOAN_SUCCESS,
    payload
  }
}
export const deleteLoanGenerator = (payload=[])=>{
  return{
    type:DELETE_ADMIN_LOAN,
    payload
  }
}
export const deleteLoanSuccessGenerator = (payload=[])=>{
  return{
    type:DELETE_ADMIN_LOAN_SUCCESS,
    payload
  }
}
export const fetchLoanByNewGenerator = (payload=[])=>{
  return {
    type:FETCH_LOAN_BY_NEW,
    payload
  }
}
export const fetchLoanByPendingGenerator = (payload=[])=>{
  return {
    type:FETCH_LOAN_BY_PENDING,
    payload
  }
}
export const fetchLoanByDefaultGenerator = (payload=[])=>{
  return {
    type:FETCH_LOAN_BY_DEFAULT,
    payload
  }
}
export const fetchLoanByPendingSuccessGenerator = (payload=[])=>{
  return {
    type:FETCH_LOAN_BY_PENDING_SUCCESS,
    payload
  }
}
export const fetchLoanByNewSuccessGenerator = (payload=[])=>{
  return {
    type:FETCH_LOAN_BY_NEW_SUCCESS,
    payload
  }
}
export const fetchLoanByDefaultSuccessGenerator = (payload=[])=>{
  return {
    type:FETCH_LOAN_BY_DEFAULTED_SUCCESS,
    payload
  }
}

export const fetchLendersGenerator = (payload=[])=>{
  return{
    type:FETCH_LENDERS,
    payload
  }
}
export const fetchLenderSuccessGenerator = (payload=[])=>{
  return{
    type:FETCH_LENDERS_SUCCESS,
    payload
  }
}

export const addCompanyLoansGenerator = (payload=[])=>{
  return {
    type:ADD_COMPANY_LOANS,
    payload
  }
}

export const addCompanyLoanSuccessGenerator = (payload=[])=>{
  return {
    type:ADD_COMPANY_LOANS_SUCCESS,
    payload
  }
}

export const fetchCompanyLoansGenerator = (payload=[])=>{
  return {
    type:FETCH_COMPANY_LOANS,
    payload
  }
};

export const fetchCompanyLoansSuccessGenerator = (payload)=>{
  return {
    type:FETCH_COMPANY_LOANS_SUCCESS,
    payload
  }
};