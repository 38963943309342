import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  FETCH_APPRAISAL_DASHBOARD,
  FETCH_HRM_DASHBOARD, FETCH_PAYOUT_DASHBOARD, fetchAppraisalDashBoardSuccessGenerator,
  fetchHrmDashBoardSuccessGenerator,
  fetchPayoutDashBoardSuccessGenerator
} from "../../actions/common/DashboardsActions";

const fetchHrmDashboardRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/hrm',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchPayoutDashBoardRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/home/payouts',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);
const fetchAppraisalDashBoardRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/home/appraisal',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

function* fetchPayoutDashboard({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPayoutDashBoardRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let dash = response.data.data;
        yield put(fetchPayoutDashBoardSuccessGenerator(dash));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchAppraisalDashboard({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAppraisalDashBoardRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let dash = response.data.data;
        yield put(fetchAppraisalDashBoardSuccessGenerator(dash));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchHrmDashboard({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchHrmDashboardRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let dash = response.data.data;
        yield put(fetchHrmDashBoardSuccessGenerator(dash));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchHrmDashboardDefault() {
  yield takeLatest(FETCH_HRM_DASHBOARD, fetchHrmDashboard);
}
export function* fetchPayoutDashboardDefault() {
  yield takeLatest(FETCH_PAYOUT_DASHBOARD, fetchPayoutDashboard);
}
export function* fetchAppraisalDashboardDefault() {
  yield takeLatest(FETCH_APPRAISAL_DASHBOARD, fetchAppraisalDashboard);
}
export default function* rootSaga() {
  yield all([
    fork(fetchHrmDashboardDefault),
    fork(fetchPayoutDashboardDefault),
    fork(fetchAppraisalDashboardDefault),
  ]);
}
