import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
  ADD_NOTIFICATION,
  addNotificationSuccessGenerator, FETCH_NOTIFICATION,
  fetchNotificationSuccessGenerator, MARK_AS_READ, markAsReadNotificationSuccessGenerator
} from "../../actions/payroll/NotificationAction";

const fetchNotificationRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/notices',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addNotificationRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/notice',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const markAsReadNotificationRequest = async(requestParameters) =>
  await axios.get( API_ENDPOINT+'/hrm/notices/'+requestParameters.category,{params:requestParameters})
    .then(response => response)
    .catch(error=>error.message);

function* markAsReadNotification({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(markAsReadNotificationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let notice = response.data.data;
        yield put(fetchNotificationSuccessGenerator(notice));
        yield put(showMessageGenerator(response.data.message));
      } else {
        yield put(showMessageGenerator(response.data.message));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchNotification({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchNotificationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let notice = response.data.data;
        yield put(fetchNotificationSuccessGenerator(notice));
        //yield put(showMessageGenerator(response.data.message));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addNotification({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addNotificationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(addNotificationSuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchNotificationDefault() {
  yield takeLatest(FETCH_NOTIFICATION, fetchNotification)
}
export function* markAsReadNotificationDefault() {
  yield takeLatest(MARK_AS_READ, markAsReadNotification);
}
export function* addNotificationDefault() {
  yield takeLatest(ADD_NOTIFICATION, addNotification);
}
export default function* rootSaga() {
  yield all([
    fork(fetchNotificationDefault),
    fork(addNotificationDefault),
    fork(markAsReadNotificationDefault)
  ]);
}
