import {
  EDIT_PENSION_RATE_SUCCESS,
  FETCH_PENSION_RATES_SUCCESS,
  HIDE_PENSION_RATE_EDIT_FORM,
  SHOW_PENSION_RATE_EDIT_FORM
} from '../../actions/common/PensionRateActions';

const INIT_STATE = {
  pensionRatesByID:[],
  pensionRateToEdit:[],
  showPensionRateEditForm:false,
};

export default (state = INIT_STATE, action)=>{
  let pension_rates;
  switch (action.type){
    case EDIT_PENSION_RATE_SUCCESS:
      pension_rates = {...state}.pensionRatesByID;
      pension_rates[action.payload.id]=action.payload;
      return {
        ...state,
        pensionRatesByID:pension_rates
      };
    case FETCH_PENSION_RATES_SUCCESS:
      return {
        ...state,
        pensionRatesByID:action.payload
      };

    case SHOW_PENSION_RATE_EDIT_FORM:
      return {
        ...state,
        pensionRateToEdit:action.payload,
        showPensionRateEditForm:true
      };
    case HIDE_PENSION_RATE_EDIT_FORM:
      return {
        ...state,
        showPensionRateEditForm:false
      };
    default:
      return {
        ...state
      };
  }
}
