import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import axios from 'axios';
import {Helpers} from '../../../util/Helpers';
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from '../../actions/AuthActions';
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import { push } from 'connected-react-router';
import {
  FETCH_ALLOWANCE_LEDGER,
  FETCH_AUDIT_LOG_REPORT,
  FETCH_LEAVE_BALANCE_REPORT,
  FETCH_LOAN_PAYMENT_REPORT,
  FETCH_MUSTER_ROLL,
  FETCH_NET_PAY,
  FETCH_STATUTORY_REPORT,
  FETCH_VIEW_PAYSLIPS,
  fetchAllowanceLedgerSuccessGenerator,
  fetchAuditLogReportSuccessGenerator,
  fetchLeaveBalanceReportSuccessGenerator,
  fetchLoanPaymentReportSuccessGenerator,
  fetchMusterRollSuccessGenerator,
  fetchNetPaySuccessGenerator,
  FETCH_DEDUCTION_LEDGER_REPORT,
  fetchDeductionLedgerReportSuccessGenerator,
  fetchSalaryAdvanceReportSuccessGenerator,
  fetchStatutoryReportSuccessGenerator,
  fetchViewPayslipsSuccessGenerator,
  deleteSalaryAdvanceSuccessGenerator,
  FETCH_SALARY_ADVANCE_REPORT,
  DELETE_SALARY_ADVANCE_REPORT,
  ADD_FROZEN_TO_MUSTER_ROLL,
  EXPORT_STATUTORY_DEDUCTIONS,
  fetchQueuedMusterRollExportsSuccessGenerator,
  ADD_QUEUED_MUSTER_ROLL_EXPORT,
  FETCH_QUEUED_MUSTER_ROLL_EXPORTS,
  generateInsuranceProvidentSuccessGenerator,
  GENERATE_INSURANCE_PROVIDENT,
  generatePayrollJournalSuccessGenerator,
  GENERATE_PAYROLL_JOURNAL,
  generatePensionSuccessGenerator,
  GENERATE_PENSION,
  fetchLeavesAttendedReportSuccessGenerator,
  FETCH_LEAVES_ATTENDED_REPORT,
  EXPORT_LEAVES_ATTENDED_REPORT,
  fetchPayrollVarianceReportSuccessGenerator,
  FETCH_PAYROLL_VARIANCE_REPORT,
  EXPORT_PAYROLL_VARIANCE_REPORT,
  fetchEmployeePayVarianceReportSuccessGenerator,
  FETCH_EMPLOYEE_PAY_VARIANCE_REPORT,
  editPayrollVarianceRemarkSuccessGenerator,
  EDIT_PAROLL_VARIANCE_REMARK
} from "../../actions/payroll/PayrollReportAction";

const deleteSalaryAdvanceRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/salary/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

function* deleteSalaryAdvance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteSalaryAdvanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let advance = response.data.data;
        yield put(deleteSalaryAdvanceSuccessGenerator(advance));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
const fetchLeavesAttendedReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/leaves-attended-report`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const fetchPayrollVarianceReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/payroll-variance-report`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const editPayrollVarianceRemarkRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/edit-payroll-variance-remark`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const fetchEmployeePayVarianceReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/payroll-variance-report`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const exportLeavesAttendedReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/leaves-attended-report/download`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const exportPayrollVarianceReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/payroll-variance-report/download`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const fetchLeaveBalanceReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/leaves_report`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const fetchInsuranceProvidentRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/insurance-provident`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);
const fetchPayrollJournalRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/payroll-journal`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);

const fetchPensionRequest = async requestData => await axios.get(API_ENDPOINT+'/hrm/pension-fund',{params:requestData})
    .then(response=>response)
    .catch(error=>error.response);

function* fetchPension({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPensionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const data_ = response.data.data;
        yield put(generatePensionSuccessGenerator(data_));
        yield put(showMessageGenerator(response.data.message.toProperCase(),'success'));
      } else {
        yield put(showMessageGenerator(response.data.message.toProperCase(),'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchPayrollJournalReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPayrollJournalRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const data_ = response.data.data;
        yield put(generatePayrollJournalSuccessGenerator(data_));
        yield put(showMessageGenerator(response.data.message.toProperCase(),'success'));
      } else {
        yield put(showMessageGenerator(response.data.message.toProperCase(),'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchInsuranceProvidentReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchInsuranceProvidentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const data_ = response.data.data;
        yield put(generateInsuranceProvidentSuccessGenerator(data_));
        yield put(showMessageGenerator(response.data.message.toProperCase(),'success'));
      } else {
        yield put(generateInsuranceProvidentSuccessGenerator([]));
        yield put(showMessageGenerator(response.data.message.toProperCase(),'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLeaveBalanceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLeaveBalanceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const leave_balance = response.data.data;
        yield put(fetchLeaveBalanceReportSuccessGenerator(leave_balance));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchLeavesAttendedReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLeavesAttendedReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const leaves_attended = response.data.data;
        yield put(fetchLeavesAttendedReportSuccessGenerator(leaves_attended));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchPayrollVarianceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPayrollVarianceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const payroll_variance = response.data.data;
        yield put(fetchPayrollVarianceReportSuccessGenerator(payroll_variance));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editPayrollVarianceRemark({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(editPayrollVarianceRemarkRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const payroll = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(editPayrollVarianceRemarkSuccessGenerator(payroll));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchEmployeePayVarianceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeePayVarianceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const payroll_variance = response.data.data;
        yield put(fetchEmployeePayVarianceReportSuccessGenerator(payroll_variance));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* exportLeavesAttendedReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(exportLeavesAttendedReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* exportPayrollVarianceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(exportPayrollVarianceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

/* statutory report */
const fetchStatutoryReportRequest = async requestParameters =>
  await axios.post(API_ENDPOINT+'/hrm/reports', requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* fetchStatutoryReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    let response = yield call(fetchStatutoryReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const statutory_data = response.data.data;
        yield put(fetchStatutoryReportSuccessGenerator(statutory_data));
        yield put(showMessageGenerator(response.data.message.toTitleCase(),'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
/* Allowance Ledger */
const fetchAllowanceLedgerRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/allowance_ledger`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* fetchAllowanceLedger({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAllowanceLedgerRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const allowance_ledger = response.data.data;
        yield put(fetchAllowanceLedgerSuccessGenerator(allowance_ledger));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
/* Muster Roll */
const fetchMusterRollRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/muster_roll`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
const addFrozenToMusterRollRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/add/frozen/employee/${requestParameters.id}`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* addFrozenToMusterRoll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(addFrozenToMusterRollRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //const muster_roll = response.data.data;
        //yield put(addFrozenToMusterRollSuccessGenerator(muster_roll));
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(push("/hrm/muster-roll"));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchMusterRoll({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchMusterRollRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const muster_roll = response.data.data;
        yield put(fetchMusterRollSuccessGenerator(muster_roll));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
/* view payslips */
const fetchViewPayslipsRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/payroll-batches/view/payslips`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* fetchViewPayslips({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchViewPayslipsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const payslips = response.data.data;
        yield put(fetchViewPayslipsSuccessGenerator(payslips));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
/* Net pay report */
const fetchNetPayRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/bank_net_pay_report`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* fetchNetPay({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchNetPayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const net_pay_data = response.data.data;
        yield put(fetchNetPaySuccessGenerator(net_pay_data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
// /// audit log
const fetchAuditLogRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/audit_log`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);

function* fetchAuditLog({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAuditLogRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const audit_log = response.data.data;
        yield put(fetchAuditLogReportSuccessGenerator(audit_log));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
const fetchDeductionLedgerReportRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/deductions_ledger`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* fetchDeductionLedgerReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDeductionLedgerReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const report = response.data.data;
        yield put(fetchDeductionLedgerReportSuccessGenerator(report));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

// ///loan Payment Report
const fetchLoanPaymentReportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/reports/loans/logs`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);

function* fetchLoanPaymentReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLoanPaymentReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const loan_payment = response.data.data;
        yield put(fetchLoanPaymentReportSuccessGenerator(loan_payment));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchDeductionLedgerReportDefault() {
  yield takeLatest(FETCH_DEDUCTION_LEDGER_REPORT, fetchDeductionLedgerReport);
}
const fetchSalaryAdvanceReportRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/hrm/advance_payment_report`, requestParameters)
  .then(response => response)
  .catch(error => error.response);
function* fetchSalaryAdvanceReport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchSalaryAdvanceReportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const report = response.data.data;
        yield put(fetchSalaryAdvanceReportSuccessGenerator(report));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

const exportStatutoryDeductionsRequest = async requestParameters => await axios.get(`${API_ADDRESS}/hrm/exportreports/`+requestParameters.type+'/'+requestParameters.month+'/'+requestParameters.year, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);

function* exportStatutoryDeductions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const request = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()}
    const response = yield call(exportStatutoryDeductionsRequest,request);
    if(response.status ===200 || response.status===201){
      yield put(showMessageGenerator('Report Exported Successfully','success'));
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


const addQueuedMusterRollExportRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/muster-roll/download/`, {params:requestParameters})
  .then(response => response)
  .catch(error => error.response);
function* addQueuedMusterRollExport({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(addQueuedMusterRollExportRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        if(requestParameters.type === 'waveapps' || requestParameters.type === 'quickbooks' || requestParameters.type === 'xero' ){
          let data = response.data.data;
          if (data.type && data.type ==='url'){
            yield put(showMessageGenerator(response.data.message,'success'));
            Helpers.openInNewTab(data.url);/*Redirect to wave apps/quickbooks/xero for authorization*/
          }else{
            yield put(showMessageGenerator(response.data.message,'success'));
          }
        }else{
          yield put(showMessageGenerator('Muster Roll export queued for processing. This process may take some time.','success'));
          yield put(push('/hrm/queued-muster-roll-exports'));
        }

      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


const fetchQueuedMusterRollExportsRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/hrm/muster-roll/queued/exports`, {params:requestParameters})
  .then(response => response)
  .catch(error => error.response);
function* fetchQueuedMusterRollExports({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchQueuedMusterRollExportsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const exports = response.data.data;
        yield put(fetchQueuedMusterRollExportsSuccessGenerator(exports));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPensionDefault() {
yield takeLatest(GENERATE_PENSION,fetchPension);
}

export function* fetchLoanPaymentDefault() {
  yield takeLatest(FETCH_LOAN_PAYMENT_REPORT, fetchLoanPaymentReport);
}
export function* exportStatutoryDeductionDefault() {
  yield takeLatest(EXPORT_STATUTORY_DEDUCTIONS, exportStatutoryDeductions);
}
export function* fetchAuditLogDefault() {
  yield takeLatest(FETCH_AUDIT_LOG_REPORT, fetchAuditLog);
}
export function* fetchSalaryAdvanceReportDefault() {
  yield takeLatest(FETCH_SALARY_ADVANCE_REPORT, fetchSalaryAdvanceReport);
}
export function* fetchLeaveBalanceReportDefault() {
  yield takeLatest(FETCH_LEAVE_BALANCE_REPORT, fetchLeaveBalanceReport);
}
export function* fetchLeavesAttendedReportDefault() {
  yield takeLatest(FETCH_LEAVES_ATTENDED_REPORT, fetchLeavesAttendedReport);
}
export function* fetchPayrollVarianceReportDefault() {
  yield takeLatest(FETCH_PAYROLL_VARIANCE_REPORT, fetchPayrollVarianceReport);
}
export function* editPayrollVarianceRemarkDefault() {
  yield takeLatest(EDIT_PAROLL_VARIANCE_REMARK, editPayrollVarianceRemark);
}
export function* fetchEmployeePayVarianceReportDefault() {
  yield takeLatest(FETCH_EMPLOYEE_PAY_VARIANCE_REPORT, fetchEmployeePayVarianceReport);
}
export function* exportLeavesAttendedReportDefault() {
  yield takeLatest(EXPORT_LEAVES_ATTENDED_REPORT, exportLeavesAttendedReport);
}
export function* exportPayrollVarianceReportDefault() {
  yield takeLatest(EXPORT_PAYROLL_VARIANCE_REPORT, exportPayrollVarianceReport);
}
export function* fetchAllowanceLedgerDefault() {
  yield takeLatest(FETCH_ALLOWANCE_LEDGER, fetchAllowanceLedger);
}
export function* fetchStatutoryReportDefault() {
  yield takeLatest(FETCH_STATUTORY_REPORT, fetchStatutoryReport);
}
export function* fetchMusterRollDefault() {
  yield takeLatest(FETCH_MUSTER_ROLL, fetchMusterRoll);
}
export function* fetchViewPayslipsDefault() {
  yield takeLatest(FETCH_VIEW_PAYSLIPS, fetchViewPayslips);
}
export function* addFrozenToMusterRollDefault() {
  yield takeLatest(ADD_FROZEN_TO_MUSTER_ROLL, addFrozenToMusterRoll);
}
export function* fetchNetPayDefault() {
  yield takeLatest(FETCH_NET_PAY, fetchNetPay);
}

export function* deleteSalaryAdvanceDefault() {
  yield takeLatest(DELETE_SALARY_ADVANCE_REPORT, deleteSalaryAdvance);
}

export function* addQueuedMusterRollExportDefault() {
  yield takeLatest(ADD_QUEUED_MUSTER_ROLL_EXPORT, addQueuedMusterRollExport);
}
export function* fetchQueuedMusterRollExportDefault() {
  yield takeLatest(FETCH_QUEUED_MUSTER_ROLL_EXPORTS, fetchQueuedMusterRollExports);
}
export function* fetchInsuranceProvidentReportDefault() {
  yield takeLatest(GENERATE_INSURANCE_PROVIDENT, fetchInsuranceProvidentReport);
}
export function* fetchPayrollJournalDefault() {
  yield takeLatest(GENERATE_PAYROLL_JOURNAL, fetchPayrollJournalReport);
}
export default function* rootSaga() {
  yield all([
    fork(fetchLeaveBalanceReportDefault),
    fork(fetchStatutoryReportDefault),
    fork(fetchAllowanceLedgerDefault),
    fork(fetchMusterRollDefault),
    fork(fetchViewPayslipsDefault),
    fork(fetchNetPayDefault),
    fork(fetchAuditLogDefault),
    fork(fetchLoanPaymentDefault),
    fork(fetchDeductionLedgerReportDefault),
    fork(fetchSalaryAdvanceReportDefault),
    fork(deleteSalaryAdvanceDefault),
    fork(addFrozenToMusterRollDefault),
    fork(exportStatutoryDeductionDefault),
    fork(addQueuedMusterRollExportDefault),
    fork(fetchQueuedMusterRollExportDefault),
    fork(fetchInsuranceProvidentReportDefault),
    fork(fetchPayrollJournalDefault),
    fork(fetchPensionDefault),
    fork(fetchLeavesAttendedReportDefault),
    fork(exportLeavesAttendedReportDefault),
    fork(fetchPayrollVarianceReportDefault),
    fork(fetchEmployeePayVarianceReportDefault),
    fork(exportPayrollVarianceReportDefault),
    fork(editPayrollVarianceRemarkDefault),
  ]);
}
