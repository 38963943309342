export const FETCH_LOAN_REQUESTS = "FETCH_LOAN_REQUESTS";
export const FETCH_LOAN_REQUESTS_SUCCESS = "FETCH_LOAN_REQUESTS_SUCCESS";

export const CREATE_LOAN_REQUEST = "CREATE_LOAN_REQUEST";

export const DISAPPROVE_LOAN_REQUESTS = "DISAPPROVE_LOAN_REQUESTS";
export const DISAPPROVE_LOAN_REQUESTS_SUCCESS = "DISAPPROVE_LOAN_REQUESTS_SUCCESS";

export const createLoanRequestGenerator = (data=[])=>{
  return {
    type:CREATE_LOAN_REQUEST,
    payload:data
  }
};
export const fetchLoanRequestsGenerator = (data=[])=>{
  return {
    type:FETCH_LOAN_REQUESTS,
    payload:data
  }
};

export const fetchLoanRequestsSuccessGenerator = (data=[])=>{
  return {
    type:FETCH_LOAN_REQUESTS_SUCCESS,
    payload:data
  }
};

export const disapproveLoanRequestsGenerator = (data=[])=>{
  return {
    type:DISAPPROVE_LOAN_REQUESTS,
    payload:data
  }
};

export const disapproveLoanRequestsSuccessGenerator = (data=[])=>{
  return {
    type:DISAPPROVE_LOAN_REQUESTS_SUCCESS,
    payload:data
  }
};
