import {
  ADD_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_SUCCESS,
  HIDE_NOTIFICATION_CREATE_FORM,
  MARK_AS_READ_SUCCESS,
  SHOW_NOTIFICATION_CREATE_FORM
} from '../../actions/payroll/NotificationAction';

const INIT_STATE = {
  notificationByID:{},
  showNotificationsCreateForm:false,
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationByID: action.payload
      };
    case SHOW_NOTIFICATION_CREATE_FORM:
      return {
        ...state,
        showNotificationCreateForm: true
      };
    case HIDE_NOTIFICATION_CREATE_FORM:
      return {
        ...state,
        showNotificationCreateForm: false
      };
    case MARK_AS_READ_SUCCESS:
    case ADD_NOTIFICATION_SUCCESS:
      let notice_id = action.payload.id;
      return {
        ...state,
        notificationByID: {
          ...state.notificationByID,
          [notice_id]:action.payload
        }
      };
      default:
        return state;
  }
}
