import {
  ADD_ALLOWANCE_SUCCESS,
  BULK_ALLOWANCE_SUCCESS,
  CANCEL_ALLOWANCES_SUCCESS,
  DELETE_ALLOWANCE_SUCCESS,
  EDIT_ALLOWANCE_SUCCESS,
  FETCH_ALLOWANCES_SUCCESS, FETCH_HOUSING_TYPES_SUCCESS,
  HIDE_ALLOWANCE_CREATE_FORM,
  HIDE_ALLOWANCE_DELETE_FORM,
  HIDE_ALLOWANCE_EDIT_FORM,
  SHOW_ALLOWANCE_CREATE_FORM,
  SHOW_ALLOWANCE_DELETE_FORM,
  SHOW_ALLOWANCE_EDIT_FORM
} from "../../actions/payroll/AllowanceActions";

const INIT_STATE = {
  allowancesByID:{},
  showAllowanceCreateForm:false,
  showAllowanceEditForm:false,
  allowanceToEdit:false,
  showAllowanceDeleteForm:false,
  allowanceToDelete:{},
  housingTypes:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case BULK_ALLOWANCE_SUCCESS:
      return {
        ...state,
        allowancesByID: action.payload
      };
    case FETCH_ALLOWANCES_SUCCESS:
      return {
        ...state,
        allowancesByID: action.payload
      };
    case SHOW_ALLOWANCE_CREATE_FORM:
      return {
        ...state,
        showAllowanceCreateForm: true
      };
    case HIDE_ALLOWANCE_CREATE_FORM:
      return {
        ...state,
        showAllowanceCreateForm: false
      };
    case SHOW_ALLOWANCE_EDIT_FORM:
      return {
        ...state,
        showAllowanceEditForm: true,
        allowanceToEdit: action.payload
      };
    case HIDE_ALLOWANCE_EDIT_FORM:
      return {
        ...state,
        showAllowanceEditForm: false
      };
    case CANCEL_ALLOWANCES_SUCCESS:
    case ADD_ALLOWANCE_SUCCESS:
    case EDIT_ALLOWANCE_SUCCESS:
     let  allowance = {...state.allowancesByID};
      allowance[action.payload.id]=action.payload;
      return {
        ...state,
        allowancesByID:allowance
      };
    case SHOW_ALLOWANCE_DELETE_FORM:
      return {
        ...state,
        showAllowanceDeleteForm: true,
        allowanceToDelete: action.payload
      };
    case HIDE_ALLOWANCE_DELETE_FORM:
      return {
        ...state,
        showAllowanceDeleteForm: false
      };
    case DELETE_ALLOWANCE_SUCCESS:
      let allowancesByID = {...state.allowancesByID};
      delete allowancesByID[action.payload.id];
      return {
        ...state,
        allowancesByID:allowancesByID
      };
    case FETCH_HOUSING_TYPES_SUCCESS:
      return {
        ...state,
        housingTypes: action.payload
      };
    default:
      return state;
  }
}
