export const FETCH_PETTY_CASH = "FETCH_PETTY_CASH";
export const FETCH_PETTY_CASH_SUCCESS = "FETCH_PETTY_CASH_SUCCESS";
export const SHOW_PETTY_CASH_CREATE_FORM = 'SHOW_PETTY_CASH_CREATE_FORM';
export const HIDE_PETTY_CASH_CREATE_FORM = 'HIDE_PETTY_CASH_CREATE_FORM';
export const ADD_PETTY_CASH = 'ADD_PETTY_CASH';
export const ADD_PETTY_CASH_SUCCESS = 'ADD_PETTY_CASH_SUCCESS';
export const SHOW_PETTY_CASH_EDIT_FORM = 'SHOW_PETTY_CASH_EDIT_FORM';
export const HIDE_PETTY_CASH_EDIT_FORM = 'HIDE_PETTY_CASH_EDIT_FORM';
export const EDIT_PETTY_CASH = 'EDIT_PETTY_CASH';
export const EDIT_PETTY_CASH_SUCCESS = 'EDIT_PETTY_CASH_SUCCESS';
export const SHOW_PETTY_CASH_DELETE_FORM = 'SHOW_PETTY_CASH_DELETE_FORM';
export const HIDE_PETTY_CASH_DELETE_FORM = 'HIDE_PETTY_CASH_DELETE_FORM';
export const DELETE_PETTY_CASH = 'DELETE_PETTY_CASH';
export const DELETE_PETTY_CASH_SUCCESS = 'DELETE_PETTY_CASH_SUCCESS';
export const PAY_PETTY_CASH = 'PAY_PETTY_CASH';
export const  EXPORT_FOR_ACCOUNTING = "EXPORT_FOR_ACCOUNTING";

export const exportForAccountingGenerator = (data=[])=>{
  return {
    type:EXPORT_FOR_ACCOUNTING,
    payload:data
  };
};

export const fetchPettyCashGenerator = (data=[])=>{
  return {
    type:FETCH_PETTY_CASH,
    payload:data
  };
};

export const payPettyCashGenerator = (data=[])=>{
  return {
    type:PAY_PETTY_CASH,
    payload:data
  };
};

export const fetchPettyCashSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PETTY_CASH_SUCCESS,
    payload:dataById
  }
};

export const showPettyCashCreateFormGenerator = ()=>{
  return {
    type:SHOW_PETTY_CASH_CREATE_FORM
  };
};
export const hidePettyCashCreateFormGenerator = ()=>{
  return {
    type:HIDE_PETTY_CASH_CREATE_FORM
  };
};
export const addPettyCashGenerator = (data)=>{
  return {
    type: ADD_PETTY_CASH,
    payload: data
  }
};

export const addPettyCashSuccessGenerator = (data)=>{
  return {
    type: ADD_PETTY_CASH_SUCCESS,
    payload: data
  }
};
export const showPettyCashEditFormGenerator = (data)=>{
  return {
    type:SHOW_PETTY_CASH_EDIT_FORM,
    payload:data
  };
};
export const hidePettyCashEditFormGenerator = ()=>{
  return {
    type:HIDE_PETTY_CASH_EDIT_FORM
  };
};
export const editPettyCashGenerator = (data)=>{
  return {
    type: EDIT_PETTY_CASH,
    payload: data
  }
};
export const editPettyCashSuccessGenerator = (data)=>{
  return {
    type: EDIT_PETTY_CASH_SUCCESS,
    payload: data
  }
};
export const showPettyCashDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_PETTY_CASH_DELETE_FORM,
    payload:data
  };
};
export const hidePettyCashDeleteFormGenerator = ()=>{
  return {
    type:HIDE_PETTY_CASH_DELETE_FORM
  };
};
export const deletePettyCashGenerator = (data)=>{
  return {
    type: DELETE_PETTY_CASH,
    payload: data
  }
};
export const deletePettyCashSuccessGenerator = (data)=>{
  return {
    type: DELETE_PETTY_CASH_SUCCESS,
    payload: data
  }
};
