export const ATTENDANCE_FETCH_EMPLOYEE="ATTENDANCE_FETCH_EMPLOYEE";
export const ATTENDANCE_FETCH_EMPLOYEE_SUCCESS="ATTENDANCE_FETCH_EMPLOYEE_SUCCESS";

export const ATTENDANCE_ADD_EMPLOYEE="ATTENDANCE_ADD_EMPLOYEE";
export const ATTENDANCE_ADD_EMPLOYEE_SUCCESS="ATTENDANCE_ADD_EMPLOYEE_SUCCESS";

export const ATTENDANCE_UPDATE_EMPLOYEE="ATTENDANCE_UPDATE_EMPLOYEE";
export const ATTENDANCE_UPDATE_EMPLOYEE_SUCCESS ="ATTENDANCE_UPDATE_EMPLOYEE_SUCCESS";

export const ATTENDANCE_DELETE_EMPLOYEE ="ATTENDANCE_DELETE_EMPLOYEE";
export const ATTENDANCE_DELETE_EMPLOYEE_SUCCESS ="ATTENDANCE_DELETE_EMPLOYEE_SUCCESS";

export const ATTENDANCE_ADD_BULK_EMPLOYEES = "ATTENDANCE_ADD_BULK_EMPLOYEES";
export const ATTENDANCE_ADD_BULK_EMPLOYEES_SUCCESS = "ATTENDANCE_ADD_BULK_EMPLOYEES_SUCCESS";

export const BULK_ASSIGN_SHIFTS_TO_EMPLOYEE ="BULK_ASSIGN_SHIFTS_TO_EMPLOYEE";

export const BULK_DELETE__ATTENDANCE_EMPLOYEES = 'BULK_DELETE__ATTENDANCE_EMPLOYEES';

export const bulkDeleteAttendanceEmployeesGenerator = payload =>{
    return {
        type:BULK_DELETE__ATTENDANCE_EMPLOYEES,
        payload
    }
}

export const bulkAssignShiftToEmployeeGenerator = (payload=[])=>{
    return {
        type:BULK_ASSIGN_SHIFTS_TO_EMPLOYEE,
        payload
    }
}

export const attendanceFetchEmployeesGenerator =(data=[])=>{
    return{
        type :ATTENDANCE_FETCH_EMPLOYEE,
        payload:data
    }
};
export const attendanceFetchEmployeesSuccessGenerator =(data=[])=>{
    return{
        type :ATTENDANCE_FETCH_EMPLOYEE_SUCCESS,
        payload:data
    }

};

export const attendanceDeleteEmployeeGenerator =(data)=>{
    return {
        type:ATTENDANCE_DELETE_EMPLOYEE,
        payload:data
    }
};

export const attendanceDeleteEmployeeSuccessGenerator =(data)=>{
    return {
        type:ATTENDANCE_DELETE_EMPLOYEE_SUCCESS,
        payload:data
    }
};

export const attendanceUpdateEmployeeGenerator =(data)=>{
    return {
        type:ATTENDANCE_UPDATE_EMPLOYEE,
        payload:data
    }
};
export const attendanceUpdateEmployeeSuccessGenerator =(data)=>{
    return{
        type:ATTENDANCE_UPDATE_EMPLOYEE_SUCCESS,
        payload:data
    }
};

export const attendanceAddEmployeeGenerator=(data)=>{
    return{
        type:ATTENDANCE_ADD_EMPLOYEE,
        payload:data
    }
};
export const attendanceAddEmployeeSuccessGenerator =(data)=>{
    return{
        type:ATTENDANCE_ADD_EMPLOYEE_SUCCESS,
        payload:data
    }
};

export const attendanceAddBulkEmployeesGenerator = (data)=>{
    return {
        type:ATTENDANCE_ADD_BULK_EMPLOYEES,
        payload:data
    }
};

export const attendanceAddBulkEmployeesSuccessGenerator = (data)=>{
    return {
        type:ATTENDANCE_ADD_BULK_EMPLOYEES_SUCCESS,
        payload:data
    }
};