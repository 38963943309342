import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_LOAN_LENDERS,
  DELETE_LOAN_LENDERS,
  FETCH_LOAN_LENDER_TRANSACTIONS,
  FETCH_LOAN_LENDERS,
  fetchLoanLendersGenerator,
  fetchLoanLendersSuccessGenerator, fetchLoanLenderTransactionsGenerator,
  fetchLoanLenderTransactionsSuccessGenerator,
  TOP_UP_LOAN_LENDER_WALLET, topUpLoanLenderWalletSuccessGenerator,
  UPDATE_LOAN_LENDERS
} from "../../actions/admin/LoanLenderActions";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";

const fetchLoanLendersRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/lenders',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchLoanLenderTransactionsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/loan/lender/transactions',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const topUpLoanLenderWalletRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/lender/topup',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const addLoanLendersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/loan/lenders',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const updateLoanLendersRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/tozza/loan/lenders/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);
const deleteLoanLendersRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/tozza/loan/lenders/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* fetchLoanLenders({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoanLendersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(fetchLoanLendersSuccessGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLoanLenderTransactions({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchLoanLenderTransactionsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(fetchLoanLenderTransactionsSuccessGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* topUpLoanLenderWallet({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(topUpLoanLenderWalletRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLoanLenderTransactionsGenerator(payload));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addLoanLenders({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(addLoanLendersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLoanLendersGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateLoanLenders({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(updateLoanLendersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLoanLendersGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteLoanLenders({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(deleteLoanLendersRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLoanLendersGenerator(role));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchLoanLendersDefault() {
  yield takeLatest(FETCH_LOAN_LENDERS, fetchLoanLenders);
}
export function* addLoanLendersDefault() {
  yield takeLatest(ADD_LOAN_LENDERS, addLoanLenders);
}
export function* updateLoanLendersDefault() {
  yield takeLatest(UPDATE_LOAN_LENDERS, updateLoanLenders);
}
export function* deleteLoanLendersDefault() {
  yield takeLatest(DELETE_LOAN_LENDERS, deleteLoanLenders);
}
export function* fetchLoanLenderTransactionsDefault() {
  yield takeLatest(FETCH_LOAN_LENDER_TRANSACTIONS, fetchLoanLenderTransactions);
}
export function* topUpLoanLenderWalletDefault() {
  yield takeLatest(TOP_UP_LOAN_LENDER_WALLET, topUpLoanLenderWallet);
}
export default function* rootSaga() {
  yield all([fork(fetchLoanLendersDefault),
    fork(addLoanLendersDefault),
    fork(updateLoanLendersDefault),
    fork(deleteLoanLendersDefault),
    fork(fetchLoanLenderTransactionsDefault),
    fork(topUpLoanLenderWalletDefault)
  ]);
}
