export const FETCH_PAYMENT_ACTIVATIONS = "FETCH_PAYMENT_ACTIVATIONS";
export const FETCH_PAYMENT_ACTIVATIONS_SUCCESS = "FETCH_PAYMENT_ACTIVATIONS_SUCCESS";

export const ADD_PAYMENT_ACTIVATIONS = "ADD_PAYMENT_ACTIVATIONS";
export const ADD_PAYMENT_ACTIVATIONS_SUCCESS = "ADD_PAYMENT_ACTIVATIONS_SUCCESS";

export const UPDATE_PAYMENT_ACTIVATIONS = "UPDATE_PAYMENT_ACTIVATIONS";
export const UPDATE_PAYMENT_ACTIVATIONS_SUCCESS = "UPDATE_PAYMENT_ACTIVATIONS_SUCCESS";

export const DELETE_PAYMENT_ACTIVATIONS = "DELETE_PAYMENT_ACTIVATIONS";
export const DELETE_PAYMENT_ACTIVATIONS_SUCCESS = "DELETE_PAYMENT_ACTIVATIONS_SUCCESS";


export const fetchPaymentActivationsGenerator = (data=[])=>{
    return {
        type:FETCH_PAYMENT_ACTIVATIONS,
        payload:data
    }
} ;
export const fetchPaymentActivationsSuccessGenerator = (data=[])=>{
    return {
        type:FETCH_PAYMENT_ACTIVATIONS_SUCCESS,
        payload:data
    }
} ;
export const addPaymentActivationsGenerator = (data=[])=>{
    return {
        type:ADD_PAYMENT_ACTIVATIONS,
        payload:data
    }
} ;
export const addPaymentActivationsSuccessGenerator = (data=[])=>{
    return {
        type:ADD_PAYMENT_ACTIVATIONS_SUCCESS,
        payload:data
    }
} ;

export const deletePaymentActivationsGenerator = (data=[])=>{
    return {
        type:DELETE_PAYMENT_ACTIVATIONS,
        payload:data
    }
} ;
export const deletePaymentActivationsSuccessGenerator = (data=[])=>{
    return {
        type:DELETE_PAYMENT_ACTIVATIONS_SUCCESS,
        payload:data
    }
} ;

