import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_EXIT_REASON, DELETE_EXIT_REASON, deleteExitReasonSuccessGenerator, EDIT_EXIT_REASON, editExitReasonSuccessGenerator,
  FETCH_EXIT_REASONS,
  fetchExitReasonsSuccessGenerator
} from "../../actions/payroll/ExitReasonActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addExitReasonSuccessGenerator
} from "../../actions/payroll/ExitReasonActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchExitReasonsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/exit-reasons',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addExitReasonRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/exit-reasons',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteExitReasonRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/exit-reasons/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editExitReasonRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/exit-reasons/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchExitReasons({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchExitReasonsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchExitReasonsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addExitReason({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addExitReasonRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(addExitReasonSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteExitReason({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteExitReasonRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(deleteExitReasonSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editExitReason({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editExitReasonRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editExitReasonSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchExitReasonsDefault() {
  yield takeLatest(FETCH_EXIT_REASONS, fetchExitReasons)
}
export function* addExitReasonDefault() {
  yield takeLatest(ADD_EXIT_REASON, addExitReason);
}
export function* deleteExitReasonDefault() {
  yield takeLatest(DELETE_EXIT_REASON, deleteExitReason);
}
export function* editExitReasonDefault() {
  yield takeLatest(EDIT_EXIT_REASON, editExitReason);
}
export default function* rootSaga() {
  yield all([fork(fetchExitReasonsDefault),
    fork(addExitReasonDefault),
    fork(deleteExitReasonDefault),
    fork(editExitReasonDefault)
  ]);
}
