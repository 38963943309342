import {Helpers} from "../../../util/Helpers";
import axios from "axios/index";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {all, call, fork, put} from "redux-saga/effects";
import {takeLatest} from "redux-saga/effects";
import {
  ADD_LEAVE_BALANCE,
  addLeaveBalanceSuccessGenerator,
  BULK_ASSIGN_LEAVE_BALANCE,
  DELETE_LEAVE_BALANCE,
  deleteLeaveBalanceSuccessGenerator,
  EDIT_LEAVE_BALANCE,
  editLeaveBalanceSuccessGenerator, FETCH_EMPLOYEE_ENTITLED_LEAVES,
  FETCH_EMPLOYEE_LEAVE_DAYS,
  FETCH_LEAVE_BALANCE,
  fetchEmployeeLeaveDaysByCategorySuccessGenerator,
  fetchEntitledLeavesSuccessGenerator,
  fetchLeaveBalanceGenerator,
  fetchLeaveBalanceSuccessGenerator, SINGLE_EDIT_LEAVE_BALANCE
} from "../../actions/payroll/LeaveBalanceActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const bulkAssignLeaveBalanceRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/assign/leavebalances',requestData,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response =>response)
        .catch(error=>error.response);

function* bulkAssignLeaveBalance({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    const response = yield call(bulkAssignLeaveBalanceRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchLeaveBalanceGenerator());
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* bulkAssignLeaveBalanceDefault(){
  yield takeLatest(BULK_ASSIGN_LEAVE_BALANCE,bulkAssignLeaveBalance);
}
const fetchLeaveBalancesRequests = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/leavebalances',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);
const fetchEmployeeEntitledLeaveRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/leavebalances/get/entitled/leaves',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchLeaveBalances({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchLeaveBalancesRequests, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveBalance = response.data.data;
        yield put(fetchLeaveBalanceSuccessGenerator(leaveBalance));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchLeaveBalancesDefault() {
  yield takeLatest(FETCH_LEAVE_BALANCE,fetchLeaveBalances)
}
////end fetch
function* fetchEmployeeEntitledLeaveBalances({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeEntitledLeaveRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaves = response.data.data;
        yield put(fetchEntitledLeavesSuccessGenerator(leaves));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEntitledLeaveDefault() {
  yield takeLatest(FETCH_EMPLOYEE_ENTITLED_LEAVES,fetchEmployeeEntitledLeaveBalances)
}
///edit addition category
const editSingleLeaveBalancesRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/single/leavebalances/edit',requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* editSingleLeaveBalances({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editSingleLeaveBalancesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLeaveBalanceGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

const editLeaveBalancesRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/assign/leavebalances',requestParameters)
    .then(response=>response)
    .catch(error => error.message);

const fetchEmployeeLeavesDaysRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/hrm/leave-balance/leave-type',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

function* fetchEmployeeLeavesDays({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeLeavesDaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchEmployeeLeaveDaysByCategorySuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* updateLeaveBalance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editLeaveBalancesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLeaveBalanceGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateLeaveBalanceDefault() {
  yield takeLatest(EDIT_LEAVE_BALANCE,updateLeaveBalance)
}
//// add new addition category
const addLeaveBalanceRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/leavebalances',requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* addLeaveBalance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addLeaveBalanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchLeaveBalanceGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addLeaveBalanceDefault() {
  yield takeLatest(ADD_LEAVE_BALANCE,addLeaveBalance)
}
///end add addition Category
//// delete addition category
const deleteLeaveBalanceRequest = async (requestParameters)=>
  await axios.delete(API_ENDPOINT+'/hrm/leavebalances/'+requestParameters.employee_id,{params:requestParameters})
    .then(response=>response)
    .catch(error => error.message);

function* deleteLeaveBalance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteLeaveBalanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveBalance = response.data.data;
        yield put(fetchLeaveBalanceGenerator(leaveBalance));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteLeaveBalanceDefault() {
  yield takeLatest(DELETE_LEAVE_BALANCE,deleteLeaveBalance)
}
////end delete category
export function* fetchEmployeeLeavesDaysDefault() {
  yield takeLatest(FETCH_EMPLOYEE_LEAVE_DAYS, fetchEmployeeLeavesDays);
}

export function* editSingleLeaveBalancesDefault(){
  yield takeLatest(SINGLE_EDIT_LEAVE_BALANCE,editSingleLeaveBalances);
}
export default function* rootSagas(){
  yield all([
    fork(fetchLeaveBalancesDefault),
    fork(updateLeaveBalanceDefault),
    fork(addLeaveBalanceDefault),
    fork(deleteLeaveBalanceDefault),
    fork(fetchEmployeeLeavesDaysDefault),
    fork(bulkAssignLeaveBalanceDefault),
    fork(fetchEntitledLeaveDefault),
    fork(editSingleLeaveBalancesDefault)
  ])
}
