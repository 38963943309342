import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import {
  ADD_SMS,
  addSmsSuccessGenerator,
  FETCH_SMS,
  FETCH_SMS_RECIPIENTS, fetchSmsGenerator,
  fetchSmsRecipientsSuccessGenerator,
  fetchSmsSuccessGenerator
} from "../../actions/payroll/SmsActions";

const fetchSmsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/display-message',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addSmsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/message',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const fetchSmsRecipientsRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/recipients/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchSms({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchSmsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let assets = response.data.data;
        yield put(fetchSmsSuccessGenerator(assets));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addSms({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(addSmsRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        /*let asset = response.data.data;
        yield put(addSmsSuccessGenerator(asset));*/
        yield put(fetchSmsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchSmsRecipients({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchSmsRecipientsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let recipients = response.data.data;
        yield put(fetchSmsRecipientsSuccessGenerator(recipients));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchSmsDefault() {
  yield takeLatest(FETCH_SMS, fetchSms)
}
export function* addSmsDefault() {
  yield takeLatest(ADD_SMS, addSms);
}
export function* fetchSmsRecipientsDefault(){
  yield takeLatest(FETCH_SMS_RECIPIENTS,fetchSmsRecipients)
}
export default function* rootSaga() {
  yield all([
    fork(fetchSmsDefault),
    fork(addSmsDefault),
    fork(fetchSmsRecipientsDefault)
  ]);
}
