export const FETCH_ADMIN_SALARY_ADVANCE_SETTINGS = "FETCH_ADMIN_SALARY_ADVANCE_SETTINGS";
export const FETCH_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS = "FETCH_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const CREATE_ADMIN_SALARY_ADVANCE_SETTINGS = "CREATE_ADMIN_SALARY_ADVANCE_SETTINGS";
export const CREATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS = "CREATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS = "UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS";
export const UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS = "UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const DELETE_ADMIN_SALARY_ADVANCE_SETTINGS = "DELETE_ADMIN_SALARY_ADVANCE_SETTINGS";
export const DELETE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS = "DELETE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const PAY_ADMIN_SALARY_ADVANCE = "PAY_ADMIN_SALARY_ADVANCE";

export const FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS = "FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS";
export const FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS = "FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS = "CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS";
export const CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS = "CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const UPDATE_COMPANIES_SALARY_ADVANCE_SETTINGS = "UPDATE_COMPANIES_SALARY_ADVANCE_SETTINGS";
export const APPROVE_COMPANIES_SALARY_ADVANCE_SETTINGS = "APPROVE_COMPANIES_SALARY_ADVANCE_SETTINGS";
export const REJECT_COMPANIES_SALARY_ADVANCE_SETTINGS = "REJECT_COMPANIES_SALARY_ADVANCE_SETTINGS";

export const FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS = "FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS";
export const FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS = "FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS = "CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS";
export const CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS = "CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS";

export const UPDATE_GLOBAL_SALARY_ADVANCE_SETTINGS = "UPDATE_GLOBAL_SALARY_ADVANCE_SETTINGS";
export const APPROVE_GLOBAL_SALARY_ADVANCE_SETTINGS = "APPROVE_GLOBAL_SALARY_ADVANCE_SETTINGS";
export const REJECT_GLOBAL_SALARY_ADVANCE_SETTINGS = "REJECT_GLOBAL_SALARY_ADVANCE_SETTINGS";

export const payAdminSalaryAdvanceGenerator = (payload=[])=>{
  return{
    type:PAY_ADMIN_SALARY_ADVANCE,
    payload
  }
}

export const fetchAdminSalaryAdvanceGenerator = (data=[])=>{
  return {
    type:FETCH_ADMIN_SALARY_ADVANCE_SETTINGS,
    payload:data
  };
};

export const fetchAdminSalaryAdvanceSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload:dataById
  }
};

export const createAdminSalaryAdvanceGenerator = (data)=>{
  return {
    type: CREATE_ADMIN_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};

export const createAdminSalaryAdvanceSuccessGenerator = (data)=>{
  return {
    type: CREATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload: data
  }
};

export const editAdminSalaryAdvanceGenerator = (data)=>{
  return {
    type: UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};

export const editAdminSalaryAdvanceSuccessGenerator = (data)=>{
  return {
    type: UPDATE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload: data
  }
};

export const deleteAdminSalaryAdvanceSettingsGenerator = (data)=>{
  return {
    type: DELETE_ADMIN_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};

export const deleteAdminSalaryAdvanceSettingsSuccessGenerator = (data)=>{
  return {
    type: DELETE_ADMIN_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload: data
  }
};


/** Salary advance company settings */
export const fetchCompaniesSalaryAdvanceSettingsGenerator = (data=[])=>{
  return {
    type:FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS,
    payload:data
  };
};
export const fetchCompaniesSalaryAdvanceSettingsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload:dataById
  }
};

export const createCompaniesSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
export const createCompaniesSalaryAdvanceSettingsSuccessGenerator = (data) => {
  return {
    type: CREATE_COMPANIES_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload: data
  }
};

export const updateCompaniesSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: UPDATE_COMPANIES_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
export const approveCompaniesSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: APPROVE_COMPANIES_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
export const rejectCompaniesSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: REJECT_COMPANIES_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};

/** Salary advance global settings */
export const fetchGlobalSalaryAdvanceSettingsGenerator = (data=[])=>{
  return {
    type:FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS,
    payload:data
  };
};
export const fetchGlobalSalaryAdvanceSettingsSuccessGenerator = (data)=> {
  return {
    type:FETCH_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload:data
  }
};
export const createGlobalSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
export const createGlobalSalaryAdvanceSettingsSuccessGenerator = (data) => {
  return {
    type: CREATE_GLOBAL_SALARY_ADVANCE_SETTINGS_SUCCESS,
    payload: data
  }
};

export const updateGlobalSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: UPDATE_GLOBAL_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
export const approveGlobalSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: APPROVE_GLOBAL_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
export const rejectGlobalSalaryAdvanceSettingsGenerator = (data) => {
  return {
    type: REJECT_GLOBAL_SALARY_ADVANCE_SETTINGS,
    payload: data
  }
};
