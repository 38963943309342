import { call, put,all,fork,takeLatest } from "redux-saga/effects";
import  axios from "axios";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  DOWNLOAD_JENGA_STATEMENT,
  FETCH_MPESA_SETTINGS, fetchMpesaSettingsGenerator,
  fetchMpesaSettingsSuccessGenerator, REQUEST_MPESA_BALANCE, UPDATE_MPESA_SETTING,
  updateMpesaSettingSuccessGenerator
} from "../../actions/admin/MpesaSettingActions";

const fetchMpesaSettingsRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+"/tozza/mpesa/settings",requestData)
    .then(response => response)
    .catch(error => error.response);
const updateMpesaSettingRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+"/tozza/mpesa/settings/update",requestData)
        .then(response => response)
        .catch(error => error.response);
const downloadJengaStatementRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+"/tozza/jenga/download/statement",requestData)
    .then(response => response)
    .catch(error => error.response);

  function* fetchMpesaSettings({type,payload}) {
      yield put(showAppLoaderGenerator(type)) ;
     try{
         let request = {...payload,token:Helpers.token()} ;
             const response = yield call(fetchMpesaSettingsRequest,request);
             if(response.status === 200 || response.status === 201) {
                 if(response.data.success){
                     const data  = response.data.data;
                       yield put(fetchMpesaSettingsSuccessGenerator(data));
                 }else{
                     yield put(showMessageGenerator(response.data.message,'error'))
                 }

             }else if(response.status === 401){
                Helpers.clearAuthData();
          yield put(userSignOutSuccessGenerator());
          }else {
              yield put(showMessageGenerator(response.statusText,'error'));
          }
     }catch (e) {
        yield showMessageGenerator(e.statusText,'error') ;
     }
      yield put(hideAppLoaderGenerator(type));
  }
function* updateMpesaSettings({type,payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try{
        let request = {...payload,token:Helpers.token()} ;
        const response = yield call(updateMpesaSettingRequest,request);
        if(response.status === 200 || response.status === 201) {
            if(response.data.success){
                const data  = response.data.data;
                yield put(updateMpesaSettingSuccessGenerator(data));
                yield put(showMessageGenerator(response.data.message,'success'))  ;
            }else{
                yield put(showMessageGenerator(response.data.message,'error'))
            }

        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield showMessageGenerator(e.statusText,'error') ;
    }
    yield put(hideAppLoaderGenerator(type));
}
function* requestMpesaBalance({type,payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try{
        let request = {...payload,token:Helpers.token()} ;
        const response = yield call(fetchMpesaSettingsRequest,request);
        if(response.status === 200 || response.status === 201) {
            if(response.data.success){
                yield put(fetchMpesaSettingsGenerator());
                yield put(showMessageGenerator(response.data.message,'success'))  ;
            }else{
                yield put(showMessageGenerator(response.data.message,'error'))
            }

        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield showMessageGenerator(e.statusText,'error') ;
    }
    yield put(hideAppLoaderGenerator(type));
}
function* downloadJengaStatement({type,payload}) {
  yield put(showAppLoaderGenerator(type)) ;
  try{
    let request = {...payload,token:Helpers.token()} ;
    const response = yield call(downloadJengaStatementRequest,request);
    if(response.status === 200 || response.status === 201) {
      if(response.data.success){
        if(response.data.data.hasOwnProperty('file_path')){
          window.open(API_ADDRESS+response.data.data.file_path, '_blank');
        }
        yield put(showMessageGenerator(response.data.message,'success'))  ;
      }else{
        yield put(showMessageGenerator(response.data.message,'error'))
      }

    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield showMessageGenerator(e.statusText,'error') ;
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchMpesaSettingsDefault(){
  yield takeLatest(FETCH_MPESA_SETTINGS,fetchMpesaSettings);
}
export function* updateMpesaSettingsDefault(){
  yield takeLatest(UPDATE_MPESA_SETTING,updateMpesaSettings);
}
export function* requestMpesaBalanceDefault(){
    yield takeLatest(REQUEST_MPESA_BALANCE,requestMpesaBalance);
}
export function* downloadJengaStatementDefault(){
  yield takeLatest(DOWNLOAD_JENGA_STATEMENT, downloadJengaStatement);
}

export default function* rootSaga () {
  yield all([
    fork(fetchMpesaSettingsDefault) ,
    fork(updateMpesaSettingsDefault) ,
    fork(requestMpesaBalanceDefault) ,
    fork(downloadJengaStatementDefault)
  ])
}
