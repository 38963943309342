export const FETCH_REPORTS_MONTHS = "FETCH_REPORT_MONTHS";
export const FETCH_REPORTS_MONTHS_SUCCESS = "FETCH_REPORT_MONTHS_SUCCESS";

export const FETCH_LOAN_REPAYMENTS_REPORT = "FETCH_LOAN_REPAYMENTS_REPORT";
export const FETCH_LOAN_REPAYMENTS_REPORT_SUCCESS = "FETCH_LOAN_REPAYMENTS_REPORT_SUCCESS";
export const EXPORT_LOAN_REPAYMENTS_REPORT = "EXPORT_LOAN_REPAYMENTS_REPORT";

export const FETCH_LOAN_EARNED_INTEREST_REPORT = "FETCH_LOAN_EARNED_INTEREST_REPORT";
export const FETCH_LOAN_EARNED_INTEREST_REPORT_SUCCESS = "FETCH_LOAN_EARNED_INTEREST_REPORT_SUCCESS";
export const EXPORT_LOAN_EARNED_INTEREST_REPORT = "EXPORT_LOAN_EARNED_INTEREST_REPORT";

export const FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT = "FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT";
export const FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT_SUCCESS = "FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT_SUCCESS";
export const EXPORT_LOAN_EARNED_PENALTY_AND_FEES_REPORT = "EXPORT_LOAN_EARNED_PENALTY_AND_FEES_REPORT";

export const EXPORT_LOAN_OVERDUE_REPORT = "EXPORT_LOAN_OVERDUE_REPORT";
export const EXPORT_LOAN_OVERDUE_REPORT_SUCCESS = "EXPORT_LOAN_OVERDUE_REPORT_SUCCESS";

export const fetchReportMonthsGenerator = (data) => {
  return {
    type: FETCH_REPORTS_MONTHS,
    payload: data
  };
};
export const fetchReportMonthsSuccessGenerator = (data) => {
  return {
    type: FETCH_REPORTS_MONTHS_SUCCESS,
    payload: data
  };
};
export const fetchLoanRepaymentsReportGenerator = (data) => {
  return {
    type: FETCH_LOAN_REPAYMENTS_REPORT,
    payload: data
  };
};
export const fetchLoanRepaymentsReportSuccessGenerator = (data) => {
  return {
    type: FETCH_LOAN_REPAYMENTS_REPORT_SUCCESS,
    payload: data
  };
};
export const exportLoanRepaymentsReportGenerator = (data) => {
  return {
    type: EXPORT_LOAN_REPAYMENTS_REPORT,
    payload: data
  };
};
export const fetchLoanEarnedInterestReportGenerator = (data) => {
  return {
    type: FETCH_LOAN_EARNED_INTEREST_REPORT,
    payload: data
  };
};
export const fetchLoanEarnedInterestReportSuccessGenerator = (data) => {
  return {
    type: FETCH_LOAN_EARNED_INTEREST_REPORT_SUCCESS,
    payload: data
  };
};
export const exportLoanEarnedInterestReportGenerator = (data) => {
  return {
    type: EXPORT_LOAN_EARNED_INTEREST_REPORT,
    payload: data
  };
};
export const fetchLoanEarnedPenaltyAndFeesReportGenerator = (data) => {
  return {
    type: FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT,
    payload: data
  };
};
export const fetchLoanEarnedPenaltyAndFeesReportSuccessGenerator = (data) => {
  return {
    type: FETCH_LOAN_EARNED_PENALTY_AND_FEES_REPORT_SUCCESS,
    payload: data
  };
};
export const exportLoanEarnedPenaltyAndFeesReportGenerator = (data) => {
  return {
    type: EXPORT_LOAN_EARNED_PENALTY_AND_FEES_REPORT,
    payload: data
  };
};
export const exportLoanOverdueReportGenerator = (data) => {
  return {
    type: EXPORT_LOAN_OVERDUE_REPORT,
    payload: data
  };
};
export const exportLoanOverdueReportSuccessGenerator = (data) => {
  return {
    type: EXPORT_LOAN_OVERDUE_REPORT_SUCCESS,
    payload: data
  };
};
