import {FETCH_PAYSLIP_SUCCESS} from '../../actions/payroll/PaySlipActions';

const INIT_STATE = {
    paySlip: []
};

export default(state = INIT_STATE,action)=> {
    switch (action.type) {
        case 'FETCH_PAYSLIP_SUCCESS':
            return {
                ...state,
                paySlip: action.payload
            };
        case 'SEND_PAYSLIP_SUCCESS':
            return {};
        default:
            return state;
    }
};
