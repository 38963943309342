export const FETCH_FINANCED_SALARY_ADVANCES = "FETCH_FINANCED_SALARY_ADVANCES";
export const FETCH_FINANCED_SALARY_ADVANCES_SUCCESS = "FETCH_FINANCED_SALARY_ADVANCES_SUCCESS";

export const APPROVE_DECLINE_SALARY_ADVANCE = "APPROVE_DECLINE_SALARY_ADVANCE";

export const FETCH_SALARY_ADVANCE_LOANS = "FETCH_SALARY_ADVANCE_LOANS";
export const FETCH_SALARY_ADVANCE_LOANS_SUCCESS = "FETCH_SALARY_ADVANCE_LOANS_SUCCESS";

export const UPDATE_SALARY_ADVANCE_LOANS = "UPDATE_SALARY_ADVANCE_LOANS";

export const TOPUP_SALARY_ADVANCE_LOANS = "TOPUP_SALARY_ADVANCE_LOANS";
export const PAUSE_SALARY_ADVANCE_LOANS = "PAUSE_SALARY_ADVANCE_LOANS";
export const UNPAUSE_SALARY_ADVANCE_LOANS = "UNPAUSE_SALARY_ADVANCE_LOANS";
export const RECORD_LUMPSUM_SALARY_ADVANCE_LOANS = "RECORD_LUMPSUM_SALARY_ADVANCE_LOANS";

export const approveDeclineSalaryAdvanceGenerator = (payload = []) => {
  return {
    type: APPROVE_DECLINE_SALARY_ADVANCE,
    payload
  }
}

export const fetchFinancedSalaryAdvanceGenerator = (data = []) => {
  return {
    type: FETCH_FINANCED_SALARY_ADVANCES,
    payload: data
  };
};

export const fetchFinancedSalaryAdvanceSuccessGenerator = (dataById) => {
  return {
    type: FETCH_FINANCED_SALARY_ADVANCES_SUCCESS,
    payload: dataById
  }
};

/** Salary advance loans */
export const fetchFinancedSalaryAdvanceLoansGenerator = (data = []) => {
  return {
    type: FETCH_SALARY_ADVANCE_LOANS,
    payload: data
  };
};

export const fetchFinancedSalaryAdvanceLoansSuccessGenerator = (data) => {
  return {
    type: FETCH_SALARY_ADVANCE_LOANS_SUCCESS,
    payload: data
  }
};

export const updateSalaryAdvanceLoansGenerator = (payload = []) => {
  return {
    type: UPDATE_SALARY_ADVANCE_LOANS,
    payload
  }
}

export const topupSalaryAdvanceLoansGenerator = (payload = []) => {
  return {
    type: TOPUP_SALARY_ADVANCE_LOANS,
    payload
  }
}

export const pauseSalaryAdvanceLoansGenerator = (payload = []) => {
  return {
    type: PAUSE_SALARY_ADVANCE_LOANS,
    payload
  }
}

export const unpauseSalaryAdvanceLoansGenerator = (payload = []) => {
  return {
    type: UNPAUSE_SALARY_ADVANCE_LOANS,
    payload
  }
}

export const recordLumpsumSalaryAdvanceLoansGenerator = (payload = []) => {
  return {
    type: RECORD_LUMPSUM_SALARY_ADVANCE_LOANS,
    payload
  }
}
