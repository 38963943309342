import React from 'react';
import {connect} from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import withRouter from 'react-router/es/withRouter';
import { Helpers } from '../../util/Helpers';
import { userSignOutGenerator } from '../../appRedux/actions/AuthActions';
import {
  SELECTED_EMPLOYEE_ID_FOR_PORTAL
} from "../../constants/SessionKeys";
import {API_ADDRESS} from "../../constants/ActionTypes";

class UserInfo extends React.Component {
  handleEmployeePortalLink = () => {
    let employee_id = Helpers.authUserEmployeeID();
    Helpers.saveItem(SELECTED_EMPLOYEE_ID_FOR_PORTAL, {id: employee_id, for:'auth_employee'});
    this.props.history.push('/portal/profile');
  };

  handleUserProfileLink = () => {
    this.props.history.push('/common/profile');
  };

  handleTransactionHistoryLink = () => {
    this.props.history.push('/payouts/transaction-history');
  };

  /*handleMyLoansLink = () => {
    this.props.history.push('/hrm/myloans');
  };*/

  handleSignOut = () => {
    Helpers.clearAuthData();
    this.props.userSignOutGenerator();
  };

  render() {
    const user_name = Helpers.authUserName();
    const avatar = Helpers.authAvatarSrc();
    const roleName = Helpers.authRoleName();
    return (
      <div>
        <div className="user-profile">
          <img
            className="user-avatar border-0 size-40 rounded-circle"
            src={ Helpers.imageExist(avatar) ? avatar : API_ADDRESS+'/images/icons/flic/png/avatar.png' }
            alt="User" />
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{user_name}</h4>
            <small className="user-description">{roleName}</small>
          </div>
        </div>
        {
          Helpers.authUserCanAccessPortal() &&
          <span className="jr-link dropdown-item text-muted" onClick={this.handleEmployeePortalLink}>
            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-1" />
            My Employee Portal
          </span>
        }
        {
            Helpers.authUserHasPermissions() &&
            <span className="jr-link dropdown-item text-muted" onClick={this.handleUserProfileLink}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-1" />
              My Profile
            </span>

        }

        {/*<span className="jr-link dropdown-item text-muted" onClick={this.handleMyLoansLink}>
            <i className="zmdi zmdi-money zmdi-hc-fw mr-1" />
            My Loans
          </span>*/}
        {/*{
          Helpers.authUserCan('payouts.wallet.view_transactions') &&
          <span className="jr-link dropdown-item text-muted" onClick={this.handleTransactionHistoryLink}>
            <i className="zmdi zmdi-money zmdi-hc-fw mr-1" />
            Wallet
          </span>
        }*/}
        <span className="jr-link dropdown-item text-muted" onClick={this.handleSignOut}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
          <IntlMessages id="popup.logout" />
        </span>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser, role} = auth;
  return {authUser, role};
};
export default connect(mapStateToProps, {userSignOutGenerator})(withRouter(UserInfo));
