import {
    ADD_SUBSCRIPTION_DAYS_SUCCESS,
    FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS, FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS
} from "../../actions/admin/SubscriptionActions";

const INIT_STATE ={
    activeSubscriptionsByID:{} ,
    inActiveSubscriptionsByID:{} ,
};
export default (state = INIT_STATE,action)=>{
    let subscription;    let inactiveSubs;
    switch (action.type) {
        case FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                activeSubscriptionsByID: action.payload
            };
        case FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                inActiveSubscriptionsByID: action.payload
            };
        case ADD_SUBSCRIPTION_DAYS_SUCCESS:
            subscription = {...state.activeSubscriptionsByID};
            subscription[action.payload.id] = action.payload;
            inactiveSubs = {...state.inActiveSubscriptionsByID};
            delete inactiveSubs[action.payload.id];
            return{
                ...state,
                activeSubscriptionsByID:subscription ,
                inActiveSubscriptionsByID:inactiveSubs
            };
        default:
            return {...state};
    }
}