import {DOWNLOAD_EMPLOYEES_WITHOUT_DAYS} from "../attendance/WorkedDaysAction";

export const FETCH_PAYROLLS = "FETCH_PAYROLLS";
export const FETCH_PAYROLLS_SUCCESS = "FETCH_PAYROLLS_SUCCESS";
export const SHOW_PAYROLL_CREATE_FORM = 'SHOW_PAYROLL_CREATE_FORM';//Create: Run Payroll
export const HIDE_PAYROLL_CREATE_FORM = 'HIDE_PAYROLL_CREATE_FORM';
export const ADD_PAYROLL = 'ADD_PAYROLL';
export const PAY_PAYROLL = 'PAY_PAYROLL';
export const ADD_PAYROLL_SUCCESS = 'ADD_PAYROLL_SUCCESS';
export const SHOW_PAYROLL_EDIT_FORM = 'SHOW_PAYROLL_EDIT_FORM';
export const HIDE_PAYROLL_EDIT_FORM = 'HIDE_PAYROLL_EDIT_FORM';
export const EDIT_PAYROLL = 'EDIT_PAYROLL';
export const EDIT_PAYROLL_SUCCESS = 'EDIT_PAYROLL_SUCCESS';
export const SHOW_PAYROLL_DELETE_FORM = 'SHOW_PAYROLL_DELETE_FORM';//Delete: Cancel payroll
export const HIDE_PAYROLL_DELETE_FORM = 'HIDE_PAYROLL_DELETE_FORM';
export const DELETE_PAYROLL = 'DELETE_PAYROLL';
export const DELETE_PAYROLL_SUCCESS = 'DELETE_PAYROLL_SUCCESS';
export const SHOW_PAYSLIPS_SEND_FORM = 'SHOW_PAYSLIPS_SEND_FORM';
export const HIDE_PAYSLIPS_SEND_FORM = 'HIDE_PAYSLIPS_SEND_FORM';
export const SEND_PAYSLIPS = 'SEND_PAYSLIPS';
export const SEND_PAYSLIPS_SUCCESS = 'SEND_PAYSLIPS_SUCCESS';
export const VIEW_MUSTER_ROLL = "VIEW_MUSTER_ROLL";
export const FETCH_QUEUED_PAYROLLS = "FETCH_QUEUED_PAYROLLS";
export const FETCH_QUEUED_PAYROLLS_SUCCESS = "FETCH_QUEUED_PAYROLLS_SUCCESS";
export const DELETE_QUEUED_PAYROLL = "DELETE_QUEUED_PAYROLL";
export const DELETE_QUEUED_PAYROLL_SUCCESS = "DELETE_QUEUED_PAYROLL_SUCCESS";
export const DOWNLOAD_EMPLOYEE_P9 ='DOWNLOAD_EMPLOYEE_P9';
export const SEND_EMPLOYEE_P9 = 'SEND_EMPLOYEE_P9';
export const GENERATE_EMPLOYEE_P9 = 'GENERATE_EMPLOYEE_P9';
export const MARK_PAYROLL_AS_PAID = 'MARK_PAYROLL_AS_PAID';

export const generateEmployeeP9Generator =(data)=>{
  return {
    type:GENERATE_EMPLOYEE_P9,
    payload:data
  }
};
export const sendEmployeeP9Generator =(data)=>{
  return{
    type:SEND_EMPLOYEE_P9,
    payload:data
  }
};

export const downloadEmployeeP9Generator = (data)=>{
  return {
    type:DOWNLOAD_EMPLOYEE_P9,
    payload:data
  }
};
export const fetchPayrollsGenerator = (data=[])=>{
  return {
    type:FETCH_PAYROLLS,
    payload:data
  };
};

export const fetchPayrollsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PAYROLLS_SUCCESS,
    payload:dataById
  }
};

export const showPayrollCreateFormGenerator = ()=>{
  return {
    type:SHOW_PAYROLL_CREATE_FORM
  };
};
export const hidePayrollCreateFormGenerator = ()=>{
  return {
    type:HIDE_PAYROLL_CREATE_FORM
  };
};
export const addPayrollGenerator = (data)=>{
  return {
    type: ADD_PAYROLL,
    payload: data
  }
};
export const payPayrollGenerator = (data)=>{
  return {
    type: PAY_PAYROLL,
    payload: data
  }
};

export const addPayrollSuccessGenerator = (data)=>{
  return {
    type: ADD_PAYROLL_SUCCESS,
    payload: data
  }
};
export const showPayrollEditFormGenerator = (data)=>{
  return {
    type:SHOW_PAYROLL_EDIT_FORM,
    payload:data
  };
};
export const hidePayrollEditFormGenerator = ()=>{
  return {
    type:HIDE_PAYROLL_EDIT_FORM
  };
};
export const editPayrollGenerator = (data)=>{
  return {
    type: EDIT_PAYROLL,
    payload: data
  }
};
export const editPayrollSuccessGenerator = (data)=>{
  return {
    type: EDIT_PAYROLL_SUCCESS,
    payload: data
  }
};
export const showPayrollDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_PAYROLL_DELETE_FORM,
    payload:data
  };
};
export const hidePayrollDeleteFormGenerator = ()=>{
  return {
    type:HIDE_PAYROLL_DELETE_FORM
  };
};
export const deletePayrollGenerator = (data)=>{
  return {
    type: DELETE_PAYROLL,
    payload: data
  }
};
export const deletePayrollSuccessGenerator = (data)=>{
  return {
    type: DELETE_PAYROLL_SUCCESS,
    payload: data
  }
};
export const showPayslipsSendFormGenerator = (data)=>{
   return {
     type:SHOW_PAYSLIPS_SEND_FORM,
     payload:data
   }
};
export const hidePayslipsSendFormGenerator = (data)=>{
   return {
     type:HIDE_PAYSLIPS_SEND_FORM,
     payload:data
   }
};
export const sendPayslipsGenerator = (data)=>{
   return {
     type:SEND_PAYSLIPS,
     payload:data
   }
};
export const sendPayslipsSuccessGenerator = (data)=>{
   return {
     type:SEND_PAYSLIPS_SUCCESS,
     payload:data
   }
};
export const viewMusterRollGenerator = (data)=>{
   return {
     type:VIEW_MUSTER_ROLL,
     payload:data
   }
};
export const fetchQueuedPayrollsGenerator = (data=[])=>{
  return {
    type:FETCH_QUEUED_PAYROLLS,
    payload:data
  };
};

export const fetchQueuedPayrollsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_QUEUED_PAYROLLS_SUCCESS,
    payload:dataById
  }
};

export const deleteQueuedPayrollGenerator = (data)=>{
  return {
    type: DELETE_QUEUED_PAYROLL,
    payload: data
  }
};
export const deleteQueuedPayrollSuccessGenerator = (data)=>{
  return {
    type: DELETE_QUEUED_PAYROLL_SUCCESS,
    payload: data
  }
};
export const markPayrollAsPaidGenerator = (data)=>{
  return {
    type: MARK_PAYROLL_AS_PAID,
    payload: data
  }
};
