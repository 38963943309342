import {
  ADD_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  EDIT_ROLE_SUCCESS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_ROLES_SUCCESS,
  HIDE_ROLE_CREATE_FORM,
  HIDE_ROLE_DELETE_FORM,
  HIDE_ROLE_EDIT_FORM,
  SHOW_ROLE_CREATE_FORM,
  SHOW_ROLE_DELETE_FORM,
  SHOW_ROLE_EDIT_FORM
} from '../../actions/common/RoleActions';

const INIT_STATE = {
  rolesByID:{},
  showRoleCreateForm:false,
  showRoleEditForm:false,
  roleToEdit:false,
  showRoleDeleteForm:false,
  roleToDelete:{},
  permissionsByAppNameByItemByID:{},
  appsByID:{},
  adminUsersByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        rolesByID: action.payload
      };
    case SHOW_ROLE_CREATE_FORM:
      return {
        ...state,
        showRoleCreateForm: true
      };
    case HIDE_ROLE_CREATE_FORM:
      return {
        ...state,
        showRoleCreateForm: false
      };
    case SHOW_ROLE_EDIT_FORM:
      return {
        ...state,
        showRoleEditForm: true,
        roleToEdit: action.payload
      };
    case HIDE_ROLE_EDIT_FORM:
      return {
        ...state,
        showRoleEditForm: false
      };
    case ADD_ROLE_SUCCESS:
    case EDIT_ROLE_SUCCESS:
      let role_id = action.payload.id;
      return {
        ...state,
        rolesByID: {
          ...state.rolesByID,
          [role_id]:action.payload
        }
      };
    case SHOW_ROLE_DELETE_FORM:
      return {
        ...state,
        showRoleDeleteForm: true,
        roleToDelete: action.payload
      };
    case HIDE_ROLE_DELETE_FORM:
      return {
        ...state,
        showRoleDeleteForm: false
      };
    case DELETE_ROLE_SUCCESS:
      let rolesByID = {...state}.rolesByID;
      delete rolesByID[action.payload.id];
      return {
        ...state,
        rolesByID:rolesByID
      };
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsByAppNameByItemByID: action.payload.permissions,
        appsByID: action.payload.apps,
        adminUsersByID: action.payload.users,
      };
    default:
      return state;
  }
}
