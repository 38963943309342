import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {all, call, fork, put,takeLatest} from "redux-saga/effects";
import {
    FETCH_SCHEDULE_REPORTS,
    fetchScheduleReportsSuccessGenarator
} from "../../actions/attendance/SchedulesReportsActions";

const  fetchSchedulesReportsRequest = async (requestParameters)=>
    await axios.get(API_ENDPOINT+'/attendance/report/scheduling',{params:requestParameters})
        .then(response => response)
        .catch(error=>error.response);

function* fetchSchedulesReports({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchSchedulesReportsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let schedule_groups_shifts = response.data.data;
                yield put(fetchScheduleReportsSuccessGenarator(schedule_groups_shifts));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* fetchSchedulesReportsDefault() {
    yield takeLatest(FETCH_SCHEDULE_REPORTS,fetchSchedulesReports);
}

export default function* rootSaga() {
    yield all([
        fork(fetchSchedulesReportsDefault)
    ]);
}
