import {Helpers} from "../../../util/Helpers";
import axios from "axios/index";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {all, call, fork, put} from "redux-saga/effects";
import {takeLatest} from "redux-saga/effects";
import {
  ADD_EMPLOYEE_NUMBER_FORMAT,
  addEmployeeNumberFormatSuccessGenerator,
  DELETE_EMPLOYEE_NUMBER_FORMAT,
  deleteEmployeeNumberFormatSuccessGenerator,
  EDIT_EMPLOYEE_NUMBER_FORMAT,
  editEmployeeNumberFormatSuccessGenerator,
  FETCH_EMPLOYEE_NUMBER_FORMAT,
  fetchEmployeeNumberFormatSuccessGenerator
} from "../../actions/payroll/EmployeeNumberFormatActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
///fetch
const fetchEmployeeNumberFormatRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/employeeno-formats',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);

function* fetchEmployeeNumberFormat({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeNumberFormatRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(fetchEmployeeNumberFormatSuccessGenerator(leaveTypeCategory));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEmployeeNumberFormatDefault() {
  yield takeLatest(FETCH_EMPLOYEE_NUMBER_FORMAT,fetchEmployeeNumberFormat)
}
////end fetch
///edit
const editEmployeeNumberFormatRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/employeeno-formats/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* editEmployeeNumberFormat({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editEmployeeNumberFormatRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(editEmployeeNumberFormatSuccessGenerator(leaveTypeCategory));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editEmployeeNumberFormatDefault() {
  yield takeLatest(EDIT_EMPLOYEE_NUMBER_FORMAT,editEmployeeNumberFormat)
}
////end edit
///delete
const deleteEmployeeNumberFormatRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/employeeno-formats/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* deleteEmployeeNumberFormat({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteEmployeeNumberFormatRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(deleteEmployeeNumberFormatSuccessGenerator(leaveTypeCategory));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteEmployeeNumberFormatDefault() {
  yield takeLatest(DELETE_EMPLOYEE_NUMBER_FORMAT,deleteEmployeeNumberFormat)
}
//end delete
////creating
const createEmployeeNumberFormatRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/employeeno-formats',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* createEmployeeNumberFormat({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(createEmployeeNumberFormatRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let leaveTypeCategory = response.data.data;
        yield put(addEmployeeNumberFormatSuccessGenerator(leaveTypeCategory));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* createEmployeeNumberFormatDefault() {
  yield takeLatest(ADD_EMPLOYEE_NUMBER_FORMAT,createEmployeeNumberFormat)
}
//end create
export default function* rootSagas(){
  yield all(
    [
      fork(fetchEmployeeNumberFormatDefault),
      fork(editEmployeeNumberFormatDefault),
      fork(deleteEmployeeNumberFormatDefault),
      fork(createEmployeeNumberFormatDefault)
    ])
}
