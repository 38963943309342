import {all, call, fork, put, takeLatest} from "redux-saga/effects";

import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {
  DELETE_EMPLOYEE_RATE_MULTIPLE,
  deleteEmployeeRateSuccessGenerator,
  EDIT_EMPLOYEE_RATE,
  editEmployeeRateSuccessGenerator,
  FETCH_EMPLOYEE_RATE,
  FETCH_SALARY_HISTORY, FETCH_SINGLE_EMPLOYEE_ALLOWANCES,
  fetchEmployeeRateGenerator,
  fetchSalaryHistoryGenerator,
  fetchSalaryHistorySuccessGenerator,
  fetchSingleEmployeeAllowanceSuccessGenerator,
  UPDATE_BULK_EMPLOYEE_RATE,
  UPLOAD_SALARY_IN_BULK
} from "../../actions/payroll/EmployeePaymentRateActions";
import {
  ADD_EMPLOYEE_RATE,
  fetchEmployeeRateSuccessGenerator,
  addEmployeeRateSuccessGenerator,
  DELETE_EMPLOYEE_RATE
} from "../../actions/payroll/EmployeePaymentRateActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchSingleEmployeeAllowanceRequest = async (requestData)=>
  axios.get(API_ENDPOINT+"/hrm/employee-single-allowance/"+requestData.employee_id,{params:requestData})
    .then(response=>response)
    .catch(error=>error.response)

const addEmployeeRateRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT+"/hrm/employee-payment-rate",requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchEmployeeRateRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/employee-payment-rate",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);
const fetchSalaryHistoryRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/salary-history",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);
const updateEmployeeRateRequest = async (requestParameters)=>
  await axios.put(API_ENDPOINT+"/hrm/employee-payment-rate/"+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.message);
const editEmployeeRateBulkRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/update/employee-payment-rate',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const deleteEmployeeRateRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/employee-payment-rate/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const deleteEmployeeRateMultipleRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/employee-payment-rate/delete/multiple',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const uploadSalaryInBulkRequest = async (requestData) =>
    await axios.post(API_ENDPOINT+'/hrm/employee_payment_rate',requestData,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response=>response)
        .catch(error=>error.response);

function* fetchSingleEmployeeAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchSingleEmployeeAllowanceRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message));
            yield put(fetchSingleEmployeeAllowanceSuccessGenerator(response.data.data))
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* uploadSalaryInBulk({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParams);
    const response = yield call(uploadSalaryInBulkRequest,formData);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message));
        let pathname = window.location.pathname;
        if(pathname === '/hrm/salary-history'){
          yield put(fetchSalaryHistoryGenerator(payload));
        }else{
          yield put(fetchEmployeeRateGenerator(payload));
        }
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editEmployeeRateBulk({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(editEmployeeRateBulkRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message));
        let pathname = window.location.pathname;
        if(pathname === '/hrm/salary-history'){
          yield put(fetchSalaryHistoryGenerator(payload));
        }else{
          yield put(fetchEmployeeRateGenerator(payload));
        }
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteEmployeePaymentRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteEmployeeRateRequest,requestParameters);
    if(response.status === 201 || response.status===200){
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message,'success'));
        let pathname = window.location.pathname;
        if(pathname === '/hrm/salary-history'){
          yield put(fetchSalaryHistoryGenerator(payload));
        }else{
          yield put(fetchEmployeeRateGenerator(payload));
        }
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteEmployeePaymentRateMultiple({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteEmployeeRateMultipleRequest,requestParameters);
    if(response.status === 201 || response.status===200){
      if(response.data.success){
        let pathname = window.location.pathname;
        if(pathname === '/hrm/salary-history'){
          yield put(fetchSalaryHistoryGenerator(payload));
        }else{
          yield put(fetchEmployeeRateGenerator(payload));
        }

        yield put(showMessageGenerator(response.data.message,'success'));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateEmployeePaymentRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(updateEmployeeRateRequest,requestParameters);
    if(response.status ===200 || response.status===201){
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message,'success'));
        let pathname = window.location.pathname;
        if(pathname === '/hrm/salary-history'){
          yield put(fetchSalaryHistoryGenerator(payload));
        }else{
          yield put(fetchEmployeeRateGenerator(payload));
        }
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addEmployeePaymentRate({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addEmployeeRateRequest,requestParameters);
    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message,'success'));
        let pathname = window.location.pathname;
        if(pathname === '/hrm/salary-history'){
          yield put(fetchSalaryHistoryGenerator(payload));
        }else{
          yield put(fetchEmployeeRateGenerator(payload));
        }
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEmployeeRates({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeRateRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        //yield put(showMessageGenerator(response.data.message));
        yield put(fetchEmployeeRateSuccessGenerator(response.data.data));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchSalaryHistory({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchSalaryHistoryRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        //yield put(showMessageGenerator(response.data.message));
        yield put(fetchSalaryHistorySuccessGenerator(response.data.data));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchSingleEmployeeAllowanceDefault(){
  yield takeLatest(FETCH_SINGLE_EMPLOYEE_ALLOWANCES,fetchSingleEmployeeAllowance);
}
export function* uploadSalaryInBulkDefault(){
  yield takeLatest(UPLOAD_SALARY_IN_BULK,uploadSalaryInBulk);
}
export function* addEmployeePaymentRateDefault(){
  yield(takeLatest(ADD_EMPLOYEE_RATE,addEmployeePaymentRate));
}
export function* editEmployeePaymentRateDefault(){
  yield(takeLatest(EDIT_EMPLOYEE_RATE,updateEmployeePaymentRate));
}
export function* deleteEmployeePaymentRateDefault(){
  yield(takeLatest(DELETE_EMPLOYEE_RATE,deleteEmployeePaymentRate));
}
export function* deleteEmployeePaymentRateMultpleDefault(){
  yield(takeLatest(DELETE_EMPLOYEE_RATE_MULTIPLE,deleteEmployeePaymentRateMultiple));
}
export function* fetchEmployeePaymentRateDefault(){
  yield(takeLatest(FETCH_EMPLOYEE_RATE,fetchEmployeeRates));
}
export function* editEmployeeRateBulkDefault() {
  yield takeLatest(UPDATE_BULK_EMPLOYEE_RATE,editEmployeeRateBulk);
}
export function* fetchSalaryHistoryDefault(){
  yield(takeLatest(FETCH_SALARY_HISTORY,fetchSalaryHistory));
}
export default function* rootSaga() {
  yield all([
    fork(addEmployeePaymentRateDefault),
    fork(fetchEmployeePaymentRateDefault),
    fork(editEmployeePaymentRateDefault),
    fork(deleteEmployeePaymentRateDefault),
      fork(deleteEmployeePaymentRateMultpleDefault),
      fork(editEmployeeRateBulkDefault),
      fork(uploadSalaryInBulkDefault),
    fork(fetchSalaryHistoryDefault),
    fork(fetchSingleEmployeeAllowanceDefault)
  ]);
}
