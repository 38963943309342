import {all, call, fork, put} from "redux-saga/effects";
import {
    ADD_PAYMENT_ACTIVATIONS,
    DELETE_PAYMENT_ACTIVATIONS,
    deletePaymentActivationsSuccessGenerator, FETCH_PAYMENT_ACTIVATIONS,
    fetchPaymentActivationsGenerator,
    fetchPaymentActivationsSuccessGenerator
} from "../../actions/admin/PaymentActivationActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import {showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchPaymentActivationsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/admin/company-payment-activation',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPaymentActivationsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/admin/company-payment-activation',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePaymentActivationsRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/admin/company-payment-activation/'+requestParameters.id,{
      params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);


function* fetchPaymentActivations({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchPaymentActivationsRequest, requestParameters);

        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchPaymentActivationsSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
function* addPaymentActivations({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addPaymentActivationsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchPaymentActivationsGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
function* deletePaymentActivations({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deletePaymentActivationsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(deletePaymentActivationsSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(fetchPaymentActivationsGenerator());
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
export function* fetchPaymentActivationsDefault() {
    yield takeLatest(FETCH_PAYMENT_ACTIVATIONS, fetchPaymentActivations)
}
export function* addPaymentActivationsDefault() {
    yield takeLatest(ADD_PAYMENT_ACTIVATIONS, addPaymentActivations);
}
export function* deletePaymentActivationsDefault() {
    yield takeLatest(DELETE_PAYMENT_ACTIVATIONS, deletePaymentActivations);
}

export default function* rootSaga() {
    yield all([fork(fetchPaymentActivationsDefault),
        fork(addPaymentActivationsDefault),
        fork(deletePaymentActivationsDefault),
    ]);
}
