import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  ADD_SCHEDULE_GROUP,
  ADD_SCHEDULE_GROUP_SHIFT, ADD_SHIFT_WORKING_HOURS,
  addScheduleGroupShiftSuccessGenerator,
  addScheduleSuccessGenerator, ASSIGN_EMPLOYEES,
  assignEmployeesScheduleSuccessGenerator, BULK_DE_ASSIGN_EMPLOYEES,
  DEASSIGN_EMPLOYEES,
  deassignEmployeesScheduleSuccessGenerator,
  DELETE_SCHEDULE_GROUP_SHIFT,
  DELETE_SCHEDULES_GROUP, DELETE_SHIFT_WORKING_HOURS,
  deleteScheduleGroupShiftSuccessGenerator,
  deleteScheduleSuccessGroupGenerator,
  EDIT_SCHEDULE_GROUP,
  EDIT_SCHEDULE_GROUP_SHIFT,
  editScheduleGroupShiftSuccessGenerator,
  editScheduleSuccessGenerator, FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS,
  FETCH_SCHEDULE_GROUP_EMPLOYEES,
  FETCH_SCHEDULE_GROUP_SHIFTS,
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_GROUPS, FETCH_SHIFT_WORKING_HOURS,
  FETCH_SHIFTS, fetchGlobalScheduleGroupShiftsSuccessGenerator, fetchScheduleGroupEmployeesGenerator,
  fetchScheduleGroupEmployeesSuccessGenarator,
  fetchScheduleGroupShiftsSuccessGenerator,
  fetchSchedulesGroupGenerator,
  fetchScheduleSuccessGenerator,
  fetchShiftSuccessGenerator, fetchShiftWorkingHoursGenerator, fetchShiftWorkingHoursSuccessGenerator
} from "../../actions/attendance/SchedulesActions";
import {all, call, fork, put,takeLatest} from "redux-saga/effects";

const bulkDeAssignShiftEmployeeRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/attendance/bulk/de-assign/shift/employee',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const  fetchSchedulesGroupRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/schedule-groups',{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

const fetchScheduleRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/schedules',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const fetchShiftsRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/shifts',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const addScheduleGroupRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group/add',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const editScheduleGroupRequest = async (requestParamters) =>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group/update',requestParamters)
    .then(response=>response)
    .catch(error => error.response);

const deleteScheduleGroupRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group/delete',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const fetchScheduleGroupShiftsRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/'+requestParameters.id+'/schedule-group-shift',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const fetchGlobalScheduleGroupShiftsRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/schedule-group-shifts',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const addScheduleGroupShiftRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group-shift/add',requestParameters)
    .then(response=>response)
    .catch(error =>error.response);

const editScheduleGroupShiftRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group-shift/update',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const deleteScheduleGroupShiftRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group-shift/delete',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const fetchScheduleGroupEmployeesRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/'+requestParameters.id+'/schedule-group-employees',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const assignScheduleGroupEmployeesRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group/employees/assign',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const deassignScheduleGroupEmployeesRequest = async (requestParamenters)=>
  await axios.post(API_ENDPOINT+'/attendance/schedule-group/employees/de-assign',requestParamenters)
    .then(response=>response)
    .catch(error=>error.response);

const fetchShiftWorkingHoursRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/attendance/shift-working-hours',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);
const addShiftWorkingHoursRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/shift-working-hours/add',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);
const deleteShiftWorkingHoursRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/shift-working-hours/delete',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);


function* bulkDeAssignShiftEmployee({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(bulkDeAssignShiftEmployeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchScheduleGroupEmployeesGenerator({id:response.data.data}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* assignScheduleGroupEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(assignScheduleGroupEmployeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchScheduleGroupEmployeesGenerator({id:response.data.data}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deassignScheduleGroupEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deassignScheduleGroupEmployeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(deassignEmployeesScheduleSuccessGenerator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchScheduleGroupEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchScheduleGroupEmployeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(fetchScheduleGroupEmployeesSuccessGenarator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteScheduleGroupShift({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteScheduleGroupShiftRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(deleteScheduleGroupShiftSuccessGenerator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editScheduleGroupShift({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editScheduleGroupShiftRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(editScheduleGroupShiftSuccessGenerator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addScheduleGroupShift({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addScheduleGroupShiftRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(addScheduleGroupShiftSuccessGenerator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchScheduleGroupShifts({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchScheduleGroupShiftsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(fetchScheduleGroupShiftsSuccessGenerator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchGlobalScheduleGroupShifts({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchGlobalScheduleGroupShiftsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups_shifts = response.data.data;
        yield put(fetchGlobalScheduleGroupShiftsSuccessGenerator(schedule_groups_shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteScheduleGroup({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteScheduleGroupRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups = response.data.data;
        yield put(deleteScheduleSuccessGroupGenerator(schedule_groups));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editScheduleGroup({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editScheduleGroupRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups = response.data.data;
        yield put(editScheduleSuccessGenerator(schedule_groups));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addScheduleGroup({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addScheduleGroupRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups = response.data.data;
        yield put(addScheduleSuccessGenerator(schedule_groups));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchSchedules({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchScheduleRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule = response.data.data;
        yield put(fetchSchedulesGroupGenerator(schedule));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchSchedulesGroup({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchSchedulesGroupRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let schedule_groups = response.data.data;
        yield put(fetchScheduleSuccessGenerator(schedule_groups));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchShifts({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchShiftsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let shifts = response.data.data;
        yield put(fetchShiftSuccessGenerator(shifts));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchShiftWorkingHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchShiftWorkingHoursRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let hours = response.data.data;
        yield put(fetchShiftWorkingHoursSuccessGenerator(hours));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addShiftWorkingHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addShiftWorkingHoursRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchShiftWorkingHoursGenerator(payload));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteShiftWorkingHours({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteShiftWorkingHoursRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
        yield put(fetchShiftWorkingHoursGenerator(payload));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* bulkDeAssignShiftEmployeeDefault() {
 yield takeLatest(BULK_DE_ASSIGN_EMPLOYEES,bulkDeAssignShiftEmployee);
}
export function* addScheduleGroupDefault() {
  yield takeLatest(ADD_SCHEDULE_GROUP,addScheduleGroup);
}
export function* fetchSchedulesGroupDefault() {
  yield takeLatest(FETCH_SCHEDULES_GROUPS,fetchSchedulesGroup);
}
export function* fetchShiftsDefault() {
  yield takeLatest(FETCH_SHIFTS,fetchShifts);
}
export function* fetchSchedulesDefault () {
   yield takeLatest(FETCH_SCHEDULES,fetchSchedules);
}
export function * editScheduleGroupDefault () {
  yield takeLatest(EDIT_SCHEDULE_GROUP,editScheduleGroup);
}
export function* deleteScheduleGroupDefault() {
  yield takeLatest(DELETE_SCHEDULES_GROUP,deleteScheduleGroup)
}
export function* fetchScheduleGroupShiftsDefault() {
  yield takeLatest(FETCH_SCHEDULE_GROUP_SHIFTS,fetchScheduleGroupShifts);
}
export function* fetchGlobalScheduleGroupShiftsDefault() {
  yield takeLatest(FETCH_GLOBAL_SCHEDULE_GROUP_SHIFTS,fetchGlobalScheduleGroupShifts);
}
export function* addScheduleGroupShiftDefault(){
 yield takeLatest(ADD_SCHEDULE_GROUP_SHIFT,addScheduleGroupShift);
}
export function* editScheduleGroupShiftDefault(){
  yield takeLatest(EDIT_SCHEDULE_GROUP_SHIFT,editScheduleGroupShift)
}
export function* deleteScheduleGroupShiftDefault() {
  yield takeLatest(DELETE_SCHEDULE_GROUP_SHIFT,deleteScheduleGroupShift);
}
export function* assignScheduleGroupEmployeesDefault () {
 yield takeLatest(ASSIGN_EMPLOYEES,assignScheduleGroupEmployees);
}
export function* fetchScheduleGroupEmployeesDefault () {
 yield takeLatest(FETCH_SCHEDULE_GROUP_EMPLOYEES,fetchScheduleGroupEmployees);
}
export function* deassignScheduleGroupEmployeesDefault () {
 yield takeLatest(DEASSIGN_EMPLOYEES,deassignScheduleGroupEmployees);
}

export function* fetchShiftWorkingHoursDefault() {
  yield takeLatest(FETCH_SHIFT_WORKING_HOURS,fetchShiftWorkingHours);
}
export function* addShiftWorkingHoursDefault() {
  yield takeLatest(ADD_SHIFT_WORKING_HOURS,addShiftWorkingHours);
}
export function* deleteShiftWorkingHoursDefault() {
  yield takeLatest(DELETE_SHIFT_WORKING_HOURS,deleteShiftWorkingHours);
}
export default function* rootSaga() {
  yield all([
    fork(fetchSchedulesGroupDefault),
    fork(fetchShiftsDefault),
    fork(addScheduleGroupDefault),
    fork(fetchSchedulesDefault),
    fork(editScheduleGroupDefault),
    fork(deleteScheduleGroupDefault),
    fork(fetchScheduleGroupShiftsDefault),
    fork(addScheduleGroupShiftDefault),
    fork(editScheduleGroupShiftDefault),
    fork(deleteScheduleGroupShiftDefault),
    fork(fetchScheduleGroupEmployeesDefault),
    fork(assignScheduleGroupEmployeesDefault),
    fork(deassignScheduleGroupEmployeesDefault),
    fork(fetchGlobalScheduleGroupShiftsDefault),
    fork(fetchShiftWorkingHoursDefault),
    fork(addShiftWorkingHoursDefault),
    fork(deleteShiftWorkingHoursDefault),
    fork(bulkDeAssignShiftEmployeeDefault)

  ])
}
