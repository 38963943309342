import {all, call, fork, put, takeLatest} from "redux-saga/effects";

import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {
  ADD_EMPLOYEE_RENT,
  DELETE_EMPLOYEE_RENT,
  EDIT_EMPLOYEE_RENT,
  FETCH_EMPLOYEE_RENT,
  fetchEmployeeRentGenerator,
  fetchEmployeeRentSuccessGenerator,
  UPLOAD_EMPLOYEE_RENT_IN_BULK
} from "../../actions/payroll/EmployeeRentActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";


const fetchEmployeeRentRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/employee-rents",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

const uploadEmployeeRentInBulkRequest = async (requestData) =>
  await axios.post(API_ENDPOINT+'/hrm/employee-rents/upload',requestData,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);
const addEmployeeRentRequest = async (requestData) =>
  await  axios.post(API_ENDPOINT+'/hrm/employee-rents',requestData)
    .then(response => response)
    .catch(error => error.response);
const editEmployeeRentRequest = async (requestData) =>
  await  axios.put(API_ENDPOINT+'/hrm/employee-rents/'+requestData.id,requestData)
    .then(response => response)
    .catch(error => error.response);
const deleteEmployeeRentRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/employee-rents/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);


function* uploadEmployeeRentInBulk({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParams);
    const response = yield call(uploadEmployeeRentInBulkRequest,formData);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchEmployeeRentGenerator(payload));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


function* fetchEmployeeRents({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeRentRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        //yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchEmployeeRentSuccessGenerator(response.data.data));
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editEmployeeRent({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(editEmployeeRentRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchEmployeeRentGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteEmployeeRent({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteEmployeeRentRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchEmployeeRentGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addEmployeeRent({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(addEmployeeRentRequest,requestParams);
    if (response.status ===200 || response.status ===201 ) {
      if(response.data.success){
        yield put(showMessageGenerator(response.data.message, 'success'));
        yield put(fetchEmployeeRentGenerator());
      }else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


export function* uploadEmployeeRentInBulkDefault(){
  yield takeLatest(UPLOAD_EMPLOYEE_RENT_IN_BULK,uploadEmployeeRentInBulk);
}

export function* fetchEmployeeRentsDefault(){
  yield(takeLatest(FETCH_EMPLOYEE_RENT,fetchEmployeeRents));
}
export function* editEmployeeRentDefault(){
  yield(takeLatest(EDIT_EMPLOYEE_RENT,editEmployeeRent));
}
export function* deleteEmployeeRentDefault(){
  yield(takeLatest(DELETE_EMPLOYEE_RENT,deleteEmployeeRent));
}
export function* addEmployeeRentDefault(){
  yield(takeLatest(ADD_EMPLOYEE_RENT,addEmployeeRent));
}

export default function* rootSaga() {
  yield all([
    fork(fetchEmployeeRentsDefault),
    fork(uploadEmployeeRentInBulkDefault),
    fork(editEmployeeRentDefault),
    fork(deleteEmployeeRentDefault),
    fork(addEmployeeRentDefault)
  ]);
}
