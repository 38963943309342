import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { IconButton, Badge } from '@material-ui/core';
import withRouter from 'react-router/es/withRouter';


class Notifications extends React.Component {
  handleNotificationClick = () => {
    this.props.history.push('/common/notifications');
  };

  render() {
    return (
      <IconButton color="inherit" onClick={this.handleNotificationClick}>
        <Badge badgeContent="" color="secondary">
          <NotificationsIcon fontSize="default" />
        </Badge>
      </IconButton>
    );
  }
}

export default withRouter(Notifications);
