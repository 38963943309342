import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import { UPLOAD_JWM } from "../../actions/attendance/JWM_uploadActions";

const  uploadJWMRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/attendance/jwm/upload',requestParameters,{headers: {
  'content-type': `multipart/form-data`
   }})
    .then(response=>response)
    .catch(error=>error.response);

function* uploadJWM({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(uploadJWMRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* uploadJWMDefault() {
  yield takeLatest(UPLOAD_JWM, uploadJWM);
}
export default function* rootSaga() {
  yield all([fork(uploadJWMDefault),
  ]);
}
