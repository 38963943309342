export const FETCH_DEPARTMENT_KPIs = "FETCH_DEPARTMENT_KPIs";
export const FETCH_DEPARTMENT_KPIs_SUCCESS = "FETCH_DEPARTMENT_KPIs_SUCCESS";
export const ADD_DEPARTMENT_KPI = 'ADD_DEPARTMENT_KPI';
export const ADD_DEPARTMENT_KPI_SUCCESS = 'ADD_DEPARTMENT_KPI_SUCCESS';
export const EDIT_DEPARTMENT_KPI = 'EDIT_DEPARTMENT_KPI';
export const EDIT_DEPARTMENT_KPI_SUCCESS = 'EDIT_DEPARTMENT_KPI_SUCCESS';
export const DELETE_DEPARTMENT_KPI = 'DELETE_DEPARTMENT_KPI';
export const DELETE_DEPARTMENT_KPI_SUCCESS = 'DELETE_DEPARTMENT_KPI_SUCCESS';

export const fetchDepartmentKPIsGenerator = (data=[])=>{
  return {
    type:FETCH_DEPARTMENT_KPIs,
    payload:data
  };
};

export const fetchDepartmentKPIsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_DEPARTMENT_KPIs_SUCCESS,
    payload:dataById
  }
};

export const addDepartmentKPIGenerator = (data)=>{
  return {
    type: ADD_DEPARTMENT_KPI,
    payload: data
  }
};

export const addDepartmentKPISuccessGenerator = (data)=>{
  return {
    type: ADD_DEPARTMENT_KPI_SUCCESS,
    payload: data
  }
};

export const editDepartmentKPIGenerator = (data)=>{
  return {
    type: EDIT_DEPARTMENT_KPI,
    payload: data
  }
};
export const editDepartmentKPISuccessGenerator = (data)=>{
  return {
    type: EDIT_DEPARTMENT_KPI_SUCCESS,
    payload: data
  }
};

export const deleteDepartmentKPIGenerator = (data)=>{
  return {
    type: DELETE_DEPARTMENT_KPI,
    payload: data
  }
};
export const deleteDepartmentKPISuccessGenerator = (data)=>{
  return {
    type: DELETE_DEPARTMENT_KPI_SUCCESS,
    payload: data
  }
};
