export const  FETCH_RELIEVERS = 'FETCH_RELIEVERS';
export const  FETCH_RELIEVERS_SUCCESS = 'FETCH_RELIEVERS_SUCCESS';
export const  DELETE_RELIEVERS="DELETE_RELIEVERS";
export const  DELETE_RELIEVERS_SUCCESS="DELETE_RELIEVERS_SUCCESS";
export const  ADD_RELIEVERS="ADD_RELIEVERS";
export const  ADD_RELIEVERS_SUCCESS="ADD_RELIEVERS_SUCCESS";
export const  UPDATE_RELIEVERS_SUCCESS="UPDATE_RELIEVERS_SUCCESS";
export const  UPDATE_RELIEVERS="UPDATE_RELIEVERS";

export const fetchRelieversGenerator = (data=[])=>{
    return {
        type:FETCH_RELIEVERS,
        payload:data
    };
};

export const fetchRelieversSuccessGenerator = (data)=> {
    return {
        type:FETCH_RELIEVERS_SUCCESS,
        payload:data
    }
};

export const deleteRelieversGenerator = (data)=> {
    return {
        type:DELETE_RELIEVERS,
        payload:data
    }
};

export const deleteRelieversSuccessGenerator = (data)=> {
    return {
        type:DELETE_RELIEVERS_SUCCESS,
        payload:data
    }
};

export const addRelieversGenerator = (data)=> {
    return {
        type:ADD_RELIEVERS,
        payload:data
    }
};

export const addRelieversSuccessGenerator = (data)=> {
    return {
        type:ADD_RELIEVERS_SUCCESS,
        payload:data
    }
};


export const updateRelieversGenerator =(data)=>{
    return {
        type:UPDATE_RELIEVERS,
        payload:data
    }
};

export const updateRelieversSuccessGenerator =(data)=>{
    return {
        type:UPDATE_RELIEVERS_SUCCESS,
        payload:data
    }
};