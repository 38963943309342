import {
  all, call, fork, put, takeLatest
} from 'redux-saga/effects';
import axios from 'axios';
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from '../../../util/Helpers';

import {userSignOutSuccessGenerator} from '../../actions/AuthActions';
import {
  deleteDepartmentSuccessGenerator,
  FETCH_DEPARTMENTS, fetchDepartmentGenerator,
  fetchDepartmentSuccessGenerator
} from "../../actions/common/DepartmentActions";
import {
  ADD_DEPARTMENT,
  addDepartmentSuccessGenerator, DELETE_DEPARTMENT,
  EDIT_DEPARTMENT,
  editDepartmentSuccessGenerator
} from '../../actions/common/DepartmentActions';
import {API_ENDPOINT} from '../../../constants/ActionTypes';
// //fetch departments
const fetchDepartmentRequest = async requestParameters => await axios.get(`${API_ENDPOINT}/setup/departments`, {
  params: requestParameters
})
  .then(response => response)
  .catch(error => error.response);

function* fetchDepartments({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDepartmentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const departments = response.data.data;
        yield put(fetchDepartmentSuccessGenerator(departments));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchDepartmentDefault() {
  yield takeLatest(FETCH_DEPARTMENTS, fetchDepartments);
}
// //end fetch departments
// /create departments
const addDepartmentRequest = async requestParameters => await axios.post(`${API_ENDPOINT}/setup/departments`,
  requestParameters
  )
  .then(response => response)
  .catch(error => error.response);

function* addDepartment({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token: Helpers.token(), company_id: Helpers.authSwitchedCompanyID()};
    let isBulk = false;
    if(requestParameters.isBulk){
      isBulk = true;
      requestParameters = Helpers.getFormData(requestParameters);
    }
    const response = yield call(addDepartmentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const departments = response.data.data;
        if (isBulk){
          yield put(fetchDepartmentSuccessGenerator(departments));
          yield put(showMessageGenerator(response.data.message,'success'));
        }else{
          yield put(addDepartmentSuccessGenerator(departments));
          yield put(showMessageGenerator(response.data.message,'success'));
        }
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDepartmentDefault() {
  yield takeLatest(ADD_DEPARTMENT, addDepartment);
}
// /end of create department
// /edit department
const editDepartmentRequest = async requestParameters => await axios.put(`${API_ENDPOINT}/setup/departments/${requestParameters.id}`, requestParameters)
  .then(response => response)
  .catch(error => error.message);

function* updateDepartment({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(editDepartmentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const departments = response.data.data;
        yield put(editDepartmentSuccessGenerator(departments));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'eeror'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateDepartmentDefault() {
  yield takeLatest(EDIT_DEPARTMENT, updateDepartment);
}
// /end update department
// / start delete department
const deleteDepartmentRequest = async requestParameters => await axios.delete(`${API_ENDPOINT}/setup/departments/${requestParameters.id}`, {params: requestParameters})
  .then(response => response)
  .catch(error => error.response);

function* deleteDepartment({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    const requestParameters = {...payload, token: Helpers.token(), company_id: Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDepartmentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        const department = response.data.data;
        yield put(deleteDepartmentSuccessGenerator(department));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if (response.status === 401) {
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* deleteDepartmentDefault() {
  yield takeLatest(DELETE_DEPARTMENT, deleteDepartment);
}

export default function* rootSaga() {
  yield all([fork(fetchDepartmentDefault), fork(addDepartmentDefault), fork(updateDepartmentDefault), fork(deleteDepartmentDefault)]);
}
