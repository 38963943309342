import axios from "axios";
import {call, put, takeLatest,fork,all} from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {FETCH_LEAVES_DASHBOARD, fetchLeaveDashboardSuccessGenerator} from "../../actions/payroll/LeaveDashboardActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchLeavesDashboardRequest = async (requestParameters)=>await axios.get(API_ENDPOINT+"/hrm/leaves-dashboard",{params:requestParameters}).then(response=>response).catch(error=>error.response);
function* fetchLeavesDashboard({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let request ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchLeavesDashboardRequest,request);
        if(response.status ===200 || response.status===201){
            if(response.data.success){
                let leavesDashboard =response.data.data;
                yield put(fetchLeaveDashboardSuccessGenerator(leavesDashboard));
            }else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchDashboardDefault() {
    yield takeLatest(FETCH_LEAVES_DASHBOARD,fetchLeavesDashboard);
}
export default function* rootSaga(){
    yield all([
        fork(fetchDashboardDefault),
    ]);
}
