export const FETCH_HRM_DASHBOARD ="FETCH_HRM_DASHBOARD";
export const FETCH_HRM_DASHBOARD_SUCCESS ="FETCH_HRM_DASHBOARD_SUCCESS";
export const FETCH_PAYOUT_DASHBOARD_SUCCESS="FETCH_PAYOUT_DASHBOARD_SUCCESS";
export const FETCH_PAYOUT_DASHBOARD="FETCH_PAYOUT_DASHBOARD";

export const FETCH_APPRAISAL_DASHBOARD="FETCH_APPRAISAL_DASHBOARD";
export const FETCH_APPRAISAL_DASHBOARD_SUCCESS="FETCH_APPRAISAL_DASHBOARD_SUCCESS";

export const fetchAppraisalDashBoardGenerator =(data=[])=>{
  return{
    type:FETCH_APPRAISAL_DASHBOARD,
    payload:data
  }
};

export const fetchAppraisalDashBoardSuccessGenerator =(data)=>{
  return{
    type:FETCH_APPRAISAL_DASHBOARD_SUCCESS,
    payload:data
  }
};

export const fetchHrmDashBoardGenerator =(data=[])=>{
  return{
    type:FETCH_HRM_DASHBOARD,
    payload:data
  }
};

export const fetchHrmDashBoardSuccessGenerator =(data)=>{
  return{
    type:FETCH_HRM_DASHBOARD_SUCCESS,
    payload:data
  }
};

export const fetchPayoutDashBoardGenerator =(data=[])=>{
  return{
    type:FETCH_PAYOUT_DASHBOARD,
    payload:data
  }
};

export const fetchPayoutDashBoardSuccessGenerator =(data)=>{
  return{
    type:FETCH_PAYOUT_DASHBOARD_SUCCESS,
    payload:data
  }
};