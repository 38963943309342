import {
  FETCH_ONBOARDING_COMPANIES_SUCCESS, ADD_ONBOARDING_COMPANY_SUCCESS, EDIT_ONBOARDING_COMPANY_SUCCESS
} from "../../actions/admin/CompanyOnboardingActions";

const INIT_STATE = {
  onboardingCompaniesByID: {}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_ONBOARDING_COMPANIES_SUCCESS:
      return {
        ...state,
        onboardingCompaniesByID: action.payload
      };
    case ADD_ONBOARDING_COMPANY_SUCCESS:
    case EDIT_ONBOARDING_COMPANY_SUCCESS:
      let id = action.payload.id;
      return {
        ...state,
        onboardingCompaniesByID: {...state.onboardingCompaniesByID, [id]:action.payload}
      };

    default:
      return state;
  }
}
