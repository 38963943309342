import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_SALARY_ADVANCE_FEE,
  DELETE_SALARY_ADVANCE_FEE,
  deleteSalaryAdvanceFeeSuccessGenerator,
  EDIT_SALARY_ADVANCE_FEE,
  editSalaryAdvanceFeeSuccessGenerator,
  FETCH_SALARY_ADVANCE_FEES,
  fetchSalaryAdvanceFeesSuccessGenerator,
  addSalaryAdvanceFeeSuccessGenerator,
  fetchDefaultSalaryAdvanceFeesSuccessGenerator,
  addDefaultSalaryAdvanceFeeSuccessGenerator,
  deleteDefaultSalaryAdvanceFeeSuccessGenerator,
  editDefaultSalaryAdvanceFeeSuccessGenerator,
  FETCH_DEFAULT_SALARY_ADVANCE_FEES,
  ADD_DEFAULT_SALARY_ADVANCE_FEE, DELETE_DEFAULT_SALARY_ADVANCE_FEE, EDIT_DEFAULT_SALARY_ADVANCE_FEE
} from "../../actions/admin/SalaryAdvanceFeesActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchSalaryAdvanceFeesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/salary-advance-fees',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addSalaryAdvanceFeeRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/salary-advance-fees',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteSalaryAdvanceFeeRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/salary-advance-fees/'+(requestParameters.id ? requestParameters.id : 0),{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editSalaryAdvanceFeeRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/salary-advance-fees/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchDefaultSalaryAdvanceFeesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/default-salary-advance-fees',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addDefaultSalaryAdvanceFeeRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/default-salary-advance-fees',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteDefaultSalaryAdvanceFeeRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/default-salary-advance-fees/'+(requestParameters.id ? requestParameters.id : 0),{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editDefaultSalaryAdvanceFeeRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/default-salary-advance-fees/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchSalaryAdvanceFees({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchSalaryAdvanceFeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchSalaryAdvanceFeesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addSalaryAdvanceFee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addSalaryAdvanceFeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(addSalaryAdvanceFeeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteSalaryAdvanceFee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteSalaryAdvanceFeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(deleteSalaryAdvanceFeeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editSalaryAdvanceFee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editSalaryAdvanceFeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(editSalaryAdvanceFeeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


function* fetchDefaultSalaryAdvanceFees({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDefaultSalaryAdvanceFeesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchDefaultSalaryAdvanceFeesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDefaultSalaryAdvanceFee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addDefaultSalaryAdvanceFeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(addDefaultSalaryAdvanceFeeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteDefaultSalaryAdvanceFee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDefaultSalaryAdvanceFeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(deleteDefaultSalaryAdvanceFeeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editDefaultSalaryAdvanceFee({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editDefaultSalaryAdvanceFeeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(editDefaultSalaryAdvanceFeeSuccessGenerator(data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchSalaryAdvanceFeesDefault() {
  yield takeLatest(FETCH_SALARY_ADVANCE_FEES, fetchSalaryAdvanceFees)
}
export function* addSalaryAdvanceFeeDefault() {
  yield takeLatest(ADD_SALARY_ADVANCE_FEE, addSalaryAdvanceFee);
}
export function* deleteSalaryAdvanceFeeDefault() {
  yield takeLatest(DELETE_SALARY_ADVANCE_FEE, deleteSalaryAdvanceFee);
}
export function* editSalaryAdvanceFeeDefault() {
  yield takeLatest(EDIT_SALARY_ADVANCE_FEE, editSalaryAdvanceFee);
}

export function* fetchDefaultSalaryAdvanceFeesDefault() {
  yield takeLatest(FETCH_DEFAULT_SALARY_ADVANCE_FEES, fetchDefaultSalaryAdvanceFees)
}
export function* addDefaultSalaryAdvanceFeeDefault() {
  yield takeLatest(ADD_DEFAULT_SALARY_ADVANCE_FEE, addDefaultSalaryAdvanceFee);
}
export function* deleteDefaultSalaryAdvanceFeeDefault() {
  yield takeLatest(DELETE_DEFAULT_SALARY_ADVANCE_FEE, deleteDefaultSalaryAdvanceFee);
}
export function* editDefaultSalaryAdvanceFeeDefault() {
  yield takeLatest(EDIT_DEFAULT_SALARY_ADVANCE_FEE, editDefaultSalaryAdvanceFee);
}
export default function* rootSaga() {
  yield all([
    fork(fetchSalaryAdvanceFeesDefault),
    fork(addSalaryAdvanceFeeDefault),
    fork(deleteSalaryAdvanceFeeDefault),
    fork(editSalaryAdvanceFeeDefault),

    fork(fetchDefaultSalaryAdvanceFeesDefault),
    fork(addDefaultSalaryAdvanceFeeDefault),
    fork(deleteDefaultSalaryAdvanceFeeDefault),
    fork(editDefaultSalaryAdvanceFeeDefault),
  ]);
}
