export const FETCH_SENDER_ID = 'FETCH_SENDER_ID';
export const FETCH_SENDER_ID_SUCCESS = 'FETCH_SENDER_ID_SUCCESS';

export const ASSIGN_SENDER_ID = 'ASSIGN_SENDER_ID';
export const DE_ASSIGN_SENDER_ID = 'DE_ASSIGN_SENDER_ID';

export const fetchSenderIdGenerator = (data)=>{
  return{
       type:FETCH_SENDER_ID,
      payload:data
  }
};
export const fetchSenderIdSuccessGenerator = (data)=>{
    return{
        type:FETCH_SENDER_ID_SUCCESS,
        payload:data
    }
};
export const assignSenderIdGenerator = (data)=>{
    return {
        type:ASSIGN_SENDER_ID,
        payload:data
    }
};
export const de_assignSenderIdGenerator = (data)=>{
    return {
        type:DE_ASSIGN_SENDER_ID,
        payload:data
    }
};