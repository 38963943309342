import {
    DELETE_SYNCED_DEVICES_SUCCESS,
    FETCH_SYNCED_DEVICES_SUCCESS,
    UPDATE_SYNCED_DEVICES_SUCCESS
} from "../../actions/attendance/SyncedDevicesActions";

const INIT_STATE = {
    syncedDevicesByID:{},
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
        case FETCH_SYNCED_DEVICES_SUCCESS:
            return {
                ...state,
                syncedDevicesByID: action.payload
            };
        case UPDATE_SYNCED_DEVICES_SUCCESS:
            let synced_devices_id = action.payload.id;
            return {
                ...state,
                syncedDevicesByID: {
                    ...state.syncedDevicesByID,
                    [synced_devices_id]:action.payload
                }
            };
        case DELETE_SYNCED_DEVICES_SUCCESS:
            let syncedDevices = {...state.syncedDevicesByID};
            delete syncedDevices[action.payload.id];
            return {
                ...state,
                syncedDevicesByID:syncedDevices
            };
        default:
            return state;
    }
}
