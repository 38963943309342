import {FETCH_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS} from '../../actions/appraisal/SupervisorLevelAppraisalActions';

const INIT_STATE ={
 supervisorEmployeeAppraise:[],
};
export default (state=INIT_STATE,action)=>{
    switch (action.type) {
        case FETCH_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS:
            return {
                 ...state,
              supervisorEmployeeAppraise:action.payload
            };
        default: return  {...state};
    }
}
