export const SEND_REQUEST = 'SEND_REQUEST';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS';
export const SEND_SUBSCRIPTION_REQUEST = 'SEND_SUBSCRIPTION_REQUEST';
export const SEND_SUBSCRIPTION_REQUEST_SUCCESS = 'SEND_SUBSCRIPTION_REQUEST_SUCCESS';
export const CONFIRM_SUBSCRIPTION_PAYMENT = 'CONFIRM_SUBSCRIPTION_PAYMENT';
export const CONFIRM_SUBSCRIPTION_PAYMENT_SUCCESS = 'CONFIRM_SUBSCRIPTION_PAYMENT_SUCCESS';
export const CONFIRM_CARD_PAYMENT_TRANSACTION = 'CONFIRM_CARD_PAYMENT_TRANSACTION';
export const CONFIRM_CARD_PAYMENT_TRANSACTION_SUCCESS = 'CONFIRM_CARD_PAYMENT_TRANSACTION_SUCCESS';
export const REQUEST_CONFIRM_CARD_PAYMENT_TRANSACTION = 'REQUEST_CONFIRM_CARD_PAYMENT_TRANSACTION';
export const CREATE_CARD_PAYMENT_TRANSACTION_RECORD = 'CREATE_CARD_PAYMENT_TRANSACTION_RECORD';
export const REQUEST_CREATE_CARD_PAYMENT_TRANSACTION_RECORD = 'REQUEST_CREATE_CARD_PAYMENT_TRANSACTION_RECORD';
export const CREATE_CARD_PAYMENT_TRANSACTION_RECORD_SUCCESS = 'CREATE_CARD_PAYMENT_TRANSACTION_RECORD_SUCCESS';

export const sendRequestGenerator =(data)=>{
  return {
    type:SEND_REQUEST,
    payload:data
  }
};
export const sendRequestSuccessGenerator  =(data)=>{
  return {
    type:SEND_REQUEST_SUCCESS,
    payload:data
  }
};
export const confirmPaymentGenerator = (data)=>{
  return {
    type:CONFIRM_PAYMENT,
    payload:data
  }
};
export const confirmPaymentSuccessGenerator =(data)=>{
  return{
    type:CONFIRM_PAYMENT_SUCCESS,
    payload:data
  }
};

export const sendSubscriptionTopUpRequestGenerator =(data)=>{
  return {
    type:SEND_SUBSCRIPTION_REQUEST,
    payload:data
  }
};
export const sendSubscriptionTopUpRequestSuccessGenerator  =(data)=>{
  return {
    type:SEND_SUBSCRIPTION_REQUEST_SUCCESS,
    payload:data
  }
};
export const confirmSubscriptionPaymentGenerator = (data)=>{
  return {
    type:CONFIRM_SUBSCRIPTION_PAYMENT,
    payload:data
  }
};
export const confirmSubscriptionPaymentSuccessGenerator =(data)=>{
  return{
    type:CONFIRM_SUBSCRIPTION_PAYMENT_SUCCESS,
    payload:data
  }
};
export const confirmCardPaymentTransactionGenerator =(data)=>{
  return{
    type:CONFIRM_CARD_PAYMENT_TRANSACTION,
    payload:data
  }
};
export const requestConfirmCardPaymentTransactionGenerator =(data)=>{
  return{
    type:REQUEST_CONFIRM_CARD_PAYMENT_TRANSACTION,
    payload:data
  }
};
export const confirmCardPaymentTransactionSuccessGenerator =(data)=>{
  return{
    type:CONFIRM_CARD_PAYMENT_TRANSACTION_SUCCESS,
    payload:data
  }
};

export const createCardPaymentTransactionRecordGenerator =(data)=>{
  return{
    type:CREATE_CARD_PAYMENT_TRANSACTION_RECORD,
    payload:data
  }
};
export const requestCreateCardPaymentTransactionRecordGenerator =(data)=>{
  return{
    type:REQUEST_CREATE_CARD_PAYMENT_TRANSACTION_RECORD,
    payload:data
  }
};
export const createCardPaymentTransactionRecordSuccessGenerator =(data)=>{
  return{
    type:CREATE_CARD_PAYMENT_TRANSACTION_RECORD_SUCCESS,
    payload:data
  }
};
