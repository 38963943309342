import {
    ADD_DEDUCTION_SUCCESS,
    BULK_DEDUCTION_SUCCESS,
    CANCEL_DEDUCTION_SUCCESS,
    DELETE_DEDUCTION_SUCCESS,
    EDIT_DEDUCTION_SUCCESS,
    FETCH_DEDUCTIONS_SUCCESS,
    FETCH_SAVINGS_SUCCESS,
    HIDE_DEDUCTION_CREATE_FORM,
    HIDE_DEDUCTION_DELETE_FORM,
    HIDE_DEDUCTION_EDIT_FORM,
    SHOW_DEDUCTION_CREATE_FORM,
    SHOW_DEDUCTION_DELETE_FORM,
    SHOW_DEDUCTION_EDIT_FORM
} from '../../actions/payroll/DeductionActions';

const INIT_STATE = {
    deductionsByID:{},
    savingsByID:{},
    showDeductionCreateForm:false,
    showDeductionEditForm:false,
    deductionToEdit:false,
    showDeductionDeleteForm:false,
    deductionToDelete:{}
};

export default (state = INIT_STATE, action)=> {
    let deduction;
    switch (action.type) {
        case BULK_DEDUCTION_SUCCESS:
            return {
                ...state,
                deductionsByID: action.payload
            };
        case FETCH_DEDUCTIONS_SUCCESS:
            return {
                ...state,
                deductionsByID: action.payload
            };
        case FETCH_SAVINGS_SUCCESS:
            return {
                ...state,
                savingsByID: action.payload
            };
        case SHOW_DEDUCTION_CREATE_FORM:
            return {
                ...state,
                showDeductionCreateForm: true
            };
        case HIDE_DEDUCTION_CREATE_FORM:
            return {
                ...state,
                showDeductionCreateForm: false
            };
        case SHOW_DEDUCTION_EDIT_FORM:
            return {
                ...state,
                showDeductionEditForm: true,
                deductionToEdit: action.payload
            };
        case HIDE_DEDUCTION_EDIT_FORM:
            return {
                ...state,
                showDeductionEditForm: false
            };
        case ADD_DEDUCTION_SUCCESS:
            switch (action.payload.type) {
                case 'SAVING':
                    deduction ={...state.savingsByID};
                    deduction[action.payload.id] = action.payload;
                    return {
                        ...state,
                        savingsByID:deduction
                    };
                default:
                    deduction = {...state.deductionsByID};
                    deduction[action.payload.id] = action.payload;
                    return {
                        ...state,
                        deductionsByID:deduction
                    }
            }
        case EDIT_DEDUCTION_SUCCESS:
        case CANCEL_DEDUCTION_SUCCESS:
            switch (action.payload.type) {
                case 'SAVING':
                    deduction ={...state.savingsByID};
                    deduction[action.payload.id] = action.payload;
                    return {
                        ...state,
                        savingsByID:deduction
                    };
                default:
                    deduction = {...state.deductionsByID};
                    deduction[action.payload.id] = action.payload;
                    return {
                        ...state,
                        deductionsByID:deduction
                    }
            }
        case SHOW_DEDUCTION_DELETE_FORM:
            return {
                ...state,
                showDeductionDeleteForm: true,
                deductionToDelete: action.payload
            };
        case HIDE_DEDUCTION_DELETE_FORM:
            return {
                ...state,
                showDeductionDeleteForm: false
            };
        case DELETE_DEDUCTION_SUCCESS:
            switch (action.payload.type) {
                case 'SAVING':
                    deduction ={...state.savingsByID};
                    delete deduction[action.payload.id];
                    return {
                        ...state,
                        savingsByID:deduction
                    };
                default:
                    deduction = {...state.deductionsByID};
                    delete deduction[action.payload.id];
                    return {
                        ...state,
                        deductionsByID:deduction
                    }
            }
        default:
            return state;
    }
}
