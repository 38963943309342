import { call, put,all,fork,takeLatest } from "redux-saga/effects";
import  axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  ATTENDANCE_MAPPING,
  fetchAttendanceMappingSuccessGenerator
} from "../../actions/attendance/AttendanceMappingActions";

const fetchAttendanceMappingRequest = async (requestParams)=>
  await axios.get(API_ENDPOINT+"/attendance/mapping",{params:requestParams})
    .then(response =>response)
    .catch(error=>error.response);

function* fetchAttendanceMapping({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAttendanceMappingRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAttendanceMappingSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchAttendanceMappingDefault(){
  yield takeLatest(ATTENDANCE_MAPPING,fetchAttendanceMapping);
}
export default function* rootSaga() {
  yield all([
    fork(fetchAttendanceMappingDefault),
  ]);
}
