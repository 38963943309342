import {
  ADD_EMPLOYEE_INVOICES_SUCCESS, DELETE_EMPLOYEE_INVOICES, EDIT_EMPLOYEE_INVOICES_SUCCESS,
  FETCH_EMPLOYEE_INVOICES_SUCCESS
} from "../../actions/payroll/EmployeeInvoicesActions";

const INIT_STATE = {
  employeeInvoicesById:{}
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
      case FETCH_EMPLOYEE_INVOICES_SUCCESS:
        return {
          ...state,
          employeeInvoicesById: action.payload
        };
      case ADD_EMPLOYEE_INVOICES_SUCCESS:
      case EDIT_EMPLOYEE_INVOICES_SUCCESS:
        let employeeInvoicesById ={...state.employeeInvoicesById};
        employeeInvoicesById[action.payload.id] =action.payload;
        return{
          ...state,
          employeeInvoicesById:employeeInvoicesById
        };
      case DELETE_EMPLOYEE_INVOICES:
        let employeesByID ={...state.employeeInvoicesById};
        delete employeesByID[action.payload.id];
        return{
          ...state,
          employeeInvoicesById:employeesByID
        };
      default:
        return state;
    }
}
