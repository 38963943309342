import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    FETCH_INFO_TO_SELF_APPRAISE,
    FETCH_SELF_APPRAISALS,
    fetchInfoToSelfAppraiseSuccessGenerator,
    fetchSelfAppraisalsSuccessGenerator, SELF_APPRAISE, selfAppraiseSuccessGenerator
} from "../../actions/appraisal/SelfAppraisalActions";
import { push } from "connected-react-router";
const fetchSelfAppraisalRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/appraisal/selfappraisal',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);
const fetchInfoToSelfAppraiseRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/myprofile/appraise',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const selfAppraiseRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/myprofile/appraisal/rate/multiple',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);
function* fetchSelfAppraisals({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchSelfAppraisalRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchSelfAppraisalsSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchInfoToSelfAppraise({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchInfoToSelfAppraiseRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchInfoToSelfAppraiseSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* selfAppraise({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(selfAppraiseRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(push("/portal/my-appraisals"));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchSelfAppraisalsDefault() {
    yield takeLatest(FETCH_SELF_APPRAISALS, fetchSelfAppraisals)
}
export function* fetchInfoToSelfAppraiseDefault() {
    yield takeLatest(FETCH_INFO_TO_SELF_APPRAISE, fetchInfoToSelfAppraise)
}
export function* selfAppraiseDefault() {
    yield takeLatest(SELF_APPRAISE, selfAppraise)
}
export default function* rootSaga() {
    yield all([
        fork(fetchSelfAppraisalsDefault),
        fork(fetchInfoToSelfAppraiseDefault),
        fork(selfAppraiseDefault)
    ])
};
