import KeyResultSaga from "./KeyResultSaga";
import DepartmentKPISaga from "./DepartmentKPISaga";
import ReviewPeriodSagas from "./ReviewPeriodSagas";
import EmployeeKPISagas from "./EmployeeKPISagas";
import SelfAppraisalSaga from "./SelfAppraisalSaga";
import PerformanceRatingSaga from "./PerformanceRatingSaga";
import PerformanceImprovementProgrammeSaga from "./PerformanceImprovementProgrammeSaga";
import SupervisorLevelSaga from "./SupervisorLevelSaga";

const Appraisal =[
  KeyResultSaga(),
  DepartmentKPISaga(),
  ReviewPeriodSagas(),
  EmployeeKPISagas(),
  SelfAppraisalSaga(),
  PerformanceRatingSaga(),
  PerformanceImprovementProgrammeSaga(),
  SupervisorLevelSaga(),
];
export default Appraisal;
