import {API_ADDRESS} from "../../../constants/ActionTypes";
import {Helpers} from "../../../util/Helpers";
import React from "react";

export const FETCH_EMPLOYEE_KPI = 'FETCH_EMPLOYEE_KPI';
export const FETCH_EMPLOYEE_KPI_SUCCESS = 'FETCH_EMPLOYEE_KPI_SUCCESS';

export const CREATE_EMPLOYEE_KPI ='CREATE_EMPLOYEE_KPI';
export const CREATE_EMPLOYEE_KPI_SUCCESS ='CREATE_EMPLOYEE_KPI_SUCCESS';

export const EDIT_EMPLOYEE_KPI = 'EDIT_EMPLOYEE_KPI';
export const EDIT_EMPLOYEE_KPI_SUCCESS = 'EDIT_EMPLOYEE_KPI_SUCCESS';

export const DELETE_EMPLOYEE_KPI = 'DELETE_EMPLOYEE_KPI';
export const DELETE_EMPLOYEE_KPI_SUCCESS = 'DELETE_EMPLOYEE_KPI_SUCCESS';

export const PRINT_EMPLOYEE_KPI ='PRINT_EMPLOYEE_KPI';
export const UPLOAD_BULK_EMPLOYEE_KPI = 'UPLOAD_BULK_EMPLOYEE_KPI';

export const printEmployeeKPIGenerator =(data)=>{
    return {
        type:PRINT_EMPLOYEE_KPI,
        payload:data
    }
};

export const fetchEmployeeKPIGenerator = (data)=>{
    return {
        type:FETCH_EMPLOYEE_KPI,
        payload:data
    }
};
export const fetchEmployeeKPISuccessGenerator = (data)=>{
    return {
        type:FETCH_EMPLOYEE_KPI_SUCCESS,
        payload:data
    }
};
export const createEmployeeKPIGenerator = (data)=>{
    return {
        type:CREATE_EMPLOYEE_KPI,
        payload:data
    }
};
export const createEmployeeKPISuccessGenerator = (data)=>{
    return {
        type:CREATE_EMPLOYEE_KPI_SUCCESS,
        payload:data
    }
};
export const editEmployeeKPIGenerator = (data)=>{
    return {
        type:EDIT_EMPLOYEE_KPI,
        payload:data
    }
};
export const editEmployeeKPISuccessGenerator = (data)=>{
    return {
        type:EDIT_EMPLOYEE_KPI_SUCCESS,
        payload:data
    }
};
export const deleteEmployeeKPIGenerator =(data)=>{
    return {
        type:DELETE_EMPLOYEE_KPI,
        payload:data
    }
};
export const deleteEmployeeKPISuccessGenerator =(data)=>{
    return {
        type:DELETE_EMPLOYEE_KPI_SUCCESS,
        payload:data
    }
};
export const uploadBulkEmployeeKPIGenerator =(data)=>{
    return {
        type:UPLOAD_BULK_EMPLOYEE_KPI,
        payload:data
    }
};
