import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap';
import withRouter from 'react-router/es/withRouter';
import { Home,Assignment,AccountBalanceWallet,AccessTime } from '@material-ui/icons';
import { Helpers } from '../../util/Helpers';
import { AUTH_SWITCHED_APP } from '../../constants/SessionKeys';
import { connect } from "react-redux";
import { fetchSectorsGenerator } from "../../appRedux/actions/GeneralActions";

const styles = theme => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  title: {
    display: 'flex'
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  fab: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 150,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    size: 'small',
    maxWidth: 'auto',
    maxHeight: '20px',
    minWidth: 'auto',
    minHeight: '20px'
  }
});

class AppSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selected_app: null,
    };
  }

  componentDidMount() {
    this.props.fetchSectorsGenerator();
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

   Apps = (sectorsByID) => (
     <ul className="jr-list jr-list-half">
       <li className="jr-list-item">
         <a href="/home" className="jr-list-link">
           <Home />
           <span className="jr-list-text">
            Home
           </span>
         </a>
       </li>

       <li className="jr-list-item">
         <a href="/attendance/dashboard" className="jr-list-link">
            <AccessTime />
           <span className="jr-list-text">
             {
               Helpers.isSecurity(this.props.companiesByID, sectorsByID)?"Guard Supervision":"Time & Attendance"
             }
           </span>
         </a>
       </li>

       <li className="jr-list-item">
         <a href="/hrm/dashboard" className="jr-list-link">
           <Assignment/>
           <span className="jr-list-text">
            HR & Payroll
           </span>
         </a>
       </li>

       {
         !Helpers.isEthiopian(this.props.countryName) &&
         <li className="jr-list-item">
           <a href="/payouts/dashboard" className="jr-list-link">
             <AccountBalanceWallet/>
             <span className="jr-list-text">
            Payouts
           </span>
           </a>
         </li>
       }

       {
         Helpers.isEthiopian(this.props.countryName) &&
         <li className="jr-list-item">
           <a href="/hrm/kyc" className="jr-list-link">
             <AccountBalanceWallet/>
             <span className="jr-list-text">
            Payouts
           </span>
           </a>
         </li>
       }

     </ul>
   );

  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    });
  };

  render() {
    let sectorsByID = Helpers.sectors();if(!sectorsByID){sectorsByID={};}
    return (
      <div>
        <Dropdown
          className="quick-menu app-notification"
          isOpen={this.state.apps}
          toggle={this.onAppsSelect.bind(this)}>

          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown">
            <span className="app-notification-menu">
              <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
              <span>Apps</span>
            </span>
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem>
              {this.Apps(sectorsByID)}
              {' '}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps =({general,company})=>{
  const {sectorsByID}= general;
  const {companiesByID} = company;
  const branch = Helpers.currentSwitchedBranch(companiesByID);
  const currencyCode = branch.currency_code||'KSH';
  const countryName = branch.country_name||'Kenya';
  return { sectorsByID,companiesByID,currencyCode,countryName};
};

export default withStyles(styles)(connect(mapStateToProps,{fetchSectorsGenerator})(withRouter(AppSwitch)));
