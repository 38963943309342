import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
    DISABLE_DETECTION_MODE,
    disableDetectionModeSuccessGenerator, ENABLE_DETECTION_MODE,
    enableDetectionModeSuccessGenerator, FETCH_DETECTION_MODES,
    fetchDetectionModesSuccessGenerator
} from "../../actions/attendance/DetectionModeActions";

const fetchDetectionMethodsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/attendance/modes-methods',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const enableDetectionModeRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/detection-mode/enable',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const disableDetectionModeRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/detection-mode/disable',requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* fetchDetectionModes({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchDetectionMethodsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let detectionModesById = response.data.data;
                yield put(fetchDetectionModesSuccessGenerator(detectionModesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* enableDetectionMode({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(enableDetectionModeRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let mode = response.data.data;
                yield put(enableDetectionModeSuccessGenerator(mode));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* disableDetectionMode({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(disableDetectionModeRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let mode = response.data.data;
                yield put(disableDetectionModeSuccessGenerator(mode));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchDetectionModesDefault() {
    yield takeLatest(FETCH_DETECTION_MODES, fetchDetectionModes)
}
export function* enableDetectionModeDefault() {
    yield takeLatest(ENABLE_DETECTION_MODE, enableDetectionMode);
}
export function* disableDetectionModeDefault() {
    yield takeLatest(DISABLE_DETECTION_MODE, disableDetectionMode);
}
export default function* rootSaga() {
    yield all([fork(fetchDetectionModesDefault),
        fork(enableDetectionModeDefault),
        fork(disableDetectionModeDefault)
    ]);
}
