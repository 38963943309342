export const FETCH_PERFORMANCE_RATINGS = "FETCH_PERFORMANCE_RATINGS";
export const FETCH_PERFORMANCE_RATINGS_SUCCESS = "FETCH_PERFORMANCE_RATINGS_SUCCESS";
export const ADD_PERFORMANCE_RATING = 'ADD_PERFORMANCE_RATING';
export const ADD_PERFORMANCE_RATING_SUCCESS = 'ADD_PERFORMANCE_RATING_SUCCESS';
export const EDIT_PERFORMANCE_RATING = 'EDIT_PERFORMANCE_RATING';
export const EDIT_PERFORMANCE_RATING_SUCCESS = 'EDIT_PERFORMANCE_RATING_SUCCESS';
export const DELETE_PERFORMANCE_RATING = 'DELETE_PERFORMANCE_RATING';
export const DELETE_PERFORMANCE_RATING_SUCCESS = 'DELETE_PERFORMANCE_RATING_SUCCESS';

export const fetchPerformanceRatingsGenerator = (data=[])=>{
  return {
    type:FETCH_PERFORMANCE_RATINGS,
    payload:data
  };
};

export const fetchPerformanceRatingsSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PERFORMANCE_RATINGS_SUCCESS,
    payload:dataById
  }
};

export const addPerformanceRatingGenerator = (data)=>{
  return {
    type: ADD_PERFORMANCE_RATING,
    payload: data
  }
};

export const addPerformanceRatingSuccessGenerator = (data)=>{
  return {
    type: ADD_PERFORMANCE_RATING_SUCCESS,
    payload: data
  }
};

export const editPerformanceRatingGenerator = (data)=>{
  return {
    type: EDIT_PERFORMANCE_RATING,
    payload: data
  }
};
export const editPerformanceRatingSuccessGenerator = (data)=>{
  return {
    type: EDIT_PERFORMANCE_RATING_SUCCESS,
    payload: data
  }
};

export const deletePerformanceRatingGenerator = (data)=>{
  return {
    type: DELETE_PERFORMANCE_RATING,
    payload: data
  }
};
export const deletePerformanceRatingSuccessGenerator = (data)=>{
  return {
    type: DELETE_PERFORMANCE_RATING_SUCCESS,
    payload: data
  }
};
