import {
  ADD_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_SUCCESS,
  HIDE_CREATE_DEPARTMENT_FORM,
  HIDE_DELETE_DEPARTMENT_MODAL,
  HIDE_EDIT_DEPARTMENT_FORM,
  SHOW_CREATE_DEPARTMENT_FORM,
  SHOW_DELETE_DEPARTMENT_MODAL,
  SHOW_EDIT_DEPARTMENT_FORM
} from '../../actions/common/DepartmentActions';


const INIT_STATE = {
  departmentsByID:[],
  departmentToEdit:[],
  showCreateDepartmentForm:false,
  showEditDepartmentForm:false,
  showDeleteDepartmentModal:false
};

export default (state = INIT_STATE, action)=>{
  let departments;
   switch (action.type){
     case ADD_DEPARTMENT_SUCCESS:
     case EDIT_DEPARTMENT_SUCCESS:
       departments = {...state}.departmentsByID;
       departments[action.payload.id]=action.payload;
       return {
         ...state,
         departmentsByID:departments
       };
     case FETCH_DEPARTMENT_SUCCESS:
       return {
         ...state,
         departmentsByID:action.payload
       };
     case SHOW_CREATE_DEPARTMENT_FORM:
       return {
         ...state,
         showCreateDepartmentForm:true
       };
     case HIDE_CREATE_DEPARTMENT_FORM:
       return {
         ...state,
         showCreateDepartmentForm:false
       };
     case SHOW_EDIT_DEPARTMENT_FORM:
       return {
         ...state,
         departmentToEdit:action.payload,
         showEditDepartmentForm:true
       };
     case HIDE_EDIT_DEPARTMENT_FORM:
       return {
         ...state,
         showEditDepartmentForm:false
       };
       case SHOW_DELETE_DEPARTMENT_MODAL:
       return {
         ...state,
         departmentToEdit:action.payload,
         showDeleteDepartmentModal:true
       };
     case HIDE_DELETE_DEPARTMENT_MODAL:
       return {
         ...state,
         showDeleteDepartmentModal:false
       };
     case DELETE_DEPARTMENT_SUCCESS:
       departments = {...state}.departmentsByID;
       delete departments[action.payload.id]
       return {
         ...state,
         departmentsByID:departments,
       };
     default:
       return {
         ...state
       };
   }
}
