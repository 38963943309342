import {
    ADD_SALARYADVANCE_SUCCESS,
    DELETE_SALARYADVANCE_SUCCESS,
    EDIT_SALARYADVANCE_SUCCESS,
    FETCH_SALARYADVANCES_SUCCESS,
    HIDE_SALARYADVANCE_CREATE_FORM,
    HIDE_SALARYADVANCE_DELETE_FORM,
    HIDE_SALARYADVANCE_EDIT_FORM,
    SHOW_SALARYADVANCE_CREATE_FORM,
    SHOW_SALARYADVANCE_DELETE_FORM,
    SHOW_SALARYADVANCE_EDIT_FORM,
    UPLOAD_SALARY_ADVANCE_SUCCESS
} from '../../actions/payroll/SalaryAdvanceActions';

const INIT_STATE = {
    salaryAdvancesByID:[],
    showSalaryAdvanceCreateForm:false,
    showSalaryAdvanceEditForm:false,
    salaryAdvanceToEdit:false,
    showSalaryAdvanceDeleteForm:false,
    salaryAdvanceToDelete:{}
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
        case FETCH_SALARYADVANCES_SUCCESS:
        case UPLOAD_SALARY_ADVANCE_SUCCESS:
            return {
                ...state,
                salaryAdvancesByID: action.payload
            };
        case SHOW_SALARYADVANCE_CREATE_FORM:
            return {
                ...state,
                showSalaryAdvanceCreateForm: true
            };
        case HIDE_SALARYADVANCE_CREATE_FORM:
            return {
                ...state,
                showSalaryAdvanceCreateForm: false
            };
        case SHOW_SALARYADVANCE_EDIT_FORM:
            return {
                ...state,
                showSalaryAdvanceEditForm: true,
                salaryAdvanceToEdit: action.payload
            };
        case HIDE_SALARYADVANCE_EDIT_FORM:
            return {
                ...state,
                showSalaryAdvanceEditForm: false
            };
        case ADD_SALARYADVANCE_SUCCESS:
        case EDIT_SALARYADVANCE_SUCCESS:
            let salaryAdvanceByID = {...state.salaryAdvancesByID};
            salaryAdvanceByID[action.payload.id] = action.payload;
            return {
                ...state,
                salaryAdvancesByID:salaryAdvanceByID
            };
        case SHOW_SALARYADVANCE_DELETE_FORM:
            return {
                ...state,
                showSalaryAdvanceDeleteForm: true,
                salaryAdvanceToDelete: action.payload
            };
        case HIDE_SALARYADVANCE_DELETE_FORM:
            return {
                ...state,
                showSalaryAdvanceDeleteForm: false
            };
        case DELETE_SALARYADVANCE_SUCCESS:
            let salaryAdvancesByID = {...state.salaryAdvancesByID};
            delete salaryAdvancesByID[action.payload.id];
            return {
                ...state,
                salaryAdvancesByID:salaryAdvancesByID
            };
        default:
            return state;
    }
}
