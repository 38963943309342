import {
  ADD_EMPLOYEE_RATE_SUCCESS,
  DELETE_EMPLOYEE_RATE_SUCCESS,
  EDIT_EMPLOYEE_RATE_SUCCESS,
  FETCH_EMPLOYEE_RATE_SUCCESS, FETCH_SALARY_HISTORY_SUCCESS, FETCH_SINGLE_EMPLOYEE_ALLOWANCES_SUCCESS,
  HIDE_EMPLOYEE_RATE_CREATE_FORM,
  HIDE_EMPLOYEE_RATE_DELETE_FORM,
  HIDE_EMPLOYEE_RATE_EDIT_FORM,
  SHOW_EMPLOYEE_RATE_CREATE_FORM,
  SHOW_EMPLOYEE_RATE_DELETE_FORM,
  SHOW_EMPLOYEE_RATE_EDIT_FORM
} from "../../actions/payroll/EmployeePaymentRateActions";

const INIT_STATE ={
  employeeRatesByID:[],
  salaryHistory:{},
  showEmployeeRateCreateForm:false,
  showEmployeeRateEditForm:false,
  EditingEmployeeRate:[] ,
  DeletingEmployeeRate:[],
  showEmployeeRateDeleteForm:false,
  employeeAllowance:[]
};
export default (state =INIT_STATE,action) =>{
  switch(action.type){
    case FETCH_SINGLE_EMPLOYEE_ALLOWANCES_SUCCESS:
      return {
        ...state,
        employeeAllowance: action.payload
      }
    case ADD_EMPLOYEE_RATE_SUCCESS:
    case EDIT_EMPLOYEE_RATE_SUCCESS:
      let employeeRatesByID = {...state.employeeRatesByID};
      employeeRatesByID[action.payload.id] = action.payload;
      return {
        ...state,
        employeeRatesByID:employeeRatesByID
      };
    case FETCH_EMPLOYEE_RATE_SUCCESS:
      return {
        ...state,
        employeeRatesByID:action.payload
      };
    case SHOW_EMPLOYEE_RATE_CREATE_FORM:
      return{
        ...state,
        showEmployeeRateCreateForm:true
      };
    case HIDE_EMPLOYEE_RATE_CREATE_FORM:
      return {
        ...state,
        showEmployeeRateCreateForm: false
      };
    case SHOW_EMPLOYEE_RATE_EDIT_FORM:
      return{
        ...state,
        showEmployeeRateEditForm:true,
        EditingEmployeeRate:action.payload
      };
    case HIDE_EMPLOYEE_RATE_EDIT_FORM:
      return {
        ...state,
        showEmployeeRateEditForm: false
      };
    case SHOW_EMPLOYEE_RATE_DELETE_FORM:
      return{
        ...state,
        showEmployeeRateDeleteForm:true,
        DeletingEmployeeRate:action.payload
      };
    case HIDE_EMPLOYEE_RATE_DELETE_FORM:
      return{
        ...state,
        showEmployeeRateDeleteForm:false,
      };
    case DELETE_EMPLOYEE_RATE_SUCCESS:
      let employee_rates ={...state.employeeRatesByID};
      delete employee_rates[action.payload.id];
      return{
        ...state,
        employeeRatesByID:employee_rates
      };
    case FETCH_SALARY_HISTORY_SUCCESS:
      return{
        ...state,
        salaryHistory:action.payload
      };
    default:
      return state;
  }
}
