export const FETCH_ADMIN_COMPANIES = "FETCH_ADMIN_COMPANIES";
export const FETCH_ADMIN_COMPANIES_SUCCESS = "FETCH_ADMIN_COMPANIES_SUCCESS";

export const CREATE_PAYMENT_CHANNEL = "CREATE_PAYMENT_CHANNEL";
export const CREATE_PAYMENT_CHANNEL_SUCCESS = "CREATE_PAYMENT_CHANNEL_SUCCESS";

export const FETCH_COMPANY_TRANSACTIONS = "FETCH_COMPANY_TRANSACTIONS";
export const FETCH_COMPANY_TRANSACTIONS_SUCCESS = "FETCH_COMPANY_TRANSACTIONS_SUCCESS";

export const FETCH_ALL_TRANSACTIONS = "FETCH_ALL_TRANSACTIONS";
export const FETCH_ALL_TRANSACTIONS_SUCCESS = "FETCH_ALL_TRANSACTIONS_SUCCESS";

export const ENABLE_APPRAISAL_COMPANY = "ENABLE_APPRAISAL_COMPANY";

export const BULK_UPDATE_TRANSACTIONS = "BULK_UPDATE_TRANSACTIONS";

export const GENERATE_QRCODE = "GENERATE_QRCODE";
export const CLIENT_GENERATE_QRCODE = "CLIENT_GENERATE_QRCODE";

export const ADD_COMPANY_BALANCE = "ADD_COMPANY-BALANCE";

export const COMPANY_PROFILE = "COMPANY_PROFILE";
export const COMPANY_PROFILE_SUCCESS = "COMPANY_PROFILE_SUCCESS";

export const DEACTIVATE_COMPANY_SUBSCRIPTION = "DEACTIVATE_COMPANY_SUBSCRIPTION";

export const EXPORT_COMPANY_BALANCES = "EXPORT_COMPANY_BALANCES";

export const  MARK_EMAIL_VERIFIED = 'MARK_EMAIL_VERIFIED';
export const  MARK_EMAIL_VERIFIED_SUCCESS = 'MARK_EMAIL_VERIFIED_SUCCESS';

export const FETCH_TRANSACTION_ACCOUNTS = "FETCH_TRANSACTION_ACCOUNTS";
export const FETCH_TRANSACTION_ACCOUNTS_SUCCESS = "FETCH_TRANSACTION_ACCOUNTS_SUCCESS";

export const GET_THREE_PAYROLLS_FOR_A_COMPANY = 'GET_THREE_PAYROLLS_FOR_A_COMPANY';
export const GET_THREE_PAYROLLS_FOR_A_COMPANY_SUCCESS = "GET_THREE_PAYROLLS_FOR_A_COMPANY_SUCCESS";

export const FETCH_ADMIN_PAGINATED_COMPANY_WALLETS = 'FETCH_ADMIN_PAGINATED_COMPANY_WALLETS';
export const FETCH_ADMIN_PAGINATED_COMPANY_WALLETS_SUCCESS = "FETCH_ADMIN_PAGINATED_COMPANY_WALLETS_SUCCESS";

export const UPDATE_COMPANY_BALANCE_LIMIT = "UPDATE_COMPANY_BALANCE_LIMIT";

export const FILTER_COMPANY_BY_DATE = 'FILTER_COMPANY_BY_DATE';
export const ADD_COMPANY_EMPLOYEES = 'ADD_COMPANY_EMPLOYEES';

export const FETCH_ADMIN_PAGINATED_COMPANIES_SUCCESS = 'FETCH_ADMIN_PAGINATED_COMPANIES_SUCCESS';

export const EXPORT_COMPANIES_BALANCES = "EXPORT_COMPANIES_BALANCES";
export const EXPORT_COMPANIES_BALANCES_SUCCESS = "EXPORT_COMPANIES_BALANCES_SUCCESS";

export const filterCompanyByDateGenerator = (payload =[])=>{
  return {
    type:FILTER_COMPANY_BY_DATE,
    payload
  }
};

export const getThreePayrollsForACompanyGenerator = (payload = [])=>{
  return{
    type:GET_THREE_PAYROLLS_FOR_A_COMPANY,
    payload
  }
};
export const getThreePayrollsForACompanySuccessGenerator = (payload = [])=>{
  return{
    type:GET_THREE_PAYROLLS_FOR_A_COMPANY_SUCCESS,
    payload
  }
};

export const markEmailVerifiedGenerator =(payload=[])=>{
  return{
    type:MARK_EMAIL_VERIFIED,
    payload
  }
};
export const markEmailVerifiedSuccessGenerator =(payload=[])=>{
  return{
    type:MARK_EMAIL_VERIFIED_SUCCESS,
    payload
  }
};

export const exportCompanyBalanceGenerator = (data) => {
  return {
    type: EXPORT_COMPANY_BALANCES,
    payload: data
  };
};
export const addCompanyBalanceGenerator = data => {
  return {
    type: ADD_COMPANY_BALANCE,
    payload: data
  };
};

export const addCompanyEmployeesGenerator = data => {
  return {
    type: ADD_COMPANY_EMPLOYEES,
    payload: data
  };
};

export const deactivateCompanySubscriptionGenerator = data => {
  return {
    type: DEACTIVATE_COMPANY_SUBSCRIPTION,
    payload: data
  };
};

export const companyProfileGenerator = (data = []) => {
  return {
    type: COMPANY_PROFILE,
    payload: data
  };
};
export const companyProfileSuccessGenerator = data => {
  return {
    type: COMPANY_PROFILE_SUCCESS,
    payload: data
  };
};

export const generateQrCodeGenerator = (data = []) => {
  return {
    type: GENERATE_QRCODE,
    payload: data
  };
};

export const clientGenerateQrCodeGenerator = (data = []) => {
  return {
    type: CLIENT_GENERATE_QRCODE,
    payload: data
  };
};

export const enableAppraisalCompanyGenerator = data => {
  return {
    type: ENABLE_APPRAISAL_COMPANY,
    payload: data
  };
};

export const fetchAllTransactionsGenerator = (data = []) => {
  return {
    type: FETCH_ALL_TRANSACTIONS,
    payload: data
  };
};
export const fetchAllTransactionsSuccessGenerator = (data = []) => {
  return {
    type: FETCH_ALL_TRANSACTIONS_SUCCESS,
    payload: data
  };
};

export const fetchCompanyTransactionsGenerator = data => {
  return {
    type: FETCH_COMPANY_TRANSACTIONS,
    payload: data
  };
};
export const fetchCompanyTransactionsSuccessGenerator = data => {
  return {
    type: FETCH_COMPANY_TRANSACTIONS_SUCCESS,
    payload: data
  };
};

export const fetchAdminCompaniesGenerator = data => {
  return {
    type: FETCH_ADMIN_COMPANIES,
    payload: data
  };
};

export const fetchAdminCompaniesSuccessGenerator = data => {
  return {
    type: FETCH_ADMIN_COMPANIES_SUCCESS,
    payload: data
  };
};

// export const exportCompaniesBalancesGenerator = data => {
//   return {
//     type: EXPORT_COMPANIES_BALANCES,
//     payload: data
//   };
// };

export const exportCompaniesBalancesSuccessGenerator = data => {
  return {
    type: EXPORT_COMPANIES_BALANCES_SUCCESS,
    payload: data
  };
};

export const fetchAdminPaginatedCompaniesSuccessGenerator = data => {
  return {
    type: FETCH_ADMIN_PAGINATED_COMPANIES_SUCCESS,
    payload: data
  };
};

export const fetchAdminPaginatedCompanyWallets = data => {
  return {
    type: FETCH_ADMIN_PAGINATED_COMPANY_WALLETS,
    payload: data
  };
};

export const fetchAdminPaginatedCompanyWalletsSuccessGenerator = data => {
  return {
    type: FETCH_ADMIN_PAGINATED_COMPANY_WALLETS_SUCCESS,
    payload: data
  };
};

export const createCompanyPaymentChannel = data => {
  return {
    type: CREATE_PAYMENT_CHANNEL,
    payload: data
  };
};
export const createCompanyPaymentChannelSuccess = data => {
  return {
    type: CREATE_PAYMENT_CHANNEL_SUCCESS,
    payload: data
  };
};
export const bulkUpdateTransactionsGenerator = data => {
  return {
    type: BULK_UPDATE_TRANSACTIONS,
    payload: data
  };
};
export const fetchTransactionAccountsGenerator = data => {
  return {
    type: FETCH_TRANSACTION_ACCOUNTS,
    payload: data
  };
};
export const fetchTransactionAccountsSuccessGenerator = data => {
  return {
    type: FETCH_TRANSACTION_ACCOUNTS_SUCCESS,
    payload: data
  };
};
export const updateCompanyBalanceLimitGenerator = data => {
  return {
    type: UPDATE_COMPANY_BALANCE_LIMIT,
    payload: data
  };
};

