export const FETCH_REVIEW_PERIODS = 'FETCH_REVIEW_PERIODS';
export const FETCH_REVIEW_PERIODS_SUCCESS = 'FETCH_REVIEW_PERIODS_SUCCESS';

export const CREATE_REVIEW_PERIODS ='CREATE_REVIEW_PERIODS';
export const CREATE_REVIEW_PERIODS_SUCCESS ='CREATE_REVIEW_PERIODS_SUCCESS';

export const EDIT_REVIEW_PERIODS = 'EDIT_REVIEW_PERIODS';
export const EDIT_REVIEW_PERIODS_SUCCESS = 'EDIT_REVIEW_PERIODS_SUCCESS';

export const DELETE_REVIEW_PERIODS = 'DELETE_REVIEW_PERIODS';
export const DELETE_REVIEW_PERIODS_SUCCESS = 'DELETE_REVIEW_PERIODS_SUCCESS';

export const fetchReviewPeriodsGenerator = (data=[])=>{
    return {
        type:FETCH_REVIEW_PERIODS,
        payload:data
    }
};
export const fetchReviewPeriodsSuccessGenerator = (data=[])=>{
    return {
        type:FETCH_REVIEW_PERIODS_SUCCESS,
        payload:data
    }
};
export const createReviewPeriodsGenerator = (data)=>{
    return {
        type:CREATE_REVIEW_PERIODS,
        payload:data
    }
};
export const createReviewPeriodsSuccessGenerator = (data)=>{
    return {
        type:CREATE_REVIEW_PERIODS_SUCCESS,
        payload:data
    }
};
export const editReviewPeriodsGenerator = (data)=>{
    return {
        type:EDIT_REVIEW_PERIODS,
        payload:data
    }
};
export const editReviewPeriodsSuccessGenerator = (data)=>{
    return {
        type:EDIT_REVIEW_PERIODS_SUCCESS,
        payload:data
    }
};
export const deleteReviewPeriodsGenerator =(data)=>{
    return {
        type:DELETE_REVIEW_PERIODS,
        payload:data
    }
};
export const deleteReviewPeriodsSuccessGenerator =(data)=>{
    return {
        type:DELETE_REVIEW_PERIODS_SUCCESS,
        payload:data
    }
};
