import {all} from "redux-saga/effects";
import authSagas from "./AuthSaga";
import generalSagas from "./GeneralSaga"
import Common from "./Common";
import Payroll from "./payroll";
import Attendance from "./attendance";
import Payouts from "./payouts";
import Profile from "./profiles";
import Admin from "./admin";
import Appraisal from "./appraisal";

const defaultSagas =[authSagas(),generalSagas()];
const allSagas = defaultSagas.concat(Common,Payroll,Attendance,Payouts,Profile,Admin,Appraisal);

export default function* rootSaga(getState) {
  yield all(allSagas);
}
