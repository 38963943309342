import {Helpers} from "../../../util/Helpers";
import axios from "axios/index";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_ADDITION_CATEGORIES,
  addAdditionCategorySuccessGenerator,
  DELETE_ADDITION_CATEGORIES,
  deleteAdditionCategorySuccessGenerator,
  EDIT_ADDITION_CATEGORIES,
  editAdditionCategorySuccessGenerator,
  FETCH_ADDITION_CATEGORIES, fetchAdditionCarCCBandsSuccessGenerator, fetchAdditionCategoryGenerator,
  fetchAdditionCategorySuccessGenerator
} from "../../actions/payroll/AdditionalCategoriesActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
const fetchAdditionCategoriesRequests = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/addition-categories',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);

function* fetchAdditionCategories({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAdditionCategoriesRequests, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        let additionCategories = data.addition_categories;
        let car_cc_bands = data.car_cc_bands;
        yield put(fetchAdditionCategorySuccessGenerator(additionCategories));
        yield put(fetchAdditionCarCCBandsSuccessGenerator(car_cc_bands));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchAdditionCategoriesDefault() {
  yield takeLatest(FETCH_ADDITION_CATEGORIES,fetchAdditionCategories)
}
////end fetch
///edit addition category
const editAdditionCategoriesRequest = async (requestParameters)=>
  await axios.put(API_ENDPOINT+'/hrm/addition-categories/'+requestParameters.id,requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* updateAdditionCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAdditionCategoriesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let additionCategories = response.data.data;
        yield put(fetchAdditionCategoryGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateAdditionCategoryDefault() {
  yield takeLatest(EDIT_ADDITION_CATEGORIES,updateAdditionCategory)
}
//// add new addition category
const addAdditionCategoryRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/addition-categories',requestParameters)
    .then(response=>response)
    .catch(error => error.message);

function* addAdditionCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addAdditionCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let additionCategories = response.data.data;
        yield put(addAdditionCategorySuccessGenerator(additionCategories));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addAdditionCategoryDefault() {
  yield takeLatest(ADD_ADDITION_CATEGORIES,addAdditionCategory)
}
///end add addition Category
//// delete addition category
const deleteAdditionCategoryRequest = async (requestParameters)=>
  await axios.delete(API_ENDPOINT+'/hrm/addition-categories/'+requestParameters.id,{params:requestParameters})
    .then(response=>response)
    .catch(error => error.message);

function* deleteAdditionCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteAdditionCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let additionCategories = response.data.data;
        yield put(deleteAdditionCategorySuccessGenerator(additionCategories));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteAdditionCategoryDefault() {
  yield takeLatest(DELETE_ADDITION_CATEGORIES,deleteAdditionCategory)
}
////end delete category
export default function* rootSagas(){
  yield all([fork(fetchAdditionCategoriesDefault),fork(updateAdditionCategoryDefault),fork(addAdditionCategoryDefault),fork(deleteAdditionCategoryDefault)])
}
