export const FETCH_ALL_FEATURES ='FETCH_ALL_FEATURES';
export const FETCH_ALL_FEATURES_SUCCESS ='FETCH_ALL_FEATURES_SUCCESS';

export const FETCH_COMPANY_ENABLED_FEATURES ='FETCH_COMPANY_ENABLED_FEATURES';
export const FETCH_COMPANY_ENABLED_FEATURES_SUCCESS ='FETCH_COMPANY_ENABLED_FEATURES_SUCCESS';

export const ENABLE_DISABLE_FEATURES = 'ENABLE_DISABLE_FEATURES';
//// on success re fetch company enabled

export const fetchAllFeaturesGenerator = (payload=[])=>{
    return {
        type:FETCH_ALL_FEATURES,
        payload
    }
}
export const fetchAllFeaturesSuccessGenerator = (payload=[])=>{
    return {
        type:FETCH_ALL_FEATURES_SUCCESS,
        payload
    }
}

export const fetchCompanyFeaturesGenerator = (payload=[])=>{
    return {
        type:FETCH_COMPANY_ENABLED_FEATURES,
        payload
    }
}
export const fetchCompanyFeaturesSuccessGenerator = (payload=[])=>{
    return {
        type:FETCH_COMPANY_ENABLED_FEATURES_SUCCESS,
        payload
    }
}

export const enableDisableCompanyFeaturesGenerator = (payload=[])=>{
    return {
        type:ENABLE_DISABLE_FEATURES,
        payload
    }
}

