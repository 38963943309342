import { FETCH_LOAN_REQUESTS_SUCCESS } from "../../actions/admin/LoanRequestActions";
import {
  DELETE_ADMIN_LOAN_SUCCESS,
  EDIT_ADMIN_LOAN_SUCCESS
} from "../../actions/admin/CompanyLoansActions";


const INIT_STATE = {
  loan_requests:{}
};

export default (status=INIT_STATE,action)=>{
  let loans ={...status.loan_requests}
  switch (action.type) {
    case EDIT_ADMIN_LOAN_SUCCESS:
      loans[action.payload.id]=action.payload;
      return {
        ...status,
        loan_requests: loans
      }
    case DELETE_ADMIN_LOAN_SUCCESS:
      delete loans[action.payload.id];
      return {
        ...status,
        loan_requests: action.payload
      }
    case FETCH_LOAN_REQUESTS_SUCCESS:
      return{
        ...status,
        loan_requests:action.payload
      };
    default:
      return status;
  }
}
