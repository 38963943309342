export const FETCH_ONBOARDING_COMPANIES = "FETCH_ONBOARDING_COMPANIES";
export const FETCH_ONBOARDING_COMPANIES_SUCCESS = "FETCH_ONBOARDING_COMPANIES_SUCCESS";

export const ADD_ONBOARDING_COMPANY = "ADD_ONBOARDING_COMPANY";
export const ADD_ONBOARDING_COMPANY_SUCCESS = "ADD_ONBOARDING_COMPANY_SUCCESS";

export const EDIT_ONBOARDING_COMPANY = "EDIT_ONBOARDING_COMPANY";
export const EDIT_ONBOARDING_COMPANY_SUCCESS = "EDIT_ONBOARDING_COMPANY_SUCCESS";

export const MARK_SUBSCRIPTION_PAID = "MARK_SUBSCRIPTION_PAID";
export const MARK_SUBSCRIPTION_PAID_SUCCESS = "MARK_SUBSCRIPTION_PAID_SUCCESS";

export const SEND_INVITATION = "SEND_INVITATION";
export const SEND_INVITATION_SUCCESS = "SEND_INVITATION_SUCCESS";

export const FETCH_ONBOARDING_WORKFLOW = "FETCH_ONBOARDING_WORKFLOW";
export const FETCH_ONBOARDING_WORKFLOW_SUCCESS = "FETCH_ONBOARDING_WORKFLOW_SUCCESS";

export const ADD_ONBOARDING_WORKFLOW = "ADD_ONBOARDING_WORKFLOW";
export const ADD_ONBOARDING_WORKFLOW_SUCCESS = "ADD_ONBOARDING_WORKFLOW_SUCCESS";


export const fetchOnboardingCompanyGenerator =(data=[])=>{
  return{
    type:FETCH_ONBOARDING_COMPANIES,
    payload:data
  }
};
export const fetchOnboardingCompanySuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_ONBOARDING_COMPANIES_SUCCESS,
    payload:dataByID
  }
};

export const addOnboardingCompanyGenerator =(data=[])=>{
  return{
    type:ADD_ONBOARDING_COMPANY,
    payload:data
  }
};
export const addOnboardingCompanySuccessGenerator =(data)=>{
  return{
    type:ADD_ONBOARDING_COMPANY_SUCCESS,
    payload:data
  }
};


export const editOnboardingCompanyGenerator =(data=[])=>{
  return{
    type:EDIT_ONBOARDING_COMPANY,
    payload:data
  }
};
export const editOnboardingCompanySuccessGenerator =(data)=>{
  return{
    type:EDIT_ONBOARDING_COMPANY_SUCCESS,
    payload:data
  }
};

export const markSubscriptionPaidGenerator =(data=[])=>{
  return{
    type: MARK_SUBSCRIPTION_PAID,
    payload:data
  }
};
export const markSubscriptionPaidSuccessGenerator =(data)=>{
  return{
    type: MARK_SUBSCRIPTION_PAID_SUCCESS,
    payload:data
  }
};

export const sendInvitationGenerator =(data=[])=>{
  return{
    type: SEND_INVITATION,
    payload:data
  }
};
export const sendInvitationSuccessGenerator =(data)=>{
  return{
    type: SEND_INVITATION_SUCCESS,
    payload:data
  }
};

export const fetchOnboardingWorkflowGenerator =(data=[])=>{
  return{
    type:FETCH_ONBOARDING_WORKFLOW,
    payload:data
  }
};
export const fetchOnboardingWorkflowSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_ONBOARDING_WORKFLOW_SUCCESS,
    payload:dataByID
  }
};

export const addOnboardingWorkflowGenerator =(data=[])=>{
  return{
    type:ADD_ONBOARDING_WORKFLOW,
    payload:data
  }
};
export const addOnboardingWorkflowSuccessGenerator =(data)=>{
  return{
    type:ADD_ONBOARDING_WORKFLOW_SUCCESS,
    payload:data
  }
};
