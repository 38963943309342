import {
    ADD_PRICING_SUCCESS, DELETE_PRICING_SUCCESS,
    FETCH_PRICINGS_SUCCESS,
    UPDATE_PRICING_SUCCESS
} from "../../actions/admin/PricingActions";

const INIT_STATE ={
    pricingsByID:{}
};
export default (state = INIT_STATE,action)=>{
    let pricing;
    switch (action.type) {
        case FETCH_PRICINGS_SUCCESS:
            return {
                ...state,
                pricingsByID: action.payload
            };
        case ADD_PRICING_SUCCESS:
        case UPDATE_PRICING_SUCCESS:
            pricing = {...state.pricingsByID};
            pricing[action.payload.id] = action.payload;
            return{
                ...state,
                pricingsByID:pricing
            };
        case DELETE_PRICING_SUCCESS:
            pricing = {...state.pricingsByID};
            delete pricing[action.payload.id];
            return {
                ...state,
                pricingsByID:pricing
            };
        default:
            return {...state};
    }
}