import {all, call, fork, put} from "redux-saga/effects";
import {
    ADD_ADMIN_USERS,
    DELETE_ADMIN_USERS,
    deleteAdminUsersSuccessGenerator,
    UPDATE_ADMIN_USERS,
    updateAdminUsersSuccessGenerator,
    FETCH_ADMIN_USERS,
    fetchAdminUsersGenerator,
    fetchAdminUsersSuccessGenerator, fetchAdminPayoutTrailSuccessGenerator, FETCH_ADMIN_PAYOUT_TRAIL
} from "../../actions/admin/AdminUsersActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchAdminUsersRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/admin-users',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addAdminUsersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/admin-users',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteAdminUsersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/delete-admin-users',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const updateAdminUsersRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/tozza/update-admin-users',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchAdminPayoutTrailRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/admin-users/payout-trail',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);


function* fetchAdminUsers({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchAdminUsersRequest, requestParameters);

        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchAdminUsersSuccessGenerator(rolesById));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
function* fetchAdminPayoutTrail({type, payload}) {
    yield put(showAppLoaderGenerator(type)) ;
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchAdminPayoutTrailRequest, requestParameters);

        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let data = response.data.data;
                yield put(fetchAdminPayoutTrailSuccessGenerator(data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type)) ;
}
function* addAdminUsers({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addAdminUsersRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                /*let role = response.data.data;*/
                yield put(fetchAdminUsersGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
function* deleteAdminUsers({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteAdminUsersRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(deleteAdminUsersSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
function* updateAdminUsers({payload}) {
    try {
        let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(updateAdminUsersRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(fetchAdminUsersGenerator());
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }

}
export function* fetchAdminUsersDefault() {
    yield takeLatest(FETCH_ADMIN_USERS, fetchAdminUsers)
}
export function* fetchAdminPayoutTrailDefault() {
    yield takeLatest(FETCH_ADMIN_PAYOUT_TRAIL, fetchAdminPayoutTrail)
}
export function* addAdminUsersDefault() {
    yield takeLatest(ADD_ADMIN_USERS, addAdminUsers);
}
export function* deleteAdminUsersDefault() {
    yield takeLatest(DELETE_ADMIN_USERS, deleteAdminUsers);
}
export function* updateAdminUsersDefault() {
    yield takeLatest(UPDATE_ADMIN_USERS, updateAdminUsers);
}

export default function* rootSaga() {
    yield all([fork(fetchAdminUsersDefault),
        fork(addAdminUsersDefault),
        fork(deleteAdminUsersDefault),
        fork(updateAdminUsersDefault),
        fork(fetchAdminPayoutTrailDefault)
    ]);
}
