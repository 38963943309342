import {
  ADD_PETTY_CASH_SUCCESS,
  DELETE_PETTY_CASH_SUCCESS,
  EDIT_PETTY_CASH_SUCCESS,
  FETCH_PETTY_CASH_SUCCESS,
  HIDE_PETTY_CASH_CREATE_FORM,
  HIDE_PETTY_CASH_DELETE_FORM,
  HIDE_PETTY_CASH_EDIT_FORM,
  SHOW_PETTY_CASH_CREATE_FORM,
  SHOW_PETTY_CASH_DELETE_FORM,
  SHOW_PETTY_CASH_EDIT_FORM
} from '../../actions/payroll/PettyCashActions';

const INIT_STATE = {
  pettyCashByID:{},
  showPettyCashCreateForm:false,
  showPettyCashEditForm:false,
  pettyCashToEdit:false,
  showPettyCashDeleteForm:false,
  pettyCashToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PETTY_CASH_SUCCESS:
      return {
        ...state,
        pettyCashByID: action.payload
      };
    case SHOW_PETTY_CASH_CREATE_FORM:
      return {
        ...state,
        showPettyCashCreateForm: true
      };
    case HIDE_PETTY_CASH_CREATE_FORM:
      return {
        ...state,
        showPettyCashCreateForm: false
      };
    case SHOW_PETTY_CASH_EDIT_FORM:
      return {
        ...state,
        showPettyCashEditForm: true,
        pettyCashToEdit: action.payload
      };
    case HIDE_PETTY_CASH_EDIT_FORM:
      return {
        ...state,
        showPettyCashEditForm: false
      };
    case ADD_PETTY_CASH_SUCCESS:
    case EDIT_PETTY_CASH_SUCCESS:
      if (!Array.isArray(action.payload) && typeof action.payload === 'object') {
        let pettyCash_id = action.payload.id;
        return {
          ...state,
          pettyCashByID: {
            ...state.pettyCashByID,
            [pettyCash_id]: action.payload
          }
        };
      }
      let pettyCashesByID = { ...state.pettyCashByID };
      action.payload.forEach(record => {
        pettyCashesByID[record.id] = record;
      });
      return {
        ...state,
        pettyCashByID: pettyCashesByID
      };

    case SHOW_PETTY_CASH_DELETE_FORM:
      return {
        ...state,
        showPettyCashDeleteForm: true,
        pettyCashToDelete: action.payload
      };
    case HIDE_PETTY_CASH_DELETE_FORM:
      return {
        ...state,
        showPettyCashDeleteForm: false
      };
    case DELETE_PETTY_CASH_SUCCESS:
      let pettyCashByID = {...state.pettyCashByID};
      delete pettyCashByID[action.payload.id];
      return {
        ...state,
        pettyCashByID:pettyCashByID
      };
    default:
      return state;
  }
}
