import {
  ADD_EXIT_REASON_SUCCESS,
  DELETE_EXIT_REASON_SUCCESS,
  EDIT_EXIT_REASON_SUCCESS,
  FETCH_EXIT_REASONS_SUCCESS,
  HIDE_EXIT_REASON_CREATE_FORM,
  HIDE_EXIT_REASON_DELETE_FORM,
  HIDE_EXIT_REASON_EDIT_FORM,
  SHOW_EXIT_REASON_CREATE_FORM,
  SHOW_EXIT_REASON_DELETE_FORM,
  SHOW_EXIT_REASON_EDIT_FORM
} from '../../actions/payroll/ExitReasonActions';

const INIT_STATE = {
  exitReasonsByID:{},
  showExitReasonCreateForm:false,
  showExitReasonEditForm:false,
  exitReasonToEdit:false,
  showExitReasonDeleteForm:false,
  exitReasonToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_EXIT_REASONS_SUCCESS:
      return {
        ...state,
        exitReasonsByID: action.payload
      };
    case SHOW_EXIT_REASON_CREATE_FORM:
      return {
        ...state,
        showExitReasonCreateForm: true
      };
    case HIDE_EXIT_REASON_CREATE_FORM:
      return {
        ...state,
        showExitReasonCreateForm: false
      };
    case SHOW_EXIT_REASON_EDIT_FORM:
      return {
        ...state,
        showExitReasonEditForm: true,
        exitReasonToEdit: action.payload
      };
    case HIDE_EXIT_REASON_EDIT_FORM:
      return {
        ...state,
        showExitReasonEditForm: false
      };
    case ADD_EXIT_REASON_SUCCESS:
    case EDIT_EXIT_REASON_SUCCESS:
      let exit_reason_id = action.payload.id;
      return {
        ...state,
        exitReasonsByID: {
          ...state.exitReasonsByID,
          [exit_reason_id]:action.payload
        }
      };
    case SHOW_EXIT_REASON_DELETE_FORM:
      return {
        ...state,
        showExitReasonDeleteForm: true,
        exitReasonToDelete: action.payload
      };
    case HIDE_EXIT_REASON_DELETE_FORM:
      return {
        ...state,
        showExitReasonDeleteForm: false
      };
    case DELETE_EXIT_REASON_SUCCESS:
      let exitReasonsByID = {...state.exitReasonsByID};
      delete exitReasonsByID[action.payload.id];
      return {
        ...state,
        exitReasonsByID:exitReasonsByID
      };
    default:
      return state;
  }
}
