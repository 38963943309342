export const FETCH_PRICINGS = "FETCH_PRICINGS";
export const FETCH_PRICINGS_SUCCESS = "FETCH_PRICINGS_SUCCESS";
export const ADD_PRICING = "ADD_PRICING";
export const ADD_PRICING_SUCCESS = "ADD_PRICING_SUCCESS";
export const UPDATE_PRICING = "UPDATE_PRICING";
export const UPDATE_PRICING_SUCCESS = "UPDATE_PRICING_SUCCESS";
export const DELETE_PRICING = "DELETE_PRICING";
export const DELETE_PRICING_SUCCESS = "DELETE_PRICING_SUCCESS";
export const fetchPricingGenerator = (data=[])=>{
    return {
        type:FETCH_PRICINGS,
        payload:data
    };
};

export const fetchPricingSuccessGenerator = (data)=> {
    return {
        type:FETCH_PRICINGS_SUCCESS,
        payload:data
    }
};

export const deletePricingGenerator = (data)=> {
    return {
        type:DELETE_PRICING,
        payload:data
    }
};

export const deletePricingSuccessGenerator = (data)=> {
    return {
        type:DELETE_PRICING_SUCCESS,
        payload:data
    }
};

export const addPricingSuccessGenerator = (data)=> {
    return {
        type:ADD_PRICING_SUCCESS,
        payload:data
    }
};

export const addPricingGenerator = (data)=> {
    return {
        type:ADD_PRICING,
        payload:data
    }
};

export const updatePricingGenerator =(data)=>{
    return {
        type:UPDATE_PRICING,
        payload:data
    }
};

export const updatePricingSuccessGenerator =(data)=>{
    return {
        type:UPDATE_PRICING_SUCCESS,
        payload:data
    }
};