import Company from "./CompanyReducer";
import Department from "./DepartmentReducer";
import Designation from "./DesignationReducer";
import Role from "./RoleReducer";
import PensionRate from "./PensionRateReducer";
import User from "./UserReducer";
import Dashboards from "./DashboardsReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import WithholdingTaxReducer from "./WithholdingTaxReducer";

const Common = {
    company: Company,
    department:Department,
    designation:Designation,
    role:Role,
    pension_rate:PensionRate,
    user:User,
    dashboards:Dashboards,
    subscription:SubscriptionReducer,
    withholding_tax_rate:WithholdingTaxReducer
};

export default Common;
