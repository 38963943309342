import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Mpesa from './mpesa';
import Equitel from './equity';
import BankTransfer from './bankTransfer';
import Flutterwave from './flutterwave';
import Headline from '../../../components/payouts/headline';
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import {Helpers} from "../../../util/Helpers";
import {connect} from "react-redux";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  halfWidth: {
    width: '45%',
    margin: 8
  },
  formControl: {
    width: '100%'
  },
  title: {
    display: 'flex'
  },
  card: {
    marginTop: 15,
  },
});

class Recharge extends React.Component {
  state = {
    value: 0
  };

  switchTabs = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {

  }

  render() {
    const branch = Helpers.currentSwitchedBranch(this.props.companiesByID);
    const { classes } = this.props;
    const account_number = branch.account_number || '';
    let { value } = this.state;
    const currentSubscription = Helpers.currentSubscription();

    const currentSubscriptionText = () => {
      return(
          currentSubscription ? (
            <span>
                Current Plan:{currentSubscription}
              </span>
          ) : null
      )
    }
    return (
      <div>
        {
          !this.props.page ?
              <div>
                <Headline headline="Top Up" accountNo={account_number} />
              </div>
              : (
                <div className='d-flex align-items-center'>
                <h1 style={{ marginLeft:20, marginTop:20}}>
                  {"Top Up  "}

                { currentSubscriptionText()}
                </h1>
              </div>
              )
        }
        <Divider />
        <Card className={classes.card}>
          <div className="row">
            <div className="col-12">
              <Tabs value={value} onChange={this.switchTabs} indicatorColor="primary" centered variant="fullWidth" className="tabs-roles">
                <Tab label="M-Pesa" />
                { !this.props.page && <Tab label="Equitel" /> }
                {!this.props.page &&<Tab label="Card" />}
                {!this.props.page &&<Tab label="Bank Transfer" />}
              </Tabs>
              {value === 0 && <Mpesa {...this.props.location.state} />}
              { (!this.props.page && value === 1 )  && <Equitel {...this.props.location.state} />}
              { ( (this.props.page && value === 1 ) || value === 2) && <Flutterwave {...this.props.location.state} />}
              { ( (this.props.page && value === 1 && value === 2) || value === 3) && <BankTransfer {...this.props.location.state} />}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
const mapStateToProps =({company})=>{
  const {companiesByID} = company;
  return {companiesByID}
};
export default withStyles(styles)(connect(mapStateToProps,{})(Recharge));
