import {
  ADD_PETTY_CASH_RECEIPT_SUCCESS,
  DELETE_PETTY_CASH_RECEIPT_SUCCESS,
  EDIT_PETTY_CASH_RECEIPT_SUCCESS,
  FETCH_PETTY_CASH_RECEIPT_SUCCESS,
  HIDE_PETTY_CASH_RECEIPT_CREATE_FORM,
  HIDE_PETTY_CASH_RECEIPT_DELETE_FORM,
  HIDE_PETTY_CASH_RECEIPT_EDIT_FORM,
  SHOW_PETTY_CASH_RECEIPT_CREATE_FORM,
  SHOW_PETTY_CASH_RECEIPT_DELETE_FORM,
  SHOW_PETTY_CASH_RECEIPT_EDIT_FORM
} from '../../actions/payroll/PettyCashReceiptActions';

const INIT_STATE = {
  pettyCashReceiptsByID:{},
  showPettyCashReceiptCreateForm:false,
  showPettyCashReceiptEditForm:false,
  pettyCashReceiptToEdit:false,
  showPettyCashReceiptDeleteForm:false,
  pettyCashReceiptToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PETTY_CASH_RECEIPT_SUCCESS:
      return {
        ...state,
        pettyCashReceiptsByID: action.payload
      };
    case SHOW_PETTY_CASH_RECEIPT_CREATE_FORM:
      return {
        ...state,
        showPettyCashReceiptCreateForm: true
      };
    case HIDE_PETTY_CASH_RECEIPT_CREATE_FORM:
      return {
        ...state,
        showPettyCashReceiptCreateForm: false
      };
    case SHOW_PETTY_CASH_RECEIPT_EDIT_FORM:
      return {
        ...state,
        showPettyCashReceiptEditForm: true,
        pettyCashReceiptToEdit: action.payload
      };
    case HIDE_PETTY_CASH_RECEIPT_EDIT_FORM:
      return {
        ...state,
        showPettyCashReceiptEditForm: false
      };
    case ADD_PETTY_CASH_RECEIPT_SUCCESS:
    case EDIT_PETTY_CASH_RECEIPT_SUCCESS:
      let pettyCashReceipt_id = action.payload.id;
      return {
        ...state,
        pettyCashReceiptsByID: {
          ...state.pettyCashReceiptsByID,
          [pettyCashReceipt_id]:action.payload
        }
      };
    case SHOW_PETTY_CASH_RECEIPT_DELETE_FORM:
      return {
        ...state,
        showPettyCashReceiptDeleteForm: true,
        pettyCashReceiptToDelete: action.payload
      };
    case HIDE_PETTY_CASH_RECEIPT_DELETE_FORM:
      return {
        ...state,
        showPettyCashReceiptDeleteForm: false
      };
    case DELETE_PETTY_CASH_RECEIPT_SUCCESS:
      let pettyCashReceipt = {...state.pettyCashReceiptsByID};
      delete pettyCashReceipt[action.payload.id];
      return {
        ...state,
        pettyCashReceiptsByID:pettyCashReceipt
      };
    default:
      return state;
  }
}
