export const FETCH_SALARYADVANCES = "FETCH_SALARYADVANCES";
export const FETCH_SALARYADVANCES_SUCCESS = "FETCH_SALARYADVANCES_SUCCESS";
export const FETCH_SAVINGS = "FETCH_SAVINGS";
export const FETCH_SAVINGS_SUCCESS = "FETCH_SAVINGS_SUCCESS";

export const SHOW_SALARYADVANCE_CREATE_FORM = 'SHOW_SALARYADVANCE_CREATE_FORM';
export const HIDE_SALARYADVANCE_CREATE_FORM = 'HIDE_SALARYADVANCE_CREATE_FORM';
export const ADD_SALARYADVANCE = 'ADD_SALARYADVANCE';
export const ADD_SALARYADVANCE_SUCCESS = 'ADD_SALARYADVANCE_SUCCESS';

export const SHOW_SALARYADVANCE_EDIT_FORM = 'SHOW_SALARYADVANCE_EDIT_FORM';
export const HIDE_SALARYADVANCE_EDIT_FORM = 'HIDE_SALARYADVANCE_EDIT_FORM';
export const EDIT_SALARYADVANCE = 'EDIT_SALARYADVANCE';
export const EDIT_SALARYADVANCE_SUCCESS = 'EDIT_SALARYADVANCE_SUCCESS';

export const SHOW_SALARYADVANCE_DELETE_FORM = 'SHOW_SALARYADVANCE_DELETE_FORM';
export const HIDE_SALARYADVANCE_DELETE_FORM = 'HIDE_SALARYADVANCE_DELETE_FORM';
export const DELETE_SALARYADVANCE = 'DELETE_SALARYADVANCE';
export const DELETE_SALARYADVANCE_SUCCESS = 'DELETE_SALARYADVANCE_SUCCESS';

export const UPLOAD_SALARY_ADVANCE = "UPLOAD_SALARY_ADVANCE";
export const UPLOAD_SALARY_ADVANCE_SUCCESS = "UPLOAD_SALARY_ADVANCE_SUCCESS";

export const PAY_SALARY_ADVANCE = "PAY_SALARY_ADVANCE";
export const MARK_SALARY_ADVANCE_AS_PAID = "MARK_SALARY_ADVANCE_AS_PAID";

export const CERTIFY_SALARY_ADVANCE_MULTIPLE = "CERTIFY_SALARY_ADVANCE_MULTIPLE";
export const APPROVE_SALARY_ADVANCE_MULTIPLE = "APPROVE_SALARY_ADVANCE_MULTIPLE";
export const REJECT_SALARY_ADVANCE_MULTIPLE = "REJECT_SALARY_ADVANCE_MULTIPLE";
export const DELETE_SALARY_ADVANCE_MULTIPLE = "DELETE_SALARY_ADVANCE_MULTIPLE";
export const DISAPPROVE_SALARY_ADVANCE_MULTIPLE = "DISAPPROVE_SALARY_ADVANCE_MULTIPLE";

export const EDIT_SALARY_ADVANCE_LIMIT = "EDIT_SALARY_ADVANCE_LIMIT";


export const rejectSalaryAdvanceMultipleGenerator = (data=[])=>{
    return {
        type: REJECT_SALARY_ADVANCE_MULTIPLE,
        payload:data
    }
};
export const deleteSalaryAdvanceMultipleGenerator = (data=[])=>{
    return {
        type: DELETE_SALARY_ADVANCE_MULTIPLE,
        payload:data
    }
};
export const disapproveSalaryAdvanceMultipleGenerator = (data=[])=>{
    return {
        type: DISAPPROVE_SALARY_ADVANCE_MULTIPLE,
        payload:data
    }
};

export const certifySalaryAdvanceMultiple = (data=[])=>{
    return {
        type:CERTIFY_SALARY_ADVANCE_MULTIPLE,
        payload:data
    }
};
export const approveSalaryAdvanceMultiple = (data=[])=>{
    return {
        type:APPROVE_SALARY_ADVANCE_MULTIPLE,
        payload:data
    }
};

export const fetchSalaryAdvancesGenerator = (data=[])=>{
    return {
        type:FETCH_SALARYADVANCES,
        payload:data
    };
};

export const fetchSalaryAdvancesSuccessGenerator = (dataById)=> {
    return {
        type:FETCH_SALARYADVANCES_SUCCESS,
        payload:dataById
    }
};
export const showSalaryAdvanceCreateFormGenerator = ()=>{
    return {
        type:SHOW_SALARYADVANCE_CREATE_FORM
    };
};
export const hideSalaryAdvanceCreateFormGenerator = ()=>{
    return {
        type:HIDE_SALARYADVANCE_CREATE_FORM
    };
};
export const addSalaryAdvanceGenerator = (data)=>{
    return {
        type: ADD_SALARYADVANCE,
        payload: data
    }
};

export const addSalaryAdvanceSuccessGenerator = (data)=>{
    return {
        type: ADD_SALARYADVANCE_SUCCESS,
        payload: data
    }
};
export const showSalaryAdvanceEditFormGenerator = (data)=>{
    return {
        type:SHOW_SALARYADVANCE_EDIT_FORM,
        payload:data
    };
};
export const hideSalaryAdvanceEditFormGenerator = ()=>{
    return {
        type:HIDE_SALARYADVANCE_EDIT_FORM
    };
};
export const editSalaryAdvanceGenerator = (data)=>{
    return {
        type: EDIT_SALARYADVANCE,
        payload: data
    }
};
export const editSalaryAdvanceSuccessGenerator = (data)=>{
    return {
        type: EDIT_SALARYADVANCE_SUCCESS,
        payload: data
    }
};
export const showSalaryAdvanceDeleteFormGenerator = (data)=>{
    return {
        type:SHOW_SALARYADVANCE_DELETE_FORM,
        payload:data
    };
};
export const hideSalaryAdvanceDeleteFormGenerator = ()=>{
    return {
        type:HIDE_SALARYADVANCE_DELETE_FORM
    };
};
export const deleteSalaryAdvanceGenerator = (data)=>{
    return {
        type: DELETE_SALARYADVANCE,
        payload: data
    }
};
export const deleteSalaryAdvanceSuccessGenerator = (data)=>{
    return {
        type: DELETE_SALARYADVANCE_SUCCESS,
        payload: data
    }
};
export const uploadSalaryAdvanceGenerator =(data=[])=>{
    return {
        type:UPLOAD_SALARY_ADVANCE,
        payload:data
    };
};
export const uploadSalaryAdvanceSuccessGenerator = (data)=>{
    return {
        type : UPLOAD_SALARY_ADVANCE_SUCCESS,
        payload:data
    }
};
export const paySalaryAdvanceGenerator = (data=[])=>{
    return {
        type: PAY_SALARY_ADVANCE,
        payload: data
    }
};
export const markSalaryAdvanceAsPaidGenerator = (data=[])=>{
    return {
        type: MARK_SALARY_ADVANCE_AS_PAID,
        payload: data
    }
};
export const editSalaryAdvanceLimitGenerator = (data={})=>{
    return {
        type: EDIT_SALARY_ADVANCE_LIMIT,
        payload: data
    }
};
