import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {all, call, fork, put,takeLatest} from "redux-saga/effects";
import { push } from 'connected-react-router';
import {
    DOWNLOAD_BULK_PAYSLIPS,
    FETCH_PAYSLIP,
    fetchPayslipSuccessGenerator,
    SEND_PAYSLIP,
    sendPayslipSuccessGenerator
} from "../../actions/payroll/PaySlipActions";

const  fetchPaySlipRequest = async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/hrm/payroll-batches/view/payslips',requestParameters)
        .then(response => response)
        .catch(error=>error.response);

const  sendPaySlipRequest = async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/hrm/send/payslips',requestParameters)
        .then(response => response)
        .catch(error=>error.response);

const  downloadBulkPaySlipRequest = async (requestParameters)=>
    await axios.post(API_ENDPOINT+'/hrm/download-bulk/payslips',requestParameters)
        .then(response => response)
        .catch(error=>error.response);

const  queueBulkPaySlipRequest = async (url)=>
    await axios.get(url)
        .then(response => response)
        .catch(error=>error.response);

function* downloadBulkPaySlips({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(downloadBulkPaySlipRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                if(response.data.count > 0) {
                    yield put(showMessageGenerator(response.data.count +" Payslips Queued for Processing ",'success'));
                    const url_=API_ENDPOINT + "/hrm/download-bulk/payslips/"+response.data.data+ "?token=" + Helpers.token()+ "&auth_company_id=" + Helpers.authSwitchedCompanyID();
                    const response_ = yield call(queueBulkPaySlipRequest,url_);
                    if (response_.status === 200 || response_.status === 201) {
                        if (response_.data.success) {

                            yield put(push('/hrm/queued-payslips-exports'));
                        }else{
                           yield put(showMessageGenerator(response_.data.message,'error'));
                        }
                    }
                }else{
                    yield put(showMessageGenerator("No Payslips Found for the selected batch",'error'));
                }
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* sendPaySlipReports({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(sendPaySlipRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let employee_payslip = response.data.data;
                if(employee_payslip.id){
                    yield put(sendPayslipSuccessGenerator(employee_payslip));
                }
                yield put(showMessageGenerator(response.data.message,'info'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchPaySlipReports({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchPaySlipRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let employee_payslip = response.data.data;
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(fetchPayslipSuccessGenerator(employee_payslip));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

export function* downloadBulkPaySlipDefault() {
    yield takeLatest(DOWNLOAD_BULK_PAYSLIPS,downloadBulkPaySlips);
}

export function* fetchPaySlipDefault() {
    yield takeLatest(FETCH_PAYSLIP,fetchPaySlipReports);
}

export function* sendPaySlipDefault() {
    yield takeLatest(SEND_PAYSLIP,sendPaySlipReports);
}

export default function* rootSaga() {
    yield all([
        fork(fetchPaySlipDefault),
        fork(sendPaySlipDefault),
        fork(downloadBulkPaySlipDefault)
    ]);
}
