import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import withRouter from 'react-router/es/withRouter';
import {Helpers} from '../../util/Helpers';
import { switchCompanyGenerator, switchCountrySuccessGenerator } from "../../appRedux/actions/common/CompanyActions";
import { fetchCountriesGenerator } from "../../appRedux/actions/GeneralActions";
import DataEntryModal from "../dataEntryModal";
import CreateCompanyForm from "../payroll/companies/createCompanyForm";
import { AUTH_SWITCHED_COMPANY_ID_KEY, SELECTED_COMPANY_KEY, SWITCHED_COUNTRY_KEY } from "../../constants/SessionKeys";

const countryCodes = ['KE', 'ET', 'UG', 'TZ', 'RW', 'BI', 'SS'];
const countryElements = [
    <div key={'country-1'}>
        <img src={require('../../assets/images/kenya-flag.png')} className='country-image'/>
        <span className='country-currency'>KE</span>
    </div>,
    <div key={'country-2'}>
        <img src={require('../../assets/images/ethiopia-flag.png')} className='country-image'/>
        <span className='country-currency'>ET</span>
    </div>,
    <div key={'country-3'}>
        <img src={require('../../assets/images/uganda-flag.png')} className='country-image'/>
        <span className='country-currency'>UG</span>
    </div>,
    <div key={'country-4'}>
        <img src={require('../../assets/images/tanzania-flag.png')} className='country-image'/>
        <span className='country-currency'>TZ</span>
    </div>,
    <div key={'country-5'}>
        <img src={require('../../assets/images/rwanda-flag.png')} className='country-image'/>
        <span className='country-currency'>RW</span>
    </div>,
    <div key={'country-6'}>
        <img src={require('../../assets/images/burundi-flag.png')} className='country-image'/>
        <span className='country-currency'>BI</span>
    </div>,
    <div key={'country-7'}>
        <img src={require('../../assets/images/south-sudan-flag.png')} className='country-image'/>
        <span className='country-currency'>SS</span>
    </div>,
];
class CountrySwitch extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            anchorEl: null,
            openModal: false,
            countryPendingSwitch:{}
        };
    }

    componentDidMount() {
        this.props.fetchCountriesGenerator();
    }

    handleClickListItem = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuItemClick = (event, index) => {
        const {companiesByID} = this.props;
        let countries = Helpers.countries();
        if(!countries){
            return [];
        }
        this.handleClose();
        let countryCode = countryCodes[index];
        let country = Object.values(countries).whereFirst(record=>record.alpha_two_code === countryCode);
        if(!country && countryCode === 'SS'){//in case old Sudan still cached instead of South Sudan
            country = Object.values(countries).whereFirst(record=>record.alpha_two_code === 'SD');
        }

        let switchedCountryCompanies = Object.values(companiesByID).where(record=>Number(record.country_id) === Number(country.id));
        if(switchedCountryCompanies.length > 0){
            let company = {...switchedCountryCompanies[0]};
            company.switching_country = true;
            company.message = 'Switched to '+country.name+' successfully.';
            this.props.switchCompanyGenerator(company);
            this.props.switchCountrySuccessGenerator(country);
            Helpers.saveItem(SELECTED_COMPANY_KEY, company.id);//for branch listing on branch page
        }else{
            this.setState({ openModal: true, countryPendingSwitch:country });
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    closeModal = (dataSubmitted = false) => {
        if(dataSubmitted){
            this.setState({ openModal: false });
        }
    };


    render() {
        const { anchorEl, openModal, countryPendingSwitch } = this.state;
        const {switchedCountry} = this.props;
        if(!switchedCountry || !switchedCountry.hasOwnProperty('id')){
            return null;
        }
        let selectedIndex = countryCodes.indexOf(switchedCountry.alpha_two_code);
        if(selectedIndex < 0){
            return null;
        }
        return (
          <div>
              <Button onClick={this.handleClickListItem}>{countryElements[selectedIndex]}<i className="zmdi zmdi-chevron-down zmdi-hc-fw" /></Button>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
              >
                  {countryElements.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={event => this.handleMenuItemClick(event, index)}
                    >
                        {option}
                    </MenuItem>
                  ))}
              </Menu>
              <DataEntryModal
                key={"DataEntryModal"}
                closeModal={this.closeModal}
                openModal={openModal}
                modalTitle={'Create Company in '+(countryPendingSwitch?countryPendingSwitch.name:'')}
                extraInfo={''}
                modalData={CreateCompanyForm}
                populatedInputFields={{action:'create', country_pending_switch:countryPendingSwitch, country_id:countryPendingSwitch.id}}
                submitButton={'Submit'}
                showSubmit={true}
              />
          </div>
        );
    }
}

const mapStateToProps = ({ company}) => {
    let {companiesByID, switchedCountry} = company;
    const branch = Helpers.currentSwitchedBranch(companiesByID);
    const currencyCode = branch.currency_code||'KSH';
    const countryName = branch.country_name||'Kenya';
    if(!switchedCountry || !switchedCountry.hasOwnProperty('id')){
        let country = branch.country;
        if(country){
            Helpers.saveItem(SWITCHED_COUNTRY_KEY, country);
            switchedCountry = country;
        }else{
            switchedCountry = Helpers.authSwitchedCountry();
        }
    }
    return { companiesByID, currencyCode,countryName, switchedCountry};
};

export default withRouter(connect(mapStateToProps, {switchCountrySuccessGenerator, fetchCountriesGenerator, switchCompanyGenerator})(CountrySwitch));
