import {
  FETCH_ADMIN_USERS_SUCCESS,
  ADD_ADMIN_USERS_SUCCESS, DELETE_ADMIN_USERS_SUCCESS,
  UPDATE_ADMIN_USERS_SUCCESS, FETCH_ADMIN_PAYOUT_TRAIL_SUCCESS

} from "../../actions/admin/AdminUsersActions";

const INIT_STATE = {
   adminUsersByID:{},
  adminPayoutTrail:{}
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {

        case FETCH_ADMIN_USERS_SUCCESS:
            return {
                ...state,
               adminUsersByID: action.payload
            };
        case ADD_ADMIN_USERS_SUCCESS:
        case UPDATE_ADMIN_USERS_SUCCESS:
            let adminUser = {...state.adminUsersByID};
           adminUser[action.payload.id]=action.payload;
            return {
                ...state,
               adminUsersByID:adminUser
            };

        case DELETE_ADMIN_USERS_SUCCESS:
            let adminUsersByID = {...state.adminUsersByID};
            delete adminUsersByID[action.payload.id];
            return {
                ...state,
               adminUsersByID:adminUsersByID
            };
      case FETCH_ADMIN_PAYOUT_TRAIL_SUCCESS:
        return {
          ...state,
          adminPayoutTrail: action.payload
        };
        default:
            return state;
    }
}
