import {all, call, fork, put, takeLatest} from "redux-saga/effects";

import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {
    deleteCompanyRateSuccessGenerator,
    EDIT_COMPANY_RATE, editCompanyRateSuccessGenerator,
    FETCH_COMPANY_RATE,
} from "../../actions/payroll/CompanyPaymentRateActions";
import {
    ADD_COMPANY_RATE,
    fetchCompanyRateSuccessGenerator,
    addCompanyRateSuccessGenerator,
    DELETE_COMPANY_RATE
} from "../../actions/payroll/CompanyPaymentRateActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const addCompanyRateRequest = async (requestParameters) =>
    await axios.post(API_ENDPOINT+"/hrm/company-payment-rate",requestParameters)
    .then(response => response)
    .catch(error => error.response);
const fetchCompanyRateRequest = async (requestParameters)=>
    await axios.get(API_ENDPOINT+"/hrm/company-payment-rate",{params:requestParameters})
        .then(response => response)
        .catch(error=>error.response);
const updateCompanyRateRequest = async (requestParameters)=>
    await axios.put(API_ENDPOINT+"/hrm/company-payment-rate/"+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.message);
const deleteCompanyRateRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/company-payment-rate/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

function* deleteCompanyPaymentRate({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteCompanyRateRequest,requestParameters);
        if(response.status === 201 || response.status===200){
            if(response.data.success){
                yield put(deleteCompanyRateSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            }else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* updateCompanyPaymentRate({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(updateCompanyRateRequest,requestParameters);
        if(response.status ===200 || response.status===201){
            if(response.data.success){
                yield put(editCompanyRateSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            }else{
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else{
            yield put(showMessageGenerator(response.data.message,'error'));
        }
    }catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addCompanyPaymentRate({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters ={...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addCompanyRateRequest,requestParameters);
        if(response.status === 200 || response.status ===201){
            if(response.data.success){
                let company_rates = response.data.data;
                yield put(addCompanyRateSuccessGenerator(company_rates));
                yield put(showMessageGenerator(response.data.message,'success'));
            }else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchCompanyRates({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParams = {...payload,token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchCompanyRateRequest,requestParams);
       if (response.status ===200 || response.status ===201 ) {
            if(response.data.success){
                //yield put(showMessageGenerator(response.data.message));
               yield put(fetchCompanyRateSuccessGenerator(response.data.data));
            }else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
       }else if(response.status ===401){
           Helpers.clearAuthData();
           yield put(userSignOutSuccessGenerator());
       }else{
           yield put(showMessageGenerator(response.data.message,'error'));
       }
    }catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* addCompanyPaymentRateDefault(){
    yield(takeLatest(ADD_COMPANY_RATE,addCompanyPaymentRate));
}
export function* editCompanyPaymentRateDefault(){
    yield(takeLatest(EDIT_COMPANY_RATE,updateCompanyPaymentRate));
}
export function* deleteCompanyPaymentRateDefault(){
    yield(takeLatest(DELETE_COMPANY_RATE,deleteCompanyPaymentRate));
}
export function* fetchCompanyPaymentRateDefault(){
    yield(takeLatest(FETCH_COMPANY_RATE,fetchCompanyRates));
}
export default function* rootSaga() {
    yield all([
        fork(addCompanyPaymentRateDefault),
        fork(fetchCompanyPaymentRateDefault),
        fork(fetchCompanyPaymentRateDefault),
        fork(editCompanyPaymentRateDefault),
        fork(deleteCompanyPaymentRateDefault),
    ]);
}
