import {
  ADD_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
  HIDE_DOCUMENT_CREATE_FORM,
  HIDE_DOCUMENT_DELETE_FORM,
  HIDE_DOCUMENT_EDIT_FORM,
  SHOW_DOCUMENT_CREATE_FORM,
  SHOW_DOCUMENT_DELETE_FORM,
  SHOW_DOCUMENT_EDIT_FORM
} from '../../actions/payroll/DocumentActions';

const INIT_STATE = {
  documentsByID:{},
  showDocumentCreateForm:false,
  showDocumentEditForm:false,
  documentToEdit:false,
  showDocumentDeleteForm:false,
  documentToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsByID: action.payload
      };
    case SHOW_DOCUMENT_CREATE_FORM:
      return {
        ...state,
        showDocumentCreateForm: true
      };
    case HIDE_DOCUMENT_CREATE_FORM:
      return {
        ...state,
        showDocumentCreateForm: false
      };
    case SHOW_DOCUMENT_EDIT_FORM:
      return {
        ...state,
        showDocumentEditForm: true,
        documentToEdit: action.payload
      };
    case HIDE_DOCUMENT_EDIT_FORM:
      return {
        ...state,
        showDocumentEditForm: false
      };
    case ADD_DOCUMENT_SUCCESS:
    case EDIT_DOCUMENT_SUCCESS:
      let document_id = action.payload.id;
      return {
        ...state,
        documentsByID: {
          ...state.documentsByID,
          [document_id]:action.payload
        }
      };
    case SHOW_DOCUMENT_DELETE_FORM:
      return {
        ...state,
        showDocumentDeleteForm: true,
        documentToDelete: action.payload
      };
    case HIDE_DOCUMENT_DELETE_FORM:
      return {
        ...state,
        showDocumentDeleteForm: false
      };
    case DELETE_DOCUMENT_SUCCESS:
      let documentsByID = {...state.documentsByID};
      delete documentsByID[action.payload.id];
      return {
        ...state,
        documentsByID:documentsByID
      };
    default:
      return state;
  }
}
