import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { FETCH_EMPLOYEES_ADMIN, fetchEmployeesAdminSuccessGenerator } from "../../actions/payroll/EmployeeActions";
import { userSignOutGenerator } from "../../actions/AuthActions";

const fetchEmployeesRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+"/hrm/employees",{params:requestParameters})
    .then(response => response)
    .catch(error=>error.response);

function* fetchEmployees({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try{
    let requestParams ={...payload,token:Helpers.token(),auth_company_id:payload.company_id};
    let response =yield(call(fetchEmployeesRequest,requestParams));

    if(response.status === 200 || response.status ===201){
      if(response.data.success){
        yield put(fetchEmployeesAdminSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      }else{
        yield put(showMessageGenerator(response.data.message,'error'));
      }

    }else if(response.status ===401){
      Helpers.clearAuthData();
      yield put(userSignOutGenerator());
    }
    else{
      yield put(showMessageGenerator(response.data.message,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));

}

export function* fetchEmployeesDefault(){
  yield(takeLatest(FETCH_EMPLOYEES_ADMIN,fetchEmployees));
}

export default function* rootSaga() {
  yield all([
    fork(fetchEmployeesDefault)
  ]);
}