import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import { confirmPaymentGenerator, sendRequestGenerator } from "../../../appRedux/actions/payouts/RechargePayoutActions";
import { Helpers } from "../../../util/Helpers";
import BackGroundLoader from "../../../components/common/BackGroundLoader";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';

const styles = theme => ({
  card: {
    margin: 10,
    padding: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class Equitel extends React.Component {
  state = {
    mobile_equitel: this.props.mobile_equitel || this.props.mobile_mpesa || '',
    amount_equitel: this.props.amount_equitel || this.props.amount_mpesa ||  '',
    type:"equitel"  ,
    page:this.props.page,
    branch_id: this.props.branch_id || this.props.head_office_id,
    head_office_id: this.props.head_office_id
  };

  updateState = (e) => {
    const { id, name, value } = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState });
  };

  proceed = () => {
    this.setState({
       page:null
    }) ;
    this.props.sendRequestGenerator(this.state);
  };

  confirm =() => {
    const data = {...this.props};
    data.account = this.props.account;
    data.mobile = this.props.mobile;
    data.checkout = this.props.checkout;
    this.props.confirmPaymentGenerator(data);
  };

  updateState1 = (name, e) => {
    const newState = Object.assign({}, this.state);
    newState[name] = e.value;
    this.setState({ ...newState });
  };

  render() {
    const { classes,companiesByID } = this.props;
    const {mobile_equitel,amount_equitel,head_office_id }  = this.state;
    const branches = Helpers.companyBranches(companiesByID, head_office_id);
    return (
      <div className={classes.card}>
        {
         Helpers.isNull(this.state.page) && ( Helpers.loading(this.props)
          && (
            <BackGroundLoader />
          ))
        }
        <br/>
        {this.props.path === '' || this.props.type !== 'equitel'? (
          <form className="profile-container">
            <div className="row">
              <div className="col-12">
                <FormControl fullWidth placeholder="Select Type" margin="normal" required>
                  <InputLabel htmlFor="branch_id" className="select-label">
                    Select Branch
                  </InputLabel>
                  <Select
                    onChange={this.updateState1.bind(this,'branch_id')}
                    name="branch_id"
                    id="branch_id"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    options={Helpers.renderBranchMenuItems(branches)}
                    isSearchable={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      color: 'green',
                      colors: {
                        ...theme.colors,
                        primary: '#3CB534',
                        primary25: '#E2F4E0',
                        primary50: '#E2F4E0'
                      }
                    })}
                  />
                </FormControl>
              </div>
              <div className="col-12">
                <TextField
                  required
                  id="mobile_equitel"
                  label="Equitel Mobile No"
                  value={mobile_equitel}
                  fullWidth
                  placeholder="0763000000"
                  margin="normal"
                  onChange={this.updateState}
                />
                {/* <div className="text-center">
              <img src={require('assets/images/equity-logo.jpg')} style={{ width: 150, height: 'auto', marginBottom: 30 }} />
              <Typography variant="h6">Coming Soon</Typography>
            </div> */}
              </div>
              <div className="col-12">
                <TextField
                  required
                  id="amount_equitel"
                  label="Amount"
                  value={amount_equitel}
                  fullWidth
                  placeholder="Amount"
                  margin="normal"
                  onChange={this.updateState}
                />
              </div>
              <div className="col-12">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{textDecoration: 'none', marginTop:10}}
                  onClick={this.proceed}>
                  Proceed with Payment
                </Button>
                {/*<Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{textDecoration: 'none', marginTop:10, marginLeft: 50}}
                  onClick={() => Helpers.choosePlanRedirect()}>
                  Change current plan
                </Button>*/}


              </div>
            </div>
          </form>
        ):
          (
            <form>
              <p>
                A payment request Account Number {' '}
                {this.props.account}
                {' '}
                has been sent to your mobile . Please complete the request and confirm the payment by clicking on the Confirm payment button below.
              </p>
              <div className={classes.title}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{ textDecoration: 'none',marginRight: 20, marginTop: 10 }}
                  onClick={this.confirm}
                >
                  Confirm
                </Button>
                {'    '}
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ textDecoration: 'none',marginRight: 20, marginTop: 10 }}
                  onClick={this.proceed}>
                  Retry
                </Button>
              </div>
            </form>
          )
        }
      </div>
    );
  }
}
const mapStateToProps = ({recharge, general,company}) => {
  const {companiesByID} = company;
  let head_office_id = Helpers.authCurrentHeadOfficeID()
  const {
    mobile, account, path, checkout, type
  } = recharge;
  const {appLoading} = general;
  return {
    mobile, account, path, checkout, type, appLoading,head_office_id,companiesByID
  };
};
export default withStyles(styles)(connect(mapStateToProps, {sendRequestGenerator, confirmPaymentGenerator})(Equitel));
