import CompanySagas from './CompanySaga';
import DepartmentSaga from "./DepartmentSaga";
import DesignationSaga from "./DesignationSaga";
import RoleSagas from "./RoleSaga"
import PensionRateSaga from "./PensionRateSaga";
import UserSagas from "./UserSaga";
import DashboardsSaga from "./DashboardsSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import WithholdingTaxSaga from "./WithholdingTaxSaga";
const Common =[
    CompanySagas(),
    DepartmentSaga(),
    DesignationSaga(),
    RoleSagas(),
    PensionRateSaga(),
    UserSagas(),
    DashboardsSaga(),
    SubscriptionSaga(),
    WithholdingTaxSaga()
];
export default Common;
