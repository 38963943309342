export const ATTENDANCE_MAPPING = "ATTENDANCE_MAPPING";
export const FETCH_ATTENDANCE_MAPPING_SUCCESS = "FETCH_ATTENDANCE_MAPPING_SUCCESS";

export const fetchAttendanceMappingGenerator=(data=[])=>{
  return{
    type:ATTENDANCE_MAPPING,
    payload:data
  }
};
export const fetchAttendanceMappingSuccessGenerator=(data)=>{
  return{
    type:FETCH_ATTENDANCE_MAPPING_SUCCESS,
    payload:data
  }
};