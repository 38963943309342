export const FETCH_BANK_TRANSFER = "FETCH_BANK_TRANSFER"
export const FETCH_BANK_TRANSFER_SUCCESS = "FETCH_BANK_TRANSFER_SUCCESS"

export const APPROVE_REJECT_BANK_TRANSFER = "APPROVE_REJECT_BANK_TRANSFER"
export const APPROVE_REJECT_BANK_TRANSFER_SUCCESS = "APPROVE_REJECT_BANK_TRANSFER_SUCCESS"

export const approveRejectBankTransferGenerator = (payload)=>{
  return{
    type:APPROVE_REJECT_BANK_TRANSFER,
    payload
  }
};
export const approveRejectBankTransferSuccessGenerator = (payload)=>{
  return{
    type:APPROVE_REJECT_BANK_TRANSFER_SUCCESS,
    payload
  }
}

export const fetchBankTransferGenerator =(payload)=>{
  return {
    type:FETCH_BANK_TRANSFER,
    payload
  }
};
export const fetchBankTransferSuccessGenerator =(payload)=>{
  return {
    type:FETCH_BANK_TRANSFER_SUCCESS,
    payload
  }
};