import {
  FETCH_PRODUCTS_DELIVERED_SUCCESS,
  FETCH_PRODUCTS_SUCCESS
} from "../../actions/payroll/ProductActions";

const INIT_STATE = {
  products:{},
  productsDelivered:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload
      };
    case FETCH_PRODUCTS_DELIVERED_SUCCESS:
      return {
        ...state,
        productsDelivered: action.payload
      };

    default:
      return state;
  }
}
