import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  forgotPasswordGenerator,
  useRestCodeGenerator,
  userSignInGenerator
} from "../appRedux/actions/AuthActions";
import { Helpers } from '../util/Helpers';
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import BackGroundLoader from "../components/common/BackGroundLoader";
import {withStyles} from '@material-ui/core/styles';

const useStyles = theme => ({
  buttonProgress: {
    color: '#fff',
    marginRight: 5,
  },
  eye: {
    cursor: 'pointer',
  },

});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      password_confirmation:'',
      token:'',
      errorMessage:'',
      hasError:false,
      passwordIsMasked: true,
      buttonLoading:false

    };
  }
  validatePassword=(event)=>{
    const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    const number = /\d/g;
    const validLength = /.{6}/.test(event.target.value);
    const hasCaps = /[A-Z]/.test(event.target.value);
    const hasSmall = /[a-z]/.test(event.target.value);
    let hasError =false;
    let errorMessage ="";
    if (!format.test(event.target.value)) {
      hasError = true;
      errorMessage = "Include a special character";
    }else if (!number.test(event.target.value)) {
      hasError = true;
      errorMessage = "Password must have at least one number";
    }else if (!hasCaps){
      hasError = true;
      errorMessage = "Password must have at least one capital letter";
    }else if (!hasSmall){
      hasError = true;
      errorMessage = "Password must have a small letter";
    }else if (!validLength){
      hasError = true;
      errorMessage = "Password must be at least 6 characters";
    }
    this.setState({password:event.target.value,hasError:hasError,errorMessage:errorMessage});
  };
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.location.state.user_data){
      this.setState({email:nextProps.location.state.user_data.email});
    }
  }
  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  resendCode =() => {
    let {email}=this.state;
    this.props.forgotPasswordGenerator({
      email
    });
  };

  recoverAccount = () => {
    let {password,token,password_confirmation} = this.state;
    let {user_id} = this.props;
    this.props.useRestCodeGenerator({
    password,
    token,
    password_confirmation,
    user_id
  });
    this.setState({   buttonLoading:true});
};

  render() {
    const {
      password,
      password_confirmation,
      token,
      hasError,
      errorMessage,
      email,
      passwordIsMasked,
      buttonLoading
    } = this.state;

    const {
      user_id
    } =this.props;

    const { classes } =this.props;

    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          {
            Helpers.loading(this.props)
            && (
                <BackGroundLoader />
            )
          }
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="">
              <img src={require('assets/images/logo.png')} alt="" title="" style={{ height:"40px"}} />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-1 text-center text-muted">
              <p><IntlMessages id="appModule.resetPasswordDesc" /></p>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                      type="text"
                      label={<IntlMessages id="appModule.confirmCode" />}
                      placeholder="Enter the Sent Code"
                      fullWidth
                      required
                      onChange={event => this.setState({ token: event.target.value })}
                      margin="normal"
                      className="mt-0 mb-3"
                  />
                  <TextField
                      type={passwordIsMasked ? 'password' : 'text'}
                    label={<IntlMessages id="appModule.password" />}
                    placeholder="Password"
                    fullWidth
                    required
                    error={hasError}
                    helperText={errorMessage}
                    onChange={this.validatePassword}
                    margin="normal"
                    className="mt-0 mb-3"
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.togglePasswordMask}
                            >
                              {this.state.passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                      type={passwordIsMasked ? 'password' : 'text'}
                    label={<IntlMessages id="appModule.confirmPassword" />}
                    placeholder="Confirm Password"
                    fullWidth
                    required
                    error={password_confirmation !== null && password_confirmation !== '' && password != password_confirmation}
                    helperText={(password_confirmation !== null && password_confirmation !== '' && password != password_confirmation) ? 'Password do not match.' : null}
                    onChange={event => this.setState({ password_confirmation: event.target.value })}
                    margin="normal"
                    className="mt-0 mb-3"
                  />
                  <div className=" d-flex align-items-center justify-content-between">
                    <Button
                      onClick={this.recoverAccount}
                      variant="contained"
                      color="primary"
                      size="small"
                      //disabled={buttonLoading&&Helpers.loading(this.props)}
                    >
                      {/*{(Helpers.loading(this.props)&&buttonLoading)&&<CircularProgress size={24} className={classes.buttonProgress} />}*/}
                      <IntlMessages id="appModule.resetPassword" />
                    </Button>
                    <Button
                      onClick={this.resendCode}
                      variant="text"
                      color="primary"
                      size="small"
                      style={{ marginRight: -10 }}>
                      <IntlMessages id="appModule.resendPin" />
                    </Button>
                  </div>
                  <div className="mb-3 d-flex align-items-center justify-content-end">
                    <Link to="/signin">
                      <IntlMessages id="appModule.rememberPassword" />
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth, general}) => {
  const {authUser,user_id} = auth;
  let {appLoading} = general;
  return {
    user_id,
    authUser,
    appLoading
  };
};

export default withStyles(useStyles)(connect(mapStateToProps, {
  userSignInGenerator,
  useRestCodeGenerator,
  forgotPasswordGenerator
})(ForgotPassword));
