import {
  CREATE_INSURANCE_PREMIUM_SUCCESS, DELETE_INSURANCE_PREMIUM_SUCCESS, EDIT_INSURANCE_PREMIUM_SUCCESS, FETCH_INSURANCE_PREMIUM_SUCCESS
} from "../../actions/payroll/InsurancePremiumActions";

const INIT_STATE ={
  InsurancePremiumBYID:{}
}
export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_INSURANCE_PREMIUM_SUCCESS:
      return {
        ...state,
        InsurancePremiumBYID: action.payload,
      };
    case CREATE_INSURANCE_PREMIUM_SUCCESS:
    case EDIT_INSURANCE_PREMIUM_SUCCESS:
      let rate_id = action.payload.id;
      return {
        ...state,
        InsurancePremiumBYID: {
          ...state.InsurancePremiumByID,
          [rate_id]:action.payload
        }
      };
    case DELETE_INSURANCE_PREMIUM_SUCCESS:
      let InsurancePremiumBYID = {...state.InsurancePremiumBYID};
      delete InsurancePremiumBYID[action.payload.id];
      return {
        ...state,
        InsurancePremiumByID:InsurancePremiumBYID
      };
    default: return {...state}
  }
}
