///create
export const ADD_LOANS = 'ADD_LOANS';
export const ADD_LOANS_SUCCESS = 'ADD_LOANS_SUCCESS';
export const SHOW_CREATE_LOANS_FORM = 'SHOW_CREATE_LOANS_FORM';
export const HIDE_CREATE_LOANS_FORM = 'HIDE_CREATE_LOANS_FORM';
/// fetch
export const FETCH_LOANS = 'FETCH_LOANS';
export const FETCH_LOANS_SUCCESS = 'FETCH_LOANS_SUCCESS';
////delete
export const DELETE_LOANS = 'DELETE_LOANS';
export const DELETE_LOANS_SUCCESS ='DELETE_LOANS_SUCCESS';
export const SHOW_DELETE_LOANS_MODAL = 'SHOW_DELETE_LOANS_MODAL';
export const HIDE_DELETE_LOANS_MODAL = 'HIDE_DELETE_LOANS_MODAL';
////edit
export const EDIT_LOANS = 'EDIT_LOANS';
export const EDIT_LOANS_SUCCESS = 'EDIT_LOANS_SUCCESS';
export const SHOW_EDIT_LOANS_FORM = 'SHOW_EDIT_LOANS_FORM';
export const HIDE_EDIT_LOANS_FORM = 'HIDE_EDIT_LOANS_FORM';
/////Activate and deactivate
export const SHOW_DEACTIVATE_LOAN_FORM ='SHOW_DEACTIVATE_LOAN_FORM';
export const HIDE_DEACTIVATE_LOAN_FORM ='HIDE_DEACTIVATE_LOAN_FORM';
export const SHOW_ACTIVATE_LOAN_FORM ='SHOW_ACTIVATE_LOAN_FORM';
export const HIDE_ACTIVATE_LOAN_FORM ='HIDE_ACTIVATE_LOAN_FORM';
///upload bulk loans
export const UPLOAD_BULK_LOANS ="UPLOAD_BULK_LOANS";
export const UPLOAD_BULK_LOANS_SUCCESS="UPLOAD_BULK_LOANS_SUCCESS";

export const ACTIVATE_BULK_LOANS = "ACTIVATE_BULK_LOANS";
export const DEACTIVATE_BULK_LOANS = "DEACTIVATE_BULK_LOANS";

export const GET_MY_COMPANY_LOAN ="GET_MY_COMPANY_LOAN";
export const GET_MY_COMPANY_LOAN_SUCCESS ="GET_MY_COMPANY_LOAN_SUCCESS";

export const CREATE_MY_COMPANY_LOAN = "CREATE_MY_COMPANY_LOAN";
export const CREATE_MY_COMPANY_LOAN_SUCCESS = "CREATE_MY_COMPANY_LOAN_SUCCESS";

export const EDIT_MY_COMPANY_LOAN = 'EDIT_MY_COMPANY_LOAN';
export const EDIT_MY_COMPANY_LOAN_SUCCESS = 'EDIT_MY_COMPANY_LOAN_SUCCESS';

export const DELETE_MY_COMPANY_LOAN = 'DELETE_MY_COMPANY_LOAN';
export const DELETE_MY_COMPANY_LOAN_SUCCESS = 'DELETE_MY_COMPANY_LOAN_SUCCESS';

export const CERTIFY_EMPLOYEE_LOAN = 'CERTIFY_EMPLOYEE_LOAN';
export const MARK_AS_PAID_EMPLOYEE_LOAN = 'MARK_AS_PAID_EMPLOYEE_LOAN';
export const PAY_EMPLOYEE_LOAN = 'PAY_EMPLOYEE_LOAN';

export const certifyEmployeeLoanGenerator = (payload=[])=>{
  return {
    type:CERTIFY_EMPLOYEE_LOAN,
    payload
  }
};
export const markAsPaidEmployeeLoanGenerator = (payload=[])=>{
  return {
    type:MARK_AS_PAID_EMPLOYEE_LOAN,
    payload
  }
};
export const payEmployeeLoanGenerator = (payload=[])=>{
  return {
    type:PAY_EMPLOYEE_LOAN,
    payload
  }
};
export const deleteMyCompanyLoanGenerator = (payload=[])=>{
  return {
    type:DELETE_MY_COMPANY_LOAN,
    payload
  }
};
export const deleteMyCompanyLoanSuccessGenerator = (payload=[])=>{
  return {
    type:DELETE_MY_COMPANY_LOAN_SUCCESS,
    payload
  }
};
export const editMyCompanyLoanGenerator = (payload=[])=>{
  return {
    type:EDIT_MY_COMPANY_LOAN,
    payload
  }
};
export const editMyCompanyLoanSuccessGenerator = (payload=[])=>{
  return {
    type:EDIT_MY_COMPANY_LOAN_SUCCESS,
    payload
  }
};
export const createMyCompanyLoanGenerator =(payload=[])=>{
  return{
    type:CREATE_MY_COMPANY_LOAN,
    payload
  }
};
export const createMyCompanyLoanSuccessGenerator =(payload=[])=>{
  return{
    type:CREATE_MY_COMPANY_LOAN_SUCCESS,
    payload
  }
};
export const getMyCompanyLoanGenerator =(payload=[])=>{
  return{
    type:GET_MY_COMPANY_LOAN,
    payload
  }
};
export const getMyCompanyLoanSuccessGenerator = (payload=[])=>{
  return{
    type:GET_MY_COMPANY_LOAN_SUCCESS,
    payload
  }
};
export const activateBulkLoansGenerator = (data=[])=>{
  return {
    type:ACTIVATE_BULK_LOANS,
    payload:data
  }
};
export const deactivateBulkLoansGenerator = (data)=>{
  return {
    type:DEACTIVATE_BULK_LOANS,
    payload:data
  }
};
////BULK
export const uploadBulkLoanGenerator=(data)=>{
  return {
    type:UPLOAD_BULK_LOANS,
    payload:data
  }
};
export const uploadBulkLoanSuccessGenerator=(data)=>{
  return {
    type:UPLOAD_BULK_LOANS_SUCCESS,
    payload:data
  }
};
/////CREATE
export const addLoanGenerator = (data)=>{
  return {
    type: ADD_LOANS,
    payload: data
  }
};
export const showLoanCreateFormGenerator = (data)=>{
  return {
    type:SHOW_CREATE_LOANS_FORM,
    payload:data
  }
};
export const hideLoanCreateFormGenerator = (data)=>{
  return {
    type:HIDE_CREATE_LOANS_FORM,
    payload:data
  }
};
export const addLoanSuccessGenerator =(data)=>{
  return {
    type:ADD_LOANS_SUCCESS,
    payload:data
  }
};
//////FETCH
export const fetchLoanGenerator=(data=[])=>{
  return {
    type:FETCH_LOANS,
    payload:data
  }
};

export const fetchLoanSuccessGenerator =(data)=>{
  return {
    type:FETCH_LOANS_SUCCESS,
    payload:data
  }
};


///EDIT
export const editLoanGenerator = (data)=>{
  return {
    type: EDIT_LOANS,
    payload: data
  }
};

export const editLoanSuccessGenerator=(data)=>{
  return {
    type:EDIT_LOANS_SUCCESS,
    payload:data
  }
};
export const showLoanEditFormGenerator = (data)=>{
  return {
    type:SHOW_EDIT_LOANS_FORM,
    payload:data
  }
};
export const hideLoanEditFormGenerator = (data)=>{
  return {
    type:HIDE_EDIT_LOANS_FORM,
    payload:data
  }
};
/////DELETE
export const deletedLoanGenerator=(data)=>{
  return {
    type:DELETE_LOANS,
    payload:data
  }
};

export const deleteLoanSuccessGenerator = (data)=>{
  return {
    type:DELETE_LOANS_SUCCESS,
    payload:data
  }
};
export const showLoanDeleteModalGenerator = (data)=>{
  return {
    type:SHOW_DELETE_LOANS_MODAL,
    payload:data
  }
};
export const hideLoanDeleteModalGenerator = (data)=>{
  return {
    type:HIDE_DELETE_LOANS_MODAL,
    payload:data
  }
};

export const showDeactivateLoanModalGenerator = (data) =>{
  return {
    type:SHOW_DEACTIVATE_LOAN_FORM,
    payload:data
  }
};
export const hideDeactivateLoanModalGenerator = (data) =>{
  return {
    type:HIDE_DEACTIVATE_LOAN_FORM,
    payload:data
  }
};
export const showActivateLoanModalGenerator = (data) =>{
  return {
    type:SHOW_ACTIVATE_LOAN_FORM,
    payload:data
  }
};
export const hideActivateLoanModalGenerator = (data) =>{
  return {
    type:HIDE_ACTIVATE_LOAN_FORM,
    payload:data
  }
};




