import {
  ADD_ADMIN_HOLIDAY_SUCCESS,
  DELETE_ADMIN_HOLIDAY_SUCCESS,
  EDIT_ADMIN_HOLIDAY_SUCCESS,
  FETCH_ADMIN_HOLIDAY_SUCCESS,
  HIDE_CREATE_ADMIN_HOLIDAY_FORM,
  HIDE_DELETE_ADMIN_HOLIDAY_MODAL,
  HIDE_EDIT_ADMIN_HOLIDAY_FORM,
  SHOW_CREATE_ADMIN_HOLIDAY_FORM,
  SHOW_DELETE_ADMIN_HOLIDAY_MODAL,
  SHOW_EDIT_ADMIN_HOLIDAY_FORM
} from '../../actions/admin/AdminHolidayAction';

const INIT_STATE = {
  adminHolidaysByID:[],
  showHolidayCreateForm:false,
  holidayToEdit:[],
  showHolidayEditForm:false,
  showDeleteHolidayModal:false
};
export default (state = INIT_STATE, action)=>{
  let adminHolidays;
  switch (action.type) {
    case ADD_ADMIN_HOLIDAY_SUCCESS:
    case EDIT_ADMIN_HOLIDAY_SUCCESS:
      adminHolidays = {...state.adminHolidaysByID};
      adminHolidays[action.payload.id]=action.payload;
      return {
        ...state,
        adminHolidaysByID:adminHolidays
      };
    case FETCH_ADMIN_HOLIDAY_SUCCESS:
      return {
        ...state,
        adminHolidaysByID:action.payload
      };
    case SHOW_CREATE_ADMIN_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayCreateForm:true
      };
      case HIDE_CREATE_ADMIN_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayCreateForm:false
      };
      case SHOW_EDIT_ADMIN_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayEditForm:true,
        holidayToEdit:action.payload
      };
      case HIDE_EDIT_ADMIN_HOLIDAY_FORM:
      return {
        ...state,
        showHolidayEditForm:false
      };
    case SHOW_DELETE_ADMIN_HOLIDAY_MODAL:
      return {
        ...state,
        showDeleteHolidayModal:true,
        holidayToEdit:action.payload
      };
      case HIDE_DELETE_ADMIN_HOLIDAY_MODAL:
      return {
        ...state,
        showDeleteHolidayModal:false
      };
    case DELETE_ADMIN_HOLIDAY_SUCCESS:
      adminHolidays = {...state.adminHolidaysByID};
      delete adminHolidays[action.payload.id];
      return {
        ...state,
        adminHolidaysByID:adminHolidays
      };
    default:
      return state;
  }
}
