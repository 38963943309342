import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  ADD_DOCUMENT_CATEGORY,
  addDocumentCategorySuccessGenerator, DELETE_DOCUMENT_CATEGORY,
  deleteDocumentCategorySuccessGenerator, EDIT_DOCUMENT_CATEGORY,
  editDocumentCategorySuccessGenerator,
  FETCH_DOCUMENT_CATEGORY,
  fetchDocumentCategorySuccessGenerator
} from "../../actions/payroll/DocumentCategoryActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchDocumentCategoryRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/home/document-categories',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addDocumentCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/document-categories',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteDocumentCategoryRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/document-categories/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editDocumentCategoryRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/home/document-categories/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchDocumentCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchDocumentCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let document_categories = response.data.data;
        yield put(fetchDocumentCategorySuccessGenerator(document_categories));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addDocumentCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addDocumentCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let document_categories = response.data.data;
        yield put(addDocumentCategorySuccessGenerator(document_categories));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteDocumentCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteDocumentCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let document_categories = response.data.data;
        yield put(deleteDocumentCategorySuccessGenerator(document_categories));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editDocumentCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editDocumentCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let document_categories = response.data.data;
        yield put(editDocumentCategorySuccessGenerator(document_categories));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchDocumentCategoryDefault() {
  yield takeLatest(FETCH_DOCUMENT_CATEGORY, fetchDocumentCategory)
}
export function* addDocumentCategoryDefault() {
  yield takeLatest(ADD_DOCUMENT_CATEGORY, addDocumentCategory);
}
export function* deleteDocumentCategoryDefault() {
  yield takeLatest(DELETE_DOCUMENT_CATEGORY, deleteDocumentCategory);
}
export function* editDocumentCategoryDefault() {
  yield takeLatest(EDIT_DOCUMENT_CATEGORY, editDocumentCategory);
}
export default function* rootSaga() {
  yield all([fork(fetchDocumentCategoryDefault),
    fork(addDocumentCategoryDefault),
    fork(deleteDocumentCategoryDefault),
    fork(editDocumentCategoryDefault)
  ]);
}
