import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";

import {
  ADD_ASSET,
  addAssetSuccessGenerator, BULK_UPLOAD_ASSETS, DELETE_ASSET,
  deleteAssetSuccessGenerator, EDIT_ASSET, editAssetSuccessGenerator, FETCH_ASSETS, fetchAssetsGenerator,
  fetchAssetsSuccessGenerator
} from "../../actions/payroll/AssetActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
const bulkUploadAssetsRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/hrm/bulk/upload',requestData,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);
function* bulkUploadAssets({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    let formData = Helpers.getFormData(requestParameters);
    const response = yield call(bulkUploadAssetsRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAssetsGenerator());
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
const fetchAssetsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/hrmassets',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addAssetRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/hrmassets',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const deleteAssetRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/hrmassets/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editAssetRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/hrmassets/update',requestParameters,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response => response)
        .catch(error => error.response);

function* fetchAssets({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAssetsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let assets = response.data.data;
        yield put(fetchAssetsSuccessGenerator(assets));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addAsset({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const sentData = Helpers.getFormData(requestParameters);
    const response = yield call(addAssetRequest, sentData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(addAssetSuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteAsset({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteAssetRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(deleteAssetSuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editAsset({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const sendData = Helpers.getFormData(requestParameters);
    const response = yield call(editAssetRequest, sendData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(editAssetSuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* bulkUploadAssetsDefault() {
  yield takeLatest(BULK_UPLOAD_ASSETS,bulkUploadAssets);
}
export function* fetchAssetsDefault() {
  yield takeLatest(FETCH_ASSETS, fetchAssets)
}
export function* addAssetDefault() {
  yield takeLatest(ADD_ASSET, addAsset);
}
export function* deleteAssetDefault() {
  yield takeLatest(DELETE_ASSET, deleteAsset);
}
export function* editAssetDefault() {
  yield takeLatest(EDIT_ASSET, editAsset);
}
export default function* rootSaga() {
  yield all([fork(fetchAssetsDefault),
    fork(addAssetDefault),
    fork(deleteAssetDefault),
    fork(editAssetDefault),
    fork(bulkUploadAssetsDefault)
  ]);
}
