import axios from "axios";
import {API_ENDPOINT, BETA_API_ENDPOINT, BETA_API_URL} from "../../../constants/ActionTypes";
import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator} from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
  ENABLE_DISABLE_FEATURES,
  FETCH_ALL_FEATURES, FETCH_COMPANY_ENABLED_FEATURES,
  fetchAllFeaturesSuccessGenerator, fetchCompanyFeaturesGenerator,
  fetchCompanyFeaturesSuccessGenerator
} from "../../actions/admin/EnableDisableFeaturesAction";


const fetchAllFeaturesRequest = async (requestParameters)=>
    await axios.get(API_ENDPOINT+"/home/features",{params:requestParameters})
        .then(response => response)
        .catch(error=>error.response);
const fetchCompanyEnabledFeaturesRequest = async (requestParams)=>
  await axios.get(BETA_API_ENDPOINT+"/company-enabled-features",{params:requestParams})
      .then(response=>response)
      .catch(error=>error.response)


const enableDisableCompanyFeaturesRequest = async (requestParams)=>
  await axios.post(BETA_API_ENDPOINT+"/company-activate-deactivate-features",requestParams)
      .then(response=>response)
      .catch(error=>error.response)

function* enableDisableCompanyFeatures({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    let response = yield( call(enableDisableCompanyFeaturesRequest, requestParameters));
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchCompanyFeaturesGenerator({auth_company_id:response.data.data})); /// re fetch company enabled features
        yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'))
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchCompanyEnabledFeatures({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),v1:true};
    let response = yield (call(fetchCompanyEnabledFeaturesRequest, requestParameters));
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data?.data;
        yield put(fetchCompanyFeaturesSuccessGenerator(data));
        //yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchAllFeatures({type,payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    let response = yield (call(fetchAllFeaturesRequest, requestParameters));
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
          yield put(fetchAllFeaturesSuccessGenerator(data));
       // yield put(showMessageGenerator(response.data.message,'info'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type))
}

export function* fetchAllFeaturesDefault() {
   yield takeLatest(FETCH_ALL_FEATURES,fetchAllFeatures);
}
export function* fetchCompanyEnabledFeaturesDefault() {
  yield takeLatest(FETCH_COMPANY_ENABLED_FEATURES,fetchCompanyEnabledFeatures);
}
export function* enableDisableCompanyFeaturesDefault() {
  yield takeLatest(ENABLE_DISABLE_FEATURES,enableDisableCompanyFeatures);
}

export default function* rootSaga() {
  yield all([
      fork(fetchAllFeaturesDefault),
      fork(fetchCompanyEnabledFeaturesDefault),
      fork(enableDisableCompanyFeaturesDefault)
  ]);
}
