import AttendanceReportSaga from "./AttendanceReportSaga";
import SnapCheckSaga from "./SnapCheckSaga";
import DetectionModeSaga from "./DetectionModeSaga";
import FingerPrintsSaga from "./FingerPrintsSaga";
import AttendanceMappingSaga from "./AttendanceMappingSaga";
import SchedulesSaga from "./SchedulesSaga";
import CheckPointsSaga from "./CheckPointsSaga";
import SyncedDevicesSaga from "./SyncedDevicesSaga";
import ProjectSaga from "./ProjectSaga";
import UploadJwmSaga from "./UploadJwmSaga";
import ScheduleReportsSaga from "./ScheduleReportsSaga";
import WorkedDaysSaga from "./WorkedDaysSaga";
import AttendanceSaga from "./AttendanceSaga";
import EmployeesSaga from "./EmployeesSaga";
import RelieverSaga from "./RelieverSaga";
import CustomHoursSaga from "./CustomHoursSaga";

const Attendance = [
  AttendanceReportSaga(),
  SnapCheckSaga(),
  DetectionModeSaga(),
  FingerPrintsSaga(),
  CheckPointsSaga(),
  SyncedDevicesSaga(),
  AttendanceMappingSaga(),
  SchedulesSaga(),
  ProjectSaga(),
  UploadJwmSaga(),
  ScheduleReportsSaga(),
  WorkedDaysSaga(),
  AttendanceSaga(),
  EmployeesSaga(),
  RelieverSaga(),
  CustomHoursSaga()
];

export default Attendance;
