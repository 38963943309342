export const FETCH_PAID_LEAVES = "FETCH_PAID_LEAVES";
export const FETCH_PAID_LEAVES_SUCCESS = "FETCH_PAID_LEAVES_SUCCESS";
export const SHOW_PAID_LEAVE_CREATE_FORM = 'SHOW_PAID_LEAVE_CREATE_FORM';
export const HIDE_PAID_LEAVE_CREATE_FORM = 'HIDE_PAID_LEAVE_CREATE_FORM';
export const ADD_PAID_LEAVE = 'ADD_PAID_LEAVE';
export const ADD_PAID_LEAVE_SUCCESS = 'ADD_PAID_LEAVE_SUCCESS';
export const SHOW_PAID_LEAVE_EDIT_FORM = 'SHOW_PAID_LEAVE_EDIT_FORM';
export const HIDE_PAID_LEAVE_EDIT_FORM = 'HIDE_PAID_LEAVE_EDIT_FORM';
export const EDIT_PAID_LEAVE = 'EDIT_PAID_LEAVE';
export const EDIT_PAID_LEAVE_SUCCESS = 'EDIT_PAID_LEAVE_SUCCESS';
export const SHOW_PAID_LEAVE_DELETE_FORM = 'SHOW_PAID_LEAVE_DELETE_FORM';
export const HIDE_PAID_LEAVE_DELETE_FORM = 'HIDE_PAID_LEAVE_DELETE_FORM';
export const DELETE_PAID_LEAVE = 'DELETE_PAID_LEAVE';
export const DELETE_PAID_LEAVE_SUCCESS = 'DELETE_PAID_LEAVE_SUCCESS';

export const fetchPaidLeavesGenerator = (data=[])=>{
  return {
    type:FETCH_PAID_LEAVES,
    payload:data
  };
};

export const fetchPaidLeavesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_PAID_LEAVES_SUCCESS,
    payload:dataById
  }
};

export const showPaidLeaveCreateFormGenerator = ()=>{
  return {
    type:SHOW_PAID_LEAVE_CREATE_FORM
  };
};
export const hidePaidLeaveCreateFormGenerator = ()=>{
  return {
    type:HIDE_PAID_LEAVE_CREATE_FORM
  };
};
export const addPaidLeaveGenerator = (data)=>{
  return {
    type: ADD_PAID_LEAVE,
    payload: data
  }
};

export const addPaidLeaveSuccessGenerator = (data)=>{
  return {
    type: ADD_PAID_LEAVE_SUCCESS,
    payload: data
  }
};
export const showPaidLeaveEditFormGenerator = (data)=>{
  return {
    type:SHOW_PAID_LEAVE_EDIT_FORM,
    payload:data
  };
};
export const hidePaidLeaveEditFormGenerator = ()=>{
  return {
    type:HIDE_PAID_LEAVE_EDIT_FORM
  };
};
export const editPaidLeaveGenerator = (data)=>{
  return {
    type: EDIT_PAID_LEAVE,
    payload: data
  }
};
export const editPaidLeaveSuccessGenerator = (data)=>{
  return {
    type: EDIT_PAID_LEAVE_SUCCESS,
    payload: data
  }
};
export const showPaidLeaveDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_PAID_LEAVE_DELETE_FORM,
    payload:data
  };
};
export const hidePaidLeaveDeleteFormGenerator = ()=>{
  return {
    type:HIDE_PAID_LEAVE_DELETE_FORM
  };
};
export const deletePaidLeaveGenerator = (data)=>{
  return {
    type: DELETE_PAID_LEAVE,
    payload: data
  }
};
export const deletePaidLeaveSuccessGenerator = (data)=>{
  return {
    type: DELETE_PAID_LEAVE_SUCCESS,
    payload: data
  }
};
