import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import {
  hideAppLoaderGenerator,
  showAppLoaderGenerator,
  showMessageGenerator
} from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  APPROVE_REJECT_BANK_TRANSFER,
  approveRejectBankTransferSuccessGenerator,
  FETCH_BANK_TRANSFER,
  fetchBankTransferGenerator,
  fetchBankTransferSuccessGenerator
} from "../../actions/admin/BankTransferActions";

const fetchBankTransferRequest = async (requestData)=>
  await axios.get(API_ENDPOINT+'/admin/bank-transfer',{params:requestData})
    .then(response=>response)
     .catch(error=>error.response);

const approveRejectTransferRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/admin/bank-transfer/approve/reject',requestData)
    .then(response=>response)
    .catch(error=>error.response)

function* approveRejectTransfer({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(approveRejectTransferRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield(put(fetchBankTransferGenerator()));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchBankTransfer({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(fetchBankTransferRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchBankTransferSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchBankTransferDefault() {
yield takeLatest(FETCH_BANK_TRANSFER,fetchBankTransfer);
}
export function* approveRejectBankTransferDafault() {
yield takeLatest(APPROVE_REJECT_BANK_TRANSFER,approveRejectTransfer)
}
export default function* rootSaga() {
  yield all([
    fork(fetchBankTransferDefault),
    fork(approveRejectBankTransferDafault),
  ]);
}
