import {
  EDIT_WORKED_DAYS_SUCCESS,
  FETCH_WORKED_DAYS_SUCCESS
} from "../../actions/attendance/WorkedDaysAction";

const INIT_STATE = {
   workedDaysByID:[],
   month:'',
   year:'',
  use_dated_days:false,
  days:[]
};
export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_WORKED_DAYS_SUCCESS:
      return {
        ...state,
        workedDaysByID:action.payload.data,
        month:action.payload.month,
        year:action.payload.year,
        use_dated_days:action.payload.use_dated_days,
        days:action.payload.days
      };
    case EDIT_WORKED_DAYS_SUCCESS:
      let worked_day_id = action.payload.id;
      let data = null;
      if(state.workedDaysByID.hasOwnProperty('data')){
        data = [...state.workedDaysByID.data];
        let newData = data.map(record=>(Number(record.id) === Number(worked_day_id))?action.payload:record);
        return {
          ...state,
          workedDaysByID: {
            ...state.workedDaysByID,
            data:newData
          }
        };
      }
      return state;
    default:return state;
  }
}
