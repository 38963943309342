import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  ADD_ONBOARDING_COMPANY, EDIT_ONBOARDING_COMPANY, FETCH_ONBOARDING_COMPANIES,
  fetchOnboardingCompanySuccessGenerator, fetchOnboardingCompanyGenerator, MARK_SUBSCRIPTION_PAID, SEND_INVITATION, fetchOnboardingWorkflowSuccessGenerator, fetchOnboardingWorkflowGenerator, FETCH_ONBOARDING_WORKFLOW, ADD_ONBOARDING_WORKFLOW
} from "../../actions/admin/CompanyOnboardingActions";

const fetchOnboardingCompanyRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/admin/client-onboarding',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addOnboardingCompanyRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/admin/client-onboarding',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editOnboardingCompanyRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/admin/client-onboarding/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const markSubscriptionAsPaidRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT+'/admin/client-onboarding/'+requestParameters.id+'/mark-paid', requestParameters)
    .then(response => response)
    .catch(error => error.response);

const sendInvitationRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT+'/admin/client-onboarding/'+requestParameters.id+'/send-invitation', requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchOnboardingWorkflowRequest= async (requestParameters) =>
  await axios.get(API_ENDPOINT+'/admin/client-onboarding/workflow',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
  
const addOnboardingWorkflowRequest = async (requestParameters) =>
  await axios.post(API_ENDPOINT+'/admin/client-onboarding/workflow',requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchOnboardingCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchOnboardingCompanyRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let clients = response.data.data;
        yield put(fetchOnboardingCompanySuccessGenerator(clients));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addOnboardingCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addOnboardingCompanyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOnboardingCompanyGenerator({recordsPerPage:15}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editOnboardingCompany({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editOnboardingCompanyRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOnboardingCompanyGenerator({recordsPerPage:15}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* markSubscriptionAsPaid({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(markSubscriptionAsPaidRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOnboardingCompanyGenerator({recordsPerPage:15}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* sendInvitation({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendInvitationRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOnboardingCompanyGenerator({recordsPerPage:15}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchOnboardingWorkflow({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchOnboardingWorkflowRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let workflow = response.data.data;
        yield put(fetchOnboardingWorkflowSuccessGenerator(workflow));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addOnboardingWorkflow({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addOnboardingWorkflowRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchOnboardingWorkflowGenerator({recordsPerPage:50}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchOnboardingCompanyDefault() {
  yield takeLatest(FETCH_ONBOARDING_COMPANIES, fetchOnboardingCompany)
}
export function* addOnboardingCompanyDefault() {
  yield takeLatest(ADD_ONBOARDING_COMPANY, addOnboardingCompany);
}
export function* editOnboardingCompanyDefault() {
  yield takeLatest(EDIT_ONBOARDING_COMPANY, editOnboardingCompany);
}
export function* markSubscriptionAsPaidDefault() {
  yield takeLatest(MARK_SUBSCRIPTION_PAID, markSubscriptionAsPaid);
}
export function* sendInvitationDefault() {
  yield takeLatest(SEND_INVITATION, sendInvitation);
}
export function* fetchOnboardingWorkflowDefault() {
  yield takeLatest(FETCH_ONBOARDING_WORKFLOW, fetchOnboardingWorkflow);
}
export function* addOnboardingWorkflowDefault() {
  yield takeLatest(ADD_ONBOARDING_WORKFLOW, addOnboardingWorkflow);
}

export default function* rootSaga() {
  yield all([
    fork(fetchOnboardingCompanyDefault),
    fork(addOnboardingCompanyDefault),
    fork(editOnboardingCompanyDefault),
    fork(markSubscriptionAsPaidDefault),
    fork(sendInvitationDefault),
    fork(fetchOnboardingWorkflowDefault),
    fork(addOnboardingWorkflowDefault)
  ]);
}
