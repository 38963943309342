import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
  ADD_PERFORMANCE_RATING,
  addPerformanceRatingSuccessGenerator, DELETE_PERFORMANCE_RATING,
  deletePerformanceRatingSuccessGenerator, EDIT_PERFORMANCE_RATING,
  editPerformanceRatingSuccessGenerator,
  FETCH_PERFORMANCE_RATINGS, fetchPerformanceRatingsGenerator,
  fetchPerformanceRatingsSuccessGenerator
} from "../../actions/appraisal/PerformanceRatingActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchPerformanceRatingsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/appraisal/performance-rating',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addPerformanceRatingRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/appraisal/performance-rating',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deletePerformanceRatingRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/appraisal/performance-rating/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editPerformanceRatingRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/appraisal/performance-rating/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchPerformanceRatings({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchPerformanceRatingsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let performance_ratings = response.data.data;
        yield put(fetchPerformanceRatingsSuccessGenerator(performance_ratings));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addPerformanceRating({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addPerformanceRatingRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let performance_ratings = response.data.data;
        //yield put(addPerformanceRatingSuccessGenerator(performance_ratings));
        yield put(fetchPerformanceRatingsGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deletePerformanceRating({type,payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deletePerformanceRatingRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let performance_ratings = response.data.data;
        yield put(deletePerformanceRatingSuccessGenerator(performance_ratings));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editPerformanceRating({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editPerformanceRatingRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let performance_ratings = response.data.data;
        yield put(editPerformanceRatingSuccessGenerator(performance_ratings));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchPerformanceRatingsDefault() {
  yield takeLatest(FETCH_PERFORMANCE_RATINGS, fetchPerformanceRatings)
}
export function* addPerformanceRatingDefault() {
  yield takeLatest(ADD_PERFORMANCE_RATING, addPerformanceRating);
}
export function* deletePerformanceRatingDefault() {
  yield takeLatest(DELETE_PERFORMANCE_RATING, deletePerformanceRating);
}
export function* editPerformanceRatingDefault() {
  yield takeLatest(EDIT_PERFORMANCE_RATING, editPerformanceRating);
}
export default function* rootSaga() {
  yield all([fork(fetchPerformanceRatingsDefault),
    fork(addPerformanceRatingDefault),
    fork(deletePerformanceRatingDefault),
    fork(editPerformanceRatingDefault)
  ]);
}
