import React,{Component} from 'react';
import { Helpers } from '../../util/Helpers';
import Recharge from '../payouts/recharge'

class TopUp extends Component{
  constructor(props) {
    super(props);
  }
    render(){
        return (
          <div className="app-subscription-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
              <div className="app-login-main-content">
                  <Recharge {...this.props} page={'first-topup'}/>
              </div>
          </div>
        );
    }
}
export default TopUp;
