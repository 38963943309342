import {
  AUTO_CALCULATE_REPAYMENT_SUCCESS,
  FETCH_COMPANY_LOANS_SUCCESS,
  FETCH_LENDERS_SUCCESS, FETCH_LOAN_BY_DEFAULTED_SUCCESS, FETCH_LOAN_BY_NEW_SUCCESS, FETCH_LOAN_BY_PENDING_SUCCESS
} from "../../actions/admin/CompanyLoansActions";


const INIT_STATE = {

  companyLoans:{},
  lenders:{},
  loansNew:{},
  loansPending:{},
  loansDefault:{},
  autoCalculate:{},
};

export default (status=INIT_STATE,action)=>{
  switch (action.type) {
    case AUTO_CALCULATE_REPAYMENT_SUCCESS:
      return {
        ...status,
        autoCalculate: action.payload
      };
    case FETCH_LOAN_BY_NEW_SUCCESS:
      return {
        ...status,
        loansNew: action.payload
      };
    case FETCH_LOAN_BY_PENDING_SUCCESS:
      return {
        ...status,
        loansPending:action.payload
      };
      case FETCH_LOAN_BY_DEFAULTED_SUCCESS:
      return {
        ...status,
        loansDefault:action.payload
      };
    case FETCH_LENDERS_SUCCESS:
      return {
        ...status,
        lenders:action.payload
      };
    case FETCH_COMPANY_LOANS_SUCCESS:
      return{
        ...status,
        companyLoans:action.payload
      };
    default:
      return status;
  }
}
