import { Helpers } from "../../../util/Helpers";
import { PAYOUT_CACHE_TYPE } from "../../../constants/SessionKeys";

export const FETCH_RECIPIENTS = "FETCH_RECIPIENTS";
export const FETCH_RECIPIENTS_SUCCESS = "FETCH_RECIPIENTS_SUCCESS";

export const FETCH_FAVOURITE_RECIPIENTS = "FETCH_FAVOURITE_RECIPIENTS";
export const FETCH_FAVOURITE_RECIPIENTS_SUCCESS = "FETCH_FAVOURITE_RECIPIENTS_SUCCESS";

export const ADD_FAVOURITE_RECIPIENT = "ADD_FAVOURITE_RECIPIENT";

export const ADD_RECIPIENT = "ADD_RECIPIENT";
export const ADD_RECIPIENT_SUCCESS = "ADD_RECIPIENT_SUCCESS";

export const EDIT_RECIPIENT = "EDIT_RECIPIENT";
export const EDIT_RECIPIENT_SUCCESS = "EDIT_RECIPIENT_SUCCESS";
export const DELETE_RECIPIENT = "DELETE_RECIPIENT";
export const DELETE_RECIPIENT_SUCCESS = "DELETE_RECIPIENT_SUCCESS";

export const ADD_BULK_RECIPIENTS = "ADD_BULK_RECIPIENTS";
export const ADD_BULK_RECIPIENTS_SUCCESS = "ADD_BULK_RECIPIENTS_SUCCESS";
export const CHANGE_FEE_POLICY = "CHANGE_FEE_POLICY";

export const VERIFY_TRANSACTION ="VERIFY_TRANSACTION";
export const VERIFY_TRANSACTION_SUCCESS ="VERIFY_TRANSACTION_SUCCESS";
export const SEND_BULK_PAYOUT ="SEND_BULK_PAYOUT";
export const SEND_BULK_PAYOUT_SUCCESS ="SEND_BULK_PAYOUT_SUCCESS";
export const RESEND_PIN ="RESEND_PIN";

export const FETCH_ONGOING_BULK_TRANSACTIONS = "FETCH_ONGOING_BULK_TRANSACTIONS";
export const FETCH_ONGOING_BULK_TRANSACTIONS_SUCCESS = "FETCH_ONGOING_BULK_TRANSACTIONS_SUCCESS";

export const fetchFavouriteRecipientsGenerator =(data=[])=>{
  return{
    type:FETCH_FAVOURITE_RECIPIENTS,
    payload:data
  }
};
export const fetchFavouriteRecipientsSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_FAVOURITE_RECIPIENTS_SUCCESS,
    payload:dataByID
  }
};
export const addFavouriteRecipientGenerator =(data=[])=>{
  return{
    type:ADD_FAVOURITE_RECIPIENT,
    payload:data
  }
};

export const resendPinGenerator=(data=[])=>{
  return {
    type:RESEND_PIN,
    payload:data,
  }
};

export const sendBulkPayoutGenerator =(data=[])=>{
  return {
    type:SEND_BULK_PAYOUT,
    payload:data,
  }
};
export const sendBulkPayoutSuccessGenerator =(data)=>{
  return {
    type:SEND_BULK_PAYOUT_SUCCESS,
    payload:data,
  }
};

export const verifyTransactionGenerator =(data=[])=>{
  return {
    type:VERIFY_TRANSACTION,
    payload:data,
  }
};
export const verifyTransactionSuccessGenerator =(data)=>{
  return {
    type:VERIFY_TRANSACTION_SUCCESS,
    payload:data,
  }
};
export const changeFeePolicyGenerator =(data=[])=>{
  return{
    type:CHANGE_FEE_POLICY,
    payload:data
  }
};
export const fetchRecipientsGenerator =(data=[])=>{
  return{
    type:FETCH_RECIPIENTS,
    payload:data
  }
};
export const fetchRecipientsSuccessGenerator =(dataByID)=>{
  return{
    type:FETCH_RECIPIENTS_SUCCESS,
    payload:dataByID
  }
};
export const addRecipientGenerator =(data=[])=>{
  return{
    type:ADD_RECIPIENT,
    payload:data
  }
};
export const addRecipientSuccessGenerator =(data)=>{
  return{
    type:ADD_RECIPIENT_SUCCESS,
    payload:data
  }
};
export const addBulkRecipientsGenerator =(data=[])=>{
  return{
    type:ADD_BULK_RECIPIENTS,
    payload:data
  }
};
export const addBulkRecipientsSuccessGenerator =(data)=>{
  return{
    type:ADD_BULK_RECIPIENTS_SUCCESS,
    payload:data
  }
};
export const editRecipientGenerator =(data=[])=>{
  return{
    type:EDIT_RECIPIENT,
    payload:data
  }
};
export const editRecipientSuccessGenerator =(data)=>{
  return{
    type:EDIT_RECIPIENT_SUCCESS,
    payload:data
  }
};
export const deleteRecipientGenerator =(data=[])=>{
  return{
    type:DELETE_RECIPIENT,
    payload:data
  }
};
export const deleteRecipientSuccessGenerator =(data)=>{
  return{
    type:DELETE_RECIPIENT_SUCCESS,
    payload:data
  }
};
export const fetchOngoingBulkTransactionsGenerator =(data={})=>{
  if(!data.hasOwnProperty('cache_type')){
     data['cache_type'] =  Helpers.getItem(PAYOUT_CACHE_TYPE, 'BULK');
  }
  return{
    type:FETCH_ONGOING_BULK_TRANSACTIONS,
    payload:data
  }
};
export const fetchOngoingBulkTransactionsSuccessGenerator =(data)=>{
  return{
    type:FETCH_ONGOING_BULK_TRANSACTIONS_SUCCESS,
    payload:data
  }
};
