import {
  FETCH_PAYMENT_ACTIVATIONS_SUCCESS,
  ADD_PAYMENT_ACTIVATIONS_SUCCESS, DELETE_PAYMENT_ACTIVATIONS_SUCCESS,

} from "../../actions/admin/PaymentActivationActions";

const INIT_STATE = {
   paymentActivationsByID:{},
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {

      case FETCH_PAYMENT_ACTIVATIONS_SUCCESS:
        return {
          ...state,
          paymentActivationsByID: action.payload
        };
      case ADD_PAYMENT_ACTIVATIONS_SUCCESS:
        let adminUser = { ...state.paymentActivationsByID };
        adminUser[action.payload.id] = action.payload;
        return {
          ...state,
          paymentActivationsByID: adminUser
        };

      case DELETE_PAYMENT_ACTIVATIONS_SUCCESS:
        let paymentActivationsByID = { ...state.paymentActivationsByID };
        delete paymentActivationsByID[action.payload.id];
        return {
          ...state,
          paymentActivationsByID: paymentActivationsByID
        };
      default:
        return state;
    }
}
