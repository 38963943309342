import {
    ADD_KEY_RESULT_SUCCESS,
    DELETE_KEY_RESULT_SUCCESS,
    FETCH_KEY_RESULTS_SUCCESS,
    UPDATE_KEY_RESULT_SUCCESS
} from '../../actions/appraisal/KeyResultActions';

const INIT_STATE ={
 keyResultAreasByID:{}
};
export default (state = INIT_STATE,action)=>{
    let keyResult;
    switch (action.type) {
        case FETCH_KEY_RESULTS_SUCCESS:
            return {
                ...state,
                keyResultAreasByID: action.payload
            };
        case ADD_KEY_RESULT_SUCCESS:
        case UPDATE_KEY_RESULT_SUCCESS:
            keyResult = {...state.keyResultAreasByID};
            keyResult[action.payload.id] = action.payload;
            return{
                ...state,
                keyResultAreasByID:keyResult
            };
        case DELETE_KEY_RESULT_SUCCESS:
            keyResult = {...state.keyResultAreasByID};
            delete keyResult[action.payload.id];
            return {
                ...state,
                keyResultAreasByID:keyResult
            };
        default:
            return {...state};
    }
}
