import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_ALLOWANCE, BULK_ALLOWANCE, BULK_DEACTIVATE_ALLOWANCE, bulkAllowanceSuccessGenerator, CANCEL_ALLOWANCES,
  DELETE_ALLOWANCE,
  EDIT_ALLOWANCE,
  FETCH_ALLOWANCES, FETCH_HOUSING_TYPES,
  fetchAllowancesGenerator,
  fetchAllowancesSuccessGenerator, fetchHousingTypesSuccessGenerator
} from "../../actions/payroll/AllowanceActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const bulkDeactivateAllowanceRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/hrm/bulk-deactivate/allowance',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const UploadAllowanceRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/additions/upload/bulk',requestParameters,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response => response)
        .catch(error => error.response);

const fetchAllowancesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/additions',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const fetchHousingTypesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/housing-types',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addAllowanceRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/additions',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteAllowanceRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/additions/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const cancelAllowanceRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/additions/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editAllowanceRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/additions/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* bulkDeactivateAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(bulkDeactivateAllowanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAllowancesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* UploadAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(UploadAllowanceRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let rolesById = response.data.data;
        yield put(fetchAllowancesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchAllowances({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAllowancesRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchAllowancesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchHousingTypes({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchHousingTypesRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let housingTypes = response.data.data;
        yield put(fetchHousingTypesSuccessGenerator(housingTypes));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addAllowanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        /*let role = response.data.data;*/
        yield put(fetchAllowancesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* cancelAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(cancelAllowanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let role = response.data.data;
        //yield put(cancelAllowancesSuccessGenerator(role));
        yield put(fetchAllowancesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteAllowanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let role = response.data.data;
        //yield put(deleteAllowanceSuccessGenerator(role));
        yield put(fetchAllowancesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editAllowance({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAllowanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let role = response.data.data;
        //yield put(editAllowanceSuccessGenerator(role));
        yield put(fetchAllowancesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* bulkDeactivateAllowanceDefault(){
  yield takeLatest(BULK_DEACTIVATE_ALLOWANCE,bulkDeactivateAllowance);
}
export function* fetchAllowancesDefault() {
  yield takeLatest(FETCH_ALLOWANCES, fetchAllowances)
}
export function* cancelAllowanceDefault(){
  yield  takeLatest(CANCEL_ALLOWANCES,cancelAllowance);
}
export function* addAllowanceDefault() {
  yield takeLatest(ADD_ALLOWANCE, addAllowance);
}
export function* deleteAllowanceDefault() {
  yield takeLatest(DELETE_ALLOWANCE, deleteAllowance);
}
export function* editAllowanceDefault() {
  yield takeLatest(EDIT_ALLOWANCE, editAllowance);
}
export function* UploadAllowanceDefault() {
  yield takeLatest(BULK_ALLOWANCE, UploadAllowance);
}
export function* fetchHousingTypesDefault() {
  yield takeLatest(FETCH_HOUSING_TYPES, fetchHousingTypes);
}
export default function* rootSaga() {
  yield all([
      fork(fetchAllowancesDefault),
      fork(addAllowanceDefault),
      fork(deleteAllowanceDefault),
      fork(editAllowanceDefault),
      fork(cancelAllowanceDefault),
      fork(UploadAllowanceDefault),
      fork(bulkDeactivateAllowanceDefault),
      fork(fetchHousingTypesDefault)
  ]);
}
