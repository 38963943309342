import {UPDATE_USER_PROFILE} from "../AuthActions";

export const SHOW_USER_CREATE_FORM = 'SHOW_USER_CREATE_FORM';
export const HIDE_USER_CREATE_FORM = 'HIDE_USER_CREATE_FORM';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const SHOW_USER_EDIT_FORM = 'SHOW_USER_EDIT_FORM';
export const HIDE_USER_EDIT_FORM = 'HIDE_USER_EDIT_FORM';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';

export const SHOW_USER_DELETE_FORM = 'SHOW_USER_DELETE_FORM';
export const HIDE_USER_DELETE_FORM = 'HIDE_USER_DELETE_FORM';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const TRANSFER_USER ="TRANSFER_USER";
export const TRANSFER_USER_SUCCESS ="TRANSFER_USER_SUCCESS";
export const DEACTIVATE_USER ="DEACTIVATE_USER";
export const DEACTIVATE_USER_SUCCESS ="DEACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER ="ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESS ="ACTIVATE_USER_SUCCESS";
export const UPDATE_USER_PROFILE_SETTING = "UPDATE_USER_PROFILE_SETTING";

export const USE_OTP = 'USE_OTP';
export const USE_OTP_SUCCESS = 'USE_OTP_SUCCESS';

export const USERS_WITH_CERTAIN_PERMISSION ='USERS_WITH_CERTAIN_PERMISSION';
export const USERS_WITH_CERTAIN_PERMISSION_SUCCESS ='USERS_WITH_CERTAIN_PERMISSION_SUCCESS';

export const usersWithCertainPermissionGenerator =(payload)=>{
    return {
        type:USERS_WITH_CERTAIN_PERMISSION,
        payload
    }
}
export const usersWithCertainPermissionSuccessGenerator =(payload=[])=>{
    return {
        type:USERS_WITH_CERTAIN_PERMISSION_SUCCESS,
        payload
    }
}

export const useOtpGenerator =(data)=>{
    return {
        type:USE_OTP,
        payload:data
    }
};
export const updateUserProfileGenerator =(data)=>{
    return {
        type:UPDATE_USER_PROFILE_SETTING,
        payload:data
    }
};


export const fetchUsersGenerator =(data=[])=>{
    return{
        type:FETCH_USERS,
        payload:data
    }
};
export const fetchUsersSuccessGenerator =(data=[])=>{
    return {
        type:FETCH_USERS_SUCCESS,
        payload:data
    }
};
export const showUserCreateFormGenerator =(data=[])=>{
    return {
        type:SHOW_USER_CREATE_FORM,
        payload:data
    }
};
export const hideUserCreateFormGenerator=(data=null)=>{
    return {
        type:HIDE_USER_CREATE_FORM,
        payload:data
    }
}
export const showUserEditFormGenerator =(data=[])=>{
    return {
        type:SHOW_USER_EDIT_FORM,
        payload:data
    }
};
export const hideUserEditFormGenerator=(data=null)=>{
    return {
        type:HIDE_USER_EDIT_FORM,
        payload:data
    }
};
export const showUserDeleteFormGenerator =(data=[])=>{
    return {
        type:SHOW_USER_DELETE_FORM,
        payload:data
    }
};
export const hideUserDeleteFormGenerator=(data=null)=>{
    return {
        type:HIDE_USER_DELETE_FORM,
        payload:data
    }
};
export const deleteUserSuccessGenerator =(data)=>{
    return {
        type:DELETE_USER_SUCCESS,
        payload:data
    }
};
export const deleteUserGenerator=(data)=>{
    return {
        type:DELETE_USER,
        payload:data
    }
};
export const addUserSuccessGenerator =(data)=>{
    return {
        type:ADD_USER_SUCCESS,
        payload:data
    }
};
export const addUserGenerator=(data)=>{
    return {
        type:ADD_USER,
        payload:data
    }
};
export const editUserSuccessGenerator =(data)=>{
    return {
        type:EDIT_USER_SUCCESS,
        payload:data
    }
};
export const editUserGenerator=(data)=>{
    return {
        type:EDIT_USER,
        payload:data
    }
};
export const transferUserSuccessGenerator =(data)=>{
    return {
        type:TRANSFER_USER_SUCCESS,
        payload:data
    }
};
export const transferUserGenerator=(data)=>{
    return {
        type:TRANSFER_USER,
        payload:data
    }
};
export const deactivateUserSuccessGenerator =(data)=>{
    return {
        type:DEACTIVATE_USER_SUCCESS,
        payload:data
    }
};
export const deactivateUserGenerator=(data)=>{
    return {
        type:DEACTIVATE_USER,
        payload:data
    }
};
export const activateUserSuccessGenerator =(data)=>{
    return {
        type:ACTIVATE_USER_SUCCESS,
        payload:data
    }
};
export const activateUserGenerator=(data)=>{
    return {
        type:ACTIVATE_USER,
        payload:data
    }
};
