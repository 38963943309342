import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import { API_ADDRESS, API_ENDPOINT } from "../../../constants/ActionTypes";
import {
    ACTIVATE_PROJECT,
    activateProjectsSuccessGenerator,
    ADD_BULK_PROJECT,
    ADD_PROJECT, ADD_TO_PROJECT_BLACKLIST,
    addProjectSuccessGenerator, BULK_DELETE_PROJECTS,
    DELETE_PROJECT,
    deleteProjectSuccessGenerator, EXPORT_PROJECTS,
    FETCH_EMPLOYEE_ASSIGNED_PROJECT,
    FETCH_PROJECT, FETCH_PROJECT_BLACKLIST,
    fetchEmployeeAssignedProjectSuccessGenerator,
    fetchProjectBlacklistGenerator,
    fetchProjectBlacklistSuccessGenerator,
    fetchProjectGenerator,
    fetchProjectSuccessGenerator, REMOVE_FROM_PROJECT_BLACKLIST, TERMINATE_PROJECT, terminateProjectsSuccessGenerator,
    UPDATE_PROJECT,
    updateProjectSuccessGenerator
} from "../../actions/attendance/ProjectActions";
import { push } from 'connected-react-router';
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import { SELECTED_PROJECT_KEY } from "../../../constants/SessionKeys";

const bulkDeleteProjectsRequest = async (requestData) =>
    await axios.post(API_ENDPOINT+'/attendance/delete/bulk/projects',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const fetchProjectsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/attendance/projects',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);
const fetchProjectBlacklistRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/project/blacklist',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const fetchEmployeeProjectAssignRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/attendance/projects/employee/'+requestParameters.employee_id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addProjectRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/project/add',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const exportProjectsRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/attendance/projects/export',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const addToProjectBlacklistRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/attendance/project/blacklist/add',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const removeFromProjectBlacklistRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/attendance/project/blacklist/delete',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const addBulkProjectRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/attendance/project/bulk/add',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteProjectRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/project/delete',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const activateProjectRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/attendance/project/activate',requestParameters)
    .then(response => response)
    .catch(error => error.response);
const terminateProjectRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/attendance/project/terminate',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editProjectRequest = async (requestParameters) =>{
    let path = '/attendance/project/update';
    if(requestParameters.action === 'assign'){
        path = '/attendance/project/assign';
    }else if(requestParameters.action === 'deassign'){
        path = '/attendance/project/deassign';
    }
    return await  axios.post(API_ENDPOINT+path,requestParameters)
      .then(response => response)
      .catch(error => error.response);
};
function* fetchEmployeeProjectAssign({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchEmployeeProjectAssignRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchEmployeeAssignedProjectSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* bulkDeleteProjects({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(bulkDeleteProjectsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(fetchProjectGenerator());
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* exportProjects({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(exportProjectsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                if(response.data.data.hasOwnProperty('file_path')){
                    window.open(API_ADDRESS+response.data.data.file_path, '_blank');
                }
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchProjects({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchProjectsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectSuccessGenerator(response.data.data));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchProjectBlacklist({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchProjectBlacklistRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectBlacklistSuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addProject({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addProjectRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(addProjectSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
               /* if(payload.hasOwnProperty('assign_employees') && payload.assign_employees){
                    yield put(push('/attendance/project-assign-employees'));
                }else{
                    yield put(push('/attendance/projects'));
                }*/
                yield put(push('/attendance/projects'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addToProjectBlacklist({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addToProjectBlacklistRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectBlacklistGenerator(payload));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* removeFromProjectBlacklist({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(removeFromProjectBlacklistRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectBlacklistGenerator(payload));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addBulkProject({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const formData = Helpers.getFormData(requestParameters);
        const response = yield call(addBulkProjectRequest, formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(fetchProjectGenerator(FETCH_PROJECT));
                yield put(showMessageGenerator(response.data.message,'success'));
                yield put(push('/attendance/projects'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteProject({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteProjectRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(deleteProjectSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* activateProject({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(activateProjectRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(activateProjectsSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* terminateProject({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(terminateProjectRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                yield put(terminateProjectsSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editProject({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(editProjectRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                try{
                  let info = {};
                  try{
                    info = JSON.parse(Helpers.getItem(SELECTED_PROJECT_KEY,{}));
                  }catch (e) {}
                  if(Number(response.data.data.id) === Number(info.id)){
                    info.employeeIDs = response.data.data.employeeIDs;
                    Helpers.saveItem(SELECTED_PROJECT_KEY, JSON.stringify(info));
                  }
                }catch (e) {}
                yield put(updateProjectSuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
                if(payload.action === 'update'){
                  yield put(push('/attendance/projects'));
                }
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* fetchProjectsDefault() {
    yield takeLatest(FETCH_PROJECT, fetchProjects)
}
export function* fetchProjectBlacklistDefault() {
    yield takeLatest(FETCH_PROJECT_BLACKLIST, fetchProjectBlacklist)
}
export function* addToProjectBlacklistDefault() {
    yield takeLatest(ADD_TO_PROJECT_BLACKLIST, addToProjectBlacklist)
}
export function* removeFromProjectBlacklistDefault() {
    yield takeLatest(REMOVE_FROM_PROJECT_BLACKLIST, removeFromProjectBlacklist)
}
export function* addProjectDefault() {
    yield takeLatest(ADD_PROJECT, addProject);
}
export function* addBulkProjectDefault() {
    yield takeLatest(ADD_BULK_PROJECT, addBulkProject);
}
export function* deleteProjectDefault() {
    yield takeLatest(DELETE_PROJECT, deleteProject);
}
export function* activateProjectDefault() {
    yield takeLatest(ACTIVATE_PROJECT, activateProject);
}
export function* terminateProjectDefault() {
    yield takeLatest(TERMINATE_PROJECT, terminateProject);
}
export function* editProjectDefault() {
    yield takeLatest(UPDATE_PROJECT, editProject);
}
export function* fetchEmployeeProjectAssignDefault() {
    yield takeLatest(FETCH_EMPLOYEE_ASSIGNED_PROJECT, fetchEmployeeProjectAssign);
}
export function* exportProjectsDefault() {
    yield takeLatest(EXPORT_PROJECTS, exportProjects);
}
export function* bulkDeleteProjectsDefault() {
 yield takeLatest(BULK_DELETE_PROJECTS,bulkDeleteProjects);
}
export default function* rootSaga() {
    yield all([fork(fetchProjectsDefault),
        fork(addProjectDefault),
        fork(deleteProjectDefault),
        fork(editProjectDefault),
        fork(addBulkProjectDefault),
        fork(fetchEmployeeProjectAssignDefault),
        fork(fetchProjectBlacklistDefault),
        fork(addToProjectBlacklistDefault),
        fork(removeFromProjectBlacklistDefault),
        fork(exportProjectsDefault),
        fork(activateProjectDefault),
        fork(terminateProjectDefault),
        fork(bulkDeleteProjectsDefault)
    ]);
}
