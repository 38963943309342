import HolidaySagas from  './HolidaySagas';
import CompanyRateSagas from './CompanyPaymentRateSaga';
import EmployeesSaga from "./EmployeesSaga";
import LoanCategorySaga from "./LoanCategorySaga";
import AdditionalCategoriesSaga from "./AdditionalCategoriesSaga";
import LeaveTypeCategorySaga from "./LeaveTypeCategorySaga";
import DeductionCategorySaga from "./DeductionCategorySaga";
import ExitReasonSaga from "./ExitReasonSaga";
import ExitSaga from "./ExitSaga";
import EmployeePaymentRateSaga from "./EmployeePaymentRateSaga";
import EmployeeNumberFormatSaga from "./EmployeeNumberFormatSaga";
import PettyCashSaga from "./PettyCashSaga";
import PaidLeaveSaga from "./PaidLeaveSaga";
import AllowanceSaga from "./AllowanceSaga";
import LeaveSaga from "./LeaveSaga";
import LoansSaga from "./LoansSaga";
import LeaveDashboardSagas from "./LeaveDashboardSagas";
import LeaveBalanceSaga from "./LeaveBalanceSaga";
import WithdrawalSagas from "./WithdrawalSaga"
import DeductionsSagas from "./DeductionsSagas";
import SalaryAdvanceSaga from "./SalaryAdvanceSaga";
import AssetSaga from "./AssetSaga";
import ImprestSaga from "./ImprestSaga";
import ExpenseCategorySaga from "./ExpenseCategorySaga";
import DocumentCategorySaga from "./DocumentCategorySaga";
import PettyCashReceiptSaga from "./PettyCashReceiptSaga";
import DocumentSaga from "./DocumentSaga";
import PayrollSaga from "./PayrollSaga";
import SmsSaga from "./SmsSaga";
import NotificationSaga from "./NotificationSaga";
import PayrollReportSaga from "./PayrollReportSaga";
import PaySlipSaga from "./PaySlipSaga";
import TrainingSagas from "./TrainingSagas";
import ProductSaga from "./ProductSaga";
import ProductVarietySaga from "./ProductVarietySaga";
import EmployeeRentSaga from "./EmployeeRentSaga";
import FringeMarketBenefitSaga from "./FringeMarketBenefitSaga";
import InsurancePremiumSaga from "./InsurancePremiumSaga";
import MortgageReliefSaga from "./MortgageReliefSaga";
import AssetCategorySaga from "./AssetCategorySaga";
import EmployeeInvoicesSaga from "./EmployeeInvoicesSaga";
import HelbSaga from "./HelbSaga";

const Payroll =[
  HolidaySagas(),
  CompanyRateSagas(),
  EmployeesSaga(),
  AdditionalCategoriesSaga(),
  LeaveTypeCategorySaga(),
  LoanCategorySaga(),
  DeductionCategorySaga(),
  ExitReasonSaga(),
   ExitSaga(),
  EmployeePaymentRateSaga(),
  EmployeeNumberFormatSaga(),
  LeaveDashboardSagas(),
  WithdrawalSagas(),
  PettyCashSaga(),
  PaidLeaveSaga(),
  AllowanceSaga(),
  LeaveSaga(),
  LoansSaga(),
  LeaveDashboardSagas(),
  LeaveBalanceSaga(),
  LeaveDashboardSagas(),
  DeductionsSagas(),
  SalaryAdvanceSaga(),
  AssetSaga(),
  ImprestSaga(),
  ExpenseCategorySaga(),
  DocumentCategorySaga(),
  PettyCashReceiptSaga(),
  DocumentSaga(),
  PayrollSaga(),
  SmsSaga(),
  NotificationSaga(),
  PayrollReportSaga(),
  PaySlipSaga(),
  TrainingSagas(),
  ProductSaga(),
  ProductVarietySaga(),
  EmployeeRentSaga(),
  FringeMarketBenefitSaga(),
  InsurancePremiumSaga(),
  MortgageReliefSaga(),
  AssetCategorySaga(),
  EmployeeInvoicesSaga(),
  HelbSaga()
];
export default Payroll;
