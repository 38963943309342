import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import AddCompany from '../../components/onboarding/addCompany';
import {Helpers} from "../../util/Helpers";
import BackGroundLoader from '../../components/common/BackGroundLoader';
import {Button} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { userSignOutGenerator } from '../../appRedux/actions/AuthActions';
import { Redirect } from "react-router-dom";
import CustomLoader from '../../components/common/CustomLoader';
import OnboardingLoader from '../../components/common/onboardingLoader';

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  card: {
    padding: 15
  }
});

class Onboarding extends React.Component {
  handleSignOut = () => {
    Helpers.clearAuthData();
    this.props.userSignOutGenerator();
  };

  render() {
    if(Helpers.authSwitchedCompanyID()){
      return <Redirect to={'/home'}/>
    }

    return (
      <div className="app-company-setup-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-company-setup-main-content">
          <div className="app-company-setup-logo">
            <h1 className="jr-font-weight-light">Company Setup</h1>
            <h5 className="jr-font-weight-light text-black-50">
              Get started by setting up your company
            </h5>
            <a onClick={this.handleSignOut} style={{textDecoration: 'none', color: '#3cb543'}} className="app-company-setup-logout1">
              <ExitToAppIcon />LOGOUT</a>
          </div>
          <div className="app-company-setup-content">
            <AddCompany />
          </div>
        </div>
        {
          Helpers.loading(this.props)
          && (
            <div className="loader-view">
              <OnboardingLoader />
            </div>
          )
        }
      </div>
    );
  }
}

Onboarding.propTypes = {
  classes: PropTypes.object
};
const mapStateToProps = ({ general, company }) => {
  const { switchedCompanyID, companiesByID } = company;
  const { appLoading } = general;
  return {
    switchedCompanyID,
    companiesByID,
    appLoading
  };
};
export default withStyles(styles)(connect(mapStateToProps, {userSignOutGenerator})(Onboarding));
