import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
    ADD_WITHHOLDING_TAX,
    EDIT_WITHHOLDING_TAX,
    DELETE_WITHHOLDING_TAX,
    BULK_WITHHOLDING_TAX,
    editWithholdingTaxSuccessGenerator, FETCH_WITHHOLDING_TAX,
    fetchWithholdingTaxSuccessGenerator,addWithholdingTaxGenerator,addWithholdingSuccessTaxGenerator,fetchWithholdingTaxGenerator
} from "../../actions/common/WithholdingTaxActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const addWithholdingTaxRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/setup/withholding-tax',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchWithholdingTaxRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/setup/withholding-tax',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editWithholdingTaxRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/setup/withholding-tax/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteWithholdingTaxRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/setup/withholding-tax/'+requestParameters.id, {params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const UploadWithholdingTaxRequest = async (requestParameters) =>
await  axios.post(API_ENDPOINT+'/setup/upload/withholding-tax',requestParameters,{headers: {
    'content-type': `multipart/form-data`
  }})
    .then(response => response)
    .catch(error => error.response);

function* fetchWithholdingTax({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchWithholdingTaxRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let withholdingTaxById = response.data.data;
        yield put(fetchWithholdingTaxSuccessGenerator(withholdingTaxById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* addWithholdingTax({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID(),company_id : Helpers.authSwitchedCompanyID()};
    const response = yield call(addWithholdingTaxRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rate = response.data.data;
        yield put(fetchWithholdingTaxGenerator());
        yield put(showMessageGenerator(response.data.message),'success');
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editWithholdingTax({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id: Helpers.authSwitchedCompanyID(),company_id : Helpers.authSwitchedCompanyID()};
    const response = yield call(editWithholdingTaxRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rate = response.data.data;
        yield put(fetchWithholdingTaxGenerator());
        yield put(showMessageGenerator(response.data.message),'success');
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteWithholdingTax({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID(),company_id : Helpers.authSwitchedCompanyID()};
    console.log(requestParameters);
    const response = yield call(deleteWithholdingTaxRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let company = response.data.data;
        yield put(fetchWithholdingTaxGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* UploadWithholdingTax({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID(), company_id : Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(UploadWithholdingTaxRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        //let rolesById = response.data.data;
        yield put(fetchWithholdingTaxGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchWithholdingTaxDefault() {
  yield takeLatest(FETCH_WITHHOLDING_TAX, fetchWithholdingTax)
}
export function* addWithholdingTaxDefault() {
  yield takeLatest(ADD_WITHHOLDING_TAX, addWithholdingTax);
}
export function* editWithholdingTaxDefault() {
  yield takeLatest(EDIT_WITHHOLDING_TAX, editWithholdingTax);
}
export function* deleteWithholdingTaxDefault() {
  yield takeLatest(DELETE_WITHHOLDING_TAX, deleteWithholdingTax);
}
export function* uploadWithholdingTaxDefault() {
  yield takeLatest(BULK_WITHHOLDING_TAX, UploadWithholdingTax);
}
export default function* rootSaga() {
  yield all([fork(fetchWithholdingTaxDefault),
    fork(editWithholdingTaxDefault),
    fork(addWithholdingTaxDefault),
    fork(deleteWithholdingTaxDefault),
    fork(uploadWithholdingTaxDefault),
  ]);
}
