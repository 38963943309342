import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'
import Settings from "./SettingsReducer";
import Auth from "./AuthReducer";
import General from "./GeneralReducer";
import Common from "./common";

import Payroll from "./payroll";
import Attendance from "./attendance";
import Payouts from "./payouts";
import Profile from "./profiles";
import { createBrowserHistory } from "history";
import Admin from "./admin";
import Appraisal from "./appraisal";
const history = createBrowserHistory();

const defaultReducers ={
    settings: Settings,
    auth: Auth,
    general: General,
};

const appReducers = combineReducers(Object.assign({router: connectRouter(history)}
  ,defaultReducers,Common,Payroll,Attendance,Payouts,Profile,Admin,Appraisal));
const rootReducer = (state, action)=>{
     return  appReducers(state, action);
};


export default rootReducer;
