import {
    CREATE_REVIEW_PERIODS_SUCCESS,
    DELETE_REVIEW_PERIODS_SUCCESS,
    EDIT_REVIEW_PERIODS_SUCCESS,
    FETCH_REVIEW_PERIODS_SUCCESS
} from '../../actions/appraisal/ReviewPeriodActions';

const INIT_STATE = {
    ReviewPeriodsByID:{}
};

export default (state=INIT_STATE,action)=>{
    let reviewPeriod ={...state.ReviewPeriodsByID};
    switch (action.type) {
        case FETCH_REVIEW_PERIODS_SUCCESS:
            return {
                ...state,
                ReviewPeriodsByID:action.payload
            };
        case CREATE_REVIEW_PERIODS_SUCCESS:
        case EDIT_REVIEW_PERIODS_SUCCESS:
            reviewPeriod[action.payload.id] =action.payload;
            return {
                ...state,
                ReviewPeriodsByID:reviewPeriod
            };
        case DELETE_REVIEW_PERIODS_SUCCESS:
           delete reviewPeriod[action.payload.id];
            return {
                ...state,
                ReviewPeriodsByID:reviewPeriod
            };
        default: return {...state};
    }
}
