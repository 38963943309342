import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_EXIT, APPROVE_EXIT, approveExitSuccessGenerator, BULK_EXIT, bulkExitSuccessGenerator,
  CERTIFY_EXIT,
  certifyExitSuccessGenerator,
  DELETE_EXIT,
  deleteExitSuccessGenerator,
  EDIT_EXIT,
  editExitSuccessGenerator,
  FETCH_EXITS,
  fetchExitsSuccessGenerator, REJECT_EXIT, rejectExitSuccessGenerator
} from "../../actions/payroll/ExitActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addExitSuccessGenerator
} from "../../actions/payroll/ExitActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";


const UploadExitsRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/bulk-employee-exits',requestParameters,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response => response)
        .catch(error => error.response);

const fetchExitsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/employee-movements',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addExitRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/employee-movements',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const certifyExitRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/employee-movements/certify/'+(requestParameters.id ? requestParameters.id : 0),requestParameters)
    .then(response => response)
    .catch(error => error.response);

const rejectExitRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/employee-movements/reject/'+(requestParameters.id ? requestParameters.id : 0),requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteExitRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/hrm/employee-movements/'+(requestParameters.id ? requestParameters.id : 0),{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editExitRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/employee-movements/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const approveExitsRequest= async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/hrm/employee-movements/approve/'+(requestParameters.id ? requestParameters.id : 0),requestParameters)
        .then(response => response)
        .catch(error => error.response);

function* UploadExits({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), action:'approve',auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(UploadExitsRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(bulkExitSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* approveExits({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), action:'approve',auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(approveExitsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(approveExitSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* certifyExit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(certifyExitRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(certifyExitSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* rejectExit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(rejectExitRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(rejectExitSuccessGenerator(rolesById));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchExits({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchExitsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchExitsSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addExit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addExitRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        if(response.data.hasOwnProperty('isBulk')){
          yield put(fetchExitsSuccessGenerator(role));
        }else {
          yield put(addExitSuccessGenerator(role));
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteExit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteExitRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let exits = response.data.data;
        yield put(fetchExitsSuccessGenerator(exits));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editExit({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editExitRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editExitSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchExitsDefault() {
  yield takeLatest(FETCH_EXITS, fetchExits)
}
export function* approveExitsDefault(){
  yield takeLatest(APPROVE_EXIT,approveExits)
}
export function* certifyExitsDefault() {
  yield takeLatest(CERTIFY_EXIT, certifyExit)
}
export function* addExitDefault() {
  yield takeLatest(ADD_EXIT, addExit);
}
export function* deleteExitDefault() {
  yield takeLatest(DELETE_EXIT, deleteExit);
}
export function* editExitDefault() {
  yield takeLatest(EDIT_EXIT, editExit);
}
export function* rejectExitDefault() {
  yield takeLatest(REJECT_EXIT, rejectExit);
}
export function* UploadExitsDefault(){
  yield  takeLatest(BULK_EXIT,UploadExits);
}
export default function* rootSaga() {
  yield all([fork(fetchExitsDefault),
    fork(addExitDefault),
    fork(deleteExitDefault),
    fork(editExitDefault),
    fork(certifyExitsDefault),
    fork(rejectExitDefault),
    fork(approveExitsDefault),
    fork(UploadExitsDefault)
  ]);
}
