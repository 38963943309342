export const FETCH_DEDUCTION_CATEGORIES = "FETCH_DEDUCTION_CATEGORIES";
export const FETCH_DEDUCTION_CATEGORIES_SUCCESS = "FETCH_DEDUCTION_CATEGORIES_SUCCESS";
export const SHOW_DEDUCTION_CATEGORY_CREATE_FORM = 'SHOW_DEDUCTION_CATEGORY_CREATE_FORM';
export const HIDE_DEDUCTION_CATEGORY_CREATE_FORM = 'HIDE_DEDUCTION_CATEGORY_CREATE_FORM';
export const ADD_DEDUCTION_CATEGORY = 'ADD_DEDUCTION_CATEGORY';
export const ADD_DEDUCTION_CATEGORY_SUCCESS = 'ADD_DEDUCTION_CATEGORY_SUCCESS';
export const SHOW_DEDUCTION_CATEGORY_EDIT_FORM = 'SHOW_DEDUCTION_CATEGORY_EDIT_FORM';
export const HIDE_DEDUCTION_CATEGORY_EDIT_FORM = 'HIDE_DEDUCTION_CATEGORY_EDIT_FORM';
export const EDIT_DEDUCTION_CATEGORY = 'EDIT_DEDUCTION_CATEGORY';
export const EDIT_DEDUCTION_CATEGORY_SUCCESS = 'EDIT_DEDUCTION_CATEGORY_SUCCESS';
export const SHOW_DEDUCTION_CATEGORY_DELETE_FORM = 'SHOW_DEDUCTION_CATEGORY_DELETE_FORM';
export const HIDE_DEDUCTION_CATEGORY_DELETE_FORM = 'HIDE_DEDUCTION_CATEGORY_DELETE_FORM';
export const DELETE_DEDUCTION_CATEGORY = 'DELETE_DEDUCTION_CATEGORY';
export const DELETE_DEDUCTION_CATEGORY_SUCCESS = 'DELETE_DEDUCTION_CATEGORY_SUCCESS';

export const fetchDeductionCategoriesGenerator = (data=[])=>{
  return {
    type:FETCH_DEDUCTION_CATEGORIES,
    payload:data
  };
};

export const fetchDeductionCategoriesSuccessGenerator = (dataById)=> {
  return {
    type:FETCH_DEDUCTION_CATEGORIES_SUCCESS,
    payload:dataById
  }
};

export const showDeductionCategoryCreateFormGenerator = ()=>{
  return {
    type:SHOW_DEDUCTION_CATEGORY_CREATE_FORM
  };
};
export const hideDeductionCategoryCreateFormGenerator = ()=>{
  return {
    type:HIDE_DEDUCTION_CATEGORY_CREATE_FORM
  };
};
export const addDeductionCategoryGenerator = (data)=>{
  return {
    type: ADD_DEDUCTION_CATEGORY,
    payload: data
  }
};

export const addDeductionCategorySuccessGenerator = (data)=>{
  return {
    type: ADD_DEDUCTION_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showDeductionCategoryEditFormGenerator = (data)=>{
  return {
    type:SHOW_DEDUCTION_CATEGORY_EDIT_FORM,
    payload:data
  };
};
export const hideDeductionCategoryEditFormGenerator = ()=>{
  return {
    type:HIDE_DEDUCTION_CATEGORY_EDIT_FORM
  };
};
export const editDeductionCategoryGenerator = (data)=>{
  return {
    type: EDIT_DEDUCTION_CATEGORY,
    payload: data
  }
};
export const editDeductionCategorySuccessGenerator = (data)=>{
  return {
    type: EDIT_DEDUCTION_CATEGORY_SUCCESS,
    payload: data
  }
};
export const showDeductionCategoryDeleteFormGenerator = (data)=>{
  return {
    type:SHOW_DEDUCTION_CATEGORY_DELETE_FORM,
    payload:data
  };
};
export const hideDeductionCategoryDeleteFormGenerator = ()=>{
  return {
    type:HIDE_DEDUCTION_CATEGORY_DELETE_FORM
  };
};
export const deleteDeductionCategoryGenerator = (data)=>{
  return {
    type: DELETE_DEDUCTION_CATEGORY,
    payload: data
  }
};
export const deleteDeductionCategorySuccessGenerator = (data)=>{
  return {
    type: DELETE_DEDUCTION_CATEGORY_SUCCESS,
    payload: data
  }
};
