import {
  DELETE_PAYROLL_SUCCESS,
  DELETE_QUEUED_PAYROLL_SUCCESS,
  EDIT_PAYROLL_SUCCESS,
  FETCH_PAYROLLS_SUCCESS,
  FETCH_QUEUED_PAYROLLS_SUCCESS,
  HIDE_PAYROLL_CREATE_FORM,
  HIDE_PAYROLL_DELETE_FORM,
  HIDE_PAYROLL_EDIT_FORM,
  HIDE_PAYSLIPS_SEND_FORM,
  SHOW_PAYROLL_CREATE_FORM,
  SHOW_PAYROLL_DELETE_FORM,
  SHOW_PAYROLL_EDIT_FORM,
  SHOW_PAYSLIPS_SEND_FORM
} from '../../actions/payroll/PayrollActions';

const INIT_STATE = {
  payrollsByID:[],
  payrollsForReports:[],//not paginated
  queuedPayrollsByID:[],
  showPayrollCreateForm:false,
  showPayrollEditForm:false,
  payrollToEdit:false,
  showPayrollDeleteForm:false,
  payrollToDelete:{},
  showPayslipsSendForm:false
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_PAYROLLS_SUCCESS:
      if(action.payload && action.payload.hasOwnProperty('for_reports')){
        return {
          ...state,
          payrollsForReports: action.payload.data//not paginated
        };
      }
      return {
        ...state,
        payrollsByID: action.payload
      };
    case SHOW_PAYROLL_CREATE_FORM:
      return {
        ...state,
        showPayrollCreateForm: true
      };
    case HIDE_PAYROLL_CREATE_FORM:
      return {
        ...state,
        showPayrollCreateForm: false
      };
    case SHOW_PAYROLL_EDIT_FORM:
      return {
        ...state,
        showPayrollEditForm: true,
        payrollToEdit: action.payload
      };
    case HIDE_PAYROLL_EDIT_FORM:
      return {
        ...state,
        showPayrollEditForm: false
      };
    case EDIT_PAYROLL_SUCCESS:
      let payroll_id = action.payload.id;
      return {
        ...state,
        payrollsByID: {
          ...state.payrollsByID,
          [payroll_id]:action.payload
        }
      };
    case SHOW_PAYROLL_DELETE_FORM:
      return {
        ...state,
        showPayrollDeleteForm: true,
        payrollToDelete: action.payload
      };
    case HIDE_PAYROLL_DELETE_FORM:
      return {
        ...state,
        showPayrollDeleteForm: false
      };
    case DELETE_PAYROLL_SUCCESS:
      let payrollsByID = {...state.payrollsByID};
      delete payrollsByID[action.payload.id];
      return {
        ...state,
        payrollsByID:payrollsByID
      };
    case SHOW_PAYSLIPS_SEND_FORM:
      return {
        ...state,
        showPayslipsSendForm: true
      };
    case HIDE_PAYSLIPS_SEND_FORM:
      return {
        ...state,
        showPayslipsSendForm: false
      };
    case FETCH_QUEUED_PAYROLLS_SUCCESS:
      return {
        ...state,
        queuedPayrollsByID: action.payload
      };
    case DELETE_QUEUED_PAYROLL_SUCCESS:
      let queuedPayrollsByID = {...state.queuedPayrollsByID};
      delete queuedPayrollsByID[action.payload.id];
      return {
        ...state,
        queuedPayrollsByID:queuedPayrollsByID
      };
    default:
      return state;
  }
}
