import { API_ENDPOINT } from "../../../constants/ActionTypes";
import axios from 'axios';
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import { Helpers } from "../../../util/Helpers";
import {
  CHANGE_PASSWORD,
  CHANGE_PROFILE_PHOTO,
  changeProfileSuccessPhoto,
  FETCH_EMPLOYEE_PROFILE_DETAILS, FETCH_MY_TRAINING,
  fetchEmployeeProfileDetailSuccessGenerator,
  fetchMyTrainingSuccessGenerator,
  MY_DAILY_ATTENDANCE,
  myDailyAttendanceSuccessGenerator, UPDATE_PROFILE_INFO, updateProfileInfoSuccessGenerator
} from "../../actions/profiles/EmployeeProfileActions";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {all, call, fork, put,takeLatest} from "redux-saga/effects";

const getMyTrainingRequest = async  (requestData)=>
    await axios.get(API_ENDPOINT+'/home/mytraining',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);
const getMyAttendanceRequest = async  (requestData)=>
    await axios.get(API_ENDPOINT+'/home/myattendance',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const fetchEmployeeProfileDetailsRequest = async (requestParameters)=>
  await axios.get(API_ENDPOINT+'/home/myprofile',{params:requestParameters})
    .then(response=>response)
    .catch(error=>error.response);

const changePasswordRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/home/user/change/password',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const updateProfileInfoRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/hrm/employee/update/profile',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const changeProfilePhotoRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/home/user/update/profile/photo',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response=>response)
    .catch(error=>error.response);

function* changeProfilePhoto({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(changeProfilePhotoRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(changeProfileSuccessPhoto(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* updateProfileInfo({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(updateProfileInfoRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(updateProfileInfoSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* changePassword({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(changePasswordRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(userSignOutSuccessGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEmployeeDetails({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeProfileDetailsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchEmployeeProfileDetailSuccessGenerator(response.data.data));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* getMyAttendance({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(getMyAttendanceRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(myDailyAttendanceSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* getMyTraining({type, payload}){
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(getMyTrainingRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchMyTrainingSuccessGenerator(response.data.data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchEmployeeDetailsDefault() {
  yield takeLatest(FETCH_EMPLOYEE_PROFILE_DETAILS,fetchEmployeeDetails);
}
function* changePasswordDefault() {
yield takeLatest(CHANGE_PASSWORD,changePassword);
}
function* changeProfilePhotoDefault() {
yield takeLatest(CHANGE_PROFILE_PHOTO,changeProfilePhoto);
}
function* getMyAttendanceDefault() {
 yield takeLatest(MY_DAILY_ATTENDANCE,getMyAttendance);
}
function* getMyTrainingDefault(){
  yield takeLatest(FETCH_MY_TRAINING,getMyTraining);
}
function* updateProfileInfoDefault(){
  yield takeLatest(UPDATE_PROFILE_INFO,updateProfileInfo);
}
export default function* rootSaga() {
  yield all([
    fork(fetchEmployeeDetailsDefault),
    fork(changePasswordDefault),
    fork(changeProfilePhotoDefault),
      fork(getMyAttendanceDefault),
      fork(getMyTrainingDefault),
      fork(updateProfileInfoDefault)
  ])
}
