export const FETCH_SUPERVISOR_LEVEL_APPRAISAL = 'FETCH_SUPERVISOR_LEVEL_APPRAISAL';
export const FETCH_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS = 'FETCH_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS';

export const CREATE_SUPERVISOR_LEVEL_APPRAISAL ='CREATE_SUPERVISOR_LEVEL_APPRAISAL';
export const CREATE_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS ='CREATE_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS';

export const EDIT_SUPERVISOR_LEVEL_APPRAISAL = 'EDIT_SUPERVISOR_LEVEL_APPRAISAL';
export const EDIT_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS = 'EDIT_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS';

export const DELETE_SUPERVISOR_LEVEL_APPRAISAL = 'DELETE_SUPERVISOR_LEVEL_APPRAISAL';
export const DELETE_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS = 'DELETE_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS';

export const fetchSupervisorLevelAppraisalGenerator = (data)=>{
    return {
        type:FETCH_SUPERVISOR_LEVEL_APPRAISAL,
        payload:data
    }
};
export const fetchSupervisorLevelAppraisalSuccessGenerator = (data)=>{
    return {
        type:FETCH_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS,
        payload:data
    }
};
export const createSupervisorLevelAppraisalGenerator = (data)=>{
    return {
        type:CREATE_SUPERVISOR_LEVEL_APPRAISAL,
        payload:data
    }
};
export const createSupervisorLevelAppraisalSuccessGenerator = (data)=>{
    return {
        type:CREATE_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS,
        payload:data
    }
};
export const editSupervisorLevelAppraisalGenerator = (data)=>{
    return {
        type:EDIT_SUPERVISOR_LEVEL_APPRAISAL,
        payload:data
    }
};
export const editSupervisorLevelAppraisalSuccessGenerator = (data)=>{
    return {
        type:EDIT_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS,
        payload:data
    }
};
export const deleteSupervisorLevelAppraisalGenerator =(data)=>{
    return {
        type:DELETE_SUPERVISOR_LEVEL_APPRAISAL,
        payload:data
    }
};
export const deleteSupervisorLevelAppraisalSuccessGenerator =(data)=>{
    return {
        type:DELETE_SUPERVISOR_LEVEL_APPRAISAL_SUCCESS,
        payload:data
    }
};