import {
  INIT_URL,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
} from "../../constants/ActionTypes";
import { Helpers } from "../../util/Helpers";

export const FETCH_AUTH_USER_ROLE = "FETCH_AUTH_USER_ROLE";
export const FETCH_AUTH_USER_ROLE_SUCCESS = "FETCH_AUTH_USER_ROLE_SUCCESS";
export const SET_DEFAULT_APP = "SET_DEFAULT_APP";
export const SET_DEFAULT_APP_SUCCESS = "SET_DEFAULT_APP_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const USE_REST_CODE = "USE_REST_CODE";
export const USE_REST_CODE_SUCCESS = "USE_REST_CODE_SUCCESS";
export const LOG_IN_AS_CLIENT = "LOG_IN_AS_CLIENT";

export const OPEN_PASSWORD_CHANGE_MODAL = 'OPEN_PASSWORD_CHANGE_MODAL';
export const CLOSE_PASSWORD_CHANGE_MODAL = 'CLOSE_PASSWORD_CHANGE_MODAL';

export const VERIFY_EMAIL_ON_REGISTER = "VERIFY_EMAIL_ON_REGISTER";
export const RESEND_VERIFY_CODE = 'RESEND_VERIFY_CODE';
export const CONFIRM_TOKEN = 'CONFIRM_TOKEN';

export const resendVerifyEmailGenerator = (payload=[])=>{
  return {
    type:RESEND_VERIFY_CODE,
    payload
  }
}

export const verifyEmailOnRegisterGenerator = (payload=[])=>{
  return {
    type:VERIFY_EMAIL_ON_REGISTER,
    payload
  }
};

export const openPasswordChangeModal= ()=>{
  return {
    type:OPEN_PASSWORD_CHANGE_MODAL,
  }
};
export const closePasswordChangeModal= ()=>{
  return {
    type:CLOSE_PASSWORD_CHANGE_MODAL,
  }
};
export const useRestCodeGenerator =(data)=>{
  return {
    type:USE_REST_CODE,
    payload:data
  }
};
export const logInAsClientGenerator =(data)=>{
  return {
    type:LOG_IN_AS_CLIENT,
    payload:data
  }
};
export const useRestCodeSuccessGenerator =(data)=>{
  return {
    type:USE_REST_CODE_SUCCESS,
    payload:data
  }
};
export const recoverPasswordGenerator =(data)=>{
  return {
    type:RECOVER_PASSWORD,
    payload:data
  }
};
export const recoverPasswordSuccessGenerator =(data)=>{
  return {
    type:RECOVER_PASSWORD_SUCCESS,
    payload:data
  }
};
export const forgotPasswordGenerator =(data)=>{
  return {
      type:FORGOT_PASSWORD,
      payload:data
  }
};
export const forgotPasswordSuccessGenerator =(data)=>{
  return {
    type:FORGOT_PASSWORD_SUCCESS,
    payload:data
  }
};
export const userSignUpGenerator = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignInGenerator = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOutGenerator = (data=null) => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccessGenerator = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccessGenerator = (authUser) => {
  console.log(authUser)
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccessGenerator = (data=null) => {
  let message = "Your session has expired.";
  let type = "info";
  if(data && data.hasOwnProperty('from') && data.from === 'user_click'){
    message = 'You have been signed out successfully';
  }
  let notify = true;
  if(data && data.hasOwnProperty('notify') && !data.notify){
    notify = false;
  }
  if(notify){
    Helpers.pushNotification(message,type);
  }
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};
export const setInitUrlGenerator = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const fetchAuthUserRoleGenerator = (data=[]) => {
  return {
    type: FETCH_AUTH_USER_ROLE,
    payload: data
  };
};
export const fetchAuthUserRoleSuccessGenerator = (data) => {
  return {
    type: FETCH_AUTH_USER_ROLE_SUCCESS,
    payload: data
  };
};
export const setDefaultAppGenerator = (data=[]) => {
  return {
    type: SET_DEFAULT_APP,
    payload: data
  };
};
export const setDefaultAppSuccessGenerator = (data) => {
  return {
    type: SET_DEFAULT_APP_SUCCESS,
    payload: data
  };
};
export const confirmTokenGenerator = (data) => {
  return {
    type: CONFIRM_TOKEN,
    payload: data
  };
};
