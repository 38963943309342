
import {
    ADD_RELIEVERS_SUCCESS,
    DELETE_RELIEVERS_SUCCESS,
    FETCH_RELIEVERS_SUCCESS, UPDATE_RELIEVERS_SUCCESS
} from "../../actions/attendance/RelieversActions";

const INIT_STATE = {
    relieversByID:{},
};

export default(state=INIT_STATE,action)=>{
    let relievers;
    switch (action.type){

        case FETCH_RELIEVERS_SUCCESS:
            return {
                ...state,
                relieversByID:action.payload
            };
        case ADD_RELIEVERS_SUCCESS:
        case UPDATE_RELIEVERS_SUCCESS:
            return {
                ...state,
                relieversByID: {
                    ...state.relieversByID,
                    [action.payload.id]:action.payload
                }
            } ;
        case DELETE_RELIEVERS_SUCCESS:
            relievers = {...state.relieversByID};
            delete relievers[action.payload.id];
            return {
                ...state,
                relieversByID:relievers
            };
        default:
            return state;
    }
}