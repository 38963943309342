import {API_ADDRESS, API_ENDPOINT} from "../../../constants/ActionTypes";
import axios from 'axios';
import {Helpers} from "../../../util/Helpers";
import {call, fork, put, all, takeLatest} from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    CREATE_EMPLOYEE_KPI,
    createEmployeeKPISuccessGenerator,
    DELETE_EMPLOYEE_KPI,
    deleteEmployeeKPISuccessGenerator,
    EDIT_EMPLOYEE_KPI,
    editEmployeeKPISuccessGenerator,
    FETCH_EMPLOYEE_KPI,
    fetchEmployeeKPIGenerator,
    fetchEmployeeKPISuccessGenerator,
    PRINT_EMPLOYEE_KPI,
    UPLOAD_BULK_EMPLOYEE_KPI
} from "../../actions/appraisal/EmployeeKPIActions";

const printEmployeeKPIRequest =async (requestData)=>
    await axios.post(API_ENDPOINT+'/appraisal/print/employee',requestData)
        .then(response=>response)
        .catch(error=>error.response);

const fetchEmployeeKPIRequest = async (requestData)=>
    await axios.get(API_ENDPOINT+'/appraisal/employeekpi',{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);

const editEmployeeKPIRequest = async (requestData)=>
    await axios.put(API_ENDPOINT+'/appraisal/employeekpi/'+requestData.id,requestData)
        .then(response=>response)
        .catch(error=>error.response);

const createEmployeeKPIRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/appraisal/employeekpi',requestData)
        .then(response=>response)
        .catch(error=>error.response);
const deleteEmployeeKPIRequest = async (requestData)=>
    await axios.delete(API_ENDPOINT+'/appraisal/employeekpi/'+requestData.id,{params:requestData})
        .then(response=>response)
        .catch(error=>error.response);
const uploadBulkEmployeeKPIRequest =async (requestData)=>
  await axios.post(API_ENDPOINT+'/appraisal/employeekpi/bulk-upload',requestData, {headers: {
          'content-type': `multipart/form-data`
      }})
    .then(response=>response)
    .catch(error=>error.response);
function* printEmployeeKPI({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};

        let response = yield call(printEmployeeKPIRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {

            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteEmployeeKPI({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response = yield call(deleteEmployeeKPIRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(deleteEmployeeKPISuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* createEmployeeKPI({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()}
        let response = yield call(createEmployeeKPIRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(fetchEmployeeKPIGenerator());
                ///yield put(createEmployeeKPISuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editEmployeeKPI({type, payload}){
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()}
        let response = yield call(editEmployeeKPIRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(editEmployeeKPISuccessGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* fetchEmployeeKPI({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let response = yield call(fetchEmployeeKPIRequest,requestData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(fetchEmployeeKPISuccessGenerator(response.data.data));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* uploadBulkEmployeeKPI({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestData = {...payload,token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
        let formData = Helpers.getFormData(requestData);
        let response = yield call(uploadBulkEmployeeKPIRequest,formData);
        if (response.status ===201 || response.status ===200) {
            if (response.data.success) {
                yield put(fetchEmployeeKPIGenerator(response.data.data));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if (response.status ===401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function * printEmployeeKPIDefault(){
    yield takeLatest(PRINT_EMPLOYEE_KPI,printEmployeeKPI)
}
function* fetchEmployeeKPIDefault() {
    yield takeLatest(FETCH_EMPLOYEE_KPI,fetchEmployeeKPI);
}
function* editEmployeeKPIDefault() {
    yield takeLatest(EDIT_EMPLOYEE_KPI,editEmployeeKPI);
}
function* createEmployeeKPIDefault() {
    yield takeLatest(CREATE_EMPLOYEE_KPI,createEmployeeKPI);
}
function* deleteEmployeeKPIDefault() {
    yield takeLatest(DELETE_EMPLOYEE_KPI,deleteEmployeeKPI);
}
function* uploadBulkEmployeeKPIDefault() {
    yield takeLatest(UPLOAD_BULK_EMPLOYEE_KPI,uploadBulkEmployeeKPI);
}

export default function* rootSaga() {
    yield all([
        fork(fetchEmployeeKPIDefault),
        fork(editEmployeeKPIDefault),
        fork(createEmployeeKPIDefault),
        fork(deleteEmployeeKPIDefault),
        fork(printEmployeeKPIDefault),
        fork(uploadBulkEmployeeKPIDefault)
    ]);
}

