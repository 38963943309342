import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {
    addSubscriptionDaysSuccessGenerator,
    fetchActiveCompaniesSubscriptionsSuccessGenerator,
    fetchInActiveCompaniesSubscriptionsSuccessGenerator,
    FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS,
    FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS, ADD_SUBSCRIPTION_DAYS, BANK_TRANSFER_SUBSCRIPTION
} from "../../actions/admin/SubscriptionActions";
import { getTrialPeriodSuccessGenerator } from "../../actions/common/SubscriptionActions";
import { SUBSCRIPTION_KEY } from "../../../constants/SessionKeys";
import { push } from "connected-react-router";

const fetchActiveSubscriptionsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/tozza/companies/active',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);
const fetchInactiveSubscriptionsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/tozza/companies/inactive',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addSubscriptionDaysRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/tozza/add_subscription_days',requestParameters)
        .then(response => response)
        .catch(error => error.response);
const bankTransferRequest = async (requestData)=>
  await axios.post(API_ENDPOINT+'/admin/bank-transfer',requestData)
    .then(response=>response)
    .catch(error=>error.response);

function* bankTransfer({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID(),noOfEmployees:0};
        const response = yield call(bankTransferRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                if ('not_from_subscription' in response.data.data){
                    yield put(showMessageGenerator(response.data.message,'success'));
                }else{
                    yield put(showMessageGenerator(response.data.message,'success'));
                    yield put(getTrialPeriodSuccessGenerator(response.data.data));
                    Helpers.saveItem(SUBSCRIPTION_KEY,response.data.data);
                    yield put(push("/home"));
                }
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));

}

function* fetchActiveSubscriptions({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchActiveSubscriptionsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let active = response.data.data;
                yield put(fetchActiveCompaniesSubscriptionsSuccessGenerator(active));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));

}
function* fetchInactiveSubscriptions({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(fetchInactiveSubscriptionsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let active = response.data.data;
                yield put(fetchInActiveCompaniesSubscriptionsSuccessGenerator(active));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));

}
function* addSubscriptionDays({type,payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(addSubscriptionDaysRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let role = response.data.data;
                yield put(addSubscriptionDaysSuccessGenerator(role));
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (e) {
        yield put(showMessageGenerator(e,'error'));
    }
    yield put(hideAppLoaderGenerator(type));

}
export function* fetchActiveDefault() {
    yield takeLatest(FETCH_ACTIVE_COMPANIES_SUBSCRIPTIONS, fetchActiveSubscriptions)
}export function* fetchInActiveDefault() {
    yield takeLatest(FETCH_INACTIVE_COMPANIES_SUBSCRIPTIONS, fetchInactiveSubscriptions)
}
export function* addDaysDefault() {
    yield takeLatest(ADD_SUBSCRIPTION_DAYS, addSubscriptionDays);
}
export function* bankTransferDefault() {
 yield takeLatest(BANK_TRANSFER_SUBSCRIPTION,bankTransfer);
}

export default function* rootSaga() {
    yield all([fork(fetchActiveDefault),
        fork(fetchInActiveDefault),
        fork(addDaysDefault),
      fork(bankTransferDefault),
    ]);
}
