import {all, call, fork, put} from "redux-saga/effects";

import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";

import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
  ADD_EMPLOYEE_INVOICE,
  addEmployeeInvoicesSuccessGenerator, DELETE_EMPLOYEE_INVOICES,
  deleteEmployeeInvoicesSuccessGenerator, EDIT_EMPLOYEE_INVOICES,
  editEmployeeInvoicesSuccessGenerator,
  FETCH_EMPLOYEE_INVOICES, fetchEmployeeInvoicesGenerator,
  fetchEmployeeInvoicesSuccessGenerator
} from "../../actions/payroll/EmployeeInvoicesActions";
import { fetchAllowancesGenerator } from "../../actions/payroll/AllowanceActions";

const fetchEmployeeInvoicesRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/employee-invoices',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addEmployeeInvoiceRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/employee-invoices',requestParameters, {headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const deleteEmployeeInvoicesRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/employee-invoices/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editEmployeeInvoicesRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/employee-invoices/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchEmployeeInvoices({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchEmployeeInvoicesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchEmployeeInvoicesSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addEmployeeInvoices({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(addEmployeeInvoiceRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        // yield put(addEmployeeInvoicesSuccessGenerator(response.data.data));
        yield put(fetchEmployeeInvoicesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteEmployeeInvoice({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteEmployeeInvoicesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        // yield put(deleteEmployeeInvoicesSuccessGenerator(role));
        yield put(fetchEmployeeInvoicesGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editEmployeeInvoice({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editEmployeeInvoicesRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        // yield put(editEmployeeInvoicesSuccessGenerator(role));
        yield put(fetchEmployeeInvoicesGenerator(payload.params))
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchEmployeeInvoiceDefault() {
  yield takeLatest(FETCH_EMPLOYEE_INVOICES, fetchEmployeeInvoices)
}
export function* addEmployeeInvoiceDefault() {
  yield takeLatest(ADD_EMPLOYEE_INVOICE, addEmployeeInvoices);
}
export function* deleteEmployeeInvoiceDefault() {
  yield takeLatest(DELETE_EMPLOYEE_INVOICES, deleteEmployeeInvoice);
}
export function* editDocumentDefault() {
  yield takeLatest(EDIT_EMPLOYEE_INVOICES, editEmployeeInvoice);
}
export default function* rootSaga() {
  yield all([fork(fetchEmployeeInvoiceDefault),
    fork(addEmployeeInvoiceDefault),
    fork(deleteEmployeeInvoiceDefault),
    fork(editDocumentDefault)
  ]);
}
