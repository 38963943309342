import {
  ADD_LOAN_CATEGORY_SUCCESS,
  DELETE_LOAN_CATEGORY_SUCCESS,
  EDIT_LOAN_CATEGORY_SUCCESS,
  FETCH_LOAN_CATEGORIES_SUCCESS,
  HIDE_LOAN_CATEGORY_CREATE_FORM,
  HIDE_LOAN_CATEGORY_DELETE_FORM,
  HIDE_LOAN_CATEGORY_EDIT_FORM,
  SHOW_LOAN_CATEGORY_CREATE_FORM,
  SHOW_LOAN_CATEGORY_DELETE_FORM,
  SHOW_LOAN_CATEGORY_EDIT_FORM
} from '../../actions/payroll/LoanCategoryActions';

const INIT_STATE = {
  loanCategoriesByID:{},
  showLoanCategoryCreateForm:false,
  showLoanCategoryEditForm:false,
  loanCategoryToEdit:false,
  showLoanCategoryDeleteForm:false,
  loanCategoryToDelete:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_LOAN_CATEGORIES_SUCCESS:
      return {
        ...state,
        loanCategoriesByID: action.payload
      };
    case SHOW_LOAN_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showLoanCategoryCreateForm: true
      };
    case HIDE_LOAN_CATEGORY_CREATE_FORM:
      return {
        ...state,
        showLoanCategoryCreateForm: false
      };
    case SHOW_LOAN_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showLoanCategoryEditForm: true,
        loanCategoryToEdit: action.payload
      };
    case HIDE_LOAN_CATEGORY_EDIT_FORM:
      return {
        ...state,
        showLoanCategoryEditForm: false
      };
    case ADD_LOAN_CATEGORY_SUCCESS:
    case EDIT_LOAN_CATEGORY_SUCCESS:
      let loan_category_id = action.payload.id;
      return {
        ...state,
        loanCategoriesByID: {
          ...state.loanCategoriesByID,
          [loan_category_id]:action.payload
        }
      };
    case SHOW_LOAN_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showLoanCategoryDeleteForm: true,
        loanCategoryToDelete: action.payload
      };
    case HIDE_LOAN_CATEGORY_DELETE_FORM:
      return {
        ...state,
        showLoanCategoryDeleteForm: false
      };
    case DELETE_LOAN_CATEGORY_SUCCESS:
      let loanCategoriesByID = {...state.loanCategoriesByID};
      delete loanCategoriesByID[action.payload.id];
      return {
        ...state,
        loanCategoriesByID:loanCategoriesByID
      };
    default:
      return state;
  }
}
