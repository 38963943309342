import {all, call, fork, put} from "redux-saga/effects";
import {
  ADD_IMPREST, DELETE_IMPREST, deleteImprestSuccessGenerator, EDIT_IMPREST, editImprestSuccessGenerator,
  FETCH_IMPREST, fetchImprestGenerator,
  fetchImprestSuccessGenerator
} from "../../actions/payroll/ImprestActions";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  addImprestSuccessGenerator
} from "../../actions/payroll/ImprestActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";

const fetchImprestRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/petty-cash/account/details',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addImprestRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/petty-cash/account/restock',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteImprestRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/coming/soon'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editImprestRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/coming/soon'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchImprest({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchImprestRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let data = response.data.data;
        yield put(fetchImprestSuccessGenerator(data));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addImprest({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(addImprestRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchImprestGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteImprest({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteImprestRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchImprestGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editImprest({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token()};
    const response = yield call(editImprestRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let role = response.data.data;
        yield put(editImprestSuccessGenerator(role));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchImprestDefault() {
  yield takeLatest(FETCH_IMPREST, fetchImprest)
}
export function* addImprestDefault() {
  yield takeLatest(ADD_IMPREST, addImprest);
}
export function* deleteImprestDefault() {
  yield takeLatest(DELETE_IMPREST, deleteImprest);
}
export function* editImprestDefault() {
  yield takeLatest(EDIT_IMPREST, editImprest);
}
export default function* rootSaga() {
  yield all([fork(fetchImprestDefault),
    fork(addImprestDefault),
    fork(deleteImprestDefault),
    fork(editImprestDefault)
  ]);
}
