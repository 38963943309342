import {
    FETCH_SNAPCHECKS,
    FETCH_SNAPCHECKS_SUCCESS,
    HIDE_SNAPCHECK_CREATE_FORM,
    SHOW_SNAPCHECK_CREATE_FORM, ADD_SNAPCHECK_SUCCESS, DELETE_SNAPCHECK_SUCCESS,
    EDIT_SNAPCHECK_SUCCESS, HIDE_SNAPCHECK_DELETE_FORM,
    HIDE_SNAPCHECK_EDIT_FORM, SHOW_SNAPCHECK_DELETE_FORM,
    SHOW_SNAPCHECK_EDIT_FORM
} from "../../actions/attendance/SnapCheckActions";

const INIT_STATE = {
    snapChecksByID:{},
    showSnapCheckCreateForm:false,
    showSnapCheckEditForm:false,
    snapCheckToEdit:false,
    showSnapCheckDeleteForm:false,
    snapCheckToDelete:{}
};

export default (state = INIT_STATE, action)=> {
    switch (action.type) {
        case FETCH_SNAPCHECKS_SUCCESS:
            return {
                ...state,
                snapChecksByID: action.payload
            };
        case SHOW_SNAPCHECK_CREATE_FORM:
            return {
                ...state,
                showSnapCheckCreateForm: true
            };
        case HIDE_SNAPCHECK_CREATE_FORM:
            return {
                ...state,
                showSnapCheckCreateForm: false
            };
        case SHOW_SNAPCHECK_EDIT_FORM:
            return {
                ...state,
                showSnapCheckEditForm: true,
                snapCheckToEdit: action.payload
            };
        case HIDE_SNAPCHECK_EDIT_FORM:
            return {
                ...state,
                showSnapCheckEditForm: false
            };
        case ADD_SNAPCHECK_SUCCESS:
            return {
                ...state,
                snapChecksByID: action.payload
            };
        case EDIT_SNAPCHECK_SUCCESS:
            let snapCheck_id = action.payload.id;
            return {
                ...state,
                snapChecksByID: {
                    ...state.snapChecksByID,
                    [snapCheck_id]:action.payload
                }
            };
        case SHOW_SNAPCHECK_DELETE_FORM:
            return {
                ...state,
                showSnapCheckDeleteForm: true,
                snapCheckToDelete: action.payload
            };
        case HIDE_SNAPCHECK_DELETE_FORM:
            return {
                ...state,
                showSnapCheckDeleteForm: false
            };
        case DELETE_SNAPCHECK_SUCCESS:
            let snapChecksByID = {...state.snapChecksByID};
            delete snapChecksByID[action.payload.id];
            return {
                ...state,
                snapChecksByID:snapChecksByID
            };
        default:
            return state;
    }
}
