import {
  ADD_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
  DELETE_EMPLOYEE_NUMBER_FORMAT,
  EDIT_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
  FETCH_EMPLOYEE_NUMBER_FORMAT_SUCCESS,
  HIDE_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM,
  HIDE_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL,
  HIDE_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM,
  SHOW_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM,
  SHOW_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL,
  SHOW_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM
} from '../../actions/payroll/EmployeeNumberFormatActions';

const INIT_STATE = {
  employeeNumberFormatsByID:[],
  showEmployeeNumberFormatCreateForm:false,
  EmployeeNumberFormatToEdit:[],
  showEmployeeNumberFormatEditForm:false,
  showEmployeeNumberFormatDeleteModal:false,
};
export default (state = INIT_STATE, action)=>{
  let employee_number_formats =[];
  switch (action.type){
    case ADD_EMPLOYEE_NUMBER_FORMAT_SUCCESS:
    case EDIT_EMPLOYEE_NUMBER_FORMAT_SUCCESS:
      employee_number_formats = {...state.employeeNumberFormatsByID};
      employee_number_formats[action.payload.id]=action.payload;
      return {
        ...state,
        employeeNumberFormatsByID:employee_number_formats
      };
    case SHOW_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM:
      return {
        ...state,
        showEmployeeNumberFormatCreateForm:true
      };
    case HIDE_CREATE_EMPLOYEE_NUMBER_FORMAT_FORM:
      return {
        ...state,
        showEmployeeNumberFormatCreateForm:false
      };
    case FETCH_EMPLOYEE_NUMBER_FORMAT_SUCCESS:
      return {
        ...state,
        employeeNumberFormatsByID:action.payload
      };
    case SHOW_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM:
      return {
        ...state,
        EmployeeNumberFormatToEdit:action.payload,
        showEmployeeNumberFormatEditForm:true
      };
    case HIDE_EDIT_EMPLOYEE_NUMBER_FORMAT_FORM:
      return{
        ...state,
        showEmployeeNumberFormatEditForm:false
      };
    case SHOW_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL:
      return {
        ...state,
        EmployeeNumberFormatToEdit:action.payload,
        showEmployeeNumberFormatDeleteModal:true
      };
    case HIDE_DELETE_EMPLOYEE_NUMBER_FORMAT_MODAL:
      return {
        ...state,
        showEmployeeNumberFormatDeleteModal:false
      };
    case DELETE_EMPLOYEE_NUMBER_FORMAT:
      employee_number_formats = {...state.employeeNumberFormatsByID};
      delete employee_number_formats[action.payload.id];
      return {
        ...state,
        employeeNumberFormatsByID:employee_number_formats
      };
    default:
      return state;

  }
}
