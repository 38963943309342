export const  FETCH_CHECKPOINT = 'FETCH_CHECKPOINT';
export const  FETCH_CHECKPOINT_SUCCESS = 'FETCH_CHECKPOINT_SUCCESS';
export const  DELETE_CHECKPOINT="DELETE_CHECKPOINT";
export const  DELETE_CHECKPOINT_SUCCESS="DELETE_CHECKPOINT_SUCCESS";
export const  ADD_CHECKPOINT="ADD_CHECKPOINT";
export const  ADD_CHECKPOINT_SUCCESS="ADD_CHECKPOINT_SUCCESS";
export const  UPDATE_CHECKPOINT_SUCCESS="UPDATE_CHECKPOINT_SUCCESS";
export const  UPDATE_CHECKPOINT="UPDATE_CHECKPOINT";
export const ADD_BULK_CHECKPOINTS = 'ADD_BULK_CHECKPOINTS';

export const  addBulkCheckpointsGenerator = (data)=>{
    return {
        type:ADD_BULK_CHECKPOINTS,
        payload:data
    }
};
export const fetchCheckpointGenerator = (data=[])=>{
    return {
        type:FETCH_CHECKPOINT,
        payload:data
    };
};

export const fetchCheckpointSuccessGenerator = (data)=> {
    return {
        type:FETCH_CHECKPOINT_SUCCESS,
        payload:data
    }
};

export const deleteCheckpointGenerator = (data)=> {
    return {
        type:DELETE_CHECKPOINT,
        payload:data
    }
};

export const deleteCheckpointSuccessGenerator = (data)=> {
    return {
        type:DELETE_CHECKPOINT_SUCCESS,
        payload:data
    }
};

export const addCheckpointSuccessGenerator = (data)=> {
    return {
        type:ADD_CHECKPOINT_SUCCESS,
        payload:data
    }
};

export const addCheckpointGenerator = (data)=> {
    return {
        type:ADD_CHECKPOINT,
        payload:data
    }
};

export const updateCheckpointGenerator =(data)=>{
    return {
        type:UPDATE_CHECKPOINT,
        payload:data
    }
};

export const updateCheckpointSuccessGenerator =(data)=>{
    return {
        type:UPDATE_CHECKPOINT_SUCCESS,
        payload:data
    }
};