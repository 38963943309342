import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";

import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  BULK_UPLOAD_INSURANCE_PREMIUM,
  CREATE_INSURANCE_PREMIUM,
  createInsurancePremiumSuccessGenerator, DELETE_INSURANCE_PREMIUM,
  deleteInsurancePremiumSuccessGenerator, EDIT_INSURANCE_PREMIUM,
  editInsurancePremiumSuccessGenerator,
  FETCH_INSURANCE_PREMIUM, fetchInsurancePremiumGenerator,
  fetchInsurancePremiumSuccessGenerator
} from "../../actions/payroll/InsurancePremiumActions";


const fetchInsurancePremiumRequest = async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/insurance-premium',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const createInsurancePremiumRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/insurance-premium',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const editInsurancePremiumRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/insurance-premium/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteInsurancePremiumRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/insurance-premium/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
const bulkUploadInsurancePremiumRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/bulk/upload/insurance-premium',requestParameters,{
    headers:{
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);


function* BulkUploadInsurancePremium({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const formData = Helpers.getFormData(requestParameters);
    const response = yield call(bulkUploadInsurancePremiumRequest, formData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchInsurancePremiumGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchInsurancePremium({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchInsurancePremiumRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchInsurancePremiumSuccessGenerator(rolesById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* createInsurancePremium({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(createInsurancePremiumRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchInsurancePremiumGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editInsurancePremium({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editInsurancePremiumRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchInsurancePremiumGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteInsurancePremium({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteInsurancePremiumRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let rolesById = response.data.data;
        yield put(fetchInsurancePremiumGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}


export function* BulkUploadInsurancePremiumDefault(){
  yield  takeLatest(BULK_UPLOAD_INSURANCE_PREMIUM,BulkUploadInsurancePremium);
}

export function* fetchInsurancePremiumDefault(){
  yield  takeLatest(FETCH_INSURANCE_PREMIUM,fetchInsurancePremium);
}

export function* createInsurancePremiumDefault(){
  yield  takeLatest(CREATE_INSURANCE_PREMIUM,createInsurancePremium);
}

export function* editInsurancePremiumDefault(){
  yield  takeLatest(EDIT_INSURANCE_PREMIUM,editInsurancePremium);
}

export function* deleteInsurancePremiumDefault(){
  yield  takeLatest(DELETE_INSURANCE_PREMIUM,deleteInsurancePremium);
}

export default function* rootSaga() {
  yield all([
    fork(fetchInsurancePremiumDefault),
    fork(createInsurancePremiumDefault),
    fork(editInsurancePremiumDefault),
    fork(deleteInsurancePremiumDefault),
    fork(BulkUploadInsurancePremiumDefault)
  ]);
}
