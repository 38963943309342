import {
  FETCH_COMPANY_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_COMPANY_ACCOUNT_SETTINGS_SUCCESS
} from "../../actions/admin/CompanyAccountSettingsActions";


const INIT_STATE = {
  adminCompanyAccountSettingsByID:{},

};
export default (state=INIT_STATE,action)=>{
  switch (action.type) {
    case FETCH_COMPANY_ACCOUNT_SETTINGS_SUCCESS:
    case UPDATE_COMPANY_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        adminCompanyAccountSettingsByID:action.payload
      };
    default: return state;
  }
}
