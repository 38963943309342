import {
  ADD_LEAVE_SUCCESS,
  DELETE_LEAVE_SUCCESS,
  EDIT_LEAVE_SUCCESS,
  FETCH_LEAVE_DETAILS_SUCCESS,
  FETCH_LEAVES_SUCCESS, GET_DEPARTMENT_SUPERVISORS,
  HIDE_LEAVE_CREATE_FORM,
  HIDE_LEAVE_DELETE_FORM,
  HIDE_LEAVE_EDIT_FORM,
  SHOW_LEAVE_CREATE_FORM,
  SHOW_LEAVE_DELETE_FORM,
  SHOW_LEAVE_EDIT_FORM
} from "../../actions/payroll/LeaveActions";

const INIT_STATE = {
  leavesByID:{},
  showLeaveCreateForm:false,
  showLeaveEditForm:false,
  leaveToEdit:false,
  showLeaveDeleteForm:false,
  leaveToDelete:{},
  leaveDetails:{},
  supervisorsByID:{}
};

export default (state = INIT_STATE, action)=> {
  switch (action.type) {
    case FETCH_LEAVES_SUCCESS:
      return {
        ...state,
        leavesByID: action.payload
      };
    case SHOW_LEAVE_CREATE_FORM:
      return {
        ...state,
        showLeaveCreateForm: true
      };
    case HIDE_LEAVE_CREATE_FORM:
      return {
        ...state,
        showLeaveCreateForm: false
      };
    case SHOW_LEAVE_EDIT_FORM:
      return {
        ...state,
        showLeaveEditForm: true,
        leaveToEdit: action.payload
      };
    case HIDE_LEAVE_EDIT_FORM:
      return {
        ...state,
        showLeaveEditForm: false
      };
    case ADD_LEAVE_SUCCESS:
    case EDIT_LEAVE_SUCCESS:
      let leave_id = action.payload.id;
      return {
        ...state,
        leavesByID: {
          ...state.leavesByID,
          [leave_id]:action.payload
        }
      };
    case SHOW_LEAVE_DELETE_FORM:
      return {
        ...state,
        showLeaveDeleteForm: true,
        leaveToDelete: action.payload
      };
    case HIDE_LEAVE_DELETE_FORM:
      return {
        ...state,
        showLeaveDeleteForm: false
      };
    case DELETE_LEAVE_SUCCESS:
      let leavesByID = {...state.leavesByID};
      delete leavesByID[action.payload.id];
      return {
        ...state,
        leavesByID:leavesByID
      };
    case FETCH_LEAVE_DETAILS_SUCCESS:
      return {
        ...state,
        leaveDetails: action.payload
      };
    case GET_DEPARTMENT_SUPERVISORS:
      return {
        ...state,
        supervisorsByID: action.payload
      }
    default:
      return state;
  }
}
