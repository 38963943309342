import HolidayReducers from "./HolidayReducers";
import CompanyPaymentRate from "./CompanyPaymentRateReducer";
import EmployeeReducer from "./EmployeeReducer";
import LoanCategoryReducer from "./LoanCategoryReducer"
import AdditionalCategoriesReducer from "./AdditionalCategoriesReducer";
import LeaveTypeCategoryReducer from "./LeaveTypeCategoryReducer";
import DeductionCategoryReducer from "./DeductionCategoryReducer";
import ExitReasonReducer from "./ExitReasonReducer";
import ExitReducer from "./ExitReducer";
import EmployeePaymentRateReducer from "./EmployeePaymentRateReducer";
import EmployeeNumberFormatReducer from "./EmployeeNumberFormatReducer";
import PettyCashReducer from "./PettyCashReducer";
import PaidLeaveReducer from "./PaidLeaveReducer";
import AllowanceReducer from "./AllowanceReducer";
import LeaveReducer from "./LeaveReducer";
import DocumentReducer from "./DocumentReducer";
import LoansReducer from "./LoansReducer";
import LeaveDashboardReducer from "./LeaveDashboardReducer";
import LeaveBalanceReducer from "./LeaveBalanceReducer";
import WithdrawalReducer from "./WithdrawalReducer";
import DeductionReducer from "./DeductionReducer";
import SalaryAdvanceReducer from "./SalaryAdvanceReducer";
import AssetReducer from "./AssetReducer";
import ImprestReducer from "./ImprestReducer";
import ExpenseCategoryReducer from "./ExpenseCategoryReducer";
import DocumentCategoryReducer from "./DocumentCategoryReducer";
import PettyCashReceiptReducer from "./PettyCashReceiptReducer";
import PayrollReducer from "./PayrollReducer";
import SmsReducer from "./SmsReducer";
import NotificationReducer from "./NotificationReducer";
import PayrollReportReducer from "./PayrollReportReducer";
import PaySlipReducers from "./PaySlipReducers";
import TrainingReducer from "./TrainingReducer";
import ProductReducer from "./ProductReducer";
import EmployeeRentReducer from "./EmployeeRentReducer";
import FringeBenefitReducer from "./FringeBenefitReducer";
import InsurancePremiumReducer from "./InsurancePremiumReducer";
import MortgageReliefReducer from "./MortgageReliefReducer";
import AssetsCategoryReducer from "./AssetsCategoryReducer";
import EmployeeInvoicesReducer from "./EmployeeInvoicesReducer";
import HelbReducer from "./HelbReducer";

const Payroll = {
  holiday: HolidayReducers,
 company_rate:CompanyPaymentRate,
  employee:EmployeeReducer,
  allowanceCategories:AdditionalCategoriesReducer,
  leaveTypeCategories:LeaveTypeCategoryReducer,
  loanCategory:LoanCategoryReducer,
  deductionCategory:DeductionCategoryReducer,
  exitReason:ExitReasonReducer,
  exit:ExitReducer,
  employee_rate:EmployeePaymentRateReducer,
  employeeNumberFormat:EmployeeNumberFormatReducer,
 leaves_dashboard:LeaveDashboardReducer,
  withdrawal:WithdrawalReducer,
  pettyCash:PettyCashReducer,
  paidLeave:PaidLeaveReducer,
  allowance:AllowanceReducer,
  leave:LeaveReducer,
  document:DocumentReducer,
  loan:LoansReducer,
  leave_balance:LeaveBalanceReducer,
  deduction:DeductionReducer,
 salaryadvance:SalaryAdvanceReducer,
  asset:AssetReducer,
  imprest:ImprestReducer,
  expenseCategory:ExpenseCategoryReducer,
  documentCategory:DocumentCategoryReducer,
  pettyCashReceipt:PettyCashReceiptReducer,
  payroll:PayrollReducer,
  payrollReport:PayrollReportReducer,
  sms:SmsReducer,
  notification:NotificationReducer,
  payslips: PaySlipReducers,
  training:TrainingReducer,
  product:ProductReducer,
  employeeRent:EmployeeRentReducer,
 fringeBenefit:FringeBenefitReducer,
  insurancePrem:InsurancePremiumReducer,
 mortgageRelief:MortgageReliefReducer,
 assetCategory: AssetsCategoryReducer,
 employeeInvoices: EmployeeInvoicesReducer,
 helb:HelbReducer,
};

export default Payroll;
