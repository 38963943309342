import {all, call, fork, put, takeLatest} from "redux-saga/effects";

import axios from "axios";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {Helpers} from "../../../util/Helpers";
import {ADD_HOLIDAY, addHolidaySuccess, DELETE_HOLIDAY, fetchHolidaySuccess,editHolidaySuccess,EDIT_HOLIDAY,deleteHolidaySuccess} from "../../actions/payroll/HolidayAction";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {FETCH_HOLIDAYS} from "../../actions/payroll/HolidayAction";
import {BETA_API_ENDPOINT } from "../../../constants/ActionTypes";

/// add new
const addHolidayRequest = async (requestParameters) =>
  await  axios.post(BETA_API_ENDPOINT+'/setup/holidays',requestParameters)
    .then(response => response)
    .catch(error => error.response);
// delete
const deleteHolidayRequest = async (requestParameters) =>
  await  axios.delete(BETA_API_ENDPOINT+'/setup/holidays/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);
///fetch
const fetchHolidaysRequest = async (requestParameters) =>
  await  axios.get(BETA_API_ENDPOINT+'/setup/holidays',{
    params:requestParameters
  })
    .then(response => response)
    .catch(error => error.response);
///update
const updateHolidayRequest = async (requestParameters) =>
  await axios.put(BETA_API_ENDPOINT+'/setup/holidays/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.message);

function* addHoliday({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID(),company_id:Helpers.authSwitchedCompanyID(),from_v1:1};
    const response = yield call(addHolidayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holiday = response.data.data;
        yield put(addHolidaySuccess(holiday));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* fetchHolidays({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID(),company_id:Helpers.authSwitchedCompanyID(),from_v1:1};
    const response = yield call(fetchHolidaysRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holidays = response.data.data;
        yield put(fetchHolidaySuccess(holidays));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* updateHoliday({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID(),company_id:Helpers.authSwitchedCompanyID(),from_v1:1};
    const response = yield call(updateHolidayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holiday = response.data.data;
        yield put(editHolidaySuccess(holiday));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deleteHoliday({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID(),company_id:Helpers.authSwitchedCompanyID(),from_v1:1};
    const response = yield call(deleteHolidayRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let holiday = response.data.data;
        yield put(deleteHolidaySuccess(holiday));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchHolidaysDefault() {
  yield takeLatest(FETCH_HOLIDAYS,fetchHolidays)
}
export function* addHolidayDefault() {
  yield takeLatest(ADD_HOLIDAY, addHoliday);
}
export function* deleteHolidayDefault() {
  yield takeLatest(DELETE_HOLIDAY,deleteHoliday)
}
export function* updateHolidayDefault() {
  yield takeLatest(EDIT_HOLIDAY,updateHoliday)
}

export default function* rootSaga() {
  yield all([fork(addHolidayDefault),fork(deleteHolidayDefault),fork(fetchHolidaysDefault),fork(updateHolidayDefault)]);
}
