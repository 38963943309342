import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {takeLatest} from "redux-saga/effects";
import {
  FETCH_USERS,
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  fetchUsersSuccessGenerator,
  deleteUserSuccessGenerator,
  transferUserSuccessGenerator,
  deactivateUserSuccessGenerator,
  TRANSFER_USER,
  DEACTIVATE_USER,
  activateUserSuccessGenerator,
  ACTIVATE_USER,
  UPDATE_USER_PROFILE_SETTING,
  fetchUsersGenerator,
  usersWithCertainPermissionSuccessGenerator, USERS_WITH_CERTAIN_PERMISSION
} from "../../actions/common/UserActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {AUTH_USER_KEY} from "../../../constants/SessionKeys";

const UpdateUsersProfileRequest= async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/home/user/update/profile',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const fetchUsersRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/users',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addUserRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/invitations',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const deleteUserRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/home/invitations/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editUserRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/home/user/role/assign',requestParameters)
    .then(response => response)
    .catch(error => error.response);

const transferDeactivateUserRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+requestParameters.url,requestParameters)
    .then(response => response)
    .catch(error => error.response);

const fetchUsersWithCertainPermissionRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/users/with/action',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

function* fetchUsersWithCertainPermission({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID(),url:'/admin/user/branch/transfer'};
    const response = yield call(fetchUsersWithCertainPermissionRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let users = response.data.data;
        yield put(usersWithCertainPermissionSuccessGenerator(users));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* updateUserProfile({type, payload}) {
  yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(UpdateUsersProfileRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
              let user = response.data.data;
              let currentUser = Helpers.getSession().user;

              if(currentUser.email === user.email){
                //TO DO
                let authState=currentUser;
                authState.person.title=user.person.title;
                authState.person.first_name=user.person.first_name;
                authState.person.othernames=user.person.othernames;
                authState.person.surname=user.person.surname;
                authState.address.mobile_no=user.address.mobile_no;
                authState.address.phone_no=user.address.phone_no;
                authState.address.country_id=user.address.country_id;
                Helpers.saveItem(AUTH_USER_KEY, {user:authState});
                //TO DO
              }else{
                yield put(showMessageGenerator("Email changed, please login.",'warning'));
                Helpers.clearAuthData();
                yield put(userSignOutSuccessGenerator());
              }
              yield put(showMessageGenerator(response.data.message));

            } else {
                yield put(showMessageGenerator(response.data.message));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}

function* transferUser({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID(),url:'/admin/user/branch/transfer'};
    const response = yield call(transferDeactivateUserRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let usersById = response.data.data;
        yield put(transferUserSuccessGenerator(usersById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* deactivateUser({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID(),url:'/admin/user/deactivate/allowed-company'};
    const response = yield call(transferDeactivateUserRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let usersById = response.data.data;
        yield put(deactivateUserSuccessGenerator(usersById));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* activateUser({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID(),url:'/admin/user/activate/allowed-company'};
    const response = yield call(transferDeactivateUserRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let usersById = response.data.data;
        yield put(activateUserSuccessGenerator(usersById));
          yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  }catch (e) {
    yield put(showMessageGenerator(e,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* fetchUsers({type, payload}) {
  yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchUsersRequest, requestParameters);

        if (response.status === 200 || response.status === 201) {
        if (response.data.success) {
            let usersById = response.data.data;
            yield put(fetchUsersSuccessGenerator(usersById));
        } else {
            yield put(showMessageGenerator(response.data.message,'error'));
        }
        }else if(response.status === 401){
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
        }else {
        yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
  yield put(hideAppLoaderGenerator(type));
}


function* addUser({type, payload}) {
  yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addUserRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
        if (response.data.success) {
            /*let user = response.data.data;*/
            yield put(fetchUsersGenerator());
            yield put(showMessageGenerator(response.data.message,'success'));
        } else {
            yield put(showMessageGenerator(response.data.message));
        }
        }else if(response.status === 401){
        Helpers.clearAuthData();
        yield put(userSignOutSuccessGenerator());
        }else {
        yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
  yield put(hideAppLoaderGenerator(type));
}


    function* deleteUser({type, payload}) {
      yield put(showAppLoaderGenerator(type));
      try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteUserRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
          if (response.data.success) {
            let user = response.data.data;
            yield put(deleteUserSuccessGenerator(user));
            yield put(showMessageGenerator(response.data.message,'success'));
          } else {
            yield put(showMessageGenerator(response.data.message,'error'));
          }
        }else if(response.status === 401){
          Helpers.clearAuthData();
          yield put(userSignOutSuccessGenerator());
        }else {
          yield put(showMessageGenerator(response.statusText,'error'));
        }
      } catch (error) {
        yield put(showMessageGenerator(error,'error'));
      }
      yield put(hideAppLoaderGenerator(type));
    }

  function* editUser({type, payload}) {
    yield put(showAppLoaderGenerator(type));
        try {
          let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
          const response = yield call(editUserRequest, requestParameters);
          if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
              yield put(fetchUsersGenerator());
              yield put(showMessageGenerator(response.data.message,'success'));
            } else {
              yield put(showMessageGenerator(response.data.message,'error'));
            }
          }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
          }else {
            yield put(showMessageGenerator(response.statusText,'error'));
          }
        } catch (error) {
          yield put(showMessageGenerator(error,'error'));
        }
    yield put(hideAppLoaderGenerator(type));
   }

export function* fetchUsersDefault() {
    yield takeLatest(FETCH_USERS, fetchUsers);
}

export function* addUserDefault() {
    yield takeLatest(ADD_USER, addUser);
}

export function* updateUserProfileDefault() {
    yield takeLatest(UPDATE_USER_PROFILE_SETTING, updateUserProfile);
}

export function* deleteUserDefault() {
    yield takeLatest(DELETE_USER, deleteUser);
}
export function* transferUserDefault() {
  yield takeLatest(TRANSFER_USER, transferUser);
}
export function* deactivateUserDefault() {
  yield takeLatest(DEACTIVATE_USER, deactivateUser);
}
export function* activateUserDefault() {
  yield takeLatest(ACTIVATE_USER, activateUser);
}
export function* editUserDefault() {
    yield takeLatest(EDIT_USER, editUser);
}
export function* fetchUsersWithCertainPermissionDefault() {
    yield takeLatest(USERS_WITH_CERTAIN_PERMISSION, fetchUsersWithCertainPermission);
}
export default function* rootSaga(){
    yield all([
        fork(fetchUsersDefault),
        fork(addUserDefault),
        fork(deleteUserDefault),
        fork(transferUserDefault),
        fork(deactivateUserDefault),
        fork(activateUserDefault),
        fork(editUserDefault),
        fork(updateUserProfileDefault),
        fork(fetchUsersWithCertainPermissionDefault)
    ]);
}
