import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helpers } from "../util/Helpers";
import BackGroundLoader from "../components/common/BackGroundLoader";
import {withStyles} from '@material-ui/core/styles';
import {
    forgotPasswordGenerator,
    resendVerifyEmailGenerator, userSignOutGenerator,
    verifyEmailOnRegisterGenerator
} from '../appRedux/actions/AuthActions';

const useStyles = theme => ({
    buttonProgress: {
        color: '#fff',
        marginRight: 5,
    },
    eye: {
        cursor: 'pointer',
    },

});
class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: props.location.state.data ? props.location.state.data.email : props.authUser.email || '',
            buttonLoading:false,
            code:'',
            data:props.location.state.data ? props.location.state.data : props.authUser || props.data
        };
    }
    resendCode =() => {
        let {email,data}=this.state;
        this.props.resendVerifyEmailGenerator({
            email,
            data
        });
    };

    handleSignOut = () => {
        Helpers.clearAuthData();
        this.props.userSignOutGenerator();
    };

    render() {
        const {
            email,
            buttonLoading,
        } = this.state;

        const RestClicked = () => {
            this.setState({   buttonLoading:true});
            this.props.verifyEmailOnRegisterGenerator(this.state);
        };

        const {classes} = this.props;
        return (
          <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
              <div className="app-login-main-content">
                  {
                      Helpers.loading(this.props)
                      && (
                        <BackGroundLoader />
                      )
                  }

                  <div className="app-logo-content d-flex align-items-center justify-content-center">
                      <Link className="logo-lg" to="/" title="">
                          <img src={require('assets/images/logo.png')} alt="" title="" style={{ height:"40px"}} />
                      </Link>
                  </div>

                  <div className="app-login-content">
                      <div className="app-login-header mb-1 text-center text-muted">
                          <p><IntlMessages id="appModule.verifyEmail" /></p>
                      </div>

                      <div className="app-login-form">
                          <form>
                              <fieldset>
                                  <TextField
                                    label="Email Address"
                                    placeholder="Email Address"
                                    fullWidth
                                    margin="normal"
                                    disabled
                                    defaultValue={email}
                                    //className="mt-1 my-sm-3"
                                  />
                                  <TextField
                                    label="Verification Code"
                                    placeholder="Verification Code"
                                    fullWidth
                                    onChange={event => this.setState({ code: event.target.value })}
                                    margin="normal"
                                    className="mt-0 my-sm-3"
                                  />
                                  <div className="d-flex align-items-center justify-content-between">
                                      <Button
                                        onClick={RestClicked}
                                        variant="contained"
                                        color="primary" size="small"
                                        disabled={buttonLoading&&Helpers.loading(this.props)}>
                                          {(Helpers.loading(this.props)&&buttonLoading)&&<CircularProgress size={24} className={classes.buttonProgress} />}
                                          <IntlMessages id="appModule.verify" />
                                      </Button>

                                      <a onClick={this.resendCode} style={{ color: '#3cb543', cursor: 'pointer' }} className="verify-email-link"><IntlMessages id="appModule.resendPin" /></a>
                                  </div>
                                  <div className="mb-3 d-flex align-items-center justify-content-end">
                                      <a onClick={this.handleSignOut} style={{ color: '#3cb543', cursor: 'pointer' }} className="verify-email-link" href={'/signin'}><IntlMessages id="appModule.signOut" /></a>
                                  </div>
                              </fieldset>
                          </form>
                      </div>
                  </div>

              </div>
          </div>
        );
    }
}

const mapStateToProps = ({auth, general}) => {
    const {alertMessage, showMessage,appLoading } = general;
    const {authUser} = auth;
    return {
        alertMessage,
        showMessage,
        authUser,
        appLoading
    };
};
export default withStyles(useStyles)( connect(mapStateToProps,{verifyEmailOnRegisterGenerator,resendVerifyEmailGenerator, userSignOutGenerator})(VerifyEmail));

