export const FETCH_SCHEDULE_REPORTS = 'FETCH_SCHEDULE_REPORTS';
export const FETCH_SCHEDULE_REPORTS_SUCCESS = 'FETCH_SCHEDULE_REPORTS_SUCCESS';

export const fetchScheduleReportsGenerator = (data)=>{
    return {
        type:FETCH_SCHEDULE_REPORTS,
        payload:data
    }
};
export const fetchScheduleReportsSuccessGenarator = (data)=>{
    return {
        type:FETCH_SCHEDULE_REPORTS_SUCCESS,
        payload:data
    }
};