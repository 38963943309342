import {
  FETCH_FIREBASE_USERS, FETCH_FIREBASE_USERS_SUCCESS
} from "../../actions/admin/MobileNotificationsAction";

const INIT_STATE = {
  firebaseUsersData :{}
} ;
export default (state=INIT_STATE,action) =>{
  switch (action.type) {
    case FETCH_FIREBASE_USERS_SUCCESS:
      return{
        ...state,
        firebaseUsersData: action.payload
      };
    default:
      return state;
  }
}