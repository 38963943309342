import axios from "axios";
import { API_ENDPOINT } from "../../../constants/ActionTypes";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import {
  FETCH_ADMIN_STATUTORY_FILLING_REQUESTS,
  fetchAdminStatutoryFillingRequestsGenerator,
  fetchAdminStatutoryFillingRequestsSuccessGenerator, UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS
} from "../../actions/admin/StatutoryFillingRequestsActions";

const fetchAdminStatutoryFillingRequestsRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/tozza/statutory-filling-requests',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editAdminStatutoryFillingRequestsRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/tozza/statutory-filling-requests/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchAdminStatutoryFillingRequests({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAdminStatutoryFillingRequestsRequest, requestParameters);

    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let statutoryRequests = response.data.data;
        console.log('statutoryRequests >> ', statutoryRequests);
        yield put(fetchAdminStatutoryFillingRequestsSuccessGenerator(statutoryRequests));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* editAdminStatutoryFillingRequests({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(),auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAdminStatutoryFillingRequestsRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(fetchAdminStatutoryFillingRequestsGenerator(payload.params));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* fetchAdminStatutoryFillingRequestsDefault() {
  yield takeLatest(FETCH_ADMIN_STATUTORY_FILLING_REQUESTS, fetchAdminStatutoryFillingRequests)
}

export function* editAdminStatutoryFillingRequestsDefault() {
  yield takeLatest(UPDATE_ADMIN_STATUTORY_FILLING_REQUESTS, editAdminStatutoryFillingRequests);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAdminStatutoryFillingRequestsDefault),
    fork(editAdminStatutoryFillingRequestsDefault)
  ]);
}
