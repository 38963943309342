import React from 'react';

import Loader from 'react-loader-spinner'
export default class OnboardingLoader extends React.Component {
    //other logic
    render() {
        return(
          <Loader
            type="Bars"
            color="#3D8E3C"
            height="100"
            width="40"
          />
        );
    }
}
