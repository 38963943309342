export const FETCH_COMPANY_LOAN_RATES = 'FETCH_COMPANY_LOAN_RATES';
export const FETCH_COMPANY_LOAN_RATES_SUCCESS = 'FETCH_COMPANY_LOAN_RATES_SUCCESS';

export const EDIT_COMPANY_LOAN_RATES = 'EDIT_COMPANY_LOAN_RATES';
export const EDIT_COMPANY_LOAN_RATES_SUCCESS = 'EDIT_COMPANY_LOAN_RATES_SUCCESS';

export const ADD_COMPANY_LOAN_RATES = 'ADD_COMPANY_LOAN_RATES';
export const ADD_COMPANY_LOAN_RATES_SUCCESS = 'ADD_COMPANY_LOAN_RATES_SUCCESS';

export const FETCH_ONE_COMPANY_LOAN_RATE = 'FETCH_ONE_COMPANY_LOAN_RATE';
export const FETCH_ONE_COMPANY_LOAN_RATE_SUCCESS = 'FETCH_ONE_COMPANY_LOAN_RATE_SUCCESS';

export const fetchOneCompanyLoanRateGenerator =(payload=[])=>{
  return {
    type:FETCH_ONE_COMPANY_LOAN_RATE,
    payload
  }
}

export const fetchOneCompanyLoanRateSuccessGenerator =(payload=[])=>{
  return {
    type:FETCH_ONE_COMPANY_LOAN_RATE_SUCCESS,
    payload
  }
}

export const fetchCompaniesLoanRatesGenerator = (payload=[])=>{
  return {
    type:FETCH_COMPANY_LOAN_RATES,
    payload
  }
}
export const fetchCompaniesLoanRatesSuccessGenerator = (payload=[])=>{
  return {
    type:FETCH_COMPANY_LOAN_RATES_SUCCESS,
    payload
  }
}

export const editCompaniesLoansRatesGenerator = (payload=[])=>{
  return {
    type:EDIT_COMPANY_LOAN_RATES,
    payload
  }
}
export const editCompaniesLoansRatesSuccessGenerator = (payload=[])=>{
  return {
    type:EDIT_COMPANY_LOAN_RATES_SUCCESS,
    payload
  }
}

export const addCompaniesLoanRatesGenerator = (payload=[])=>{
  return{
    type:ADD_COMPANY_LOAN_RATES,
    payload
  }
}
export const addCompaniesLoanRatesSuccessGenerator = (payload=[])=>{
  return{
    type:ADD_COMPANY_LOAN_RATES_SUCCESS,
    payload
  }
}