import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";

import {
  addAssetSuccessGenerator,
  deleteAssetSuccessGenerator,
  editAssetSuccessGenerator,
  fetchAssetsSuccessGenerator
} from "../../actions/payroll/AssetActions";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
  ADD_ASSET_CATEGORY, addAssetCategorySuccessGenerator,
  DELETE_ASSET_CATEGORY, deleteAssetCategorySuccessGenerator, EDIT_ASSET_CATEGORY, editAssetCategorySuccessGenerator,
  FETCH_ASSETS_CATEGORIES, fetchAssetsCategorySuccessGenerator
} from "../../actions/payroll/AssetCategoryActions";
const fetchAssetsCategoryRequest= async (requestParameters) =>
  await  axios.get(API_ENDPOINT+'/hrm/asset-categories',{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const addAssetCategoryRequest = async (requestParameters) =>
  await  axios.post(API_ENDPOINT+'/hrm/asset-categories',requestParameters,{headers: {
      'content-type': `multipart/form-data`
    }})
    .then(response => response)
    .catch(error => error.response);

const deleteAssetCategoryRequest = async (requestParameters) =>
  await  axios.delete(API_ENDPOINT+'/hrm/asset-categories/'+requestParameters.id,{params:requestParameters})
    .then(response => response)
    .catch(error => error.response);

const editAssetCategoryRequest = async (requestParameters) =>
  await  axios.put(API_ENDPOINT+'/hrm/asset-categories/'+requestParameters.id,requestParameters)
    .then(response => response)
    .catch(error => error.response);

function* fetchAssetCategories({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(fetchAssetsCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let assets = response.data.data;
        yield put(fetchAssetsCategorySuccessGenerator(assets));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* addAssetCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const sentData = Helpers.getFormData(requestParameters);
    const response = yield call(addAssetCategoryRequest, sentData);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(addAssetCategorySuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* deleteAssetCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(deleteAssetCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(deleteAssetCategorySuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
function* editAssetCategory({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(editAssetCategoryRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        let asset = response.data.data;
        yield put(editAssetCategorySuccessGenerator(asset));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}
export function* fetchAssetsDefault() {
  yield takeLatest(FETCH_ASSETS_CATEGORIES, fetchAssetCategories)
}
export function* addAssetCategoryDefault() {
  yield takeLatest(ADD_ASSET_CATEGORY, addAssetCategory);
}
export function* deleteAssetCategoryDefault() {
  yield takeLatest(DELETE_ASSET_CATEGORY, deleteAssetCategory);
}
export function* editAssetCategoryDefault() {
  yield takeLatest(EDIT_ASSET_CATEGORY, editAssetCategory);
}
export default function* rootSaga() {
  yield all([fork(fetchAssetsDefault),
    fork(addAssetCategoryDefault),
    fork(deleteAssetCategoryDefault),
    fork(editAssetCategoryDefault),
  ]);
}
