import {
    ASSIGN_EMPLOYEE_TO_TRAINING_SUCCESS,
    CREATE_TRAINING_SUCCESS,
    DELETE_TRAINING_SUCCESS,
    EDIT_TRAINING_SUCCESS,
    FETCH_TRAINING_SUCCESS,
    GET_TRAINING_KEYED_SUCCESS,
    UPLOAD_TRAINING_CERTIFICATE,
    UPLOAD_TRAINING_CERTIFICATE_SUCCESS
} from "../../actions/payroll/TrainingActions";

const INIT_STATUS={
    trainingByID:[],
    trainingKeyed:[]
}

export default (state=INIT_STATUS,action)=>{
    let training ;
    switch (action.type){
        case UPLOAD_TRAINING_CERTIFICATE_SUCCESS:
        case EDIT_TRAINING_SUCCESS:
        case CREATE_TRAINING_SUCCESS:
            training = {...state.trainingByID};
            training[action.payload.id] = action.payload
          return {
              ...state,
              trainingByID: training
          }
        case ASSIGN_EMPLOYEE_TO_TRAINING_SUCCESS:
        case DELETE_TRAINING_SUCCESS:
            training = {...state.trainingByID};
            delete training[action.payload.id];
            return {
                ...state,
                leavesByID:training
            };
        case FETCH_TRAINING_SUCCESS:
            return {
                ...state,
                trainingByID: action.payload
            }
        case GET_TRAINING_KEYED_SUCCESS:
            return {
                ...state,
                trainingKeyed:action.payload
            }

        default:return state;
    }
}
