import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { confirmCardPaymentTransactionGenerator, createCardPaymentTransactionRecordGenerator } from '../../../appRedux/actions/payouts/RechargePayoutActions';
import { Helpers as Helper, Helpers } from "../../../util/Helpers";
import BackGroundLoader from "../../../components/common/BackGroundLoader";
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import { userSignOutGenerator } from "../../../appRedux/actions/AuthActions";
import FlutterWaveButton from "../../../components/common/flutterwavePaymentButton";

const styles = theme => ({
  card: {
    margin: 10,
    padding: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  title: {
    display: 'flex'
  },
  payBtn: {
    color: '#fff',
    backgroundColor: '#4caf50',
    padding: '4px 8px',
    minWidth: '64px',
    fontSize: '0.8125rem',
    borderRadius: '4px',
    textDecoration: 'capitalize',
    border: 'none',
    boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
    lineHeight: 1.75,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    display: 'none'
  }
});

const config = {
    public_key: 'FLWPUBK_TEST-c62874fbdd19e492f7f2fe2bcd94e971-X',
    tx_ref: Date.now(),
    amount: 0,
    currency: 'KES',
    payment_options: 'card',
    customer: {
        email: '',
        phonenumber: '',
        name: ''
    },
    customizations: {
        title: 'Workpay',
        logo: 'https://my.workpay.co.ke/assets/images/logo.png'
    }
};

const closePaymentModal = () => {
    document.getElementsByName('checkout')[0].setAttribute('style',
        'position:fixed;top:0;left:0;z-index:-1;border:none;opacity:0;pointer-events:none;width:100%;height:100%;');
    document.body.style.overflow = '';
};

class Flutterwave extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      amount_card: this.props.amount_card || '',
      type: 'card',
      page:this.props.page,
      head_office_id: this.props.head_office_id || Helpers.authCurrentHeadOfficeID,
      branch_id: this.props.branch_id || Helpers.authSwitchedCompanyID(),
      disablePaymentBtn: false,
      requestingPaymentReference: false,
      referenceNumber: false
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.paymentConfirmed && this.props.paymentConfirmed !== prevProps.paymentConfirmed) {
      this.setState({disablePaymentBtn: true, amount_card: ''});
    }

    if(this.props.requestingConfirmation && this.props.requestingConfirmation !== prevProps.requestingConfirmation) {
      this.setState({disablePaymentBtn: true});
    }

    if(this.props.requestingPaymentReference !== prevProps.requestingPaymentReference) {
      let state = {requestingPaymentReference: this.props.requestingPaymentReference};
      if(this.props.referenceNumber) {
        state.referenceNumber = this.props.referenceNumber;
      }
      this.setState({...state}, () => {
        if(this.state.referenceNumber) {
          this.doCardPayment();
        }
      });
    }
  }

  fwConfig = {
    ...config,
    text: 'Pay with Flutterwave!',
    callback: (response) => {
        closePaymentModal();
        this.props.confirmCardPaymentTransactionGenerator({
            transaction_id: response.transaction_id,
            transaction_ref: response.tx_ref
        });
    }
  };

  formValidate = () =>{
    const fieldsToValidate = { branch_id:{} };

    if(!Helper.formValidations(fieldsToValidate,this.state)){
      return false;
    }else{
      return true;
    }
  };

  updateState = (e) => {
    const { id, name, value } = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState }, () => {
        if(this.state.amount_card && Number(this.state.amount_card) > 0) {
            this.setState({disablePaymentBtn: false});
        } else {
            this.setState({disablePaymentBtn: true});
        }
    });
  };

  initiateCardPayment = () => {
    this.props.createCardPaymentTransactionRecordGenerator({
      amount: this.state.amount_card,
      branch_id: this.state.branch_id
    });
  }

  doCardPayment = () => {
    this.btnRef.click();
  }

  proceed = () => {
    this.setState({
      page:null
    }) ;
    if (!this.formValidate()){
        return ;
    }
  };

  updateState1 = (name, e) => {
    const newState = Object.assign({}, this.state);
    newState[name] = e.value;
    this.setState({ ...newState });
  };

  render() {
    const validationMessage='This field is required!';
    const { classes,companiesByID} = this.props;
    const { branch_id,head_office_id,disablePaymentBtn, amount_card, requestingPaymentReference, referenceNumber } = this.state;
    const branches = Helpers.companyBranches(companiesByID, head_office_id);
    const branch_idd = this.state.branch_id;

    let defaultBranch = Helpers.recordFromNonIndexedDataWithDefault(branches, branch_idd, null);
    const accDetails = {...defaultBranch};

    if(defaultBranch && defaultBranch.id === head_office_id){
      defaultBranch = {label:'Head Office', value:defaultBranch.id};
    }else if(defaultBranch){
      defaultBranch = {label:defaultBranch.name, value:defaultBranch.id};
    }else{}
    this.fwConfig.amount = amount_card;
    this.fwConfig.customer = {
        email: accDetails.email,
        phonenumber: accDetails.mobile || '',
        name: accDetails.ownername || ''
    };
    this.fwConfig.tx_ref = referenceNumber;

    return (
      <div className={classes.card}>
        {
         (Helpers.loading(this.props) || this.props.requestingPaymentReference) && <BackGroundLoader />
        }
        <br/>
          <div className="profile-container">
            <div className="row">
              <div className="col-12">
                <FormControl fullWidth placeholder="Select Type" margin="normal" required>
                  <InputLabel htmlFor="branch_id" className="select-label">
                    Select Branch
                  </InputLabel>
                  <Select
                    onChange={this.updateState1.bind(this,'branch_id')}
                    name="branch_id"
                    id="branch_id"
                    value={defaultBranch}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    options={Helpers.renderBranchMenuItems(branches)}
                    isSearchable={true}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      color: 'green',
                      colors: {
                        ...theme.colors,
                        primary: '#3CB534',
                        primary25: '#E2F4E0',
                        primary50: '#E2F4E0'
                      }
                    })}
                  />
                  <span className="error text-danger">{this.proceed  && branch_id === "" ? validationMessage : ' '}</span>
                </FormControl>
              </div>
              <div className="col-12">
                <TextField
                  required
                  id="amount_card"
                  label="Amount"
                  value={amount_card}
                  fullWidth
                  placeholder="Amount"
                  margin="normal"
                  onChange={this.updateState}
                />
              </div>
              <div className="col-12">
              <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.initiateCardPayment}
                  disabled={disablePaymentBtn || requestingPaymentReference}>
                  {this.props.requestingConfirmation ? 'Confirming payment...' : 'Proceed with payment'}
                </Button>
                <FlutterWaveButton ref={ref => this.btnRef = ref} {...this.fwConfig} className={classes.payBtn} disabled={disablePaymentBtn || requestingPaymentReference} />
                {
                  this.state.page &&[
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{textDecoration: 'none', marginTop: 10, marginLeft: 50 }}
                      onClick={() => Helpers.choosePlanRedirect()}>
                      Change current plan
                    </Button>,
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{textDecoration: 'none', marginTop: 10, marginLeft: 50 }}
                      onClick={() => {
                        Helper.clearAuthData();
                       return this.props.userSignOutGenerator();
                      }}>
                      Sign Out
                    </Button>,
                  ]
                }
              </div>
            </div>
          </div>
      </div>
    );
  }
}
const mapStateToProps = ({recharge, general,company, auth}) => {
  const {companiesByID} = company;
  let head_office_id = Helpers.authCurrentHeadOfficeID()
  const {
    mobile, account, path, checkout, type, paymentConfirmed, requestingConfirmation, referenceNumber, requestingPaymentReference
  } = recharge;
  const {appLoading} = general;

  return {
    mobile, account, path, checkout, type, appLoading,head_office_id,companiesByID, auth, paymentConfirmed, requestingConfirmation, requestingPaymentReference, referenceNumber
  };
};
export default withStyles(styles)(connect(mapStateToProps, {confirmCardPaymentTransactionGenerator, createCardPaymentTransactionRecordGenerator, userSignOutGenerator})(Flutterwave));
