import React from 'react';
import {useFlutterwave} from 'flutterwave-react-v3';

const FlutterWaveButton = React.forwardRef(({
  text,
  className,
  children,
  callback,
  onClose,
  disabled,
  ...config
}, ref) => {
  const handleFlutterwavePayment = useFlutterwave(config);

  return (
    <button
      ref={ref}
      disabled={disabled}
      className={className}
      onClick={() => handleFlutterwavePayment({ callback, onClose })}
    >
      {text || children}
    </button>
  );
});

export default FlutterWaveButton;
