import {all, call, fork, put} from "redux-saga/effects";
import axios from "axios";
import {Helpers} from "../../../util/Helpers";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import {takeLatest} from "redux-saga/effects";
import {API_ENDPOINT} from "../../../constants/ActionTypes";
import {
    ADD_BULK_CHECKPOINTS,
    ADD_CHECKPOINT,
    DELETE_CHECKPOINT, FETCH_CHECKPOINT,
    fetchCheckpointSuccessGenerator, UPDATE_CHECKPOINT
} from "../../actions/attendance/CheckPointActions";
import {userSignOutSuccessGenerator} from "../../actions/AuthActions";
import {SELECTED_PROJECT_KEY} from "../../../constants/SessionKeys";
import {
    fetchProjectGenerator
} from "../../actions/attendance/ProjectActions";

const fetchCheckPointsRequest= async (requestParameters) =>
    await  axios.get(API_ENDPOINT+'/attendance/checkpoints',{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const addCheckPointRequest = async (requestParameters) =>
    await  axios.post(API_ENDPOINT+'/attendance/checkpoints',requestParameters)
        .then(response => response)
        .catch(error => error.response);

const deleteCheckPointRequest = async (requestParameters) =>
    await  axios.delete(API_ENDPOINT+'/attendance/checkpoints/'+requestParameters.id,{params:requestParameters})
        .then(response => response)
        .catch(error => error.response);

const editCheckPointRequest = async (requestParameters) =>
    await  axios.put(API_ENDPOINT+'/attendance/checkpoints/'+requestParameters.id,requestParameters)
        .then(response => response)
        .catch(error => error.response);

const addBulkCheckpointsRequest = async (requestData)=>
    await axios.post(API_ENDPOINT+'/attendance/checkpoints/bulk',requestData,{headers: {
        'content-type': `multipart/form-data`
      }})
        .then(response => response)
        .catch(error=>error.response);

function* addBulkCheckpoints({type,payload}){
    yield put(showAppLoaderGenerator(type));
    try{
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const formData = Helpers.getFormData(requestParameters);
        const response = yield call(addBulkCheckpointsRequest, formData);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                try{
                    let info = {};
                    try{
                        info = JSON.parse(Helpers.getItem(SELECTED_PROJECT_KEY,{}));
                    }catch (e) {}
                    if(Number(response.data.data.id) === Number(info.id)){
                        info.employeeIDs = response.data.data.employeeIDs;
                        Helpers.saveItem(SELECTED_PROJECT_KEY, JSON.stringify(info));
                    }
                }catch (e) {}
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
               yield put(showMessageGenerator(response.data.message,'error'));
             }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    }catch(error){
      yield put(showMessageGenerator(response.data.message,'error'))
    }
    yield put(hideAppLoaderGenerator(type));
}

function* fetchCheckPoints({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(fetchCheckPointsRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                let rolesById = response.data.data;
                yield put(fetchCheckpointSuccessGenerator(rolesById));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* addCheckPoint({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(addCheckPointRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                try{
                    let info = {};
                    try{
                        info = JSON.parse(Helpers.getItem(SELECTED_PROJECT_KEY,{}));
                    }catch (e) {}
                    if(Number(response.data.data.id) === Number(info.id)){
                        info.checkpoint = response.data.data.checkpoint;
                        Helpers.saveItem(SELECTED_PROJECT_KEY, JSON.stringify(info));
                    }
                }catch (e) {}
                yield put(fetchProjectGenerator()); //done to make sure if a check point is added from assignments all projects are fetched to update the checkpoint count
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* deleteCheckPoint({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
        const response = yield call(deleteCheckPointRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                try{
                    let info = {};
                    try{
                        info = JSON.parse(Helpers.getItem(SELECTED_PROJECT_KEY,{}));
                    }catch (e) {}
                    if(Number(response.data.data.id) === Number(info.id)){
                        info.checkpoint = response.data.data.checkpoint;
                        Helpers.saveItem(SELECTED_PROJECT_KEY, JSON.stringify(info));
                    }
                }catch (e) {}
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
function* editCheckPoint({type, payload}) {
    yield put(showAppLoaderGenerator(type));
    try {
        let requestParameters = {...payload, token:Helpers.token()};
        const response = yield call(editCheckPointRequest, requestParameters);
        if (response.status === 200 || response.status === 201) {
            if (response.data.success) {
                try{
                    let info = {};
                    try{
                        info = JSON.parse(Helpers.getItem(SELECTED_PROJECT_KEY,{}));
                    }catch (e) {}
                    if(Number(response.data.data.id) === Number(info.id)){
                        info.checkpoint = response.data.data.checkpoint;
                        Helpers.saveItem(SELECTED_PROJECT_KEY, JSON.stringify(info));
                    }
                }catch (e) {}
                yield put(showMessageGenerator(response.data.message,'success'));
            } else {
                  yield put(showMessageGenerator(response.data.message,'error'));
            }
        }else if(response.status === 401){
            Helpers.clearAuthData();
            yield put(userSignOutSuccessGenerator());
        }else {
            yield put(showMessageGenerator(response.statusText,'error'));
        }
    } catch (error) {
        yield put(showMessageGenerator(error,'error'));
    }
    yield put(hideAppLoaderGenerator(type));
}
export function* addBulkCheckpointDefault() {
    yield takeLatest(ADD_BULK_CHECKPOINTS,addBulkCheckpoints);
}
export function* fetchCheckPointsDefault() {
    yield takeLatest(FETCH_CHECKPOINT, fetchCheckPoints)
}
export function* addCheckPointDefault() {
    yield takeLatest(ADD_CHECKPOINT, addCheckPoint);
}
export function* deleteCheckPointDefault() {
    yield takeLatest(DELETE_CHECKPOINT, deleteCheckPoint);
}
export function* editCheckpointDefault() {
    yield takeLatest(UPDATE_CHECKPOINT, editCheckPoint);
}
export default function* rootSaga() {
    yield all([fork(fetchCheckPointsDefault),
        fork(addCheckPointDefault),
        fork(deleteCheckPointDefault),
        fork(editCheckpointDefault),
        fork(addBulkCheckpointDefault)
    ]);
}
