import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import withRouter from 'react-router/es/withRouter';
import { switchCompanyGenerator } from '../../appRedux/actions/common/CompanyActions';
import { Helpers } from '../../util/Helpers';
import {
  AUTH_HEAD_OFFICE_ID_KEY,
  AUTH_SWITCHED_APP,
  AUTH_SWITCHED_COMPANY_ID_KEY,
  SWITCHED_COUNTRY_KEY
} from "../../constants/SessionKeys";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

const styles = theme => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  title: {
    display: 'flex'
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  fab: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  button: {
    // background: 'linear-gradient(45deg, #dcdcdc 30%, #dcdcdc 90%)',
    border: 0,
    borderRadius: 150,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    size: 'small',
    maxWidth: 'auto',
    maxHeight: '20px',
    minWidth: 'auto',
    minHeight: '20px'
  }
});

class CompanySwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      head_office_id: this.props.currentHeadOfficeID,
      branch_id:null,
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCompanySettingsLink = () => {
    this.setState({ open: false });
    let pathname = window.location.pathname;
    if(pathname.startsWith('/attendance')){
      this.props.history.push('/attendance/companies');
    }
    if(pathname.startsWith('/hrm')){
      this.props.history.push('/hrm/companies');
    }
    if(pathname.startsWith('/payouts')){
      this.props.history.push('/payouts/companies');
    }
    if(pathname.startsWith('/appraisals')){
      this.props.history.push('/appraisals/companies');
    }
    this.props.history.push('/common/companies');
  };

  handleSubmit = () => {
    const data = {...this.state};
    const company = {id:data.branch_id?data.branch_id:data.head_office_id};
    this.setState({ open: false });
    this.props.switchCompanyGenerator(company);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  updateState = (e) => {
    const {id, name, value} = e.target;
    const newState = Object.assign({}, this.state);
    const key = id || name;
    newState[key] = value;
    this.setState({ ...newState });
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      head_office_id:nextProps.currentHeadOfficeID,
      branch_id:null
    })
  }

  atSubscription=()=>{
     return window.location.pathname === '/common/subscription';
   };

  renderMenuItems = data => data.map(s => (
    <MenuItem key={s.id} value={s.id}>
      {s.name}
    </MenuItem>
  ));

  renderBranchMenuItems = data => data.map(s => (
    <MenuItem key={s.id} value={s.id}>
      {s.is_head_office === 1 || s.is_head_office ==='1' ?"Head Office" : s.name}
    </MenuItem>
  ));

  render() {
    const { classes, companiesByID, switchedCountryCompanies } = this.props;
    const { head_office_id, branch_id} = this.state;
    const companies = switchedCountryCompanies;
    const branches = Helpers.companyBranches(companiesByID, head_office_id);
    const selected_company_name = this.props.selectedCompanyName;
    let selected_branch_name = this.props.selectedBranchName;
    let switched_department = Helpers.authSwitchedDepartment();
    if(switched_department && !switched_department.toUpperCase().includes('DEPARTMENT')){
      switched_department+= ' Department';
    }
    if(switched_department){
      selected_branch_name+= ' | '+switched_department;
    }
    return (
      <div>
        <ul align="center" style={{'listStyleType': 'none' }}>
          <li>
            <Button size="small" variant="contained" style={{ textTransform: 'none', borderRadius: 20, paddingLeft:5, paddingRight:9, paddingTop:0, paddingBottom:0 }} onClick={this.handleClickOpen}>
              {selected_company_name}
              <i className="zmdi zmdi-chevron-down zmdi-hc-fw" />
            </Button>
          </li>
          {
            !this.atSubscription() &&
            <li>
              <h6 className="text-success" style={{ marginTop:5}} onClick={this.handleClickOpen}>
                {selected_branch_name}
                <i className="zmdi zmdi-chevron-down zmdi-hc-fw" />
              </h6>
            </li>
          }
        </ul>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Switch Company/Branch
          </DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off">
              <div className="row">
                <div className="col-12">
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="company">Company</InputLabel>
                    <Select
                      value={head_office_id}
                      onChange={this.updateState}
                      input={<Input name="head_office_id" id="head_office_id" />}
                >
                      {this.renderMenuItems(companies)}
                    </Select>
                  </FormControl>
                </div>
                {
                  !this.atSubscription() &&
                  <div className="col-12">
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="branch">Branch</InputLabel>
                      <Select
                        value={branch_id}
                        onChange={this.updateState}
                        input={<Input name="branch_id" id="branch_id" />}
                      >
                        {this.renderBranchMenuItems(branches)}
                      </Select>
                    </FormControl>
                  </div>
                }
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSubmit} color="primary">
              Switch
            </Button>
            {
              this.atSubscription() &&
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            }
            {
              !this.atSubscription() &&
              <Button onClick={this.handleCompanySettingsLink} color="primary">
                View Companies
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = ({company}) => {
  let {companiesByID, switchedCountry} = company;
  const reducerSwitchedCompanyID = company.switchedCompanyID;//just to trigger for re-render
  const reducerCurrentHeadOfficeID = company.currentHeadOfficeID;//just to trigger for re-render
  let switchedCompanyID = Helpers.authSwitchedCompanyID();//with latest data used for axios
  let currentHeadOfficeID = Helpers.authCurrentHeadOfficeID();//with latest data used for axios
  let selectedCompany = Helpers.recordFromIndexedData(companiesByID,currentHeadOfficeID);
  let selectedCompanyName = (selectedCompany && selectedCompany.hasOwnProperty('name'))?selectedCompany.name:'Select Company';
  let selectedBranchName = '';
  if(switchedCompanyID === currentHeadOfficeID){
    selectedBranchName ="Head Office";
  }else{
    let branches = Helpers.companyBranches(companiesByID, currentHeadOfficeID);
    let selectedBranch = branches.where(b=>b.id===switchedCompanyID).first();
    selectedBranchName = selectedBranch?selectedBranch.name:"Branch"
  }
  let switchedCountryCompanies = [];
  if(!switchedCountry || !switchedCountry.hasOwnProperty('id')){
    const branch = Helpers.currentSwitchedBranch(companiesByID);
    let country = branch.country;
    if(country){
      Helpers.saveItem(SWITCHED_COUNTRY_KEY, country);
      switchedCountry = country;
    }else{
      switchedCountry = Helpers.authSwitchedCountry();
    }
  }
  if(switchedCountry && switchedCountry.hasOwnProperty('id')){
    switchedCountryCompanies = Object.values(companiesByID).where(record=>Number(record.country_id) === Number(switchedCountry.id));
  }else{
    switchedCountryCompanies = Object.values(companiesByID)
  }

  return {companiesByID, switchedCountryCompanies,switchedCountry,switchedCompanyID,currentHeadOfficeID,selectedCompanyName,selectedBranchName, reducerSwitchedCompanyID, reducerCurrentHeadOfficeID};
};
export default withStyles(styles)(withRouter(connect(mapStateToProps, {switchCompanyGenerator})(CompanySwitch)));
